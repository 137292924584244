import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddRegionHeadForm from './AddRegionHeadForm';
import EditRegionHeadForm from './EditRegionHeadForm';
import RegionHeadTable from './RegionHeadTable';
import MultiSelectDropdown from './MultiSelectDropDown';
import { changeHeaderName } from '../../actions/dashboardActions';
import { requestRegions, getAllRegions } from '../../actions/regionActions';
import { requestRegionalHeads, getAllRegionalHeads, addRegionalHead, updateRegionalHead, clearMsg } from '../../actions/regionalHeadActions';

const styles = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	table: {
		minWidth: 650,
	},
	head: {
		fontWeight: 700
	},
	button: {
		marginTop: theme.spacing(3),
		float: 'right'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '45%',
		height: '75%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
	searchBox: {
		width: '30%',
		marginTop: '24px'
	},
	clearButton: {
		marginLeft: '25px'
	},
	content: {
		width: '100%',
		height: '100%',
	},
	container: {
		width: '100%',
		height: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	searchPaper: {
		// padding: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	formControl: {
        width: '100%',
        marginTop: '10px',
    }
});


class Region extends Component {
	constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			regionalHeadToEdit: null,
			assignedRegions: [],
			selectedRegions: [],
			firstName: "",
			lastName: "",
			email: "",
			mobileNumber: "",
			username: "",
			pinCode: "",
			activeInactiveStatus: "",
		};
	}

	componentDidMount() {
		this.props.changeHeaderName("Manage Regional Heads");
		this.setState({ activeInactiveStatus: "active" }, () => {this.fetchRegionalHeadsByQuery()});
		// this.fetchRegionalHeadsByQuery();
		this.props.requestRegions();
		this.props.getAllRegions();
	};

	handleModalOpen = () => {
		this.setState({ modalOpen: true });
	};

	handleModalClose = () => {
		this.setState({ modalOpen: false, editMode: false });
	};

	openEditRegionalHeadModal = (regionalHead) => {
		this.setState({ editMode: true, regionalHeadToEdit: regionalHead, modalOpen: true });
	};

	handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null });
		this.setState({ editMode: false });
		this.fetchRegionalHeadsByQuery();
		this.props.requestRegions();
		this.props.getAllRegions();
	};

	fetchRegionalHeadsByQuery = async () => {
		this.props.requestRegionalHeads();
		let payload = {
			orderBy: this.state.orderBy,
			order: Number(this.state.order),
			skip: this.state.page * this.state.rowsPerPage,
			limit: this.state.rowsPerPage,
			activeInactiveStatus: this.state.activeInactiveStatus,
			regionids: this.state.selectedRegions.join(','),
			firstName: this.state.firstName.trim(),
			lastName: this.state.lastName.trim(),
			email: this.state.email.trim(),
			mobileNumber: this.state.mobileNumber.trim(),
			username: this.state.username.trim(),
			pincode: this.state.pinCode.trim()
		};
		this.props.getAllRegionalHeads(payload);
	};

	handleSearchSubmit = (event) => {
		event.preventDefault();
		this.setState({
			page: 0,
			clearSearch: true
		}, () => this.fetchRegionalHeadsByQuery())
	};

	handleClearButton = () => {
		this.setState({
			page: 0,
			regionSearchvalue: "",
			clearSearch: false
		}, () => this.fetchRegionalHeadsByQuery())
	};

	addRegionalHead = (props) => {
		props.controlledAreas = [];
		props.isActive = true;
		this.props.addRegionalHead(props);
		this.fetchRegionalHeadsByQuery();
		this.setState({ modalOpen: false });
	};

	updateRegionalHead = (payload) => {
		this.props.updateRegionalHead(payload);
	};

	setOrder = (order) => {
		this.setState({ order }, () => this.fetchRegionalHeadsByQuery());
	};

	setOrderBy = (orderBy) => {
		this.setState({ orderBy }, () => this.fetchRegionalHeadsByQuery());
	};

	setPage = (page) => {
		this.setState({ page }, () => this.fetchRegionalHeadsByQuery());
	};

	setRowsPerPage = (rowsPerPage) => {
		this.setState({ rowsPerPage });
	};

	handleFilterChange = (event) => {
		if (event.target.name === "assignedRegions") {
			let selectedRegions = [];
			for (let i = 0; i < this.props.regions.data.data.length; i++) {
				for (let j = 0; j < event.target.value.length; j++) {
					const element = event.target.value[j];
					if (element.toLowerCase() === this.props.regions.data.data[i].name.toLowerCase()) {
						selectedRegions.push(this.props.regions.data.data[i]._id)
					}
				}
			}
			this.setState({
				selectedRegions: selectedRegions,
				assignedRegions: event.target.value
			});
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	};

	resetFilter = () => {
		this.setState({
			order: -1,
			orderBy: 'username',
			page: 0,
			rowsPerPage: 10,
			assignedRegions: [],
			selectedRegions: [],
			firstName: "",
			lastName: "",
			email: "",
			mobileNumber: "",
			username: "",
			pinCode: "",
			activeInactiveStatus: "",
		}, () => {
			this.fetchRegionalHeadsByQuery();
		})
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.regionalHead.sucessMsg) {
			this.setState({ sucessMsg: this.props.regionalHead.sucessMsg })
			this.setState({ snackOpen: true });
			this.setState({ modalOpen: false });
			this.fetchRegionalHeadsByQuery();
			this.props.requestRegions();
			this.props.getAllRegions();
			this.props.clearMsg();
		} else if (this.props.regionalHead.errorMsg) {
			this.setState({ errorMsg: this.props.regionalHead.errorMsg })
			this.setState({ snackOpen: true });
			this.props.clearMsg();
		}
	};

	handleUserFlagSwitchChange = async (regionalHeadObj) => {
		let payload = {
			_id: regionalHeadObj._id,
			isActive: !regionalHeadObj.isActive
		};
		await this.props.updateRegionalHead(payload);
	};

	render() {
		const { classes } = this.props;
		let muiAlert = null;

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

		return (
			<div className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container}>
					<Container style={{ width: '100%', height: "40px", paddingTop: '0px' }}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							style={{ float: 'right' }}
							onClick={() => this.handleModalOpen()}
						>
							Add Regional Head
          			</Button>
						<Modal
							className={classes.modal}
							open={this.state.modalOpen}
							onClose={this.handleModalClose}
							closeAfterTransition>
							<Container className={classes.modalContainer}>
								{
									this.state.editMode ? <EditRegionHeadForm initialValues={_.pick(this.state.regionalHeadToEdit, '_id', 'firstName', 'lastName', 'email', 'mobileNumber', 'username', 'controlledRegions')} regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} onSubmit={this.updateRegionalHead} onClose={this.handleModalClose} />
										: <AddRegionHeadForm onSubmit={this.addRegionalHead} onClose={this.handleModalClose} regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []} />
								}

							</Container>
						</Modal>
					</Container>
					<Container style={{ paddingTop: '10px' }}>
						{
							this.props.regions && this.props.regions.data && this.props.regions.data.data.length > 0 ?
								<ExpansionPanel>
									<ExpansionPanelSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										style={{ minHeight: '44px', height: '44px' }}
									>
										<Typography className={classes.heading}>Search Regional Heads</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<Grid container spacing={1}>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="username" label="Username" value={this.state.username} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="email" label="E-mail" value={this.state.email} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="firstName" label="First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="lastName" label="Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="mobileNumber" label="Contact Number" value={this.state.mobileNumber} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="pinCode" label="Pin Code" value={this.state.pinCode} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<MultiSelectDropdown
													regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []}
													assignedRegions={this.state.assignedRegions}
													assignRegions={this.handleFilterChange}
													avoidErrorMsg={true}
												/>
											</Grid>
											<Grid item xs={3}>
											<FormControl
												variant="outlined"
												fullWidth
												className={classes.formControl}
												style={{ marginTop: '0px' }}
											>
												<InputLabel id="ActiveInactiveStatus-label"> Status</InputLabel>
												<Select
												labelId="ActiveInactiveStatus-label"
												name="activeInactiveStatus"
												value={this.state.activeInactiveStatus}
												onChange={this.handleFilterChange}
												input={<Input />}
												// MenuProps={MenuProps}
												style={{height:'32px'}}
												>
												<MenuItem
													value={"active"}
													style={{ minHeight: "28px", height: "28px" }}
												>
													<ListItemText primary="Active" />
												</MenuItem>
												<MenuItem
													value={"inactive"}
													style={{ minHeight: "28px", height: "28px" }}
												>
													<ListItemText primary="Inactive" />
												</MenuItem>
												</Select>
											</FormControl>
											</Grid>
											<Grid item xs={8}>
											</Grid>
											<Grid item xs={2} style={{ paddingTop: "12px" }}>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													margin="normal"
													onClick={this.resetFilter}
												>
													Reset
                                    			</Button>
											</Grid>
											<Grid item xs={2} style={{ paddingTop: "12px" }}>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													margin="normal"
													color="primary"
													onClick={
														()=>this.setPage(0)
														// this.fetchRegionalHeadsByQuery
													}
												>
													Search
                                    			</Button>
											</Grid>
										</Grid>

									</ExpansionPanelDetails>
								</ExpansionPanel>
								: null
						}

					</Container>

					<Container className={classes.container}>
						{
							this.props.regionalHead.regionalHeads && this.props.regionalHead.regionalHeads.data ?
								<RegionHeadTable
									rowData={this.props.regionalHead.regionalHeads}
									// order={this.state.order === 1 ? 'asc' : 'desc'}
									// orderBy={this.state.orderBy}
									page={this.state.page}
									rowsPerPage={this.state.rowsPerPage}
									setOrder={this.setOrder}
									setOrderBy={this.setOrderBy}
									setPage={this.setPage}
									setRowsPerPage={this.setRowsPerPage}
									updateRegionalHead={this.updateRegionalHead}
									openEditRegionalHeadModal={this.openEditRegionalHeadModal}
									history={this.props.history}
									handleUserFlagSwitchChange={this.handleUserFlagSwitchChange}
								/>
								: null
						}
					</Container>
				</Container>

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.snackOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}>
					{muiAlert}
				</Snackbar>

				<Backdrop className={classes.backdrop} open={this.props.regionalHead.isFetching || this.props.isRegionFetching}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		regionalHead: state.regionalHead,
		isRegionFetching: state.region.isFetching,
		regions: state.region.regions
	}
};
export default connect(mapStateToProps, {
	changeHeaderName,
	requestRegionalHeads,
	getAllRegionalHeads,
	requestRegions,
	getAllRegions,
	addRegionalHead,
	updateRegionalHead,
	clearMsg
})(withStyles(styles)(Region));