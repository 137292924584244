import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Avatar from '@material-ui/core/Avatar';
import { changeHeaderName } from '../../actions/dashboardActions';
import { requestServiceRequests, exportToExcelPrepayment, getAllPrepaymentOrders, findAllConversations } from '../../actions/serviceRequestActions';
import { getCategoriesByType } from '../../actions/categoriesActions';
import ServiceRequestsTable from './Table';
import CategoriesMultiSelectDropdown from './CategoriesMultiSelectDropdown';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { getConsumerTagList } from "../../actions/consumerTagAction";
import { getBusinessTagList } from "../../actions/businessTagAction";
import Modal from '@material-ui/core/Modal';
import ConversationsTable from './ConversationsTable';


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    asap: {
        height: "25px",
        width: "25px",
        backgroundColor: "#000000",
        borderRadius: "50%",
        display: "inline-block",
    },
    customerPrefferedTime: {
        height: "25px",
        width: "25px",
        backgroundColor: "#2AB3F7",
        borderRadius: "50%",
        display: "inline-block",
    },
    businessRescheduledTime: {
        height: "25px",
        width: "25px",
        backgroundColor: "#F8B051",
        borderRadius: "50%",
        display: "inline-block",
    },
    scheduleLater: {
        height: "25px",
        width: "25px",
        backgroundColor: "#70C87E",
        borderRadius: "50%",
        display: "inline-block",
    },
    preOrders: {
        height: "25px",
        width: "25px",
        backgroundColor: "#C7709B",
        borderRadius: "50%",
        display: "inline-block",
    },
    preparationTimer: {
        height: "25px",
        width: "25px",
        backgroundColor: "#FF0000",
        borderRadius: "50%",
        display: "inline-block",
    }
});


const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class PrepaymentOrders extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('/servicerequests/getAllPrepaymentOrders')) || {

            order: -1,
            orderBy: 'creationDate',
            page: 0,
            rowsPerPage: 50,
            serviceRequestId: "",
            creationDate: "",
            paymentStatus: [],
            selectedCategories: [],
            filteredCategoryIds: [],
            businessFilter: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {},
            consumerFilter: "",
            consumerMobileNumber: "",
            consumerId: "",
            preparexportToExcel: false,
            pnCode: "",
            serviceMode: "",
            consumerTags: [],
            businessTags: [],
            filteredConsumerTags: [],
            filteredBusinessTags: [],
            modalOpen: false,
            modalClose: false,
            conversationsflag: this.props.serviceRequest.serviceRequests.conversationsflag
        }
    }
    componentDidMount() {
        this.props.changeHeaderName("Preservice Requests");
        this.fetchOrdersByQuery();
        this.props.getCategoriesByType("category");
        this.fetchConsumerTags();
        this.fetchBusinessTags();
        this.setState({ modalClose : this.props?.serviceRequest?.serviceRequests?.conversationsflag})
        window.addEventListener("beforeunload", () => localStorage.removeItem('/servicerequests/getAllPrepaymentOrders'));
    }

    fetchOrdersByQuery = async () => {
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
        this.props.requestServiceRequests();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            serviceRequestId: this.state.serviceRequestId.trim(),
            businessName: this.state.businessFilter.trim(),
            paymentStatus: this.state.paymentStatus,
            categories: this.state.filteredCategoryIds,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            consumerName: this.state.consumerFilter.trim(),
            consumerMobileNumber: this.state.consumerMobileNumber.trim(),
            consumerId: this.state.consumerId.trim(),
            pnCode: this.state.pnCode,
            exportToexcel: 0,
            filteredConsumerTags: filteredConsumerTagsVar,
            filteredBusinessTags: filteredBusinessTagsVar

        }
        await this.props.getAllPrepaymentOrders(payload);
        if (this.state.preparexportToExcel) {
            this.setState({ preparexportToExcel: false });
        }
        localStorage.setItem("/servicerequests/getAllPrepaymentOrders", JSON.stringify(this.state));
    }


    fetchOrdersByQueryExcelFile = async () => {
     
        // let deliveryfinalArray = deliveryArr.map((value) => value === "Xirify Delivery" ? "xirify_delivery" : (value === 'Porter' ? 'porter' : (value === 'Shadowfax' ? 'shadowfax' : value)));
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');

        this.props.requestServiceRequests();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            serviceRequestId: this.state.serviceRequestId.trim(),
            businessName: this.state.businessFilter.trim(),
            paymentStatus: this.state.paymentStatus,
            categories: this.state.filteredCategoryIds,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            consumerName: this.state.consumerFilter.trim(),
            consumerMobileNumber: this.state.consumerMobileNumber.trim(),
            consumerId: this.state.consumerId.trim(),
            pnCode: this.state.pnCode,
            exportToexcel: 1,
            filteredConsumerTags: filteredConsumerTagsVar,
            filteredBusinessTags: filteredBusinessTagsVar
        }
        await this.props.exportToExcelPrepayment(payload);
        this.setState({ preparexportToExcel: true });

    }

    fetchConsumerTags = async () => {
        const response = await getConsumerTagList();
        if (response) {
            this.setState({ consumerTags: response.data })
        } else {
            this.setState({ consumerTags: [] })
        }
    }

    fetchBusinessTags = async () => {
        const response = await getBusinessTagList();
        if (response) {
            this.setState({ businessTags: response.data })
        } else {
            this.setState({ businessTags: [] })
        }

    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchOrdersByQuery());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchOrdersByQuery());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchOrdersByQuery());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        if (event.target.name === "categories") {
            let filteredCategoryIds = [];
            for (let i = 0; i < this.props.categories.length; i++) {
                for (let j = 0; j < event.target.value.length; j++) {
                    const element = event.target.value[j];
                    if (element.toLowerCase() === this.props.categories[i].name.toLowerCase()) {
                        filteredCategoryIds.push(this.props.categories[i]._id)
                    }
                }
            }
            this.setState({
                selectedCategories: event.target.value,
                filteredCategoryIds: filteredCategoryIds
            });
        } else if (event.target.name === "serviceMode") {
            if (event.target.value === 'PICKUP') {
                this.setState({ deliveryType: [] })
            }
            this.setState({ [event.target.name]: event.target.value });
        }
        else if (event.target.name === "serviceMode") {
            if (event.target.value === 'dunzo' && event.target.value === 'DELIVERY' && event.target.value === 'INITIATED') {
                this.setState({ deliveryType: [] })
            }
        }
        else {
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'creationDate',
            page: 0,
            rowsPerPage: 50,
            serviceRequestId: "",
            creationDate: "",
            selectedCategories: [],
            filteredCategoryIds: [],
            businessFilter: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            consumerFilter: "",
            consumerMobileNumber: "",
            consumerId: "",
            pnCode: "",
            filteredConsumerTags: [],
            filteredBusinessTags: []
        }, () => {
            this.fetchOrdersByQuery();
        })
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchOrdersByQuery();
        }
    }
    handleModalOpen = () => {
        this.setState({ modalOpen: true });
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false, modalClose: true, conversationsflag: true });
    };
    render() {


        const { classes } = this.props;
        return (

            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Preservice Request</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={12}> */}
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="serviceRequestId" label="Service Request Id" value={this.state.serviceRequestId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="businessFilter" label="Business Name/Id" style={{ width: "225px" }} value={this.state.businessFilter} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                        }}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="orderDates" label="Service Request Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>

                                <Grid item xs={3}>
                                    <CategoriesMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        categories={this.props.categories}
                                        selectedCategories={this.state.selectedCategories}
                                    />
                                </Grid>


                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>



                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="consumerFilter" label="Consumer Name" style={{ width: "225px" }} value={this.state.consumerFilter} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerMobileNumber" label="Consumer Mobile No" value={this.state.consumerMobileNumber} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="pnCode" label="Pin Code" value={this.state.pnCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>

                                <Grid item xs={3}>
                                    {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                        >
                                            {this.state.businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={9}>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            () => this.setPage(0)
                                            //this.fetchOrdersByQuery
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>


                </Container>
                <Modal
                    open={this.state.modalOpen}
                    className={classes.modal}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <ConversationsTable
                        onClose={this.handleModalClose}

                    />
                </Modal>
                <Container className={classes.container}>
                    {
                        this.props.serviceRequest.serviceRequests && this.props.serviceRequest.serviceRequests.data ?
                            <ServiceRequestsTable
                                data={this.props.serviceRequest.serviceRequests}
                                exportToexcel={this.props.serviceRequest.exportToExcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchOrdersByQueryExcelFile={this.fetchOrdersByQueryExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                isPreServiceRequst = {true}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.serviceRequest.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        serviceRequest: state.serviceRequest,
        categories: state.categories.categoriesByType,
        user: state.auth.user
    }
};



export default connect(mapStateToProps, { changeHeaderName, requestServiceRequests, getAllPrepaymentOrders, getCategoriesByType, exportToExcelPrepayment, findAllConversations })(withStyles(styles)(PrepaymentOrders));