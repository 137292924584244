import React, { Component } from "react";
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import UploadCampaignPhoto from "./uploadCampaignPhoto";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import DeleteIcon from '@material-ui/icons/Delete';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import moment from 'moment';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from "@material-ui/core/FormHelperText";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { checkPromoCode } from '../../actions/campaignActions';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { convertToRaw, ContentState, convertFromHTML } from 'draft-js';

const styles = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
      marginTop: '100px',
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '60px',
      marginBottom: '25px'
    },
    paper2: {
      padding: '0px 0px',
      display: 'flex',
      alignItems: 'center',
      height: '150px',
      marginBottom: '25px'
    },
    snackClose: {
      padding: theme.spacing(0.5),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px",
      width: "100%",
    },
    form: {
        //   width: '70%',
        //   height: '170%',
        // width: '100%',
        // height: '100%',
        marginTop: "300px",
        width: '100%',
        height: '200%',

    },
    header: {
        // height: '7%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    //   headGridThree: {
    //     float: 'right',
    //     marginLeft: '8px',
    //     marginTop: '4px',
    //     fontSize: '15px'
    //   },
    content: {
        height: '60%',
        // margin: '10px 0px',
        overflow: 'auto',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: '100%',
        height:'100%'
    },

    gridElement: {
        margin: '10px 5px'
    },

    img: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    icon: {
        color: 'white',
    },
    buttonGroup: {
        "height": "39px",
        "marginTop": "8px",
        "width": "0px",
        "marginLeft":"8px"
    },

    headercell:{
        height: '35px',
        // margin: '0px 0px',
        width: '5%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#b9edeb',
        border: "1px solid grey",
    },
    // gridborder:{
    //     border: "1px solid black",
    //     borderRadius: "25px",
    //     // alignItems: "center",
    //     // marginLeft:"10px",
    //     // paddingLeft:"10px",
    //     marginRight:"10px",
    //     width:"20px"
    // }
    gridborder:{
        border: "1px solid black",
        borderRadius: "25px",
        alignSelf: 'center',
        paddingLeft:"10px",
        paddingRight:"10px",
        paddingTop:"10px",
        paddingBottom:"10px",
        marginRight:"10px",
    
    },

    headGridThree: {
        float: 'right',
        alignSelf: 'center',
        alignItems: "center",
        marginLeft: '8px',
        marginRight: '4px',
        fontSize: '20px'
    },
});

let dateCurrent = new Date();
dateCurrent = moment(dateCurrent).format('YYYY-MM-DD');

let arrayList = [];
function promoCodeAssign(data){
    if(arrayList.length === 0){
        arrayList.push(data);
    }
    else if(arrayList.length === 1){
        if(arrayList[0].promocode === data.promocode){return 1;}
        else{arrayList.push(data); return arrayList }
    }
    else{
        for(let i = 0; i < arrayList.length; i++){
            if(data.promocode === arrayList[i].promocode){return 1;}
        }
        arrayList.push(data);
        return arrayList
    }
}

function hasWhiteSpace(s) {
    if(/\s/g.test(s)){
        return true;
    }else{
        return false;
    }
}

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class campaignAddForm extends Component {
    constructor(props) {
        super(props);
        const tempString=this?.props?.initialValues?.couponDescription || ""
        const contentHTML = convertFromHTML(tempString)
        const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
        const contentString = JSON.stringify(convertToRaw(state))
        this.state = {
            snackOpen: false,
            sucessMsg: null,
            value: 0,
            date: "",
            error: {},
            modalOpen: false, 
            rewardType: "",
            couponName: "",
            couponTotalAmountUser: "",
            numberofCoupons: "",
            valueperCoupon: "",
            minOrderValuePerCoupon: "",
            couponValidityDays: "",
            type: "",
            referralData:{},
            referralCouponImage: "",
            modalDeleteOpen: false,
            selectedPhoto: null,
            typePhoto: "", //imageUrl
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            campaignName: "",
            termsAndConditions: "",
            campaignTotalBudget: "",
            businessID: "",
            couponDescription: "",
            campaignTimeDateRangeStart: "",
            campaignTimeDateRangeEnd: "",
            campaignImage: "",
            promoCode: "",
            rewardTypeError:false,
            promocode: [],
            submitFlag: false,
            richTextError: false,
            promoFlag: false,
            responsePromoCodeError: "",
            infinityCheck: false,
            sponsoredBy: "",
            sponsoredByError: false,
            campaignCodes: "",
            shopperStatus: "",
            shopperStatusError: false,
            richTextInitialValue:contentString,
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };

    renderTextFieldCouponName({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
                inputProps={{ maxLength: 18 }}
            />
        );
    };

    renderTextFieldCheckBox({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <Checkbox
                label={label}
                placeholder={label}
                // error={touched && invalid}
                // helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    };

    onDeletePromoCode = async (code) => {
        let arrayL = [];
        arrayL = arrayList;
        arrayL.splice(arrayL.findIndex(a=>a.num===code.num),1);
        this.setState({promocode: arrayL});
        if(arrayL.length === 0){
            this.setState({promoFlag: true})
        }
    };

    componentWillReceiveProps(nextProps) {
        const { change, couponTotalAmountUser, numberofCoupons } = nextProps
        if (this.props.couponTotalAmountUser !== couponTotalAmountUser || this.props.numberofCoupons !== numberofCoupons) {
          change('valueperCoupon', calculateValuePerCoupon(couponTotalAmountUser,numberofCoupons))
        }
    }

    onSubmit = formValues => {
        if(this.props.campaignCode === "offerCode"){
            // console.log("offerCode if condition")
            if(this.state.couponDescription && this.state.sponsoredBy && this.state.shopperStatus){
                // console.log("if condition")
                formValues.campaignImage = this.state.campaignImage;
                formValues.couponDescription = this.state.couponDescription;
                formValues.sponsoredBy = this.state.sponsoredBy;
                formValues.infinityCheck = this.state.infinityCheck;
                formValues.shopperStatus = this.state.shopperStatus;
                // console.log("formValues------>",formValues);
                this.props.showLoader();
                this.props.onSubmit(formValues);
            }else{
                // console.log("else condition")
                if (!this.state.couponDescription) {
                    this.setState({ richTextError: true });
                }
                if (!this.state.sponsoredBy && this.state.sponsoredBy === "") {
                    this.setState({ sponsoredByError: true });
                }
                if (!this.state.shopperStatus && this.state.shopperStatus === "" && (this.state.shopperStatus !=="new" || this.state.shopperStatus!=="existing")) {
                    this.setState({ shopperStatusError: true });
                }
            }
        }else{
            // console.log("promoCode if condition")
            if(arrayList.length !== 0 && this.state.couponDescription && this.state.sponsoredBy){
                // console.log("if condition")
                formValues.campaignImage = this.state.campaignImage;
                formValues.couponDescription = this.state.couponDescription;
                formValues.arrayList = arrayList;
                formValues.sponsoredBy = this.state.sponsoredBy;
                this.props.showLoader();
                this.props.onSubmit(formValues);
            }else{
                // console.log("else condition")
                if(arrayList.length === 0){
                    this.setState({submitFlag:true})
                }
                if (!this.state.couponDescription) {
                    this.setState({ richTextError: true });
                }
                if (!this.state.sponsoredBy && this.state.sponsoredBy === "") {
                    this.setState({ sponsoredByError: true });
                }
            }
        }
    }

    handleChange = (event) => {
        this.setState({
          [event.target.name]: event.target.value
        });
        // if(event.target.name==="promoCode" && this.state.responsePromoCodeError !==""){
        //     this.setState({responsePromoCodeError:""})
        // }
        // if (!_.isEmpty(this.state.error)) {
        //         this.setState({ error: {} })
        //     }
        
        // let response =  this.props.responsePromoCodeError 
        // response= "";
        // this.setState({submitFlag: false, promoFlag: false});
    }

    handleChangePromoCode = (event) => {
        this.setState({
          [event.target.name]: event.target.value.toUpperCase()
        });
        if(event.target.name==="promoCode" && this.state.responsePromoCodeError !==""){
            this.setState({responsePromoCodeError:""})
        }
        if (!_.isEmpty(this.state.error)) {
                this.setState({ error: {} })
            }
        
        
        this.setState({submitFlag: false, promoFlag: false});
    }

    handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    }
    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }

    componentDidMount(){
        this.setState({campaignImage:  "", promocode: this.props.promocodes, campaignCodes: this.props.campaignCode});
        arrayList = [];
    }

    updateCampaignPhotos = async (files, photoType) => {
        let data = new FormData();
        let folderPath = "Marketplace_Business";
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "CampaignPromoCode") {
        let campaignImage = file.secure_url;    
        this.setState({ campaignImage });
        this.setState({ isUploading: false, typePhoto: photoType });
        }
    };

    deleteCampaignPhoto = async (selectedPhoto, photoType) => {    
        if (photoType === "CampaignPromoCode") {
            this.setState({
            campaignImage: "",
            modalDeleteOpen: false 
            });
        }
    };

    handleRichTextData = editorState => {
        if (editorState.getCurrentContent().getPlainText()) {
            this.setState({
                couponDescription: stateToHTML(editorState.getCurrentContent()),
                richTextError: false
            })
        } else {
            this.setState({
                couponDescription: "",
                richTextError: true
            })
        }
    };

    handlePromoCodeCheck = async (promoCode) => {
        let whiteSpaceCheck = hasWhiteSpace(promoCode);
        if(whiteSpaceCheck === false) {
            let payload = {
                promocode: promoCode
            }
            let answer = await this.props.checkPromoCode(payload);
            if(answer!== undefined && answer.status === 200){
                let arrayPromoCodes = promoCodeAssign(answer.data);
                if(arrayPromoCodes === 1){
                    this.setState({responsePromoCodeError: "Promo Code already exists", promoFlag: false});
                }
                else{
                    this.setState({promocode: arrayPromoCodes, responsePromoCodeError:"", promoFlag: false, promoCode: ""});
                }
            }
            else if(answer === 'Request failed with status code 400'){
                this.setState({responsePromoCodeError:"Promo Code Required", promoFlag: false});
            }
            else{
                this.setState({responsePromoCodeError: "Promo Code already exists", promoFlag: false});
            }
        }
        if(whiteSpaceCheck === true){
            this.setState({responsePromoCodeError: "Promo Code should not contain spaces", promoFlag: false});
        }
    };

    handleCheckBoxInfinity = async (event) => {
        this.setState({ infinityCheck: event.target.checked });
        this.props.initialValues.infinityCheckFlag = event.target.checked;
        // console.log("intial values before sending---->",this.props.initialValues)
        // validate(this.props.initialValues);
    };

    handleSponsoredByChange = async (event) => {
        this.setState({
            sponsoredBy: event.target.value,
            sponsoredByError: false
        });
    };

    handleFilterChange = (event) => {
        // console.log(event.target.name);
        if(event.target.name==="shopperType") {
            this.setState({shopperStatus:event.target.value, shopperStatusError:false});
        }
    };

    render() {
        const { classes } = this.props;
        // this.state.promocode = this.props.promocodes;

        if(this.props.promocodes && this.props.promocodes !==  "" && this.props.promocodes.length && this.props.promocodes !== undefined){
            this.props.promocodes.map((promocode) => (promocode))
        }
        this.props.initialValues.rewardType = 'Coupons';
        this.props.initialValues.campaignCodes = this.props.campaignCode;
        // this.props.initialValues.infinityCheckFlag = this.state.infinityCheck;

        // console.log("props in add form----->", this.props);
        // console.log("state in add form----->", this.state);
        // console.log("this.props.initialValues.infinityCheckFlag in add form----->", this.props.initialValues.infinityCheckFlag);

        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                <Grid item xs={8}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        Add / Edit / Duplicate Campaign
                    </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                    <WhiteTextTypography variant="h6" color="inherit" gutterBottom >
                        <CloseIcon
                            className={classes.headGridTwo}
                            onClick={() => { this.props.onClose() }}
                        />
                    </WhiteTextTypography>
                </Grid>
                </Grid>

                <Container className={classes.content}>
                    <Grid container className={classes.grid} spacing={1} justify="center" alignItems="center" >
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Campaign Name *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Start Date *
                            </Typography>
                        </Grid>
                        {
                            this.props.campaignCode === "offerCode" 
                            ?
                            <Grid item xs={12} sm={3}>
                                <Typography variant="subtitle2" gutterBottom>
                                    End Date *
                                </Typography>
                            </Grid>
                            :
                            <Grid item xs={12} sm={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    End Date *
                                </Typography>
                            </Grid>
                        }
                        {
                            this.props.campaignCode === "offerCode" 
                            ?
                            <Grid item xs={12} sm={1}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Infinity
                                </Typography>
                            </Grid>
                            :null
                        }
                        
                        <Grid></Grid>

                        {/* TextFields and Date */}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="campaignName" label="Campaign Name *" variant="outlined"
                                component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field
									fullWidth
									margin="dense"
									name="campaignTimeDateRangeStart"
									type="date"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									component={this.renderTextField}
                                    
								/>
                        </Grid>
                        {
                            this.props.campaignCode === "offerCode" 
                            ?
                            <Grid item xs={12} sm={3}>
                                <Field
									fullWidth
									margin="dense"
									name="campaignTimeDateRangeEnd"
									type="date"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									component={this.renderTextField}
                                    disabled = { this.state.infinityCheck === true ? true : false }
								/>
                            </Grid>
                            :
                            <Grid item xs={12} sm={4}>
                                <Field
									fullWidth
									margin="dense"
									name="campaignTimeDateRangeEnd"
									type="date"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									component={this.renderTextField}
                                    // disabled = { this.state.infinityCheck === true ? true : false }
								/>
                            </Grid>
                        }
                        {
                            this.props.campaignCode === "offerCode" 
                            ?
                            <Grid item xs={12} sm={1}>
                                {/* <Checkbox
                                    onClick={(event) => this.handleCheckBoxInfinity(event)}
                                    value={this.state.infinityCheck}
                                    name="infinityCheck"
                                    checked={this.state.infinityCheck}
                                /> */}
                                <Field
									fullWidth
									margin="dense"
									name="infinityCheck"
									variant="outlined"
									InputLabelProps={{
										shrink: true,
									}}
									component={this.renderTextFieldCheckBox}
                                    onClick={(event) => this.handleCheckBoxInfinity(event)}
								/>
                            </Grid>
                            :null
                        }
                        

                        {
                            this.props.campaignCode === "offerCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Sponsored By *
                                    </Typography>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Campaign Total Budget ({'\u20B9'})
                                    </Typography>
                                </Grid>
                        }
                        
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Reward Type *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Name *
                            </Typography>
                        </Grid>

                        {/* TextFields and drop down*/}
                        {
                            this.props.campaignCode === "offerCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth >
                                        {/* <InputLabel id="demo-simple-select-label">Reward Type</InputLabel> */}
                                        <Select
                                            labelId="SponsoredBy-label"
                                            id="demo-simple-select"
                                            value={this.state.sponsoredBy}
                                            onChange={this.handleSponsoredByChange}
                                            // component={this.renderTextField}
                                            name="sponsoredBy" 
                                            variant="outlined"
                                            margin="dense"
                                            error={this.state.sponsoredByError ? true : false}
                                            // helperText={(this.props.responseRewardTypeError && this.props.responseRewardTypeError !==undefinedundefined && this.props.responseRewardTypeError!== "")  ? this.props.responsePromoCodeError : null}
                                        >
                                            {/* <MenuItem value={''}></MenuItem> */}
                                            <MenuItem value={'Business'}>Business</MenuItem>
                                            <MenuItem value={'Xirify'}>Xirify</MenuItem>
                                        </Select>
                                        {(this.state.sponsoredByError)? (
                                            <FormHelperText style={{ color: "red" }}>
                                            Required
                                            </FormHelperText>
                                        ) :  null}
                                    </FormControl>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}>
                                    <Field fullWidth margin="dense" name="campaignTotalBudget" label="Campaign Total Budget *" variant="outlined" component={this.renderTextField}
                                    />
                                </Grid>
                        }
                        
                        <Grid item xs={12} sm={4}>
                            {/* <Field fullWidth margin="dense" name="rewardType" label="Reward Type *" variant="outlined" component={this.renderTextField}
                            /> */}
                            <FormControl fullWidth >
                                {/* <InputLabel id="demo-simple-select-label">Reward Type</InputLabel> */}
                                <Select
                                    // labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={'Coupons'}
                                    // label="Reward Type"
                                    onChange={this.handleChange}
                                    // component={this.renderTextField}
                                    name="rewardType" 
                                    variant="outlined"
                                    margin="dense"
                                    // error={this.props.responseRewardTypeError}
                                    // helperText={(this.props.responseRewardTypeError && this.props.responseRewardTypeError !==undefinedundefined && this.props.responseRewardTypeError!== "")  ? this.props.responsePromoCodeError : null}
                                >
                                    {/* <MenuItem value={''}></MenuItem> */}
                                    <MenuItem value={'Coupons'} >Coupons</MenuItem>
                                </Select>
                                {/* {(this.state.rewardTypeError)? (
                                    <FormHelperText style={{ color: "red" }}>
                                    Required
                                    </FormHelperText>
                                ) :  null} */}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponName" label="Coupon Name *" variant="outlined" component={this.renderTextFieldCouponName}
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Total Amount/User ({'\u20B9'})
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Number of Coupons *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Value per Coupon
                            </Typography>
                        </Grid>

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponTotalAmountUser" label="Coupon Total Amount/User" variant="outlined" component={this.renderTextField} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="numberofCoupons" label="Number of Coupons *" variant="outlined" component={this.renderTextField} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="valueperCoupon" label="Value per Coupon" variant="outlined" disabled
							component={this.renderTextField} 
                            />
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Min. Order Value (Per Coupon) *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Validity (Days) *
                            </Typography>
                        </Grid>
                        {
                            this.props.campaignCode === "promoCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        {/* Business ID */}
                                        Sponsored By *
                                    </Typography>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom></Typography> </Grid>
                        }
                        

                        {/* TextFields and drop down*/}
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="minOrderValuePerCoupon" label="Min. Order Value (Per Coupon) *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="couponValidityDays" label="Coupon Validity (Days) *" variant="outlined" component={this.renderTextField} inputProps={{ maxLength: 3 }}
                            />
                        </Grid>
                        {
                            this.props.campaignCode === "promoCode" 
                            ? 
                                <Grid item xs={12} sm={4}>
                                     {/* <Field fullWidth margin="dense" name="businessID" label="Business ID" variant="outlined" component={this.renderTextField}
                                     /> */}
                                    <FormControl fullWidth >
                                        {/* <InputLabel id="demo-simple-select-label">Reward Type</InputLabel> */}
                                        <Select
                                            labelId="SponsoredBy-label"
                                            id="demo-simple-select"
                                            value={this.state.sponsoredBy}
                                            onChange={this.handleSponsoredByChange}
                                            // component={this.renderTextField}
                                            name="sponsoredBy" 
                                            variant="outlined"
                                            margin="dense"
                                            error={this.state.sponsoredByError ? true : false}
                                            // helperText={this.state.sponsoredByError ? "Required" : null}
                                        >
                                            {/* <MenuItem value={''}></MenuItem> */}
                                            <MenuItem value={'Business'}>Business</MenuItem>
                                            <MenuItem value={'Xirify'}>Xirify</MenuItem>
                                        </Select>
                                        {(this.state.sponsoredByError)? (
                                            <FormHelperText style={{ color: "red" }}>
                                            Required
                                            </FormHelperText>
                                        ) :  null}
                                    </FormControl>
                                </Grid>
                            :
                                <Grid item xs={12} sm={4}> <Typography variant="subtitle2" gutterBottom></Typography> </Grid>
                        }

                        {
                            this.props.campaignCode === "offerCode" ?
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Shopper Selection *
                                    </Typography>
                                </Grid>
                            :
                                null
                        }
                        {
                            this.props.campaignCode === "offerCode" ?
                                <Grid item xs={12}>
                                    <RadioGroup row aria-label="position" name="shopperType" 
                                    onChange={this.handleFilterChange} value={this.state.shopperStatus} >
                                    <FormControlLabel value="existing" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All existing shoppers</Typography>} size="small" />
                                    <FormControlLabel value="new" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Only new shoppers</Typography>} size="small" />
                                    </RadioGroup>
                                </Grid>
                            :
                                null
                        }
                        {
                            this.props.campaignCode === "offerCode" ?
                                <Grid item xs={12}><FormHelperText style={{ color: "red", marginLeft:'10px',marginTop:'0px', marginBottom:'10px'}}> {this.state.shopperStatusError === true?"Shopper Selection Required":null} </FormHelperText></Grid>
                            :
                                null
                        }

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                T&C *
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Field fullWidth margin="dense" name="termsAndConditions" label="Terms And Conditions *" variant="outlined" component={this.renderTextField}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Description *
                            </Typography>
                        </Grid>
                

                        {/* TextFields and drop down*/}
                        <Grid item xs={12}>
                            {/* <Field fullWidth margin="dense" name="couponDescription" label="Coupon Description *" variant="outlined" component={this.renderTextField} /> */}
                            <div>
                            {this.state.richTextError ? 
                            <FormHelperText style={{ color: "red" }}>
                                Coupon Description Required
                            </FormHelperText>
                            : null
                            }
                            </div>
                            <MUIRichTextEditor
                                name="couponDescription"
                                label="Coupon Description"
                                toolbarButtonSize="small"
                                defaultValue={this.state.richTextInitialValue}
                                // inlineToolbar={true}
                                error={this.state.richTextError}
                                onChange={this.handleRichTextData}
                                controls={[
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strikethrough',
                                    'link',
                                    'numberList',
                                    'bulletList',
                                    // "highlight",
                                    // 'quote',
                                    'media',
                                    'undo',
                                    'redo',
                                ]}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}><Typography variant="subtitle2" gutterBottom></Typography></Grid>
                        <Grid item xs={12} sm={4}></Grid>

                        {/* Upload Campaign Image button */}
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Coupon Image
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UploadCampaignPhoto
                                uploadButtonName = "UPLOAD IMAGE"
                                updateCampaignPhotos = {this.updateCampaignPhotos}
                                photoType="CampaignPromoCode"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.campaignImage !==  "" ) ?
                                    <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                        <GridListTile
                                            className={classes.gridElement} 
                                            // key={images._id}
                                        >
                                            <a href={this.state.campaignImage} target="_blank" rel="noopener noreferrer">
                                            <img border="0" className={classes.img} src={this.state.campaignImage} alt="" />
                                            </a>
                                            <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.campaignImage)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                            />
                                        </GridListTile>
                                    ))
                                    </GridList >
                            : null}
                        
                        </Grid>

                        <Grid item xs={12} sm={4}></Grid>

                        <Grid item xs={12} hidden={this.props.campaignCode === "offerCode"}>
                            <Typography variant="subtitle2" gutterBottom> Promo Code * </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} hidden={this.props.campaignCode === "offerCode"}>

                            <TextField fullWidth label="Promo Code *" margin="dense" name="promoCode" style={{ width: "48%" }} variant="outlined" value={this.state.promoCode} onChange={this.handleChangePromoCode} 
                                error={
                                    this.state.promoFlag === true ? "Promo Code Required" :
                                (this.state.responsePromoCodeError && this.state.responsePromoCodeError !== undefined && this.state.responsePromoCodeError!== "")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag === true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)
                                }
                            />

                            {/* <Field style={{width:"48%"}} margin="dense" name="promoCode" label="Promo Code *" variant="outlined" component={this.renderTextField} onChange={this.handleChange}
                            // error= {(this.props.responsePromoCodeError && this.props.responsePromoCodeError !==undefinedundefined && this.props.responsePromoCodeError!== "")  ? this.props.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag==true)) ? "Promo Code Required" : null)}
                            // error={this.props.responsePromoCodeError}
            
                            // error={
                                //     this.state.promoFlag === true ? "Promo Code Required" :
                                // (this.state.responsePromoCodeError && this.state.responsePromoCodeError !==undefinedundefined && this.state.responsePromoCodeError!== "")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag==true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)
                            // }
                            // helperText={(this.props.responsePromoCodeError && this.props.responsePromoCodeError !==undefinedundefined && this.props.responsePromoCodeError!== "")  ? "Promocode "+this.props.responsePromoCodeError : (!this.props.promocodes ? "Promo Code Required" : null)}
                            />                             */}
                            {/* </Grid> */}
                                {/* {!this.props.promocodes ? (
                                    <FormHelperText style={{ color: "red" }}>
                                    Required
                                    </FormHelperText>
                                ) : null} */}
                            {/* <Grid item xs={12} sm={3}> */}
                            <ButtonGroup className={classes.buttonGroup} size="small" aria-label="small outlined primary button group" fullWidth >
                                <Button style={{fontWeight:'bold', backgroundColor:'#07c6db', color:'#ffffff'}} variant="contained" 
                                    // name={index.index} 
                                    // onClick={() => {this.props.handlePromoCodeCheck(this.state.promoCode)}}
                                    onClick={() => {this.handlePromoCodeCheck(this.state.promoCode)}}
                                >+</Button>
                            </ButtonGroup>
                            <div>
                                <FormHelperText style={{ color: "red" }}>
                                    {/* {(this.state.responsePromoCodeError && this.state.responsePromoCodeError !==undefinedundefined && this.state.responsePromoCodeError!== "")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag==true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)} */}
                                    {this.state.promoFlag === true ? "Promo Code Required" : (this.state.responsePromoCodeError && this.state.responsePromoCodeError !==undefined && this.state.responsePromoCodeError !== "")  ? this.state.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag === true) || (this.state.promoFlag === true)) ? "Promo Code Required" : null)}
                                    {/* {(this.props.responsePromoCodeError && this.props.responsePromoCodeError !==undefinedundefined && this.props.responsePromoCodeError!== "")  ? "Promocode "+this.props.responsePromoCodeError : ((!this.props.promocodes || (this.props.promocodes.length === 0 && this.state.submitFlag==true)) ? "Promo Code Required" : null)} */}
                                </FormHelperText>
                            </div>
                        </Grid>
                        {arrayList && arrayList !==  "" && arrayList.length && arrayList!== undefined  ? 
                            arrayList.map((promocode) => { 
                            return(
                                <Grid className={classes.gridborder} InputLabelProps={{shrink: true}} >
                                    {promocode.promocode}
                                    <CloseIcon
                                        className={classes.headGridThree}
                                        onClick={() => { this.onDeletePromoCode(promocode) }}
                                    />
                                </Grid>
                            )
                            })
                        : null }
                        
                        <Grid item xs={12}>
                            <Divider
                                color="textSecondary"
                                style={{ marginBottom: "5px", marginTop: "10px" }}
                                display="block"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}></Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                // onClick={this.addCampaign}
                                type="submit"
                            >
                                ADD / SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Container>

                <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Photo</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Do you want to delete this photo?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                    <Button onClick={() => this.deleteCampaignPhoto(this.state.selectedPhoto, this.state.typePhoto)} color="primary" autoFocus> Yes </Button>
                </DialogActions>
                </Dialog>
            </form>
        );
    }
}

const validateValuePerCoupon = (couponTotalAmountUser,valueperCoupon) => {
    if(couponTotalAmountUser && valueperCoupon){
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if(numberOfCoupon % 1===0) {
            return ""
        } else{
            return "Value per coupon should be a whole number"}
    }
}

const calculateValuePerCoupon=(couponTotalAmountUser,valueperCoupon)=>{
    if(couponTotalAmountUser && valueperCoupon){
        let numberOfCoupon = parseInt(couponTotalAmountUser)/parseInt(valueperCoupon);
        if(numberOfCoupon % 1===0) {
            return numberOfCoupon
        } else return ""
    } else {
        return ""
    }
}

const validate = values => {
    // console.log("validate values: ",values);
    // console.log("validate values infinityCheckFlag: ",values.infinityCheck);
    const errors = {};
    let requiredFields = [];
    
    if (values.campaignCodes === "offerCode") {
        // console.log("its offer code validations");
        // requiredFields = [ 'campaignName', 'rewardType','couponName','couponTotalAmountUser','numberofCoupons', 'valueperCoupon','minOrderValuePerCoupon','couponValidityDays','couponDescription', 'campaignTimeDateRangeStart', 'termsAndConditions','sponsoredBy', 'campaignTimeDateRangeEnd']

        if (values.infinityCheck) {
            // console.log("infinity check flag");
            // requiredFields.pop();
            requiredFields = ['campaignName', 'rewardType', 'couponName', 'couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription', 'campaignTimeDateRangeStart', 'termsAndConditions', 'sponsoredBy']

            requiredFields.forEach(field => {
                if (!values[field] && field !==  'sponsoredBy') {
                    errors[field] = 'Required'
                }
            }); //4284
        } else {
            requiredFields = ['campaignName', 'rewardType', 'couponName', 'couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription', 'campaignTimeDateRangeStart', 'termsAndConditions', 'sponsoredBy', 'campaignTimeDateRangeEnd']

            requiredFields.forEach(field => {
                if (!values[field] && field !==  'sponsoredBy') {
                    errors[field] = 'Required'
                }
            }); //4284
        }

        // console.log("required fields: ", requiredFields);

        // requiredFields.forEach(field => {
        //     if (!values[field] && field !==  'sponsoredBy') {
        //         errors[field] = 'Required'
        //     }
        // }); //4284
    } else if (values.campaignCodes === "promoCode") {
        // console.log("its promo code validations");
        requiredFields = ['campaignName', 'campaignTotalBudget', 'rewardType', 'couponName', 'couponTotalAmountUser', 'numberofCoupons', 'valueperCoupon', 'minOrderValuePerCoupon', 'couponValidityDays', 'couponDescription', 'promoCode', 'campaignTimeDateRangeStart', 'termsAndConditions', 'campaignTimeDateRangeEnd']

        requiredFields.forEach(field => {
            if (!values[field] && field !==  'promoCode') {
                errors[field] = 'Required'
            }
    
            if (!values[field] && field === 'promoCode') {
                if (arrayList && arrayList !==  "" && arrayList.length && arrayList !==  undefined) {
                    errors[field] = null;
                } else {
                    errors[field] = 'Promo Code Required'
                }
            }
        }); //4284
    }

    if(values.campaignCodes === "promoCode"){
        if (values.campaignTotalBudget) {
            if (values.campaignTotalBudget < 1 || !/^[0-9]+$/i.test(values.campaignTotalBudget) ) {
                errors.campaignTotalBudget = 'Invalid Amount'
            }
        }
    }
    if (values.campaignTimeDateRangeStart) {
        if (values.campaignTimeDateRangeStart < dateCurrent ) {
            errors.campaignTimeDateRangeStart = 'Campaign Start date cannot be less than current date'
        }
    }

    if (values.campaignCodes === "offerCode") {
        // console.log("inside if condition");
        // console.log("validate values: ",values);
        if (values.infinityCheck === true) {
            // console.log("flag is true----")
            errors.campaignTimeDateRangeEnd = "";
        } else {
            // console.log("flag is false----")
            if (values.campaignTimeDateRangeEnd) {
                if (values.campaignTimeDateRangeEnd < dateCurrent || values.campaignTimeDateRangeEnd < values.campaignTimeDateRangeStart ) {
                    errors.campaignTimeDateRangeEnd = 'Campaign End date cannot be less than current date or start date'
                }
            }
        }
    } else {
        // console.log("inside else condition")
        if (values.campaignTimeDateRangeEnd) {
            if (values.campaignTimeDateRangeEnd < dateCurrent || values.campaignTimeDateRangeEnd < values.campaignTimeDateRangeStart ) {
                errors.campaignTimeDateRangeEnd = 'Campaign End date cannot be less than current date or start date'
            }
        }
    }
    
    if (values.couponName) {
        // if (!/^[A-Za-z0-9]+$/i.test(values.couponName) ) {
        //     errors.couponName = 'Enter Valid Coupon Name'
        // }
        // /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+$/g
        // if (/[`~!$%^*()={}|\\?:;"'<,>_@./#&+-\[\]]+$/g.test(values.couponName)) {
        //     errors.couponName = 'Coupon Name cannot contain special characters'
        // }
        if (values.couponName.trim().length === 0) {
            errors.couponName = 'Required'
        }
    }
    
    if (values.couponTotalAmountUser) {
        if (values.couponTotalAmountUser < 1 || !/^[0-9]+$/i.test(values.couponTotalAmountUser) ) {
            errors.couponTotalAmountUser = 'Invalid Amount'
        }
    }
    if (values.numberofCoupons) {
        if (values.numberofCoupons < 1 || !/^[0-9]+$/i.test(values.numberofCoupons) ) {
            errors.numberofCoupons = 'Invalid Number of Coupons'
        }
    }
    if (values.valueperCoupon) {
        if (values.valueperCoupon < 0 || !/^[0-9]+$/i.test(values.valueperCoupon) ) {
            errors.valueperCoupon = 'Invalid Amount'
        }
        if (values.minOrderValuePerCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value should be greater than Coupon Amount'
        }
    }
    errors.valueperCoupon=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.numberofCoupons && !values.valueperCoupon)
        errors.numberofCoupons=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if(!errors.couponTotalAmountUser && !values.valueperCoupon)
        errors.couponTotalAmountUser=validateValuePerCoupon(values.couponTotalAmountUser,values.numberofCoupons);
    
    if (values.minOrderValuePerCoupon) {
        if (values.minOrderValuePerCoupon < 0 || !/^[0-9]+$/i.test(values.minOrderValuePerCoupon) ) {
            errors.minOrderValuePerCoupon = 'Invalid Amount'
        }
        if (values.valueperCoupon && parseInt(values.valueperCoupon) >= parseInt(values.minOrderValuePerCoupon)) {
            errors.minOrderValuePerCoupon = 'Min. Order Value should be greater than Coupon Amount'
        }
    }
    if (values.couponValidityDays) {
        if (values.couponValidityDays < 0 || !/^[0-9]+$/i.test(values.couponValidityDays) ) {
            errors.couponValidityDays = 'Invalid Number of Days'
        }
    }

    // console.log("errors---->",errors)
    return errors
}

const makeMapStateToProps = () => {
    const mapStateToProps = state => {
        // console.log("state======>",state)
        let numberofCoupons = state.form?.CampaignAddForm?.values?.numberofCoupons 
        let couponTotalAmountUser = state.form?.CampaignAddForm?.values?.couponTotalAmountUser 
        let infinityCheckFlag = state.form?.CampaignAddForm?.values?.infinityCheckFlag 
      return {
        numberofCoupons: numberofCoupons,
        couponTotalAmountUser:couponTotalAmountUser,
        initialValues: {},
        infinityCheckFlag: infinityCheckFlag
      }
    }
    return mapStateToProps
  }

campaignAddForm = reduxForm(
    {
        form: 'CampaignAddForm',
        validate
    },
    makeMapStateToProps
  )(withStyles(styles)(campaignAddForm))

campaignAddForm= connect(makeMapStateToProps, {checkPromoCode})(campaignAddForm)

export default campaignAddForm