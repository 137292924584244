import {
    SET_AREA_MANAGERS,
    SET_AREA_MANAGER_SUCCESS_MESSAGE,
    SET_AREA_MANAGER_ERROR_MESSAGE,
    REQUEST_AREA_MANAGERS
} from "../actions/types";

const INITIAL_STATE = {
    areaManagers: {},
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false
};

export default function areaManagerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_AREA_MANAGERS:
            return { ...state, isFetching: action.payload };
        case SET_AREA_MANAGERS:
            return { ...state, areaManagers: action.payload, isFetching: false };
        case SET_AREA_MANAGER_SUCCESS_MESSAGE:
            return {
                ...state,
                // sucessMsg: action.payload,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                // isFetching: false
            };
        case SET_AREA_MANAGER_ERROR_MESSAGE:
            return {
                ...state,
                // errorMsg: action.payload,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                // isFetching: false
            };
        default:
            return state;
    }
}