import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { changeHeaderName } from '../../actions/dashboardActions';
import { getFaqsByUsertype, addFaq, editFaq, deleteFaq, requestFaqs, clearMsg } from '../../actions/faqActions';
import FaqForm from './FaqForm';

const styles = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	container: {
		width: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	head: {
		fontWeight: 700
	},
	button: {
		marginTop: theme.spacing(3)
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '40%',
		height: '70%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	root: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	section1: {
		margin: theme.spacing(3, 2),
	},
	section2: {
		margin: theme.spacing(2),
	},
	section3: {
		margin: theme.spacing(3, 1, 1),
	},
	deleteBtn: {
		cursor: 'pointer',
		color: 'grey',
		marginTop: "7px"
	},
	editBtn: {
		cursor: 'pointer',
		color: 'grey',
		marginTop: "7px"
	},
	expandBtn: {
		cursor: 'pointer',
		color: 'grey',
		marginTop: "7px"
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
});


class Faq extends Component {

	constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			selectedTab: "general",
			selectedFaq: "",
			modalDeleteOpen: false,
			modalEditOpen: false,
			sucessMsg: null,
			errorMsg: null
		};
	}

	componentDidMount() {
		this.props.changeHeaderName("FAQ");
		this.props.clearMsg();
		this.getFaqsByUsertype();
		this.setState({ errorMsg: null });
		this.setState({ sucessMsg: null });
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevState.sucessMsg === null && this.state.sucessMsg) ||
			(prevState.errorMsg === null && this.state.errorMsg)) {
			this.setState({ snackOpen: true });
		}

		if (prevState.sucessMsg !== this.state.sucessMsg) {
			this.getFaqsByUsertype();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.faq.sucessMsg !== prevState.sucessMsg) {
			return { sucessMsg: nextProps.faq.sucessMsg };
		} else if (nextProps.faq.errorMsg !== prevState.errorMsg) {
			return { errorMsg: nextProps.faq.errorMsg };
		}
	}

	getFaqsByUsertype = () => {
		this.props.getFaqsByUsertype(this.state.selectedTab);
	}

	handleModalOpen = () => {
		this.setState({ modalOpen: true });
	}

	handleModalClose = () => {
		this.setState({ modalOpen: false });
	}

	handleSnackClose = () => {
		this.props.clearMsg();
		this.setState({
			snackOpen: false,
			modalOpen: false,
			modalDeleteOpen: false,
			faqToDelete: "",
			errorMsg: null,
			sucessMsg: null
		});
		this.getFaqsByUsertype();
	}

	addFaq = (props) => {
		this.props.requestFaqs();
		this.props.addFaq(props);
		this.handleModalClose();
	}

	handleTabChange = (e, value) => {
		this.props.requestFaqs();
		this.setState({
			selectedTab: value
		}, () => { this.getFaqsByUsertype() });
	}

	handleExpand = (faq) => {
		this.setState({
			selectedFaq: faq._id
		});
	}

	handleCollapse = () => {
		this.setState({
			selectedFaq: ""
		});
	}

	deleteFAQ = () => {
		this.props.requestFaqs();
		this.props.deleteFaq(this.state.faqToDelete, this.props.user.id);
		this.handleDialogueDeleteClose();
	}

	handleDialogueDeleteOpen = (faq) => {
		this.setState({
			modalDeleteOpen: true,
			faqToDelete: faq._id
		});
	}

	handleDialogueDeleteClose = () => {
		this.setState({ modalDeleteOpen: false, faqToDelete: "" });
	}

	handleEditModalOpen = (faq) => {
		// const sampleMarkup = faq.qanda.answer;
		// const contentHTML = convertFromHTML(sampleMarkup)
		// const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap)
		// faq.qanda.answer = JSON.stringify(convertToRaw(state))
		this.setState({ modalEditOpen: true, faqToEdit: faq, qandaToEdit: faq.qanda });
	}

	handleEditModalClose = () => {
		this.setState({ modalEditOpen: false });
	}

	editFaq = (props) => {
		props.userId = this.props.user.id;
		this.props.requestFaqs();
		this.props.editFaq(props);
		this.handleEditModalClose();
	}

	render() {
		const { classes } = this.props;
		let muiAlert = null

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

		return (
			<Container maxWidth="lg">
				<div className={classes.appBarSpacer} />
				<Container>
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						startIcon={<AddIcon />}
						onClick={() => this.handleModalOpen()}
						style={{ cursor: 'pointer' }}
					>
						Add
          			</Button>
					<Modal
						className={classes.modal}
						open={this.state.modalOpen}
						onClose={this.handleModalClose}
						closeAfterTransition>
						<Container className={classes.modalContainer}>
							<FaqForm onSubmit={this.addFaq} selectedTab={this.state.selectedTab} onClose={this.handleModalClose} />
						</Container>
					</Modal>
				</Container>
				<Container className={classes.container}>
					<AppBar position="static">
						<Tabs value={this.state.selectedTab} onChange={this.handleTabChange} aria-label="simple tabs example">
							<Tab value="general" label="General" />
							<Tab value="consumer" label="Consumer" />
							<Tab value="serviceprovider" label="Business" />
						</Tabs>
					</AppBar>
					{
						this.props.faq.faqList.map(faq => {
							return (
								<div className={classes.root}>
									<div className={classes.section1}>
										<Grid container alignItems="center">
											<Grid item xs>
												<Typography gutterBottom color="textSecondary" variant="body3">
													Question
            									</Typography>
											</Grid>
											<Grid item>
												<span style={{ padding: '4px' }}><DeleteIcon className={classes.deleteBtn} onClick={() => this.handleDialogueDeleteOpen(faq)} /></span>
												<span style={{ padding: '4px' }}><EditIcon className={classes.editBtn} onClick={() => this.handleEditModalOpen(faq)} /></span>
												{
													this.state.selectedFaq.toString() === faq._id.toString() ?
														<span style={{ padding: '4px' }}><ExpandLessIcon className={classes.expandBtn} onClick={this.handleCollapse} /></span>
														:
														<span style={{ padding: '4px' }}><ExpandMoreIcon className={classes.expandBtn} onClick={() => this.handleExpand(faq)} /></span>
												}

											</Grid>
										</Grid>
										<Typography variant="body2">
											{faq.qanda.question}
										</Typography>
									</div>
									<Divider variant="middle" />
									{
										this.state.selectedFaq.toString() === faq._id.toString() ?
											<div className={classes.section2}>
												<Typography gutterBottom color="textSecondary" variant="body3">
													Answer
        										</Typography>
												<div>
													<Typography variant="body2">
														<div dangerouslySetInnerHTML={{ __html: faq.qanda.answer }} />
													</Typography>
												</div>
											</div> : null
									}
								</div>
							);
						})
					}
				</Container>

				<Modal
					editForm
					className={classes.modal}
					open={this.state.modalEditOpen}
					onClose={this.handleEditModalClose}
					closeAfterTransition>
					<Container className={classes.modalContainer}>
						<FaqForm
							onSubmit={this.editFaq}
							editForm={true}
							faq={this.state.faqToEdit}
							initialValues={_.pick(this.state.qandaToEdit, 'question', 'answer')}
							onClose={this.handleEditModalClose}
						/>
					</Container>
				</Modal>

				<Dialog
					open={this.state.modalDeleteOpen}
					onClose={this.handleDialogueDeleteClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">Delete FAQ</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Do you want to delete this question?
            			</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleDialogueDeleteClose} color="primary">
							No
            			</Button>
						<Button onClick={() => this.deleteFAQ()} color="primary" autoFocus>
							Yes
            			</Button>
					</DialogActions>
				</Dialog>

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.snackOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}>
					{muiAlert}
				</Snackbar>

				<Backdrop className={classes.backdrop} open={this.props.faq.isFetching}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Container>
		);
	}
}


const mapStateToProps = state => {
	return {
		faq: state.faq,
		user: state.auth.user
	}
};
export default connect(mapStateToProps, { changeHeaderName, getFaqsByUsertype, addFaq, editFaq, deleteFaq, requestFaqs, clearMsg })(withStyles(styles)(Faq));
