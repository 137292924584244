import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { getBusinessTagList} from "../../actions/businessTagAction";
const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    marginTop: "100px",
    width: "100%",
    height: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    height: "60px",
    marginBottom: "25px",
  },
  paper2: {
    padding: "0px 0px",
    display: "flex",
    alignItems: "center",
    height: "150px",
    marginBottom: "25px",
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    flexDirection: "column",
    width: "80%",
    height: "150%",
  },
  form: {
    //   width: '70%',
    //   height: '170%',
    // width: '100%',
    // height: '100%',
    marginTop: "300px",
    width: "45%",
    height: "200%",
  },
  header: {
    //   height: '9%',
    // height: "7%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.primary.main,
  },
  headerWhite: {
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    padding: "10px",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  content: {
    // height: "60%",
    // margin: '10px 0px',
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: "100%",
    height: "100%",
  },

  gridElement: {
    margin: "10px 5px",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  icon: {
    color: "white",
  },
  buttonGroup: {
    // "height": "40px",
    // "marginTop": "2px",
    // "width": "0px"
    height: "39px",
    marginTop: "8px",
    width: "0px",
    // "marginRight":"1px"
    marginLeft: "8px",
  },

  gridborder: {
    border: "1px solid black",
    borderRadius: "25px",
    alignSelf: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "10px",
  },

  headGridThree: {
    float: "right",
    alignSelf: "center",
    alignItems: "center",
    marginLeft: "8px",
    marginRight: "4px",
    fontSize: "20px",
  },
});

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AssignBusinessModal(props) {
  const { classes } = props;
  const [categoryList, setCategoryList] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [displayList, setDisplayBusinessList] = useState([]);
  const [selectAll, setSelectAll] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [count, setCount] = useState(0);
  const [unselectedCount, setUnselectedCount] = useState(0);
  const [showSelectedBusinesses, setShowSelectedBusinesses] = useState(false);
  const [showUnselectedBusinesses, setShowUnselectedBusinesses] =
    useState(false);
    let [businessTags, setBusinessTags] = useState([]);
    let [filteredBusinessTags, setFilteredBusinessTags] = useState([]);
    

  useEffect(() => {
    setCategoryList(props.categoryList);
  
  }, [props.categoryList]);

  useEffect(() => {
    fetchBusinessTags();
  }, []);

  useEffect(() => {
    // console.log("inside useEffect------");
    // console.log("business list----",props.businessList)
    let sortedArray =[];

      sortedArray =props.businessList &&
      props.businessList.length > 0 &&
      props.businessList.map((ele) => {
        // console.log("business element---->",ele);
        let selectedEntry =
          props.selectedCampaignBusinessList &&
          props.selectedCampaignBusinessList.length > 0 &&
          props.selectedCampaignBusinessList.filter((item) => {
            // console.log("single item------>",item);
            // console.log("business element---->",ele);
            if (ele.businessId === item.businessId) {
              return item;
            }
          });
          // console.log("selectedEntry------>",selectedEntry);
        if (selectedEntry && selectedEntry[0]) {
          return selectedEntry[0];
        } else {
          return {
            businessId: ele.businessId,
            isSelected: false,
            businessName: ele.businessName,
          };
        }
      });
      // console.log("sortedArray------>",sortedArray);
    let tempDisplayList = sortedArray;
    if (searchText) {
      tempDisplayList = sortedArray.map((ele) => {
        if (
          ele.businessId.toUpperCase().includes(searchText.toUpperCase()) ||
          ele.businessName.toUpperCase().includes(searchText.toUpperCase())
        ) {
          ele.shouldNotDisplay = false;
        } else ele.shouldNotDisplay = true;
        return ele;
      });
    }

    setDisplayBusinessList(tempDisplayList);
    const isAllSelected =
      tempDisplayList && tempDisplayList?.every((ele) => ele.isSelected);
    setSelectAll(isAllSelected);

    // let filteredArray = props.selectedCampaignBusinessList.filter((ele) => {
      let filteredArray=[]
      if(tempDisplayList?.length){
        filteredArray = tempDisplayList?.filter((ele) => {
          return ele.isSelected;
        });

      }
    
    if (filteredArray) setCount(filteredArray.length);
    else setCount(0);
    let unSelectedFilteredArray=[]
    if(tempDisplayList?.length){
      unSelectedFilteredArray = tempDisplayList?.filter((ele) => {
        return !ele.isSelected;
      });
    }
    
    if (unSelectedFilteredArray)
      setUnselectedCount(unSelectedFilteredArray.length);
    else setUnselectedCount(0);
  }, [props.businessList, props.selectedCampaignBusinessList]);

  const fetchBusinessTags=async()=>{
    const response=await getBusinessTagList();
    if(response){
      setBusinessTags(response.data)
    }else{
      setBusinessTags([])
    }
    
}
  const handleSelectToggleForItems = (value, index, e) => {
    let tempArray = [...displayList];
    let itemIndex = tempArray.findIndex(
      (ele) => ele.businessId === value.businessId
    );
    tempArray[itemIndex].isSelected = e.target.checked;
    let arr = [...props.selectedCampaignBusinessList, value];

    const uniqueObjects = [
      ...new Map(arr.map((item) => [item.businessId, item])).values(),
    ];
    // console.log(uniqueObjects);
    props.setSelectedCampaignBusinessList(uniqueObjects);
  };

  const handleSelectAllClick = (e) => {
    let tempArray = displayList.map((ele) => {
      if (!ele.shouldNotDisplay) {
        ele.isSelected = e.target.checked;
      }
      return ele;
    });
    setSelectAll(e.target.checked);

    let arr = [...props.selectedCampaignBusinessList, ...tempArray];
    const uniqueObjects = [
      ...new Map(arr.map((item) => [item.businessId, item])).values(),
    ];
    props.setSelectedCampaignBusinessList(uniqueObjects);
  };

  const onSearchTextChange = (value) => {
    setSearchText(value);
    let tempDisplayList = displayList.map((ele) => {
      if (
        ele.businessId.toUpperCase().includes(value.toUpperCase()) ||
        ele.businessName.toUpperCase().includes(value.toUpperCase())
      ) {
        ele.shouldNotDisplay = false;
      } else ele.shouldNotDisplay = true;
      return ele;
    });
    setDisplayBusinessList(tempDisplayList);
  };

  const fetchBusinessWithFilter = async () => {
    setIsFetching(true);
    var myVar2 = categoryFilter.join(", ");
    let filteredBusinessTagsVar = filteredBusinessTags.join(', ');
    let payload = {
      category: myVar2,
      filteredBusinessTags:filteredBusinessTagsVar
    };
    await props.fetchBusinesses(payload);
    setIsFetching(false);
  };

  const resetBusinessFilter = async () => {
    setIsFetching(true);
    setCategoryFilter([]);
    setSearchText("");
    setFilteredBusinessTags([]);
    await props.fetchBusinesses();
    setIsFetching(false);
  };

  return (
    <div className={classes.modal}>
      <Grid container className={classes.header} spacing={3}>
        <Grid item xs={8}>
          <WhiteTextTypography variant="h6" color="inherit" gutterBottom>
            Assign Business
          </WhiteTextTypography>
        </Grid>
        <Grid item xs={4}>
          <WhiteTextTypography variant="h6" color="inherit" gutterBottom>
            <CloseIcon
              className={classes.headGridTwo}
              onClick={() => {
                props.onClose();
                resetBusinessFilter();
              }}
            />
          </WhiteTextTypography>
        </Grid>
      </Grid>
      <Container className={classes.content}>
        <Grid
          container
          className={classes.grid}
          spacing={1}
          justify="left"
          alignItems="left"
        >
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" gutterBottom>
              {props.selectedCampaign.campaignName +
                " ( " +
                props.selectedCampaign.campaignId +
                " ) "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
              style={{marginTop:"5px"}}
            >
              <InputLabel id="weeklyDays">Select Category</InputLabel>
              <Select
                labelId="weeklyDays"
                multiple
                value={categoryFilter}
                name="categoryFilter"
                onChange={(e) => setCategoryFilter(e.target.value)}
                input={<Input />}
                renderValue={(selected) => selected.join(",")}
                MenuProps={MenuProps}
              >
                {categoryList &&
                  categoryList.map((category) => (
                    <MenuItem
                      key={category._id}
                      value={category.name}
                      style={{
                        maxWidth: "100%",
                        overflow: "hidden",
                        wordWrap: "normal",
                        whiteSpace: "normal",
                      }}
                    >
                      <Checkbox
                        checked={
                          categoryFilter &&
                          categoryFilter.indexOf(category.name) > -1
                        }
                      />
                      <ListItemText primary={category.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                          <FormControl variant="outlined" fullWidth className={classes.formControl} style={{marginTop:"5px"}}>
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={(e) => setFilteredBusinessTags(e.target.value)}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {businessTags.map((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              placeholder="Business Name/ID"
              fullWidth
              margin="dense"
              name="BusinessNameID"
              value={searchText}
              variant="outlined"
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
            />
          </Grid>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                margin="normal"
                onClick={() => resetBusinessFilter()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                margin="normal"
                color="primary"
                onClick={() => fetchBusinessWithFilter()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                gutterBottom
              >{`Selected Business Count (${count})`}</Typography>
            </Grid>
            {showUnselectedBusinesses ? null : (
              <>
                <Grid item xs={1}>
                  <Checkbox
                    edge="start"
                    checked={showSelectedBusinesses}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": "selectAll" }}
                    onClick={(e) => setShowSelectedBusinesses(e.target.checked)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                  >{`Show Only Selected Businesses`}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid container spacing={2} xs={12}>
            <Grid item xs={7} style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                gutterBottom
              >{`Unselected Business Count (${unselectedCount})`}</Typography>
            </Grid>
            {showSelectedBusinesses ? null : (
              <>
                <Grid item xs={1}>
                  <Checkbox
                    edge="start"
                    checked={showUnselectedBusinesses}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": "selectAll" }}
                    onClick={(e) =>
                      setShowUnselectedBusinesses(e.target.checked)
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                  >{`Show Only Unselected Businesses`}</Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <List
              sx={{
                width: "100%",
                maxWidth: 360,
                bgcolor: "background.paper",
                position: "relative",
                overflow: "scroll",
                maxHeight: 300,
              }}
            >
              {!showSelectedBusinesses && !showUnselectedBusinesses && (
                <ListItem key={"selectAll"} disablePadding>
                  <Checkbox
                    edge="start"
                    checked={selectAll}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": "selectAll" }}
                    onClick={(e) => handleSelectAllClick(e)}
                  />
                  <ListItemText id={"selectAll"} primary={`Select All`} />
                </ListItem>
              )}
              {showSelectedBusinesses &&
                displayList &&
                displayList.length > 0 &&
                displayList
                  .filter((ele) => ele.isSelected)
                  .map((value, index) => {
                    // console.log(value)
                    const labelId = `checkbox-list-label-${value}`;

                    if (value.shouldNotDisplay) return null;
                    return (
                      <ListItem key={value.businessId + index} disablePadding>
                        <Checkbox
                          edge="start"
                          checked={value.isSelected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(e) =>
                            handleSelectToggleForItems(value, index, e)
                          }
                        />
                        <ListItemText
                          id={labelId}
                          primary={`(${value.businessId}) ${value.businessName}`}
                        />
                      </ListItem>
                    );
                  })}
              {showUnselectedBusinesses &&
                displayList &&
                displayList.length > 0 &&
                displayList
                  .filter((ele) => !ele.isSelected)
                  .map((value, index) => {
                    const labelId = `checkbox-list-label-${value}`;

                    if (value.shouldNotDisplay) return null;
                    return (
                      <ListItem key={value.businessId + index} disablePadding>
                        <Checkbox
                          edge="start"
                          checked={value.isSelected}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(e) =>
                            handleSelectToggleForItems(value, index, e)
                          }
                        />
                        <ListItemText
                          id={labelId}
                          primary={`(${value.businessId}) ${value.businessName}`}
                        />
                      </ListItem>
                    );
                  })}
              {!showSelectedBusinesses &&
                !showUnselectedBusinesses &&
                displayList &&
                displayList.length > 0 &&
                displayList.map((value, index) => {
                  const labelId = `checkbox-list-label-${value}`;

                  if (value.shouldNotDisplay) return null;
                  return (
                    <ListItem key={value.businessId + index} disablePadding>
                      <Checkbox
                        edge="start"
                        checked={value.isSelected}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        onClick={(e) =>
                          handleSelectToggleForItems(value, index, e)
                        }
                      />
                      <ListItemText
                        id={labelId}
                        primary={`(${value.businessId}) ${value.businessName}`}
                      />
                    </ListItem>
                  );
                })}
            </List>
          </Grid>
        </Grid>
      </Container>
      <Grid container className={classes.headerWhite} spacing={3}>
        <Grid container spacing={2} xs={12}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              onClick={() => {
                resetBusinessFilter();
                props.onClose();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              margin="normal"
              color="primary"
              onClick={() => {
                props.onSave(props.selectedCampaign._id);
                resetBusinessFilter();
                props.onClose();
              }}
            >
              Edit/Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default withStyles(styles)(AssignBusinessModal);
