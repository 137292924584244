import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import moment from 'moment';

const headCells = [
    { id: 'calledTimeStamp', disablePadding: false, label: 'Called Time Stamp' },
    { id: 'orderId', disablePadding: false, label: 'Order ID' },
    { id: 'caller', disablePadding: false, label: 'Caller' },
    { id: 'callerId', disablePadding: false, label: 'Caller ID' },
    { id: 'callerName', disablePadding: false, label: 'Caller Name' },
    { id: 'callerContactNo', disablePadding: false, label: 'Caller Contact No.' },
    { id: 'screenName', disablePadding: false, label: 'Screen Name' },
    { id: 'receiver', disablePadding: false, label: 'Receiver' },
    { id: 'receiverId', disablePadding: false, label: 'Receiver ID' },
    { id: 'receiverName', disablePadding: false, label: 'Receiver Name' },
    { id: 'receiverContactNo', disablePadding: false, label: 'Receiver Contact No.' },
    { id: 'categoryType', disablePadding: false, label: 'Category Type' },
];

function EnhancedTableHead(props) {
    const {classes} = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Call Icon Hit Report ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"call_icon_hit_report.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                : <Button
                    variant="contained"
                    margin="normal"
                    style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                    onClick={props.fetchCallIconHitRecordsExcel}
                    >
                    Export
                </Button>
                } 
            </Typography> 
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px",
        textAlign: 'left',
    },
    tableRow: {
        // cursor: 'pointer'
    },
  
    tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", 
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 300,
        minWidth:200,
        width: 700,
    },
    tableCellSmall14: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px"
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    // console.log("in table code");
    // console.log("props--->",props)

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'callTimeStamp', label: 'Called Time Stamp' },
        { key: 'orderId', label: 'Order ID' },
        { key: 'caller', label: 'Caller' },
        { key: 'callerId', label: 'Caller ID' },
        { key: 'callerName', label: 'Caller Name' },
        { key: 'callerContactNo', label: 'Caller Contact No.' },
        { key: 'screenName', label: 'Screen Name' },
        { key: 'receiver', label: 'Receiver' },
        { key: 'receiverId', label: 'Receiver ID' },
        { key: 'receiverName', label: 'Receiver Name' },
        { key: 'receiverContactNo', label: 'Receiver Contact No.' },
        { key: 'categoryData', label: 'Category Type' },
    ];

    let data = []
    props.exportToExcel && props.exportToExcel.forEach(element => {
        /* eslint-disable no-self-assign */
        let item = element
        item.callTimeStamp = item.callTimeStamp;
        item.orderId = item.orderId;
        item.caller = item.caller;
        item.callerId = item.callerId;
        item.callerName = item.callerName;
        item.callerContactNo = item.callerContactNo;
        item.screenName = item.screenName;
        item.receiver = item.receiver;
        item.receiverId = item.receiverId;
        item.receiverName = item.receiverName;
        item.receiverContactNo = item.receiverContactNo;
        item.categoryData = item.categoryData;
        /* eslint-enable no-self-assign */
        data.push(item)
    });

    let totalCount = props.totalCount;
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.totalCount - props.page * props.rowsPerPage);
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    headers={headers}
                    totalCount={props.totalCount}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchCallIconHitRecordsExcel={props.fetchCallIconHitRecordsExcel}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell align="left" className={classes.tableCellSmall13} >{moment(row.callTimeStamp).format('DD-MMM-YYYY hh:mm:ss A')}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall} >{row.orderId && row.orderId!==null && row.orderId!=="" ? row.orderId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.caller}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.callerId && row.callerId!==null && row.callerId!=="" ? row.callerId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall14} >{row.callerName && row.callerName!==null && row.callerName!=="" && row.callerName!==undefined ? row.callerName : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.callerContactNo && row.callerContactNo!==null && row.callerContactNo!==undefined ? row.callerContactNo : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall14} >{row.screenName}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.receiver}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.receiverId && row.receiverId!==null && row.receiverId!=="" && row.receiverId!==undefined ? row.receiverId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall14} >{row.receiverName && row.receiverName!==null && row.receiverName!=="" && row.receiverName!==undefined ? row.receiverName : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.receiverContactNo && row.receiverContactNo!==null && row.receiverContactNo!=="" && row.receiverContactNo!==undefined ? row.receiverContactNo : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.categoryData.name}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}