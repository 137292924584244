import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import "bootstrap-daterangepicker/daterangepicker.css";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Checkbox from '@material-ui/core/Checkbox';
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
// import Checkbox from '@material-ui/core/Checkbox';
import { changeHeaderName } from "../../actions/dashboardActions";
import {
  requestPaymentSummary,
  getPaymentSummary,
  getPaymentSummaryExcel,
  getCashFreeSettlementData,
  changeAPStatus,
  clearMsg,
  addTRNotes,
  getGMVValue
} from "../../actions/paymentSummaryActions";
import PaymentSummaryTable from "./PaymentSummaryTable";
import RequestStatusMultiSelectDropdown from "./RequestStatusMultiSelectDropdown";
import PaymentStatusMultiSelectDropdown from "./PaymentStatusMultiSelectDropdown";
// import { red } from "@material-ui/core/colors";
import { getCategoriesByType } from '../../actions/categoriesActions';
import CategoriesMultiSelectDropdown from '../AllOrders/CategoriesMultiSelectDropdown';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Modal from '@material-ui/core/Modal';
import AddTRNotesForm from './AddTRNotesForm';
import _ from 'lodash';
import { getConsumerTagList} from "../../actions/consumerTagAction";
import { getBusinessTagList} from "../../actions/businessTagAction";

const styles = (theme) => ({
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    height: "100%",
    // overflow: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px'
  },
  modalContainer: {
    width: '30%',
    height: '43%',
    backgroundColor: theme.palette.background.paper,
    padding: '0px 0px',
  },
});
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class PaymentSummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: -1,
      orderBy: "dateOfInvite",
      page: 0,
      rowsPerPage: 10,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
      },
      preparexportToExcel: false,
      requestDate: "",
      serviceRequestId: "",
      consumerId: "",
      consumerName: "",
      cashfreeVendorId: "",
      businessId: "",
      businessName: "",
      location: "",
      currentStatus: [],
      orderTotalBeforeDisc: null,
      itemLevelDisc: null,
      cartDisc: null,
      couponAmount: null,
      couponCode: "",
      netDiscPrice: null,
      totalAdjustments: null,
      taxableValue: null,
      gstRate: "",
      cgst: null,
      sgst: null,
      igst: null,
      consumerConvFees: null,
      deliveryCharges: null,
      invoiceValToCust: null,
      businessConvFees: null,
      totalChargesToXirify: null,
      orderCancelation: "",
      finalPayToBusiness: null,
      isAdvancePaymentToBusiness :"",
      advancePaid :"",
      paymentStatus: [],
      paymentMethod: "",
      paidMarkedBy: "",
      paymentDate: "",
      paymentMode: "",
      comments: "",
      settlementDate: "",
      pgServiceCharge: null,
      pgServiceTax: null,
      pgSplitServiceCharge: null,
      pgSplitServiceTax: null,
      netSettlementAmount: null,
      utrId: "",
      balPayToXirify: null,
      balPayToBusiness: null,
      requestStartDate: "",
      requestEndDate: "",
      paymentStartDate: "",
      paymentEndDate: "",
      settlementStartDate: "",
      settlementEndDate: "",
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      settlementFromDate: "",
      settlementFromTime: "",
      settlementToDate: "",
      settlementToTime: "",
      warningText: "",
      warnings: {
        paymentFromDate: "",
        paymentFromTime: "",
        paymentToDate: "",
        paymentToTime: "",
        settlementFromDate: "",
        settlementFromTime: "",
        settlementToDate: "",
        settlementToTime: "",
        paymentMethod:""
      },
      categories: [],
      selectedCategories: [],
      filteredCategoryIds: [],
      APconfirmationFlag: false,
      APOrdersList: [],
      isChecked : false,
      confirmAdvancePaymentFlag: false,
      isAdvancePaymentDoneToBusiness: "",
      snackOpen: false,     
      modalOpen:false,
      addMode:false,
      NoteAdd:null,
      trNote:"",
      TRNoteconfirmationFlag:false,
      selectedRow: null,
      consumerTags:[],
      businessTags:[],
      filteredConsumerTags:[],
      filteredBusinessTags:[],
      APOrdersLengthCheck: false,
      transactionNotes: "",
      totalGMVWithoutFilter: null,
      checkFlagForGMV: true,
      handleGMVFlag: false,
      prePaymentOrders : "",
      refundApplied: ""
      
    };
  }
 
 async componentDidMount () {
    this.props.changeHeaderName("Transaction Report");
    this.fetchPaymentSummary();
    this.props.getCategoriesByType("category");
    this.fetchConsumerTags();
    this.fetchBusinessTags();
  
  }
  componentDidUpdate(prevProps, prevState){
    if(this.props.isFetching ===false){
      if (this.props.APSuccessMsg && this.props.APSuccessMsg !== undefined && this.props.APSuccessMsg !== null) {
          this.setState({ sucessMsg: this.props.APSuccessMsg, snackOpen: true })
            this.props.clearMsg();
           
        }
        if (this.props.APErrorMsg && this.props.APErrorMsg !== undefined && this.props.APErrorMsg !== null) {
          this.setState({ errorMsg: this.props.APErrorMsg, snackOpen: true })
           this.props.clearMsg()
        }
      }
  }

  fetchPaymentSummary = async () => {
    let flag = true;
    let warnings = {
      paymentFromDate: "",
      paymentFromTime: "",
      paymentToDate: "",
      paymentToTime: "",
      settlementFromTime: "",
      settlementToDate: "",
      settlementToTime: "",
      paymentMethod:"",    
    };
    if(!this.state.paymentMethod && (this.state.paymentMode === "DirectPayment" || this.state.paymentMode === "XirifyPayments")){
      flag=false;
      warnings.paymentMethod = "Please Select Payment Method"
    }
    if (this.state.fromDate || this.state.fromTime || this.state.toDate || this.state.toTime) {
      if (!this.state.fromDate || !this.state.fromTime || !this.state.toDate || !this.state.toTime) {
        flag = false;
        if (!this.state.fromDate) {
          warnings.paymentFromDate = "Required Payment From Date";
        }
        if (!this.state.fromTime) {
          warnings.paymentFromTime = "Required Payment From Time";
        }
        if (!this.state.toDate) {
          warnings.paymentToDate = "Required Payment To Date";
        }
        if (!this.state.toTime) {
          warnings.paymentToTime = "Required Payment To Time";
        }
        // this.setState({warningText:" Please select Payment from date, to date , from time and to time inorder to apply payment datetime filter"});
      } else if (new Date(this.state.fromDate + " " + this.state.fromTime) > new Date(this.state.toDate + " " + this.state.toTime)) {
        flag = false;
        warnings.paymentFromDate = "Payment From date should be less than Payment To date";
        warnings.paymentFromTime = "Payment From time should be less than Payment To time";
        warnings.paymentToDate = "Payment To date should be greater than Payment From date";
        warnings.paymentToTime = "Payment To time should be greater than Payment From time";
        // this.setState({warningText:"Payment from date can not be greater that payment to date"});
      }
    }
    if (this.state.settlementFromDate || this.state.settlementFromTime || this.state.settlementToDate || this.state.settlementToTime) {
      if (!this.state.settlementFromDate || !this.state.settlementFromTime || !this.state.settlementToDate || !this.state.settlementToTime) {
        flag = false;
        if (!this.state.settlementFromDate) {
          warnings.settlementFromDate = "Required Settlement From Date";
        }
        if (!this.state.settlementFromTime) {
          warnings.settlementFromTime = "Required Settlement From Time";
        }
        if (!this.state.settlementToDate) {
          warnings.settlementToDate = "Required Settlement To Date";
        }
        if (!this.state.settlementToTime) {
          warnings.settlementToTime = "Required Settlement To Time";
        }
        // this.setState({warningText:" Please select Settlement from date, to date , from time and to time inorder to apply settlement datetime filter"});
      } else if (new Date(this.state.settlementFromDate + " " + this.state.settlementFromTime) > new Date(this.state.settlementToDate + " " + this.state.settlementToTime)) {
        flag = false;
        warnings.settlementFromDate = "Settlement From date should be less than Settlement To date";
        warnings.settlementFromTime = "Settlement From time should be less than Settlement To time";
        warnings.settlementToDate = "Settlement To date should be greater than Settlement From date";
        warnings.settlementToTime = "Settlement To time should be greater than Settlement From time";
        // this.setState({warningText:"Settle,emt from date can not be greater that settlement to date"});
      }
    }
    this.setState({ warnings });
    if (flag) {
      this.setState({ warningText: "" });
      this.props.requestPaymentSummary();
      let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
      let payload = {
        orderBy: this.state.orderBy,
        order: Number(this.state.order),
        skip: this.state.page * this.state.rowsPerPage,
        limit: this.state.rowsPerPage,
        requestDate: this.state.requestDate,
        serviceRequestId: this.state.serviceRequestId.trim(),
        consumerId: this.state.consumerId.trim(),
        consumerName: this.state.consumerName.trim(),
        cashfreeVendorId: this.state.cashfreeVendorId,
        businessId: this.state.businessId.trim(),
        businessName: this.state.businessName.trim(),
        location: this.state.location,
        currentStatus: this.state.currentStatus,
        orderTotalBeforeDisc: this.state.orderTotalBeforeDisc,
        itemLevelDisc: this.state.itemLevelDisc,
        cartDisc: this.state.cartDisc,
        couponAmount: this.state.couponAmount,
        couponCode: this.state.couponCode.trim(),
        netDiscPrice: this.state.netDiscPrice,
        totalAdjustments: this.state.totalAdjustments,
        taxableValue: this.state.taxableValue,
        gstRate: this.state.gstRate,
        cgst: this.state.cgst,
        sgst: this.state.sgst,
        igst: this.state.igst,
        consumerConvFees: this.state.consumerConvFees,
        deliveryCharges: this.state.deliveryCharges,
        invoiceValToCust: this.state.invoiceValToCust,
        businessConvFees: this.state.businessConvFees,
        totalChargesToXirify: this.state.totalChargesToXirify,
        orderCancelation: this.state.orderCancelation,
        finalPayToBusiness: this.state.finalPayToBusiness,
        isAdvancePaymentToBusiness: this.state.isAdvancePaymentToBusiness,
        advancePaid: this.state.advancePaid,
        paymentStatus: this.state.paymentStatus,
        paymentMethod: this.state.paymentMethod,
        paidMarkedBy: this.state.paidMarkedBy,
        paymentDate: this.state.paymentDate,
        paymentMode: this.state.paymentMode,
        comments: this.state.comments,
        settlementDate: this.state.settlementDate,
        pgServiceCharge: this.state.pgServiceCharge,
        pgServiceTax: this.state.pgServiceTax,
        pgSplitServiceCharge: this.state.pgSplitServiceCharge,
        pgSplitServiceTax: this.state.pgSplitServiceTax,
        netSettlementAmount: this.state.netSettlementAmount,
        utrId: this.state.utrId,
        balPayToXirify: this.state.balPayToXirify,
        balPayToBusiness: this.state.balPayToBusiness,
        requestStartDate: this.state.requestStartDate,
        requestEndDate: this.state.requestEndDate,
        paymentStartDate: this.state.paymentStartDate,
        paymentEndDate: this.state.paymentEndDate,
        settlementStartDate: this.state.settlementStartDate,
        settlementEndDate: this.state.settlementEndDate,
        fromDate: this.state.fromDate,
        fromTime: this.state.fromTime,
        toDate: this.state.toDate,
        toTime: this.state.toTime,
        settlementFromDate: this.state.settlementFromDate,
        settlementFromTime: this.state.settlementFromTime,
        settlementToDate: this.state.settlementToDate,
        settlementToTime: this.state.settlementToTime,
        // eslint-disable-next-line
        categories: this.state.categories,
        // eslint-disable-next-line
        categories: this.state.filteredCategoryIds,

        exportToexcel: 0,
        isAdvancePaymentDoneToBusiness: this.state.isAdvancePaymentDoneToBusiness,
        filteredConsumerTags:filteredConsumerTagsVar,
        filteredBusinessTags:filteredBusinessTagsVar,
        transactionNotes: this.state.transactionNotes,
        prePaymentOrders: this.state.prePaymentOrders,
        refundApplied: this.state.refundApplied       
        
      };
      await this.props.getPaymentSummary(payload);
      if(this.state.handleGMVFlag) {  await this.props.getGMVValue(payload);
        this.setState({totalGMVWithoutFilter: this.props.gmvValue.totalGMVValue})
      }
     
      if (this.state.preparexportToExcel) {
        this.setState({ preparexportToExcel: false });
      }
      // this.setState({checkFlagForGMV : false}

     
     
      // if(this?.props?.gmvValue && this.props.gmvValue !== undefined && this.state.checkFlagForGMV  === true){
      //   this.setState({totalGMVWithoutFilter : this.props.gmvValue.totalGMVValue })
      // }
     
       
    }
  };

  fetchPaymentSummaryExcelFile = async () => {
    let flag = true;
    if (this.state.fromDate || this.state.fromTime || this.state.toDate || this.state.toTime) {
      if (!this.state.fromDate || !this.state.fromTime || !this.state.toDate || !this.state.toTime) {
        flag = false;
        this.setState({ warningText: " Please select Payment from date, to date , from time and to time inorder to apply payment datetime filter" });
      }
    }
    if (flag) {
      this.props.requestPaymentSummary();
      let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
      let payload = {
        orderBy: this.state.orderBy,
        order: Number(this.state.order),
        skip: this.state.page * this.state.rowsPerPage,
        limit: this.state.rowsPerPage,
        requestDate: this.state.requestDate,
        serviceRequestId: this.state.serviceRequestId.trim(),
        consumerId: this.state.consumerId.trim(),
        consumerName: this.state.consumerName.trim(),
        cashfreeVendorId: this.state.cashfreeVendorId,
        businessId: this.state.businessId.trim(),
        businessName: this.state.businessName.trim(),
        location: this.state.location,
        currentStatus: this.state.currentStatus,
        orderTotalBeforeDisc: this.state.orderTotalBeforeDisc,
        itemLevelDisc: this.state.itemLevelDisc,
        cartDisc: this.state.cartDisc,
        couponAmount: this.state.couponAmount,
        couponCode: this.state.couponCode.trim(),
        netDiscPrice: this.state.netDiscPrice,
        totalAdjustments: this.state.totalAdjustments,
        taxableValue: this.state.taxableValue,
        gstRate: this.state.gstRate,
        cgst: this.state.cgst,
        sgst: this.state.sgst,
        igst: this.state.igst,
        consumerConvFees: this.state.consumerConvFees,
        deliveryCharges: this.state.deliveryCharges,
        invoiceValToCust: this.state.invoiceValToCust,
        businessConvFees: this.state.businessConvFees,
        totalChargesToXirify: this.state.totalChargesToXirify,
        orderCancelation: this.state.orderCancelation,
        finalPayToBusiness: this.state.finalPayToBusiness,
        isAdvancePaymentToBusiness: this.state.isAdvancePaymentToBusiness,
        advancePaid: this.state.advancePaid,
        paymentStatus: this.state.paymentStatus,
        paymentMethod: this.state.paymentMethod,
        paidMarkedBy: this.state.paidMarkedBy,
        paymentDate: this.state.paymentDate,
        paymentMode: this.state.paymentMode,
        comments: this.state.comments,
        settlementDate: this.state.settlementDate,
        pgServiceCharge: this.state.pgServiceCharge,
        pgServiceTax: this.state.pgServiceTax,
        pgSplitServiceCharge: this.state.pgSplitServiceCharge,
        pgSplitServiceTax: this.state.pgSplitServiceTax,
        netSettlementAmount: this.state.netSettlementAmount,
        utrId: this.state.utrId,
        balPayToXirify: this.state.balPayToXirify,
        balPayToBusiness: this.state.balPayToBusiness,
        requestStartDate: this.state.requestStartDate,
        requestEndDate: this.state.requestEndDate,
        paymentStartDate: this.state.paymentStartDate,
        paymentEndDate: this.state.paymentEndDate,
        settlementStartDate: this.state.settlementStartDate,
        settlementEndDate: this.state.settlementEndDate,
        fromDate: this.state.fromDate,
        fromTime: this.state.fromTime,
        toDate: this.state.toDate,
        toTime: this.state.toTime,
        settlementFromDate: this.state.settlementFromDate,
        settlementFromTime: this.state.settlementFromTime,
        settlementToDate: this.state.settlementToDate,
        settlementToTime: this.state.settlementToTime,
        // eslint-disable-next-line
        categories: this.state.categories,
        // eslint-disable-next-line
        categories: this.state.filteredCategoryIds,        
        exportToexcel: 1,
        isAdvancePaymentDoneToBusiness: this.state.isAdvancePaymentDoneToBusiness,
        filteredConsumerTags:filteredConsumerTagsVar,
        filteredBusinessTags:filteredBusinessTagsVar,
        transactionNotes: this.state.transactionNotes,
        prePaymentOrders: this.state.prePaymentOrders,
        refundApplied: this.state.refundApplied     
      };
      await this.props.getPaymentSummaryExcel(payload);
      this.setState({ preparexportToExcel: true });
    }
  };
  handleSelectedAP = async (value, index, event) => {   
    let tempArray = [];
    if (event) {
      const APOrdersListArr = [];
      this.props.paymentSummary.paymentsDoneList.forEach((n) => {if(n.paymentStatus === "Advance Paid"){APOrdersListArr.push(n.serviceRequestId)}});
      tempArray = [...this.state.APOrdersList];
      if (value && value.serviceRequestId) tempArray.push(value.serviceRequestId);
      tempArray.findIndex(
        (ele) => ele.serviceRequestId === value.serviceRequestId
      );    
      await this.setState({ APOrdersList: tempArray });        
      if (APOrdersListArr.length  === tempArray.length) { this.setState({ isChecked: true }) }
    }
    else {
      tempArray = [...this.state.APOrdersList];      
      if (value && value.serviceRequestId) {
        let index = tempArray.indexOf(value.serviceRequestId);
        if (index !== -1) {
          tempArray.splice(index, 1);
        }
      }      
      this.setState({ APOrdersList: tempArray, isChecked: false });
    }
    if (!tempArray.length) this.setState({confirmAdvancePaymentFlag: false});
    if (tempArray.length) this.setState({confirmAdvancePaymentFlag: true})

  };

  isCheckedHandleChange = async(event)=>{
    await this.setState({isChecked: event.target.checked});   
    if(this.state.isChecked  === true){
      const APOrdersList = [];
      this.props.paymentSummary.paymentsDoneList.forEach((n) => {if(n.paymentStatus === "Advance Paid"){APOrdersList.push(n.serviceRequestId)}});
     await this.setSelected(APOrdersList);
     this.setState({confirmAdvancePaymentFlag: true});    
    }
    else {await this.setSelected([]);
      this.setState({confirmAdvancePaymentFlag: false})
    } 

  }
  
  setSelected = (APOrdersList) => {
		this.setState({ APOrdersList: APOrdersList });
	}
  handleDeleteClose = () => {
    this.setState({ APconfirmationFlag: false, handleGMVFlag: false });
    this.setState({TRNoteconfirmationFlag:false})
  };
 
  changeAPStatus = async (APOrdersList) => {   
    this.setState({APconfirmationFlag: false, isChecked: false, handleGMVFlag: false});
    await this.props.changeAPStatus(APOrdersList);   
    this.setState({confirmAdvancePaymentFlag: false})
     await this.fetchPaymentSummary();
  }

  fetchCashFreeSettlement = async () => {
    this.props.requestPaymentSummary();
    let payload = {
      serviceRequestId: this.state.serviceRequestId,
      // consumerId: this.state.consumerId,
      // consumerFirstName: this.state.consumerFirstName,
      // consumerLastName: this.state.consumerLastName,
      requestDate: this.state.requestDate,
      businessId: this.state.businessId,
      businessName: this.state.businessName,
      // paymentDate: this.state.paymentDate,
      // paymentMode: this.state.paymentMode,
      // paymentMethod: this.state.paymentMethod,
      requestStartDate: this.state.requestStartDate,
      requestEndDate: this.state.requestEndDate,
      paymentStartDate: this.state.paymentStartDate,
      paymentEndDate: this.state.paymentEndDate,
    };
    await this.props.getCashFreeSettlementData(payload);
    await this.fetchPaymentSummary();
  };

  setOrder = (order) => {
    this.setState({ order }, () => this.fetchPaymentSummary());
  };

  setOrderBy = (orderBy) => {
    this.setState({ orderBy }, () => this.fetchPaymentSummary());
  };

  setPage = (page) => {
    this.setState({checkFlagForGMV: false})
    this.setState({ page }, () => this.fetchPaymentSummary(), this.setState({isChecked: false}));
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchPaymentSummary(),  this.setState({isChecked: false}));
  };

  handleRequestDateRangeChange = (event, picker) => {
    let reportTimeRange =
      picker.startDate.format("DD MMM YYYY") +
      " - " +
      picker.endDate.format("DD MMM YYYY");
    picker.endDate.add(1, "days");
    this.setState({
      requestStartDate: picker.startDate.format("DD MMM YYYY"),
      requestEndDate: picker.endDate.format("DD MMM YYYY"),
    });
    let chosenLabel =
      picker.chosenLabel !== "Custom Range"
        ? picker.chosenLabel
        : reportTimeRange;
    this.setState({ requestDate: chosenLabel });
  };

  handlePaymentDateRangeChange = (event, picker) => {
    let reportTimeRange =
      picker.startDate.format("DD MMM YYYY") +
      " - " +
      picker.endDate.format("DD MMM YYYY");
    picker.endDate.add(1, "days");
    this.setState({
      paymentStartDate: picker.startDate.format("DD MMM YYYY"),
      paymentEndDate: picker.endDate.format("DD MMM YYYY"),
    });
    let chosenLabel =
      picker.chosenLabel !== "Custom Range"
        ? picker.chosenLabel
        : reportTimeRange;
    this.setState({ paymentDate: chosenLabel });
  };
  handleSettlementDateRangeChange = (event, picker) => {
    let reportTimeRange =
      picker.startDate.format("DD MMM YYYY") +
      " - " +
      picker.endDate.format("DD MMM YYYY");
    picker.endDate.add(1, "days");
    this.setState({
      settlementStartDate: picker.startDate.format("DD MMM YYYY"),
      settlementEndDate: picker.endDate.format("DD MMM YYYY"),
    });
    let chosenLabel =
      picker.chosenLabel !== "Custom Range"
        ? picker.chosenLabel
        : reportTimeRange;
    this.setState({ settlementDate: chosenLabel });
  };
  resetFilter = () => {
    this.props.requestPaymentSummary()
    this.setState(
      {
        order: -1,
        orderBy: "dateOfInvite",
        page: 0,
        rowsPerPage: 10,
        preparexportToExcel: false,
        requestDate: "",
        serviceRequestId: "",
        consumerId: "",
        consumerName: "",
        cashfreeVendorId: "",
        businessId: "",
        businessName: "",
        location: "",
        currentStatus: [],
        orderTotalBeforeDisc: null,
        itemLevelDisc: null,
        cartDisc: null,
        couponAmount: null,
        couponCode: "",
        netDiscPrice: null,
        totalAdjustments: null,
        taxableValue: null,
        gstRate: "",
        advancePaid: "",
        cgst: null,
        sgst: null,
        igst: null,
        consumerConvFees: null,
        deliveryCharges: null,
        invoiceValToCust: null,
        businessConvFees: null,
        totalChargesToXirify: null,
        orderCancelation: "",
        finalPayToBusiness: null,
        paymentStatus: [],
        paymentMethod: "",
        paidMarkedBy: "",
        paymentDate: "",
        paymentMode: "",
        comments: "",
        settlementDate: "",
        pgServiceCharge: null,
        pgServiceTax: null,
        pgSplitServiceCharge: null,
        pgSplitServiceTax: null,
        netSettlementAmount: null,
        utrId: "",
        balPayToXirify: null,
        balPayToBusiness: null,
        requestStartDate: "",
        requestEndDate: "",
        paymentStartDate: "",
        paymentEndDate: "",
        settlementStartDate: "",
        settlementEndDate: "",
        fromDate: "",
        fromTime: "",
        toDate: "",
        toTime: "",
        warningText: "",
        settlementFromDate: "",
        settlementFromTime: "",
        settlementToDate: "",
        settlementToTime: "",
        warnings: {
          paymentFromDate: "",
          paymentFromTime: "",
          paymentToDate: "",
          paymentToTime: "",
          settlementFromDate: "",
          settlementFromTime: "",
          settlementToDate: "",
          settlementToTime: "",
        },
        categories: [],
        selectedCategories: [],
        filteredCategoryIds: [],
        isChecked: false,
        isAdvancePaymentDoneToBusiness: "",
        confirmAdvancePaymentFlag: false,       
        filteredConsumerTags:[],
        filteredBusinessTags:[],
        transactionNotes: "",
        handleGMVFlag : false,
        totalGMVWithoutFilter : null,
        prePaymentOrders : "",
        refundApplied: ""
        
       },
      async () => {
      
        await this.fetchPaymentSummary();
        let payload = {
          orderBy: this.state.orderBy,
          order: Number(this.state.order),
          skip: this.state.page * this.state.rowsPerPage,
          limit: this.state.rowsPerPage,
          requestDate: this.state.requestDate,
          serviceRequestId: this.state.serviceRequestId,
          consumerId: this.state.consumerId,
          consumerName: this.state.consumerName,
          cashfreeVendorId: this.state.cashfreeVendorId,
          businessId: "",
          businessName: this.state.businessName,
          location: this.state.location,
          currentStatus: this.state.currentStatus,
          orderTotalBeforeDisc: this.state.orderTotalBeforeDisc,
          itemLevelDisc: this.state.itemLevelDisc,
          cartDisc: this.state.cartDisc,
          couponAmount: this.state.couponAmount,
          couponCode: this.state.couponCode,
          netDiscPrice: this.state.netDiscPrice,
          totalAdjustments: this.state.totalAdjustments,
          taxableValue: this.state.taxableValue,
          gstRate: this.state.gstRate,
          cgst: this.state.cgst,
          sgst: this.state.sgst,
          igst: this.state.igst,
          consumerConvFees: this.state.consumerConvFees,
          deliveryCharges: this.state.deliveryCharges,
          invoiceValToCust: this.state.invoiceValToCust,
          businessConvFees: this.state.businessConvFees,
          totalChargesToXirify: this.state.totalChargesToXirify,
          orderCancelation: this.state.orderCancelation,
          finalPayToBusiness: this.state.finalPayToBusiness,
          isAdvancePaymentToBusiness: this.state.isAdvancePaymentToBusiness,
          advancePaid: this.state.advancePaid,
          paymentStatus: this.state.paymentStatus,
          paymentMethod: this.state.paymentMethod,
          paidMarkedBy: this.state.paidMarkedBy,
          paymentDate: this.state.paymentDate,
          paymentMode: this.state.paymentMode,
          comments: this.state.comments,
          settlementDate: this.state.settlementDate,
          pgServiceCharge: this.state.pgServiceCharge,
          pgServiceTax: this.state.pgServiceTax,
          pgSplitServiceCharge: this.state.pgSplitServiceCharge,
          pgSplitServiceTax: this.state.pgSplitServiceTax,
          netSettlementAmount: this.state.netSettlementAmount,
          utrId: this.state.utrId,
          balPayToXirify: this.state.balPayToXirify,
          balPayToBusiness: this.state.balPayToBusiness,
          requestStartDate: this.state.requestStartDate,
          requestEndDate: this.state.requestEndDate,
          paymentStartDate: this.state.paymentStartDate,
          paymentEndDate: this.state.paymentEndDate,
          settlementStartDate: this.state.settlementStartDate,
          settlementEndDate: this.state.settlementEndDate,
          isAdvancePaymentDoneToBusiness: this.state.isAdvancePaymentDoneToBusiness,
          filteredConsumerTags:[],
          filteredBusinessTags:[],
          transactionNotes: this.state.transactionNotes,
          prePaymentOrders: this.state.prePaymentOrders,
          refundApplied: this.state.refundApplied
         
        };
        this.props.getPaymentSummary(payload);
      }
     
    );
    
    // if(this?.props?.gmvValue && this.props.gmvValue !== undefined && this.state.checkFlagForGMV  === true){
    //   this.setState({totalGMVWithoutFilter : this.props.gmvValue.totalGMVValue })
    // }
   
  }

  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    if(event.target.name  === "advancePaid" && event.target.value  === false){
      this.setState({isAdvancePaymentDoneToBusiness :""})
    }
    if (event.target.name === "paymentMode") {
      this.setState({ paymentMethod: "" });
    }
    if (event.target.name === "categories") {    
      let filteredCategoryIds = [];
      for (let i = 0; i < this.props.categories.length; i++) {
          for (let j = 0; j < event.target.value.length; j++) {
              const element = event.target.value[j];
              if (element.toLowerCase() === this.props.categories[i].name.toLowerCase()) {
                  filteredCategoryIds.push(this.props.categories[i]._id)
              }
          }
      }
      this.setState({
          selectedCategories: event.target.value,
          filteredCategoryIds: filteredCategoryIds
      });
  }
  };
  // Transaction report Handler start
  openAddNotesModal = (notes) => {
		this.setState({ addMode: true, NoteAdd: notes, modalOpen: true });
	};
  handleModalClose = () => {
		this.setState({ modalOpen: false, addMode: false });
	};
  addTRNotes =async (payload) => {
    await this.props.addTRNotes(payload);
    this.setState({ modalOpen: false })
    await this.fetchPaymentSummary();
    this.setState({snackOpen: true,TRNoteconfirmationFlag: false});
    this.setState({ sucessMsg: "Note updated successfully" })
	};
  deleteTRNote = async () => {
    this.setState({ trNote: "" })
    let payload = {
        _id:this.state.selectedRow._id,
        trNote: null
      }
    await this.props.addTRNotes(payload);
    this.setState({TRNoteconfirmationFlag: false})
    await this.fetchPaymentSummary();
    this.setState({ modalOpen: false ,snackOpen: true});
    this.setState({ sucessMsg: "Note Deleted successfully" })
   }

  confirmAdvancePayment = (e) =>{
   this.setState({APconfirmationFlag : true});
   
  }
  handleGMVCall = (e) =>{
    console.log("e ------------------------------->", e.target)
   this.setState({handleGMVFlag : e.target.checked});
   
  }
  handleSnackClose = async () => {
    this.setState({ snackOpen: false });
    this.setState({ sucessMsg: null });
  };
 isSelected = (_id) => this.state.APOrdersList.indexOf(_id) !== -1;

 handleDeleteOpen = (e,row) => {
  this.setState({ TRNoteconfirmationFlag: true, selectedRow: row }); };

  // Transaction report Handler End
  fetchConsumerTags=async()=>{
    const response=await getConsumerTagList();
    if(response){
    this.setState({consumerTags:response.data})
}else{
    this.setState({consumerTags:[]})
  }
}

fetchBusinessTags=async()=>{
    const response=await getBusinessTagList();
    if(response){
      this.setState({businessTags:response.data})
    }else{
      this.setState({businessTags:[]})
    }
    
}

  render() {
    const { classes } = this.props;   
    let orderArr = [];    
    this?.props?.paymentSummary?.paymentsDoneList?.map((order)=>{if(order.paymentStatus  === "Advance Paid" && (order.isAdvancePaymentDoneToBusiness  === "No")) {     
    orderArr.push(order.serviceRequestId);
    }})
    
    if(orderArr.length){this.state.APOrdersLengthCheck = true}
    else{this.state.APOrdersLengthCheck = false}   

     let muiAlert = null;
     if (this.state.sucessMsg) {
       muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
         {this.state.sucessMsg}
       </MuiAlert>
     } else if (this.state.errorMsg) {
       muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
         {this.state.errorMsg}
       </MuiAlert>
     }
    return (
      <Container className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }}
        >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                Search Transactions
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={3} style={{ alignItems: "center" }}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="serviceRequestId"
                    label="Order Id"
                    value={this.state.serviceRequestId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="consumerId"
                    label="Consumer Id"
                    value={this.state.consumerId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="consumerName"
                    label="Consumer Name"
                    value={this.state.consumerName}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
               
                <Grid item xs={3}>
                  <DateRangePicker
                    ranges={this.state.ranges}
                    onApply={this.handleRequestDateRangeChange}
                    maxSpan={{ months: 12 }}
                    maxDate={moment()}
                    showDropdowns
                  >
                    <TextField
                      fullWidth
                      margin="dense"
                      label="Order Date"
                      value={this.state.requestDate}
                      style={{ width: "225px" }}
                      variant="outlined"
                    />
                  </DateRangePicker>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="couponCode"
                    label="Coupon Code"
                    value={this.state.couponCode}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <CategoriesMultiSelectDropdown
                    handleFilterChange={this.handleFilterChange}
                    categories={this.props.categories}
                    selectedCategories={this.state.selectedCategories}
                  />
                </Grid> 
              
                <Grid item xs={3}>
                  <RequestStatusMultiSelectDropdown
                    handleFilterChange={this.handleFilterChange}
                    currentStatus={this.state.currentStatus}
                    fromTR = {true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="businessId"
                    label="Business Id"
                    value={this.state.businessId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="businessName"
                    label="Business Name"
                    value={this.state.businessName}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Advance Payment Request</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="advancePaid"
                                        value={this.state.advancePaid}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Advance Payment Request"
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                </Grid>
                  <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Advance Payment Done</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="isAdvancePaymentDoneToBusiness"
                                        value={this.state.isAdvancePaymentDoneToBusiness}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Advance Payment Done"
                                        disabled = {this.state.advancePaid  === false}
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <PaymentStatusMultiSelectDropdown
                    handleFilterChange={this.handleFilterChange}
                    paymentStatus={this.state.paymentStatus}
                  />
                </Grid>
             
                <Grid item xs={3}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="PaidMarkedBy-label">
                      Paid Marked By
                    </InputLabel>
                    <Select
                      labelId="PaidMarkedBy-label"
                      name="paidMarkedBy"
                      value={this.state.paidMarkedBy}
                      onChange={this.handleFilterChange}
                      input={<Input />}
                      MenuProps={this.MenuProps}
                    >
                      <MenuItem
                        value={"admin"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Support" />
                      </MenuItem>
                      <MenuItem
                        value={"business"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Business" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
            
                <Grid item xs={3}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="PaidMarkedBy-label">
                      Payment Mode
                    </InputLabel>
                    <Select
                      labelId="PaidMarkedBy-label"
                      name="paymentMode"
                      value={this.state.paymentMode}
                      onChange={this.handleFilterChange}
                      input={<Input />}
                      MenuProps={this.MenuProps}
                    >
                      <MenuItem
                        value={"CashFree"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Online Payment" />
                      </MenuItem>
                      <MenuItem
                        value={"DirectPayment"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Direct Payment" />
                      </MenuItem>
                      <MenuItem
                        value={"XirifyPayments"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Xirify Payments" />
                      </MenuItem>
               
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.formControl}
                  >
                    <InputLabel id="PaidMarkedBy-label">Method</InputLabel>
                  { this.state.paymentMode === "CashFree"? 
                  <Select
                      labelId="PaidMarkedBy-label"
                      name="paymentMethod"
                      value={this.state.paymentMethod}
                      onChange={this.handleFilterChange}
                      input={<Input />}
                      MenuProps={this.MenuProps}
                      // disabled={this.state.paymentMode !== "CashFree"}
                    >
                    
                      <MenuItem
                        value={"NET_BANKING"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Net Banking" />
                      </MenuItem>
                      <MenuItem
                        value={"WALLET"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Wallet" />
                      </MenuItem>
                      <MenuItem
                        value={"UPI"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="UPI" />
                      </MenuItem>
                      <MenuItem
                        value={"CREDIT_CARD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Credit Card" />
                      </MenuItem>
                      <MenuItem
                        value={"DEBIT_CARD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Debit Card" />
                      </MenuItem>
                    </Select> :
                    (this.state.paymentMode === "DirectPayment"? 
                    <Select
                      labelId="PaidMarkedBy-label"
                      name="paymentMethod"
                      value={this.state.paymentMethod}
                      onChange={this.handleFilterChange}
                      input={<Input />}
                      MenuProps={this.MenuProps}                     
                    >
                
                      <MenuItem
                        value={"COD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Cash" />
                      </MenuItem>
                      <MenuItem
                        value={"UPIDIRECT"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="UPI_Direct" />
                      </MenuItem>
                      <MenuItem
                        value={"CHEQUE"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Cheque" />
                      </MenuItem>
                      <MenuItem
                        value={"BANKTRANSFER"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Bank Transfer" />
                      </MenuItem>
                      <MenuItem
                        value={"OTHER"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Other" />
                      </MenuItem>
                    </Select> :
                      (this.state.paymentMode === "XirifyPayments"? 
                    <Select
                      labelId="PaidMarkedBy-label"
                      name="paymentMethod"
                      value={this.state.paymentMethod}
                      onChange={this.handleFilterChange}
                      input={<Input />}
                      MenuProps={this.MenuProps}
                      // disabled={this.state.paymentMode !== "XirifyPayments"}
                    >
              
                      <MenuItem
                        value={"PAIDBYXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Paid By Xirify" />
                      </MenuItem>
                      <MenuItem
                        value={"COMPENSATEDBYXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Compensated By Xirify" />
                      </MenuItem>
                      <MenuItem
                        value={"GIFTFROMXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Gift From Xirify" />
                      </MenuItem>
                      <MenuItem

                        value={"ADVANCEPAYMENTTOBUSINESS"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Advanace Payment 
                        To Business" />
                      </MenuItem>

                      <MenuItem
                        value={"RESETPAYMENT"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Reset Payment" />

                      </MenuItem>
                    </Select>:
                  <Select                  
                    labelId="PaidMarkedBy-label"
                    name="paymentMethod"
                    value={this.state.paymentMethod}
                    onChange={this.handleFilterChange}
                    input={<Input />}
                    MenuProps={this.MenuProps}
                    // disabled={this.state.paymentMode !== "XirifyPayments" && this.state.paymentMode !== "DirectPayment" && this.state.paymentMode !== "CashFree" }
                    >
                        <MenuItem
                        value={"NET_BANKING"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Net Banking" />
                      </MenuItem>
                      <MenuItem
                        value={"WALLET"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Wallet" />
                      </MenuItem>
                      <MenuItem
                        value={"UPI"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="UPI" />
                      </MenuItem>
                      <MenuItem
                        value={"CREDIT_CARD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Credit Card" />
                      </MenuItem>
                      <MenuItem
                        value={"DEBIT_CARD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Debit Card" />
                      </MenuItem>
                      <MenuItem
                        value={"COD"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Cash" />
                      </MenuItem>
                      <MenuItem
                        value={"UPIDIRECT"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="UPI_Direct" />
                      </MenuItem>
                      <MenuItem
                        value={"CHEQUE"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Cheque" />
                      </MenuItem>
                      <MenuItem
                        value={"BANKTRANSFER"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Bank Transfer" />
                      </MenuItem>
                      <MenuItem
                        value={"OTHER"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Other" />
                      </MenuItem>
                      <MenuItem
                        value={"PAIDBYXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Paid By Xirify" />
                      </MenuItem>
                      <MenuItem
                        value={"COMPENSATEDBYXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Compensated By Xirify" />
                      </MenuItem>
                      <MenuItem
                        value={"GIFTFROMXIRIFY"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Gift From Xirify" />
                      </MenuItem>
                      <MenuItem
                        value={"ADVANCEPAYMENTTOBUSINESS"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Advanace Payment 
                        To Business" />
                      </MenuItem>
                      <MenuItem
                        value={"RESETPAYMENT"}
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        <ListItemText primary="Reset Payment" />
                      </MenuItem>
                     
                    </Select>
                  ))}
                
                  {this.state.warnings.paymentMethod && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.paymentMethod} </Typography>
                 }
                   
                     
                  </FormControl>
                </Grid>
              
                <Grid item xs={3}>
                  <Typography gutterBottom>Payment From Date</Typography>
                  <Input
                    fullWidth
                    margin="dense"
                    type="date"
                    name="fromDate"
                    label="Payment From Date"
                    value={this.state.fromDate}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.paymentFromDate && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.paymentFromDate} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Payment From Time</Typography>
                  <Input
                    fullWidth
                    type="time"
                    margin="dense"
                    name="fromTime"
                    label="Payment From Time"
                    value={this.state.fromTime}
                    variant="filled"
                    className={classes.textField}
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.paymentFromTime && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.paymentFromTime} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Payment To Date</Typography>
                  <Input
                    fullWidth
                    type="date"
                    margin="dense"
                    name="toDate"
                    label="Payment To Date"
                    value={this.state.toDate}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.paymentToDate && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.paymentToDate} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Payment To Time</Typography>
                  <Input
                    fullWidth
                    type="time"
                    margin="dense"
                    name="toTime"
                    placeholder=""
                    label="Payment To Time"
                    value={this.state.toTime}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.paymentToTime && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.paymentToTime} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Settlement From Date</Typography>
                  <Input
                    fullWidth
                    margin="dense"
                    type="date"
                    name="settlementFromDate"
                    label="Settlement From Date"
                    value={this.state.settlementFromDate}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.settlementFromDate && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.settlementFromDate} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Settlement From Time</Typography>
                  <Input
                    fullWidth
                    type="time"
                    margin="dense"
                    name="settlementFromTime"
                    label="Settlement From Time"
                    value={this.state.settlementFromTime}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.settlementFromTime && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.settlementFromTime} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Settlement To Date</Typography>
                  <Input
                    fullWidth
                    type="date"
                    margin="dense"
                    name="settlementToDate"
                    label="Settlement To Date"
                    value={this.state.settlementToDate}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.settlementToDate && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.settlementToDate} </Typography>}
                </Grid>
                <Grid item xs={3}>
                  <Typography gutterBottom>Settlement To Time</Typography>
                  <Input
                    fullWidth
                    type="time"
                    margin="dense"
                    name="settlementToTime"
                    label="Settlement To Time"
                    value={this.state.settlementToTime}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                  {this.state.warnings.settlementToTime && <Typography gutterBottom style={{ color: "red" }}>{this.state.warnings.settlementToTime} </Typography>}
                </Grid>
                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3}>
                              {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Transaction Notes</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="transactionNotes"
                                        value={this.state.transactionNotes}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Transaction Notes"
                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">PrePayment Orders</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="prePaymentOrders"
                                        value={this.state.prePaymentOrders}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="PrePayment Orders"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                <FormControl margin="dense" variant="outlined" fullWidth className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard-label">Refund Applied</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard"
                                        name="refundApplied"
                                        value={this.state.refundApplied}
                                        onChange={this.handleFilterChange}
                                        id="demo-simple-select-standard"
                                        label="Refund Applied"                                       
                                    >
                                        <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Yes </MenuItem>
                                        <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                    </Select>
                                </FormControl>
                                </Grid>
                <Grid item xs={8}>
                  <Typography style={{ color: "#F00" }}>
                    {this.state.warningText}
                  </Typography>
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={() => this.setPage(0)}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* confirmation dialogue for confirm advance payment */}
          <Dialog open={this.state.APconfirmationFlag} onClose={this.handleDeleteClose}>
          <DialogTitle>
            Advance Payment Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Are you sure want to do Advance Payment for selected orders?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.changeAPStatus(this.state.APOrdersList)} color="primary" autoFocus> Yes </Button>
          </DialogActions>
        </Dialog>
        </Container>
        <Container className={classes.container}>
          {this.props.paymentSummary &&
            this.props.paymentSummary.paymentsDoneList ? (
            <PaymentSummaryTable
              data={this.props.paymentSummary}
              exportToexcel={this.props.paymentSummaryExcel}
              order={this.state.order === 1 ? "asc" : "desc"}
              orderBy={this.state.orderBy}
              page={this.state.page}
              rowsPerPage={this.state.rowsPerPage}
              currentStatus={this.state.currentStatus}
              setOrder={this.setOrder}
              setOrderBy={this.setOrderBy}
              setPage={this.setPage}
              setRowsPerPage={this.setRowsPerPage}
              history={this.props.history}
              fetchPaymentSummaryExcelFile={this.fetchPaymentSummaryExcelFile}
              fetchCashFreeSettlement={this.fetchCashFreeSettlement}
              showDownloadIcon={this.state.preparexportToExcel}
              confirmAdvancePayment={this.confirmAdvancePayment}
              confirmAdvancePaymentFlag = {this.state.confirmAdvancePaymentFlag}
              handleSelectedAP = {this.handleSelectedAP}
              setSelected={this.setSelected}
              handleSelectAllClick = {this.handleSelectAllClick}
              isSelected = {this.isSelected}
              isCheckedFlag = {this.state.isChecked}
              isCheckedHandleChange = {this.isCheckedHandleChange}
              openAddNotesModal={this.openAddNotesModal}
              deleteTRNote = {this.deleteTRNote}
              handleDeleteOpen={this.handleDeleteOpen}
              handleDeleteClose={this.handleDeleteClose}
              TRNoteconfirmationFlag={this.state.TRNoteconfirmationFlag}
              APOrdersLengthCheck = {this.state.APOrdersLengthCheck}
              totalGMV = {this.state.totalGMVWithoutFilter}
              handleGMVCall = {this.handleGMVCall}
              handleGMVFlag = {this.state.handleGMVFlag}
            />
          ) : null}
        </Container>
        <Modal
							className={classes.modal}
							open={this.state.modalOpen}
							onClose={this.handleModalClose}
							closeAfterTransition>
							<Container className={classes.modalContainer}>
								{
									<AddTRNotesForm
                   initialValues={_.pick(this.state.NoteAdd,'_id', 'serviceRequestId', 'trNote','finalTRNote')}
                   onSubmit={this.addTRNotes} 
                   onClose={this.handleModalClose} />
								}

							</Container>
						</Modal>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.snackOpen}
          autoHideDuration={3000}
          onClose={this.handleSnackClose}
          ContentProps={{ 'aria-describedby': 'message-id' }}
        >
          {muiAlert}
        </Snackbar>
        <Backdrop className={classes.backdrop} open={this.props.isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
        
      </Container>
    );
  }
}


const mapStateToProps = (state) => {
  
  return {
    isFetching: state.paymentSummary.isFetching,
    paymentSummary: state.paymentSummary.paymentSummary,
    paymentSummaryExcel: state.paymentSummary.paymentSummaryExcel,
    paymentSummaryCashfreeSettlementData:state.paymentSummary.paymentSummaryCashfreeSettlementData,
    categories: state.categories.categoriesByType,
    APSuccessMsg: state.paymentSummary.sucessMsg,
    APErrorMsg: state.paymentSummary.errorMsg,
    gmvValue: state.paymentSummary.gmvValue
  };
};

export default connect(mapStateToProps, {
  changeHeaderName,
  requestPaymentSummary,
  getPaymentSummary,
  getPaymentSummaryExcel,
  getCategoriesByType,
  getCashFreeSettlementData,
  changeAPStatus,
  clearMsg,
  addTRNotes,
  getGMVValue
})(withStyles(styles)(PaymentSummaryReport));
