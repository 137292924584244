import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { changeHeaderName } from '../../actions/dashboardActions';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import MessageIcon from '@material-ui/icons/Message';
import CouponSettleAmountModal from './couponSettleAmountModal';
import AddCouponNotesModal from './addCouponNotesModal';
import { 
    requestCampaign, 
    getCampaignDetailsById, 
    getCampaignBusinessExportToExcel, 
    getCouponConsumerDataByBusinessId, 
    getCouponConsumerDataByBusinessIdExport,
    getCouponSettlements,
    clearNotesRecord,
    getCouponSettlementsNotes
} from '../../actions/campaignActions';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchEnvs } from '../../actions/authActions';
import TablePagination from '@material-ui/core/TablePagination';

const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '90%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
});

let dataSettlement = [];
let totalCount;
// const rows = [
//     { id:1, duration: '#12456366', noOfCouponsHonoured: 100,totalAmtOfCouponsHonoured:40000,dueStatus: null,uploadedImage:'image url ',Notes:''},
// ];

// const data = [
//     { id:1, consumerID: '#12456366', consumerName: 'abc',orderID:'#565421',couponCode:'HAPPY21',promoCode:'PROMOCODE',consumedOn:'12-may-2021',couponStatus:'settled',settledOn:'22-dec-2021'},
// ];

let couponConsumerBusinessCampaignData = [];
let couponConsumerBusinessCampaignDataExport = [];

class campaignReport extends Component {
    constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			regionToEdit: null,
            campaignID: "",
            campaignName: "",
            businessId: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            campaignTimeDateRangeEnd: "",
            campaignTimeDateRangeStart: "",
            campaignStatus: "",
            showDownloadIcon: false,
            headers: [
                { key: 'consumerId[0]', label: 'Consumer ID' },
                { key: 'consumerFirstName[0]', label: 'Consumer First Name' },
                { key: 'consumerLastName[0]', label: 'Consumer Last Name' },
                { key: 'orderId', label: 'Order ID' },
                { key: 'orderAmount', label: 'Order Amount' },
                { key: 'couponCode', label: 'Coupon Code' },
                { key: 'couponAmount', label: 'Coupon Amount' },
                { key: 'promoCode', label: 'Promo Code' },
                { key: 'consumed', label: 'Consumed On' },
                { key: 'couponStatus', label: 'Coupon Status' },
                { key: 'settled', label: 'Settled On'},
                // { key: 'business[0].businessId', label: 'Business Id' },
                // { key: 'business[0].businessName', label: 'Business Name'},
                // { key: 'campaignId', label: 'campaignId'},
                // { key: 'campaignName', label: 'campaignName'},
            ],
            headersoffercode: [
                { key: 'consumerId[0]', label: 'Consumer ID' },
                { key: 'consumerFirstName[0]', label: 'Consumer First Name' },
                { key: 'consumerLastName[0]', label: 'Consumer Last Name' },
                { key: 'orderId', label: 'Order ID' },
                { key: 'orderAmount', label: 'Order Amount' },
                { key: 'couponCode', label: 'Coupon Code' },
                { key: 'couponAmount', label: 'Coupon Amount' },
                { key: 'offerCode', label: 'Offer Code' },
                { key: 'consumed', label: 'Consumed On' },
                { key: 'couponStatus', label: 'Coupon Status' },
                { key: 'settled', label: 'Settled On'},
                // { key: 'business[0].businessId', label: 'Business Id' },
                // { key: 'business[0].businessName', label: 'Business Name'},
                // { key: 'campaignId', label: 'campaignId'},
                // { key: 'campaignName', label: 'campaignName'},
            ],
            headersone:[
                    { key: 'range', label: 'Duration' },
                    { key: 'totalHonouredCoupons', label: 'No Of Coupons Honoured' },
                    { key: 'totalAmountOfHonouredCoupons', label: 'Total Amount Of Coupons Honoured' },
                    { key: 'dueStatus', label: 'Due Status' }, //settled / not settled or paid / unpaid
                    { key: 'attachment', label: 'Uploaded Image' },
                    { key: 'settlementDate', label: 'Settlement Date' },
            ],
            businessName: "",
            consumerID: "",
            consumerName: "",
            promoCode: "",
            offerCode: "",
            couponStatus: "",
            modalOpenSettle: false,
            settleModal: false,
            openNotesModal: false,
            orderId: "",
            consumerFirstName: "",
            consumerLastName: "",
            couponCode: "",
            timeDateRange:"",
            couponStat: "",
            timeDateRangeDuration: "",
            campaignCode: "",
		};
	}

    componentDidMount() {
        this.props.changeHeaderName(`Campaign Report - ${this.props.history.campaignCode === "offerCode" ? "Offer Code" : "Promo Code"} Campaign`);
        this.props.fetchEnvs();        
        this.setState({ campaignCode: this.props.history.campaignCode ? this.props.history.campaignCode : "" });
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        // this.props.getCampaignBusinessExportToExcel(this.props.match.params.campaignId); //businessId
        // this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId, this.props.match.params.campaignId);
        this.fetchFilter();
        // this.getCouponSettlements();

    };

    getCouponSettlements = async () => {
        let payload ={
            businessId: this.props.match.params.businessId,
            campaignId: this.props.match.params.campaignId,
            // data:{
            //     skip: this.state.deliverySettlementTablePage * this.state.rowsPerPageDeliverySettlementTable,
            //     limit: this.state.rowsPerPageDeliverySettlementTable,
            // }
        }
        await  this.props.getCouponSettlements(payload);
        await this.setState({showDownloadIcon:false});
    };

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    handleModalOpen = () => {
        this.setState({ modalOpen: true });
    };
    handleModalClose = () => {
        this.setState({ settleModal: false });
    };
    fetchExportToExcel = async () => {
        this.props.requestCampaign();
        let payload = {
            consumerID: this.state.consumerID,
            consumerFirstName: this.state.consumerFirstName,
            consumerLastName: this.state.consumerLastName,
            orderId: this.state.orderId,
            orderAmount:this.state.orderAmount,
            couponCode: this.state.couponCode,
            promoCode: this.state.promoCode,
            offerCode: this.state.offerCode,
            couponStatus: this.state.couponStatus,
            businessId: this.props.match.params.businessId,
            campaignId: this.props.match.params.campaignId,
            // businessId: this.state.businessId,
            // businessName: this.state.businessName,
            // campaignObjId: this.props.campaign.campaignDetails.campaignListData[0]._id,
        }
        // await this.props.getCampaignDetailsById(payload);
        await this.props.getCouponConsumerDataByBusinessIdExport(this.props.match.params.businessId, payload);
        this.setState({showDownloadIcon:true});
    };
    handleModalOpenSettle = () => {
        this.setState({ modalOpenSettle: true });
    };
    openSettleModal = async (row) => {
        // console.log("settle data", row);
        await this.setState({ selectedSettlementRecord: row });
        this.setState({ settleModal: true });
    };
    resetFilter = async () => {
        this.setState({
            consumerID: "",
            consumerFirstName: "",
            consumerLastName: "",
            orderId: "",
            couponCode: "",
            promoCode: "",
            offerCode: "",
            couponStatus: "",
            timeDateRange: "",
        },()=>{
            // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
            this.fetchFilter();
        });
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        // // this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId, this.props.match.params.campaignId);
        // await this.fetchFilter();
        // // this.setState({showDownloadIcon:false});
    };

    fetchFilter = async () => {
        this.props.requestCampaign();
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            consumerID: this.state.consumerID,
            consumerFirstName: this.state.consumerFirstName,
            consumerLastName: this.state.consumerLastName,
            orderId: this.state.orderId,couponCode: this.state.couponCode,
            promoCode: this.state.promoCode,
            offerCode: this.state.offerCode,
            couponStatus: this.state.couponStatus,
            businessId: this.props.match.params.businessId,
            campaignId: this.props.match.params.campaignId,
            // businessId: this.state.businessId,
            // businessName: this.state.businessName,
            // campaignObjId: this.props.campaign.campaignDetails.campaignListData[0]._id,
        }
        // console.log("payload before sending---->",payload)
        // await this.props.getCampaignDetailsById(payload);
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        await this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId,this.props.match.params.campaignId,payload);
        this.setState({showDownloadIcon: false });
    };

    resetSettlementFilter = async () => {
        this.setState({
            couponStat: "",
            timeDateRange: "",
        });
        // this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId, this.props.match.params.campaignId);
        this.getCouponSettlements();
        this.setState({showDownloadIcon:false});
    };

    fetchSettlementFilter = async () => {
        let payload = {
            couponStat: this.state.couponStat,
            timeDateRange: this.state.timeDateRange,
            businessId: this.props.match.params.businessId,
            campaignId: this.props.match.params.campaignId,
        }
        // await this.props.getCampaignDetailsById(this.props.match.params.campaignId);
        await this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId, this.props.match.params.campaignId);
        await this.props.getCouponSettlements(payload);
        this.setState({showDownloadIcon:false});
        // await this.props.getCampaignDetailsById(payload);
        // await this.props.getCouponConsumerDataByBusinessId(this.props.match.params.businessId,payload);
        // this.setState({showDownloadIcon: false });
    };

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            FilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
            FilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ timeDateRange: chosenLabel });
    };

    saveAndClose = async () => {
        this.onClose();
        this.getCouponSettlements();
    };

    onClose = async () => {
        this.setState({
            settleModal: false,
            openNotesModal: false
        });
        await this.props.clearNotesRecord();
    };

    openNotesModal = async (row) => {
        await this.setState({ selectedSettlementRecord: row });
        this.setState({ openNotesModal: true });
        await this.props.getCouponSettlementsNotes({couponSettlementId:row.couponSettlementId});

    };

    fetchExportToExcelCouponSettlement = async () => {
        let payload ={
            businessId: this.props.match.params.businessId,
            data:{
                // skip: this.state.deliverySettlementTablePage * this.state.rowsPerPageDeliverySettlementTable,
                // limit: this.state.rowsPerPageDeliverySettlementTable,
                exportToexcel:1
            }
        }
        await  this.props.getCouponSettlements(payload);
        await this.setState({showDownloadIcon:true});
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    };

    handleChangeRowsPerPage = (event) => {
        this.setRowsPerPage(parseInt(event.target.value, 10));
        this.setPage(0);
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    render() {
        const { classes } = this.props;
        // console.log("this.props in level 3---->",this.props);
        // console.log('slot data---->',this.props.campaign.couponSettlementRecords.slotData);
        // let idBusiness;
        
        // console.log("consumer data---->",this.props.campaign.couponConsumerBusinessCampaignDetails);
        if(this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.couponsConsumerData){
           
            couponConsumerBusinessCampaignData = this.props.campaign.couponConsumerBusinessCampaignDetails.couponsConsumerData;
            // couponConsumerBusinessCampaignData.map(consumer => { consumerData = consumer ? consumer.consumer : ""; });
            // couponConsumerBusinessCampaignData.map(servicerequest => { servicerequestData = servicerequest ? servicerequest.serviceRequest : ""; });
            // couponConsumerBusinessCampaignData.map(business => { businessData = business ? business.business : ""; });
            // idBusiness = (businessData && businessData[0].businessId) ? businessData[0].businessId : "";
            // row = couponConsumerBusinessCampaignData[0];
            // console.log(row);
        }
        if(this.props.campaign && this.props.campaign.campaignBusinessCustomerCouponExcelRecords && this.props.campaign.campaignBusinessCustomerCouponExcelRecords.couponsConsumerData
            // && this.props.campaign.campaignBusinessCustomerCouponExcelRecords.couponListData
            ){
                couponConsumerBusinessCampaignDataExport = this.props.campaign.campaignBusinessCustomerCouponExcelRecords.couponsConsumerData;
            // couponConsumerBusinessCampaignDataExport = this.props.campaign.campaignBusinessCustomerCouponExcelRecords.couponListData;
            // console.log(couponConsumerBusinessCampaignDataExport);
        }

        if( this.props.campaign.couponSettlementRecords &&  this.props.campaign.couponSettlementRecords.exportToExcel ){
            dataSettlement = this.props.campaign.couponSettlementRecords.exportToExcel ;
            dataSettlement && dataSettlement.forEach(record => {
                record.settlementDate = (record.settlementDate && record.settlementDate!=="Invalid date" && record.settlementDate!==undefined && record.settlementDate!=="NA")? moment(record.settlementDate).format('DD MMM YYYY') : "NA"
                // record.settlementDate = ( record.settlementDate && record.settlementDate !== "Invalid date" && moment(record.settlementDate)) ? moment( record.settlementDate).format('DD MMM YYYY hh:mm A') : "NA"
                record.range = moment.utc(record.rangeStartDate).format('DD MMM') + "-" + moment(record.rangeEndDate).format('DD MMM YYYY')
                record.status =record.dueStatus? "PAID" : "UNPAID"
                //record.settlementDate = record.settlementDate? moment(record.settlementDate).format('DD MMM YYYY') : "NA"
            });
        }

        // if(this.props.campaign.couponConsumerBusinessCampaignDetails.couponsConsumerCount) {
            totalCount = this.props.campaign.couponConsumerBusinessCampaignDetails.couponsConsumerCount;
        // }

        let idCampaign = this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.campaignId ? this.props.campaign.couponConsumerBusinessCampaignDetails.campaignId : "--";
        let idBusinessId = this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.businessId ? this.props.campaign.couponConsumerBusinessCampaignDetails.businessId : "--";
       
        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <Container style={{ marginTop: '24px', marginBottom: '8px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <Typography style={{ fontSize:'20px', fontWeight: 'bold' }}gutterBottom> {this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.campData ? this.props.campaign.couponConsumerBusinessCampaignDetails.campData[0].campaignName : "--"} ({this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.campData ? this.props.campaign.couponConsumerBusinessCampaignDetails.campData[0].campaignId : "--"}) {'\u2794'} {this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.busiData ? this.props.campaign.couponConsumerBusinessCampaignDetails.busiData[0].businessName : "--"} ({this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.busiData ? this.props.campaign.couponConsumerBusinessCampaignDetails.busiData[0].businessId : "--"}) </Typography>
                            </Grid>  
                            {/* {
                                this.props.user.role =='admin' ?
                                <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={this.handleModalOpenSettle}
                                    hidden={this.state.modalOpenSettle == true}
                                    style={{ "float": "right" }}
                                    disabled
                                >
                                    Settle
                                </Button>
                            </Grid>
                            : null
                            }            */}
                            
                        </Grid>
                    </Container>
                    <Container style={{ marginTop: '24px', marginBottom: '8px' }}>
                        <Typography style={{ fontSize:'20px', fontWeight: 'bold' }}gutterBottom></Typography>
                    </Container>

                    {
                        !this.state.modalOpenSettle ? 
                            ( 
                                // If the campaignCode == promoCode (filters, export, column, data inside promoCode column should be related to promocode campaign)
                                // If the campaignCode == offerCode (filters, export, column, data inside offerCode column should be related to offerCode campaign)
                                <Container className={classes.container}>
                                    <Container className={classes.searchPaper}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{ fontSize:'17px'}}>Search</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                                <Grid container spacing={1}>
                                                <Grid item xs={3} >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Consumer ID
                                                    </Typography>
                                                    <TextField fullWidth margin="dense" name="consumerID" value={this.state.consumerID} variant="outlined" onChange={this.handleFilterChange} />
                                                    {/* style={{ width: "225px" }} */}
                                                </Grid>
                                                <Grid item xs={3} >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Consumer First Name
                                                    </Typography>
                                                    <TextField fullWidth margin="dense" name="consumerFirstName" value={this.state.consumerFirstName} variant="outlined" onChange={this.handleFilterChange} />
                                                </Grid>
                                                <Grid item xs={3} >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Consumer Last Name
                                                    </Typography>
                                                    <TextField fullWidth margin="dense" name="consumerLastName" value={this.state.consumerLastName} variant="outlined" onChange={this.handleFilterChange} />
                                                </Grid>
                                                <Grid item xs={3} >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Order ID
                                                    </Typography>
                                                    <TextField fullWidth margin="dense" name="orderId" value={this.state.orderId} variant="outlined" onChange={this.handleFilterChange} />
                                                </Grid>
                                                <Grid item xs={3} >
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Coupon Code
                                                    </Typography>
                                                    <TextField fullWidth margin="dense" name="couponCode" value={this.state.couponCode} variant="outlined" onChange={this.handleFilterChange} />
                                                </Grid>
                                                {
                                                    this.state.campaignCode === "promoCode" ? 
                                                        <Grid item xs={3} >
                                                            <Typography variant="subtitle2" gutterBottom> Promo Code </Typography>
                                                            <TextField fullWidth margin="dense" name="promoCode" value={this.state.promoCode} variant="outlined" onChange={this.handleFilterChange} />
                                                        </Grid>
                                                    : null
                                                }
                                                
                                                <Grid item xs={5} >
                                                    <Typography variant="subtitle2" gutterBottom> Coupon Status </Typography>
                                                    <RadioGroup row aria-label="position" name="couponStatus" onChange={this.handleFilterChange} value={this.state.couponStatus}>
                                                    <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                                                    <FormControlLabel value="PayBackSeller" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Settled</Typography>} size="small" />
                                                    {/* <FormControlLabel value="NotSettled" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Not Settled</Typography>} size="small" /> */}
                                                    <FormControlLabel value="Consumed" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Consumed</Typography>} size="small" />
                                                    {/* <FormControlLabel value="Expired" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Expired</Typography>} size="small" /> */}
                                                    </RadioGroup>
                                                </Grid>
                                                
                                                {this.state.campaignCode === "offerCode" ? <Grid item xs={4}></Grid> : null}
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={3}></Grid><Grid item xs={3}></Grid>
                                                <Grid item xs={3}></Grid><Grid item xs={3}></Grid>
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={2}></Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    margin="normal"
                                                    onClick={this.resetFilter}
                                                    >
                                                    Reset
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    margin="normal"
                                                    color="primary"
                                                    onClick={this.fetchFilter}
                                                    >
                                                    Search
                                                    </Button>
                                                </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Container>

                                    <Container className={classes.container}>
                                        <Paper className={classes.paper}>
                                            <Typography style={{marginTop:'10px', marginBottom:'10px'}} variant="subtitle2" id="tableTitle" component="div">
                                            Total Coupons Honoured : {this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.totalCouponsHonoured ? this.props.campaign.couponConsumerBusinessCampaignDetails.totalCouponsHonoured : "0"}  &nbsp; &nbsp; &nbsp; Total Revenue Generated: {'\u20B9'} {this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.totalRevenue ? this.props.campaign.couponConsumerBusinessCampaignDetails.totalRevenue.toFixed(2) : "0"} &nbsp; &nbsp; &nbsp; New Customers: {this.props.campaign && this.props.campaign.couponConsumerBusinessCampaignDetails && this.props.campaign.couponConsumerBusinessCampaignDetails.newConsumersCount ? this.props.campaign.couponConsumerBusinessCampaignDetails.newConsumersCount : "0"}

                                                {/* <CSVLink
                                                    style={{ "marginRight": "28px", "float": "right" }}
                                                    headers={this.state.headers}
                                                    data={couponConsumerBusinessCampaignDataExport}
                                                    filename={"File.xlsx"}
                                                    className="btn btn"
                                                    target="_blank"
                                                    onClick={this.fetchExportToExcel}
                                                >
                                                    <Tooltip title="Export to Excel">
                                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                                    </Tooltip>
                                                </CSVLink> */}

                                            {this.state.showDownloadIcon ?
                                                <CSVLink
                                                style={{ "marginRight": "28px", "float": "right" }}
                                                data={couponConsumerBusinessCampaignDataExport}
                                                headers={this.state.campaignCode === "promoCode" ? this.state.headers : this.state.headersoffercode}
                                                filename={idCampaign+"-"+idBusinessId+".csv"}
                                                className="btn btn"
                                                target="_blank"
                                                >
                                                    <Tooltip title="Export to Excel">
                                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                                    </Tooltip>
                                                </CSVLink>
                                            :
                                                <Button 
                                                style={{ float: "right", margin: "5px",backgroundColor:"green",color:"white" }}
                                                variant="contained"
                                                onClick={this.fetchExportToExcel}
                                                >
                                                    Export
                                                </Button>
                                            } 
                                            </Typography>
                                        
                                            <TableContainer>
                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size="small"
                                                    aria-label="enhanced table"
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Consumer ID</TableCell>
                                                            <TableCell>Consumer Name</TableCell>
                                                            <TableCell>Order ID</TableCell>
                                                            <TableCell>Order Amount</TableCell>
                                                            <TableCell>Coupon Code</TableCell>
                                                            <TableCell> {this.state.campaignCode === "promoCode" ? "Promo Code" : "Offer Code"}</TableCell>
                                                            <TableCell>Coupon Amount</TableCell>
                                                            <TableCell>Consumed On</TableCell>
                                                            <TableCell>Coupon Status</TableCell>
                                                            <TableCell>Settled On</TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            (couponConsumerBusinessCampaignData) ? couponConsumerBusinessCampaignData
                                                            .map((row, index) => {
                                                                // console.log(row);
                                                                let consumedOn = 'NA';
                                                                let settledOn = 'NA';
                                                                let consumed = row.couponStatusHistory && row.couponStatusHistory.length > 0 && row.couponStatusHistory.find(status => status.status === 'Consumed');
                                                                let payBackSeller = row.couponStatusHistory && row.couponStatusHistory.length > 0 && row.couponStatusHistory.find(status => status.status === 'PayBackSeller');
                                                                consumedOn = consumed && consumed.time ? moment(consumed.time).format('DD MMM YYYY hh:mm A') : 'NA';
                                                                settledOn = payBackSeller && payBackSeller.time ? moment(payBackSeller.time).format('DD MMM YYYY hh:mm A') : 'NA';
                                                                return ( 
                                                                    <TableRow
                                                                        hover
                                                                        className={classes.tableRow}
                                                                        key={index}
                                                                    >
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                        {(row.consumerId && row.consumerId.length > 0) ? row.consumerId[0] : "--"}
                                                                    </TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                        <span>{(row.consumerFirstName && row.consumerFirstName.length > 0 && row.consumerLastName && row.consumerLastName.length > 0) ? row.consumerFirstName[0] + " " + row.consumerLastName[0] : "--"}</span>
                                                                        </TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                        {/* {(servicerequestData) ? servicerequestData[0].serviceRequestId : "--"} */}
                                                                        {row.orderId}
                                                                        </TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >{row.orderAmount.toFixed(2)}</TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >{row.couponCode}</TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                        {this.state.campaignCode === "promoCode" ? row.promoCode : (row.offerCode ? row.offerCode : "--")}
                                                                    </TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                        {row.couponAmount}
                                                                    </TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >{consumedOn}</TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >{(row.couponStatus === 'PayBackSeller' ? 'Settled' : row.couponStatus)}</TableCell>
                                                                    <TableCell align="left" className={classes.tableCellSmall} scope="row" >{settledOn}</TableCell>
                                                                    </TableRow>
                                                                    );
                                                                })
                                                                : null 
                                                            } 
                                                        </TableBody> 
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                                    component="div"
                                                    count={totalCount}
                                                    rowsPerPage={this.state.rowsPerPage}
                                                    page={this.state.page}
                                                    onChangePage={this.handleChangePage}
                                                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                                />
                                        </Paper>
                                    </Container>
                                </Container>
                            ) 
                            : 

                            (
                                <Container className={classes.container}>
                                    <Container className={classes.searchPaper}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography style={{ fontSize:'17px'}}>Search</Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                                <Grid container spacing={1}>
                                                <Grid xs={3} >
                                                    <Typography variant="subtitle2" gutterBottom>
                                                        Coupon Duration
                                                    </Typography>
                                                    <DateRangePicker
                                                        ranges={this.state.ranges}
                                                        onApply={this.handleDateRangeChange}
                                                        maxSpan={{ "months": 12 }}
                                                        maxDate={moment()}
                                                        showDropdowns
                                                    >
                                                        <TextField fullWidth margin="dense" name="DateFilter"  value={this.state.timeDateRange} style={{ width: "225px" }} variant="outlined" />
                                                    </DateRangePicker>
                                                </Grid>
                                                <Grid xs={5} >
                                                <Typography variant="subtitle2" gutterBottom>
                                                    Coupon Status
                                                    </Typography>
                                                    <RadioGroup row aria-label="position" name="couponStat" onChange={this.handleFilterChange} value={this.state.couponStat}>
                                                    <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                                                    <FormControlLabel value="true" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Settled</Typography>} size="small" />
                                                    <FormControlLabel value="false" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Not Settled</Typography>} size="small" />
                                                    
                                                    </RadioGroup>
                                                </Grid>
                                                
                                                
                                                <Grid item xs={3}></Grid>
                                                <Grid item xs={4}></Grid><Grid item xs={4}></Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    margin="normal"
                                                    onClick={this.resetSettlementFilter}
                                                    >
                                                    Reset
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                    type="submit"
                                                    fullWidth
                                                    variant="contained"
                                                    margin="normal"
                                                    color="primary"
                                                    onClick={this.fetchSettlementFilter}
                                                    >
                                                    Search
                                                    </Button>
                                                </Grid>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Container>


                                    <Container className={classes.container}>
                                    <Paper className={classes.paper}>
                                        <Typography style={{marginTop:'10px', marginBottom:'10px'}} variant="subtitle2" id="tableTitle" component="div">
                                        Total Charges: {'\u20B9'} {(this.props.campaign.couponSettlementRecords && this.props.campaign.couponSettlementRecords.totalCouponCharges) ? this.props.campaign.couponSettlementRecords.totalCouponCharges : 0 }

                                            {/* <CSVLink
                                                style={{ "marginRight": "28px", "float": "right" }}
                                                headers={this.state.headersone}
                                                data={ dataSettlement }
                                                filename={"File.xlsx"}
                                                className="btn btn"
                                                target="_blank"
                                                onClick={this.fetchExportToExcelCouponSettlement}
                                            >
                                                <Tooltip title="Export to Excel">
                                                    <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                                </Tooltip>
                                            </CSVLink> */}
                                            {this.state.showDownloadIcon ?
                                                <CSVLink
                                                style={{ "marginRight": "28px", "float": "right" }}
                                                headers={this.state.headersone}
                                                data={ dataSettlement }
                                                filename={"File.xlsx"}
                                                className="btn btn"
                                                target="_blank"
                                                >
                                                    <Tooltip title="Export to Excel">
                                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                                    </Tooltip>
                                                </CSVLink>
                                            :
                                                <Button 
                                                style={{ float: "right", margin: "5px" }}
                                                variant="contained"
                                                color="primary"
                                                onClick={this.fetchExportToExcelCouponSettlement}
                                                >
                                                    Export
                                                </Button>
                                            } 
                                        </Typography>
                                            
                                        <TableContainer>
                                            <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    size="small"
                                                    aria-label="enhanced table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Duration</TableCell>
                                                        <TableCell>No. of Coupons Honoured</TableCell>
                                                        <TableCell>Total Amt. of honoured coupons</TableCell>
                                                        <TableCell>Due Status</TableCell>
                                                        <TableCell>Uploaded Image</TableCell>
                                                        <TableCell>Notes</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                    // rows
                                                    (this.props.campaign.couponSettlementRecords && this.props.campaign.couponSettlementRecords.slotData) ?
                                                        this.props.campaign.couponSettlementRecords.slotData
                                                        .map((row, index) => {
                                                            return ( 
                                                                <TableRow
                                                                    hover
                                                                    className={classes.tableRow}
                                                                    key={index}
                                                                >
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                    {moment.utc(row.rangeStartDate).format("DD MMM ") +"-" +moment(row.rangeEndDate).format(" DD MMM YYYY")}
                                                                    {/* {moment(row.rangeStartDate).format("DD MMMM ") +"-" +moment.utc(row.rangeEndDate).format(" DD MMMM YYYY")} */}
                                                                </TableCell>
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >{row.totalHonouredCoupons}</TableCell>
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >{row.totalAmountOfHonouredCoupons}</TableCell>
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                    {/* {row.dueStatus ? <Typography style ={{color:"green"}}>Settled on {row.settlementDate ? moment(row.settlementDate).format("DD/MM/YYYY"):"NA"}</Typography> : <Typography style ={{color:"red"}}>Not Settled</Typography>} */}

                                                                    {row.dueStatus ? <Typography style ={{color:"green"}}>Settled on {row.settlementDate ? moment(row.settlementDate).format("DD/MM/YYYY"):"NA"}</Typography> : <Typography style ={{color:"red"}}>Not Settled</Typography>}

                                                                    {row.dueStatus ? null
                                                                        : 
                                                                        <Button
                                                                            variant="contained"
                                                                            margin="normal"
                                                                            color="primary"
                                                                            onClick={() => this.openSettleModal(row)}
                                                                        >
                                                                            SETTLE
                                                                        </Button>}
                                                                </TableCell>
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                    {row.attachment ? 
                                                                        <a 
                                                                            href={row.attachment} 
                                                                            target="_blank" rel="noopener noreferrer">
                                                                                <img 
                                                                                src={row.attachment}
                                                                                alt="Coupon Settlement Attachment" width="64" height="35" 
                                                                                />
                                                                        </a>


                                                                    : "--"}
                                                                    </TableCell>
                                                                <TableCell align="left" className={classes.tableCellSmall} scope="row" >
                                                                    <MessageIcon 
                                                                        margin="normal"
                                                                        onClick={() => this.openNotesModal(row)}
                                                                    />
                                               
                                                                    { row.settlementNotes && row.settlementNotes.length ? row.settlementNotes.length : "0" }
                                                                </TableCell>
                                                                </TableRow>
                                                                );
                                                            })
                                                            : null
                                                        }  
                                                    </TableBody> 
                                                </Table>
                                            </TableContainer>
                                            {/* <TablePagination
                                                rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                                                component="div"
                                                count={props.rowData.totalcount}
                                                rowsPerPage={props.rowsPerPage}
                                                page={props.page}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            /> */}
                                        </Paper>
                                    </Container>

                                    <CouponSettleAmountModal
                                        open={this.state.settleModal}
                                        onClose={this.handleModalClose}
                                        envs = {this.props}
                                        saveAndClose={this.saveAndClose}
                                        fullData = {this.props}
                                        data={this.state.selectedSettlementRecord}
                                    />
                                    <AddCouponNotesModal
                                        open={this.state.openNotesModal}
                                        onClose={this.saveAndClose}
                                        data={this.state.selectedSettlementRecord}
                                        
                                    />
                                </Container>
                            )
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.campaign.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}
const mapStateToProps = state => {
	return {
		coupon: state.coupon,
        campaign: state.campaign,
        business: state.business,
        user: state.auth.user,
        env: state.auth.env
	}
};
export default connect(mapStateToProps, { changeHeaderName, getCampaignDetailsById, getCampaignBusinessExportToExcel, getCouponConsumerDataByBusinessId, getCouponConsumerDataByBusinessIdExport, fetchEnvs, getCouponSettlements, clearNotesRecord, getCouponSettlementsNotes, requestCampaign })(withStyles(styles)(campaignReport));