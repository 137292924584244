import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";  
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { CSVLink } from "react-csv";
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors'; 





const headCells = [
   { id: "noticeId",  disablePadding: true, label: "Notice Id"  },
   { id: "noticeName",  disablePadding: true, label: "Notice Name"  },
   { id: "activeDate",  disablePadding: true, label: "Active Date"  },
   { id: "role",  disablePadding: true, label: "Role"  },
   { id: "noticeType",  disablePadding: true, label: "Notice Type"  },
   { id: "preview",  disablePadding: true, label: "Preview"  },
   { id: "isActive",  disablePadding: true, label: "Status"  },
   { id: "deleted",  disablePadding: true, label: "Is Deleted"  },
   { id: "edit",  disablePadding: true, label: ""  },
   { id: "delete",  disablePadding: true, label: ""  },

  ];

  function EnhancedTableHead(props) {
    const { classes } = props;
  
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              className={classes.tableCellSmall}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
      <Toolbar style={{ paddingLeft: "16px" }}>
        <Typography
          className={classes.title}
          variant="subtitle2"
          id="tableTitle"
          component="div"
        >
          Notices ({props.totalCount})
        </Typography>
  
        <Typography component="div">
          {props.showDownloadIcon ? (
            <CSVLink
              data={props.data}
              headers={props.headers}
              filename={"Exported Notices.csv"}
              className="btn btn"
              target="_blank"
            >
              <Tooltip title="Export to Excel">
                <img
                  alt="Export To Excel"
                  src="../../images/exportToExcel.png"
                  width="25"
                  height="25"
                />
              </Tooltip>
            </CSVLink>
          ) : (
            <Button
              variant="contained"
              margin="normal"
              style={{
                backgroundColor: "green",
                color: "white",
                fontFamily: "bold",
              }}
              onClick={props.fetchExportToExcel}
            >
              Export
            </Button>
          )}
        </Typography>
      </Toolbar>
    );
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    
    table: {
      width : "auto"
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    tableCellSmall: {
      fontSize: "12px",
      padding: "25px 25px 25px 25px",
      overflow : "hidden",
      wordWrap: "break-word!important",
    },
    tableCellSmall1: {
    fontSize: "12px",
    inlineSize: "170px",

    },
    tableCellSmall111: {
    fontSize: "12px",
    inlineSize: "101px",

    },
    tableRow: {
      // cursor: 'pointer'
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    customImageContainerPreview :{
      height : "100%",
      width : "100%"
    },
    lightBlue: {
      // color: theme.palette.getContrastText(lightBlue[50]),
      backgroundColor: lightBlue[50],
      width: theme.spacing(4),
      height: theme.spacing(4),
      cursor: 'pointer'
    },
    tableCellSmall11: {
      fontSize: "13px",
      padding: "4px 4px 4px 10px", //TRBL
      textAlign: 'center',
      marginRight:"25px",
      marginLeft:"45px",
      maxWidth: 50,
      minWidth:20,
      width: 50,
    },
    tableCellSmall21: {
      fontSize: "13px",
      padding: "6px 0px 6px 10px", //TRBL
      height : "100%",
      textAlign: 'left',
      marginRight:"25px",
      marginLeft:"25px",
      maxWidth: 700,
      minWidth:300,
      width: 700,
      position :"relative"
    },


  }));
  
  export default function EnhancedTable(props) {
    const classes = useStyles();
  
    const handleChangePage = (event, newPage) => {
      props.setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      props.setRowsPerPage(parseInt(event.target.value, 10));
      props.setPage(0);
    };

    const headers = [
      { key: "noticeId", disablePadding: true, label: "Notice Id" },
      { key: "noticeName", disablePadding: true, label: "Notice Name" },
      { key: "toggleMaintenance", disablePadding: true, label: "Active Date" },
      { key: "role", disablePadding: true, label: "Role" },
      { key: "noticeType", disablePadding: true, label: "Notice Type" },

    ];

 
    const emptyRows =
    props.rowsPerPage -
    Math.min(
      props.rowsPerPage,
      props.totalCount - props.page * props.rowsPerPage
    );
    let data = [];
    props.exportToExcel &&
    props.exportToExcel.forEach((element) => {
      let item = element;
      /* eslint-disable no-self-assign */
      item.noticeId = item.noticeId;
      item.noticeName = item.noticeName;
      item.toggleMaintenance = item.toggleMaintenance.length > 0 ? (item.isActive === true ? moment(item.toggleMaintenance[item.toggleMaintenance.length - 1].actionDate).format("[From:] DD-MMM-YYYY hh:mm A") : (`${moment(item.toggleMaintenance[item.toggleMaintenance.length - 2].actionDate).format("[From:] DD-MMM-YYYY hh:mm A")} ${moment(item.toggleMaintenance[item.toggleMaintenance.length - 1].actionDate).format("[To:] DD-MMM-YYYY hh:mm A")} `)) : "NA"
      item.role = item.role;
      item.noticeType = item.noticeType;
      /* eslint-enable no-self-assign */
      data.push(item);
    });

    if (props.activeShopper) {
       var activeShopperRecordExists = props.activeShopper
    }
    if (props.activeBusiness) {
      var activeBusinessRecordExists = props.activeBusiness
    }
    return (
 
        <div className={classes.root}>
          
          <Paper className={classes.paper}>
            <EnhancedTableToolbar
                headers={headers}
                totalCount={props.totalCount}
                data={data}
                showDownloadIcon={props.showDownloadIcon}
                fetchExportToExcel={props.fetchExportToExcel}
             
            />
            { props.data && props.data.length >0 ?
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size="small"
                aria-label="enhanced table"
                totalCount={props.totalCount}
                data={data}
              >
                <EnhancedTableHead
                  classes={classes}
                  order={props.order}
                  orderBy={props.orderBy}
                  rowCount={props.totalCount}
                 
                />
             
                <TableBody>
                 { props.data && props.data.map((row ,index)=>{
                      // const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          className={classes.tableRow}
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell
                            align="left"
                            style={{ fontSize : "13px"}}
                          >
                            {row.noticeId && row.noticeId != null ? row.noticeId : "NA"}
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                           <Typography  className={ classes.tableCellSmall111}>{row.noticeName && row.noticeName != null ? row.noticeName : "NA"}</Typography> 
                          </TableCell>
                          <TableCell align="left"     className={ classes.tableCellSmall1} >
                            {row.toggleMaintenance && row.toggleMaintenance.length > 0 ? (
                              row.isActive ? (
                                <Typography  className={ classes.tableCellSmall1}>
                                  {moment(
                                    row.toggleMaintenance[
                                      row.toggleMaintenance.length - 1
                                    ].actionDate
                                  ).format("[From:] DD-MMM-YYYY hh:mm A")} <br></br>
                                </Typography>
                              ) : (
                                <Typography  className={ classes.tableCellSmall1} >
                                  {moment(
                                    row.toggleMaintenance[
                                      row.toggleMaintenance.length - 2
                                    ].actionDate
                                  ).format("[From:] DD-MMM-YYYY hh:mm A")}

                                  <br></br>
                                  {moment(
                                    row.toggleMaintenance[
                                      row.toggleMaintenance.length - 1
                                    ].actionDate
                                  ).format("[To:] DD-MMM-YYYY hh:mm A")}
                                </Typography>
                              )
                            ) : (
                              "NA"
                            )}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={classes.tableCellSmall13}
                          >
                            {row.role && row.role != null ? row.role : "NA"}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontSize : "13px"}}
                          >
                            {row.noticeType && row.noticeType != null ? row.noticeType : "NA"}
                          </TableCell>
                           {
                            row.noticeType === "Image Notice" ?
                              <TableCell
                                align="left"
                                className={classes.tableCellSmall21}
                              >{<a href={row.photo?.path} target="_blank" rel="noopener noreferrer">
                                <img border="0" src={row.photo?.path} alt="" className={classes.customImageContainerPreview} />
                              </a>}<br></br>{row.notificationBody}</TableCell> : <TableCell className={classes.tableCellSmall21}>{<a href={row.photo?.path} target="_blank" rel="noopener noreferrer">
                                <img border="0" src={row.photo?.path} alt=""  />
                              </a>}<br></br>{row.message}</TableCell>

                          }
                          <TableCell align="left" className={classes.unclickableTableCellSmall}>
                            <FormControlLabel
                              control={
                                <Switch
                                   size="small"
                                   checked={row.isActive}
                                   onChange={() => props.handleToggleSwitch(row)}
                                   color="primary"
                                  disabled={ (row.role === "Business" && activeBusinessRecordExists.length >0 && row.isActive !==  true) || (row.role === "Shopper" && activeShopperRecordExists.length > 0 && row.isActive !==  true)  ? true : false}
                                />
                              }
                              style={{ fontSize: "8px", color: row.isActive ? 'black' : 'red' }}
                              label={row.isActive ? 'Active' : 'Inactive'}
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                          >
                           <Typography  className={ classes.tableCellSmall111}>{row.deleted ? "Yes" : "No"}</Typography> 
                          </TableCell>
                          <TableCell className={classes.tableCellSmall11} scope="row" >
                            <IconButton 
                              style={{
                                float: "right",
                              }}
                              onClick={() => props.handleNoticeEditOpen(row)}
                            >
                              <Avatar className={classes.lightBlue}>
                                <EditIcon color="action" />
                              </Avatar>
                            </IconButton>
                          </TableCell>
                          <TableCell className={classes.tableCellSmall11} scope="row">
                            <IconButton
                              style={{
                                float: "right"
                              }}
                              onClick={() => { props.handleDeleteOpen(row) }}
                            >
                              <Avatar className={classes.lightBlue}>
                                <DeleteIcon color="action" />
                              </Avatar>
                            </IconButton>
                          </TableCell>

                          </TableRow>
                      );
                 })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 33 * emptyRows }}>
                      <TableCell colSpan={6}/> 
                    </TableRow>
                  )}
                </TableBody>
                    
                 
              </Table>
            </TableContainer>
                 : <Typography style={{margin: "0px 400px 0px 400px"}}>No Records Available</Typography>} 
            <TablePagination
              rowsPerPageOptions={[5 ,10, 15, 20, 25, 30, 50]}
              component="div"
              count={props.totalCount}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
          
            />
          </Paper>
        </div>
      );

}