import React, { Component } from 'react';
import { changeHeaderName, requestCallIconHitRecords, getCallIconHitRecords, getCallIconHitRecordsExportToExcel } from '../../actions/dashboardActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CallIconHitTable from './CallIconHitTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from "@material-ui/core/Button";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
});

class CallIconHit extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('callIconHitData')) || { 
            callTimeStamp: "",
            orderId: "",
            caller: "",
            callerId: "",
            callerName: "",
            callerContactNo: "",
            screenName: "",
            receiver: "",
            receiverId: "",
            receiverName: "",
            receiverContactNo: "",
            categoryType: "",
            filterStartDate: "",
            filterEndDate: "",
            page: 0,
            rowsPerPage: 10,
            rows: [],
            showDownloadIcon: false,
            order: -1,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        this.props.changeHeaderName("Call Icon Hit Report");
        this.fetchFilter(); //resetFilter
        window.addEventListener("beforeunload", () => localStorage.removeItem('callIconHitData'));
    };

    resetFilter = async () => {
        this.setState({
            callTimeStamp: "",
            filterStartDate: "",
            filterEndDate: "",
            orderId: "",
            caller: "",
            callerId: "",
            callerName: "",
            callerContactNo: "",
            screenName: "",
            receiver: "",
            receiverId: "",
            receiverName: "",
            receiverContactNo: "",
            categoryType: "",
            page: 0
        }, () => {
            this.fetchFilter();
        })
    };

    fetchFilter = async () => {
        this.props.requestCallIconHitRecords();
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            // callTimeStamp: this.state.callTimeStamp,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            orderId: this.state.orderId.trim(),
            caller: this.state.caller.trim(),
            callerId: this.state.callerId.trim(),
            callerName: this.state.callerName.trim(),
            callerContactNo: this.state.callerContactNo.trim(),
            screenName: this.state.screenName,
            receiver: this.state.receiver,
            receiverId: this.state.receiverId.trim(),
            receiverName: this.state.receiverName.trim(),
            receiverContactNo: this.state.receiverContactNo.trim(),
            categoryType: this.state.categoryType,
        }
        this.props.getCallIconHitRecords(payload);
        this.setState({showDownloadIcon: false });
        localStorage.setItem("callIconHitData", JSON.stringify(this.state));
    };

    fetchCallIconHitRecordsExcel = async () => {
        this.props.requestCallIconHitRecords();
        let payload = {
            // callTimeStamp: this.state.callTimeStamp,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            orderId: this.state.orderId,
            caller: this.state.caller.trim(),
            callerId: this.state.callerId.trim(),
            callerName: this.state.callerName.trim(),
            callerContactNo: this.state.callerContactNo.trim(),
            screenName: this.state.screenName,
            receiver: this.state.receiver,
            receiverId: this.state.receiverId.trim(),
            receiverName: this.state.receiverName.trim(),
            receiverContactNo: this.state.receiverContactNo.trim(),
            categoryType: this.state.categoryType,
        }
        await this.props.getCallIconHitRecordsExportToExcel(payload);
        this.setState({ showDownloadIcon: true });

    };

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchFilter());
    };

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ callTimeStamp: chosenLabel });
    };

    ITEM_HEIGHT = 48;
    ITEM_PADDING_TOP = 8;
    MenuProps = {
        PaperProps: {
            style: {
                maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    
    render() { 
        const { classes } = this.props;

        // console.log("This.props----->",this.props)

        return (
            <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                <Container className={classes.searchPaper}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography style={{ fontSize:'17px'}}>Search/Filter</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} >
                                    <DateRangePicker ranges={this.state.ranges} onApply={this.handleDateRangeChange} maxSpan={{ "months": 12 }} maxDate={moment()} showDropdowns>
                                        <TextField fullWidth margin="dense" name="callTimeStamp" label="Called Time Stamp" value={this.state.callTimeStamp} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>  
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="orderId" label="Order ID" value={this.state.orderId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Caller-label">Caller</InputLabel>
                                        <Select fullWidth labelId="Caller-label" name="caller" value={this.state.caller} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps}>
                                            <MenuItem value={"Business"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Business"/> </MenuItem>
                                            <MenuItem value={"Consumer"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Consumer"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerId" label="Caller ID" value={this.state.callerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerName" label="Caller Name" value={this.state.callerName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="callerContactNo" label="Caller Contact No." value={this.state.callerContactNo} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Screen-Name-label">Screen Name</InputLabel>
                                        <Select fullWidth labelId="Screen-Name-label" name="screenName" value={this.state.screenName} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps} >
                                            <MenuItem value={"About"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="About"/> </MenuItem>
                                            <MenuItem value={"Order Placed"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Order Placed"/> </MenuItem>
                                            <MenuItem value={"Order Details"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Order Details"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Receiver-label">Receiver</InputLabel>
                                        <Select fullWidth labelId="Receiver-label" name="receiver" value={this.state.receiver} onChange={this.handleFilterChange} input={<Input />} MenuProps={this.MenuProps} >
                                            <MenuItem value={"Business"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Business"/> </MenuItem>
                                            <MenuItem value={"Consumer"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Consumer"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverId" label="Receiver ID" value={this.state.receiverId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverName" label="Receiver Name" value={this.state.receiverName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <TextField fullWidth margin="dense" name="receiverContactNo" label="Receiver Contact No." value={this.state.receiverContactNo} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>    
                                <Grid item xs={3} >
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="Category-Type-label">Category Type</InputLabel>
                                        <Select fullWidth labelId="Category-Type-label" name="categoryType" value={this.state.categoryType} onChange={this.handleFilterChange} input={<Input />}  MenuProps={this.MenuProps} >
                                            <MenuItem value={"Shopping"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Shopping"/> </MenuItem>
                                            <MenuItem value={"Services"} style={{ minHeight: "28px", height: "28px" }}> <ListItemText primary="Services"/> </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>                                    
                                <Grid item xs={9}></Grid> <Grid item xs={8}></Grid><Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" onClick={this.resetFilter}> Reset </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button type="submit" fullWidth variant="contained" margin="normal" color="primary" onClick={
                                         ()=>this.setPage(0)
                                        // this.fetchFilter
                                        }> Search </Button>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>

                <Container className={classes.container}>
                    {
                        this.props.dashboard.callRecordsList && this.props.dashboard.callRecordsList.callRecordsList ?
                            <CallIconHitTable 
                                data={this.props.dashboard.callRecordsList.callRecordsList}
                                totalCount={this.props.dashboard.callRecordsList.totalCount}
                                exportToExcel={this.props.dashboard.callIconHitExcelRecords}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                showDownloadIcon={this.state.showDownloadIcon}
                                fetchCallIconHitRecordsExcel={this.fetchCallIconHitRecordsExcel}
                            />
                        : null
                    }
                </Container>
            </Container>

            <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}> <CircularProgress color="inherit" /> </Backdrop>
        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
    }
}
export default connect(mapStateToProps, { changeHeaderName, requestCallIconHitRecords, getCallIconHitRecords, getCallIconHitRecordsExportToExcel })(withStyles(styles)(CallIconHit));
