import axios from "axios";
import {
    SET_ADMIN_LIST,
    SELECT_ADMIN,
    SET_ADMIN_SUCCESS_MESSAGE,
    SET_ADMIN_ERROR_MESSAGE,
    GET_OTP_RECORDS
} from "./types";

//Get all the category types available in the MarketPlace.
export const getAllAdmin = () => async dispatch => {
    const response = await axios.get("/api/admin");
    dispatch({ type: SET_ADMIN_LIST, payload: response.data });
};

// Add a admin to the admin Table
export const addToAdmin = (admin) => async dispatch => {
    try {
        await axios.post("/api/admin", {
            "username": admin.username,
            "password": admin.password,
            "firstName": admin.firstName,
            "lastName": admin.lastName,
            "email": admin.email,
            "role": admin.role,
            "mobileNumber": admin.mobileNumber
        });
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "Created Admin user " + admin.username + " successfully" });
    } catch (err) {
        if (err.response.status === 409) {
            dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "The username already exists. Please use a different username." });
        } else {
            dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Failed to create " + admin.username });
        }
    }
};

// Add a admin to the admin Table
export const changeAdminPassword = (admin) => async dispatch => {
    try {
        await axios.patch("/api/admin/changepassword/" + admin.id, {
            "oldPassword": admin.oldPassword,
            "newPassword": admin.password,
        });
    } catch (err) {
        //console.log("addToAdmin", admin, err);
    }
};

export const setAdmin = res => {
    return {
        type: SELECT_ADMIN,
        payload: res
    };
};

export const deleteAdmin = (admin) => async dispatch => {
    try {
        await axios.delete("/api/admin/" + admin._id);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "Deleted " + admin.username + " successfully" });
    } catch (err) {
        console.log(err);
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Failed to delete " + admin.username });
    }
};

export const clearSucessMsg = () => async dispatch => {
    dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: null });
};

export const clearErrorMsg = () => async dispatch => {
    dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: null });
};

export const getOtpReport = (payload) => async dispatch => {
    if (payload && payload.reset) {
        dispatch({ type: GET_OTP_RECORDS, payload: {} });
    } else {
        const response = await axios.get(`api/analysisReport/OtpReport`, {
            params: payload
        });
        dispatch({ type: GET_OTP_RECORDS, payload: response });
    }
};

export const updateAdmin = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/admin/${payload._id}`, payload);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "Admin updated successfully." });
    } catch (err) {
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "Failed to update Admin" });
    }
};