import { connect } from "react-redux";
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';
import 'bootstrap-daterangepicker/daterangepicker.css';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import React, { Component } from "react";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { changeHeaderName,getCronJobPerfRecords,getCronJobPerfExportToExcel,requestCronPerfRecords } from '../../actions/dashboardActions';
import CronJobPerformanceTable from './cronJobPerformanceTable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class cronJobReport extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('cronJobPerfData')) || {
            order: -1,           
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            // filterStartDate: "",
            // filterEndDate: "",
            cronJobName:"",
            filterStartofStartDate:"",
            filterEndofStartDate:"",
            filterStarOfEndDate:"",
            filterEndofEndDate:"",
            ranges: { },
            startOnDateRange:"",
            endedOnDateRange:"",
            preparexportToExcel: false,
            snackOpen: false,
            errorMsg:null,
            successMsg:null,
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Cron Job Performance Report");
        this.fetchFilter(); //resetFilter
        window.addEventListener("beforeunload", () => localStorage.removeItem('cronJobPerfData'));
    }

    fetchFilter = async () => {
        this.props.requestCronPerfRecords();
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            // filterStartDate: this.state.filterStartDate,
            // filterEndDate: this.state.filterEndDate,
            cronJobName: this.state.cronJobName,
            filterStartofStartDate:this.state.filterStartofStartDate,
            filterEndofStartDate:this.state.filterEndofStartDate,
            filterStarOfEndDate:this.state.filterStarOfEndDate,
            filterEndofEndDate:this.state.filterEndofEndDate,

        }
        this.props.getCronJobPerfRecords(payload);
        this.setState({ preparexportToExcel: false });
        localStorage.setItem("cronJobPerfData", JSON.stringify(this.state));

    };

    fetchCronJobPerformanceExcel = async () => {
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            // filterStartDate: this.state.filterStartDate,
            // filterEndDate: this.state.filterEndDate,
            cronJobName: this.state.cronJobName,
            filterStartofStartDate:this.state.filterStartofStartDate,
            filterEndofStartDate:this.state.filterEndofStartDate,
            filterStarOfEndDate:this.state.filterStarOfEndDate,
            filterEndofEndDate:this.state.filterEndofEndDate,

        }
        
        this.props.getCronJobPerfExportToExcel(payload);
        this.setState({  preparexportToExcel: true  });
    }


    setOrder = (order) => {
        this.setState({ order }, () => this.fetchFilter());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchFilter());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchFilter());
    }
   //Event to search cron start On date
    handleStartDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        //picker.endDate.add(1, "days");
        this.setState({
            filterStartofStartDate: picker.startDate.format(),
            filterEndofStartDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ startOnDateRange: chosenLabel });
    }

    //Event to search Cron End on date
    handleEndDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        //picker.endDate.add(1, "days");
        this.setState({
            filterStarOfEndDate: picker.startDate.format(),
            filterEndofEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ endedOnDateRange: chosenLabel });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'dateOfInvite',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            // filterStartDate: "",
            // filterEndDate: "",
            // firstName: ""
            cronJobName:"",
            filterStartofStartDate:"",
            filterEndofStartDate:"",
            filterStarOfEndDate:"",
            filterEndofEndDate:"",
            startOnDateRange:"",
            endedOnDateRange:"",
        }, () => {
            this.fetchFilter();
        })
    }

    handleFilterChange = (event) => {
        if(event.target.name === "cronJobName"){
             this.setState({
                cronJobName:event.target.value,
           })}
            
    }
    
    ITEM_HEIGHT = 48;
    ITEM_PADDING_TOP = 8;
    MenuProps = {
        PaperProps: {
            style: {
                maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    render() {
        // console.log("*******Props value**********",this.props,"*****************")
        const { classes } = this.props;
        let muiAlert = null;
        if(this.state.errorMsg){
            muiAlert = <MuiAlert elevation={6} variant="filled" severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        }
        if(this.state.successMsg){
            muiAlert = <MuiAlert elevation={6} variant="filled" severity="success">
                {this.state.successMsg}
            </MuiAlert>
        }
        return (
            <Container className={classes.content}>
                 <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>
                <div className={classes.appBarSpacer} />
                
                {/* Search Filter model start */}
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Cron Job Performance Report</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                            <Grid item xs={4}>
                            <FormControl margin="dense" variant="outlined" className={classes.formControl} >
                                        <InputLabel>Cron Job Name</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-outlined-label"
                                            name="cronJobName"
                                            value={this.state.cronJobName}
                                            onChange={this.handleFilterChange}
                                            //input={<Input />}
                                            style={{ width: "300px" }}
                                            MenuProps={this.MenuProps}
                                            label="Cron Job Name"
                                        >
                                             {
                                            (this.props.dashboard.cronJobPerfList && this.props.dashboard.cronJobPerfList.uniqCronName ?
                                            this.props.dashboard.cronJobPerfList.uniqCronName.map((cronName) => { if(cronName.cronJob !== "Business Open Close Cron" && cronName.cronJob !== "getTodaysBusinesses" && cronName.cronJob !== "Business resume time" && cronName.cronJob !== "Business open close")
                                                return <MenuItem title={cronName.cronJob}name={cronName.cronJob} value={cronName.cronJob}>{(cronName.cronJob)}</MenuItem>
                                              }) : null)
                    
                                          }
                                        </Select>
                                    </FormControl>
                                    {/* <TextField fullWidth margin="dense" name="cronJobName" label="Cron Job Name" value={this.state.cronJobName} variant="outlined" onChange={this.handleFilterChange} /> */}
                                </Grid>
                                <Grid item xs={4}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                        }}
                                        onApply={this.handleStartDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" label="Cron Performance Date Range" value={this.state.startOnDateRange} style={{ width: "300px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    <DateRangePicker
                                       ranges={{
                                        'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    }}
                                        onApply={this.handleEndDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" label="Ended On Date Range" value={this.state.endedOnDateRange} style={{ width: "300px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid> */}
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        // onClick={this.fetchPromocodes}
                                        onClick={()=>this.setPage(0)}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                {/* Search filter model End */}
                <Container className={classes.container}>
                    {
                        this.props.dashboard.cronJobPerfList && this.props.dashboard.cronJobPerfList ?
                            <CronJobPerformanceTable
                                data={this.props.dashboard.cronJobPerfList.cronJobs}
                                exportToExcel={this.props.dashboard.cronJobPerfList.exportData}
                                uniqCronName={this.props.dashboard.cronJobPerfList.uniqCronName}
                                totalCount={this.props.dashboard.cronJobPerfList.totalCount}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}                                
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                fetchCronJobPerformanceExcel={this.fetchCronJobPerformanceExcel}
                                showDownloadIcon={this.state.preparexportToExcel}
                                user={this.props.user}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.dashboard.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}
const mapStateToProps = state => {
   
    return {
        dashboard: state.dashboard,
        isFetching:state.dashboard.isFetching
    }
    
};

export default connect(mapStateToProps, { changeHeaderName,getCronJobPerfRecords,getCronJobPerfExportToExcel,requestCronPerfRecords })(withStyles(styles)(cronJobReport));