import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TabPanel from "../../utils/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ManageHeadAndSubHead from "./ManageHeadAndSubHead";
import ManageService from "./ManageService";
import ServiceDetailView from './ServiceDetailView';
import SubHeadDetailView from './SubHeadDetailView';
import HeadDetailView from './HeadDetailView';
import ShowService from './ShowService';
import ManageBusinessSequence from "./ManageBusinessSequence";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
    getHeadByBusinessId,
    getHeadSubheadHierarchy,
    deleteHeadSubheadService,
    getServicesByBusinessId,
    uploadServices,
    updateService,
    deleteMultipleServices,
    clearMsg,
    deleteSubcategoryButton,
    getServicesMenuExportToExcel,
    getPetPoojaMenuExportToExcel,
    
} from '../../../actions/businessActions';
import HeadSubheadTable from "./HeadSubheadTable";
import ServiceTable from './ServiceTable';
import BulkUpload from '../../common/FileUploader';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import ManageVariationGroups from "./ManageVariationGroup";
import ManageAddonList from "./ManageAddon/ManageAddonList";
import Linking from "./ManageAddon/Linking";
import { variationGroupsToLinkCatalogItem  } from "../../../actions/variationsAction";
import LinkedAddonsModal from "./LinkedAddonsModal";
import Modal from "@material-ui/core/Modal";
import { updateVariationsFlag } from "../../../actions/variationsAction"
import { Container } from "@material-ui/core";
import AddonGroup from "./ManageAddonGroup/AddonGroup";



const styles = (theme) => ({
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: "100%"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    headers: {
        fontSize: '10px'
    },
    tabPadding : {
        minWidth:"100px"
    }
});

class ServiceSection extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            serviceModalOpen: false,
            headModalOpen: false,
            subHeadModalOpen: false,
            edit: false,
            headsubHeadData: {},
            editService: false,
            serviceData: {},
            showService: false,
            selectedService: {},
            order: - 1,
            orderBy: "service",
            page: 0,
            rowsPerPage: 50,
            pageOfHeadSubHead: 0,
            rowsPerPageOfHeadSubHead: 10,
            snackOpen: false,
            sucessMsg: null,
            errorMsg: null,
            parentHead: {},
            serviceFilter: {},
            headSubheadFilter: {},
            numSelected: "",
            numSelectedSubHead : "",
            selected: [],
            headSubheadSelected: [],
            selectedSubHead : [],
            modalDeleteOpen: false,
            headModalDeleteOpen: false,
            subHeadModalDeleteOpen: false,
            showDownloadIcon: false,
            showDownloadExcelIcon: false,
            searchedSubhead: "",
            searchedHead: "",
            headers: [
                { key: 'serviceId', label: 'itemId' },
                { key: 'petPoojaItemId', label: 'petPoojaItemId' },
                { key: 'petPoojaVariationId', label: 'petPoojaVariationId' },
                { key: 'variation.variationId', label: 'XirifyVariationID' },
                { key: 'variation.variationGroupName', label: 'XirifyVariationGroupName' },
                { key: 'service', label: 'itemName' },
                { key: 'description', label: 'itemDescription' },
                { key: 'cost.costType', label: 'itemCostType' },
                { key: 'cost.from', label: 'itemCostFrom' },
                { key: 'cost.to', label: 'itemCostTo' },
                { key: 'cost.fixedCost', label: 'itemFixedCost' },
                { key: 'cost.quantity', label: 'Quantity' },
                { key: 'cost.unit', label: 'itemUnit' },
                { key: 'servicesPackagingCharges', label: 'itemPackagingCharges' },
                { key: 'cost.gstConf.gst', label: 'itemGst (5%, 12%, 18%, 28%)' },
                { key: 'cost.gstConf.cgst', label: 'itemCgst' },
                { key: 'cost.gstConf.sgst', label: 'itemSgst' },
                { key: 'cost.gstConf.igst', label: 'itemIgst' },
                { key: 'isEnabled', label: 'itemIsEnabled' },
                { key: 'allowLooseQuantity', label: 'itemAllowLooseQuantity' },
                { key: 'foodType', label: 'itemFoodType' },
                { key: 'availability.isActive', label: 'itemAvailabilityIsActive' },
                { key: 'isDisplay', label: 'itemIsDisplay' },
                { key: 'mustTry', label: 'itemMustTry' },
                { key: 'newArrival', label: 'itemNew' },
                { key: 'excludeItemTags', label: 'excludeItemTagsFlag' },
                { key: 'workingHours', label: 'itemWorkingHrs' },
                { key: 'availability.holidays', label: 'itemHolidays' },
                { key: 'photos[0].path', label: 'itemPhotos' },
                { key: 'headSubHead', label: 'itemsHeads' },
                { key: 'serviceOffers.discount.offers[0].discountType', label: 'itemDiscountTypeOne(FLAT,PERCENTAGE)' },
                { key: 'serviceOffers.discount.offers[0].off', label: 'itemDiscountOffOne' },
                { key: 'serviceOffers.discount.offers[0].maxDiscountCap', label: 'itemMaxDiscountCapOne' },
                { key: 'serviceOffers.discount.offers[0].isActive', label: 'itemDiscountIsActiveOne' },

                { key: 'serviceOffers.discount.offers[1].discountType', label: 'itemDiscountTypeTwo(FLAT,PERCENTAGE)' },
                { key: 'serviceOffers.discount.offers[1].off', label: 'itemDiscountOffTwo' },
                { key: 'serviceOffers.discount.offers[1].maxDiscountCap', label: 'itemMaxDiscountCapTwo' },
                { key: 'serviceOffers.discount.offers[1].isActive', label: 'itemDiscountIsActiveTwo' },

                { key: 'serviceOffers.discount.offers[2].discountType', label: 'itemDiscountTypeThree(FLAT,PERCENTAGE)' },
                { key: 'serviceOffers.discount.offers[2].off', label: 'itemDiscountOffThree' },
                { key: 'serviceOffers.discount.offers[2].maxDiscountCap', label: 'itemMaxDiscountCapThree' },
                { key: 'serviceOffers.discount.offers[2].isActive', label: 'itemDiscountIsActiveThree' },
                { key: 'addOnChildServiceItems', label: 'SingleAddOnId'},
                { key: 'linkedAddOnGroups', label: 'AddnOnGroupId' },
            ],

            petpoojaheaders: [
                { key: 'itemid', label: 'petPoojaItemId' },
                { key: 'itemvariationid', label: 'petPoojaVariationId' },
                { key: 'itemname', label: 'petPoojaItemName' },
                { key: 'itemdescription', label: 'petPoojaItemDescription' },
                { key: 'price', label: 'petPoojaItemPrice' },
                { key: 'itemtype', label: 'petPoojaItemType' },
                { key: 'item_attributeid', label: 'petPoojaItemFoodType' },
                { key: 'in_stock', label: 'petPoojaItemInStockCount' },
                { key: 'taxes', label: 'taxId,taxName,tax(%)' },
                { key: 'item_ordertype', label: 'petPoojaItemOrderType' },
                { key: 'item_image_url', label: 'petPoojaItemImageUrl' },
                { key: 'item_packingcharges', label: 'petPoojaItemPackingCharges' },
                { key: 'minimumpreparationtime', label: 'petPoojaMinimumPreparationTime' },
                { key: 'itemCategoryData.categoryname', label: 'petPoojaItemCategoryName' },
                { key: 'variation_groupname', label: 'petPoojaVariationGroupName' },
                { key: 'discountType', label: 'Discount Type' },
                { key: 'off', label: 'Off' },
                { key: 'maxDiscountCap', label: 'Max Discount Cap' }
            ],
            itemTags: "",
            isAddonLinked : "",
            variations : "",
            variationGroupName : "",
            handleLinkedAddonModalOpen : false,
            itemIdToFetchAddon : ""
        };
    }

    componentDidMount =  async()=> {
        await  this.filterServices();
        this.getHeadSubheads()
        if (this.state.headSubheadSelected.length > 0 || this.state.selectedSubHead.length > 0) {
            await this.editheadSubhead();
            this.openSubheadModal();
            this.openHeadModal();
        }
       
    };

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.sucessMsg === null && this.state.sucessMsg) ||
            (prevState.errorMsg === null && this.state.errorMsg)) {
            this.setState({ snackOpen: true });
        }
        if (prevState.sucessMsg !== this.state.sucessMsg) {
            this.fetchServices();
        }
        if (this.state.headSubheadSelected.length > 0 || this.state.selectedSubHead.length > 0) {
            this.editheadSubhead();
        }
        if (this.props.business.bulkUploadSuccess) {
            this.setState({ sucessMsg: this.props.business.bulkUploadSuccess })
            this.setState({ snackOpen: true });
            this.props.clearMsg();
            this.getHeadSubheads();
            this.fetchServices();
        } else if (this.props.business.bulkUploadError) {
            this.setState({ errorMsg: this.props.business.bulkUploadError });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
        } else if (this.props.business.deleteSubcategoryMsg) {
            this.setState({ sucessMsg: this.props.business.deleteSubcategoryMsg });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
    
        } else if (this.props.business.sucessMsgAddon) {
            this.setState({ sucessMsg: this.props.business.sucessMsgAddon });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
        
        } else if (this.props.business.sucessMsgAddon) {
            this.setState({ sucessMsg: this.props.business.sucessMsgAddon });
            this.setState({ snackOpen: true });
            this.props.clearMsg();
        }
    }
    handleSnackClose = () => {
        this.props.clearMsg();
        this.setState({
            snackOpen: false,
            modalDeleteOpen: false,
            errorMsg: null,
            sucessMsg: null
        });
        this.fetchServices();
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };
    openServiceModal = async() => {
		let payload = {businessId:this.props.businessDetails._id}
        await this.props.variationGroupsToLinkCatalogItem(payload)
        this.setState({ serviceModalOpen: true });
    }
    closeServiceModal = () => {
        this.setState({ editService: false, serviceModalOpen: false });
    }
    openHeadModal = () => {
        this.setState({ headModalOpen: true });
    }
    closeHeadModal = () => {
        this.setState({ headModalOpen: false, edit: false });
    }
    openSubheadModal = () => {
        this.props.getHeadByBusinessId(this.props.businessDetails._id)
        this.setState({ subHeadModalOpen: true });
    }
    closeSubheadModal = () => {
        this.setState({ subHeadModalOpen: false, edit: false });
    }
    openShowService = () => {
        this.setState({ showService: true });
    }
    closeShowService = () => {
        this.setState({ showService: false });
    }
    getHeadSubheads = async () => {
        let data = {
            id: this.props.businessDetails._id,
            data: {
                head: this.state.headSubheadFilter && this.state.headSubheadFilter.head ? this.state.headSubheadFilter.head : null,
                subhead: this.state.headSubheadFilter && this.state.headSubheadFilter.subhead ? this.state.headSubheadFilter.subhead : null,
                rowsPerPage: this.state.rowsPerPageOfHeadSubHead,
                skip: this.state.pageOfHeadSubHead * this.state.rowsPerPageOfHeadSubHead
            }
        }
        await this.props.getHeadSubheadHierarchy(data);
    }

    handleDeleteOpen = () => {
        this.setState({
            modalDeleteOpen: true,
        });
    }

    deleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }
    handleDeleteOpenForHeadSubHead = () => {
        this.setState({
            headModalDeleteOpen: true,
        });
    }

    deleteCloseForHeadSubHead = () => {
        this.setState({ headModalDeleteOpen: false });
    }
    handleDeleteOpenForSubHead = () => {
        this.setState({
            subHeadModalDeleteOpen: true,
        });
    }

    deleteCloseForSubHead = () => {
        this.setState({ subHeadModalDeleteOpen: false });
    }

    deleteMultiServices = async () => {
        let payload = {
            businessId: this.props.businessDetails._id,
            data: {
                serviceIds: this.state.selected
            }
        }
        await this.props.deleteMultipleServices(payload)
        this.setState({ selected: [] })
        this.fetchServices();
        this.deleteClose();
    }

    deleteheadSubhead = async () => {
        let data = [];
        let _id = [];
        let selectedHeadSubHeadData = this.state.headSubheadSelected
        let allHeadSubHeadData = this.props.business.headSubheadHeirarchy.data
        let selectedSubHeadData = this.state.selectedSubHead
       

        if (this.state.headSubheadSelected && this.state.headSubheadSelected.length > 0) {

            for (let i = 0; i < allHeadSubHeadData.length; i++) {
                const elementFirst = allHeadSubHeadData[i];
                for (let j = 0; j < selectedHeadSubHeadData.length; j++) {
                    const elementSecond = selectedHeadSubHeadData[j];
                    if (elementFirst._id === elementSecond) {
                        data.push(elementFirst)
                    }
                }
            }

            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                _id.push(element._id)
            }

        }

        if (this.state.selectedSubHead && this.state.selectedSubHead.length > 0) {
            let subHead = []
            let finalSubHead = []
            for (let i = 0; i < allHeadSubHeadData.length; i++) {
                if (!allHeadSubHeadData[i].hasOwnProperty("children")) {
                    continue;
                }
                let allChildrens = allHeadSubHeadData[i].children;
                subHead.push(allChildrens);
            }
            for (let j = 0; j < subHead.length; j++) {
                finalSubHead.push(...subHead[j])

            }
            for (let a = 0; a < finalSubHead.length; a++) {
                const elementA = finalSubHead[a];
                for (let b = 0; b < selectedSubHeadData.length; b++) {
                    const elementB = selectedSubHeadData[b];
                    if (elementA._id === elementB) {
                        data.push(elementA)
                    }

                }

            }
            for (let i = 0; i < data.length; i++) {
                const element = data[i];
                _id.push(element._id)
            }

        }
        let payload = {
            businessId: data[0].businessId,
            type: data[0].type,
            id:  _id
        };
        await this.props.deleteHeadSubheadService(payload);
        this.setState({ headSubheadSelected: [] });
        this.setState({ selectedSubHead: [] });
        await this.getHeadSubheads();
        this.fetchServices();
        await this.props.getHeadByBusinessId(this.props.businessDetails._id);
        this.deleteCloseForHeadSubHead();
    }
    editheadSubhead = (head) => {
        var parentsName;
        let data = {};
        this.setState({
            edit: true,
            headsubHeadData: head
        })
        if (head.type === "SubHead") {
            if (this.props.business.Heads) {
                this.props.business.Heads.map((parent) => {
                    if (parent._id.toString() === head.parentId.toString()) {
                        parentsName = parent.name
                    }
                })
            }

            let parentHead = this.props.business && this.props.business.Heads &&
                this.props.business.Heads.find(tempHead => tempHead._id.toString() === head.parentId.toString());
            if (parentsName) {
                data = head
                data.parentsName = parentsName;
            }
            this.setState({
                headsubHeadData: data,
                parentHead: parentHead
            })
            this.openSubheadModal();
        }
        else if (head.type === "Head") {
            this.openHeadModal();
        }
    }
    editService = (service) => {
        this.setState({
            editService: true,
            serviceData: service
        })
        this.openServiceModal();
    }
    handleListItemClick = (data) => {
        this.openShowService();
        this.setState({ selectedService: data });
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetchServices());
    };

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchServices());
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchServices());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };
    setPageOfHeadSubHead = (page) => {
        this.setState({ pageOfHeadSubHead: page }, () => this.getHeadSubheads());
    };
    setRowsPerPageOfHeadSubHead = (rowsPerPage) => {
        this.setState({ rowsPerPageOfHeadSubHead: rowsPerPage });
    };


    bulkUpload = (file) => {
        let payload = {
            businessId: this.props.businessDetails._id,
            file: file
        }
        this.props.uploadServices(payload);
    }
   

    handleSwitchChange = async (event, service) => {
        let groupPayload = {}

        let updatePayload = {};
        if (event.target.name === 'availability') {
            let availabilityData = service.availability;
            availabilityData.isActive = event.target.checked;
            updatePayload.availability = availabilityData;
            if ( service.variation?.variationGroupObjectId) {
                groupPayload.variationGroupId =  service.variation?.variationGroupObjectId;
                groupPayload.serviceId =  service._id;
                groupPayload.availability = availabilityData.isActive
            }
        } else {
            updatePayload[event.target.name] = event.target.checked;
            if ( service.variation?.variationGroupObjectId) {
                groupPayload.variationGroupId =  service.variation?.variationGroupObjectId;
                groupPayload.serviceId =  service._id; 
                groupPayload[event.target.name] = event.target.checked;
            }
        }
       
         if (service.variation?.variationGroupObjectId) {
            await this.props.updateVariationsFlag(groupPayload)
        }
         
        let payload = {
            serviceId: service._id,
            data: updatePayload
        }
        await this.props.updateService(payload);
        this.fetchServices();
    }

    fetchServices = async (data) => {
        let payload = {
            id: this.props.businessDetails._id,
            data: {
                orderBy: this.state.orderBy,
                order: this.state.order,
                rowsPerPage: this.state.rowsPerPage,
                skip: this.state.page * this.state.rowsPerPage,
                service: this.state.serviceFilter && this.state.serviceFilter.service ? this.state.serviceFilter.service : null,
                head: this.state.serviceFilter && this.state.serviceFilter.head ? this.state.serviceFilter.head : null,
                subhead: this.state.serviceFilter && this.state.serviceFilter.subhead ? this.state.serviceFilter.subhead : null,
                availability: this.state.serviceFilter && this.state.serviceFilter.availability,
                isEnabled: this.state.serviceFilter && this.state.serviceFilter.isEnabled,
                isDisplay: this.state.serviceFilter && this.state.serviceFilter.isDisplay,
                itemTags: this.state.serviceFilter && this.state.serviceFilter.itemTags,
                variationGroupName : this.state.serviceFilter &&  this.state.serviceFilter.variationGroupName,
                variations : this.state.serviceFilter &&  this.state.serviceFilter.variations,
                isAddonLinked : this.state.serviceFilter && this.state.serviceFilter.isAddonLinked,
                foodType: this.state.serviceFilter && this.state.serviceFilter.foodType
            }
        }
        await this.props.getServicesByBusinessId(payload);
        this.setState({ showDownloadIcon: false });

    }
    clearFilter = async () => {
        await this.setState({
            serviceFilter: {},
            headSubheadFilter: {},
            handleLinkedAddonModalOpen : false

        });
    }
    filterServices = async (data) => {
        await this.setState({ serviceFilter: data, page: 0 });
        this.fetchServices();
    }
    setHeadSubheadFilter = async (data) => {
        await this.setState({ headSubheadFilter: data });
        this.getHeadSubheads();
    }
    setSelected = (selected) => {
        this.setState({ selected });
    }
    setSelectedHeadSubHead = (headSubheadSelected) => {
        this.setState({ headSubheadSelected });
    }
    setSelectedSubHead = (selectedSubHead) => {
        this.setState({ selectedSubHead });
    }
    removeSubcategory = async () => {
        let payload = {
            businessId: this.props.businessDetails._id
        };
        await this.props.deleteSubcategoryButton(payload);
    }

    fetchExportToExcel = async () => {
        let payload = {
            businessId: this.props.businessDetails._id,
            data: {
                orderBy: this.state.orderBy,
                order: this.state.order,
                service: this.state.serviceFilter && this.state.serviceFilter.service ? this.state.serviceFilter.service : null,
                head: this.state.serviceFilter && this.state.serviceFilter.head ? this.state.serviceFilter.head : null,
                subhead: this.state.serviceFilter && this.state.serviceFilter.subhead ? this.state.serviceFilter.subhead : null,
                availability: this.state.serviceFilter && this.state.serviceFilter.availability,
                isEnabled: this.state.serviceFilter && this.state.serviceFilter.isEnabled,
                isDisplay: this.state.serviceFilter && this.state.serviceFilter.isDisplay,
                itemTags: this.state.serviceFilter && this.state.serviceFilter.itemTags,
                foodType: this.state.serviceFilter && this.state.serviceFilter.foodType
            }
        }
        await this.props.getServicesMenuExportToExcel(payload);
        this.setState({ showDownloadIcon: true });
    }

    exportToExcelPetPoojaCatalog = async () => {
        let payload = {
            businessId: this.props.businessDetails._id,
        }
        await this.props.getPetPoojaMenuExportToExcel(payload);
        this.setState({ showDownloadExcelIcon: true });
    }

    setSearchedSubhead = (searchedSubhead) => {
        this.setState({ searchedSubhead: searchedSubhead });
    }

    setSearchedHead = (searchedHead) => {
        this.setState({ searchedHead: searchedHead });
    }

    handleLinkedAddonOpen = (_id)=>{
        this.setState({ handleLinkedAddonModalOpen : true , itemIdToFetchAddon : _id })
        }
    handleLinkedAddonModalClose = async()=>{
        this.setState({ handleLinkedAddonModalOpen : false , itemIdToFetchAddon : "" })
        await  this.fetchServices();
    }

    render() {
        const { classes } = this.props;
        let muiAlert = null;
        let footerButton = null;
        if (this.state.sucessMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                {this.state.sucessMsg}
            </MuiAlert>
        } else if (this.state.errorMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        }

        return (
            <div style={{ padding: "0px" }}>
                 
                <Grid container direction="row" >
                    <Grid>
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            style={{ padding : "0px"}}
                        >
                            <Tab onClick={ ()=> this.filterServices()}   className={classes.tabPadding} label={this.props.user.role === "areaManager" ? "Manage Catalog" : "Catalog"} />
                            <Tab className={classes.tabPadding} label={this.props.user.role === "areaManager" ? "Manage Heads & Sub-Heads" : "Heads & Sub-Heads"} />
                            <Tab className={classes.tabPadding} label={this.props.user.role === "areaManager" ? "Manage Variation Groups" : "Variation Groups"} />
                            <Tab className={classes.tabPadding} label={"Addons"}  />
                            <Tab className={classes.tabPadding} label={"Addon Groups"}  />
                            <Tab className={classes.tabPadding} label={"LINKING"}  />
                            {this.props.user.role === "areaManager" ? (
                                <Tab className={classes.tabPadding} label={"Manage Item Sequence"} />
                            ) : null}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                    {this.props.user.role === "areaManager" && this.state.value === 1 ? (
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "right", margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.handleDeleteOpenForHeadSubHead}
                                // onClick={this.deleteMultiServices}
                                disabled={this.state.headSubheadSelected.length < 1 && this.state.selectedSubHead.length < 1}
                            >
                                Delete
                            </Button>
                            <Button
                                style={{ float: "right", margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.openSubheadModal}
                            >
                                Add Sub-head
                            </Button>
                            <Button
                                style={{ float: "right", margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.openHeadModal}
                            >
                                Add Head
                            </Button>

                        </Grid>
                    ) : null}
                    <Container>
                    {this.props.user.role === "areaManager" && this.state.value === 0 ? (
                        
                        <Grid item xs={12} sm={12}>
                            <Button
                                style={{ float: "right", margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.handleDeleteOpen}
                                // onClick={this.deleteMultiServices}
                                disabled={this.state.selected.length < 1}
                            >
                                Delete
                            </Button>
                            <BulkUpload handleFile={this.bulkUpload} buttonName="CATALOG BULK UPLOAD" />
                            <Button
                                style={{ float: "right", margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.openServiceModal}
                            >
                                Add Item
                            </Button>
                            {this.state.showDownloadIcon ?
                                <CSVLink
                                    style={{ float: "right", margin: "10px" }}
                                    headers={this.state.headers}
                                    data={this.props.business.servicesMenuExcelRecords}
                                    filename={this.props.businessDetails.businessName + ".csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                :
                                <Button
                                    style={{ float: "right", margin: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.fetchExportToExcel}
                                >
                                    DOWNLOAD CATALOG
                                </Button>
                            }
                            {this.state.showDownloadExcelIcon ?
                                <CSVLink
                                    style={{ float: "right", margin: "10px" }}
                                    headers={this.state.petpoojaheaders}
                                    data={this.props.business.petPoojaMenuExcelRecords}
                                    filename={this.props.businessDetails.businessName + "_petPoojaCatalog.csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                :
                                <Button
                                    style={{ float: "right", margin: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.exportToExcelPetPoojaCatalog}
                                    disabled={!this.props.businessDetails.isPetpoojaEnabled === true}
                                >
                                    Petpooja Catalog Download
                                </Button>
                            }

                        </Grid>

                    ) : null}
                    </Container>
                   

                    {(this.props.user.role === "admin" && this.state.value  === 0)  || ( this.props.user.role === "admin" && this.state.value  === 1)  ?
                        <Grid xs={12}>
                            <Button
                                style={{ float: "right", fontWeight: "bold", margin: "5px" }}
                                variant="contained"
                                color="secondary"
                                onClick={this.removeSubcategory}
                            >
                                REMOVE SUBCATEGORY FROM ALL SERVICES
                            </Button>
                            {this.state.showDownloadIcon ?
                                <CSVLink
                                    style={{ float: "right", margin: "10px" }}
                                    data={this.props.business.servicesMenuExcelRecords}
                                    headers={this.state.headers}
                                    filename={this.props.businessDetails.businessName + ".csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                :
                                <Button
                                    style={{ float: "right", margin: "5px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.fetchExportToExcel}
                                >
                                    DOWNLOAD CATALOG
                                </Button>
                            }
                        </Grid>
                        : null}
                    {(this.props.user.role === "regionalHead"   && this.state.value  === 0)  ||  (this.props.user.role === "regionalHead" && this.state.value  === 1) ?
                        <Grid xs={12}>
                            {this.state.showDownloadIcon ?
                                <CSVLink
                                    style={{ float: "right", margin: "10px" }}
                                    data={this.props.business.servicesMenuExcelRecords}
                                    headers={this.state.headers}
                                    filename={this.props.businessDetails.businessName + ".csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                :
                                <Button
                                    style={{marginLeft:"850px" }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.fetchExportToExcel}
                                >
                                    DOWNLOAD CATALOG
                                </Button>
                            }
                        </Grid>
                        : null}
                    <TabPanel value={this.state.value} index={0} classes={{ root: classes.tab }}>
                      <Container>
                      <ManageService
                            // data={this.props.business.headSubheadHeirarchy.data}
                            data={this.props.business.Heads}
                            getServices={this.filterServices}
                            clearFilter={this.clearFilter}
                        />
                        {this.props.business.allServices && this.props.business.allServices.data ?
                            <ServiceTable
                                rowData={this.props.business.allServices.data}
                                totalCount={this.props.business.allServices.totalCount}
                                order={this.state.order === 1 ? "asc" : "desc"}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                handleEditOpen={this.editService}
                                handleListItemClick={this.handleListItemClick}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                role={this.props.user.role}
                                // handleIsEnabledSwitchChange={this.handleIsEnabledSwitchChange}
                                handleSwitchChange={this.handleSwitchChange}
                                selected={this.state.selected}
                                setSelected={this.setSelected}
                                numSelected={this.state.numSelected}
                                businessCategoryType={this.props.businessDetails.categoryType.name}
                                isPetpoojaEnabled={this.props.businessDetails.isPetpoojaEnabled}
                                handleLinkedAddonOpen={this.handleLinkedAddonOpen}
                                handleLinkedAddonModalClose={this.handleLinkedAddonModalClose}

                            />
                            : null}
                      </Container>
                    </TabPanel>
                    {footerButton}
                 
                {/* <Grid container spacing={3}>
                    <Grid item className={classes.gridItem} xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.buttonDisabled}
                            onClick={this.handleDisableDialogOpen}
                            style={{  backgroundColor: "#f50505", color: "white" }}
                        >
                            RESET
                        </Button>
                    </Grid>
                </Grid> */}
          

                    <TabPanel style={{ width: "100%" }} value={this.state.value} index={1} classes={{ root: classes.tab }}>
                        <ManageHeadAndSubHead
                            businessDetails={this.props.businessDetails}
                            data={this.props.business.headSubheadHeirarchy.data}
                            getHeadSubheads={this.getHeadSubheads}
                            clearData={this.clearFilter}
                            headSubheadFilter={this.setHeadSubheadFilter}
                            setSearchedSubhead={this.setSearchedSubhead}
                            setSearchedHead={this.setSearchedHead}
                        />
                        {this.props.business.headSubheadHeirarchy.data ?
                            <HeadSubheadTable
                                rowData={this.props.business.headSubheadHeirarchy.data}
                                totalCount={this.props.business.headSubheadHeirarchy.totalCount}
                                order={this.state.order === 1 ? "asc" : "desc"}
                                orderBy={this.state.orderBy}
                                page={this.state.pageOfHeadSubHead}
                                rowsPerPage={this.state.rowsPerPageOfHeadSubHead}
                                handleEditOpenHeadSubhead={this.editheadSubhead}
                                handleDeleteHead={this.deleteheadSubhead}
                                skip={this.state.pageOfHeadSubHead * this.state.rowsPerPageOfHeadSubHead}
                                // setOrder={this.setOrder}
                                // setOrderBy={this.setOrderBy}
                                setPage={this.setPageOfHeadSubHead}
                                setRowsPerPage={this.setRowsPerPageOfHeadSubHead}
                                history={this.props.history}
                                role={this.props.user.role}
                                selected={this.state.headSubheadSelected}
                                setSelectedHeadSubHead={this.setSelectedHeadSubHead}
                                numSelected={this.state.numSelected}
                                searchedSubhead={this.state.searchedSubhead}
                                searchedHead={this.state.searchedHead}
                                selectedSubHead={this.state.selectedSubHead}
                                numSelectedSubHead={this.state.numSelectedSubHead}
                                setSelectedSubHead={this.setSelectedSubHead}
                            />
                            : null}
                    </TabPanel>
                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={2}
                        classes={{ root: classes.tab }}
                    >
                        <ManageVariationGroups
                         role={this.props.user.role}
                         businessDetails={this.props.businessDetails}
                        />
                         
                    </TabPanel>

                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={3}
                        classes={{ root: classes.tab }}
                    >
                        <ManageAddonList
                         role={this.props.user.role}
                         businessDetails={this.props.businessDetails}
                        />
                         
                    </TabPanel>

                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={4}
                        classes={{ root: classes.tab }}
                    >
                        <AddonGroup
                         role={this.props.user.role}
                         businessDetails={this.props.businessDetails}
                        />
                         
                    </TabPanel>

                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={5}
                        classes={{ root: classes.tab }}
                    >
                        <Linking 
                        role={this.props.user.role}
                        businessDetails={this.props.businessDetails}
                        />
                         
                    </TabPanel>

                    <TabPanel
                        style={{ width: "100%" }}
                        value={this.state.value}
                        index={6}
                        classes={{ root: classes.tab }}
                    >
                        <ManageBusinessSequence
                            businessDetails={this.props.businessDetails}
                        />
                    </TabPanel>
                    
                  
                   
                </Grid>

                <ServiceDetailView
                    open={this.state.serviceModalOpen}
                    onClose={this.closeServiceModal}
                    edit={this.state.editService}
                    data={this.state.serviceData}
                    businessDetails={this.props.businessDetails}
                    getHeadSubheads={this.getHeadSubheads}
                    getServices={this.fetchServices}
                />
                <HeadDetailView
                    open={this.state.headModalOpen}
                    onClose={this.closeHeadModal}
                    businessDetails={this.props.businessDetails}
                    edit={this.state.edit}
                    data={this.state.headsubHeadData}
                    headsLength={this.props.business.headSubheadHeirarchy.data && this.props.business.headSubheadHeirarchy.data.length > 0 ? this.props.business.headSubheadHeirarchy.data.length : 0}
                    getHeadSubheads={this.getHeadSubheads}
                />
                <SubHeadDetailView
                    open={this.state.subHeadModalOpen}
                    onClose={this.closeSubheadModal}
                    businessDetails={this.props.businessDetails}
                    edit={this.state.edit}
                    data={this.state.headsubHeadData}
                    parentHead={this.state.parentHead}
                    getHeadSubheads={this.getHeadSubheads}
                />
                <ShowService
                    open={this.state.showService}
                    onClose={this.closeShowService}
                    data={this.state.selectedService}
                />           


                <Dialog
                    open={this.state.modalDeleteOpen}
                    onClose={this.deleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete Services</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete selected services?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteClose} color="primary">
                            No
                        </Button>
                        <Button onClick={() => this.deleteMultiServices()} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                { this.state.headSubheadSelected.length > 0  ? 
                    <Dialog
                        open={this.state.headModalDeleteOpen}
                        onClose={this.deleteCloseForHeadSubHead}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Delete Head</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete head ?<br></br>
                                (Note: Deleting Head will delete all sub-heads within, if present.)
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.deleteCloseForHeadSubHead} color="primary">
                                No
                            </Button>
                            <Button onClick={() => this.deleteheadSubhead()} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : 
                        <Dialog></Dialog>
                } 
                
                { this.state.selectedSubHead.length > 0 ? 
                    <Dialog
                        open={this.state.headModalDeleteOpen}
                        onClose={this.deleteCloseForHeadSubHead}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">Delete Sub-head</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure you want to delete sub-head ?<br></br>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.deleteCloseForHeadSubHead} color="primary">
                                No
                            </Button>
                            <Button onClick={() => this.deleteheadSubhead()} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    : 
                        <Dialog></Dialog>
                } 

            <Modal
             open={this.state.handleLinkedAddonModalOpen}
             className={classes.linkedItemsModal}
             onClose={this.handleLinkedAddonModalClose}
             closeAfterTransition
           >
            <LinkedAddonsModal
             handleLinkedAddonModalClose={this.handleLinkedAddonModalClose}
             itemId={this.state.itemIdToFetchAddon}
             role={this.props.user.role}
             serviceSection={true}
             />
            </Modal>  

                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>

                <Backdrop className={classes.backdrop} open={this.props.business.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        user: state.auth.user,
        admin: state.admin,
        categories: state.categories,
        env: state.auth.env
    };
};
export default connect(mapStateToProps, {
    getHeadByBusinessId,
    getHeadSubheadHierarchy,
    deleteHeadSubheadService,
    getServicesByBusinessId,
    uploadServices,
    updateService,
    deleteMultipleServices,
    clearMsg,
    deleteSubcategoryButton,
    getServicesMenuExportToExcel,
    getPetPoojaMenuExportToExcel,
    variationGroupsToLinkCatalogItem,
    updateVariationsFlag
})(withStyles(styles)(ServiceSection));
