import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PaymentGatewayTable from './paymentGatewayTable';
import { getPGTransactionRecords, changeHeaderName,onlinePaymentRecords, onlinePaymentStopLoadingRecords,getAllConsumers, requestBusinessStats } from '../../actions/dashboardActions';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';
import { getConsumerTagList} from "../../actions/consumerTagAction";
import { getBusinessTagList} from "../../actions/businessTagAction";

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(4),
        display: 'flex'
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    error: {
        color: "red"
    },
    box: {
        width: "50%",
        display: "inherit",
        alignItems: "center",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        justifyContent: 'space-around',
        boxShadow: "0px 1px 1px -1px"
    }
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class PaymentGateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consumerId:"",
            // consumerFirstName:"",
            consumerName:"",
            businessId:"",
            businessName:"",
            paymentId:"",
            serviceRequestId:"",
            referenceId:"",
            transactionStatus:"",
            paymentMode:"",
            filterStartDate: "",
            filterEndDate: "",
            dateRange :"",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            preparexportToExcel: false,
            page: 0,
            rowsPerPage: 10,
            order: -1,
            orderBy: 'createdAt',
            consumerTags:[],
            businessTags:[],
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        };
    }
    setPage = (page) => {
        this.setState({ page }, () => this.getPgRecords());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }
    componentDidMount() {
        this.props.changeHeaderName("Online Payment Report");
        this.getPgRecords();
        this.fetchConsumerTags();
        this.fetchBusinessTags();
    }
    resetFilter =() =>{
        this.setState({
            consumerId:"",
            // consumerFirstName:"",
            consumerName:"",
            businessId:"",
            businessName:"",
            paymentId:"",
            serviceRequestId:"",
            referenceId:"",
            transactionStatus:"",
            dateRange:"",
            filterStartDate: "",
            filterEndDate: "",
            paymentMode:"",
            preparexportToExcel: false,
            page: 0,
            rowsPerPage: 10,
            order: -1,
            orderBy: 'createdAt',
            filteredConsumerTags:[],
            filteredBusinessTags:[]
        }, () => {
            this.getPgRecords();
        })
    }

    getPgRecords = async () => {   
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');  
        await this.props.getPGTransactionRecords({
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage, 
            order: Number(this.state.order),
            orderBy: this.state.orderBy,
            consumerId:this.state.consumerId.trim(),
            //consumerFirstName:this.state.consumerFirstName,
            consumerName:this.state.consumerName.trim(),
            businessId:this.state.businessId.trim(),
            businessName:this.state.businessName.trim(),
            paymentId:this.state.paymentId.trim(),
            serviceRequestId:this.state.serviceRequestId.trim(),
            referenceId:this.state.referenceId.trim(),
            transactionStatus:this.state.transactionStatus,
            paymentMode:this.state.paymentMode,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            dateRange:this.state.dateRange,
            exportToexcel: 0,
            filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
        });
        // if (!this.state.preparexportToExcel) {
            this.setState({
                preparexportToExcel: false
            });
        // }
    }

    fetchExportToexcel = async () => {
        this.props.onlinePaymentRecords();
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');  
     await this.props.getPGTransactionRecords(
            { 
                skip: this.state.page * this.state.rowsPerPage,
                limit: this.state.rowsPerPage,
                exportToexcel: 1,
                order: Number(this.state.order),
                orderBy: this.state.orderBy,
                consumerId:this.state.consumerId.trim(),
             //   consumerFirstName:this.state.consumerFirstName,
                consumerName:this.state.consumerName.trim(),
                businessId:this.state.businessId.trim(),
                businessName:this.state.businessName.trim(),
                paymentId:this.state.paymentId.trim(),
                serviceRequestId:this.state.serviceRequestId.trim(),
                referenceId:this.state.referenceId.trim(),
                transactionStatus:this.state.transactionStatus,
                paymentMode:this.state.paymentMode,
                filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
                dateRange:this.state.dateRange,
                filteredConsumerTags:filteredConsumerTagsVar,
            filteredBusinessTags:filteredBusinessTagsVar
            
            }
        );
       this.setState({
            preparexportToExcel: true
        });
        setTimeout( this.props.onlinePaymentStopLoadingRecords,3000);
          };
          fetchConsumerTags=async()=>{
            const response=await getConsumerTagList();
            if(response){
            this.setState({consumerTags:response.data})
        }else{
            this.setState({consumerTags:[]})
          }
        }
    
        fetchBusinessTags=async()=>{
            const response=await getBusinessTagList();
            if(response){
              this.setState({businessTags:response.data})
            }else{
              this.setState({businessTags:[]})
            }
            
        }
    setOrder = (order) => {
        this.setState({ order }, () => this.getPgRecords());
    };

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.getPgRecords());
    }
    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
       // picker.endDate.add(1, "days");

        this.setState({
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }
    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.getPgRecords();
        }
    }
    handleFilterChange = (event)=>{
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { classes } = this.props;
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Online Payment   Report</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                {/* <Grid item xs={12}> */}
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerName" label="Consumer Name" value={this.state.consumerName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessId" label="Business Id" value={this.state.businessId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="businessName" label="Business Name" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="paymentId" label="Payment Id" value={this.state.paymentId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="serviceRequestId" label="Order Id" value={this.state.serviceRequestId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="referenceId" label="Reference Id" value={this.state.referenceId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="transactionStatus" label="Transaction Status" value={this.state.transactionStatus} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid> */}
                                <Grid item xs={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="delivery-partner-label">Transaction Status</InputLabel>
                                        <Select
                                            // labelId="select-transaction-status-label"
                                            // id="select-transaction-status-id"
                                            value={this.state.transactionStatus}
                                            name="transactionStatus"
                                            onChange={this.handleFilterChange}
                                            label="Transaction Status"
                                        >
                                            <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                                            <MenuItem value="FAILED">FAILED</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    {/* <TextField fullWidth margin="dense" name="paymentMode" label="" value={this.state.paymentMode} variant="outlined" onChange={this.Payment modehandleFilterChange} /> */}
                                    <FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="delivery-partner-label">Payment mode</InputLabel>
                                        <Select
                                            // labelId="select-transaction-status-label"
                                            // id="select-transaction-status-id"
                                            value={this.state.paymentMode}
                                            name="paymentMode"
                                            onChange={this.handleFilterChange}
                                            label="Payment mode"
                                        >
                                            <MenuItem value="CREDIT_CARD">Credit Card</MenuItem>
                                            <MenuItem value="DEBIT_CARD">Debit Card</MenuItem>
                                            <MenuItem value="PREPAID_CARD">Prepaid Card</MenuItem>
                                            <MenuItem value="Wallet">Wallet</MenuItem>
                                            <MenuItem value="UPI">UPI</MenuItem>
                                            <MenuItem value="NET_BANKING">Net Banking</MenuItem>

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="transactionTime" label="Transaction Time" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                                <Grid item xs={3}>
                                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredBusinessTags}
                                            name="filteredBusinessTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.businessTags.map((eachBusinessTagObj) => (
                                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                                    <ListItemText primary={eachBusinessTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                       
                                        onClick={
                                            ()=>this.setPage(0)
                                            // this.getPgRecords
                                        }

                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                                </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.onlinePayentRecods && this.props.onlinePayentRecods.data ?
                            <PaymentGatewayTable
                                data={this.props.onlinePayentRecods}
                                exportToexcel={this.props.onlinePayentRecods.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                fetchExportToexcel={this.fetchExportToexcel}
                                showDownloadIcon={this.state.preparexportToExcel}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        onlinePayentRecods: state.dashboard.onlinePayentRecods,
    }
};

export default connect(mapStateToProps, { changeHeaderName, getAllConsumers, requestBusinessStats, getPGTransactionRecords,onlinePaymentRecords ,onlinePaymentStopLoadingRecords })(withStyles(styles)(PaymentGateway));