import {
    SET_REGIONAL_HEAD,
    SET_REGIONAL_HEADS,
    SET_REGION_HEAD_SUCCESS_MESSAGE,
    SET_REGION_HEAD_ERROR_MESSAGE,
    REQUEST_REGIONAL_HEADS
} from "../actions/types";

const INITIAL_STATE = {
    regionalHead: {},
    regionalHeads: {},
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false
};

export default function regionalHeadReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_REGIONAL_HEADS:
            return { ...state, isFetching: action.payload };
        case SET_REGIONAL_HEAD:
            return { ...state, regionalHead: action.payload };
        case SET_REGIONAL_HEADS:
            return { ...state, regionalHeads: action.payload, isFetching: false };
        case SET_REGION_HEAD_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_REGION_HEAD_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        default:
            return state;
    }
}