import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changeHeaderName } from '../../actions/dashboardActions';
import { getCoupons, requestCouponState, updateCoupon, changeCouponStatus,updateSelectedCoupons, exportCoupons, deleteCoupons, clearMsg } from '../../actions/couponAction'
import CouponTable from './CouponTable'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import StatusMultiSelectDropdown from './StatusMultiSelectDropdown';
const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
});


class Coupon extends Component {

	constructor(props) {
		super(props);
		this.state = {
			order: -1,
			orderBy: 'issuedAt',
			page: 0,
			rowsPerPage: 10,
			couponCode: "",
			applicability: "",
			category: "",
			issuer: "",
			status: [],
			consumerId:"",
			consumerName:"",
			invitationCode: "",
			amount: "",
			filterStartDate: "",
			filterEndDate: "",
			consumedOnFilterStartDate: "",
			consumedOnFilterEndDate: "",
			settledOnFilterStartDate: "",
			settledOnFilterEndDate: "",
			expiryDateDateRange: "",
			consumedOnDateRange: "",
			settledOnDateRange: "",
			multipleStatusDateRange:"",
			serviceRequestId: "",
			ranges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
			},
			modalDeleteOpen: false,
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			numSelected: "",
			selectedCouponIds: [],
			businessName: "",
			updateConfirmModalOpen: false,
			preparexportToExcel: false,
			sponsoredBy: "",
			campaignId: "",
			campaignName: "",
			promoCode: "",
			offerCode: "",
			consumerFirstName: "",
			consumerLastName: "",
			statusData: null,
			selectedCouponRow:null,
            multipleStatusfilterStartDate:"",
			multipleStatusfilterEndDate:""
		};
	}

	componentDidMount() {
		this.props.changeHeaderName("Coupon");
		this.props.clearMsg();
		this.fetchCouponsByQuery();
		this.setState({ errorMsg: null });
		this.setState({ sucessMsg: null });
	}

	componentDidUpdate(prevProps, prevState) {
		if ((prevState.sucessMsg === null && this.state.sucessMsg) ||
			(prevState.errorMsg === null && this.state.errorMsg)) {
			this.setState({ snackOpen: true });
		}
		if (prevState.sucessMsg !== this.state.sucessMsg) {
			this.fetchCouponsByQuery();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.coupon.sucessMsg !== prevState.sucessMsg) {
			return { sucessMsg: nextProps.coupon.sucessMsg };
		} else if (nextProps.coupon.errorMsg !== prevState.errorMsg) {
			return { errorMsg: nextProps.coupon.errorMsg };
		}
	}

	handleUpdateConfirmModal = (flag) => {
		this.setState({ updateConfirmModalOpen: flag });
		if ( flag === false ){
			this.setState( { selectedCouponIds: [] } );
		}
	}

	updateMultipleCouponsStatus = async () => {
		let payload = {
			selectedCouponIds: this.state.selectedCouponIds,
			data: {
				"$set": {
					status: "PayBackSeller",
				},
				"$push": {
					statusHistory: {
						"status": "PayBackSeller",
						"time": new Date()
					}
				}
			}
		}
		this.setState({ updateConfirmModalOpen: false });
		await this.props.updateSelectedCoupons(payload);
		this.fetchCouponsByQuery();
		this.setState({selectedCouponIds: []});
	}

	fetchCouponsByQuery = async() => {
		this.props.requestCouponState(true);

		let payload = {
			skip: this.state.page * this.state.rowsPerPage,
			limit: this.state.rowsPerPage,
			order:this.state.order,
			orderBy:this.state.orderBy,

		}
		if (this.state.couponCode) {
			payload.couponCode = this.state.couponCode.trim(" ")
		}
		if (this.state.applicability) {
			payload.applicability = this.state.applicability.trim(" ")
		}
		if (this.state.category) {
			payload.category = this.state.category.trim(" ")
		}
		if (this.state.filterStartDate && this.state.filterEndDate) {
			payload.filterStartDate = this.state.filterStartDate
			payload.filterEndDate = this.state.filterEndDate
		}
		if (this.state.multipleStatusfilterStartDate && this.state.multipleStatusfilterEndDate) {
			payload.multipleStatusfilterStartDate = this.state.multipleStatusfilterStartDate
			payload.multipleStatusfilterEndDate = this.state.multipleStatusfilterEndDate
		}
		if (this.state.consumedOnFilterStartDate && this.state.consumedOnFilterEndDate) {
			payload.consumedOnFilterStartDate = this.state.consumedOnFilterStartDate
			payload.consumedOnFilterEndDate = this.state.consumedOnFilterEndDate
		}
		if (this.state.settledOnFilterStartDate && this.state.settledOnFilterEndDate) {
			payload.settledOnFilterStartDate = this.state.settledOnFilterStartDate
			payload.settledOnFilterEndDate = this.state.settledOnFilterEndDate
		}
		if (this.state.issuer) {
			payload.issuer = this.state.issuer.trim(" ")
		}
		if (this.state.consumerId) {
			payload.consumerId = this.state.consumerId.trim(" ")
		}
		// if (this.state.consumerName) {
		// 	payload.consumerName = this.state.consumerName.trim(" ")
		// }
		if (this.state.consumerFirstName) {
			payload.consumerFirstName = this.state.consumerFirstName.trim(" ")
		}
		if (this.state.consumerLastName) {
			payload.consumerLastName = this.state.consumerLastName.trim(" ")
		}

		if (this.state.status) {
			// console.log(this.state.status)
			let StatusArr = this.state.status
			let finalArray = StatusArr.map((value)=>value==="Settled" ?"PayBackSeller":value)
			// let finalArray=[];
			// for(let i=0; i<StatusArr.length;i++){
			// 	if(StatusArr[i] ==='Settled'){  
			// 	let PayBackSeller=StatusArr[i].replace(/Settled/g,"PayBackSeller")
			// 	console.log(PayBackSeller)
			// 	finalArray.push(PayBackSeller)
			//    }
			// 	else{
			// 		finalArray.push(StatusArr[i])
			// 	}
			// }

			// StatusArr.find((value,index)=>{
			// 	if(value ==='Settled'){  
			// 	let PayBackSeller=value.replace(/Settled/g,"PayBackSeller")
			// 	console.log(PayBackSeller)
			// 	finalArray.push(PayBackSeller)
			//    }
			// 	else{
			// 		finalArray.push(value)
			// 	}
			// })
			//console.log("finalArray",finalArray)
			payload.status = finalArray
		}
		if (this.state.invitationCode) {
			payload.invitationCode = this.state.invitationCode.trim(" ")
		}
		if (this.state.serviceRequestId) {
			payload.serviceRequestId = this.state.serviceRequestId.trim(" ");
		}
		if (this.state.businessName) {
			payload.businessName = this.state.businessName.trim(" ")
		}
		if (this.state.campaignId) {
			payload.campaignId = this.state.campaignId.trim(" ");
		}
		if (this.state.campaignName) {
			payload.campaignName = this.state.campaignName.trim(" ");
		}
		if (this.state.promoCode) {
			payload.promoCode = this.state.promoCode.trim(" ");
		}
		if (this.state.offerCode) {
			payload.offerCode = this.state.offerCode.trim(" ");
		}
		await this.props.getCoupons(payload);
		// this.exportCoupons();
		if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
		this.props.requestCouponState(false);
	}

	setOrder = (order) => {
		this.setState({ order }, () => this.fetchCouponsByQuery());
	}
    changeEditStatusFlag = (f,i) => {this.setState({ editStatusFlag: f , editIndex: i})}

	setOrderBy = (orderBy) => {
		this.setState({ orderBy }, () => this.fetchCouponsByQuery());
	}

	setPage = (page) => {
		this.setState({ page }, () => this.fetchCouponsByQuery());
	}

	setRowsPerPage = (rowsPerPage) => {
		this.setState({ rowsPerPage });
	}

	setSelected = (selectedCouponIds) => {
		this.setState({ selectedCouponIds })
	}

	handleFilterChange = (event) => {
		
		this.setState({ [event.target.name]: event.target.value });
	}

	enterPressed = (event) => {
		let code = event.keyCode;
		if (code === 13) {
			event.preventDefault();
			this.fetchCouponsByQuery();
		}
	}

	resetFilter = () => {
		this.setState({
			page: 0,
			rowsPerPage: 10,
			couponCode: "",
			applicability: "",
			category: "",
			filterStartDate: "",
			filterEndDate: "",
			consumedOnFilterStartDate: "",
			consumedOnFilterEndDate: "",
			settledOnFilterStartDate: "",
			settledOnFilterEndDate: "",
			issuer: "",
			status: [],
			consumerId:"",
			// consumerName:"",
			invitationCode: "",
			serviceRequestId: "",
			amount: "",
			expiryDateDateRange: "",
			consumedOnDateRange: "",
			settledOnDateRange: "",
			businessName: "",
			campaignId: "",
			campaignName: "",
			promoCode: "",
			offerCode: "",
			consumerLastName: "",
			consumerFirstName: "",
			multipleStatusDateRange:"",
			multipleStatusfilterStartDate:"",
			multipleStatusfilterEndDate:"",
		}, () => {
			this.fetchCouponsByQuery();
		})
	};

	handleDateRangeChange = (event, picker) => {
		let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
		// picker.endDate.add(1, "days");
		this.setState({
			filterStartDate: picker.startDate.format(),
			filterEndDate: picker.endDate.format()
		});
		let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
		this.setState({
			expiryDateDateRange: chosenLabel
		});
	}
		
	handleConsumedOnDateRangeChange = (event, picker) => {
		let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
		// picker.endDate.add(1, "days");
		this.setState({
			consumedOnFilterStartDate: picker.startDate.format(),
			consumedOnFilterEndDate: picker.endDate.format()
		});
		let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
		this.setState({
			consumedOnDateRange: chosenLabel
		});
	}

	// Added date range for multiple status selector

	handleMultipleStatusDateRangeChange = (event, picker) => {
		let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
		// picker.endDate.add(1, "days");
		this.setState({
			multipleStatusfilterStartDate: picker.startDate.format(),
			multipleStatusfilterEndDate: picker.endDate.format()
		});
		let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
		this.setState({
			multipleStatusDateRange: chosenLabel
		});
	}


	updateCouponStatus = (coupon,newCouponStatus) => { this.setState({ statusData: coupon, confirmDialogFlag: true, newCouponStatus: newCouponStatus });}

	changeNewStatusFlag = (newstatus,row,i) => {this.setState({ selectedStatus: newstatus , editIndex: i,selectedCouponRow:row})}

    handleDeleteClose = () => { this.setState({ confirmDialogFlag: false, editStatusFlag:false, selectedStatus: ""}) };
	handleChangeCouponStatus = async () => {
        this.setState({confirmDialogFlag:false})
        let _id = this.state.statusData._id;
        let oldStatus = this.state.statusData.status ? this.state.statusData.status : "";
        let payload = { status: this.state.selectedStatus ,
			selectedCouponRow:this.state.selectedCouponRow,
			oldStatus:oldStatus
		}
        await this.props.changeCouponStatus(payload,_id);
        this.setState({editStatusFlag:false,selectedStatus:""})
        this.fetchCouponsByQuery();
    };
	handleSettledOnDateRangeChange = (event, picker) => {
		let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
		picker.endDate.add(1, "days");
		this.setState({
			settledOnFilterStartDate: picker.startDate.format(),
			settledOnFilterEndDate: picker.endDate.format()
		});
		let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
		this.setState({
			settledOnDateRange: chosenLabel
		});
	}

	deleteOpen = (couponId) => {
		this.setState({ modalDeleteOpen: true });
		this.setState({ selectedCoupon: couponId })
	}

	deleteClose = () => {
		this.setState({ modalDeleteOpen: false, couponToDelete: "", selectedCouponIds: [] });
	}

	deleteCoupons = async () => {
		await this.props.deleteCoupons(this.state.selectedCouponIds);
		this.setState({ modalDeleteOpen: false });
		this.fetchCouponsByQuery();
		this.setState({selectedCouponIds: []});
	}

	exportCoupons = async() => {
		this.props.requestCouponState(true);
		let StatusArr = this.state.status
		let finalArray = StatusArr.map((value)=>value==="Settled" ?"PayBackSeller":value)
		let payload = {
			order: this.state.order,
			orderBy: this.state.orderBy,
			couponCode: this.state.couponCode.trim(" "),
			applicability: this.state.applicability.trim(" "),
			category: this.state.category.trim(" "),
			filterStartDate: this.state.filterStartDate,
			filterEndDate: this.state.filterEndDate,
			consumedOnFilterStartDate: this.state.consumedOnFilterStartDate,
			consumedOnFilterEndDate: this.state.consumedOnFilterEndDate,
			settledOnFilterStartDate: this.state.settledOnFilterStartDate,
			settledOnFilterEndDate: this.state.settledOnFilterEndDate,
			issuer: this.state.issuer.trim(" "),
			consumerId:this.state.consumerId.trim(" "),
			// consumerName: this.state.consumerName,
			status: finalArray,
			invitationCode: this.state.invitationCode.trim(" "),
			serviceRequestId: this.state.serviceRequestId.trim(" "),
			amount: this.state.amount,
			businessName: this.state.businessName.trim(" "),
			campaignId: this.state.campaignId.trim(" "),
			campaignName: this.state.campaignName.trim(" "),
			promoCode: this.state.promoCode.trim(" "),
			offerCode: this.state.offerCode.trim(" "),
			consumerFirstName: this.state.consumerFirstName.trim(" "),
			consumerLastName: this.state.consumerLastName.trim(" "),
			multipleStatusfilterStartDate:this.state.multipleStatusfilterStartDate,
			multipleStatusfilterEndDate:this.state.multipleStatusfilterEndDate,
			
		};
		await this.props.exportCoupons(payload);
    	await this.setState({ preparexportToExcel: true });
		this.props.requestCouponState(false);
	}

	handleSnackClose = () => {
		this.props.clearMsg();
		this.setState({
			snackOpen: false,
			modalOpen: false,
			modalDeleteOpen: false,
			couponToDelete: "",
			errorMsg: null,
			sucessMsg: null
		});
		this.fetchCouponsByQuery();
	}

	render() {
		// console.log("this.props",this.props)
		const { classes } = this.props;
		let muiAlert = null;

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}

		return (
			<Container className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container} style={{ paddingBottom: '0px' }}>
					<ExpansionPanel>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							<Typography className={classes.heading}>Search Coupons</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails onKeyDown={this.enterPressed}>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="couponCode" label="CouponCode" value={this.state.couponCode} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="applicability" label="Applicability" value={this.state.applicability} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									{/* <TextField fullWidth margin="dense" name="category" label="Category" value={this.state.category} variant="outlined" onChange={this.handleFilterChange} /> */}
									<FormControl fullWidth margin="dense" variant="outlined" className={classes.formControl}>
                                        <InputLabel id="delivery-partner-label">Category</InputLabel>
                                        <Select
                                            // labelId="select-transaction-status-label"
                                            // id="select-transaction-status-id"
                                            value={this.state.category}
                                            name="category"
                                            onChange={this.handleFilterChange}
                                            label="Category"
                                        >
                                            <MenuItem value="ReferAndEarn">ReferAndEarn</MenuItem>
                                            <MenuItem value="Promotional">Promotional</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
								</Grid>
								<Grid item xs={3}>
									{/* <TextField fullWidth margin="dense" name="issuer" label="Issuer" value={this.state.issuer} variant="outlined" onChange={this.handleFilterChange} /> */}
									<FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="demo-simple-select-standard-label">Sponsored By</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.issuer}
                                                name="issuer"
                                                onChange={this.handleFilterChange}
                                                label="Sponsored By"
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="Business" style={{ minHeight: "28px", height: "28px" }}>Business</MenuItem>
                                                <MenuItem value="Platform" style={{ minHeight: "28px", height: "28px" }}>Xirify</MenuItem>
                                            </Select>
                                        </FormControl>
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="consumerId" label="Consumer ID" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								{/* <Grid item xs={3}>
									<TextField fullWidth margin="dense" name="consumerName" label="Consumer Name" value={this.state.consumerName} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>														 */}
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="consumerFirstName" label="Consumer First Name" value={this.state.consumerFirstName} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>														
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="consumerLastName" label="Consumer Last Name" value={this.state.consumerLastName} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>														
							
								<Grid item xs={3}>
									{/* <TextField fullWidth margin="dense" name="status" label="Status" value={this.state.status} variant="outlined" onChange={this.handleFilterChange} /> */}
									{/* singal selector */}
									{/* <FormControl variant="outlined" fullWidth className={classes.formControl}>
										<InputLabel id="demo-mutiple-checkbox-label">Status</InputLabel>
										<Select
											// defaultValue="DELIVERED"
											labelId="demo-mutiple-checkbox-label"
											id="demo-mutiple-checkbox"
											value={this.state.status}
											name="status"
											onChange={this.handleFilterChange}
											input={<Input />}
											MenuProps={MenuProps}
										>
											{names.map((name) => (
												<MenuItem
													key={name}
													value={name.value}
													style={{ minHeight: "28px", height: "28px" }}
												>
													<ListItemText primary={name.label} />
												</MenuItem>
											))}
										</Select>
									</FormControl> */}
									
                                    <StatusMultiSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        status={this.state.status}
                                    />
                               </Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="invitationCode" label="InvitationCode" value={this.state.invitationCode} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="businessName" label="Consumed At" value={this.state.businessName} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<DateRangePicker
										ranges={this.state.ranges}
										onApply={this.handleDateRangeChange}
										maxSpan={{ "months": 12 }}
										showDropdowns
									>
										<TextField fullWidth margin="dense" name="expiryDateDateRange" label="Expiry Date Range" value={this.state.expiryDateDateRange} variant="outlined" />
									</DateRangePicker>
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="serviceRequestId" label="Order Id" value={this.state.serviceRequestId} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									{/* <DateRangePicker
										ranges={this.state.ranges}
										onApply={this.handleConsumedOnDateRangeChange}
										maxSpan={{ "months": 12 }}
										maxDate={moment()}
										showDropdowns
									>
										<TextField fullWidth margin="dense" name="consumedOn" label="Consumed On Date Range" value={this.state.consumedOnDateRange} variant="outlined" />
									</DateRangePicker> */}
									
								<DateRangePicker
										ranges={this.state.ranges}
										onApply={this.handleMultipleStatusDateRangeChange}
										maxSpan={{ "months": 12 }}
										showDropdowns
									>
										<TextField fullWidth margin="dense" name="multipleStatusDateRange" label="Date Range" value={this.state.multipleStatusDateRange} variant="outlined"
										 />
									</DateRangePicker>
								</Grid>
								<Grid item xs={3}>
									<DateRangePicker
										ranges={this.state.ranges}
										onApply={this.handleSettledOnDateRangeChange}
										maxSpan={{ "months": 12 }}
										maxDate={moment()}
										showDropdowns
									>
										<TextField fullWidth margin="dense" name="settledOn" label="Settled On Date Range" value={this.state.settledOnDateRange} variant="outlined" />
									</DateRangePicker>
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="campaignName" label="Campaign Name" value={this.state.campaignName} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="campaignId" label="Campaign Id" value={this.state.campaignId} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="promoCode" label="Promo Code" value={this.state.promoCode} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}>
									<TextField fullWidth margin="dense" name="offerCode" label="Offer Code" value={this.state.offerCode} variant="outlined" onChange={this.handleFilterChange} />
								</Grid>
								<Grid item xs={3}></Grid>
								<Grid item xs={8}>
								</Grid>
								<Grid item xs={2}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										margin="normal"
										onClick={this.resetFilter}
									>
										Reset
									</Button>
								</Grid>
								<Grid item xs={2}>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										margin="normal"
										color="primary"
										onClick={
											()=>this.setPage(0)
											// this.fetchCouponsByQuery
										}
									>
										Search
									</Button>
								</Grid>
							</Grid>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</Container>
				<Container className={classes.buttonContainer}>
					<Grid container spacing={3}>
						<Grid item xs={8}>
						</Grid>
						<Grid item xs={3}>
							{/* <Button
								style={{ float: "right", margin: "5px" }}
								variant="contained"
								color="primary"
								disabled={this.state.selectedCouponIds.length < 1}
								onClick={() => this.handleUpdateConfirmModal(true)}
							>
								Settle Coupons
							</Button> */}
						</Grid>
						<Grid item xs={1}>
							<Button
								style={{ float: "right", margin: "5px" }}
								variant="contained"
								color="primary"
								disabled={this.state.selectedCouponIds.length < 1}
								onClick={this.deleteOpen}
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</Container>
				<Container className={classes.container}>
					{
						this.props.coupon.couponList.length > 0 ?
							<CouponTable
								data={this.props.coupon.couponList}
								totalCount={this.props.coupon.couponListCount}
								order={this.state.order === 1 ? 'asc' : 'desc'}
								orderBy={this.state.orderBy}
								page={this.state.page}
								rowsPerPage={this.state.rowsPerPage}
								setOrder={this.setOrder}
								setOrderBy={this.setOrderBy}
								setPage={this.setPage}
								setRowsPerPage={this.setRowsPerPage}
								handleFilterChange={this.handleFilterChange}
								changeEditStatusFlag={(flag,i)=>{this.changeEditStatusFlag(flag,i)}}
								exportToExcelData={this.props.coupon.exportToExcel}
								selectedCouponIds={this.state.selectedCouponIds}
								setSelected={this.setSelected}
								numSelected={this.state.numSelected}
								exportCoupons={this.exportCoupons}
								showDownloadIcon={this.state.preparexportToExcel}
								updateCouponStatus={(data,newCouponStatus)=>{this.updateCouponStatus(data,newCouponStatus)}}
								changeNewStatus={(newStatus,row,i)=>{this.changeNewStatusFlag(newStatus,row,i)}}
								selectedStatus={this.state.selectedStatus}
								editStatusFlag={this.state.editStatusFlag}
                                editIndex={this.state.editIndex}
								handleServiceRequestDetails={(srId)=>{
									this.handleServiceRequestDetails(srId)
								}}
								user = {this.props.user}
					
							/>
							: <Container />
					}
				</Container>
				<Dialog open={this.state.confirmDialogFlag} onClose={this.handleDeleteClose}>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to change the Coupon Status to {this.state.selectedStatus}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                        <Button onClick={() => this.handleChangeCouponStatus()} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
				<Container>
					<Dialog open={this.state.modalDeleteOpen} onClose={this.deleteClose}>
						<DialogTitle>Delete Coupon(s)</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Do you want to delete the coupon(s)?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.deleteClose} color="primary"> No </Button>
							<Button onClick={() => this.deleteCoupons()} color="primary" autoFocus> Yes </Button>
						</DialogActions>
					</Dialog>
				</Container>
				<Container>
					<Dialog open={this.state.updateConfirmModalOpen} onClose={() => this.handleUpdateConfirmModal(false)}>
						<DialogTitle>Settle Coupon(s)</DialogTitle>
						<DialogContent>
							<DialogContentText>
								Do you want to settle these coupons?
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => this.handleUpdateConfirmModal(false)} color="primary"> No </Button>
							<Button onClick={() => this.updateMultipleCouponsStatus()} color="primary" autoFocus> Yes </Button>
						</DialogActions>
					</Dialog>
				</Container>
				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.snackOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}>
					{muiAlert}
				</Snackbar>
				<Backdrop className={classes.backdrop} open={this.props.coupon.isFetching}>
					<CircularProgress color="inherit" />
				</Backdrop>
			</Container>
		);
	}
}

const mapStateToProps = state => {
	return {
		coupon: state.coupon,
		user:state.auth.user
	}
};
export default connect(mapStateToProps, { changeHeaderName, getCoupons, changeCouponStatus,requestCouponState, exportCoupons, updateCoupon, updateSelectedCoupons, deleteCoupons, clearMsg })(withStyles(styles)(Coupon));