import axios from "axios";
import * as actions from "./types";

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: data });
    dispatch({ type: actions.SET_ALL_COUPON_SETTLEMENT_LIST_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: actions.SET_ALL_COUPON_SETTLEMENT_LIST_ERROR_MESSAGE, payload: data });
};

export const requestCampaign = () => {
	return {
		type: actions.REQUEST_CAMPAIGN,
		payload: true
	};
};

export const requestSuccess = () => {
	return {
		type: actions.REQUEST_CAMPAIGN,
		payload: true
	};
};

export const requestSuccessDone = () => {
	return {
		type: actions.REQUEST_CAMPAIGN,
		payload: false
	};
};

export const requestShoppers = () => {
	return {
		type: actions.REQUEST_SHOPPERS,
		payload: true
	};
};

export const requestCouponSettlement = () => {
    return {
		type: actions.REQUEST_COUPON_SETTLEMENT,
		payload: true
	};
}

//level 1 api call to get list of all campaigns
export const getAllCampaignList = (payload) => async (dispatch) => {
    try {
        let response = await axios.get("/api/campaign/campaignslist",{ params: payload});
        // console.log(response);
      dispatch({ type: actions.GET_ALL_CAMPAIGN_LIST_COMPLETED, payload: response.data, });
    } catch (err) {
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: "Failed to get data for campaigns."});
    }
};

// api to add new campaign
export const addNewCampaign = (payload,code) => async (dispatch) => {
    try {
        // console.log("payload in action----->",payload);
        if(code === "offercode"){
            console.log("calling create offer code campaign");
            let response = await axios.post("/api/campaign/offercodecampaign",payload);
            dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Offer Code Campaign added successfully." }});
            return response;
        }else{
            console.log("calling create promo code campaign");
            // console.log("payload for add new campaign: " , payload)
            let response = await axios.post("/api/campaign",payload);
            dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Promo Code Campaign added successfully." }});
            // console.log("response--->",response);
            return response;
        }
    } catch (err) {
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: err.response.data.error ? err.response.data.error : "Failed to add campaign." }});
    }
};

// to check promocode in level 1 forms
export const checkPromoCode = (payload) => async (dispatch) => {
    try {
        // console.log("from add form to check promocode")
        let response = await axios.post("/api/campaign/promocode",payload);
        // console.log("check promocode response----->",response);
        dispatch({ type: actions.SET_PROMOCODE, payload: response.data });
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};

// level 1 change the status of the campaign - active or inactive
export const updateCampaignStatus = (payload) => async (dispatch) => {
    try {
        await axios.patch("/api/campaign/"+ payload.campaignId, payload.data);       
        dispatch({ type: actions.SET_CAMPAIGN_UPDATE, payload: { ops: "UPDATE", msg: "Campaign status updated successfully." } });
    } catch (error) {
        dispatch({ type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update campaign status." } });
    }
};

//level 1 delete campaign
export const deleteCampaign = (payload) => async (dispatch) => {
    try {
        await axios.delete("/api/campaign/"+ payload.campaignId);
    } catch (error) {
        console.log(error);
    }
};

//level 1 export to excel campaigns
export const getCampaignExportToExcel = (payload) => async (dispatch) => {
    dispatch({ type: actions.CAMPAIGN_DOWNLOAD_EXCEL});
    let response = await axios.get("/api/campaign/export/allcampaigns",{params: payload});
    dispatch({ type: actions.CAMPAIGN_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
    // dispatch({ type: actions.CAMPAIGN_DOWNLOAD_EXCEL_COMPLETED, payload: { ops: "ADD", msg: "Campaigns exported successfully." } });

};

//level 1 edit campaign
export const editCampaign = (payload, payloadid) => async (dispatch) => {
    try {        
        await axios.patch("/api/campaign/"+payloadid, payload);       
        dispatch({ type: actions.SET_CAMPAIGN_UPDATE, payload: { ops: "UPDATE", msg: "Campaign updated successfully." } });
    } catch (error) {
        console.error(error);
        dispatch({ type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Error in updating campaign." } });
    }
};

//level 2 - to Get campaign details by id.
export const getCampaignDetailsById = (campaignId, payload) => async dispatch => {
    // try {
        const response = await axios.get(`/api/campaign/${campaignId}`,{ params: payload});        
	    dispatch({ type: actions.SET_CAMPAIGN_DETAILS, payload: response.data.exportToExcel });
  
};

//level 2 export to excel
export const getCampaignBusinessExportToExcel = (payload) => async (dispatch) => {
    // console.log("Payload in level 2 export----",payload);
    dispatch({ type: actions.CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL});
    let response = await axios.get("/api/campaign/export/"+ payload.campaignObjId,{params: payload});
    // console.log("response from excel: " , response);
    dispatch({ type: actions.CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
};

//level 3 - to get data related to coupons and consumer by business id
export const getCouponConsumerDataByBusinessId = (businessId, campaignId, payload ) => async (dispatch) => {
    // console.log("payload---->", payload);
    const response = await axios.get(`/api/campaign/coupons/${campaignId}/${businessId}`, {params: payload});
    // console.log("response from get----->", response);
    dispatch({ type: actions.SET_COUPON_CONSUMER_BUSINESS_CAMPAIGN_DETAILS, payload: response.data.exportToExcel });
};

//level 3 export to excel file
export const getCouponConsumerDataByBusinessIdExport = (businessId, payload) => async (dispatch) => {
    // console.log(payload, " ", businessId);
    dispatch({ type: actions.CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL});
    let response = await axios.get("/api/campaign/export/"+ payload.campaignId+"/"+businessId,{params: payload});
    // console.log("response from excel: " , response);
    dispatch({ type: actions.CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
};

//level 4 - get coupon settlement data from coupon settlement collection
export const getCouponSettlements = (payload) => async (dispatch) => {
    try {
        // const response = await axios.get(`/api/campaign/getCouponSettlements/${payload.businessId}`, { params: payload.data });
        const response = await axios.get(`/api/campaign/getCouponSettlements/${payload.businessId}`, { params: payload });
        // console.log("response from get coupon settlement get api---->", response);
        dispatch({ type: actions.GET_COUPON_SETTLEMENT_RECORDS, payload: response.data });
    } catch (err) {
        dispatch({ type: actions.GET_COUPON_SETTLEMENT_RECORDS, payload: {} });
    }
};


//level 2 - 4295 story - for settling the coupons for the selected business and by settlementId
export const couponSettlementById = (payload) => async (dispatch) => {
   
    try {       
      await axios.put(`/api/couponsettlement/settlement/${payload.couponSettlementId}`, payload);       
        dispatch({type: actions.SET_ALL_COUPON_SETTLEMENT_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Coupon Settlement done successfully." }});
    } catch (err) {
        dispatch({type: actions.SET_ALL_COUPON_SETTLEMENT_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: err.response.data.error ? err.response.data.error : "Failed to settle coupon." }});
        // dispatch({ type: actions.SET_COUPON_SETTLEMENT, payload: err });
    }
};

export const clearNotesRecord = () => async (dispatch) => {
    dispatch({
        type: actions.GET_COUPON_SETTLEMENT_NOTES,
        payload: []
    });
};

//level 2 - 4295 coupon settlement story, to get coupon settlement notes for the given settlement id
export const getCouponSettlementsNotes = (payload) => async (dispatch) => {
    try {       
        const response = await axios.get(`/api/couponsettlement/getCouponSettlementNotesBySettlementId/${payload.couponSettlementId}`);        
        dispatch({
            type: actions.GET_COUPON_SETTLEMENT_NOTES,
            payload: response.data.settlementNotes
        });
    } catch (err) {
        dispatch({ type: actions.GET_COUPON_SETTLEMENT_NOTES, payload: {} });
    }
};


//level 2 - 4295 couponsettlement story, this is for adding coupon settlement notes
export const addCouponSettlementNotes = (payload) => async (dispatch) => {
    try {
        // console.log("more notes payload----->",payload)
        const response = await axios.put(`/api/couponsettlement/saveCouponSettlementNotes`, payload);
        dispatch({
            type: actions.SET_COUPON_SETTLEMENT_NOTES,
            payload: response.msg
        });
    } catch (err) {
        dispatch({ type: actions.SET_COUPON_SETTLEMENT_NOTES, payload: err });
    }
};

// level 2 coupon settlement story am-4295 - get coupon settlement details for prefilled business id and due status
export const getBusinessCouponDetailsById = (payload) => async (dispatch) => {
    try {
        // console.log("payload here------>",payload)
        let response = await axios.get("/api/couponsettlement",{ params: payload});
        // console.log(response);
      dispatch({ type: actions.GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_COMPLETED, payload: response.data.exportToExcel });
    } catch (err) {
        console.log(err);
        dispatch({type: actions.SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_ERROR_MESSAGE, payload: "Failed to get data for business for coupon settlement."});
    }
};

//level 2 - export data
export const getBusinessCouponDetailsExportToExcel = (payload) => async (dispatch) => {
    dispatch({ type: actions.COUPONSETTLEMENT_DOWNLOAD_EXCEL});
    let response = await axios.get("/api/couponsettlement/exportcouponsettlementdata",{params: payload});
    // console.log("response of export----->",response);
    dispatch({ type: actions.COUPONSETTLEMENT_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.exportToExcel });
};

export const getCampaignSummaryReport = (payload) => async (dispatch) => {
    try {
        let params={skip:0,limit:10};
        if(payload) params= payload
        const response = await axios.get(`/api/couponSettlement/couponsummeryreport`,{params:params});
        dispatch({
            type: actions.GET_COUPON_SUMMARY_REPORT,
            payload: response.data
        });
    } catch (err) {
        console.log(err);
        dispatch({ type: actions.GET_COUPON_SUMMARY_REPORT, payload: err });
    }

};
export const getCampaignSummaryReportExcel = (payload) => async (dispatch) => {
    try {
        let params={};
        if(payload) params= payload
        const response = await axios.get(`/api/couponSettlement/couponsummeryreport/excel`,{params:params});
        dispatch({
            type: actions.GET_COUPON_SUMMARY_REPORT_EXCEL,
            payload: response.data
        });
    } catch (err) {
        console.log(err);
        dispatch({ type: actions.GET_COUPON_SUMMARY_REPORT_EXCEL, payload: err });
    }
};

// For offer code - to get shoppers data on Assign Shopper modal - with the search filters
export const getShoppersData = (payload,campaignId) => async (dispatch) => {
    try {
        // console.log("inside getShoppersData action------>",payload);
        let response = await axios.get(`/api/campaign/shoppersList/${campaignId}`,{ params: payload});
        // console.log(response);
        dispatch({ type: actions.GET_SHOPPERS_LIST, payload: response.data, });
    } catch (err) {
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: "Failed to get data of shoppers."});
    }
};

// For offer code - to get shoppers data on Assign Shopper modal - with the search filters
export const assignAllShoppersCoupons = (payload,campaignId) => async (dispatch) => {
    try {
        let consumerIdsList = {};
        // console.log("inside assignAllShoppersCoupons action------>",payload);
        let response = await axios.get(`/api/campaign/shoppersList/getall/${campaignId}`,{ params: payload});
        // console.log(response);
        if(response.data.consumerIds && response.data.consumerIds.length > 0){
            consumerIdsList = {consumerIds:response.data.consumerIds};
            // console.log(consumerIdsList)
            // console.log("calling assign coupons to shoppers list");
            let responseAssign;
            try {
                responseAssign = await axios.patch(`/api/campaign/assigncouponstoshoppers/${campaignId}`, consumerIdsList);
                // console.log("response inside assignCouponsToShoppers action---->",responseAssign);
                dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Coupons are assigned successfully to the shoppers for Offer Code Campaign." }});
                return responseAssign.status;
            } catch (err) {
                // console.log("error response inside assignCouponsToShoppers action---->",err);
                dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: err?.response?.data?.error?err.response.data.error:"Error while assigning coupons."}});
            }
        }else{
            dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: "No Shoppers Present to assign coupons."} });
        }
        // dispatch({ type: actions.GET_SHOPPERS_LIST, payload: response.data, });
    } catch (err) {
        console.log(err)
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Error while assigning coupon."}});
    }
};

// To assign the coupons to the shoppers for selected campaign
export const assignCouponsToShoppers = (consumerIds,campaignId) => async (dispatch) => {
    try {
        // console.log("inside assignCouponsToShoppers action------>",consumerIds, " ", campaignId);
        let response = await axios.patch(`/api/campaign/assigncouponstoshoppers/${campaignId}`, consumerIds);
        // console.log("response inside assignCouponsToShoppers action---->",response);
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Coupons are assigned successfully to the shoppers for Offer Code Campaign." }});
        return response.status;
    } catch (err) {
        dispatch({type: actions.SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE, payload: { ops: "ADD", msg: err?.response?.data?.error?err.response.data.error:"Error while assigning coupons."}});
    }
};