import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';

import { changeHeaderName } from '../../actions/dashboardActions';
import { requestPromoCodes, getPromoCodes, assignCoupon,clearErrorMsg, clearSuccessMsg } from '../../actions/promocodeReportActions';
import PromoCodeTable from './PromoCodeTable';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        // overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

class promoCodeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'dateOfInvite',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            firstName: "",
            lastName: "",
            preparexportToExcel: false,

            campaignId:"",
            campaignName:"",
            consumerId:"",
            consumerMobile:"",
            promoCode:"",
            societyName:"",
            streetName:"",
            area:"",
            pinCode:"",
            city:"",
            couponGeneratedStatus:false,
            snackOpen: false,
            errorMsg:null,
            successMsg:null,
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Consumer Campaign Promo Code Report");
        this.fetchPromocodes();
    }

    componentDidUpdate(){
        if(this.props.errorMsg){
            this.setState({snackOpen:true,errorMsg:this.props.errorMsg})
            this.props.clearErrorMsg();
        }
        if(this.props.successMsg){
            this.setState({snackOpen:true,successMsg:this.props.successMsg})
            this.props.clearSuccessMsg();
        }
    }

    handleSnackClose = () => {
        this.setState({
            snackOpen: false,
            errorMsg: null,
            successMsg: null
        });
    }

    assignCouponValueToItem = async(promoCode,consumerId)=>{
        let payload = {
            promoCode:promoCode,
            consumerId:consumerId
        }
        this.props.requestPromoCodes();
        await this.props.assignCoupon(payload);
        this.fetchPromocodes();
    }

    fetchPromocodes = async () => {
        this.props.requestPromoCodes();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            exportToexcel: 0,
            campaignId:this.state.campaignId.trim(),
            campaignName:this.state.campaignName.trim(),
            consumerId:this.state.consumerId.trim(),
            consumerMobile:this.state.consumerMobile.trim(),
            promoCode:this.state.promoCode.trim(),
            societyName:this.state.societyName.trim(),
            streetName:this.state.streetName.trim(),
            area:this.state.area.trim(),
            pinCode:this.state.pinCode.trim(),
            city:this.state.city.trim(),
            couponGeneratedStatus:this.state.couponGeneratedStatus,
        }
        await this.props.getPromoCodes(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
    }

    fetchPromocodesExcelFile = async () => {
        this.props.requestPromoCodes();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            exportToexcel: 1,
            campaignId:this.state.campaignId.trim(),
            campaignName:this.state.campaignName.trim(),
            consumerId:this.state.consumerId.trim(),
            consumerMobile:this.state.consumerMobile.trim(),
            promoCode:this.state.promoCode.trim(),
            societyName:this.state.societyName.trim(),
            streetName:this.state.streetName.trim(),
            area:this.state.area.trim(),
            pinCode:this.state.pinCode.trim(),
            city:this.state.city.trim(),
            couponGeneratedStatus:this.state.couponGeneratedStatus,
        }
        await this.props.getPromoCodes(payload);
        await this.setState({ preparexportToExcel: true });
    }


    setOrder = (order) => {
        this.setState({ order }, () => this.fetchPromocodes());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchPromocodes());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchPromocodes());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage }, () => this.fetchPromocodes());
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.endDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'dateOfInvite',
            page: 0,
            rowsPerPage: 10,
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            firstName: "",
            lastName: "",
            campaignId:"",
            campaignName:"",
            consumerId:"",
            consumerMobile:"",
            promoCode:"",
            societyName:"",
            streetName:"",
            area:"",
            pinCode:"",
            city:"",
            couponGeneratedStatus:false,
        }, () => {
            this.fetchPromocodes();
            let payload = {
                orderBy: this.state.orderBy,
                order: Number(this.state.order),
                skip: this.state.page * this.state.rowsPerPage,
                limit: this.state.rowsPerPage,
                filterStartDate: this.state.filterStartDate,
                filterEndDate: this.state.filterEndDate,
                firstName: this.state.firstName.trim(),
                lastName: this.state.lastName.trim(),
                campaignId:this.state.campaignId.trim(),
                campaignName:this.state.campaignName.trim(),
                consumerId:this.state.consumerId.trim(),
                consumerMobile:this.state.consumerMobile.trim(),
                promoCode:this.state.promoCode.trim(),
                societyName:this.state.societyName.trim(),
                streetName:this.state.streetName.trim(),
                area:this.state.area.trim(),
                pinCode:this.state.pinCode.trim(),
                city:this.state.city.trim(),
                couponGeneratedStatus:this.state.couponGeneratedStatus,
            }
            this.props.getPromoCodes(payload);
        })
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }
    
    ITEM_HEIGHT = 48;
    ITEM_PADDING_TOP = 8;
    MenuProps = {
        PaperProps: {
            style: {
                maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    render() {
        const { classes } = this.props;
        let muiAlert = null;
        if(this.state.errorMsg){
            muiAlert = <MuiAlert elevation={6} variant="filled" severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        }
        if(this.state.successMsg){
            muiAlert = <MuiAlert elevation={6} variant="filled" severity="success">
                {this.state.successMsg}
            </MuiAlert>
        }
        return (
            <Container className={classes.content}>
                 <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Promo Code</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3} style={{ alignItems: 'center' }}>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" label="Registration Date Range" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="campaignId" label="Campaign Id" value={this.state.campaignId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="campaignName" label="Campaign Name" value={this.state.campaignName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="firstName" label="Consumer First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="lastName" label="Consumer Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerMobile" label="Consumer Mobile Number" value={this.state.consumerMobile} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="promoCode" label="Promocode" value={this.state.promoCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="societyName" label="Society Name" value={this.state.societyName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="streetName" label="Street Name" value={this.state.streetName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="area" label="Area" value={this.state.area} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="pinCode" label="Pin Code" value={this.state.pinCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="city" label="City" value={this.state.city} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="filled" fullWidth className={classes.formControl} >
                                        <InputLabel id="CouponIssuedStatus-label">Coupon Generated Status</InputLabel>
                                        <Select
                                            labelId="CouponIssuedStatus-label"
                                            name="couponGeneratedStatus"
                                            value={this.state.couponGeneratedStatus}
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            MenuProps={this.MenuProps}
                                        >
                                            <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="Yes" />
                                            </MenuItem>
                                            <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary="No" />
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        // onClick={this.fetchPromocodes}
                                        onClick={()=>this.setPage(0)}
                                    >
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>

                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
                <Container className={classes.container}>
                    {
                        this.props.promocodes && this.props.promocodes.consumerData ?
                            <PromoCodeTable
                                data={this.props.promocodes}
                                exportToexcel={this.props.promocodes.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                                fetchPromocodesExcelFile={this.fetchPromocodesExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                user={this.props.user}
                                assignCouponValueToItem={this.assignCouponValueToItem}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.promocodes.isFetching,
        promocodes: state.promocodes.promocodes,
        successMsg: state.promocodes.successMsg,
        errorMsg: state.promocodes.errorMsg,
        user: state.auth.user,
    }
};

export default connect(mapStateToProps, { changeHeaderName, getPromoCodes, requestPromoCodes, assignCoupon, clearErrorMsg, clearSuccessMsg })(withStyles(styles)(promoCodeReport));