import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
// import { lightBlue } from '@material-ui/core/colors';
// import { ObjectID } from 'bson';
// import _ from 'lodash';
import { couponSettlementById } from '../../actions/campaignActions';
import moment from "moment";
import UploadSettlementPhoto from './uploadSettlementPhoto';

const styles = theme => ({
	Checkbox: {
		fontSize: 20
	},
	header: {
		height: '10%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modalContainer: {
		height: '100%',
		overflow: 'auto',
		backgroundColor: theme.palette.background.paper,
	},
	gridElement: {
		margin: '10px 5px'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	icon: {
		color: 'white',
	},
	gridList: {
		width: '100%',
		height: '100%'
	},
	mainContainer: {
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
        width: '62%',
        height: '100%',
	},
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class couponSettleAmountModal extends Component {
	constructor() {
		super();
		this.state = {
			confirmCheckBox: false,
			date: "",
			notes: "",
			dateError : "",
			settlementImage: "",
			modalDeleteOpen: false, 
			attachment: "",
			couponSettlementImages: [],
		};
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if(this.state.dateError){
			this.setState({ dateError : "" });
		}
	};

	componentDidMount(){
        this.setState({
			// attachment:  "",
			couponSettlementImages: []
		});
    };

	clearData = () => {
		this.setState({
			confirmCheckBox: false,
			date: "",
			notes: "",
			// attachment: "",
			couponSettlementImages: []
		})
		this.props.data.attachment = [];
	};

	save = async () => {
		let flag = await this.validate();
		if (parseInt(flag)){
		let payload = {
			couponSettlementId: this.props.data.couponSettlementId,
			dueStatus: true,
			settlementNote: this.state.notes,
			settlementDate: this.state.date,
			// attachment: this.state.attachment,
			attachment: this.state.couponSettlementImages
		}
		await this.props.couponSettlementById(payload);
		this.saveAndClose();
		}
	};

	saveAndClose = () => {
		this.clearData();
		this.props.saveAndClose();
	};

	close = () => {
		this.clearData();
		this.props.onClose();
	};

	handleCheck = (event) => {
		this.setState({ confirmCheckBox: event.target.checked })
	};

	validate = async ()=>{
		let dateError = this.state.dateError;
		if(this.state.date && moment(this.state.date).isAfter(moment())){
		dateError="You can't select date from future";
		await this.setState({dateError});
		return 0;
		}
		return 1 ;
	};

	handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    };

	handleDeleteOpen = (photo) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedPhoto: photo });
    };

	updateSettlementPhotos = async (files, photoType) => {
        let data = new FormData();
        let folderPath = "Marketplace_Business";       
    
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "upload_preset",
            this.props.envs.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.envs.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });
        let file = await res.json();
        if (photoType === "SettlementPhoto") {
			this.props.data.attachment.push({path:file.secure_url});  
        	this.setState({ couponSettlementImages: this.props.data.attachment });
        	this.setState({ isUploading: false, typePhoto: photoType });
        }
    };

	deleteSettlementPhotos = async (selectedPhoto, photoType) => {   
        if (photoType === "SettlementPhoto") {
			this.props.data.attachment = this.props.data.attachment.filter((settlementImage) => settlementImage.path !== selectedPhoto.path);
        }
        this.setState({couponSettlementImages: this.props.data.attachment});
    };

	render() {
		const { classes } = this.props;
		// console.log("data in coupon settlement modal--->",this.props)
		return (
			<Container className={classes.mainContainer}>
				<Grid container className={classes.header} spacing={1} >
					<Grid item xs={8}>
						<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
							Settle Dues
						</WhiteTextTypography>
					</Grid>
					<Grid item xs={4}>
						<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
							<CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
						</WhiteTextTypography>
					</Grid>
				</Grid>
				<Container className={classes.modalContainer}>
					<Grid container spacing={1} >
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Confirm *
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<Typography variant="subtitle2" gutterBottom>
								Settlement Date *
							</Typography>
						</Grid>
						<Grid item xs={6}>
							<FormControl component="fieldset">
								<FormControlLabel
									control={<Checkbox color="primary" onChange={this.handleCheck} />}
									label="Confirm the dues are settled"
									className={classes.Checkbox}
									labelPlacement="end"
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								margin="dense"
								name="date"
								type="date"
								variant="outlined"
								className={classes.textField}
								InputLabelProps={{shrink: true}}
								error={this.state.dateError}
								helperText={this.state.dateError}
								value={this.state.date}
								onChange={this.handleChange}
							/>
						</Grid>
						
						{/* Upload Coupon Settlement Image button */}
						<Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
								Upload Image
							</Typography>
                        </Grid>
						<Grid item xs={12}>
                            <UploadSettlementPhoto
								images={this.state.couponSettlementImages}
                                uploadButtonName = "Choose File"
                                updateSettlementPhotos = {this.updateSettlementPhotos}
								deleteSettlementPhotos = {this.deleteSettlementPhotos}
                                photoType="SettlementPhoto"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {(this.state.attachment !== "" ) ?
                                <GridList cols={7} cellHeight={80} className={classes.gridList}>
                                    <GridListTile className={classes.gridElement} >
                                        <a href={this.state.attachment} target="_blank" rel="noopener noreferrer">
                                        	<img border="0" className={classes.img} src={this.state.attachment} alt="" />
                                        </a>
                                        <GridListTileBar  
                                            titlePosition="bottom"
                                            actionIcon={
                                                <IconButton className={classes.icon} 
                                                onClick={() => this.handleDeleteOpen(this.state.attachment)}
                                                >
                                                <DeleteIcon />
                                                </IconButton>
                                            }
                                            actionPosition="right"
                                        />
                                    </GridListTile>
                                ))
                                    </GridList >
                            : null}
                        
                        </Grid>
						<Grid item xs={12}>
							<Typography variant="subtitle2" gutterBottom>
								Notes *
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								margin="dense"
								name="notes"
								variant="outlined"
								className={classes.textField}
								InputLabelProps={{
									shrink: true,
								}}
								// error={this.state.error && this.state.error.date}
								// helperText={(this.state.error && this.state.error.date) ? this.state.error.date : null}
								value={this.state.notes}
								onChange={this.handleChange}
							/>
						</Grid>
						<Grid item xs={4}></Grid>
						<Grid item xs={4}>
							<Button
								fullWidth
								style={{ margin: "5px" }}
								variant="contained"
								onClick={() =>{this.close()}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item xs={4}>
							<Button
								fullWidth
								disabled={(this.state.confirmCheckBox === true && this.state.date && this.state.notes) ? false : true}
								style={{ margin: "5px" }}
								variant="contained"
								color="primary"
								onClick={this.save}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</Container> 
			</Container>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		user: state.auth.user,
		admin: state.admin,
		env: state.auth.env,
		campaign: state.campaign,
	};
};
export default connect(mapStateToProps, {
	couponSettlementById
})(withStyles(styles)(couponSettleAmountModal));