import React, { Component } from 'react';
import { changeHeaderName } from '../../actions/dashboardActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import CampaignsCouponSettlementBusinessTable from './CampaignsCouponSettlementBusinessTable';
import CouponSettleAmountModal from './couponSettleAmountModal';
import AddCouponNotesModal from './addCouponNotesModal';
// import CouponCategorySelectDropdown from './couponCategorySelectDropdown';
// import DueStatusSelectDropdown from './dueStatusSelectDropdown';
import { getBusinessCouponDetailsById, requestCouponSettlement, getBusinessCouponDetailsExportToExcel, clearNotesRecord, getCouponSettlementsNotes, clearMsg } from '../../actions/campaignActions';
import { fetchEnvs } from '../../actions/authActions';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";



const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	containerTable: {
		width: '100%',
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
	},
	text: {
		width: '100%',
		paddingTop: theme.spacing(2),
		// paddingBottom: theme.spacing(-2),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	formControl: {
		minWidth: 225,
		maxWidth: 300,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '90%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
      tableRow: {
        cursor: 'pointer'
    },
});
class CampaignsCouponSettlementBusiness extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
            campaignName: "",
            businessId: "",
            sponsoredBy: "",
            showDownloadIcon: false,
            headers: [
                { key: 'businessId', label: 'Business ID' },
                { key: 'businessName', label: 'Business Name' },
                { key: 'honouredCnt', label: 'No Of Coupons Honoured' },
                { key: 'honouredAmount', label: 'Total Amt Of Coupons Honoured' },
                { key: 'settledCnt', label: 'Settled Coupons Count'},
                { key: 'settledAmount', label: 'Settled Coupons Amt'},
                { key: 'notSettledCnt', label: 'Not Settled Coupons'},
                { key: 'notSettledAmt', label: 'No tSettled Coupons Amt'},
                { key: 'campId', label: 'Campaign ID'},
                { key: 'campaignName', label: 'Campaign Name' }
            ],
            businessName: "",
            business: "",
            campaign: "",
            category: "",
            duration: "",
            dueStatus: "",
            settleModal: false,
            openNotesModal: false,
            filterStartDate: "",
            filterEndDate: "",
            startDate: "",
            endDate: "",
            endDateError: "",
            startDateError: "",
            dateError: "",
            ranges: {
                // 'Today': [moment(), moment()],
                // 'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                // 'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        }
    }

    componentDidMount() {
        this.props.changeHeaderName("Business Coupon Settlement Report");
        // this.setState({business:this.props.location.state.businessId, dueStatus:"Not Settled"}, () => this.fetchFilter())
        this.setState({businessId:this.props.location.state.businessId,businessName:this.props.location.state.businessName,business:this.props.location.state.businessId, dueStatus:"Not Settled"}, () => this.fetchFilter())
        this.props.fetchEnvs();
        // this.props.getBusinessCouponDetailsById(this.props.match.params.businessId);
        // this.fetchFilter();
    };

    searchButtonClick=()=>{
        if(this.state.startDate && !this.state.endDate){
            this.setState({dateError:"Both From and To month is required to search"})
        } else if(!this.state.startDate && this.state.endDate){
            this.setState({dateError:"Both From and To month is required to search"})
        } else{
            this.setState({dateError:""})
            this.fetchFilter()
        }
        
    }

    fetchFilter = async () => {
        this.props.requestCouponSettlement();
        let payload = {
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            businessName: this.state.business,
            campaignName: this.state.campaign.trim(),
            category: this.state.category,
            sponsoredBy: this.state.sponsoredBy,
            // duration: this.state.duration,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            dueStatus: this.state.dueStatus ? (this.state.dueStatus==='Not Settled' ? false : true) : "",
        }
        await this.props.getBusinessCouponDetailsById(payload);
        this.setState({showDownloadIcon: false });
    };

    resetFilter = () => {
        this.setState({
            // business: "",
            campaign: "",
            category: "",
            duration: "",
            dueStatus: "",
            filterStartDate: "",
            filterEndDate: "",
            startDate: "",
            endDate: "",
            endDateError: "",
            startDateError: "",
            dateError: "",
            sponsoredBy: ""
        }, () => {
            this.fetchFilter();
        })
    };

    handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null, editMode: false });
		this.fetchFilter();
	};

    componentDidUpdate(prevProps, prevState) {
		if (this.props.campaign.sucessMsg) {
			this.setState({ sucessMsg: this.props.campaign.sucessMsg })
			this.setState({ snackOpen: true });
			this.setState({ modalOpen: false });
			this.props.clearMsg();
			this.fetchFilter();
		} else if (this.props.campaign.errorMsg) {
			this.setState({ errorMsg: this.props.campaign.errorMsg })
			this.setState({ snackOpen: true });
			this.props.clearMsg();
		}
	};

    fetchCouponSettlementWithExcelFile = async () => {
        this.props.requestCouponSettlement();
        let payload = {
            businessName: this.state.business,
            campaignName: this.state.campaign.trim(),
            category: this.state.category,
            // duration: this.state.duration,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            dueStatus: this.state.dueStatus ? (this.state.dueStatus==='Not Settled' ? false : true) : ""
        }
        await this.props.getBusinessCouponDetailsExportToExcel(payload);
        this.setState({ showDownloadIcon: true });

    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    openSettleModal = async (row) => {
        await this.setState({ selectedSettlementRecord: row });
        this.setState({ settleModal: true });
    };

    handleModalClose = () => {
        this.setState({ settleModal: false });
    };

    saveAndClose = async () => {
        this.onClose();
        this.fetchFilter();
    };

    onClose = async () => {
        this.setState({
            settleModal: false,
            openNotesModal: false
        });
        await this.props.clearNotesRecord();
    };

    openNotesModal = async (row) => {
        await this.setState({ selectedSettlementRecord: row });
        this.setState({ openNotesModal: true });
        await this.props.getCouponSettlementsNotes({couponSettlementId:row.couponSettlementId});
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchFilter());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };

    handleDateRangeChange = (event, picker) => {
        let startDateText = picker.startDate.format('MMM YYYY')
        let endDateText = picker.startDate.format('MMM YYYY') === picker.endDate.format('MMM YYYY')?"": " - " + picker.endDate.format('MMM YYYY')
        let reportTimeRange =startDateText + endDateText
        this.setState({
            filterStartDate: picker.startDate.startOf('month').format('YYYY-MM-DD[T00:00:00.000Z]'),
            filterEndDate: picker.endDate.endOf('month').format('YYYY-MM-DD[T23:59:59.999Z]')
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ duration: chosenLabel });
    }
    handleFromDateChange=(startDate)=>{
        let filterStartDate= moment(startDate)
        let currentDate=moment().startOf('month')
        filterStartDate=filterStartDate.startOf('month').format('YYYY-MM-DD[T00:00:00.000Z]')
        let endDateError=this.state.endDateError
        let startDateError=""
        if(startDate===null){
            endDateError=""
            startDateError=""
            startDate=""
            filterStartDate=""
        } else if(this.state.endDate && startDate>this.state.endDate){
            startDateError="From month cannot be more than to month"
            endDateError="To month cannot be less than from month"
        } else if(endDateError==="To month cannot be less than from month"){
            endDateError=""
        }
        if(startDate && startDate>currentDate){
            startDateError="From month should be current month or less"
        }
        this.setState({
            startDate, filterStartDate, startDateError, endDateError, dateError:""
        });
    }

    handleToDateChange=(endDate)=>{
        let filterEndDate= moment(endDate)
        let currentDate=moment().startOf('month')
        filterEndDate=filterEndDate.endOf('month').format('YYYY-MM-DD[T23:59:59.999Z]')
        let endDateError=""
        let startDateError=this.state.startDateError
        if(endDate===null){
            endDateError=""
            startDateError=""
            endDate=""
            filterEndDate=""
        } else if(this.state.startDate!=="" &&this.state.startDate>endDate){
            endDateError="To month cannot be less than from month"
            startDateError="From month cannot be more than to month"
        } else if(startDateError==="From month cannot be more than to month"){
            startDateError=""
        }
        if(endDate && currentDate<endDate){
            endDateError="To month should be current month or less"
        }
        this.setState({
            endDate, endDateError, filterEndDate, startDateError, dateError:""
        });
    }

    render() { 
        const { classes } = this.props;
        let muiAlert = null;

        // console.log("this.props in campaign coupon settlement per business------->",this.props)

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}
       let busId="";
       let busName="";
       if(this.state.businessName !=="" && this.state.businessId !=="" ){
        busId=this.state.businessId;
        busName=this.state.businessName;
       }
    
        return ( 
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
              

         <Container style={{ marginTop: '24px', marginBottom: '8px' , marginLeft: '24px'}}>
                        <Typography style={{ fontSize:'20px', fontWeight: 'bold' }}gutterBottom> 
                        {busName !=="" && busId !=="" ? busName+"("+busId+")" : null }
                        
                        </Typography>
                    </Container>
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <Container className={classes.searchPaper}>
                        <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography style={{ fontSize:'17px'}}>Search/Filter</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                    <Grid container spacing={1}>
                                    {/* <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Business ID / Name
                                        </Typography>
                                        <TextField fullWidth margin="dense" name="business" label="Business ID / Name" 
                                        value={this.state.business} 
                                        variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>  */}
                                    <Grid item xs={3} >
                                    {/* <Typography variant="subtitle2" gutterBottom>
                                        Campaign ID / Name
                                        </Typography> */}
                                        <TextField fullWidth margin="dense" name="campaign" label="Campaign ID / Name" value={this.state.campaign} variant="outlined" onChange={this.handleFilterChange} />
                                      </Grid>  
                                   <Grid item xs={3}>
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel id="delivery-partner-label">Category</InputLabel>
                                        <Select
                                            labelId="select-category-label"
                                            id="select-category-id"
                                            value={this.state.category}
                                            name="category"
                                            onChange={this.handleFilterChange}
                                            label="Category"
                                        >
                                             
                                            <MenuItem value="ReferAndEarn">ReferAndEarn</MenuItem>
                                            <MenuItem value="Promotional">Promotional</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                  </Grid>  
                                    {/* <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Category
                                        </Typography>
                                        <CouponCategorySelectDropdown
                                            handleFilterChange={this.handleFilterChange}
                                            category={this.state.category}
                                        />
                                    </Grid>      */}
                                      <Grid item xs={3} > 
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        <InputLabel id="delivery-partner-label">Due Status</InputLabel>
                                        <Select
                                            // labelId="select-transaction-status-label"
                                            // id="select-transaction-status-id"
                                            value={this.state.dueStatus}
                                            name="dueStatus"
                                            onChange={this.handleFilterChange}
                                            label="Due Status"
                                        >
                                             
                                            <MenuItem value="Not Settled">Not Settled</MenuItem>
                                            <MenuItem value="Settled">Settled</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                      </Grid>      
                                 
                                    {/* <Grid item xs={3} >
                                        <Typography variant="subtitle2" gutterBottom>
                                        Due Status
                                        </Typography>                                        
                                        <DueStatusSelectDropdown
                                            handleFilterChange={this.handleFilterChange}
                                            dueStatus={this.state.dueStatus}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Duration
                                        </Typography>
                                        <DateRangePicker
                                            ranges={this.state.ranges}
                                            onApply={this.handleDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                            >
                                        <TextField autoComplete='off' fullWidth margin="dense" name="duration" label="Duration" value={this.state.duration}variant="outlined" />
                                    </DateRangePicker>
                                    </Grid> */}
                                   {/* <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={this.state.ranges}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="transactionTime" label="From Month" value={this.state.dateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                                </Grid> */}
                                    <Grid item xs={3} >
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="demo-simple-select-standard-label">Sponsored By</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.sponsoredBy}
                                                name="sponsoredBy"
                                                onChange={this.handleFilterChange}
                                                label="Sponsored By"
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="business" style={{ minHeight: "28px", height: "28px" }}>Business</MenuItem>
                                                <MenuItem value="xirify" style={{ minHeight: "28px", height: "28px" }}>Xirify</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3} >
                                        <Typography variant="subtitle2" gutterBottom style={{marginBottom:"15px"}}>
                                            From Month
                                            </Typography>
                                          
                                        <DatePicker
                                             label= "From Month"
                                            selected={this.state.startDate}
                                            showMonthYearPicker
                                            dateFormat={"MMM yyyy"}
                                            selectsStart
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            maxDate={moment()}
                                            onChange={this.handleFromDateChange}
                                            className="form-control"
                                            
                                            >
                                        
                                        </DatePicker>
                                        <br/> 
                                        <br/>
                                        {this.state.startDateError ?
                                            <FormHelperText style={{ color: "red" }}>{this.state.startDateError}</FormHelperText> : null}
                                              {/* </FormControl> */}
                                              
                                    </Grid>  
                                    <Grid item xs={3} >
                                        <Typography variant="subtitle2" gutterBottom style={{marginBottom:"15px"}}>
                                            To Month
                                            </Typography>
                                        <DatePicker
                                            selected={this.state.endDate}
                                            showMonthYearPicker
                                            dateFormat={"MMM yyyy"}
                                            selectsEnd
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleToDateChange}
                                            className="form-control"
                                        />
                                        <br/>
                                        <br/>
                                        {this.state.endDateError ?
                                            <FormHelperText style={{ color: "red" }}>{this.state.endDateError}</FormHelperText> : null}
                                    </Grid>   
                                    
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormHelperText style={{ color: "red" }}>{this.state.dateError}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={8}></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                        >
                                        Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={this.searchButtonClick}
                                        disabled={this.state.endDateError || this.state.startDateError}
                                        >
                                        Search
                                        </Button>
                                    </Grid>
                                    </Grid>
                                    
                                </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Container>
                    <Container className={classes.text}>
                        <Container className={classes.searchPaper} style = {{fontSize:"16px", color:"red",paddingLeft: '10px'}}>
                             <b>Note :</b> Current month settlement can be done NEXT month & onwards
                        </Container>
                    </Container>

                    <Container className={classes.containerTable}>
                        { 
                            this.props.campaign.couponSettlementBusinessListData && this.props.campaign.couponSettlementBusinessListData.settlementdata ?
                                <CampaignsCouponSettlementBusinessTable 
                                    data = { this.props.campaign.couponSettlementBusinessListData.settlementdata }
                                    totalCount={this.props.campaign.couponSettlementBusinessListData.total}
                                    fullData={this.props.campaign.couponSettlementBusinessListData}
                                    fetchCouponSettlementWithExcelFile={this.fetchCouponSettlementWithExcelFile}
                                    exportToexcel={this.props.campaign.couponsettlementExcelRecords}
                                    showDownloadIcon={this.state.showDownloadIcon}
                                    history={this.props.history}
                                    openSettleModal={this.openSettleModal}
                                    openNotesModal={this.openNotesModal}
                                    settlementdataCount={this.props.campaign.couponSettlementBusinessListData.total}
                                    rowsPerPage={this.state.rowsPerPage}
                                    page={this.state.page}
                                    setPage={this.setPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                />
                            : null
                        }
                    </Container>

                    <Modal 
                        open={this.state.settleModal}
                        className={classes.modal}
                        onClose={this.handleModalClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <CouponSettleAmountModal
                            // open={this.state.settleModal}
                            onClose={this.handleModalClose}
                            envs = {this.props}
                            saveAndClose={this.saveAndClose}
                            fullData = {this.props}
                            data={this.state.selectedSettlementRecord}
                        />
                    </Modal>
                    
                    <AddCouponNotesModal
                        open={this.state.openNotesModal}
                        onClose={this.saveAndClose}
                        data={this.state.selectedSettlementRecord}
                    />

                </Container>
                <Snackbar
                    anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                    open={this.state.snackOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{'aria-describedby': 'message-id'}}
                >
                    {muiAlert}
                </Snackbar>
                <Backdrop className={classes.backdrop} open={this.props.campaign.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

const mapStateToProps = state => {
	return {
		coupon: state.coupon,
        campaign: state.campaign,
        user: state.auth.user,
        env: state.auth.env
	}
};

export default connect(mapStateToProps, { changeHeaderName, getBusinessCouponDetailsById, requestCouponSettlement, getBusinessCouponDetailsExportToExcel, fetchEnvs, clearNotesRecord, getCouponSettlementsNotes, clearMsg })(withStyles(styles)(CampaignsCouponSettlementBusiness));
// export default CampaignsCouponSettlementBusiness;