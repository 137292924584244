import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, withStyles, Button, Grid, Typography, Modal, Avatar, Divider, Box, Stepper, Step, StepLabel, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as RefundSVG } from '../../images/refundSVG.svg'
import { fetchEnvs } from '../../actions/authActions';
import { ReactComponent as TotalRefund } from '../../images/TotalRefund.svg';
import { ReactComponent as ReferenceNumber } from '../../images/ReferenceNumber.svg';
import Alert from '@material-ui/lab/Alert';

const styles = theme => ({
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF"
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'hidden',
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
    },
    modalContainer: {
        width: '40%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    contentForRefund: {
        height: '65%',
        margin: '10px 0px',
        overflowY: 'auto',
        overflowX: 'hidden'
    },



});

const steps = [
    {
        label: 'Refund initiated for ₹',
        description: `When refund request is initiated by business or admin either partial or full`,
    },
    {
        label: 'Refund will be issued to the original payment method',
        description:
            'Intermidiated state between request and successfull transaction',
    },
    {
        label: 'Refund Completed',
        description: `When refund is completed`,
    },
];

class RefundsDetailsView extends Component {
    constructor() {
        super();
        this.state = {
            activeStep: 0,
            snackOpen: false
        };
    }


    componentDidMount() {
        this.props.fetchEnvs();

    }

    callToCheckIndex = (created_at, index) => {
        let dateToReturn;
        if (index === 2) {
            dateToReturn = this.addDaysAndFormatDate(created_at, 7);

        } else {
            dateToReturn = this.convertTimestampToIST(created_at);
        }
        console.log("dateToReturn --->", dateToReturn)
        return dateToReturn;
    };

    addDaysAndFormatDate = (timestamp, daysToAdd) => {
        // Parse the timestamp into a Date object
        const date = new Date(timestamp);

        // Add the specified number of days
        date.setDate(date.getDate() + daysToAdd);

        // Define options for formatting the date
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        };

        // Use the Intl.DateTimeFormat object to format the date
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedParts = formatter.formatToParts(date);

        // Extract formatted parts
        const day = formattedParts.find(part => part.type === 'day').value;
        const month = formattedParts.find(part => part.type === 'month').value;
        const year = formattedParts.find(part => part.type === 'year').value;

        // Construct the desired formatted string
        const formattedString = `Estimated Date - ${day} ${month}, ${year}`;


        return formattedString;
    }

    convertTimestampToIST = (timestamp) => {
        // Parse the timestamp into a Date object
        const date = new Date(timestamp);

        // Convert to IST (Indian Standard Time)
        const istOffset = 5.5 * 60; // IST is UTC+5:30
        const utcOffset = date.getTimezoneOffset(); // Get the local timezone offset in minutes
        const istDate = new Date(date.getTime() + (istOffset + utcOffset) * 60 * 1000);

        // Define options for formatting the date and time
        const options = {
            day: '2-digit',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        // Use the Intl.DateTimeFormat object to format the date and time
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedParts = formatter.formatToParts(istDate);

        // Extract formatted parts
        const day = formattedParts.find(part => part.type === 'day').value;
        const month = formattedParts.find(part => part.type === 'month').value;
        const year = formattedParts.find(part => part.type === 'year').value;
        const hour = formattedParts.find(part => part.type === 'hour').value;
        const minute = formattedParts.find(part => part.type === 'minute').value;
        const dayPeriod = formattedParts.find(part => part.type === 'dayPeriod').value;

        // Construct the desired formatted string
        const formattedString = `${day} ${month}, ${year} | ${hour}.${minute} ${dayPeriod}`;

        return formattedString;
    }

    handleCopy = (cf_refund_id) => {
        navigator.clipboard.writeText(cf_refund_id || '')
            .then(() => {
                this.setState({ snackOpen: true });
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackOpen: false });
        ;
    };

    handleFocus = (event) => {
        event.target.style.borderColor = 'white';
    };

    handleBlur = (event) => {
        event.target.style.borderColor = 'transparent';
    };

    render() {

        const { classes } = this.props;
        const { activeStep } = this.state;

        

        // Create a new array by iterating in reverse
        let uniqueRefunds = new Map();
        let totalRefundAmount = 0;

        (this.props?.serviceRequest?.serviceRequestDetails?.cfRefundResponses || []).forEach(item => {
            const existingItem = uniqueRefunds.get(item.refund_id);
            if (!existingItem || item.refund_status === "SUCCESS" || existingItem.refund_status === "PENDING") {
                uniqueRefunds.set(item.refund_id, item);
            }
        });

        let result = Array.from(uniqueRefunds.values());


        if (result?.length) {
            result.forEach(element => {
                totalRefundAmount = totalRefundAmount + element.refund_amount

            })
        }
        // console.log("this.props in refundDetailsComponent ----->", this.props, "with totalRefundAmount ---->", totalRefundAmount)


        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <div style={{ height: "80%", width: "80%" }}>
                    <Container className={classes.modalContainer}>
                        <Grid container className={classes.header}>
                            <Grid item xs={8}>
                                <Typography style={{ fontSize: "16px", paddingTop: "8px" }} gutterBottom >
                                    Refund Details
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <CloseIcon style={{ height: "18px", marginLeft: "100px", marginTop: "5px" }} onClick={() => {

                                    this.props.onClose();

                                }} />
                            </Grid>
                        </Grid>

                        <Container className={classes.content}>
                            <Grid style={{ display: "flex", flexDirection: "row", backgroundColor: "#FAFAFA" }}>
                                <Avatar style={{ height: "50px", paddingBottom: "5px", backgroundColor: "#FAFAFA" }}>
                                    <RefundSVG height="25px" />
                                </Avatar>
                                <Typography variant="subtitle2" style={{ paddingLeft: "10px", fontSize: "14px" }} gutterBottom>
                                    This Order is cancelled/items changed by business & eligible for the refund
                                </Typography>
                            </Grid>

                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <Avatar style={{ height: "50px", paddingBottom: "5px", backgroundColor: "white" }}>
                                    <TotalRefund height="25px" />
                                </Avatar>
                                <Typography style={{ paddingLeft: "10px", fontSize: "12px", paddingTop: "12px", fontWeight: "bold" }} >
                                    REFUND FOR ₹{(totalRefundAmount).toFixed(2)}
                                </Typography>
                            </div>
                            <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '1px', marginBottom: "-10px"}} />

                            <Container className={classes.contentForRefund}>
                                {result?.map((refundStep, index) => {
                                    return (

                                        <Container key={index}  > {/* Assuming `item` has an `id` property, use a unique key */}
                                        {index !== 0 ? 
                                            <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '1px', paddingRight: "150px", marginLeft: "-70px" }} />
                                        : null }
                                            <Box sx={{ maxWidth: 400 }} style={{ width: "370px", marginLeft: "-60px" }}>
                                                <Stepper activeStep={activeStep} orientation="vertical">
                                                    {steps.map((stepLabel, i) => (
                                                        <Step key={i} completed={(i === 0 || refundStep.refund_status === "SUCCESS") ? true : false} style={{ height: "auto" }} >
                                                            <StepLabel style={{ "fontSize": "12px" }}>
                                                                {stepLabel.label} {i === 0 ? refundStep.refund_amount : ""} <br />
                                                                <Typography style={{ "fontSize": "12px", "fontWeight": "normal" }}>
                                                                    {this.callToCheckIndex(refundStep.created_at, i)}
                                                                </Typography>

                                                            </StepLabel>

                                                        </Step>
                                                    ))}
                                                </Stepper>

                                            </Box>
                                            <Grid style={{ marginTop: "-10px", marginBottom: "10px", backgroundColor: "#EFEFEF", marginLeft: "-2px", display: "flex", flexDirection: "row" }}>
                                                <Grid xs={10}>
                                                    <Typography style={{ fontSize: "12px", height: "40px", marginLeft: "10px", paddingTop: "3px" }}>
                                                        Refund reference number<br />
                                                        {refundStep.refund_arn ? refundStep.refund_arn : ""}
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={1}>
                                                    <IconButton style={{
                                                        paddingTop: "15px", backgroundColor: "#EFEFEF", border: '1px solid transparent', borderRadius: '50%', transition: 'border-color 0.3s',outline: 'none'}} onClick={() => { this.handleCopy(refundStep.cf_refund_id) }} onFocus={this.handleFocus}
                                                        onBlur={this.handleBlur}>
                                                        <ReferenceNumber height="12px" />
                                                    </IconButton>
                                                </Grid>

                                            </Grid>
                                            <Snackbar open={this.state.snackOpen} autoHideDuration={3000} onClose={(e) => { this.handleClose(e) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                                                <Alert onClose={(e) => { this.handleClose(e) }} severity="success" sx={{ width: '100%' }}>
                                                    Refund ID copied
                                                </Alert>
                                            </Snackbar>
                                        </Container>

                                    )
                                }
                                )}
                            </Container>



                        </Container>
                        <Grid container>
                            <Grid xs={9}>
                            </Grid>
                            <Grid xs={2} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        this.props.onClose()
                                    }}
                                    style={{ marginLeft: "10px", marginTop: "20px" }}
                                    size="small"
                                >
                                    OK
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => ({
    data: state, env: state.auth.env

});
const mapDispatchToProps = (dispatch) => ({
    fetchEnvs
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RefundsDetailsView));