import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import businessReducer from "./businessReducer";
import dashboardReducer from "./dashboardReducer";
import categoriesReducer from "./categoriesReducer";
import adminReducer from "./adminReducer";
import faqReducer from "./faqReducer";
import contractReducer from "./contractReducer";
import serviceRequestReducer from "./serviceRequestReducer";
import regionReducer from "./regionReducer";
import regionalHeadReducer from "./regionalHeadReducer";
import areaReducer from "./areaReducer";
import areaManagerReducer from "./areaManagerReducer";
import invitesReducer from "./invitesReducer";
import coupenReducer from "./couponReducer";
import deliveriesReducer from "./deliveriesReducer";
import appMaintenanceReducer from "./appMaintenanceReducer";
import campaignReducer from "./campaignReducer";
import promocodeReportReducer from "./promocodeReportReducer";
import offerCodeReportReducer from "./offerCodeReportReducer";
import paymentSummaryReducer from "./paymentSummaryReducer";
import businessItemTagReducer from "./businessItemTagReducer";
import noticeAppReducer from "./noticeAppReducer";
import variationsReducer from "./variationsReducer";
import addonsReducer from "./addonsReducer";
import addonGroupReducer from './addonGroupReducer'
import expenseReducer    from './expenseReducer'
import businessAgreementReducer from "./businessAgreementReducer";


export default combineReducers({
  auth: authReducer,
  business: businessReducer,
  dashboard: dashboardReducer,
  categories: categoriesReducer,
  admin: adminReducer,
  faq: faqReducer,
  contract: contractReducer,
  serviceRequest: serviceRequestReducer,
  region: regionReducer,
  regionalHead: regionalHeadReducer,
  area: areaReducer,
  areaManager:areaManagerReducer,
  form: formReducer,
  invites: invitesReducer,
  coupon: coupenReducer,
  deliveries: deliveriesReducer,
  appMaintenance: appMaintenanceReducer,
  campaign: campaignReducer,
  promocodes: promocodeReportReducer,
  offercodes: offerCodeReportReducer,
  paymentSummary: paymentSummaryReducer,
  businessItemTag : businessItemTagReducer,
  notice :noticeAppReducer,
  variation : variationsReducer,
  addons    : addonsReducer,
  addonGroup : addonGroupReducer,
  businessAgreement: businessAgreementReducer,
  expenseList : expenseReducer
});
 