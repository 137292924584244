import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CollapsibleTable from "./ManageVariationTable";
import Modal from "@material-ui/core/Modal";
import {
  createVariationGroup,
  getAllVariationGroups,
  clearMsg,
  getItemsToLinkVariationGroup,
  linkVariationsToGroup,
  updateVariationGroup,
  updateVariationsFlag,
  deleteGroupWithVariations,
  requestGroupRecords,
  requestGroupRecordsFalse
} from "../../../actions/variationsAction"
import CreateVariationGroup from "./CreateVariationGroup";
import AddVariationModel from "./AddVariationModal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import LinkedAddonsModal from "./LinkedAddonsModal";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({
  container: {
    width: "100%",
    float: "left",
    padding: "5px 0px 0px 0px",
  },
  table: {
    float: "left",
    padding: "5px 0px 0px 0px",
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 800,
    marginleft: "4px",
  },
  createVariationConatiner: {
    height: "100%",
    width: "480px",
    display: "flex",
    alignItems: "center!important",

  },
  addVariationContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    padding: "10px 80px 10px 80px"
  }

});

const foodType = ['Veg','NonVeg','Egg','NA','Blank']
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class ManageVariationGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      variationGroupId: "",
      foodType: [],
      page: 0,
      rowsPerPage: 10,
      rows: [],
      openCreateVariation: false,
      addVariationModel: false,
      itemId: " ",
      addVariationObj: {},
      selectedItemId: [],
      numSelectedItems: "",
      showUnselectedRecords: false,
      showSelectedRecords: false,
      searchedRecords: [],
      editFlag: false,
      handleDeleteVariationGroup: false,
      selectedGroupToDelete: [],
      isSelectedExist: "",
      handleUnlinkVariation: false,
      variationUnlinkData: null,
      isEnabled: "",
      isDisplay: "",
      availability: "",
      itemIdToFetchAddon : "",
      handleLinkedAddonModalOpen : false,
      selectedCount : 0,
      unSelectedCount : 0,
      stateRefresh :0,
      isSelectAllChecked : false,
    };
  }

  async componentDidMount() {
    await this.fetchVariationsFilter()
  }

  fetchVariationsFilter = async () => {
    await this.props.requestGroupRecords()
    let payload = {
      id: this.props.businessDetails._id,
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      variationGroupId: this.state.variationGroupId.trim(),
      foodType: this.state.foodType,
      isEnabled: this.state.isEnabled,
      availability: this.state.availability,
      isDisplay: this.state.isDisplay
    }
    await this.props.getAllVariationGroups(payload);
    await this.props.requestGroupRecordsFalse()
  }

  resetFilter = async () => {
    this.setState(
      {
        variationGroupId: "",
        foodType: [],
        page: 0,
        isEnabled: "",
        isDisplay: "",
        availability: "",
        itemIdToFetchAddon : "",
        handleLinkedAddonModalOpen : false,
        selectedCount : 0,
        unSelectedCount : 0,
        isSelectAllChecked : false,
        showUnselectedRecords: false,
        showSelectedRecords: false,
      },
      () => {
        this.fetchVariationsFilter();
      }
    );
  };

  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };
  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchVariationsFilter());
  };
  setPage = (page) => {
    this.setState({ page }, () => this.fetchVariationsFilter());
  };
  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleOpen = (data) => {
    this.setState({ open: data })
  };
  openCreateVariation = () => {
    this.setState({ openCreateVariation: true })
  }
  closeCreateVariation = () => {
    this.setState({ openCreateVariation: false, editFlag: false })
  };
  createVariationGroup = async (payload) => {
    await this.props.createVariationGroup(payload);
    await this.fetchVariationsFilter()
  };
  handleAddVariationModel = async (value) => {
    this.setState({ addVariationModel: true })
    this.setState({
      addVariationObj: {
        _id: value._id,
        foodType: value.foodType,
        name: value.variationGroupName
      }
    })

  };
  setSelectedItems = async (selectedItemId) => {
    this.setState({ selectedItemId: selectedItemId })
  };
  fetchItemsToLinkVariationGroup = async () => {

    if (this.state.itemId) {
      this.setState({ selectedCount : 0  , unSelectedCount : 0  , showUnselectedRecords: false,  showSelectedRecords: false, selectedItemId: [],})
      let payload = {
        id: this.props.businessDetails._id,
        data: {
          itemId: this.state.itemId.trim(),
          foodType: this.state.addVariationObj.foodType,
          isSearched : true
        }
      }
      await this.props.getItemsToLinkVariationGroup(payload);
      await this.setState({ searchedRecords: this.props.variation.linkItemsToGroupList.data })
    };

  };
  handleLinkFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  resetAddVariation = async() => {
    this.setState(
      {
        itemId: " ",
        searchedRecords : [],
        selectedItemId: [],
        numSelectedItems: "",
        showUnselectedRecords: false,
        showSelectedRecords: false,
        selectedCount : 0,
        unSelectedCount : 0,
        isSelectAllChecked : false

      });
      let payload ={
        id: this.props.businessDetails._id,
        data: {
          isSearched : false
        }
      }
      await this.props.getItemsToLinkVariationGroup(payload) 
  }
  closeAddVariation = async () => {
    this.setState(
      {
        addVariationModel: false,

      },
      () => { this.resetAddVariation() }
    )
  };

  handleShowUnselected = async (value) => {
    this.setState({ showUnselectedRecords: value });

    if (value === true) {
      let searchedRecords = this.state.searchedRecords;
      let selectedItemId = this.state.selectedItemId;
      var filteredUnSelectedRecords = searchedRecords.filter((v) => !selectedItemId.includes(v._id));
      this.setState({ searchedRecords: filteredUnSelectedRecords })
    } else {
      this.setState({ searchedRecords: this.props.variation.linkItemsToGroupList.data })
    }
  }
  handleShowSelected = async (value) => {
    this.setState({ showSelectedRecords: value });

    if (value === true) {
      let searchedRecords = this.state.searchedRecords;
      let selectedItemId = this.state.selectedItemId;
      var filteredSelectedRecords = searchedRecords.filter((v) => selectedItemId.includes(v._id));
      this.setState({ searchedRecords: filteredSelectedRecords })
    } else {
      this.setState({ searchedRecords: this.props.variation.linkItemsToGroupList.data })
    }
  };
  onAddVariationSubmit = async () => {

    if (this.state.selectedItemId.length) {

      let totalItemRecords = this.props.variation.linkItemsToGroupList.data
      let selectedItemId = this.state.selectedItemId;
      let filteredSelectedRecords = totalItemRecords.filter((v) => selectedItemId.includes(v._id));

      if (filteredSelectedRecords.length) {
        let isDefaultViewItem = filteredSelectedRecords.reduce((a, b) => a.cost?.fixedCost < b.cost.fixedCost ? a : b);
        await this.props.requestGroupRecords()
        let payload = {
          _id: this.state.addVariationObj._id,
          defaultViewItemId: isDefaultViewItem._id,
          itemIds: selectedItemId

        };
        await this.props.linkVariationsToGroup(payload);
        await this.closeAddVariation()
        await this.fetchVariationsFilter()
        await this.props.requestGroupRecordsFalse()
      }
    }
  };

  handleEditVariationGroup = async (variationRecord) => {

    this.setState({
      editFlag: true,
      dataToSet: variationRecord
    });
    this.openCreateVariation()

  };

  onUpdateVariationGroup = async (payload) => {
    await this.props.updateVariationGroup(payload);
    await this.fetchVariationsFilter()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.props.variation.sucessMsg) {
      this.setState({ sucessMsg: this.props.variation.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    } else if (this.props.variation.errorMsg) {
      this.setState({ errorMsg: this.props.variation.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };

  handleGroupSwitchChange = async (event, value) => {

    let payload = {
      id: value._id
    }
    if (event.target.name === "isAvailable") {
      payload[event.target.name] = event.target.checked
    } else {
      payload[event.target.name] = event.target.checked
    }
    await this.props.updateVariationGroup(payload);
    await this.fetchVariationsFilter()

  };

  handleFlagChange = async (event, value, row) => {
    let payload = {
      variationGroupId: row._id,
      serviceId: value._id
    }
    if (event.target.name === "isDefaultVariationView") {
      payload[event.target.name] = event.target.checked
    }
    if (event.target.name === "availability") {
      payload[event.target.name] = event.target.checked
    }
    if (event.target.name === "isDisplay") {
      payload[event.target.name] = event.target.checked
    }
    if (event.target.name === "isEnabled") {
      payload[event.target.name] = event.target.checked
    }
    if (event.target.name === "isDisplay") {
      payload[event.target.name] = event.target.checked
    }
    await this.props.updateVariationsFlag(payload)
    await this.fetchVariationsFilter()

  };

  handleDeleteVariationGroupOpen = async () => {
    this.setState({ handleDeleteVariationGroup: true })
  };

  handleDeleteVariationGroupClose = async () => {
    this.setState({
      handleDeleteVariationGroup: false,
      selectedGroupToDelete: [],
      isSelectedExist: ""
    })
  };

  deleteVariationGroup = async () => {
    if (this.state.selectedGroupToDelete) {
      let payload = {
        variationGroupIds: this.state.selectedGroupToDelete,
        message : 'Variation Group Deleted Successfully'
      }

      await this.props.deleteGroupWithVariations(payload);
      await this.fetchVariationsFilter();
      this.handleDeleteVariationGroupClose()

    }
  };

  setSelectedGroupDelete = (IdArr) => {
    this.setState({ selectedGroupToDelete: IdArr })
  };

  handleUnlinkVariationOpen = (value, row) => {
    this.setState({ handleUnlinkVariation: true });
    this.setState({ variationUnlinkData: { variationDetails: value, variationGroupId: row._id } })
  };
  handleUnlinkVariationClose = () => {
    this.setState({ handleUnlinkVariation: false });
    this.setState({ variationUnlinkData: null });
  };
  unlinkVariation = async () => {

    if (this.state.variationUnlinkData) {
      let variationObj = this.state.variationUnlinkData;

      let payload = {
        variationGroupId: variationObj.variationGroupId,
        variationId: variationObj?.variationDetails?._id,
        isDefaultVariationView: Boolean(variationObj?.variationDetails?.isDefaultVariationView),
        message : 'Variation Unlinked Successfully'
      };
      await this.props.deleteGroupWithVariations(payload);
      await this.fetchVariationsFilter();
      this.handleUnlinkVariationClose()
    }
  };
  handleSwitchChange = async (event, service) => {
    let updatePayload = {};
    if (event.target.name === 'availability') {
        let availabilityData = service.availability;
        availabilityData.isActive = event.target.checked;
        updatePayload.availability = availabilityData;
    } else {
        updatePayload[event.target.name] = event.target.checked;
    }

    let payload = {
        serviceId: service._id,
        data: updatePayload
    }
    await this.props.updateService(payload);
    await this.fetchVariationsFilter()
    
}
handleLinkedAddonOpen = (_id)=>{
this.setState({ handleLinkedAddonModalOpen : true , itemIdToFetchAddon : _id })
}
handleLinkedAddonModalClose = async()=>{
this.setState({ handleLinkedAddonModalOpen : false , itemIdToFetchAddon : "" })
await this.fetchVariationsFilter()
}

handleItemSelection = async(event , _id)=>{
  let totalItemRecords = this.props.variation.linkItemsToGroupList.data
  let selectedItemId = this.state.selectedItemId;
  if (event.target.checked) {
  let selectedCount = totalItemRecords.filter((v) => selectedItemId.includes(v._id));
  let unSelectedCount =  totalItemRecords.length - selectedCount.length;
  this.setState({ selectedCount : selectedCount.length  , unSelectedCount : unSelectedCount})

 }else{
  
  let selectedCount = selectedItemId.filter((v) =>  v !== _id);
  let unSelectedCount =  totalItemRecords.length - selectedCount.length;
  this.setState({stateRefresh : Math.random(), selectedCount : selectedCount.length  , unSelectedCount : unSelectedCount})

 }

}

handleItemSelectCount = async(event)=>{
  let totalItemRecords = this.props.variation.linkItemsToGroupList.data
  if (event.target.checked) {
    this.setState({ selectedCount : totalItemRecords.length  , unSelectedCount : 0  ,isSelectAllChecked : true})
  } else {
    this.setState({ selectedCount : 0  , unSelectedCount : totalItemRecords.length  ,isSelectAllChecked : false})
  }
}


  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



  render() {
    const { classes } = this.props;
    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }

    return (
      <Container className={classes.container}>
        <div className={classes.appBarSpacer} />
       

        {this.props.role === "areaManager" ?
          <Grid item xs={12} sm={12}>
            <Container>
              <Button
                 style={{ float: "right", margin: "5px 100px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.handleDeleteVariationGroupOpen}
                disabled={this.state.selectedGroupToDelete.length < 1}
              >
                Delete
              </Button>

            </Container>

            <Container>
              <Button
                style={{ float: "right", margin: "5px" }}
                variant="contained"
                color="primary"
                onClick={this.openCreateVariation}
              >
                Create Variation Group
              </Button>
              <Modal
                className={classes.createVariationModel}
                open={this.state.openCreateVariation}
                onClose={this.closeCreateVariation}
                closeAfterTransition
              >
                {
                  <Container className={classes.createVariationConatiner}>
                    <CreateVariationGroup
                      closeCreateVariation={this.closeCreateVariation}
                      businessMemberDetails={this.props.business.businessMembersDetails}
                      businessDetails={this.props.businessDetails}
                      onSubmit={this.createVariationGroup}
                      editFlag={this.state.editFlag}
                      dataToSet={this.state.dataToSet}
                      onUpdate={this.onUpdateVariationGroup}

                    />
                  </Container>
                }
              </Modal>
            </Container>

          </Grid> : null}

        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }} 
        >

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Search/Filter Report</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="variationGroupId"
                    label="GroupId / Name"
                    value={this.state.variationGroupId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                        <InputLabel id="demo-simple-select-standard">Food Type</InputLabel>
                        <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={this.state.foodType}
                                    name="foodType"
                                    onChange={this.handleFilterChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                            >
                            {foodType.map((foodType) => (
                                        <MenuItem key={foodType} value={foodType} style={{ minHeight: "28px", height: "28px" }}>
                                            <Checkbox checked={this.state.foodType.indexOf(foodType) > -1} />
                                            <ListItemText primary={foodType} />
                                        </MenuItem>
                                    ))}
                        </Select>
                    </FormControl>
                  </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Is Enabled
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="isEnabled"
                      label="Is Enabled"
                      value={this.state.isEnabled}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled </MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">Availability</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard-label"
                      name="availability"
                      label="Availability"
                      value={this.state.availability}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Available</MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Unavailable</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">Hide/Display</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard-label"
                      name="isDisplay"
                      label="isDisplay"
                      value={this.state.isDisplay}
                      onChange={this.handleFilterChange}
                    > 
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Hide</MenuItem>
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Display</MenuItem>
                     
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={
                      () => this.setPage(0)

                    }
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Container>
        <Container className={classes.table}>
          <CollapsibleTable
            data={this.props.variation.variationGroupList.data}
            totalCount={this.props.variation.variationGroupList.totalCount}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            setPage={this.setPage}
            setRowsPerPage={this.setRowsPerPage}
            open={this.state.open}
            handleOpen={this.handleOpen}
            handleEditVariationGroup={this.handleEditVariationGroup}
            role={this.props.user.role}
            handleAddVariationModel={this.handleAddVariationModel}
            handleGroupSwitchChange={this.handleGroupSwitchChange}
            handleFlagChange={this.handleFlagChange}
            selectedGroupToDelete={this.state.selectedGroupToDelete}
            setSelectedGroupDelete={this.setSelectedGroupDelete}
            handleUnlinkVariationOpen={this.handleUnlinkVariationOpen}
            handleLinkedAddonOpen={this.handleLinkedAddonOpen}
          />
        </Container>
        <Modal
          className={classes.addVariationContainer}
          open={this.state.addVariationModel}
          onClose={this.closeAddVariation}
          closeAfterTransition
        >
          <Container className={classes.addVariationContainer}>
            <AddVariationModel
              closeAddVariation={this.closeAddVariation}
              onSubmit={this.createVariationGroup}
              selected={this.state.selectedItemId}
              numSelectedItems={this.state.numSelectedItems}
              setSelectedItems={this.setSelectedItems}
              fetchItemsToLinkVariationGroup={this.fetchItemsToLinkVariationGroup}
              searchedRecords={this.state.searchedRecords}
              totalSearchedCount={this.state.searchedRecords.length}
              handleLinkFilterChange={this.handleLinkFilterChange}
              itemId={this.state.itemId}
              groupNameHeader={this.state.addVariationObj.name}
              resetAddVariation={this.resetAddVariation}
              showUnselectedRecords={this.state.showUnselectedRecords}
              handleShowUnselected={this.handleShowUnselected}
              finalRecords={this.props.variation.linkItemsToGroupList.data}
              finalCount={this.props.variation.linkItemsToGroupList.totalCount}
              handleShowSelected={this.handleShowSelected}
              showSelectedRecords={this.state.showSelectedRecords}
              onAddVariationSubmit={this.onAddVariationSubmit}
              isSelectedExist={this.state.isSelectedExist}
              handleItemSelection={this.handleItemSelection}
              selectedCount={this.state.selectedCount}
              unSelectedCount={this.state.unSelectedCount}
              handleItemSelectCount={this.handleItemSelectCount}
              isSelectAllChecked={this.state.isSelectAllChecked}
              noRecordsFound={this.props.variation.linkItemsToGroupList.noRecordsFound}
            />
          </Container>
        </Modal>
          
        <Modal
             open={this.state.handleLinkedAddonModalOpen}
             className={classes.linkedItemsModal}
             onClose={this.handleLinkedAddonModalClose}
             closeAfterTransition
           >
            <LinkedAddonsModal
             handleLinkedAddonModalClose={this.handleLinkedAddonModalClose}
             itemId={this.state.itemIdToFetchAddon}
             role={this.props.role}
             />
            </Modal>  


        {this.state.selectedGroupToDelete.length > 0 ? <Dialog
          open={this.state.handleDeleteVariationGroup}
          onClose={this.handleDeleteVariationGroupClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Variation Group</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete Variation Group ?<br></br>
              (Note: Deleting group will unlink all variations within, if present.)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteVariationGroupClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.deleteVariationGroup()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog> : null}

        <Dialog
          open={this.state.handleUnlinkVariation}
          onClose={this.handleUnlinkVariationClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Unlink  Variation</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Unlink Variation from Group ?<br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUnlinkVariationClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.unlinkVariation()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>

        <Backdrop
          className={classes.backdrop}
          open={this.props.variation.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    variation: state.variation,
    user: state.auth.user,
    business: state.business,
    addons  : state.addons
  };
};

export default connect(mapStateToProps, {
  clearMsg,
  getAllVariationGroups,
  createVariationGroup,
  getItemsToLinkVariationGroup,
  linkVariationsToGroup,
  updateVariationGroup,
  updateVariationsFlag,
  deleteGroupWithVariations,
  requestGroupRecords,
  requestGroupRecordsFalse
})(withStyles(styles)(ManageVariationGroups));
