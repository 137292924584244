import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { loginUser, getAdminProfile } from "../../actions/authActions";
import setAuthToken from "../../utils/setAuthToken";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    }
});

class Login extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: "",
            snackOpen: false,
        };
    }

    componentDidMount() {
    
    if (localStorage.jwtToken) {
      const token = localStorage.jwtToken;
      setAuthToken(token);
      this.props.getAdminProfile();
    } 

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            if(localStorage.lastVisitedPage && (localStorage.lastVisitedUser && localStorage.lastVisitedUser === nextProps.auth.user.role)){
            // if(localStorage.lastVisitedPage){
                this.props.history.push(localStorage.lastVisitedPage);     
            }
            else{
                this.props.history.push("/dashboard");
                // window.localStorage.setItem("user", JSON.stringify(nextProps.auth.user.role));
                // window.localStorage.setItem("user", nextProps.auth.user.role);
            }
        }

        if (nextProps.auth.isAuthenticated === false) {
            this.setState({ snackOpen: true });
        }
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = event => {
        event.preventDefault();
        const userData = {
            username: this.state.username,
            password: this.state.password
        };

        this.props.loginUser(userData);
    };

    clickForgotPassword = e => {
        e.preventDefault();
        this.props.history.push("/forgot");
    }
    
    handleSnackClose = () => {
        this.setState({ snackOpen: false });
    }

    render() {
        const { classes } = this.props;
         console.log("this.props=>",this.props);
         let webLogo = this.props?.dashboard?.data && this.props?.dashboard?.data.length>0 && this.props?.dashboard?.data[0]?.webLogo ? this.props.dashboard.data[0].webLogo : "../../images/xirify_logo.png";
         console.log("this.webLogo++++++++",webLogo);
     
        return (
            <div>
                <Container maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <img className={classes.avatar} alt="XIRIFY" src={webLogo}></img>
                        <Typography component="h1" variant="h5">
                            Administrator
                        </Typography>
                        <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="User Name"
                                name="username"
                                autoComplete="User Name"
                                autoFocus
                                value={this.state.username}
                                onChange={this.onChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={this.onChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body1" onClick={this.clickForgotPassword}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        open={this.state.snackOpen}
                        autoHideDuration={6000}
                        onClose={this.handleSnackClose}>
                            <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                                {this.props.auth.loginErr}
                            </MuiAlert>
                    </Snackbar>
                </Container>
            </div>
        );
    }

}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    dashboard: state.dashboard.getMasterOTP,
});

export default connect(mapStateToProps, { loginUser, getAdminProfile })(withStyles(styles)(Login));
