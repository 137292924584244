

import axios from "axios";
import {
  PATCH_REPORTED_SUCCESS_MESSAGE,
  PATCH_REPORTED_ERROR_MESSAGE,
  PROMOCODEREPORT_STATE_CHG_SUCCESS,
  PROMOCODEREPORT_STATE_CHG_ERR,
  SET_PROMOCODEREPORT_SUCCESS_MESSAGE,
  SET_PROMOCODEREPORT_ERROR_MESSAGE,
  SET_PROMOCODEREPORT,
  REQUEST_PROMOCODEREPORT,
   OFFERCODEREPORT_STATE_CHG_SUCCESS,
   OFFERCODEREPORT_STATE_CHG_ERR,
  SET_OFFERCODEREPORT_SUCCESS_MESSAGE,
  SET_OFFERCODEREPORT_ERROR_MESSAGE,
  SET_OFFERCODEREPORT,
  REQUEST_OFFERCODEREPORT
} from "./types";

export const clearMsg = () => async dispatch => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_PROMOCODEREPORT_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_PROMOCODEREPORT_ERROR_MESSAGE, payload: data });
  dispatch({ type: SET_OFFERCODEREPORT_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_OFFERCODEREPORT_ERROR_MESSAGE, payload: data });
};

export const requestPromoCodes = () => {
  return {
    type: REQUEST_PROMOCODEREPORT,
    payload: true
  };
};

export const requestOfferCodes = () => {
  return {
    type: REQUEST_OFFERCODEREPORT,
    payload: true
  };
};


export const clearPromoCodesMsg = () => {
  return {
    type: PROMOCODEREPORT_STATE_CHG_SUCCESS,
    payload: null
  };
};
export const clearOfferCodesMsg = () => {
  return {
    type: OFFERCODEREPORT_STATE_CHG_SUCCESS,
    payload: null
  };
};
export const clearBusiErrorMsg = () => {
  return {
    type: PROMOCODEREPORT_STATE_CHG_ERR,
    payload: null
  };
};
export const clearBusiErrorMsgForOfferCodes = () => {
  return {
    type: OFFERCODEREPORT_STATE_CHG_ERR,
    payload: null
  };
};

export const clearSuccessMsg = () => {
  return {
    type: PATCH_REPORTED_SUCCESS_MESSAGE,
    payload: null
  };
};

export const clearErrorMsg = () => {
  return {
    type: PATCH_REPORTED_ERROR_MESSAGE,
    payload: null
  };
};

export const getPromoCodes = (payload) => async dispatch => {
  try {
    let promoCodes = await axios.get(`api/consumers/campaignconsumers`, { params: payload });
    dispatch({
      type: SET_PROMOCODEREPORT, payload: promoCodes.data
    });
  } catch (err) {
    console.log("PromoCode Report Error==========>", err);
    dispatch({
      type: SET_PROMOCODEREPORT_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Error while fetching PromoCode Report." }
    });
  }
};
export const getOfferCodes = (payload) => async dispatch => {
  try {
    let offerCodes = await axios.get(`api/consumers/offerCodeConsumers`, { params: payload });
    dispatch({
      type: SET_OFFERCODEREPORT, payload: offerCodes.data
    });
  } catch (err) {
    console.log("PromoCode Report Error==========>", err);
    dispatch({
      type: SET_OFFERCODEREPORT_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Error while fetching OfferCode Report." }
    });
  }
};
export const assignCoupon = (payload) => async dispatch => {
  try {
    // let response = await axios.post(`api/coupon/assigncoupon`, { params: payload });
    // let response = await axios.post(`api/coupon/assigncoupon?promoCode=`+payload.promoCode+`&consumerId=`+payload.consumerId);
    let response = await axios.post(`api/coupon/assigncoupon?consumerId=`+payload.consumerId,{promoCode:payload.promoCode});
    if(response?.data?.message){
      dispatch({
        type: SET_PROMOCODEREPORT_SUCCESS_MESSAGE, payload: { ops: "UPDATE", msg: response.data.message }
      });
    }
  } catch (err) {
    console.log("Coupon Assign Error==========>", err);
    dispatch({
      type: SET_PROMOCODEREPORT_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: err?.response?.data?.error?err.response.data.error:"Error while assigning coupon." }
    });
  }
};