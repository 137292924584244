import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import { addHeadSubhead, getHeadSubheadHierarchy, updateHeadAndSubhead } from '../../../actions/businessActions';
const styles = theme => ({
    header: {
        height: '20%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    grid: {
        margin: '0px 0px',
        width: '100%',
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    content: {
        height: '73%',
        // height: '60%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '40%',
        height: '35%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
        margin: '10px 0px',
        overflow: 'auto',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    }
});


class HeadDetailView extends Component {
    constructor(props) {
        super();
        this.state = {
            headName: "",
            sequenceNo: 1,
            error: ""
        };
    }

    handleIncrement = () => {
        let sequence = this.state.sequenceNo + 1;
        if (sequence <= (this.props.headsLength + 1)) {
            this.setState({ sequenceNo: sequence });
        }
    };

    handleDecrement = () => {
        if (this.state.sequenceNo && (this.state.sequenceNo > 1)) {
            this.setState(state => ({ sequenceNo: state.sequenceNo - 1 }));
        }
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });

        if (!this.state.error) {
            this.setState({ error: "" })
        }
    }

    checkerror = () => {
        if (!this.state.headName || !this.state.headName.trim()) {
            this.setState({ error: "Required" })
        }
    }

    createHead = async () => {
        await this.checkerror()
        if (!this.state.error) {
            if (!this.props.edit) {
                let payload = {
                    name: this.state.headName,
                    type: "Head",
                    parentId: null,
                    children: [],
                    subcategory: null,
                    businessId: this.props.businessDetails._id,
                    services: [],
                    sequence: this.state.sequenceNo
                };
                await this.props.addHeadSubhead(payload)
                this.close();

                this.props.getHeadSubheads();

            } else if (this.props.edit) {
                let payload = {
                    data: {
                        name: this.state.headName
                    },
                    id: this.props.data._id
                };
                if (this.state.sequenceNo) {
                    payload.data.sequence = this.state.sequenceNo;
                }
                await this.props.updateHeadAndSubhead(payload)
                this.close();
                this.props.getHeadSubheads()
            }
        }
    };

    clearData = () => {
        this.setState({
            headName: "",
            sequenceNo: 1,
            error: ""
        })
    }

    close = () => {
        this.clearData();
        this.props.onClose();
    }

    componentDidUpdate(prevProps) {
        if (this.props.edit && (this.props.edit !== prevProps.edit) && this.props.data.name) {
            this.setState({
                headName: this.props.data.name,
                sequenceNo: this.props.data.sequence
            })
        }
        if (this.props.headsLength && (this.props.headsLength !== prevProps.headsLength)) {
            this.setState({
                sequenceNo: this.props.headsLength + 1
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <Container className={classes.modalContainer}>
                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom>
                                Head
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.grid} spacing={1} style={{marginTop:"10px"}}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" gutterBottom>
                                Head Name*
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Sequence Number*
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField fullWidth margin="dense" name="headName" variant="outlined" value={this.state.headName} error={this.state.error} helperText={this.state.error} onChange={this.handleChange} />
                        </Grid>
                        {/* <Grid item xs={4} style={{ alignItems: "center", justify: "center", alignContent: "center" }} >
                            <ButtonGroup size="small" aria-label="small outlined button group">
                                <Button variant="contained" onClick={this.handleDecrement}>-</Button>
                                <TextField variant="filled" name="sequenceNo" value={this.state.sequenceNo} onChange={this.handleChange} />
                                <Button variant="contained" onClick={this.handleIncrement}>+</Button>
                            </ButtonGroup>
                        </Grid> */}
                        <Divider />
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={this.close}
                            >
                                Cancel
            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.createHead}
                            >
                                SAVE
            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        contract: state.contract,
        user: state.auth.user,
        env: state.auth.env,
    };
};

export default connect(mapStateToProps, {
    addHeadSubhead,
    getHeadSubheadHierarchy,
    updateHeadAndSubhead
})(withStyles(styles)(HeadDetailView));
