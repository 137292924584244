import {
    SET_REGIONS,
    SET_REGION_DETAILS,
    SET_REGION_SUCCESS_MESSAGE,
    SET_REGION_ERROR_MESSAGE,
    REQUEST_REGIONS
} from "../actions/types";

const INITIAL_STATE = {
    regions: {},
    regionDetails: {},
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false
};

export default function regionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_REGIONS:
            return { ...state, isFetching: action.payload };
        case SET_REGIONS:
            return { ...state, regions: action.payload, isFetching: false };
        case SET_REGION_DETAILS:
            return { ...state, regionDetails: action.payload, isFetching: false };
        case SET_REGION_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_REGION_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        default:
            return state;
    }
}