import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
// import Modal from '@material-ui/core/Modal';
import { Badge, Divider } from "@material-ui/core";
import { cuisineList, deleteCuisine, xirifyEnv, updateCuisine, addCuisine } from "../../actions/cuisineAction";
import { CSVLink } from "react-csv";
import Tooltip from '@material-ui/core/Tooltip';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const styles = theme => ({
    // appBarSpacer: theme.mixins.toolbar,
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: '168px'
    },
    secondaryButton: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        border: '1px solid #14B7DD',
        fontWeight: 'bold'
    },
    closeBadge: {
        color: 'grey',
        cursor: 'pointer'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '400px'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    editBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    arrowFwdBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    avatarGrey: {
        width: theme.spacing(10),
        height: theme.spacing(6),
    },
    img: {
        width: '43px',
        height: '43px',
        // paddingLeft: '10px',
        // paddingTop: '3px'
    },
    containerTable: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginTop: theme.spacing(3)
    },
    table: {
        justifyContent: 'left'
    },
    tableRow: {
        cursor: 'pointer',
        height: '60px'
    },
    tableBtn: {
        padding: '0px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    form: {
        margin: '5% 10%',
        maxWidth: '350px',
        // width: '80%',
        // height: '60%',
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        height: '20%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    headerBar: {
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    headGridTwoButton: {
        float: 'right',
        marginTop: '2px'
    },
    content: {
        // height: '50%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '20px 0px 10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridItem: {
        margin: '0px 20px',
    }
});

class Cuisine extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            modalAddOpen: false,
            modalEditOpen: false,
            modalDeleteOpen: false,
            snackOpen: false,
            noDelMsg: null,
            sucessMsg: null,
            showExcel: false,
            errorMsg: null,
            backDropOpen: false,
            isFetching: false,
            selectedCuisine: null,
            cuisineExists: null,
            inputErrorMessage: null,
            cloudinaryData: null,
            cuisineName: "",
            cuisineImage: null,
            cuisineData: {},
            cuisineList: [],
            cloudinaryFileData: null,
            isDisabled: true,
            excelDownloadList: []
        };
        this.onDragEndNew = this.onDragEndNew.bind(this)
        this.inputReference = React.createRef();
    }

    onDragEndNew = async (result) => {
        // console.log("dropped outside the list")
        if (!result.destination) {
            return
        }
        const cuisineList = reorder(
            this.state.cuisineList,
            result.source.index,
            result.destination.index
        )

        this.setState({
            cuisineList
        });

    }

    fileUploadAction = () => this.inputReference.current.click();

    componentDidMount() {
        this.props.attrs.changeHeaderName("Cuisine");
        this.getCuisineList();
        this.getCloudinaryEnv();

    }

    getCuisineList = async () => {
        let response = await cuisineList();
        this.setState({ cuisineList: response.data });

        let excelList = response.data.map(item => ({ name: item.name }));
        this.setState({ excelDownloadList: excelList });

    }

    getCloudinaryEnv = async () => {
        let res = await xirifyEnv();
        this.setState({ cloudinaryData: res.data });
    }

    removeCuisineImage = (e) => {
        this.setState({ cuisineFile: null });
    }

    handleChange = async (e) => {
        const { target: { value } } = e;

        if (e.target.files) {
            this.setState({ cuisineImage: e.target.files[0] })
            let files = e.target.files;
            let data = new FormData();
            let folderPath = "Marketplace_Business";
            let temp = files[0].name.split(".");
            let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

            data.append("file", files[0]);
            data.append("folder", folderPath);
            data.append(
                "public_id",
                `${fileName}`
            );
            data.append(
                "upload_preset",
                this.state.cloudinaryData.cloudinaryBusinessImagesUploadPreset
            );

            let res = await fetch(this.state.cloudinaryData.cloudinaryUploadUrl, {
                method: "POST",
                body: data,
            });

            let file = await res.json();
            this.setState({ cuisineFile: file });

        } else {

            this.setState({ cuisineName: value })
            if (!value.trim()) {
                this.setState({ inputErrorMessage: "Cuisine Name Cannot Be Empty" });
                this.setState({ cuisineName: "", isDisabled: true });
            } else {
                this.setState({ inputErrorMessage: null, isDisabled: false });
            }

        }

    }

    handleModalAddOpen = () => {
        this.setState({ selectedCuisine: null });
        this.setState({ modalAddOpen: true });
        this.setState({ cuisineName: "" });
        this.setState({ cuisineImage: null, cuisineFile: null });
    }

    handleModalAddClose = () => {
        this.setState({ modalAddOpen: false, inputErrorMessage: null });
        this.setState({ cuisineImage: null, cuisineFile: null, cuisineName: '' });
    }

    handleRank = async () => {

        let payload = [];

        this.state.cuisineList.forEach((e, i) => {
            // console.log("i", i);
            e.rank = i + 1;
            payload.push(e);
        });

        let response = await updateCuisine(payload);

        if (response) {
            this.setState({ sucessMsg: "Updated Rank Successfully" });
        } else {
            this.setState({ errorMsg: "Error in updating the Rank" });
        }

    }

    handleDialogueDeleteOpen = (cuisineType) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedCuisine: cuisineType });

    }

    handleDialogueDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }

    handleEditModalOpen = (cuisineType) => {
        this.setState({ selectedCuisine: { ...cuisineType } });
        this.setState({ cuisineName: cuisineType.name });
        // this.setState({ cuisineImage: (cuisineType?.image?.path) ? cuisineType.image.path : null });
        if (cuisineType?.image?.path) {
            this.setState({ cuisineFile: { secure_url: cuisineType?.image?.path } });

        } else {
            this.setState({ cuisineFile: null });
        }
        this.setState({ modalEditOpen: true });

    }

    handleEditModalClose = () => {
        this.setState({ modalEditOpen: false });
        this.setState({ cuisineName: "" });
        this.setState({ cuisineImage: null, cuisineFile: null, inputErrorMessage: null });
    }

    handleEditSubmit = async (event) => {

        event.preventDefault();

        if (!this.state.cuisineName) {
            this.setState({ errorMsg: "Cuisine Name Cannot Be Empty" });
            this.setState({ snackOpen: true });
        } else {
            // let res = await fetch(this.state.cloudinaryData.cloudinaryUploadUrl, {
            //     method: "POST",
            //     body: this.state.cloudinaryFileData,
            // });

            // let file = await res.json();
            for (let i = 0; i < this.state.cuisineList.length; i++) {
                if (this.state.selectedCuisine._id !== this.state.cuisineList[i]._id && this.state.cuisineName.trim().toLowerCase() === this.state.cuisineList[i].name.trim().toLowerCase()) {
                    this.setState({ inputErrorMessage: "Cusine With the Same Name Already Exists" });
                    return;
                }
            }
            let payload = {
                _id: this.state.selectedCuisine._id,
                name: this.state.cuisineName
            };

            if (this.state.cuisineFile && !this.state.cuisineFile.error) {
                payload["image"] = {
                    title: "Cuisine",
                    path: this.state.cuisineFile.secure_url,
                    public_id: this.state.cuisineFile.public_id
                }
            } else {
                payload.image = null;
            }

            let response = await updateCuisine(payload);

            if (response) {
                this.setState({ sucessMsg: "Updated Cuisine Successfully" });
                this.setState({ modalEditOpen: false });
                this.getCuisineList();
            } else {
                this.setState({ errorMsg: "Error in updating the Cuisine" });
            }
        }

    }

    handleAddSubmit = async (event) => {
        event.preventDefault();

        this.setState({ isDisabled: true })
        if (!this.state.cuisineName) {
            this.setState({ errorMsg: "Cuisine Name Cannot Be Empty" });
            this.setState({ snackOpen: true });
            this.setState({ isDisabled: false });

        } else {
            // let res = await fetch(this.state.cloudinaryData.cloudinaryUploadUrl, {
            //     method: "POST",
            //     body: this.state.cloudinaryFileData,
            // });

            for (let i = 0; i < this.state.cuisineList.length; i++) {
                if (this.state.cuisineName.trim().toLowerCase() === this.state.cuisineList[i].name.trim().toLowerCase()) {
                    this.setState({ inputErrorMessage: "Cuisine With the Same Name Already Exists" });
                    return;
                }
            }
            // let file = await res.json();
            let payload = {
                name: this.state.cuisineName
            };

            if (this.state.cuisineFile && !this.state.cuisineFile.error) {
                payload["image"] = {
                    title: "Cuisine",
                    path: this.state.cuisineFile.secure_url,
                    public_id: this.state.cuisineFile.public_id
                }
            }

            let response = await addCuisine(payload);

            if (response) {
                // console.log("in it");
                this.setState({ sucessMsg: "Added Cuisine Successfully" });
                this.setState({ modalAddOpen: false, cuisineName: '', cuisineFile: null });
                this.getCuisineList();
            } else {
                this.setState({ errorMsg: "Error in Adding the Cuisine" });
            }
        }

    }

    handleDeleteCategory = async () => {
        // request began and thus showing the spinner
        let response = await deleteCuisine(this.state.selectedCuisine._id);
        if (response) {
            this.setState({ modalDeleteOpen: false });
            this.getCuisineList();
        }
    }

    handleSnackClose = () => {
        this.setState({ snackOpen: false });
        this.setState({ noDelMsg: null });
        this.setState({ errorMsg: null });
        this.setState({ sucessMsg: null });
    }

    showExcel = async () => {
        this.getCuisineList();
        this.setState({ showExcel: true });
    }

    render() {

        const { classes } = this.props;
        let muiAlert = null;
        let headers = [
            { label: 'Cuisine Name', key: 'name' }
        ];

        if (this.state.sucessMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                {this.state.sucessMsg}
            </MuiAlert>
        } else if (this.state.errorMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        } else if (this.state.noDelMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.noDelMsg}
            </MuiAlert>
        } else if (this.state.cuisineExists) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.categoryExists}
            </MuiAlert>
        }

        return (
            <Container>
                <Grid container justify="space-between">
                    <Grid item style={{ paddingRight: '460px' }}>
                        <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}
                            onClick={this.handleModalAddOpen}>
                            CUISINE
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" className={classes.button}
                            onClick={this.handleRank}>
                         SAVE SEQUENCE
                        </Button>
                    </Grid>
                    <Grid alignContent="right" >
                        {this.state.showExcel ?
                            (this.state.excelDownloadList.length > 0 ?
                                <CSVLink
                                    data={this.state.excelDownloadList}
                                    headers={headers}
                                    filename={"cuisines.csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                : null)
                            :
                            <Button variant="contained" color="primary"
                                onClick={() => this.showExcel()}>
                                DOWNLOAD LIST
                            </Button>
                        }
                    </Grid>
                </Grid>
                {this.state.cuisineList.length > 0 &&
                    <Container className={classes.containerTable}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableBody component={DroppableComponentNew(this.onDragEndNew)} >
                                    {this.state.cuisineList.map((cuisineType, index) => {
                                        return (
                                            <TableRow component={DraggableComponentNew(cuisineType._id, index)} className={classes.tableRow} hover key={cuisineType.name}>
                                                {/* <TableCell align="left">
                                                    <img style={{ "objectFit": "contain" }} variant="square" src={cuisineType?.image?.path} alt=" " className={classes.avatarGrey} />
                                                </TableCell> */}
                                                <TableCell align="left" style={{ width: '100px' }}>
                                                    {cuisineType?.image?.path &&
                                                        <img style={{ "objectFit": "contain" }} variant="square" src={cuisineType?.image?.path} alt=" " className={classes.avatarGrey} />
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                                                        {cuisineType.name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableBtn} style={{ width: '100px' }}>
                                                    <EditIcon className={classes.editBtn} onClick={() => this.handleEditModalOpen(cuisineType)} />
                                                </TableCell>
                                                <TableCell align="left" className={classes.tableBtn} style={{ width: '100px' }}>
                                                    <DeleteIcon className={classes.deleteBtn} onClick={() => this.handleDialogueDeleteOpen(cuisineType)} />
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                }
                <Dialog
                    className={classes.modal}
                    open={this.state.modalAddOpen}
                    onClose={this.handleModalAddClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={false}
                >
                    <DialogTitle className={classes.headerBar} id="form-dialog-title">
                        <div >Add Cuisine
                            <CloseIcon className={classes.headGridTwoButton} onClick={this.handleModalAddClose} />
                        </div>
                    </DialogTitle>
                    <form className={classes.form} onSubmit={this.handleAddSubmit}>
                        {/* Header of the FORM */}
                        {/* <Grid container style={{ 'paddingLeft': '24px' }} className={classes.header}>
                            <Grid item xs={11}>
                                <Typography variant="h5" gutterBottom>
                                    ADD CUISINE
                                </Typography>
                            </Grid>
                            <Grid item xs={1} >
                                <CloseIcon className={classes.headGridTwoButton} onClick={this.handleModalAddClose} />
                            </Grid>
                        </Grid> */}
                        {/* Content of the FORM */}

                        <Grid container className={classes.content}>
                            <TextField
                                name="cuisine"
                                label="Cuisine Name"
                                // placeholder="Speciality Name"
                                value={this.state.cuisineName}
                                fullWidth variant="outlined"
                                margin="normal"
                                inputProps={{
                                    maxLength: 30,
                                  }}
                                onChange={this.handleChange}
                                error={Boolean(this.state.inputErrorMessage)}
                                helperText={this.state.inputErrorMessage}
                            />

                            <Grid container style={{ margin: '20px 0px' }} alignItems="center" justify="space-around">
                                <Grid item xs={6} >
                                    {this.state.cuisineFile ?

                                        <Badge className={classes.closeBadge} badgeContent={<CancelIcon onClick={this.removeCuisineImage} />}>
                                            <div className={classes.img}>
                                                <img border="0" className={classes.img} src={this.state.cuisineFile.secure_url} alt="" />
                                            </div >
                                        </Badge>
                                        :
                                        <div >
                                            <Typography >
                                                No File Chosen
                                            </Typography>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6} >

                                    <Button variant="contained" htmlFor="myInput2" style={{ float: 'right' }} onClick={this.fileUploadAction} color="primary" className={classes.secondaryButton}>
                                        {this.state.cuisineFile ? 'Change Icon' : 'Add Icon'}
                                    </Button>
                                    <input
                                        ref={this.inputReference}
                                        id="myInput2"
                                        name="Cuisine"
                                        style={{ display: 'none' }}
                                        type={"file"}
                                        onChange={this.handleChange}
                                        accept="image/x-png,image/jpeg"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider></Divider>
                        {/* Button for  FORM */}
                        <Container className={classes.footer}>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    ///style={{ padding: "20px" }}
                                    margin="normal"
                                    color="primary"
                                    disabled={this.state.isDisabled}
                                    onClick={this.handleAddSubmit}>
                                    Confirm
                                </Button>
                            </div>
                        </Container>
                    </form>
                </Dialog >
                <Dialog
                    className={classes.modal}
                    open={this.state.modalEditOpen}
                    onClose={this.handleEditModalClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={false}
                >
                    <DialogTitle className={classes.headerBar} id="form-dialog-title">
                        <div >Edit Cuisine
                            <CloseIcon className={classes.headGridTwoButton} onClick={this.handleEditModalClose} />
                        </div>
                    </DialogTitle>
                    <form className={classes.form} onSubmit={this.handleEditSubmit}>
                        {/* Header of the FORM */}
                        {/* <Grid container style={{ 'paddingLeft': '24px' }} className={classes.header} spacing={3}>
                            <Grid item xs={11}>
                                <Typography variant="h5" gutterBottom>
                                    EDIT CUISINE
                                </Typography>
                            </Grid>
                            <Grid item xs={1} >
                                <CloseIcon className={classes.headGridTwoButton} onClick={this.handleEditModalClose} />
                            </Grid>
                        </Grid> */}
                        {/* Content of the FORM */}

                        <Grid container className={classes.content}>
                            <TextField
                                name="cuisine"
                                label="Edit Cuisine"
                                // placeholder="Cuisine Name"
                                value={this.state.cuisineName}
                                fullWidth variant="outlined"
                                margin="normal"
                                inputProps={{
                                    maxLength: 30,
                                  }}
                                onChange={this.handleChange}
                                error={Boolean(this.state.inputErrorMessage)}
                                helperText={this.state.inputErrorMessage}
                            />

                            <Grid container style={{ margin: '20px 0px' }} alignItems="center" justify="space-around">
                                <Grid item xs={6} >
                                    {this.state.cuisineFile ?
                                        <Badge className={classes.closeBadge} badgeContent={<CancelIcon onClick={this.removeCuisineImage} />}>
                                            <div className={classes.img}>
                                                <img border="0" className={classes.img} src={this.state.cuisineFile.secure_url} alt="" />
                                            </div >
                                        </Badge>
                                        :
                                        <div >
                                            <Typography >
                                                No File Chosen
                                            </Typography>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Button variant="contained" htmlFor="myInput2" style={{ float: 'right' }} onClick={this.fileUploadAction} color="primary" className={classes.secondaryButton}>
                                        {this.state.cuisineFile ? 'Change Icon' : 'Add Icon'}
                                    </Button>
                                    <input
                                        ref={this.inputReference}
                                        id="myInput2"
                                        name="Cuisine"
                                        style={{ display: 'none' }}
                                        type={"file"}
                                        onChange={this.handleChange}
                                        accept="image/x-png,image/jpeg"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider></Divider>

                        {/* Button for  FORM */}
                        <Container className={classes.footer}>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    ///style={{ padding: "20px" }}
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleEditSubmit}>
                                    Confirm
                                </Button>
                            </div>
                        </Container>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.modalDeleteOpen}
                    onClose={this.handleDialogueDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete the cuisine?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleDialogueDeleteClose} className={classes.secondaryButton}>
                            No
                        </Button>
                        <Button variant="contained" onClick={this.handleDeleteCategory} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>

            </Container >
        )

    }
}

const DraggableComponentNew = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

const DroppableComponentNew = (
    onDragEnd: ((result, provided) => void)) => (props) => {

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'1'} direction="vertical">
                    {(provided) => {
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        )
    }

export default (withStyles(styles)(Cuisine));