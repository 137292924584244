import React, { Component } from 'react'
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    changeHeaderName,
    getAllMasterOtp,
    setBusinessAppMasterOTP,
    deleteConsumerMasterOTP,
    deleteBusinessMasterOTP,
    setConsumerAppMasterOTP,
    getConsumerMasterOTP,
    setCofiguration
} from '../../actions/dashboardActions';





const styles = theme => ({
    boxStyle: {
        // width: "100%",
        // height: "170px",
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        backgroundColor: "#fff",
        flex: 1
    },
    content: {
        // display: 'flex',
        flexDirection: "row"
    },

    heading: {
        // fontSize: "14px",
        // fontWeight: 500
        marginTop: "110px",
        marginLeft: "40px"
    },

    heading2: {
        // fontSize: "14px",
        // fontWeight: 500
        marginTop: "25px",
        marginBottom: "7px",
        marginLeft: "60px"
    },
    inputBox: {
        width: "60%",
        height: "30px",
        // marginTop: "32px",
        marginLeft: "130px",
        marginRight: "7px",
        borderRadius: "4px",
        borderColor: "#f0fff"
    },
    buttonStyle: {
        width: "80px",
        height: "30px",
        marginTop: "43px",
        // marginLeft: "10px",
        // marginRight: "10px",
    },
    clearButtonStyle: {
        width: "80px",
        height: "25px",
        marginLeft: "40px",
        marginRight: "15px",
    },
    textStyle: {
        // margin: "2px",
        marginTop: "80px",
        marginLeft: "130px",
        // marginRight: "10px",
    }



});

class masterPin extends Component {
    constructor(props) {
        super(props);
        this.state = {
          masterMpin: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConsumerOTPClear = this.handleConsumerOTPClear.bind(this);
        this.handleBusinessMPINClear = this.handleBusinessMPINClear.bind(this);
        this.handleConsumerSubmit = this.handleConsumerSubmit.bind(this);
    }

    componentDidMount() {
        this.props.changeHeaderName("Master M-PIN");
        this.getMasterOTP();
    }
    getMasterOTP = () => {
        this.props.getAllMasterOtp();
    }
    handleSubmit = async (event) => {

        if (this.state.masterMpin.length === 6) {

            if (window.confirm(` Do You Want to Set Business App OTP: ${Number(this.state.masterMpin)} `)) {
                await this.props.setCofiguration({businessMasterMpin:this.state.masterMpin});
                await this.getMasterOTP();
                this.setState({ masterMpin: "" })
            } else {
                return ("cancle otp")
            }
        } else {
            return alert("OTP Should be 6 digits")
        }
    }

    handleConsumerSubmit = async (event) => {
        let setConsumerMasterOTP = {
            consumerOTP: Number(this.state.consumerOTP)
        };

        if (this.state.consumerOTP.length === 6) {
            if (window.confirm(` Do You Want to Set Consumer App OTP: ${Number(this.state.consumerOTP)} `)) {
                await this.props.setConsumerAppMasterOTP(setConsumerMasterOTP);
                await this.getMasterOTP();
                this.setState({ consumerOTP: "" })
            } else {
                return ("cancle otp")
            }

        } else {
            return alert("OTP Should be 6 digits")
        }
    };
    handleChange = (e) => {
        if(e.target.value.length > 6) {
            return ("Error");
        }
        this.setState({ [e.target.name]: e.target.value });
    }
    handleConsumerInputChange = (event) => {
        if (event.target.value.length > 6) {
            return ("Error");
        }
        this.setState({ [event.target.name]: event.target.value });
    }


    handleBusinessMPINClear = async () => {
        if (window.confirm("Do You Want To Clear Business App OTP?")) {
            await this.props.setCofiguration({businessMasterMpin:null});
            await this.getMasterOTP();
        } else {
            return ("cancle otp Clear")
        }
    }

    handleConsumerOTPClear = async () => {

        if (window.confirm("Do You Want To Clear Consumer App OTP?")) {
            await this.props.deleteConsumerMasterOTP();
            await this.getMasterOTP();
        } else {
            return ("cancle otp Clear")
        }


    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.boxStyle}>
                <div >
                    <div>
                        <h4 className={classes.heading} >Business App M-PIN </h4>
                    </div>
                    <div className="row"  >

                        <div className={classes.heading2} >

                            <h5 >Current Master M-PIN</h5>

                            <h7 style={{ marginLeft: "40px" }}
                            > {this.props && this.props.getMasterOTP && this.props.getMasterOTP.data && this.props.getMasterOTP.data.length ?
                                this.props.getMasterOTP.data[0].businessMasterMpin : "NOT SET"} </h7>

                            <Button
                                className={classes.clearButtonStyle}
                                type="submit"
                                variant="contained"
                                margin="normal"
                                onClick={this.handleBusinessMPINClear}
                            >Clear
                        </Button>

                        </div>

                        <div className="row" >
                            <div style={{ marginTop: "24px", width: "55%", marginLeft: "40px" }}>
                                <text className={classes.textStyle}>New Master M-PIN</text>
                                <input
                                    className={classes.inputBox}
                                    type="number"
                                    pattern="[0-9]"
                                    inputMode="numeric"
                                    name="masterMpin"
                                    label="New Master M-PIN"
                                    value={this.state.masterMpin}
                                    onChange={this.handleChange}


                                />
                            </div>
                            <div>
                                <Button
                                    className={classes.buttonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                >SET
                        </Button>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        getMasterOTP: state.dashboard.getMasterOTP

    }
};

export default connect(mapStateToProps, {
    changeHeaderName,
    deleteConsumerMasterOTP,
    deleteBusinessMasterOTP,
    getAllMasterOtp,
    setBusinessAppMasterOTP,
    setConsumerAppMasterOTP,
    getConsumerMasterOTP,
    setCofiguration
})(withStyles(styles)(masterPin));