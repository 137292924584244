import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

const headCells = [
    { id: 'consumerId', disablePadding: true, label: 'Consumer Id' },
    { id: 'consumer', label: 'Consumer Name' },
    { id: 'businessId', label: 'Business Id' },
    { id: 'business', label: 'Business Name' },
    { id: 'paymentId', disablePadding: true, label: 'Payment Id' },
    {id: 'xpId', label: 'Xirify Payment Id'},
    { id: 'serviceRequestId', label: 'Service Request Id' },
    { id: 'amount', label: 'Amount' },
    { id: 'referenceId', label: 'Reference Id' },
    { id: 'txStatus', label: 'Transaction Status' },
    { id: 'paymentMode', label: 'Payment Mode' },
    { id: 'txTime', label: 'Transaction Time' },
    { id: 'txMsg', label: 'Transaction Message' }
];

function EnhancedTableHead(props) {
    const { classes} = props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        // sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        > */}
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
   
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Online Payment Transactions ({props.total})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"transactions.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchExportToexcel}
                    >
                        Export
                    </Button>
                }
            </Typography>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };
    let headers = [
        { key: 'cId', label: 'Consumer Id' },
        { key: 'firstName', label: 'Consumer First Name' },
        { key: 'lastName', label: 'Consumer Last Name' },
        { key: 'bussId', label: 'Business Id' },
        { key: 'businessName', label: 'Business Name' },
        { key: 'paymentId', label: 'Payment Id' },
        {key: 'xpId', label: 'Xirify Payment Id'},
        { key: 'serviceRequestId', label: 'Service Request Id' },
        { key: 'amount', label: 'Amount' },
        { key: 'referenceId', label: 'Reference Id' },
        { key: 'txStatus', label: 'Transaction Status' },
        { key: 'paymentMode', label: 'Payment Mode' },
        { key: 'txTime', label: 'Transaction Time' },       
        { key: 'txMsg', label: 'Transaction Message' }
    ];
    let data = [];
    props.data.exportToExcel && props.data.exportToExcel.forEach(element => {
        let item = element;
        item.txTime = (item.txTime &&  item.txTime !== "Invalid date" && moment( item.txTime)) ? moment( item.txTime).format('DD MMM YYYY hh:mm A') : "NA";;
        item.cId = (item.consumerId && item.consumerId.consumerId) ? item.consumerId.consumerId : "NA";
        item.firstName = (item.consumerId && item.consumerId.firstName) ? item.consumerId.firstName : "NA";
        item.lastName = (item.consumerId && item.consumerId.lastName) ? item.consumerId.lastName : "NA";
        item.bussId = (item.businessId && item.businessId.businessId) ? item.businessId.businessId : "NA";
        item.businessName = (item.businessId && item.businessId.businessName) ? item.businessId.businessName : "NA";
        item.paymentId = item.paymentId  ? item.paymentId : "NA";
        item.serviceRequestId = item.serviceRequestId  ? item.serviceRequestId : "NA";
        item.amount = item.amount  ? item.amount : "NA";
        item.referenceId = item.referenceId  ? item.referenceId : "NA";
        item.txStatus = item.txStatus  ? item.txStatus : "NA";
        item.paymentMode = item.paymentMode  ? item.paymentMode : "NA";
        item.txMsg = item.txMsg  ? item.txMsg : "NA";
        item.xpId = element.xpId ? element.xpId : "NA";       
        data.push(item)
    });
    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.total - props.page * props.rowsPerPage);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    total={props.data.total}
                    headers={headers}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchExportToexcel={props.fetchExportToexcel}

                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.data.total}
                        />
                        <TableBody>
                            {
                                // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                                props.data && props.data.data.map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.consumerId  ? row.consumerId.consumerId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.consumerId && (row.consumerId.firstName || row.consumerId.lastName) ? row.consumerId.firstName + " " + row.consumerId.lastName : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{ row.businessId  ? row.businessId.businessId:"NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.businessId ? row.businessId.businessName : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.paymentId ? row.paymentId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.xpId ? row.xpId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.serviceRequestId ? row.serviceRequestId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.amount ? row.amount : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.referenceId ? row.referenceId : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.txStatus ? row.txStatus : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.paymentMode ? row.paymentMode : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.txTime ? moment(row.txTime).format('DD MMM YYYY hh:mm A') : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.txMsg ? row.txMsg : "NA"}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
