import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { lightBlue } from '@material-ui/core/colors';
import { settlementById } from '../../../actions/servicePartnerDeliveriesAction';
import moment from "moment";
// import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
	Checkbox: {
		fontSize: 20
	},
	grid: {
		width: '100%',
		//display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "15px",
		paddingRight: "15px",
		margin: '0px 0px',

	},
	deleteBtn: {
		cursor: 'pointer',
		color: 'grey',
		//marginTop: "7px"
	},
	header: {
		height: '20%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	content: {
		height: '73%',
		margin: '10px 0px',
		overflow: 'auto',
	},
	footer: {
		height: '10%',
		margin: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		padding: '20px',
		backgroundColor: theme.palette.background.paper,

	},
	gridElement: {
		margin: '10px 5px'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	icon: {
		color: 'white',
	},
	gridList: {
		width: '100%',
		height: '100%'
	},
	mainContainer: {
		width: '40%',
		height: '50%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',

	},
	tableCellSmall: {
		fontSize: "12px",
		padding: "6px 0px 6px 16px"
	},
	head: {
		fontWeight: 700,
		fontSize: "12px",
	},
	input: {
		height: 30,
		padding: '0px'
	},
	button: {
		height: 30
	},
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[50]),
		backgroundColor: lightBlue[50],
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	select: {
		height: '38px'
	},
	listItemText: {
		'margin': '0px'
	},
	buttonGroup: {
		"height": "44px",
		"marginTop": "9px",
		"width": "0px"
	},
	buttonGroupTextFeild: {
		"height": "40px"
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class SettleAmountModal extends Component {
	constructor() {
		super();
		this.state = {
			confirmCheckBox: false,
			date: "",
			notes: "",
			dateError : ""
		};

	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if(this.state.dateError){
			this.setState({ dateError : "" });
		}
	}


	clearData = () => {
		this.setState({
			confirmCheckBox: false,
			date: "",
			notes: ""
		})

	}
	save = async () => {
		let flag = await this.validate();
		if (parseInt(flag)){
		let payload = {
			settlementIds: this.props.data.map(dataObj=>dataObj.settlementId),
			dueStatus: true,
			settlementNote: this.state.notes,
			settlementDate: this.state.date
		}
		await this.props.settlementById(payload);
		this.saveAndClose();
	}
	}
	saveAndClose = () => {
		this.clearData();
		this.props.saveAndClose();
	}
	close = () => {
		this.clearData();
		this.props.onClose();
	}
	handleCheck = (event) => {
		this.setState({ confirmCheckBox: event.target.checked })
	}
	validate =async ()=>{
		let dateError = this.state.dateError;
		if(this.state.date && moment(this.state.date).isAfter(moment())){
		dateError="You can't select date from future";
		await this.setState({dateError});
		return 0;
		}
		return 1 ;
	}
	render() {
		const { classes } = this.props;
		return (
			<Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition>
				<Container className={classes.mainContainer}>
					<Grid container className={classes.header} spacing={1} >
						<Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								Settle Dues
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
							</WhiteTextTypography>
						</Grid>
					</Grid>
					<Container className={classes.modalContainer}>
						<Grid container spacing={1} >
							<Grid item xs={6}>
								<Typography variant="subtitle2" gutterBottom>
									Confirm *
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography variant="subtitle2" gutterBottom>
									Settlement Date *
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<FormControl component="fieldset">
									<FormControlLabel
										//={this.state.allowLooseQuantity}
										control={<Checkbox color="primary" onChange={this.handleCheck} />}
										label="Confirm the dues are settled"
										className={classes.Checkbox}
										labelPlacement="end"
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									margin="dense"
									name="date"
									type="date"
									variant="outlined"
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									error={this.state.dateError}
									helperText={this.state.dateError}
									value={this.state.date}
									onChange={this.handleChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="subtitle2" gutterBottom>
									Notes *
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									margin="dense"
									name="notes"
									variant="outlined"
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
									// error={this.state.error && this.state.error.date}
									// helperText={(this.state.error && this.state.error.date) ? this.state.error.date : null}
									value={this.state.notes}
									onChange={this.handleChange}
								/>
							</Grid>
							<Grid item xs={4}></Grid>
							<Grid item xs={4}>
								<Button
									fullWidth
									style={{ margin: "5px" }}
									variant="contained"
									onClick={this.close}
								>
									Cancel
								</Button>
							</Grid>
							<Grid item xs={4}>
								<Button
									fullWidth
									disabled={(this.state.confirmCheckBox === true && this.state.date && this.state.notes) ? false : true}
									style={{ margin: "5px" }}
									variant="contained"
									color="primary"
									onClick={this.save}
								>
									Save
								</Button>
							</Grid>
						</Grid>
					</Container>
				</Container>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		business: state.business,
		user: state.auth.user,
		admin: state.admin,
		categories: state.categories,
		env: state.auth.env
	};
};
export default connect(mapStateToProps, {
	settlementById
})(withStyles(styles)(SettleAmountModal));