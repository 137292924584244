import {
  GET_DELIVERIES,
  SETTLEMENT_CRON_RUN,
  COUPON_SETTLEMENT_CRON_RUN,
  GET_SETTLEMENT_RECORDS,
  SET_SETTLEMENT,
  SET_SETTLEMENT_NOTES,
  GET_SETTLEMENT_NOTES,
  REQUEST_DELIVERYLOADER,
  REQUEST_DELIVERYLOADER_FALSE,
  GET_DELIVERIES_EXPORT,
  GET_SERVICEDELIVERY_PARTNER
} from '../actions/types'

const INITIAL_STATE = {
  deliveriesList: {},
  settlementMsg: "",
  couponSettlementMsg: "",
  settlementRecords: {},
  addSettlementMsg: "",
  setSettlementNotesMsg: "",
  settlementNotesById:[],
  exportToExcel : [],
  servicePartnerDelivery : [],
  isFetching : false,
  page: 0,
  skip: 0,
  limit: 10,
};

export default function deliveriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REQUEST_DELIVERYLOADER:
      return { ...state, isFetching: action.payload };
      case REQUEST_DELIVERYLOADER_FALSE:
        return { ...state, isFetching: action.payload };
    case GET_DELIVERIES:
      return {
        ...state,
        deliveriesList: action.payload,
      };
    case GET_DELIVERIES_EXPORT:
      return {
        ...state,
        exportToExcel: action.payload,
      };
    case SETTLEMENT_CRON_RUN:
      return {
        ...state,
        settlementMsg: action.payload,
      };
    case COUPON_SETTLEMENT_CRON_RUN:
      return {
        ...state,
        couponSettlementMsg: action.payload,
      };
    case GET_SETTLEMENT_RECORDS:
      return {
        ...state,
        settlementRecords: action.payload,
      };
    case SET_SETTLEMENT:
      return {
        ...state,
        addSettlementMsg: action.payload,
      };
    case SET_SETTLEMENT_NOTES:
      return {
        ...state,
        setSettlementNotesMsg: action.payload,
      };
      case GET_SETTLEMENT_NOTES:
      return {
        ...state,
        settlementNotesById: action.payload,
      };
      case GET_SERVICEDELIVERY_PARTNER:
        return {
          ...state,
          servicePartnerDelivery: action.payload,
        };
    default:
      return state;
  }
}
