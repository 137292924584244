import {
    PATCH_REPORTED_SUCCESS_MESSAGE,
    PATCH_REPORTED_ERROR_MESSAGE,
    INVITES_STATE_CHG_SUCCESS,
    INVITES_STATE_CHG_ERR,
    SET_INVITES_SUCCESS_MESSAGE,
    SET_INVITES_ERROR_MESSAGE,
    SET_INVITES,
    REQUEST_INVITES
  } from "../actions/types";
  
  const INITIAL_STATE = {
    page: 0,
    skip: 0,
    limit: 10,
    sucessMsg: null,
    errorMsg: null,
    showRpd: false,
    invitesStateChgSuccess: null,
    invitesStateChgErr: null,
    invites: {},
    isFetching: false
  };
  
  export default function inviteReducer(state = INITIAL_STATE, action) {
    
    switch (action.type) {
      case REQUEST_INVITES:
        return { ...state, isFetching: action.payload };
    
      case PATCH_REPORTED_SUCCESS_MESSAGE:
        return { ...state, sucessMsg: action.payload };
      case PATCH_REPORTED_ERROR_MESSAGE:
        return { ...state, errorMsg: action.payload };
      case INVITES_STATE_CHG_SUCCESS:
        return { ...state, invitesStateChgSuccess: action.payload };
      case INVITES_STATE_CHG_ERR:
        return { ...state, invitesStateChgErr: action.payload };
      case SET_INVITES_SUCCESS_MESSAGE:
        return {
          ...state,
          sucessMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_INVITES_ERROR_MESSAGE:
        return {
          ...state,
          errorMsg: action.payload.msg,
          ops: action.payload.ops,
          // isFetching: false
        };
      case SET_INVITES:
        return {
          ...state,
          invites: action.payload,
          isFetching: false
        };
     
      default:
        return state;
    }
  }