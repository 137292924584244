import axios from "axios";
import request from 'superagent';
import {
    SET_CATEGORIES,
    SET_CATERGORY_HISTORY,
    REMOVE_CATEGORY_HISTORY,
    SELECT_CATEGORY,
    SET_CATEGORY_SUCCESS_MESSAGE,
    SET_CATEGORY_ERROR_MESSAGE,
    REQUEST_CATEGORIES,
    SET_ENV,
    SET_CATEGORIES_BY_TYPE,
    SET_FOOD_TYPE_SUCCESS_MESSAGE,
    SET_FOOD_TYPE_ERROR_MESSAGE,
    GET_CATEGORIES_LIST,
    SET_CATEGORIES_LIST
} from "./types";

// Add the Category document inside categoryHistory
export const setCatergoryHistory = res => {
    return {
        type: SET_CATERGORY_HISTORY,
        payload: res
    };
};

export const removeCategoryHistory = res => {
    return {
        type: REMOVE_CATEGORY_HISTORY,
        payload: res
    };
};

export const selectCategory = res => {
    return {
        type: SELECT_CATEGORY,
        payload: res
    };
};

export const requestCategories = () => {
    return {
        type: REQUEST_CATEGORIES,
        payload: true
    };
};

//Get all the category types available in the MarketPlace.
export const getAllCategoryType = () => async dispatch => {
    const response = await axios.get("/api/categories/")
    dispatch({ type: SET_CATEGORIES, payload: response.data });
    const envData = await axios.get(`/api/xirify/env`);
    dispatch({ type: SET_ENV, payload: envData.data });
};

/* 
 * Get all the category types available in the MarketPlace for categorytype, category and subcategory
 * Different API is fired for type = categorytype and type = category & subcategory
 */
export const getCategoryTree = (category) => async dispatch => {
    if (category.type === 'categorytype') {
        const response = await axios.get("/api/categories/" + category._id + "/children")
        dispatch({ type: SET_CATEGORIES, payload: response.data });
    } else if (category.type === 'category' || category.type === 'subcategory') {
        const response = await axios.get("/api/categories/" + category._id + "/childrenlist")
        dispatch({ type: SET_CATEGORIES, payload: response.data });
    }
};

export const clearMsg = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_CATEGORY_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_CATEGORY_ERROR_MESSAGE, payload: data });
};

// Add a categorytype , category, subcategory or service to the tree
export const addToCategory = (category) => async dispatch => {
    let data = {};
    try {
        // Upload the categorytype , category, subcategory or service ICON
        /* const formData = new FormData();
        formData.append('file', category.icon.whiteImgIcon);
        formData.append('file', category.icon.greyImgIcon);
        await axios.post("/api/categories/uploadicon", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }); */

        let cloudIconPaths = {
            white: '',
            grey: '',
            coloured:''
        }
        const keys = Object.keys(category.icon);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            try {
                let upload = await request.post(category.env.cloudinaryUploadUrl)
                    .field('upload_preset', category.env.cloudinaryUploadPreset)
                    .field('file', category.icon[element]);

                if (element === 'whiteImgIcon') {
                    cloudIconPaths.white = upload.body.secure_url
                } else if (element === 'greyImgIcon'){
                    cloudIconPaths.grey = upload.body.secure_url
                } else {
                    cloudIconPaths.coloured = upload.body.secure_url
                }
            } catch (e) {
                console.log("Error while uploading category icon for - ", category.name);
                throw e;
            }
        }

        // Add the categorytype , category, subcategory or service category 
        await axios.post("/api/categories/", {
            "name": category.name,
            "type": category.type,
            "parentId": category.parentId,
            "icon": cloudIconPaths
        });

        data.msg = "Created " + category.name + " successfully";
        data.ops = "ADD";
        dispatch({ type: SET_CATEGORY_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log(err);
        data.msg = "Failed to create " + category.name;
        data.ops = "ADD";
        dispatch({ type: SET_CATEGORY_ERROR_MESSAGE, payload: "Failed to create " + category.name });
    }
};


// Edit Category
export const editCategory = (category) => async dispatch => {
    let data = {};

    try {
        /* const formData = new FormData();

        if (category.icon.hasOwnProperty("greyImgIcon")) {
            formData.append('file', category.icon.greyImgIcon);
        }

        if (category.icon.hasOwnProperty("whiteImgIcon")) {
            formData.append('file', category.icon.whiteImgIcon);
        }

        await axios.post("/api/categories/uploadicon", formData, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }); */
        // console.log("edit to category uploadicon",response);

        let cloudIconPaths = {
            white: category.icon.white,
            grey: category.icon.grey,
            coloured : category.icon.coloured ? category.icon.coloured : null
        }
        const keys = Object.keys(category.icon);
        for (let index = 0; index < keys.length; index++) {
            const element = keys[index];
            if (typeof (category.icon[element]) !== 'string') {
                let upload;
                if(category.icon[element]){
                upload= await request.post(category.env.cloudinaryUploadUrl)
                    .field('upload_preset', category.env.cloudinaryUploadPreset)
                    .field('file', category.icon[element]);
                }
                if (element === 'whiteImgIcon') {
                    cloudIconPaths.white = upload.body.secure_url
                } else if (element === 'greyImgIcon'){
                    cloudIconPaths.grey = upload.body.secure_url
                } else {
                    cloudIconPaths.coloured = (upload && upload.body && upload.body.secure_url) ? upload.body.secure_url : null 
                }
            }
        }

        await axios.patch("/api/categories/" + category.id, {
            "name": category.name,
            "icon": cloudIconPaths,
            "changedCategoryType": category.changedCategoryType
        });

        // console.log("editCategory patch", response);

        data.msg = "Edited " + category.name + " successfully";
        data.ops = "EDIT";
        dispatch({ type: SET_CATEGORY_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log(err);
        data.msg = "Failed to edit " + category.name;
        data.ops = "EDIT";
        dispatch({ type: SET_CATEGORY_ERROR_MESSAGE, payload: data });
    }

};

// /:id/foodtype
export const editFoodType = (category) => async dispatch => {
    let data = {}

    try {
        await axios.patch("/api/categories/foodtype/" + category._id, {
            "foodType": category.foodType
        });
        data.msg = "Edited " + category.name + " successfully";
        data.ops = "EDIT";
        dispatch({ type: SET_FOOD_TYPE_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log(err);
        data.msg = "Failed to edit " + category.name;
        data.ops = "EDIT";
        dispatch({ type: SET_FOOD_TYPE_ERROR_MESSAGE, payload: data });
    }
};
// Delete Category
export const deleteCategory = (category) => async dispatch => {
    let data = {};

    try {
        await axios.delete("/api/categories/" + category._id);
        // console.log("deleteCategory", response);

        data.msg = "Deleted " + category.name + " successfully";
        data.ops = "DELETE";
        dispatch({ type: SET_CATEGORY_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log(err);
        data.msg = "Failed to delete " + category.name;
        data.ops = "DELETE";
        dispatch({ type: SET_CATEGORY_ERROR_MESSAGE, payload: "Failed to delete " + category.name });
    }
};

export const getCategoriesByType = (type) => async dispatch => {
    try {
        const response = await axios.get(`/api/categories/type/${type}`);
        dispatch({ type: SET_CATEGORIES_BY_TYPE, payload: response.data });
    } catch (e) {
        console.log("error while fetching categories : ", e);
        dispatch({ type: SET_CATEGORY_ERROR_MESSAGE, payload: "Failed to get categories by type." });
    }
};

export const getCategoriesList = () => async dispatch => {
    try {
        const response = await axios.get(`/api/categories/getAll`);
        dispatch({ type: GET_CATEGORIES_LIST, payload: response.data });
    } catch (e) {
        console.log("error while fetching categories : ", e);
        dispatch({ type: GET_CATEGORIES_LIST, payload: null });
    }
};

export const getAllCategories = () => async dispatch => {
    const response = await axios.get("/api/categories/")
    dispatch({ type: SET_CATEGORIES_LIST, payload: response.data });
};

export const changeRank = (payload) => async dispatch => {
    try {
        if(payload.parentCategory && payload.parentCategory._id){
        await axios.patch("/api/categories/changeRank/" + payload.oldRank +"/" + payload.newRank+"/" + payload.parentCategory._id);
        }
    } catch (err) {
        console.log(err);
        // data.msg = "Failed to edit " + category.name;
        // data.ops = "EDIT";
        // dispatch({ type: SET_FOOD_TYPE_ERROR_MESSAGE, payload: data });
    }
};