export const months =  [
    { index: 0, month: 1 }, { index: 1, month: 2 }, { index: 2, month: 3 }, { index: 3, month: 4 }, { index: 4, month: 5 }, 
    { index: 5, month: 6 }, { index: 6, month: 7 }, { index: 7, month: 8 }, { index: 8, month: 9 }, { index: 9, month: 10 }, 
    { index: 10, month: 11 }, { index: 11, month: 12 },
];

export const weeks = [
    { index: 0, week: 1 }, { index: 1, week: 2 }, { index: 2, week: 3 }, { index: 3, week: 4 }
];

export const days = [
    { index: 0, day: 1 }, { index: 1, day: 2 }, { index: 2, day: 3 }, { index: 3, day: 4 }, { index: 4, day: 5 }, 
    { index: 5, day: 6 }, { index: 6, day: 7 }, { index: 7, day: 8 }, { index: 8, day: 9 }, { index: 9, day: 10 }, 
    { index: 10, day: 11 }, { index: 11, day: 12 }, { index: 12, day: 13 }, { index: 13, day: 14 }, { index: 14, day: 15 }, 
    { index: 15, day: 16 }, { index: 16, day: 17 }, { index: 17, day: 18 }, { index: 18, day: 19 }, { index: 19, day: 20 },
    { index: 20, day: 21 }, { index: 21, day: 22 }, { index: 22, day: 23 }, { index: 23, day: 24 },
    { index: 24, day: 25 }, { index: 25, day: 26 }, { index: 26, day: 27 }, { index: 27, day: 28 },
    { index: 28, day: 29 }, { index: 29, day: 30 }, { index: 30, day: 31 },
];