import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  imageOutermost: {
    width: '100%',
    height: '100%',
    padding: '0px 0px',

  },
  gridElement: {
    margin: '10px 5px'
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  icon: {
    color: 'white',
  },
  gridList: {
    width: '100%',
    height:'100%'
  },


});

class uploadReferralPhoto extends Component {
  constructor() {
    super();
    this.state = {
      modalDeleteOpen: false,
      selectedPhoto: null
    };
    this.inputReference = React.createRef();
  }

  fileUploadAction = () => this.inputReference.current.click();
  onFileChange = async event => {
    let files = event.target.files;
    // console.log("files", files);
    this.props.updateReferralPhotos(files, this.props.photoType);
  };

  handleDeleteOpen = (photo) => {
    this.setState({ modalDeleteOpen: true });
    this.setState({ selectedPhoto: photo });
  }

  handleDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  }

  render() {
    const { classes } = this.props;
   
    return (
      <Grid>
        <Grid className={classes.imageOutermost}>
          <Grid>
            <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" component="span" >
              {this.props.uploadButtonName}
            </Button>
            <input
              ref={this.inputReference}
              id="myInput2"
              name="Referral"
              style={{ display: 'none' }}
              type={"file"}
              onChange={this.onFileChange}
              accept="image/x-png,image/jpeg"
            />
          </Grid>
          {/* <Grid>
            {imageListContent}
          </Grid> */}
        </Grid>
        {/* <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
          <DialogTitle>Delete Photo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to delete this photo?
            </DialogContentText>
          </DialogContent>
           <DialogActions>
            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
            <Button onClick={() => this.deleteReferralPhoto()} color="primary" autoFocus> Yes </Button>
          </DialogActions> 
        </Dialog> */}
      </Grid>
    );
  }
}

// uploadReferralPhoto.propTypes = {
//   images: PropTypes.array.isRequired
// };

export default (withStyles(styles)(uploadReferralPhoto));
