import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkAddonToItem from "./LinkAddonModal";
import { getAddonsToLinkItem, getItemsToLinkAddon, requestAddonRecords, requestAddonRecordsFalse, linkAddonsToItems, clearMsg, getAllItemsLinkedWithAddons, unlinkAddonsFromItem } from '../../../../actions/addonsAction';
import { linkAddonGroupToItem, clearAddonGroupMsg } from '../../../../actions/addonGroupAction';
import LinkingTable from './LinkingTable';
import LinkedItemsModal from "./LinkedItemsModal";
import LinkingUpdate from "./LinkingUpdate";
import LinkAddonGroup from "./LinkAddonGroup";
import LinkedItemToAddonGroup from './LinkedItemToAddonGroup'
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';

const styles = (theme) => ({

  container: {
    width: "100%",
    float: "left",
    padding: "5px 45px 0px 0px",
  },
  table: {
    marginTop: "10px",
    minWidth: 706,
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 800,
    marginleft: "4px",
  },

});

const foodType = ['Veg','NonVeg','Egg','NA','Blank']
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class ManageAddonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      rowsPerPage: 10,
      rows: [],
      modalOpen: false,
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      handleLinkAddonToItem: false,
      addonIdToLink: "",
      itemIdToLink: "",
      groupIdToLink: "",
      finalAddonRecords: [],
      finalItemRecords: [],
      selectedAddonToLink: [],
      selecteItemToLink: [],
      showSelectedAddon: false,
      showUnSelectedAddon: false,
      availability: "",
      foodType: [],
      isEnabled: "",
      ItemId: "",
      expand: null,
      handleLinkedItemsModalOpen: false,
      addonsCheckedToUnlink: [],
      linkedAddonItemId: "",
      handleUnlinkAddonModalOpen: false,
      editLinkedAddonopen: false,
      itemArray: [],
      addonUnCheckedToUpdate: [],
      addonArray: [],
      selectedAddonCount: 0,
      unSelectedAddonCount: 0,
      isSelectAllAddonChecked: false,
      selectedItemCount: 0,
      onLinkingAddonSubmitFlag: false,
      onLinkingItemSubmitFlag: false,
      fetchedItemRecords: [],
      isAddonGroupOpen: false,
      groupCheckedToUnlink : [],
      linkedServiceToGroupOpen : false , 
      linkedServiceItems : null ,
      addonGroupId : null

    };
  }

  componentDidMount = async () => {
    await this.fetchItemsLinkedWithAddons()
  };

  fetchItemsLinkedWithAddons = async () => {
    await this.props.requestAddonRecords()
    let payload = {
      id: this.props.businessDetails._id,
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      itemId: this.state.ItemId.trim(),
      foodType: this.state.foodType,
      isEnabled: this.state.isEnabled,
      availability: this.state.availability,
    }
    await this.props.getAllItemsLinkedWithAddons(payload);
    await this.props.requestAddonRecordsFalse()
  };

  resetFilter = async () => {
    this.setState(
      {
        handleLinkAddonToItem: false,
        modalOpen: false,
        snackOpen: false,
        sucessMsg: null,
        errorMsg: null,
        addonIdToLink: "",
        itemIdToLink: "",
        groupIdToLink: "",
        finalAddonRecords: [],
        finalItemRecords: [],
        selectedAddonToLink: [],
        selecteItemToLink: [],
        showSelectedAddon: false,
        showUnSelectedAddon: false,
        availability: "",
        foodType: [],
        isEnabled: "",
        ItemId: "",
        page: 0,
        expand: null,
        handleLinkedItemsModalOpen: false,
        addonsCheckedToUnlink: [],
        linkedAddonItemId: "",
        handleUnlinkAddonModalOpen: false,
        editLinkedAddonopen: false,
        itemArray: [],
        addonUnCheckedToUpdate: [],
        addonArray: [],
        selectedAddonCount: 0,
        unSelectedAddonCount: 0,
        isSelectAllAddonChecked: false,
        onLinkingAddonSubmitFlag: false,
        onLinkingItemSubmitFlag: false,
        fetchedItemRecords: [],
        isAddonGroupOpen: false,
        groupCheckedToUnlink : [],
        linkedServiceToGroupOpen : false , 
        linkedServiceItems : null ,
        addonGroupId : null
      },
      () => {
        this.fetchItemsLinkedWithAddons();
      }
    );
  };

  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };

  setPage = (page) => {
    this.setState({ page }, () => this.fetchItemsLinkedWithAddons());
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchItemsLinkedWithAddons());
  };



  componentDidUpdate(prevProps, prevState) {

    if (this.props.addons.sucessMsg) {
      this.setState({ sucessMsg: this.props.addons.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    } else if (this.props.addons.errorMsg) {
      this.setState({ errorMsg: this.props.addons.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    } else if (this.props.addonGroup.sucessMsg) {
      this.setState({ sucessMsg: this.props.addonGroup.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearAddonGroupMsg();
    } else if (this.props.addonGroup.errorMsg) {
      this.setState({ errorMsg: this.props.addonGroup.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearAddonGroupMsg();
    }

  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };



  handleLinkAddonOpen = () => {
    this.setState({ handleLinkAddonToItem: true });
  }
  handleLinkAddonClose = async () => {
    this.setState({ handleLinkAddonToItem: false  });
    this.resetFilter()
    await this.props.getAddonsToLinkItem();
    await this.props.getItemsToLinkAddon()


  }

  onAddonToLinkSearch = async () => {
    this.setState({ selectedAddonCount: 0, unSelectedAddonCount: 0, isSelectAllAddonChecked: false, selectedAddonToLink: [], showSelectedAddon: false, showUnselectedAddon: false })
    if (this.state.addonIdToLink) {
      let payload = {
        businessId: this.props.businessDetails._id,
        addonId: this.state.addonIdToLink.trim(),
        isSearched: true
      }
      await this.props.getAddonsToLinkItem(payload)
      await this.setState({ finalAddonRecords: this.props.addons.addonRecordstoLink.data })

    }
  }
  onItemToLinkSearch = async () => {
    if (this.state.itemIdToLink && !this.state.groupIdToLink) {
      let payload = {
        businessId: this.props.businessDetails._id,
        itemId: this.state.itemIdToLink.trim(),
        isSearched: true
      }
      await this.props.getItemsToLinkAddon(payload)
      await this.setState({ finalItemRecords: this.props.addons.itemRecordstoLink.data, fetchedItemRecords: this.props.addons.itemRecordstoLink.data })
    }
    if (this.state.groupIdToLink && !this.state.itemIdToLink) {
      let payload = {
        businessId: this.props.businessDetails._id,
        groupId: this.state.groupIdToLink.trim(),
        isSearched: true
      }
      await this.props.getItemsToLinkAddon(payload);
      await this.setState({ finalItemRecords: this.props.addons.itemRecordstoLink.data, fetchedItemRecords: this.props.addons.itemRecordstoLink.data })
    }
  }

  handleLinkAddonsToItemChange = async (event) => {
    this.setState({ [event.target.name]: event.target.value, onLinkingAddonSubmitFlag: false, onLinkingItemSubmitFlag: false });

  }
  resetSearchedAddon = async () => {
    this.setState({ addonIdToLink: "", finalAddonRecords: [], selectedAddonToLink: [], showSelectedAddon: false, showUnselectedAddon: false, onLinkingAddonSubmitFlag: false })
    await this.props.getAddonsToLinkItem()
  }
  resetSearchedItem = async () => {
    this.setState({ itemIdToLink: "", groupIdToLink: "", finalItemRecords: [], onLinkingItemSubmitFlag: false })
    await this.props.getItemsToLinkAddon()
  };

  setSelectedAddonToLink = (IdArr) => {
    this.setState({ selectedAddonToLink: IdArr })
  };


  handleShowSelectedAddon = (value) => {
    this.setState({ showSelectedAddon: value });
    if (value === true) {
      let finalAddonRecords = this.state.finalAddonRecords;
      let selectedAddonToLink = this.state.selectedAddonToLink;
      var filteredShowSelectedAddon = finalAddonRecords.filter((v) => selectedAddonToLink.includes(v._id));
      this.setState({ finalAddonRecords: filteredShowSelectedAddon })
    } else {
      this.setState({ finalAddonRecords: this.props.addons.addonRecordstoLink.data })
    }

  }
  handleShowUnselectedAddon = (value) => {
    this.setState({ showUnSelectedAddon: value });
    if (value === true) {
      let finalAddonRecords = this.state.finalAddonRecords;
      let selectedAddonToLink = this.state.selectedAddonToLink;
      var filteredShowSelectedAddon = finalAddonRecords.filter((v) => !selectedAddonToLink.includes(v._id));
      this.setState({ finalAddonRecords: filteredShowSelectedAddon })
    } else {
      this.setState({ finalAddonRecords: this.props.addons.addonRecordstoLink.data })
    }

  }

  onLinkAddonToItemSubmit = async () => {
    let selectedAddonToLink = this.state.selectedAddonToLink
    let selecteItemToLink = this.state.selecteItemToLink
    let itemIds = new Set(selecteItemToLink.map(obj => obj._id))
    this.setState({ onLinkingAddonSubmitFlag: (this.state.addonIdToLink === "" || this.state.addonIdToLink === null || selectedAddonToLink.length === 0) ? true : false, onLinkingItemSubmitFlag: (selecteItemToLink.length === 0) ? true : false })
    if (selectedAddonToLink.length && selecteItemToLink.length) {
      await this.props.requestAddonRecords()
      let payload = {
        addonIds: selectedAddonToLink,
        itemIds: [...itemIds],
      }
      await this.props.linkAddonsToItems(payload);
      await this.fetchItemsLinkedWithAddons()
      await this.handleLinkAddonClose()
      await this.props.requestAddonRecordsFalse()
    }
  }

  handleFilterOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleExpand = (value) => {
    this.setState({ expand: value, addonsCheckedToUnlink: [] ,groupCheckedToUnlink: [] })
  };

  handleLinkedItemsOpen = async (_id) => {
    this.setState(
      {
        handleLinkedItemsModalOpen: true,
        addonObjectId: _id
      }
    );

  }

  handleLinkedItemsClose = async () => {
    this.setState({ expand: null ,handleLinkedItemsModalOpen: false })
    await this.fetchItemsLinkedWithAddons();
  }

  setCheckedAddonToDelete = (value) => {
    this.setState({ addonsCheckedToUnlink: value })
  }

  handleRowInfo = async (event, _id) => {
    if (event.target.checked) {
      this.setState({ linkedAddonItemId: _id })
    }
  }
  handleUnlinkAddonsOpen = () => {
    this.setState({ handleUnlinkAddonModalOpen: true })
  }
  handleUnlinkAddonsClose = async() => {
    this.setState({ handleUnlinkAddonModalOpen: false, addonsCheckedToUnlink: []  , groupCheckedToUnlink : [] ,  linkedAddonItemId : ""});
    await this.fetchItemsLinkedWithAddons()
  }

  handleUnlinkAddons = async () => {
    const  {addonsCheckedToUnlink , linkedAddonItemId , groupCheckedToUnlink } = this.state
    if (addonsCheckedToUnlink.length || groupCheckedToUnlink.length) {
      await this.props.requestAddonRecords()
      let payload = {
        id: linkedAddonItemId,
        addonIds: addonsCheckedToUnlink,
        addonGroupIds : groupCheckedToUnlink
      }
      await this.props.unlinkAddonsFromItem(payload);
      await this.handleUnlinkAddonsClose()
      await this.fetchItemsLinkedWithAddons()
      await this.props.requestAddonRecordsFalse()
    }
  }
  handleEditLinkedOpen = async (row, addonData) => {
    addonData && addonData.length && addonData.forEach(element => { element.flag = true });
    if (row.linkedAddOnGroups && row.linkedAddOnGroups.length) {
      row.linkedAddOnGroups.forEach(element=> element.isLinked = true )
    }
    this.setState({ editLinkedAddonopen: true, itemArray: [row], addonArray: addonData })

  };
  handleEditAddonClose = async () => {
    this.setState({ editLinkedAddonopen: false, addonUnCheckedToUpdate: [] })
    await this.props.getAddonsToLinkItem()
  };
  setUncheckedAddonToUnlink = (addonToUpdate) => {
    this.setState({ addonUnCheckedToUpdate: addonToUpdate })
  };


  handleAddonSelectionCount = async (event, _id) => {
    this.setState({ onLinkingAddonSubmitFlag: false })
    let finalAddonRecords = this.state.finalAddonRecords;
    let selectedAddonToLink = this.state.selectedAddonToLink;
    if (event.target.checked) {

      let selectedAddonRecord = finalAddonRecords.filter((v) => selectedAddonToLink.includes(v._id));
      let unSelectedAddonCount = finalAddonRecords.length - selectedAddonToLink.length
      this.setState({ selectedAddonCount: selectedAddonRecord.length, unSelectedAddonCount: unSelectedAddonCount, isSelectAllAddonChecked: true })

    } else {

      let selectedAddonRecord = selectedAddonToLink.filter((v) => v !== _id);
      let unSelectedAddonCount = finalAddonRecords.length - selectedAddonRecord.length
      this.setState({ selectedAddonCount: selectedAddonRecord.length, unSelectedAddonCount: unSelectedAddonCount, isSelectAllAddonChecked: false })

    }

  }


  handleAddonSelectAllCount = (event) => {
    this.setState({ onLinkingAddonSubmitFlag: false })
    let finalAddonRecords = this.state.finalAddonRecords;
    if (event.target.checked) {
      this.setState({ selectedAddonCount: finalAddonRecords.length, unSelectedAddonCount: 0, isSelectAllAddonChecked: true })

    } else {
      this.setState({ selectedAddonCount: 0, unSelectedAddonCount: finalAddonRecords.length, isSelectAllAddonChecked: false })

    }
  }

  handleLinkItem = async (item) => {
    let tempArray = [];
    if (item) {
      tempArray.push(...this.state.selecteItemToLink, item)
    };
    let selectedArray = new Set(tempArray.map(obj => obj._id))
    let unSelectedArray = this.state.finalItemRecords.filter(obj => !selectedArray.has(obj._id));
    let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
    this.setState({ finalItemRecords: unSelectedArray, selecteItemToLink: uniqueSelectedItems })
  }

  handleUnLinkItem = async (_id) => {

    let unlinkLinkedItem = _id
    let fetchedItemRecords = this.state.fetchedItemRecords
    let selecteItemToLink = this.state.selecteItemToLink.filter(obj => obj._id !== unlinkLinkedItem.toString());
    let itemIds = new Set(selecteItemToLink.map(obj => obj._id))
    let unSelectedArray = fetchedItemRecords.filter(obj => !itemIds.has(obj._id));
    this.setState({ finalItemRecords: unSelectedArray, selecteItemToLink: selecteItemToLink })
  }

  handleUnlinkAll = async () => {
    let fetchedItemRecords = this.state.fetchedItemRecords
    if (this.state.selecteItemToLink.length) {
      this.setState({ finalItemRecords: fetchedItemRecords, selecteItemToLink: [] })
    }
  }

  handleLinkAll = async () => {
    if (this.state.finalItemRecords.length) {
      let tempArray = [];
      tempArray.push(...this.state.selecteItemToLink, ...this.state.finalItemRecords)
      let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
      this.setState({ selecteItemToLink: uniqueSelectedItems, finalItemRecords: [] })
    }
  }



  onAddonGroupOpen = async () => {
    this.setState({ isAddonGroupOpen: true })
  }
  onLinkAddonGroupClose = async () => {
    this.setState({ isAddonGroupOpen: true })
  }
  onAddonGroupClose = async () => {
    this.setState({ isAddonGroupOpen: false })
    await this.fetchItemsLinkedWithAddons()
  }
  handleLinkingRecordsSubmit = async (payload) => {
    this.setState({ isAddonGroupOpen: false })
    await this.props.linkAddonGroupToItem(payload);
    await this.fetchItemsLinkedWithAddons()
  }

  setSelectedGroupToUnlink =(value)=>{
    this.setState({ groupCheckedToUnlink : value })
  }

  linkedItemToGroupOpen = (linkedServiceItems , _id)=>{
    this.setState({ linkedServiceToGroupOpen : true , linkedServiceItems : linkedServiceItems , addonGroupId : _id})
}
closeLinkedItemToGroup= async()=>{
  this.setState({ linkedServiceToGroupOpen : false , linkedServiceItems : null , addonGroupId : null });
  await this.fetchItemsLinkedWithAddons()
}

  render() {
    const { classes } = this.props;

    let muiAlert = null;
    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }

    return (

      <Container className={classes.container}>

        <Grid item xs={12} sm={12}>
          <Container>

            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 115px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.handleUnlinkAddonsOpen}
                disabled={(this.state.addonsCheckedToUnlink.length > 0  || this.state.groupCheckedToUnlink.length > 0) ? false : true}
              >
                Unlink
              </Button> : null}
          </Container>

          <Container>
            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 5px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.onAddonGroupOpen}
              >
                LINK ADDON Group
              </Button> : null}
            <Modal
              open={this.state.isAddonGroupOpen}
              onClose={this.onAddonGroupClose}
              closeAfterTransition
            >
              <LinkAddonGroup
                onAddonGroupClose={this.onAddonGroupClose}
                businessId={this.props.businessDetails._id}
                handleLinkingRecordsSubmit={this.handleLinkingRecordsSubmit}
              />

            </Modal>
          </Container>


          <Container>
            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 5px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.handleLinkAddonOpen}
              >
                LINK ADDON
              </Button> : null}
            <Modal
              open={this.state.handleLinkAddonToItem}
              onClose={this.handleLinkAddonClose}
              closeAfterTransition
            >
              <LinkAddonToItem
                handleLinkAddonClose={this.handleLinkAddonClose}
                onAddonToLinkSearch={this.onAddonToLinkSearch}
                onItemToLinkSearch={this.onItemToLinkSearch}
                handleLinkAddonsToItemChange={this.handleLinkAddonsToItemChange}
                addonIdToLink={this.state.addonIdToLink}
                itemIdToLink={this.state.itemIdToLink}
                groupIdToLink={this.state.groupIdToLink}
                finalAddonRecords={this.props.addons.addonRecordstoLink.data}
                filteredAddonRecords={this.state.finalAddonRecords}
                filteredItemRecords={this.state.finalItemRecords}
                totalAddonCount={this.state.finalAddonRecords.length}
                resetSearchedAddon={this.resetSearchedAddon}
                resetSearchedItem={this.resetSearchedItem}
                setSelectedAddonToLink={this.setSelectedAddonToLink}
                selectedAddonToLink={this.state.selectedAddonToLink}
                isSelectedAddonExist={this.state.selectedAddonToLink.length}
                isSelectedItemExist={this.state.selecteItemToLink.length}
                showSelectedAddon={this.state.showSelectedAddon}
                showUnSelectedAddon={this.state.showUnSelectedAddon}
                handleShowSelectedItem={this.handleShowSelectedItem}
                handleShowSelectedAddon={this.handleShowSelectedAddon}
                handleShowUnselectedAddon={this.handleShowUnselectedAddon}
                onLinkAddonToItemSubmit={this.onLinkAddonToItemSubmit}
                handleItemSelectionCount={this.handleItemSelectionCount}
                handleAddonSelectionCount={this.handleAddonSelectionCount}
                selectedAddonCount={this.state.selectedAddonCount}
                unSelectedAddonCount={this.state.unSelectedAddonCount}
                isSelectAllAddonChecked={this.state.isSelectAllAddonChecked}
                handleAddonSelectAllCount={this.handleAddonSelectAllCount}
                onLinkingAddonSubmitFlag={this.state.onLinkingAddonSubmitFlag}
                onLinkingItemSubmitFlag={this.state.onLinkingItemSubmitFlag}
                noRecordsFound={this.props.addons.addonRecordstoLink?.noRecordsFound}
                noItemRecordsFound={this.props.addons.itemRecordstoLink?.noRecordsFound}
                handleLinkItem={this.handleLinkItem}
                handleUnLinkItem={this.handleUnLinkItem}
                selecteItemToLink={this.state.selecteItemToLink}
                handleUnlinkAll={this.handleUnlinkAll}
                handleLinkAll={this.handleLinkAll}
              />
            </Modal>
          </Container>


        </Grid>
        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }}
        >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Search/Filter Report</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="ItemId"
                    label="ItemId / Item Name"
                    value={this.state.ItemId}
                    variant="outlined"
                    onChange={this.handleFilterOnChange}
                  />
                </Grid>
                {/* <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Food Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.foodType}
                      name="foodType"
                      onChange={this.handleFilterOnChange}
                      label="Food Type"
                    >
                      <MenuItem value="Veg" style={{ minHeight: "28px", height: "28px" }} >Veg</MenuItem>
                      <MenuItem value="NonVeg" style={{ minHeight: "28px", height: "28px" }} >NonVeg</MenuItem>
                      <MenuItem value="Egg" style={{ minHeight: "28px", height: "28px" }} >Egg</MenuItem>
                      <MenuItem value="NA" style={{ minHeight: "28px", height: "28px" }} >NA</MenuItem>
                      <MenuItem value="Blank" style={{ minHeight: "28px", height: "28px" }} >Blank </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                 <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard">Food Type</InputLabel>
                                    <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.foodType}
                                                name="foodType"
                                                onChange={this.handleFilterOnChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                        >
                                        {foodType.map((foodType) => (
                                                    <MenuItem key={foodType} value={foodType} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.foodType.indexOf(foodType) > -1} />
                                                        <ListItemText primary={foodType} />
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Is Enabled
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="isEnabled"
                      label="Is Enabled"
                      value={this.state.isEnabled}
                      onChange={this.handleFilterOnChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled </MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">Availability</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard-label"
                      name="availability"
                      label="Availability"
                      value={this.state.availability}
                      onChange={this.handleFilterOnChange}
                    >
                      <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Available</MenuItem>
                      <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Unavailable</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>


                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={
                      () => this.setPage(0)

                    }
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Container>
        <Container className={classes.container}>
          <LinkingTable
            linkedRecords={this.props.addons.linkedRecords.data}
            totalCount={this.props.addons.linkedRecords.totalCount}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            setPage={this.setPage}
            setRowsPerPage={this.setRowsPerPage}
            role={this.props.role}
            handleExpand={this.handleExpand}
            expand={this.state.expand}
            handleLinkedItemsOpen={this.handleLinkedItemsOpen}
            addonsCheckedToUnlink={this.state.addonsCheckedToUnlink}
            setCheckedAddonToDelete={this.setCheckedAddonToDelete}
            handleRowInfo={this.handleRowInfo}
            handleEditLinkedOpen={this.handleEditLinkedOpen}
            groupCheckedToUnlink={this.state.groupCheckedToUnlink}
            setSelectedGroupToUnlink={this.setSelectedGroupToUnlink}
            linkedItemToGroupOpen={this.linkedItemToGroupOpen}
            closeLinkedItemToGroup={this.closeLinkedItemToGroup}
          />
        </Container>

        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>
        <Modal
          open={this.state.handleLinkedItemsModalOpen}
          className={classes.linkedItemsModal}
          onClose={this.handleLinkedItemsClose}
          closeAfterTransition
        >
          <LinkedItemsModal
            handleLinkedItemsClose={this.handleLinkedItemsClose}
            addonObjectId={this.state.addonObjectId}
            role={this.props.role}
          />
        </Modal>


        <Modal
          open={this.state.editLinkedAddonopen}
          className={classes.linkedItemsModal}
          onClose={this.handleEditAddonClose}
          closeAfterTransition
        >
          <LinkingUpdate
            handleEditAddonClose={this.handleEditAddonClose}
            itemArray={this.state.itemArray}
            role={this.props.role}
            addonUnCheckedToUpdate={this.state.addonUnCheckedToUpdate}
            setUncheckedAddonToUnlink={this.setUncheckedAddonToUnlink}
            existingAddons={this.state.addonArray}
            businessId={this.props.businessDetails._id}
            fetchItemsLinkedWithAddons={this.fetchItemsLinkedWithAddons}
          />
        </Modal>
        <Modal
          open={this.state.linkedServiceToGroupOpen}
          className={classes.groupModal}
          onClose={this.closeLinkedItemToGroup}
          closeAfterTransition
        >
          <LinkedItemToAddonGroup
            linkedServiceItems={this.state.linkedServiceItems}
            addonGroupId={this.state.addonGroupId}
            role={this.props.role}
            closeLinkedItemToGroup={this.closeLinkedItemToGroup}
          />
        </Modal>


        <Dialog
          open={this.state.handleUnlinkAddonModalOpen}
          onClose={this.handleUnlinkAddonsClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Unlink Addon</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Unlink Addons / Addon Group from Item?<br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleUnlinkAddonsClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.handleUnlinkAddons()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          className={classes.backdrop}
          open={this.props.addons.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    addons: state.addons,
    addonGroup: state.addonGroup,
  };
};

export default connect(mapStateToProps, {

  getAddonsToLinkItem, getItemsToLinkAddon, requestAddonRecords, requestAddonRecordsFalse, linkAddonsToItems, clearMsg, getAllItemsLinkedWithAddons, unlinkAddonsFromItem, linkAddonGroupToItem, clearAddonGroupMsg

})(withStyles(styles)(ManageAddonList));
