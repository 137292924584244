import React, { Component } from 'react'
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
    changeHeaderName,
    getAllMasterOtp,
    setBusinessAppMasterOTP,
    deleteConsumerMasterOTP,
    deleteBusinessMasterOTP,
    setConsumerAppMasterOTP,
    getConsumerMasterOTP,
    setConsumerAppMasterRegistrationOTP,
    deleteConsumerAppRegestrationMasterOTP
} from '../../actions/dashboardActions';





const styles = theme => ({
    boxStyle: {
        // width: "100%",
        // height: "170px",
        // paddingTop: theme.spacing(2),
        // paddingBottom: theme.spacing(2),
        backgroundColor: "#fff",
        flex: 1
    },
    content: {
        // display: 'flex',
        flexDirection: "row"
    },

    heading: {
        // fontSize: "14px",
        // fontWeight: 500
        marginTop: "110px",
        marginLeft: "40px"
    },

    heading2: {
        // fontSize: "14px",
        // fontWeight: 500
        marginTop: "25px",
        marginBottom: "7px",
        marginLeft: "60px"
    },
    inputBox: {
        width: "60%",
        height: "30px",
        // marginTop: "32px",
        marginLeft: "130px",
        marginRight: "7px",
        borderRadius: "4px",
        borderColor: "#f0fff"
    },
    buttonStyle: {
        width: "80px",
        height: "30px",
        marginTop: "43px",
        // marginLeft: "10px",
        // marginRight: "10px",
    },
    clearButtonStyle: {
        width: "80px",
        height: "25px",
        marginLeft: "40px",
        marginRight: "15px",
    },
    textStyle: {
        // margin: "2px",
        marginTop: "80px",
        marginLeft: "130px",
        // marginRight: "10px",
    }



});

class MasterOTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            masterOTP: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConsumerOTPClear = this.handleConsumerOTPClear.bind(this);
        this.handleBusinessOTPClear = this.handleBusinessOTPClear.bind(this);
        this.handleConsumerSubmit = this.handleConsumerSubmit.bind(this);
    }


    componentDidMount() {
        this.props.changeHeaderName("Master OTP");
        this.getMasterOTP();


    }


    getMasterOTP = () => {
        let payload = {
            masterOTP: this.state.masterOTP

        }
        this.props.getAllMasterOtp(payload);


    }


    handleSubmit = async (event) => {

        let setMasterOTP = {
            businessOTP: Number(this.state.masterOTP)
        };
        if (this.state.masterOTP.length === 6) {

            if (window.confirm(` Do You Want to Set Business App OTP: ${Number(this.state.masterOTP)} `)) {
                await this.props.setBusinessAppMasterOTP(setMasterOTP);
                await this.getMasterOTP();
                this.setState({ masterOTP: "" })
            } else {
                return ("cancle otp")
            }
        } else {
            return alert("OTP Should be 6 digits")
        }
    }

    // handleConsumerSubmit = async (event) => {
    //     let setConsumerMasterOTP = {
    //         consumerOTP: Number(this.state.consumerOTP)
    //     };

    //     if (this.state.consumerOTP.length == 6) {
    //         if (window.confirm(` Do You Want to Set Consumer App OTP: ${Number(this.state.consumerOTP)} `)) {
    //             await this.props.setConsumerAppMasterOTP(setConsumerMasterOTP);
    //             await this.getMasterOTP();
    //             this.setState({ consumerOTP: "" })
    //         } else {
    //             return ("cancle otp")
    //         }

    //     } else {
    //         return alert("OTP Should be 6 digits")
    //     }



    // };

    handleConsumerSubmit = async (event) => {
            let setConsumerMasterOTP = {
                consumerMasterRegistrationOTP: Number(this.state.consumerOTP)
            };
            if(!this.state.consumerOTP){
                return alert("Required")
            }
    
            if (this.state.consumerOTP.length === 6) {
                if (window.confirm(` Set new consumer app Master OTP: ${Number(this.state.consumerOTP)} `)) {
                    await this.props.setConsumerAppMasterRegistrationOTP(setConsumerMasterOTP);
                    await this.getMasterOTP();
                    this.setState({ consumerOTP: "" })
                } else {
                    return ("Cancel otp")
                }
    
            } else {
                return alert("OTP Should be 6 digits")
            }
    
    
    
        };



    handleChange = (e) => {

        if (e.target.value.length > 6) {
            return ("Error");
        }
        this.setState({ [e.target.name]: e.target.value });

    }


    handleConsumerInputChange = (event) => {
        if (event.target.value.length > 6) {
            return ("Error");
        }
        this.setState({ [event.target.name]: event.target.value });
    }


    handleBusinessOTPClear = async () => {

        if (window.confirm("Do You Want To Clear Business App OTP?")) {
            await this.props.deleteBusinessMasterOTP();
            await this.getMasterOTP();
        } else {
            return ("Cancel otp Clear")
        }


    }

    // handleConsumerOTPClear = async () => {

    //     if (window.confirm("Do You Want To Clear Consumer App OTP?")) {
    //         await this.props.deleteConsumerMasterOTP();
    //         await this.getMasterOTP();
    //     } else {
    //         return ("cancle otp Clear")
    //     }


    // }
    handleConsumerOTPClear = async () => {

        if (window.confirm("Do You Want To Clear Consumer App OTP?")) {
            await this.props.deleteConsumerAppRegestrationMasterOTP();
            await this.getMasterOTP();
        } else {
            return ("cancle otp Clear")
        }


    }


    render() {
        const { classes } = this.props;

        return (
            <div className={classes.boxStyle}>
                <div >
                    <div>
                        <h4 className={classes.heading} >Business App OTP </h4>
                    </div>
                    <div className="row"  >

                        <div className={classes.heading2} >

                            <h5 >Current Master OTP</h5>

                            <h7 style={{ marginLeft: "40px" }}
                            > {this.props && this.props.getMasterOTP && this.props.getMasterOTP.data && this.props.getMasterOTP.data.length ?
                                this.props.getMasterOTP.data[0].businessOverrideOTP : "NOT SET"} </h7>

                            <Button
                                className={classes.clearButtonStyle}
                                type="submit"
                                variant="contained"
                                margin="normal"
                                onClick={this.handleBusinessOTPClear}
                            >Clear
                        </Button>

                        </div>

                        <div className="row" >
                            <div style={{ marginTop: "24px", width: "55%", marginLeft: "40px" }}>
                                <text className={classes.textStyle}>New Master OTP</text>
                                <input
                                    className={classes.inputBox}
                                    type="number"
                                    pattern="[0-9]"
                                    inputMode="numeric"
                                    name="masterOTP"
                                    label="New Master OTP"
                                    value={this.state.masterOTP}
                                    onChange={this.handleChange}


                                />
                            </div>
                            <div>
                                <Button
                                    className={classes.buttonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleSubmit}
                                >SET
                        </Button>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{ margin: "10px", backgroundColor: "" }} ></div>


                <div className={classes.boxStyle}>
                    <div>
                        <h4 className={classes.heading} >New Consumer Registration Master OTP</h4>
                    </div>
                    <div className="row"  >
                        <div className={classes.heading2} >
                            <h5 >Current Master OTP</h5>
                            <div className="row" >
                                <h7 style={{ marginLeft: "40px" }}
                                > {this.props && this.props.getMasterOTP && this.props.getMasterOTP.data && this.props.getMasterOTP.data.length
                                    ? this.props.getMasterOTP.data[0].consumerMasterRegistrationOTP : "NOT SET"} </h7>

                                <Button
                                    className={classes.clearButtonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.handleConsumerOTPClear}
                                >Clear
                        </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ marginTop: "24px", width: "55%", marginLeft: "55px" }}>
                                <text className={classes.textStyle}>New Master OTP</text>
                                <input
                                    className={classes.inputBox}
                                    type="number"
                                    pattern="[0-9]"
                                    inputMode="numeric"
                                    name="consumerOTP"
                                    label="New Master OTP"
                                    value={this.state.consumerOTP}
                                    onChange={this.handleConsumerInputChange}
                                />
                            </div>
                            <div>
                                <Button
                                    className={classes.buttonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleConsumerSubmit}
                                >SET
                        </Button>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className={classes.boxStyle}>
                    <div>
                        <h4 className={classes.heading} >Customer App OTP </h4>
                    </div>
                    <div className="row"  >
                        <div className={classes.heading2} >
                            <h5 >Current Master OTP</h5>
                            <div className="row" >
                                <h7 style={{ marginLeft: "40px" }}
                                > {this.props && this.props.getMasterOTP && this.props.getMasterOTP.data && this.props.getMasterOTP.data.length
                                    ? this.props.getMasterOTP.data[0].consumerOverrideOTP : "NOT SET"} </h7>

                                <Button
                                    className={classes.clearButtonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.handleConsumerOTPClear}
                                >Clear
                        </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div style={{ marginTop: "24px", width: "55%", marginLeft: "55px" }}>
                                <text className={classes.textStyle}>New Master OTP</text>
                                <input
                                    className={classes.inputBox}
                                    type="number"
                                    pattern="[0-9]"
                                    inputmode="numeric"
                                    name="consumerOTP"
                                    label="New Master OTP"
                                    value={this.state.consumerOTP}
                                    onChange={this.handleConsumerInputChange}
                                />
                            </div>
                            <div>
                                <Button
                                    className={classes.buttonStyle}
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleConsumerSubmit}
                                >SET
                        </Button>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {
        getMasterOTP: state.dashboard.getMasterOTP

    }
};

export default connect(mapStateToProps, {
    changeHeaderName,
    deleteConsumerMasterOTP,
    deleteBusinessMasterOTP,
    getAllMasterOtp,
    setBusinessAppMasterOTP,
    setConsumerAppMasterOTP,
    getConsumerMasterOTP,
    setConsumerAppMasterRegistrationOTP,
    deleteConsumerAppRegestrationMasterOTP
})(withStyles(styles)(MasterOTP));