import {
    SET_VARIATION_ADD_SUCCESS_MESSAGE,
    SET_VARIATION_ADD_ERROR_MESSAGE,
    GET_ALL_VARIATIONS,
    GET_ITEMS_TO_LINK_VARIATION_GROUP,
    SET_VARIATIONs_LINK_SUCCESS_MESSAGE,
    SET_VARIATIONs_LINK_ERROR_MESSAGE,
    SET_UPDATE_SUCCESS_MESSAGE,
    SET_UPDATE_ERROR_MESSAGE,
    SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE,
    SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE,
    SET_DELETE_VARIATIONS_SUCCESS_MESSAGE,
    SET_DELETE_VARIATIONS_ERROR_MESSAGE,
    REQUEST_GROUP_RECORDS,
    REQUEST_GROUP_RECORDS_FALSE,
    UNSET_LINKED_ADDONS_SUCCESS,
    UNSET_LINKED_ADDONS_ERROR,
    GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS

} from "../actions/types";

const INITIAL_STATE = {
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false,
    variationGroupList : [],
    linkItemsToGroupList : [],
    variationListCatalog : [],
    page: 0,
    skip: 0,
    limit: 10,
};

export default function variationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_ALL_VARIATIONS:
            return {
                ...state,
                variationGroupList: action.payload,

            };
        case GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS:
            return {
                ...state,
                variationListCatalog: action.payload,

            };
            case  SET_VARIATION_ADD_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_VARIATION_ADD_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case GET_ITEMS_TO_LINK_VARIATION_GROUP:
            return {
                ...state,
                linkItemsToGroupList: action.payload,

            };
        case SET_VARIATIONs_LINK_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case SET_VARIATIONs_LINK_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false

            };
        case SET_UPDATE_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case SET_UPDATE_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            
            case SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case SET_DELETE_VARIATIONS_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case SET_DELETE_VARIATIONS_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
            case REQUEST_GROUP_RECORDS:
                return { ...state, isFetching: action.payload };
            case REQUEST_GROUP_RECORDS_FALSE:
                return { ...state, isFetching: action.payload };
                case UNSET_LINKED_ADDONS_SUCCESS:
                    return {
                        ...state,
                        sucessMsg: action.payload.msg,
                        ops: action.payload.ops,
                        isFetching: false
                    };
                case UNSET_LINKED_ADDONS_ERROR:
                    return {
                        ...state,
                        errorMsg: action.payload.msg,
                        ops: action.payload.ops,
                        isFetching: false
        
                    };
            

        default:
            return state;
    }
}