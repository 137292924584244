import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
// import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';
import { addHeadSubhead, getHeadSubheadHierarchy, updateHeadAndSubhead } from '../../../actions/businessActions';
import FormHelperText from '@material-ui/core/FormHelperText';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const styles = theme => ({
    header: {
        height: '20%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
    },
    grid: {
        margin: '0px 0px',
        width: '100%',
        //display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "15px",
        paddingRight: "15px"

    },
    content: {
        height: '73%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px'
    },
    modalContainer: {
        width: '40%',
        height: '35%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    select: {
        height: '38px'
    },
});


class SubHeadDetailView extends Component {
    constructor() {
        super();
        this.state = {
            sequenceNo: 1,
            head: null,
            subHeadName: "",
            error: {},
            peersLength: 0
        };
    }

    handleIncrement = () => {
        let sequence = this.state.sequenceNo + 1;
        if (sequence <= this.state.peersLength) {
            this.setState({ sequenceNo: sequence });
        }
    };

    handleDecrement = () => {
        if (this.state.sequenceNo && (this.state.sequenceNo > 1)) {
            this.setState(state => ({ sequenceNo: state.sequenceNo - 1 }));
        }
    };

    handleChange = (event) => {
        if (event.target.name === 'head') {
            let parentHead = this.props.business && this.props.business.Heads &&
                this.props.business.Heads.find(tempHead => tempHead._id.toString() === event.target.value.toString());
            this.setState({ sequenceNo: parentHead ? (parentHead.children.length + 1) : 1, peersLength: parentHead.children.length + 1 });
        }
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.edit && this.props.edit !== prevProps.edit && this.props.data.name && this.props.data.parentsName) {
            this.setState({ subHeadName: this.props.data.name, head: this.props.data.parentsName });
            let subHeads = this.props.parentHead && this.props.parentHead.children.map(sub => sub._id);
            let index = subHeads.findIndex(x => x === this.props.data._id);
            this.setState({ sequenceNo: index + 1, peersLength: this.props.parentHead.children.length });
        }
    }

    checkerror = () => {
        let error = this.state.error
        if (!this.state.head) {
            error.head = "Required"
        }
        if (!this.state.subHeadName.trim()) {
            error.subhead = "Required"
        }
        this.setState({ error })
    }

    addSubHead = async () => {
        await this.checkerror()
        if (!this.state.error.head && !this.state.error.subhead) {
            if (!this.props.edit) {
                let payload = {
                    name: this.state.subHeadName,
                    type: "SubHead",
                    parentId: this.state.head,
                    children: [],
                    subcategory: null,
                    businessId: this.props.businessDetails._id,
                    services: [],
                    sequence: this.state.sequenceNo
                }
                await this.props.addHeadSubhead(payload)
                this.close();
                this.props.getHeadSubheads()

            } else if (this.props.edit) {
                let payload = {
                    data: {
                        name: this.state.subHeadName
                    },
                    id: this.props.data._id
                };

                if (this.state.sequenceNo) {
                    payload.data.sequence = this.state.sequenceNo;
                }

                await this.props.updateHeadAndSubhead(payload)
                this.close();
                this.props.getHeadSubheads()

            }
        }
    };

    clearData = () => {
        this.setState({
            sequenceNo: 1,
            head: "",
            subHeadName: "",
            error: {}
        })
    }

    close = () => {
        this.clearData();
        this.props.onClose();
    }

    render() {
        const { classes } = this.props;
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                closeAfterTransition>
                <Container className={classes.modalContainer}>
                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h5" gutterBottom>
                                Sub-Head
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
                        </Grid>
                    </Grid>
                    <Grid container className={classes.grid} spacing={1} style={{marginTop:"10px"}}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" gutterBottom>
                                Head *
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" gutterBottom>
                                Sub-Head Name *
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <Typography variant="subtitle2" gutterBottom>
                                Sequence Number *
                            </Typography>
                        </Grid> */}
                        <Grid item xs={6}>
                            {this.props.edit ?
                                <Typography variant="subtitle2" gutterBottom>
                                    {this.state.head}
                                </Typography>
                                :
                                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                    <Select
                                        id="head"
                                        value={this.state.head}
                                        name="head"
                                        onChange={this.handleChange}
                                        MenuProps={MenuProps}
                                        helperText="required"
                                        className={classes.select}
                                    >
                                        {this.props.business?.Heads?.map((head) => (
                                            <MenuItem key={head._id} value={head._id} style={{ minHeight: "28px", height: "28px" }}>
                                                <ListItemText primary={head.name} style={{ textOverflow:'ellipsis', overflow: 'hidden' }} />
                                            </MenuItem>
                                        ))
                                        }

                                    </Select>
                                    {
                                        this.state.error.head ? <FormHelperText error>{this.state.error.head}</FormHelperText> : null
                                    }
                                </FormControl>
                            }
                        </Grid>
                        <Grid item xs={6} >
                            <TextField fullWidth margin="dense" name="subHeadName" variant="outlined" value={this.state.subHeadName} error={this.state.error.subhead} helperText={this.state.error.subhead} onChange={this.handleChange} />
                        </Grid>
                        {/* <Grid item xs={4} style={{ alignItems: "center", justify: "center", alignContent: "center" }} >
                            <ButtonGroup size="small" aria-label="small outlined button group">
                                <Button variant="contained" onClick={this.handleDecrement}>-</Button>
                                <TextField variant="filled" name="sequenceNo" value={this.state.sequenceNo} onChange={this.handleChange} />
                                <Button variant="contained" onClick={this.handleIncrement}>+</Button>
                            </ButtonGroup>
                        </Grid> */}
                        <Divider />
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                style={{ margin: "5px" }}
                                fullWidth
                                variant="contained"
                                onClick={this.close}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                style={{ margin: "5px" }}
                                variant="contained"
                                color="primary"
                                onClick={this.addSubHead}
                            >
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {

    return {
        dashboard: state.dashboard,
        business: state.business,
        contract: state.contract,
        user: state.auth.user,
        env: state.auth.env,
    };
};

export default connect(mapStateToProps, {
    addHeadSubhead,
    getHeadSubheadHierarchy,
    updateHeadAndSubhead
})(withStyles(styles)(SubHeadDetailView));
