import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


const headers = [
    { id: 'username', disablePadding: true, label: 'Username' },
    { id: 'firstName', disablePadding: false, label: 'First Name' },
    { id: 'lastName', disablePadding: false, label: 'Last Name' },
    { id: 'mobileNumber', disablePadding: false, label: 'Contact Number' },
    { id: 'email', disablePadding: false, label: 'E-mail' },
    { id: 'region', disablePadding: false, label: 'Region' },
    { id: 'pincode', disablePadding: false, label: 'Pin Code' },
    { id: 'regionCode', disablePadding: false, label: 'Region Code' },
    { id: 'isActive', disablePadding: false, label: 'Status' },
    { id: '', disablePadding: false, label: '' },
]

function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };

    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        // sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        > */}
                            {headCell.label}
                            {/* {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Regional Heads ({props.totalcount})
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        // fontSize: "12px",
        padding: "6px 0px 6px 16px"
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleEditRegionalHeadModal = (data) => {
        props.openEditRegionalHeadModal(data);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.rowData.totalcount - props.page * props.rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar totalcount={props.rowData.totalcount} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rowData.totalcount}
                        />
                        <TableBody>
                            {props.rowData.data
                                .map((row, index) => {
                                    let regions = row.controlledRegions.map(a => a.name);
                                    let regionCodes = row.controlledRegions.map(a => a.regionCode);
                                    let pinCodes = row.controlledRegions.map(a => a.pincode);
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.username}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.firstName}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.lastName}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.mobileNumber}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.email}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{
                                                regions.map((region, i) => {
                                                    return regions.length - 1 === i ? region : region + ", ";
                                                })
                                            }
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{
                                                pinCodes.map((pinCode, i) => {
                                                    return pinCodes.length - 1 === i ? pinCode : pinCode + ", ";
                                                })
                                            }</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{
                                                regionCodes.map((regionCode, i) => {
                                                    return regionCodes.length - 1 === i ? regionCode : regionCode + ", ";
                                                })
                                            }</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            key={`userFlagSwitch_${row._id}_${index}`}
                                                            checked={row.isActive === true}
                                                            onChange={() => props.handleUserFlagSwitchChange(row)}
                                                            color="primary"
                                                        />
                                                        }
                                                        style={{ fontSize: "10px", color: row.isActive === true ? 'black' : 'red' }}
                                                        label={row.isActive === true ? 'Active' : 'Inactive'}
                                                />
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                <IconButton aria-label="edit" onClick={() => handleEditRegionalHeadModal(row)} style={{ height: '30px' }}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.rowData.totalcount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
