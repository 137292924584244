import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import { connect } from "react-redux";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as UnlinkAllIcon } from '../../../../images/UnlinkAll_Icon.svg';
import { ReactComponent as LinkAllIcon } from '../../../../images/LinkAll_Icon.svg';
import { ReactComponent as UnlinkIcon } from '../../../../images/Unlink_Icon.svg';
import { ReactComponent as LinkIcon } from '../../../../images/Link_Icon.svg';



const styles = (theme) => ({
    form: {
        position: "fixed",
        top: 4,
        bottom: 4,
        margin : '0px 200px',
        width : '78.05%',
        background: "white",
        overflow: "auto",
        borderRadius: "1px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        },
        display : 'center'
    },
    dynamicForm: {
        position: "absolute",
        top: 100,
        right: 200,
        left: 200,
        bottom: 100,
        background: "white",
        overflow: "auto",
        borderRadius: "10px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        }
    },
    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    dynamicHeader: {
        height: '6%',
        margin: '0px 0px',
        width: '78.05%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        position : 'fixed!important',
       ' z-index' : 60
    },
    CloseIcon: {
        float: "right",
        color: "white",

    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    topcontainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: "50%",
    },

    bottomcontainer: {
        position: "relative",
        top: "50%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    bottomcontainerDynamic: {
        position: "absolute",
        top: "40%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    table: {
        border: "1px"
    },
    cellPadding: {
        padding: "0px 0px 0px 0px"
    },
    dynamicCellPadding: {
        padding: "50px 0px 0px 0px"
    },
    paper: {
        border: "1px solid #dcdcdc"
    },
    footer: {
        height: '10%',
        float: "right"

    },
});
const useStyles = makeStyles((theme) => ({

    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
      
    },
    CloseIcon: {
        float: "right",
        color: "white",

    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    topcontainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: "50%",
    },

    bottomcontainer: {
        position: "relative",
        top: "50%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    bottomcontainerDynamic: {
        position: "absolute",
        top: "40%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    table: {
        border: "1px"
    },
    cellPadding: {
        padding: "0px"
    },
    paper: {
        border: "1px solid #dcdcdc"
    },
    footer: {
        height: '10%',
        float: "right"

    },
    headerPadding: {
        padding: '0px 0px 0px 10px'
    }
}));


export function EnhancedTableToolbar(props) {

    const {showUnSelectedAddon, showSelectedAddon, selectedAddonCount, unSelectedAddonCount, isSelectAllAddonChecked } = props;

    return (
        <Toolbar style={{ display: "flex!important", padding: "0px" }}>

            {
                showUnSelectedAddon === false ?
                    <Grid style={{ padding: "0px 0px 0px 60px" }}>
                        <Typography >
                            <Checkbox
                                onChange={() => { props.handleShowSelectedAddon(!props.showSelectedAddon) }}
                                checked={showSelectedAddon}

                            />
                            Show Selected({selectedAddonCount > 0 ? selectedAddonCount : `00`})
                        </Typography>
                    </Grid> :   <Grid style={{ padding: "0px 0px 0px 50px" }}></Grid>
            }
            {
                showSelectedAddon === false ?
                    <Grid style={{ padding: "0px 0px 0px 30px" }}>
                        <Typography >
                            <Checkbox
                                onChange={() => { props.handleShowUnselectedAddon(!props.showUnSelectedAddon) }}
                                checked={showUnSelectedAddon}

                            />
                            Show Unselected({unSelectedAddonCount > 0 || isSelectAllAddonChecked === true ? unSelectedAddonCount : (props.totalCount > 0 ? props.totalCount : `00`)})

                        </Typography>
                    </Grid> :  <Grid style={{ padding: "0px 0px 0px 30px" }}></Grid>
            }
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 300px" }}>
                    Total Addons Found({props.totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}

export function EnhancedTableToolbar2(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 770px" }}>
                    Total Items Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function EnhancedTableToolbar3(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 760px" }}>
                    Total Items Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function EnhancedTableHeadAddon(props) {
    const classes = useStyles();
    const { handleAddonAllSelect, isSelectedAddonExist, totalCount } = props;


    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left' className={classes.headerPadding}>Addon Id</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Addon Name</TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell align='left' className={classes.headerPadding}>Food Type</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Quantity</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Unit</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Cost</TableCell>
                <TableCell align='left' className={classes.headerPadding}>
                    <Checkbox
                        indeterminate={isSelectedAddonExist > 0 && (isSelectedAddonExist < totalCount && isSelectedAddonExist !== totalCount)}
                        checked={isSelectedAddonExist ? isSelectedAddonExist : null}
                        onChange={handleAddonAllSelect}
                        inputProps={{ "aria-label": "select all desserts" }}
                        onClick={(event) => props.handleAddonSelectAllCount(event)}
                    />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
export function EnhancedTableHeadItem(props) {
    const classes = useStyles();

    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Id</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Name</TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell align='left' className={classes.headerPadding}>Food Type</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Quantity</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Unit</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Cost</TableCell>
                <TableCell style={{padding : '7px 0px 0px 0px'}}><Button onClick={()=>props.handleLinkAll()}><LinkAllIcon></LinkAllIcon></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function EnhancedTableHeadLinkedItem(props) {
    const classes = useStyles();

    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Id</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Name</TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell align='left' className={classes.headerPadding}>Food Type</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Quantity</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Unit</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Cost</TableCell>
                <TableCell style={{padding : '7px 0px 0px 0px'}}><Button onClick={()=>props.handleUnlinkAll()}><UnlinkAllIcon /></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}

export function AddonsTable(props) {
    let selectedAddonIds = props.selectedAddonToLink
    const classes = useStyles();

    const isAddonSelected = (_id) => {
        return props.selectedAddonToLink.indexOf(_id) !== -1;

    }

    const handleAddonClick = (event, _id) => {
        if (event.target.checked) {
            selectedAddonIds.push(_id);
        } else {
            selectedAddonIds = selectedAddonIds.filter((id) => id !== _id);
        }
        props.setSelectedAddonToLink(selectedAddonIds);
    }

    const handleAddonAllSelect = async (event) => {
        if (event.target.checked) {
            const allAddonIds = props.finalAddonRecords.map((n) => n._id);
            props.setSelectedAddonToLink(allAddonIds);
            return;
        }
        props.setSelectedAddonToLink([]);
    };


    return (
        <Container style={{ padding: '0px' }} >
            {props.finalAddonRecords && props.finalAddonRecords.length ?
                <Container>
                    <EnhancedTableToolbar
                        handleAddonAllSelect={handleAddonAllSelect}
                        isSelectedAddonExist={props.isSelectedAddonExist}
                        totalCount={props.filteredAddonRecords.length}
                        showUnSelectedAddon={props.showUnSelectedAddon}
                        showSelectedAddon={props.showSelectedAddon}
                        handleShowUnselectedAddon={props.handleShowUnselectedAddon}
                        handleShowSelectedAddon={props.handleShowSelectedAddon}
                        selectedAddonCount={props.selectedAddonCount}
                        unSelectedAddonCount={props.unSelectedAddonCount}
                        isSelectAllAddonChecked={props.isSelectAllAddonChecked}
                        handleAddonSelectAllCount={props.handleAddonSelectAllCount}
                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHeadAddon
                                    isSelectedAddonExist={props.isSelectedAddonExist}
                                    totalCount={props.filteredAddonRecords.length}
                                    handleAddonSelectAllCount={props.handleAddonSelectAllCount}
                                    handleAddonAllSelect={handleAddonAllSelect}
                                />
                                <TableBody>
                                    {props.filteredAddonRecords && props.filteredAddonRecords.length ? props.filteredAddonRecords.map((row, index) => {
                                        const selectedAddonRecord = isAddonSelected(row._id);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                               
                                                <TableCell align="center" style={{ padding: "0px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square"  style={{ height : '30px', width : '30px' }}  ></Avatar>
                                                        : <Avatar variant="square"  style={{ height : '30px', width : '30px' }}  src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 10px' }}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 10px' }} colSpan={4}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.foodType ? row.foodType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px', display: 'inlineSize!important' }}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell align='left'style={{ padding: '0px 0px 0px 8px', display: 'inlineSize!important' }} >
                                                    <Checkbox
                                                        onClick={(event) => handleAddonClick(event, row._id)}
                                                        checked={selectedAddonRecord}
                                                        aria-checked={selectedAddonRecord}
                                                        selected={selectedAddonRecord}
                                                        onChange={(event) => props.handleAddonSelectionCount(event, row._id)}
                                                        inputProps={{ "aria-labelledby": labelId }}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </Container>
    )


}


export function LinkedItemTable(props) {
    const classes = useStyles();


    return (
        <div>
            {props.selecteItemToLink && props.selecteItemToLink.length > 0 ?
                <Container>
                    <EnhancedTableToolbar3
                        totalCount={props.selecteItemToLink.length}

                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHeadLinkedItem
                                handleUnlinkAll={props.handleUnlinkAll}
                                />
                                <TableBody>
                                    { props.selecteItemToLink.length ? props.selecteItemToLink.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >

                                                <TableCell align="left" style={{ padding: "2px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square" style={{ height : '30px', width : '30px' }} ></Avatar>
                                                        : <Avatar variant="square"  style={{ height : '30px', width : '30px' }}   src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }} colSpan={4}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.foodType ? row.foodType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding : '0px'}}><Button onClick={()=>props.handleUnLinkItem(row._id)}><UnlinkIcon/></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noItemRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </div>
    )
}

export function ItemTable(props) {
    const classes = useStyles();


    return (
        <div>
            {props.filteredItemRecords && props.filteredItemRecords.length > 0 ?
                <Container>
                    <EnhancedTableToolbar2
                        totalCount={props.filteredItemRecords.length}

                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHeadItem
                                handleLinkAll={props.handleLinkAll}
                                />
                                <TableBody>
                                    {props.filteredItemRecords && props.filteredItemRecords.length ? props.filteredItemRecords.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >

                                                <TableCell align="left" style={{ padding: "2px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square"  style={{ height : '30px', width : '30px' }}  ></Avatar>
                                                        : <Avatar variant="square"  style={{ height : '30px', width : '30px' }}  src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }} colSpan={4}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.foodType ? row.foodType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding : '0px'}}><Button onClick={()=>props.handleLinkItem(row)}><LinkIcon/></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noItemRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </div>
    )
}
class LinkAddonToItem extends React.Component {
    constructor() {
        super();
        this.state = {

        };

    }

    componentDidMount() {

    };

    render() {
        const { classes } = this.props;
        let foundResults = (this.props.finalAddonRecords && this.props.finalAddonRecords.length) || (this.props.filteredItemRecords && this.props.filteredItemRecords.length) || (this.props.selecteItemToLink && this.props.selecteItemToLink.length)
        return (
           
            <div className={ foundResults > 0 ? classes.form : classes.dynamicForm}>
                 <Grid container className={ foundResults > 0 ? classes.dynamicHeader : classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom className={classes.headerText}>
                            Link Addons to Items
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.handleLinkAddonClose() }} />
                    </Grid>
                </Grid>
                <Container className={foundResults > 0 ? classes.dynamicCellPadding : classes.cellPadding}>
                    <Container className={classes.topcontainer}>
                        <Typography variant="h5" gutterBottom className={classes.addonHeader}>
                            Addons
                        </Typography>
                        <Container style={{ display: "flex!important", padding: "0px" }}>
                            <TextField label="Addon Id | Addon Name" margin="dense" variant="outlined" name="addonIdToLink" value={this.props.addonIdToLink} onChange={this.props.handleLinkAddonsToItemChange}
                                placeholder="Addon Id | Addon Name" style={{ width: "80%" }} error={(this.props.onLinkingAddonSubmitFlag === true) ? true : false} helperText={(this.props.onLinkingAddonSubmitFlag === true) ? "Please Select Addons To Link Item" : null} />
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                onClick={this.props.onAddonToLinkSearch}
                                color='primary'>
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                onClick={this.props.resetSearchedAddon}
                                color='prdefaultmary'>
                                Reset
                            </Button>
                        </Container>

                        <AddonsTable
                            finalAddonRecords={this.props.finalAddonRecords}
                            filteredAddonRecords={this.props.filteredAddonRecords}
                            setSelectedAddonToLink={this.props.setSelectedAddonToLink}
                            selectedAddonToLink={this.props.selectedAddonToLink}
                            isSelectedAddonExist={this.props.isSelectedAddonExist}
                            showUnSelectedAddon={this.props.showUnSelectedAddon}
                            showSelectedAddon={this.props.showSelectedAddon}
                            handleShowSelectedAddon={this.props.handleShowSelectedAddon}
                            handleShowUnselectedAddon={this.props.handleShowUnselectedAddon}
                            handleAddonSelectionCount={this.props.handleAddonSelectionCount}
                            selectedAddonCount={this.props.selectedAddonCount}
                            unSelectedAddonCount={this.props.unSelectedAddonCount}
                            isSelectAllAddonChecked={this.props.isSelectAllAddonChecked}
                            handleAddonSelectAllCount={this.props.handleAddonSelectAllCount}
                            noRecordsFound={this.props.noRecordsFound}

                        />

                    </Container>

                   

                    <Container className={this.props.finalAddonRecords && this.props.finalAddonRecords.length > 0 ? classes.bottomcontainer : classes.bottomcontainerDynamic}> 
                    <Container className={classes.cellPadding}> 
                    <LinkedItemTable 
                       filteredItemRecords={this.props.filteredItemRecords}
                       handleUnLinkItem={this.props.handleUnLinkItem}
                       selecteItemToLink={this.props.selecteItemToLink}
                       handleUnlinkAll={this.props.handleUnlinkAll}
                     
                    />
                    </Container>
                    <hr></hr>

                        <Typography variant="h5" gutterBottom className={classes.addonHeader}>
                            Items and Variation Groups
                        </Typography>
                        <Container style={{ display: "flex!important", padding: "0px" }}>
                            <TextField label="Item Name | ID" margin="dense" variant="outlined" name='itemIdToLink' value={this.props.itemIdToLink} onChange={this.props.handleLinkAddonsToItemChange} placeholder="Item Name | ID" style={{ width: "40%" }} error={(this.props.onLinkingItemSubmitFlag === true) ? true : false} helperText={(this.props.onLinkingItemSubmitFlag === true) ? "Please Select Items To Link Addon" : null} />
                            <TextField label="Group Name| ID" margin="dense" variant="outlined" name='groupIdToLink' value={this.props.groupIdToLink} onChange={this.props.handleLinkAddonsToItemChange} placeholder="Group Name| ID" style={{ width: "40%", marginLeft: "10px" }} />
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                onClick={this.props.onItemToLinkSearch}
                                color='primary'>
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px" }}
                                onClick={this.props.resetSearchedItem}
                                color='prdefaultmary'>
                                Reset
                            </Button>
                        </Container>
                        <ItemTable
                            filteredItemRecords={this.props.filteredItemRecords}
                            handleLinkItem={this.props.handleLinkItem}
                            handleLinkAll={this.props.handleLinkAll}
                            noItemRecordsFound={this.props.noItemRecordsFound}


                        />
                        <hr></hr>
                        <Container className={classes.footer}>
                            <Button
                                type="submit"
                                variant="contained"
                                margin="normal"
                                onClick={() => { this.props.onLinkAddonToItemSubmit() }}
                                style={{ maxWidth: "60px", float: "right", margin: "1px 0px 5px 0px" }}
                                color="primary">
                                SAVE
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.handleLinkAddonClose() }}
                                style={{ maxWidth: "60px", float: "right", margin: "1px 10px 5px 0px" }}
                            >
                                CANCEL
                            </Button>
                        </Container>
                    </Container>
                </Container>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {

    };
};
export default connect(mapStateToProps, {

})(withStyles(styles)(LinkAddonToItem));