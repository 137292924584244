import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import { setReportedBusinessDetails, showRpd } from "../../../actions/businessActions";
import { connect } from "react-redux";
import { withRouter } from 'react-router';

const styles = theme => ({
    table: {
      minWidth: 650,
    },
    head: {
      fontWeight: 700
    },
    tableRow: {
      cursor: 'pointer' 
    }
});

class Rbtable extends Component {

  handleClick = (busObj) => {
    this.props.setReportedBusinessDetails(busObj);
    this.props.showRpd(true);
  }

  render() {
    const { classes, onChangePage } = this.props;

    // let cnt = 1;
    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Business Mobile</TableCell>
                  <TableCell>Consumer Name</TableCell>
                  <TableCell>Consumer Mobile</TableCell>
                  <TableCell>Reported Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.business.busRptList.map(busObj => {
                  let dateObj = new Date(busObj.dateOfReporting);  
                  return (
                    <TableRow className={classes.tableRow} hover key={busObj._id} button={true} onClick={(e) => this.handleClick(busObj, e)}>
                      <TableCell component="th" scope="row">
                        {busObj.businessId.businessName}
                      </TableCell>
                      <TableCell>{busObj.businessId.primaryContactNumber}</TableCell>
                      <TableCell>{busObj.reportedBy.firstName + " " + busObj.reportedBy.lastName}</TableCell>
                      <TableCell>{busObj.reportedBy.primaryMobileNumber}</TableCell>
                      <TableCell>{dateObj.toLocaleDateString("en-US",
                       {weekday: "long", year: "numeric", month: "short",day: "numeric" })}</TableCell>
                  </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={this.props.business.busRptListCount}
                    rowsPerPage={this.props.business.limit}
                    page={this.props.business.page}
                    onChangePage={onChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  }
};

export default  withRouter(connect(mapStateToProps, { setReportedBusinessDetails, showRpd })(withStyles(styles)(Rbtable)));
