import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { ReactComponent as Collapsed } from '../../../images/collapsed.svg';
import { ReactComponent as Expanded } from '../../../images/expanded.svg';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import Radio from '@material-ui/core/Radio';
import { ReactComponent as UNLINK } from '../../../images/Unlink.svg';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "5px"
  },
  paper: {
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    cursor: 'pointer',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  headerBackground: {
    backgroundColor: "#fafafa"
  },
  rowBackground: {
    backgroundColor: "#fafafa",
    overflowX: "auto"
  },


}));

function EnhancedTableHead(props) {
  const { classes, onGroupSelectAll  , rowsPerPage , isSelectedExist } = props;

  return (
    <TableHead className={classes.headerBackground}>
      <TableRow>

        {props.role === "areaManager" ?
          <TableCell padding="checkbox"> 
          <Checkbox 
           indeterminate={isSelectedExist > 0 && (isSelectedExist < rowsPerPage && isSelectedExist !== rowsPerPage)}
           checked={isSelectedExist ? isSelectedExist : null}
           onChange={onGroupSelectAll}
           inputProps={{ "aria-label": "select all desserts" }}
          />   
        </TableCell> : <TableCell>  </TableCell>}
        <TableCell></TableCell>
        <TableCell>Variation Group Id</TableCell>
        <TableCell>Variation Group Name</TableCell>
        <TableCell></TableCell>
        <TableCell>Item Count</TableCell>
        <TableCell>GST</TableCell>
        <TableCell>Food Type</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {props.role === "areaManager" ? <TableCell></TableCell> : <TableCell></TableCell>}
        {props.role === "areaManager" ? <TableCell></TableCell> : <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  return (
    <Toolbar style={{ paddingLeft: "16px" }}>
      <Typography
        className={classes.headerBackground}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Variation Group ({props.totalCount})
      </Typography>
    </Toolbar>
  );
};



export default function EnhancedTable(props) {
  const classes = useStyles();

  let selectedGroupIds = props.selectedGroupToDelete

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };
 
  const isGroupSelected = (_id) => {
    return props.selectedGroupToDelete.indexOf(_id) !== -1;
};

  const handleOneGroupClick = (event, _id) => {
    if (event.target.checked) {
      selectedGroupIds.push(_id);
    } else {
      selectedGroupIds = selectedGroupIds.filter((id) => id !== _id);
    }
    props.setSelectedGroupDelete(selectedGroupIds);
  }

  const handleGroupSelectAll = async (event) => {
    if (event.target.checked) {
        const allGroupIds = props.data.map((n) => n._id);
        props.setSelectedGroupDelete(allGroupIds);
        return;
    }
    props.setSelectedGroupDelete([]);
};

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          totalCount={props.totalCount}
        />

        {props.data && props.data.length > 0 ?
          <TableContainer>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                classes={classes}
                order={props.order}
                orderBy={props.orderBy}
                rowCount={props.totalCount}
                role={props.role}
                onGroupSelectAll={handleGroupSelectAll}
                totalCount={props.totalCount}
                isSelectedExist={props.selectedGroupToDelete.length}
                selectedGroupToDelete={props.selectedGroupToDelete}
                rowsPerPage={props.rowsPerPage}
              />
              <TableBody>
                {props.data.map((row, index) => {
                   const recordToDeleteSelected = isGroupSelected(row._id);
                  //  console.log(`------- recordToDeleteSelected>`, recordToDeleteSelected);
                   const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <>
                      <TableRow key={row._id} className={classes.rowBackground} >
                        {props.role === "areaManager" ?
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(event) => handleOneGroupClick(event, row._id)}
                              checked={recordToDeleteSelected}
                              aria-checked={recordToDeleteSelected}
                              selected={recordToDeleteSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell> : <TableCell></TableCell>}
                        <TableCell style={{ padding: "8px" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => props.handleOpen(props.open === index ? -1 : index)}
                          >
                            {props.open === index ? (
                              <Expanded />
                            ) : (
                              <Collapsed />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell  align='left' style={{ paddingLeft: "20px" }}> {row.variationGroupId ? row.variationGroupId : "NA"}</TableCell>
                        <TableCell  colspan={2}>{row.variationGroupName ? row.variationGroupName : "NA"}</TableCell>
                        <TableCell  align='left'>{row.variations.length ? row.variations.length : "0"}</TableCell>
                        <TableCell>{ row.GST && row.GST.gst !== null  ? `${row.GST.gst}%` : "NA"}</TableCell>
                        <TableCell  align='left'>{row.foodType ? row.foodType : "NA"}</TableCell>

                        <TableCell align="left" style={{ padding: "6px 6px 6px 6px " }} >
                          {props.role === "areaManager" ?
                            <FormControlLabel
                              control={
                                <Switch
                                  size="small"
                                  checked={row.isAvailable}
                                  onChange={(e) => props.handleGroupSwitchChange(e, row)}
                                  name="isAvailable"
                                  color="primary"
                                />
                              }
                              style={{ fontSize: "0.87rem", color: row.isAvailable ? 'black' : 'red' }}
                              label={row.isAvailable ? 'Available' : 'Unavailable'}
                            />
                            :
                            row.isAvailable ? 'Available' : 'Unavailable'}
                        </TableCell>



                        <TableCell align="left" style={{ padding: "7px" }} >
                          {props.role === "areaManager" ?
                            <FormControlLabel
                              control={
                                <Switch
                                  size="small"
                                  checked={row.isEnabled}
                                  onChange={(e) => props.handleGroupSwitchChange(e, row)}
                                  name="isEnabled"
                                  color="primary"
                                />
                              }
                              style={{ fontSize: "0.87rem", color: row.isEnabled ? 'black' : 'red' }}
                              label={row.isEnabled ? 'Enabled' : 'Disabled'}
                            />
                            :
                            row.isEnabled ? 'Enabled' : 'Disabled'}
                        </TableCell>

                        <TableCell align="left" style={{ padding: "7px" }} >
                          {props.role === "areaManager" ?
                            <FormControlLabel
                              control={
                                <Switch
                                  size="small"
                                  checked={row.isDisplay}
                                  onChange={(e) => props.handleGroupSwitchChange(e, row)}
                                  name="isDisplay"
                                  color="primary"
                                />
                              }
                              style={{ fontSize: "0.87rem", color: row.isDisplay ? 'black' : 'red' }}
                              label={row.isDisplay ? 'Display' : 'Hide'}
                            />
                            :
                            row.isDisplay ? 'Display' : 'Hide'}
                        </TableCell>

                        {props.role === "areaManager" ?

                          <TableCell align="left" style={{ padding: "6px" }}>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => props.handleAddVariationModel(row)}

                            >
                              <Typography style={{ fontSize: "12px", padding: "2px" }}>ADD</Typography>
                              <Typography style={{ fontSize: "12px" }}>VARIATION</Typography>

                            </Button>
                          </TableCell> : <TableCell></TableCell>}

                        {props.role === "areaManager" ?

                          <TableCell align="left" style={{ padding: "2px" }}>
                            <IconButton
                              style={{
                                float: "left",
                                size: "small",
                                padding: "0px"
                              }}
                              onClick={() => props.handleEditVariationGroup(row)}
                            >
                              <Avatar className={classes.lightBlue} >
                                <EditIcon color="action" />
                              </Avatar>
                            </IconButton>
                          </TableCell>
                          : <TableCell></TableCell>}

                      </TableRow>

                      <TableRow >

                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={14}>

                          <Collapse in={props.open === index} timeout="auto" unmountOnExit>
                            <Box
                              style={{
                                minHeight: 96,
                                textAlign: 'center',
                                fontSize: 12,
                                borderRadius: "10px",
                                border: "1px solid gray",
                                margin: "5px 0px 5px 5px",
                           

                              }}
                              className={classes.rowBackground}
                            >

                              {row.variations && row.variations.length > 0 ?
                                <Table size="small" aria-label="a dense table" style={{ overflow: "auto" }}>
                                  <TableHead>
                                    <TableRow>
                                      {props.role === "areaManager" ? <TableCell align="left">Default</TableCell> : <TableCell></TableCell>}
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">Item Id</TableCell>
                                      <TableCell align="left" >Item Name</TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left"></TableCell>
                                      <TableCell align="left">Cost</TableCell>
                                      <TableCell align="left">Linked Addon</TableCell>
                                      <TableCell align="left" style={{ paddingLeft : '0px'}}>Quantity</TableCell>
                                      <TableCell align="left" style={{ paddingLeft : '0px'}}>Unit</TableCell>
                                      <TableCell align="left">Availability</TableCell>
                                      <TableCell align="left">Is Enabled</TableCell>
                                      <TableCell align="left">Hide/Display</TableCell>
                                      <TableCell align="left">Item Tag</TableCell>
                                      {props.role === "areaManager" ? <TableCell align="left">Unlink</TableCell> : <TableCell></TableCell>}

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row.variations && row.variations.length && row.variations.map((value, index) => {

                                    let itemTags = value.excludeItemTags === true ? "NA" : ( value.mustTry === true ? "Must Try" : ( value.newArrival === true ? "New" : ( value.bestSeller ? "Best Seller" : "NA" ) ) );
                                  

                                      return (
                                        <TableRow key={value._id} >
                                          {props.role === "areaManager" ? <TableCell align="left" style={{ padding: "0px 2px 0px 20px" }}>
                                            <FormControlLabel name="isDefaultVariationView" checked={value.isDefaultVariationView} onChange={(e) => props.handleFlagChange(e, value, row)} value={value.isDefaultVariationView} control={<Radio color="primary" />} />
                                          </TableCell> : <TableCell></TableCell>}
                                          <TableCell align="left" style={{ padding: "0px 4px 0px 4px" }}>
                                            {value.photos && value.photos[0] && value.photos[0].path ?
                                              <Avatar src={value.photos[0].path} variant="square" style={{ padding: "0px" }}></Avatar>
                                              : <Avatar variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                          </TableCell>
                                          <TableCell align="left" style={{ padding: "0px 20px 0px 12px" }}>{value.serviceId ? value.serviceId : "NA"}</TableCell>
                                          <TableCell align="left" style={{ padding: "0px 20px 0px 12px" }} colSpan={4}> {value.service ? value.service : "NA"}</TableCell>
                                          <TableCell align="left" style={{ padding: "0px 20px 0px 14px" }}> {value.cost?.fixedCost ? value.cost?.fixedCost : "NA"}</TableCell>
                                           <TableCell align="left">{value.addOnChildServiceItems && value.addOnChildServiceItems.length ?
                                            <Container style={{ display: 'flex' ,  padding : '0px 0px 0px 1px' }}>
                                                <Typography style={{  fontSize: "0.875rem" ,marginRight : "15px" ,display : 'inlineSize!important' }}>Yes</Typography>
                                                <Button onClick={() => { props.handleLinkedAddonOpen(value._id) }} style={{ padding: "2px", borderRadius: "25px" }} variant="contained" margin="normal" color="primary">{value.addOnChildServiceItems.length}</Button>
                                            </Container> :
                                            <Container style={{ display: 'flex'  ,  padding : '0px 0px 0px 1px'}}><Typography style={{  fontSize: "0.875rem" ,marginRight : "17px" ,display : 'inlineSize!important'}}>No</Typography><Button style={{ padding: "2px", borderRadius: "25px" }} variant="contained" margin="normal" color="default">00</Button></Container>}
                                        </TableCell>
                                        <TableCell align="left"  style={{padding : '0px'}}>{value.cost?.quantity ? value.cost?.quantity : "NA"}</TableCell>
              
                                          <TableCell align="left" style={{ padding: "0px 5px 0px 5px" }}> {value.cost?.unit ? value.cost?.unit : "NA"}</TableCell>
                                          <TableCell align="center" style={{ padding: "0px 5px 0px 20px" }}>
                                            {props.role === "areaManager" ?
                                              <FormControlLabel
                                                control={
                                                  <Switch
                                                    size="small"
                                                    checked={value.availability.isActive}
                                                    onChange={(e) => props.handleFlagChange(e, value, row)}
                                                    name="availability"
                                                    color="primary"
                                                  />
                                                }
                                                style={{ font: "0.87rem", color: value.availability.isActive ? 'black' : 'red' }}
                                                label={value.availability.isActive ? 'Available' : 'Unavailable'}
                                              />
                                              :
                                              value.availability.isActive ? 'Available' : 'Unavailable'}
                                          </TableCell>
                                        <TableCell align="center" style={{ padding: "0px 5px 0px 20px" }}>
                                            {props.role === "areaManager" ?
                                              <FormControlLabel
                                                control={
                                                  <Switch
                                                    size="small"
                                                    checked={value.isEnabled}
                                                    onChange={(e) => props.handleFlagChange(e, value, row)}
                                                    name="isEnabled"
                                                    color="primary"
                                                  />
                                                }
                                                style={{ font: "0.87rem", color: value.isEnabled ? 'black' : 'red' }}
                                                label={value.isEnabled ? 'Yes' : 'No'}
                                              />
                                              : value.isEnabled ? 'Yes' : 'No'}

                                          </TableCell>
                                          <TableCell align="center" style={{ padding: "0px 5px 0px 20px" }}>
                                            {props.role === "areaManager" ?
                                              <FormControlLabel
                                                control={
                                                  <Switch
                                                    size="small"
                                                    checked={value.isDisplay}
                                                    onChange={(e) => props.handleFlagChange(e, value, row)}
                                                    name="isDisplay"
                                                    color="primary"
                                                  />
                                                }
                                                style={{ font: "0.87rem", color: value.isDisplay ? 'black' : 'red' }}
                                                label={value.isDisplay ? 'Display' : 'Hide'}
                                              />
                                              : value.isDisplay ? 'Display' : 'Hide'}

                                          </TableCell>
                                         
                                          <TableCell  align='left' style={{ paddingLeft: "0px 0px 0px 12px" }}>{itemTags}</TableCell>
                                          {props.role === "areaManager" ? <TableCell style={{ paddingLeft: "0px 0px 0px 12px" }}>
                                            <IconButton
                                              style={{
                                                float: "center",
                                                padding: "0px",
                                                background: "none"

                                              }}
                                             onClick={() => { props.handleUnlinkVariationOpen(value , row) }}
                                            >
                                              <Avatar style={{
                                                background: "none"

                                              }}>
                                                <UNLINK />
                                              </Avatar>
                                            </IconButton>
                                          </TableCell> : <TableCell></TableCell>}
                                        </TableRow>
                                      )
                                    })}
                                  </TableBody>
                                </Table>

                                : <Typography style={{ margin: "20px" }}>Please Add Variation To Group</Typography>}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
          : <Typography style={{ margin: "0px 400px" }}>No Records Available</Typography>}
        <TablePagination
          rowsPerPageOptions={[5, 10 ,20 ,30]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
