import React from "react";
import { Component } from "react";
import Typography from '@material-ui/core/Typography';
import { Container } from "@material-ui/core";


class CountdownTimer extends Component {
state = {
     minutes: '00',
     seconds: '00',
     timeUp: false
}
componentDidMount() {
   setInterval(() => {
      let eventDate = new Date(this.props.date);
      let difference = eventDate - new Date();
if (difference < 1) {
         this.setState({ timeUp: true });
      } else {
         let minutes = Math.floor((difference / (1000 * 60)) % 60);
         let seconds = Math.floor((difference / (1000)) % 60);
         this.setState({
            minutes: minutes > 9 ? minutes : `0${minutes}`,
            seconds: seconds > 9 ? seconds : `0${seconds}`,
         });
      }
   }, 1000)
}
render() {
     const {minutes, seconds} = this.state;
return (
         <Container style={{ 
         display:'flex',padding:'4px',paddingTop:"0px"}}>
       <Typography style={{  width : "fit-content",
         border : "1px solid gray",
         height : "fit-content",
         // padding : "2px 6px 2px",
         borderRadius : "4px",
         fontSize : "15px",
         fontWeight:"400",
         marginRight: "1px",
         marginLeft: "1px",
         display:'flex',backgroundColor:"orange",color:"black"}} >
         { `${minutes} `}
         </Typography>
         <Typography style={{ paddingRight:"1px",paddingLeft:"1px",color:"black"}}>:</Typography>
         <Typography style={{  width : "fit-content",
         border : "1px solid gray",
         height : "fit-content",
         // padding : "2px 6px 2px",
         borderRadius : "4px",
         fontSize : "15px",
         fontWeight:"400",
         marginRight: "1px",
         marginLeft: "1px",
         display:'flex',backgroundColor:"orange",color:"black"}} >{ `${seconds} `}</Typography>
         </Container>
     );
   }
}
export default CountdownTimer;

