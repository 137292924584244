import {

    GET_ADDONLIST_AG,
    CREATE_ADDON_GROUP_SUCCESS,
    CREATE_ADDON_GROUP_ERROR,
    GET_ALL_ADDON_GROUP_RECORDS,
    UPDATE_ADDON_GROUP_RECORD_SUCCESS,
    UPDATE_ADDON_GROUP_RECORD_ERROR,
    REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL,
    ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED,  
    RESTORE_ADDONS_RECORDS_SUCCESS,
    RESTORE_ADDONS_RECORDS_ERROR,
    ADDON_GROUP_DELETION_SUCCESS,
    ADDON_GROUP_DELETION_ERROR,
    ADDON_GROUP_RECORDS_TOLINK,
    LINK_ADDON_GROUP_TOITEM_SUCCESS,
    LINK_ADDON_GROUP_TOITEM_ERROR,
    UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS,
    UNLINK_ADDON_GROUP_FROM_ITEM_ERROR,
    GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM,
    ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE,
    ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR
    

} from "../actions/types";

const INITIAL_STATE = {
    sucessMsg: null,
    errorMsg: null,
    isFetching: false,
    addonList : [],
    addonGroupRecords : [],
    addonGroupToLink : [],
    groupsUpdateItem : [],
    ops: "",
    page: 0,
    skip: 0,
    limit: 10,
};

export default function addonGroupReducer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_ADDONLIST_AG:
            return {
                ...state,
                addonList: action.payload,

            };
        case CREATE_ADDON_GROUP_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case CREATE_ADDON_GROUP_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_ALL_ADDON_GROUP_RECORDS:
            return {
                ...state,
                addonGroupRecords: action.payload,

            };
        case UPDATE_ADDON_GROUP_RECORD_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UPDATE_ADDON_GROUP_RECORD_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL:
                return { ...state, addonsDownloadToExcel : action.payload, isFetching: true };
        case ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED:
                return { ...state, addonsDownloadToExcel : action.payload, isFetching: false };     
        case RESTORE_ADDONS_RECORDS_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case RESTORE_ADDONS_RECORDS_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case ADDON_GROUP_DELETION_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case ADDON_GROUP_DELETION_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case ADDON_GROUP_RECORDS_TOLINK:
                return {
                    ...state,
                    addonGroupToLink: action.payload,
    
            };
        case LINK_ADDON_GROUP_TOITEM_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case LINK_ADDON_GROUP_TOITEM_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case UNLINK_ADDON_GROUP_FROM_ITEM_ERROR:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM:
                return {
                    ...state,
                    groupsUpdateItem: action.payload,
    
         };
            case ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE:
                return {
                    ...state,
                    sucessMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
                };
            case ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR:            
                return {
                    ...state,
                    errorMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
    
                };

        default:
            return state;
    }
}