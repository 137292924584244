import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ActionPendingList from './ActionPendingList';
import ActionTakenList from './ActionTakenList';
import TabPanel from '../../utils/TabPanel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ReportedBusinessDetails from "./ReportedBusinessDetails";  
import { showRpd } from "../../../actions/businessActions";
import { changeHeaderName } from "../../../actions/dashboardActions";

const styles = theme => ({
    content: {
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
});


class ReportedBusiness extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
        };
    }
    
    componentDidMount() {
        /* 
         * Get the reported business where Action is pending
         */
        this.props.changeHeaderName("Reported Issues");
        this.props.showRpd(false);
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
        this.props.showRpd(false);
    };

    render() {
      const { classes } = this.props;
      
      return (
        <div className={classes.content}>
             <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <AppBar position="static">
                        <Tabs value={this.state.value} onChange={this.handleChangeTab}  aria-label="simple tabs example">
                            <Tab label="Pending"/>
                            <Tab label="Resolved"/>
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.value} index={0}>
                        {this.props.business.showRpd ? <ReportedBusinessDetails pending /> : <ActionPendingList /> }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        {this.props.business.showRpd ? <ReportedBusinessDetails resolved /> : <ActionTakenList /> }
                    </TabPanel>
                </Container>
        </div>
      );
    }
  }
  
  const mapStateToProps = state => {
    return {
        business: state.business,
    }
  };

  export default connect( mapStateToProps, { showRpd, changeHeaderName })(withStyles(styles)(ReportedBusiness));