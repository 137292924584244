import axios from "axios";

import {
    GET_DELIVERIES,
    SETTLEMENT_CRON_RUN,
    COUPON_SETTLEMENT_CRON_RUN,
    GET_SETTLEMENT_RECORDS,
    SET_SETTLEMENT,
    SET_SETTLEMENT_NOTES,
    GET_SETTLEMENT_NOTES,
    REQUEST_DELIVERYLOADER,
    REQUEST_DELIVERYLOADER_FALSE,
    GET_DELIVERIES_EXPORT,
    GET_SERVICEDELIVERY_PARTNER
} from "./types";




export const requestDelivery = () => {
    return {
      type: REQUEST_DELIVERYLOADER,
      payload: true,
    };
  };
 
  export const requestDeliveryFalse = () => {
	return {
		type: REQUEST_DELIVERYLOADER_FALSE,
		payload: false
	};
};
// Get the list of deliveries
export const clearMsg = () => async (dispatch) => {
    let data = {};
    data.msg = null;

    dispatch({ type: SETTLEMENT_CRON_RUN, payload: data.msg });
    dispatch({ type: COUPON_SETTLEMENT_CRON_RUN, payload: data.msg });
};

export const servicePartnerDeliveries = (payload) => async dispatch => {
    const response = await axios.post("/api/servicepartnerdeliveries/search", Object.assign({}, payload))
    // console.log(response)
    dispatch({ type: GET_DELIVERIES, payload: response.data });
};
export const  exportServicePartnerDeliveries = (payload) => async dispatch => {
    const response = await axios.post("/api/servicepartnerdeliveries/exportDeliveryReport", payload)
    // console.log(`IN THE DELIVERY REPORT ACTION - - -- - - ->`,response.data.exportToExcel)
    dispatch({ type: GET_DELIVERIES_EXPORT, payload: response.data.exportToExcel });
};

export const settlementCronJob = (payload) => async (dispatch) => {
    try {
        const response = await axios.post(`/api/deliverypartnersettlement/settlementCronJob`, payload);
        dispatch({
            type: SETTLEMENT_CRON_RUN,
            payload: response.data.message
        });
    } catch (err) {
        dispatch({ type: SETTLEMENT_CRON_RUN, payload: err });
    }

};

export const couponSettlementCronJob = (payload) => async (dispatch) => {
    try {
        // console.log("payload---->",payload);
        const response = await axios.post(`/api/couponsettlement`, payload);
        // console.log(response);
        dispatch({
            type: COUPON_SETTLEMENT_CRON_RUN,
            payload: response.data.message
        });
    } catch (err) {
        dispatch({ type: COUPON_SETTLEMENT_CRON_RUN, payload: err });
    }

};

export const getSettlementsRecords = (payload) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/deliverypartnersettlement/getSettlements/${payload.businessid}`, {
            params: payload.data
        });
        dispatch({
            type: GET_SETTLEMENT_RECORDS,
            payload: response.data
        });
    } catch (err) {
        dispatch({ type: GET_SETTLEMENT_RECORDS, payload: {} });
    }

};

export const settlementById = (payload) => async (dispatch) => {
    try {
        const response = await axios.patch(`/api/deliverypartnersettlement/settlementDoneById `, payload);
        dispatch({
            type: SET_SETTLEMENT,
            payload: response.msg
        });
    } catch (err) {
        dispatch({ type: SET_SETTLEMENT, payload: err });
    }

};


export const addSettlementNotes = (payload) => async (dispatch) => {
    try {
        const response = await axios.patch(`/api/deliverypartnersettlement/saveSettlementNotes`, payload);
        dispatch({
            type: SET_SETTLEMENT_NOTES,
            payload: response.msg
        });
    } catch (err) {
        dispatch({ type: SET_SETTLEMENT_NOTES, payload: err });
    }

};
export const getSettlementsNotes = (payload) => async (dispatch) => {
    try {
        const response = await axios.get(`/api/deliverypartnersettlement/getSettlementNotesBySettlementId/${payload.settlementId}`);
        dispatch({
            type: GET_SETTLEMENT_NOTES,
            payload: response.data.settlementNotes
        });
    } catch (err) {
        dispatch({ type: GET_SETTLEMENT_NOTES, payload: {} });
    }

};
export const clearNotesRecord = () => async (dispatch) => {
    dispatch({
        type: GET_SETTLEMENT_NOTES,
        payload: []
    });
};

export const getServiceDeliveryPartner = (payload)=> async(dispatch)=>{
    try {
        let response = await axios.get("/api/business/" + payload.id  +"/getServiceDeliveryPriority");
        dispatch({ type: GET_SERVICEDELIVERY_PARTNER, payload: response.data });
    } catch (error) {
        dispatch({ type: GET_SERVICEDELIVERY_PARTNER, payload: error });
    }
} 
