import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";

function ConfirmationDialogComponent({
  handleDialogClose,
  handleDialogYesClick,
  handleDialogNoClick,
  isOpenDialog,
  dialogTitle,
  dialogContent,
  noButtonText,
  yesButtonText,
}) {
  return (
    <Container>
      <Dialog
        open={isOpenDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {handleDialogYesClick && (
            <Button variant="outlined" onClick={handleDialogYesClick}>
              {yesButtonText || "Yes"}
            </Button>
          )}
          {handleDialogNoClick && (
            <Button variant="outlined" onClick={handleDialogNoClick} autoFocus>
              {noButtonText || "No"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ConfirmationDialogComponent;
