import {
    SET_COUPON_LIST,
    REQUEST_COUPON_STATS,
    COUPON_UPDATE_SUCCESS,
    COUPON_UPDATE_ERR,
    REMOVE_COUPON,
    GET_ALL_COUPONS,
    SET_COUPON_SUCCESS_MESSAGE,
    SET_COUPON_ERROR_MESSAGE,
    CHANGE_COUPON_STATUS,
   SET_COUPON_STATUS_ERROR_MESSAGE
} from '../actions/types'

const INITIAL_STATE = {
    couponList: [],
    couponListCount: 0,
    page: 0,
    skip: 0,
    limit: 10,
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false,
    exportToExcel: [],

};

export default function couponReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_COUPON_LIST:
            return {
                ...state,
                couponList: action.payload.data,
                couponListCount: action.payload.total,
            };
        case GET_ALL_COUPONS:
            return {
                ...state,
                exportToExcel: action.payload
            }
        case REQUEST_COUPON_STATS:
            return { ...state, isFetching: action.payload };
        case COUPON_UPDATE_SUCCESS:
            return { ...state, couponStatusUpdateSuccess: action.payload };
        case COUPON_UPDATE_ERR:
            return { ...state, couponStatusUpdateErr: action.payload };
        case REMOVE_COUPON:
            return {
                ...state,
                coupon: state.couponList.filter(c => c._id !== action.payload.id)
            };
        case SET_COUPON_SUCCESS_MESSAGE:
                return {
                    ...state,
                    sucessMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
                };
        case SET_COUPON_ERROR_MESSAGE:
                return {
                    ...state,
                    errorMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
                };
        case CHANGE_COUPON_STATUS:
                return {
                    ...state,
                    sucessMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
                };
        case SET_COUPON_STATUS_ERROR_MESSAGE:
                return {
                    ...state,
                    errorMsg: action.payload.msg,
                    ops: action.payload.ops,
                    isFetching: false
                };
        default:
            return state;
    }
}