export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const SET_ENV = "SET_ENV";
export const FETCH_BUSINESS_AWAITING_APPROVAL = "FETCH_BUSINESS_AWAITING_APPROVAL";
export const FETCH_BUSINESS_APPROVED = "FETCH_BUSINESS_APPROVED";
export const FETCH_REGIONS_RECORDS = "FETCH_REGIONS_RECORDS";
export const FETCH_USERS_RECORDS = "FETCH_USERS_RECORDS";
export const FETCH_BUSINESS_APPROVED_CAMPAIGN = "FETCH_BUSINESS_APPROVED_CAMPAIGN";
export const FETCH_BUSINESS_REJECTED = "FETCH_BUSINESS_REJECTED";
export const FETCH_BUSINESS_DISABLED = "FETCH_BUSINESS_DISABLED";
export const FETCH_REPORTED_BUSINESS = "FETCH_REPORTED_BUSINESS";
export const SHOW_PENDING_BUSINESS_DETAILS = "SHOW_PENDING_BUSINESS_DETAILS";
export const SHOW_APPROVED_BUSINESS_DETAILS = "SHOW_APPROVED_BUSINESS_DETAILS";
export const SET_REPORTED_BUSINESS_DETAILS = "SET_REPORTED_BUSINESS_DETAILS";
export const SHOW_REPORTED_BUSINESS_DETAILS = "SHOW_REPORTED_BUSINESS_DETAILS";
export const PATCH_REPORTED_SUCCESS_MESSAGE = "PATCH_REPORTED_SUCCESS_MESSAGE";
export const PATCH_REPORTED_ERROR_MESSAGE = "PATCH_REPORTED_ERROR_MESSAGE";
export const OPEN_BUSINESS_MODAL = "OPEN_BUSINESS_MODAL"
export const CLOSE_BUSINESS_MODAL = "CLOSE_BUSINESS_MODAL"
export const SET_PAN_CARD = "SET_PAN_CARD"
export const SET_AADHAR_CARD = "SET_AADHAR_CARD"
export const UPDATE_PAN_CARD = "UPDATE_PAN_CARD"
export const UPDATE_AADHAR_CARD = "UPDATE_AADHAR_CARD"
export const SET_SHOP_ACT = "SET_SHOP_ACT"
export const SET_QR_CODE_IMAGE = "SET_QR_CODE_IMAGE"
export const BUSI_STATE_CHG_SUCCESS = "BUSI_STATE_CHG_SUCCESS"
export const BUSI_STATE_CHG_ERR = "BUSI_STATE_CHG_ERR"
export const SET_BUSINESS_STATS = "SET_BUSINESS_STATS"
export const REQUEST_STATS = "REQUEST_STATS"
export const SET_TOP_PERFORMING_BUSINESSES = "SET_TOP_PERFORMING_BUSINESSES"
export const REQUEST_BUSINESSES = "REQUEST_BUSINESSES"
export const SET_BUSINESS_REPORTS = "SET_BUSINESS_REPORTS"
export const SET_BUSINESS_PERFORMANCE_REPORTS = " SET_BUSINESS_PERFORMANCE_REPORTS"
export const BUSINESS_MEMBERS_DETAILS = "BUSINESS_MEMBERS_DETAILS"
export const BUSI_DET_UPDATE_SUCCESS = "BUSI_DET_UPDATE_SUCCESS"
export const BUSI_DET_UPDATE_ERR = "BUSI_DET_UPDATE_ERR"
export const BUSI_PERSONAL_DET_UPDATE_SUCCESS = "BUSI_PERSONAL_DET_UPDATE_SUCCESS"
export const BUSI_PERSONAL_DET_UPDATE_ERR = "BUSI_PERSONAL_DET_UPDATE_ERR"
export const UPDATE_SHOPACT_ERROR_MESSAGE = "UPDATE_SHOPACT_ERROR_MESSAGE"
export const UPDATE_SHOPACT_SUCCESS_MESSAGE = "UPDATE_SHOPACT_SUCCESS_MESSAGE"
export const FETCH_BUSINESS_EXCEL = "FETCH_BUSINESS_EXCEL"
export const SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE = "SET_ALL_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE";
export const SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE = "SET_ALL_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE";
export const EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE = "EDIT_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE";
export const EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE = "EDIT_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE";
export const DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE = "DELETE_BUSINESS_VIDEO_LINK_LIST_SUCCESS_MESSAGE";
export const DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE = "DELETE_BUSINESS_VIDEO_LINK_LIST_ERROR_MESSAGE";
export const AADHAR_CARD_VALUE = "AADHAR_CARD_VALUE";
export const PAN_CARD_VALUE = "PAN_CARD_VALUE";
export const SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE = "SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE";
export const SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE = "SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE";
export const GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION = "GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION";
export const REQUEST_APPROVED_BUSINESS_RECORDS = "REQUEST_APPROVED_BUSINESS_RECORDS";
export const REQUEST_APPROVED_BUSINESS_RECORDS_FALSE = "REQUEST_APPROVED_BUSINESS_RECORDS_FALSE";
export const REQUEST_BUSINESS_DETAILS_RECORDS = "REQUEST_BUSINESS_DETAILS_RECORDS";
export const REQUEST_BUSINESS_DETAILS_RECORDS_FALSE = "REQUEST_BUSINESS_DETAILS_RECORDS_FALSE";

export const REQUEST_VENDOR_SETTLEMENTS = "REQUEST_VENDOR_SETTLEMENTS";
export const GET_ALL_VENDOR_SETTLEMENT_DATA = "GET_ALL_VENDOR_SETTLEMENT_DATA";
export const REQUEST_VENDOR_SETTLEMENTS_RESET = "REQUEST_VENDOR_SETTLEMENTS_RESET";
export const DELETE_SUCCESS_MESSAGE = "DELETE_SUCCESS_MESSAGE";
export const DELETE_ERROR_MESSAGE = "DELETE_ERROR_MESSAGE";
export const ADD_VENDOR_SETTLEMENTS = "ADD_VENDOR_SETTLEMENTS";
export const REMOVE_DUPLICATE_VENDOR_SETTLEMENTS = "REMOVE_DUPLICATE_VENDOR_SETTLEMENTS";
export const REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE = "REMOVE_DUPLICATE_VENDOR_SETTLEMENTS_ERROR_MESSAGE";

export const  GET_BUSINESS_AGREEMENT_DETAILS = "GET_BUSINESS_AGREEMENT_DETAILS";
export const  GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID = "GET_BUSINESS_AGREEMENT_UPDATE_HISTORY_DETAILS_BY_BUSINESSID";
export const  ADD_BUSINESS_AGREEMENT = "ADD_BUSINESS_AGREEMENT";
export const  ADD_BUSINESS_AGREEMENT_ERROR = "ADD_BUSINESS_AGREEMENT_ERROR";
export const  REQUEST_BUSINESS_AGREEMENT = "REQUEST_BUSINESS_AGREEMENT";
export const  REQUEST_BUSINESS_AGREEMENT_RESET = "REQUEST_BUSINESS_AGREEMENT_RESET";
export const  GET_BUSINESS_AGREEMENT_DETAILS_PDF = "GET_BUSINESS_AGREEMENT_DETAILS_PDF";

export const CHANGE_HEADER_NAME = "CHANGE_HEADER_NAME"
export const SET_XIRIFY_STATS = "SET_XIRIFY_STATS"
export const SET_CONSUMER_LIST = "SET_CONSUMER_LIST"
export const SET_CONSUMER_LIST_WEBPUSHNOTIFICATION = "SET_CONSUMER_LIST_WEBPUSHNOTIFICATION"
export const SET_SP_LIST = "SET_SP_LIST"
export const SET_SP_DETAILS = "SET_SP_DETAILS"
export const SET_CONSUMER_LIST_EMPTY = "SET_CONSUMER_LIST_EMPTY"
export const SET_SELECTED_CONSUMER = "SET_SELECTED_CONSUMER"
export const GET_ALL_OPEN_CLOSE_STATUSES = "GET_ALL_OPEN_CLOSE_STATUSES"
export const GET_ALL_OPEN_CLOSE_EXPORT_STATUSES = "GET_ALL_OPEN_CLOSE_EXPORT_STATUSES"
export const GET_ALL_ITEMS_AVAILABILITY_STATUSES = "GET_ALL_ITEMS_AVAILABILITY_STATUSES"
export const GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES = "GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES"
export const CHANGE_CONSUMER_STATUS = "CHANGE_CONSUMER_STATUS"
export const SET_CONSUMER_STATUS_ERROR_MESSAGE = "SET_CONSUMER_STATUS_ERROR_MESSAGE"

export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_CATERGORY_HISTORY = "SET_CATERGORY_HISTORY"
export const REMOVE_CATEGORY_HISTORY = "REMOVE_CATEGORY_HISTORY"
export const SELECT_CATEGORY = "SELECT_CATEGORY"
export const SET_CATEGORY_SUCCESS_MESSAGE = "SET_CATEGORY_SUCCESS_MESSAGE"
export const SET_CATEGORY_ERROR_MESSAGE = "SET_CATEGORY_ERROR_MESSAGE"
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES"
export const SET_CATEGORIES_BY_TYPE = "SET_CATEGORIES_BY_TYPE"
export const SET_FOOD_TYPE_SUCCESS_MESSAGE = "SET_FOOD_TYPE_SUCCESS_MESSAGE"
export const SET_FOOD_TYPE_ERROR_MESSAGE = "SET_FOOD_TYPE_ERROR_MESSAGE"
export const GET_CATEGORIES_LIST = " GET_CATEGORIES_LIST"
export const SET_CATEGORIES_LIST = "SET_CATEGORIES_LIST"

export const SET_ADMIN_LIST = "SET_ADMIN_LIST"
export const RESET_PASSWORD = "RESET_PASSWORD"

export const REQUEST_FAQS = "REQUEST_FAQS"
export const SET_FAQ_LIST = "SET_FAQ_LIST"
export const SET_FAQ_SUCCESS_MESSAGE = "SET_FAQ_SUCCESS_MESSAGE"
export const SET_FAQ_ERROR_MESSAGE = "SET_FAQ_ERROR_MESSAGE"

export const SELECT_ADMIN = "SELECT_ADMIN"
export const SET_ADMIN_SUCCESS_MESSAGE = "SET_ADMIN_SUCCESS_MESSAGE"
export const SET_ADMIN_ERROR_MESSAGE = "SET_ADMIN_ERROR_MESSAGE"
export const REQUEST_ADMIN = "REQUEST_ADMIN"

export const SET_CONTRACT_LIST = "SET_CONTRACT_LIST"
export const SET_CONTRACT_SUCCESS_MESSAGE = "SET_CONTRACT_SUCCESS_MESSAGE"
export const SET_CONTRACT_ERROR_MESSAGE = "SET_CONTRACT_ERROR_MESSAGE"
export const REQUEST_CONTRACTS = "REQUEST_CONTRACTS"

export const SET_SERVICE_REQUESTS = "SET_SERVICE_REQUESTS"
export const SET_SR_SUCCESS_MESSAGE = "SET_SR_SUCCESS_MESSAGE"
export const SET_SR_ERROR_MESSAGE = "SET_SR_ERROR_MESSAGE"
export const SET_SR_PAYMENT_SUCCESS_MESSAGE = "SET_SR_PAYMENT_SUCCESS_MESSAGE"
export const SET_SR_PAYMENT_ERROR_MESSAGE = "SET_SR_PAYMENT_ERROR_MESSAGE"
export const REQUEST_SERVICE_REQUESTS = "REQUEST_SERVICE_REQUESTS"
export const SET_SR_DETAILS = "SET_SR_DETAILS"
export const SR_EXPORTTOEXCEL = "SR_EXPORTTOEXCEL"
export const SR_ITEM_REPORT_TO_EXCEL = "SR_ITEM_REPORT_TO_EXCEL"
export const SET_SR_PAYMENT_IMAGE_SUCCESS_MESSAGE = "SET_SR_PAYMENT_IMAGE_SUCCESS_MESSAGE"
export const ADD_SERVICE_REQUESTS_CONVERSATION = "ADD_SERVICE_REQUESTS_CONVERSATION"
export const ADD_SERVICE_REQUESTS_SUPPORT_CONVERSATION = "ADD_SERVICE_REQUESTS_SUPPORT_CONVERSATION"
export const ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT="ADD_SERVICE_REQUESTS_XIRIFYADJUSTMENT"
export const SET_REGIONS = "SET_REGIONS"
export const SET_REGION_SUCCESS_MESSAGE = "SET_REGION_SUCCESS_MESSAGE"
export const SET_REGION_ERROR_MESSAGE = "SET_REGION_ERROR_MESSAGE"
export const REQUEST_REGIONS = "REQUEST_REGIONS"
export const SET_REGION_DETAILS = "SET_REGION_DETAILS"
export const REQUEST_GET_ALL_OPEN_CLOSE_STATUSES = "REQUEST_GET_ALL_OPEN_CLOSE_STATUSES"
export const REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES = "REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES"
export const REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES  = "REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES"
export const REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES = "REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES"

export const SET_AREAS = "SET_AREAS"
export const SET_AREA_SUCCESS_MESSAGE = "SET_AREA_SUCCESS_MESSAGE"
export const SET_AREA_ERROR_MESSAGE = "SET_AREA_ERROR_MESSAGE"
export const REQUEST_AREAS = "REQUEST_AREAS"
export const SET_AREAS_BY_REGIONS = "SET_AREAS_BY_REGIONS"

export const SET_REGIONAL_HEAD = "SET_REGIONAL_HEAD"
export const SET_REGIONAL_HEADS = "SET_REGIONAL_HEADS"
export const SET_REGION_HEAD_SUCCESS_MESSAGE = "SET_REGION_HEAD_SUCCESS_MESSAGE"
export const SET_REGION_HEAD_ERROR_MESSAGE = "SET_REGION_HEAD_ERROR_MESSAGE"
export const REQUEST_REGIONAL_HEADS = "REQUEST_REGIONAL_HEADS"

export const SET_AREA_MANAGERS = "SET_AREA_MANAGERS"
export const SET_AREA_MANAGER_SUCCESS_MESSAGE = "SET_AREA_MANAGER_SUCCESS_MESSAGE"
export const SET_AREA_MANAGER_ERROR_MESSAGE = "SET_AREA_MANAGER_ERROR_MESSAGE"
export const REQUEST_AREA_MANAGERS = "REQUEST_AREA_MANAGERS"
export const SET_RELEASES = "SET_RELEASES"
export const SET_QR_CODE_STATUS = "SET_QR_CODE_STATUS"

export const SET_BUSINESS_SUCCESS_MESSAGE = "SET_BUSINESS_SUCCESS_MESSAGE"
export const SET_BUSINESS_ERROR_MESSAGE = "SET_BUSINESS_ERROR_MESSAGE"

export const INVITES_STATE_CHG_SUCCESS = "INVITES_STATE_CHG_SUCCESS"
export const INVITES_STATE_CHG_ERR = "INVITES_STATE_CHG_ERR"
export const SET_INVITES_SUCCESS_MESSAGE = "SET_INVITES_SUCCESS_MESSAGE"
export const SET_INVITES_ERROR_MESSAGE = "SET_INVITES_ERROR_MESSAGE"
export const SET_INVITES = "SET_INVITES"
export const REQUEST_INVITES = "REQUEST_INVITES"

export const SET_COUPON_LIST = "SET_COUPON_LIST";
export const GET_ALL_COUPONS = "GET_ALL_COUPONS";
export const REQUEST_COUPON_STATS = "REQUEST_COUPON_STATS";
export const COUPON_UPDATE_SUCCESS = "COUPON_UPDATE_SUCCESS";
export const COUPON_UPDATE_ERR = "COUPON_UPDATE_ERR";
export const REMOVE_COUPON = "REMOVE_COUPON";
export const SET_COUPON_SUCCESS_MESSAGE = "SET_COUPON_SUCCESS_MESSAGE";
export const SET_COUPON_ERROR_MESSAGE = "SET_COUPON_ERROR_MESSAGE";

export const ADD_HEAD_SUCCESS = "ADD_HEAD_SUCCESS";
export const ADD_HEAD_ERROR = "ADD_HEAD_ERROR";
export const GET_HEAD_BY_BUSINESSID = "GET_HEAD_BY_BUSINESSID";
export const GET_HEADANDSUBHEAD_BY_BUSINESSID = "GET_HEADANDSUBHEAD_BY_BUSINESSID";
export const UPDATE_HEAD_SUBHEAD = "UPDATE_HEAD_SUBHEAD";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAILURE = "ADD_SERVICE_FAILURE";
export const GET_ALL_SERVICES = "GET_ALL_SERVICES";
export const GET_SUBHEAD_BY_HEAD_ID = "GET_SUBHEAD_BY_HEAD_ID";
export const UPDATE_SERVICE = "UPDATE_SERVICE";
export const SERVICES_BULK_UPLOAD_SUCCESS = "SERVICES_BULK_UPLOAD_SUCCESS";
export const SERVICES_BULK_UPLOAD_ERROR = "SERVICES_BULK_UPLOAD_ERROR";
export const GET_HEAD_WITH_SERVICES_BY_BUSINESSID = "GET_HEAD_WITH_SERVICES_BY_BUSINESSID";
export const SERVICES_MENU_DOWNLOAD_EXCEL = "SERVICES_MENU_DOWNLOAD_EXCEL";
export const SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED = "SERVICES_MENU_DOWNLOAD_EXCEL_COMPLETED";
export const PETPOOJA_MENU_DOWNLOAD_EXCEL = "PETPOOJA_MENU_DOWNLOAD_EXCEL";
export const PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED = "PETPOOJA_MENU_DOWNLOAD_EXCEL_COMPLETED";

export const GET_OTP_RECORDS = "GET_OTP_RECORDS";

export const GET_REVIEW_REPORT = "GET_REVIEW_REPORT";
export const REQUEST_CALL_ICON_HIT_RECORDS = "REQUEST_CALL_ICON_HIT_RECORDS";
export const REQUEST_PAYMENT_GATEWAY_RECORDS = "REQUEST_PAYMENT_GATEWAY_RECORDS";
export const REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS = "REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS";
export const SET_CALL_ICON_HIT_LIST = "SET_CALL_ICON_HIT_LIST";
export const GET_ALL_CALL_ICON_HIT_RECORDS = "GET_ALL_CALL_ICON_HIT_RECORDS";
export const CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL = "CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL";
export const CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED = "CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED";

export const REQUEST_CRON_PERFORMANCE_RECORDS = "REQUEST_CRON_PERFORMANCE_RECORDS";
export const GET_ALL_CRON_PERFORMANCE_RECORDS = "GET_ALL_CRON_PERFORMANCE_RECORDS";
export const CRON_JOB_PERF_DOWNLOAD_EXCEL = "CRON_JOB_PERF_DOWNLOAD_EXCEL";
export const CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED = "CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED";

export const GET_MASTER_OTP = "GET_MASTER_OTP";
export const SET_BUSINESS_MASTER_OTP = "SET_BUSINESS_MASTER_OTP";
export const SET_CONSUMER_MASTER_OTP = "SET_CONSUMER_MASTER_OTP";
export const GET_CONSUMER_MASTER_OTP = "GET_CONSUMER_MASTER_OTP"

export const GET_DELIVERIES = "GET_DELIVERIES";
export const GET_ACTIVEBUSINESSUSER_REPORTS = "GET_ACTIVEBUSINESSUSER_REPORTS";

export const REQUEST_DELIVERYLOADER = "REQUEST_DELIVERYLOADER";
export const REQUEST_DELIVERYLOADER_FALSE = "REQUEST_DELIVERYLOADER_FALSE";

export const SEND_PUSH_NOTIFICATION = "SEND_PUSH_NOTIFICATION";
export const GET_PUSH_NOTIFICATION = "GET_PUSH_NOTIFICATION";
export const GET_PUSH_NOTIFICATION_CONSUMERDATA = "GET_PUSH_NOTIFICATION_CONSUMERDATA";
export const SET_PUSH_NOTIFICATION = "SET_PUSH_NOTIFICATION";
export const DELETE_PUSH_NOTIFICATION = "DELETE_PUSH_NOTIFICATION";
export const SUCCESS_MESSAGE_EMAIL_SENT = "SUCCESS_MESSAGE_EMAIL_SENT";
export const ERROR_MESSAGE_EMAIL_SENT = "ERROR_MESSAGE_EMAIL_SENT";
export const SEND_ERROR_PUSHNOTIFICATION_MSG = "SEND_ERROR_PUSHNOTIFICATION_MSG";

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const SET_CONFIG = "SET_CONFIG";
export const SET_CONFIG_ORDERBY = "SET_CONFIG_ORDERBY";
export const SET_MINORDERS_FOR_ELIGIBLITY = "SET_MINORDERS_FOR_ELIGIBLITY";

export const SETTLEMENT_CRON_RUN = "SETTLEMENT_CRON_RUN";
export const COUPON_SETTLEMENT_CRON_RUN = "COUPON_SETTLEMENT_CRON_RUN";
export const GET_SETTLEMENT_RECORDS = "GET_SETTLEMENT_RECORDS";
export const SET_SETTLEMENT = "SET_SETTLEMENT";
export const SET_SETTLEMENT_NOTES = "SET_SETTLEMENT_NOTES";
export const GET_SETTLEMENT_NOTES = "GET_SETTLEMENT_NOTES";

export const GET_PG_RECORDS = "GET_PG_RECORDS";
export const GET_APPMAINTENANCE_DATA = "GET_APPMAINTENANCE_DATA";
export const SET_APPMAINTENANCE_DATA_ERROR_MESSAGE = "SET_APPMAINTENANCE_DATA_ERROR_MESSAGE";
export const SET_APPMAINTENANCE_DATA_SUCCESS_MESSAGE = "SET_APPMAINTENANCE_DATA_SUCCESS_MESSAGE";

export const GET_ALL_CAMPAIGN_LIST = "GET_ALL_CAMPAIGN_LIST";
export const SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE = "SET_ALL_CAMPAIGN_LIST_SUCCESS_MESSAGE";
export const SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE = "SET_ALL_CAMPAIGN_LIST_ERROR_MESSAGE";
export const CAMPAIGN_DOWNLOAD_EXCEL = "CAMPAIGN_DOWNLOAD_EXCEL";
export const CAMPAIGN_DOWNLOAD_EXCEL_COMPLETED = "CAMPAIGN_DOWNLOAD_EXCEL_COMPLETED";
export const SET_CAMPAIGN_UPDATE = "SET_CAMPAIGN_UPDATE";
export const SET_PROMOCODE = "SET_PROMOCODE";
export const REQUEST_CAMPAIGN = "REQUEST_CAMPAIGN";
export const REQUEST_COUPON_SETTLEMENT = "REQUEST_COUPON_SETTLEMENT";
export const SET_CAMPAIGN_DETAILS = "SET_CAMPAIGN_DETAILS";
export const CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL = "CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL";
export const CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL_COMPLETED = "CAMPAIGN_BUSINESS_DOWNLOAD_EXCEL_COMPLETED";
export const SET_COUPON_CONSUMER_BUSINESS_CAMPAIGN_DETAILS = "SET_COUPON_CONSUMER_BUSINESS_CAMPAIGN_DETAILS";
export const CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL = "CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL";
export const CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL_COMPLETED = "CAMPAIGN_BUSINESS_CUSTOMER_COUPON_DOWNLOAD_EXCEL_COMPLETED";
export const GET_COUPON_SETTLEMENT_RECORDS = "GET_COUPON_SETTLEMENT_RECORDS";
export const GET_COUPON_SETTLEMENT_NOTES = "GET_COUPON_SETTLEMENT_NOTES";
export const SET_COUPON_SETTLEMENT = "SET_COUPON_SETTLEMENT";
export const SET_COUPON_SETTLEMENT_NOTES = "SET_COUPON_SETTLEMENT_NOTES";
export const GET_ALL_CAMPAIGN_LIST_COMPLETED = "GET_ALL_CAMPAIGN_LIST_COMPLETED";
export const GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST = "GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST";
export const GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_COMPLETED = "GET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_COMPLETED";
export const SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_SUCCESS_MESSAGE = "SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_SUCCESS_MESSAGE";
export const SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_ERROR_MESSAGE = "SET_ALL_COUPON_SETTLEMENT_BUSINESS_LIST_ERROR_MESSAGE";
export const COUPONSETTLEMENT_DOWNLOAD_EXCEL = "COUPONSETTLEMENT_DOWNLOAD_EXCEL";
export const COUPONSETTLEMENT_DOWNLOAD_EXCEL_COMPLETED = "COUPONSETTLEMENT_DOWNLOAD_EXCEL_COMPLETED";
export const GET_COUPON_SUMMARY_REPORT = "GET_COUPON_SUMMARY_REPORT";
export const GET_COUPON_SUMMARY_REPORT_EXCEL = "GET_COUPON_SUMMARY_REPORT_EXCEL";
export const SET_ALL_COUPON_SETTLEMENT_LIST_SUCCESS_MESSAGE = "SET_ALL_COUPON_SETTLEMENT_LIST_SUCCESS_MESSAGE";
export const SET_ALL_COUPON_SETTLEMENT_LIST_ERROR_MESSAGE = "SET_ALL_COUPON_SETTLEMENT_LIST_ERROR_MESSAGE";
export const GET_SHOPPERS_LIST = "GET_SHOPPERS_LIST";
export const GET_SHOPPERS_LIST_ERROR_MESSAGE = "GET_SHOPPERS_LIST_ERROR_MESSAGE";
export const REQUEST_SHOPPERS = "REQUEST_SHOPPERS";

export const PROMOCODEREPORT_STATE_CHG_SUCCESS = "PROMOCODEREPORT_STATE_CHG_SUCCESS";
export const PROMOCODEREPORT_STATE_CHG_ERR = "PROMOCODEREPORT_STATE_CHG_ERR";
export const SET_PROMOCODEREPORT_SUCCESS_MESSAGE = "SET_PROMOCODEREPORT_SUCCESS_MESSAGE";
export const SET_PROMOCODEREPORT_ERROR_MESSAGE = "SET_PROMOCODEREPORT_ERROR_MESSAGE";
export const SET_PROMOCODEREPORT = "SET_PROMOCODEREPORT";
export const REQUEST_PROMOCODEREPORT = "REQUEST_PROMOCODEREPORT";

export const OFFERCODEREPORT_STATE_CHG_SUCCESS = "OFFERCODEREPORT_STATE_CHG_SUCCESS";
export const OFFERCODEREPORT_STATE_CHG_ERR = "OFFERCODEREPORT_STATE_CHG_ERR";
export const SET_OFFERCODEREPORT_SUCCESS_MESSAGE = "SET_OFFERCODEREPORT_SUCCESS_MESSAGE";
export const SET_OFFERCODEREPORT_ERROR_MESSAGE = "SET_OFFERCODEREPORT_ERROR_MESSAGE";
export const SET_OFFERCODEREPORT = "SET_OFFERCODEREPORT";
export const REQUEST_OFFERCODEREPORT = "REQUEST_OFFERCODEREPORT";

export const SET_PAYMENT_SUMMARY_ERROR_MESSAGE="SET_PAYMENT_SUMMARY_ERROR_MESSAGE";
export const SET_PAYMENT_SUMMARY="SET_PAYMENT_SUMMARY";
export const REQUEST_PAYMENT_SUMMARY="REQUEST_PAYMENT_SUMMARY";
export const SET_PAYMENT_SUMMARY_EXCEL="SET_PAYMENT_SUMMARY_EXCEL";
export const SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA="SET_PAYMENT_SUMMARY_CASHFREE_SETTLEMENT_DATA";

export const BUSINESS_DASHBOARD_CRON = "BUSINESS_DASHBOARD_CRON";
export const BUSINESS_DASHBOARD_CRON_RESET = "BUSINESS_DASHBOARD_CRON_RESET";
export const GET_BUSINESS_DASHBOARD_CRON_DATA = "GET_BUSINESS_DASHBOARD_CRON_DATA";
export const GET_COUPON_EXPIRY_CRON = "GET_COUPON_EXPIRY_CRON";
export const GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE = "GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE";
export const BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE = "BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE";
export const GET_DELETE_OPEN_CLOSE_RECORDS = "GET_DELETE_OPEN_CLOSE_RECORDS";
export const GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE = "GET_DELETE_OPEN_CLOSE_RECORDS_MESSAGE"; 

export const REQUEST_BUSINESS_ITEMTAG_LOADER = "REQUEST_BUSINESS_ITEMTAG_LOADER";
export const REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE = "REQUEST_BUSINESS_ITEMTAG_LOADER_FALSE";
export const GET_BUSINESS_ITEMTAG_REPORT = "GET_BUSINESS_ITEMTAG_REPORT";
export const GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT = "GET_BUSINESS_ITEMTAG_REPORT_OF_EXPORT";

export const  SET_NOTICE_APP_SUCCESS_MESSAGE = "SET_NOTICE_APP_SUCCESS_MESSAGE";
export const  SET_NOTICE_APP_ERROR_MESSAGE = "SET_NOTICE_APP_ERROR_MESSAGE";
export const  GET_ALL_NIOTICES = "GET_ALL_NOTICES";
export const  GET_ALL_EXPORT_NOTICES = "GET_ALL_EXPORT_NOTICES";
export const  GET_UPDATE_FLAG_SUCCESS_MESSAGE = "GET_UPDATE_FLAG_SUCCESS_MESSAGE";
export const  GET_UPDATE_FLAG_ERROR_MESSAGE = "GET_UPDATE_FLAG_ERROR_MESSAGE"
export const  GET_UPDATE_NOTICE_ERROR_MESSAGE = "GET_UPDATE_NOTICE_ERROR_MESSAGE";
export const  GET_UPDATE_NOTICE_SUCCESS_MESSAGE = "GET_UPDATE_NOTICE_SUCCESS_MESSAGE";
export const  GET_DELETED_NOTICE_SUCCESS_MESSAGE = "GET_DELETED_NOTICE_SUCCESS_MESSAGE";
export const  GET_DELETED_NOTICE_ERROR_MESSAGE = "GET_DELETED_NOTICE_ERROR_MESSAGE";
export const  GET_DELIVERIES_EXPORT = "GET_DELIVERIES_EXPORT";
export const  SET_VARIATION_ADD_SUCCESS_MESSAGE  = "SET_VARIATION_ADD_SUCCESS_MESSAGE";
export const  SET_VARIATION_ADD_ERROR_MESSAGE = "SET_VARIATION_ADD_ERROR_MESSAGE";
export const  GET_ALL_VARIATIONS = "GET_ALL_VARIATIONS";
export const  SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE = "SET_ALL_EXOTEL_NUMBERS_SUCCESS_MESSAGE";
export const  SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE = "SET_ALL_EXOTEL_NUMBERS_ERROR_MESSAGE";
export const  GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS = "GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS";
export const  GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR = "GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR";
export const  GET_ITEMS_TO_LINK_VARIATION_GROUP = "GET_ITEMS_TO_LINK_VARIATION_GROUP";
export const  SET_VARIATIONs_LINK_SUCCESS_MESSAGE = "SET_VARIATIONs_LINK_SUCCESS_MESSAGE";
export const  SET_VARIATIONs_LINK_ERROR_MESSAGE = "SET_VARIATIONs_LINK_ERROR_MESSAGE";
export const  SET_UPDATE_SUCCESS_MESSAGE = "SET_UPDATE_SUCCESS_MESSAGE";
export const  SET_UPDATE_ERROR_MESSAGE = "SET_UPDATE_ERROR_MESSAGE";
export const  SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE = "SET_UPDATE_VARIATION_FLAG_SUCCESS_MESSAGE";
export const  SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE = "SET_UPDATE_VARIATION_FLAG_ERROR_MESSAGE";
export const  SET_DELETE_VARIATIONS_SUCCESS_MESSAGE = "SET_DELETE_VARIATIONS_SUCCESS_MESSAGE";
export const  SET_DELETE_VARIATIONS_ERROR_MESSAGE = "SET_DELETE_VARIATIONS_ERROR_MESSAGE";
export const  SET_CREATE_ADDON_SUCCESS_MESSAGE = "SET_CREATE_ADDON_SUCCESS_MESSAGE";
export const  SET_CREATE_ADDON_ERROR_MESSAGE = "SET_CREATE_ADDON_ERROR_MESSAGE";
export const  GET_ALL_ADDONS_LIST = "GET_ALL_ADDONS_LIST";
export const  UPDATE_ADDON_SUCCESS_MESSAGE = "UPDATE_ADDON_SUCCESS_MESSAGE";
export const  UPDATE_ADDON_ERROR_MESSAGE = "UPDATE_ADDON_ERROR_MESSAGE";
export const  UPDATE_ADDON_GROUP_RECORD_ERROR = "UPDATE_ADDON_GROUP_RECORD_ERROR";
export const  UPDATE_ADDON_GROUP_RECORD_SUCCESS = "UPDATE_ADDON_GROUP_RECORD_SUCCESS";
export const  REQUEST_ADDON_RECORDS = "REQUEST_ADDON_RECORDS";
export const  REQUEST_ADDON_RECORDS_FALSE = "REQUEST_ADDON_RECORDS_FALSE";
export const  UNSET_LINKED_ITEMS_SUCCESS = "UNSET_LINKED_ITEMS_SUCCESS";
export const  UNSET_LINKED_ITEMS_ERROR = "UNSET_LINKED_ITEMS_ERROR";
export const  SET_DELETE_ADDON_SUCCESS_MESSAGE = "SET_DELETE_ADDON_SUCCESS_MESSAGE";
export const  SET_DELETE_ADDON_ERROR_MESSAGE = "SET_DELETE_ADDON_ERROR_MESSAGE";
export const  GET_ALL_ADDONS_LIST_TOLINK_ITEM = "GET_ALL_ADDONS_LIST_TOLINK_ITEM";
export const  GET_ALL_ITEMS_LIST_TOLINK_ADDON = "GET_ALL_ITEMS_LIST_TOLINK_ADDON";
export const  LINK_ADDON_TO_ITEM_SUCCESS = "LINK_ADDON_TO_ITEM_SUCCESS";
export const  LINK_ADDON_TO_ITEM_ERROR = "LINK_ADDON_TO_ITEM_ERROR";
export const  GET_ALL_ITEMS_LINKED_WITH_ADDONS = "GET_ALL_ITEMS_LINKED_WITH_ADDONS";
export const  GET_LINKEDITEM_BY_ADDON_ID = "GET_LINKEDITEM_BY_ADDON_ID";
export const  UNLINK_ADDON_FROM_ITEM_SUCCESS = "UNLINK_ADDON_FROM_ITEM_SUCCESS";
export const  UNLINK_ADDON_FROM_ITEM_ERROR = "UNLINK_ADDON_FROM_ITEM_ERROR";
export const  LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS = "LINKED_ADDON_TO_ITEM_UPDATE_SUCCESS";
export const  LINKED_ADDON_TO_ITEM_UPDATE_ERROR = "LINKED_ADDON_TO_ITEM_UPDATE_ERROR";
export const  REQUEST_GROUP_RECORDS = "REQUEST_GROUP_RECORDS";
export const  REQUEST_GROUP_RECORDS_FALSE = "REQUEST_GROUP_RECORDS_FALSE";
export const  GET_LINKED_ADDON_BY_ITEMID = "GET_LINKED_ADDON_BY_ITEMID";
export const  GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS = "GET_ALL_VARIATION_GROUPS_TO_LINK_CATALOG_ITEMS";
export const  UNSET_LINKED_ADDONS_SUCCESS = "UNSET_LINKED_ADDONS_SUCCESS";
export const  UNSET_LINKED_ADDONS_ERROR = "UNSET_LINKED_ADDONS_ERROR";
export const  UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS = "UNSET_LINKED_ADDONS_SERVICESEC_SUCCESS";
export const  UNSET_LINKED_ADDONS_SERVICESEC_ERROR = "UNSET_LINKED_ADDONS_SERVICESEC_ERROR";
export const  UPLOAD_ADDON_ITEM = "UPLOAD_ADDON_ITEM";
export const  ADDON_BULKUPLOAD_SUCCESS_MESSAGE = "ADDON_BULKUPLOAD_SUCCESS_MESSAGE";
export const  ADDON_BULKUPLOAD_SUCCESS_ERROR = "ADDON_BULKUPLOAD_SUCCESS_ERROR";
export const  REQUEST_ADDONS_DOWNLOAD_TOEXCEL = "REQUEST_ADDONS_DOWNLOAD_TOEXCEL";
export const  ADDONS_DOWNLOAD_TOEXCEL_COMPLETED = "ADDONS_DOWNLOAD_TOEXCEL_COMPLETED";
 
export const   DELETE_ALLSERVICES_SUCCESS_MESSAGE = " DELETE_ALLSERVICES_SUCCESS_MESSAGE";
export const   CHANGE_COUPON_STATUS = " CHANGE_COUPON_STATUS";
export const   SET_COUPON_STATUS_ERROR_MESSAGE = " SET_COUPON_STATUS_ERROR_MESSAGE";
export const  DELETE_ALLSERVICES_ERROR_MESSAGE = "DELETE_ALLSERVICES_ERROR_MESSAGE";
export const  CREATE_DUNZOID_SUCCESS_MESSAGE = "CREATE_DUNZOID_SUCCESS_MESSAGE";
export const  CREATE_DUNZOID_ERROR_MESSAGE = "CREATE_DUNZOID_ERROR_MESSAGE";
export const  CREATE_DUNZO_ID = "CREATE_DUNZO_ID";
export const  RESET_DUNZO_IDS = "RESET_DUNZO_IDS";
export const  GET_ADDONLIST_AG = "GET_ADDONLIST_AG";
export const  CREATE_ADDON_GROUP_SUCCESS = "CREATE_ADDON_GROUP_SUCCESS";
export const  CREATE_ADDON_GROUP_ERROR = "CREATE_ADDON_GROUP_ERROR";
export const  GET_ALL_ADDON_GROUP_RECORDS = "GET_ALL_ADDON_GROUP_RECORDS";
export const  UPLOAD_ADDON_GROUP = "UPLOAD_ADDON_GROUP";
export const  ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE = "ADDON_GROUP_BULKUPLOAD_SUCCESS_MESSAGE";
export const  ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR = "ADDON_GROUP_BULKUPLOAD_SUCCESS_ERROR";
export const  REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL = "REQUEST_ADDON_GROUP_DOWNLOAD_TOEXCEL";
export const  ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED = "ADDON_GROUP_DOWNLOAD_TOEXCEL_COMPLETED";
export const  CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE = "CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE";
export const  CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE = "CANCEL_DELIVERY_PARTNER_TASKID_MESSAGE_ERROR_MESSAGE";

export const GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS = "GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS";
export const GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE = "GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE"; 

export const GET_DELETE_ITEMS_AVAILABILITY_RECORDS = "GET_DELETE_ITEMS_AVAILABILITY_RECORDS";
export const GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE = "GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE"; 
export const FETCH_EXPENSE = "FETCH_EXPENSE";
export const GET_EXPENSE = "GET_EXPENSE";
export const REQUEST_EXPENSE = "REQUEST_EXPENSE";
export const REQUEST_EXPENSES_RESET = "REQUEST_EXPENSES_RESET";
export const SET_EXPENSE_SUCCESS_MESSAGE = "SET_EXPENSE_SUCCESS_MESSAGE";
export const SET_EXPENSE_ERROR_MESSAGE = "SET_EXPENSE_ERROR_MESSAGE";
export const UPDATE_EXPENSE_SUCCESS_MESSAGE = "UPDATE_EXPENSE_SUCCESS_MESSAGE";
export const UPDATE_EXPENSE_ERROR_MESSAGE = "UPDATE_EXPENSE_ERROR_MESSAGE";
export const DELETE_EXPENSE_ERROR_MESSAGE = "DELETE_EXPENSE_ERROR_MESSAGE";
export const DELETE_EXPENSE_SUCCESS_MESAGE = "DELETE_EXPENSE_SUCCESS_MESSAGE";
export const GET_EXPENSE_ERROR_MESSAGE = "GET_EXPENSE_ERROR_MESSAGE";  


export const RESTORE_ADDONS_RECORDS_SUCCESS = "RESTORE_ADDONS_RECORDS_SUCCESS";
export const RESTORE_ADDONS_RECORDS_ERROR = "RESTORE_ADDONS_RECORDS_ERROR";

export const ADDON_GROUP_DELETION_SUCCESS = "ADDON_GROUP_DELETION_SUCCESS";
export const ADDON_GROUP_DELETION_ERROR = "ADDON_GROUP_DELETION_ERROR";
export const ADDON_GROUP_RECORDS_TOLINK = "ADDON_GROUP_RECORDS_TOLINK";
export const LINK_ADDON_GROUP_TOITEM_SUCCESS = "LINK_ADDON_GROUP_TOITEM_SUCCESS";
export const LINK_ADDON_GROUP_TOITEM_ERROR = "LINK_ADDON_GROUP_TOITEM_ERROR";
export const UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS = "UNLINK_ADDON_GROUP_FROM_ITEM_SUCCESS";
export const UNLINK_ADDON_GROUP_FROM_ITEM_ERROR = "UNLINK_ADDON_GROUP_FROM_ITEM_ERROR";
export const GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM = "GET_ADDONGROUPS_TO_UPDATE_LINKEDITEM";
export const SHOW_CONSUMER_COUNT = "SHOW_CONSUMER_COUNT";
export const GET_BUSINESSES_FOR_ADDRESS = "GET_BUSINESSES_FOR_ADDRES"

export const ADD_DELETE_BUSINESS = "ADD_DELETE_BUSINESS"
export const ADD_DELETE_BUSINESS_ERROR = "SET_CONSUMER_STATUS_ERROR_MESSAGE"
export const GET_SERVICEDELIVERY_PARTNER = "GET_SERVICEDELIVERY_PARTNER"
export const GET_CONSUMER_CART_DATA = "GET_CONSUMER_CART_DATA"

export const CHANGE_AP_ORDERS_SUCCESS_MESSAGE = "CHANGE_AP_ORDERS_SUCCESS_MESSAGE"
export const CHANGE_AP_ORDERS_ERROR_MESSAGE = "CHANGE_AP_ORDERS_ERROR_MESSAGE"

export const SET_TR_NOTE_SUCCESS_MESSAGE = "SET_TR_NOTE_SUCCESS_MESSAGE"
export const SET_TR_NOTE_ERROR_MESSAGE = "SET_TR_NOTE_ERROR_MESSAGE"


export const ADD_CONSUMER_TAGS = "ADD_CONSUMER_TAGS"
export const ADD_PENDING_BUSINESS_TAGS = "ADD_PENDING_BUSINESS_TAGS"
export const ADD_REJECTED_BUSINESS_TAGS = "ADD_REJECTED_BUSINESS_TAGS"
export const ADD_DISABLED_BUSINESS_TAGS = "ADD_DISABLED_BUSINESS_TAGS"
export const ADD_APPROVED_BUSINESS_TAGS = "ADD_APPROVED_BUSINESS_TAGS"
export const FIND_ALL_CONVERSATIONS = "FIND_ALL_CONVERSATIONS"

export const ADD_SERVICEPROVIDER_CONVERSATION = "ADD_SERVICEPROVIDER_CONVERSATION"

export const CREATE_REFUND_REQUEST = "CREATE_REFUND_REQUEST"
export const SET_GMV_VALUE_ERROR_MESSAGE="SET_GMV_VALUE_ERROR_MESSAGE";
export const SET_GMV_VALUE="SET_GMV_VALUE";
export const REQUEST_GMV_VALUE="REQUEST_GMV_VALUE";
export const UPDATE_MANUAL_DELIVERY="UPDATE_MANUAL_DELIVERY";
export const GET_CONSUMER_VERSIONS="GET_CONSUMER_VERSIONS";
export const SAVE_BRAND_NAME="SAVE_BRAND_NAME";
export const SET_BRANDNAME = "SET_BRANDNAME";


