import React from 'react';
import { Field, reduxForm } from 'redux-form';
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';

import MultiSelectDropDown from './MultiSelectDropDown';

const styles = theme => ({
    form: {
        width: '100%',
        height: '100%',
    },
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    content: {
        height: '75%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '4%',
        margin: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    }
});

class AddRegionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assignedRegions: [],
            selectedRegions: [],
            assignedAreas: [],
            selectedAreas: []
        }
    }

    renderTextField({ label, input, meta: { touched, invalid, error }, ...custom }) {
        return (
            <TextField
                label={label}
                placeholder={label}
                error={touched && invalid}
                helperText={touched && error}
                {...input}
                {...custom}
            />
        );
    }

    componentDidMount() {
        let assignedAreasTemp = [];
        let selectedAreasTemp = [];
        if (this.props.initialValues && this.props.initialValues.controlledAreas) {
            let assignedRegions = new Set();
            let selectedRegionIds = new Set();
            this.props.initialValues.controlledAreas.forEach(area => {
                let areaTemp = this.props.areasByRegions.find(element => element._id === area);
                if(areaTemp){
                    assignedAreasTemp.push(areaTemp.name);
                    selectedAreasTemp.push(areaTemp._id);
                    assignedRegions.add(areaTemp.regionid.name);
                    selectedRegionIds.add(areaTemp.regionid._id);
                }                
            });
            this.setState({
                assignedAreas: assignedAreasTemp,
                assignedRegions: [...assignedRegions],
                selectedAreas: selectedAreasTemp
            })
            this.props.getAreasByRegions({ regionids: [...selectedRegionIds].join(',') });
        }
    }

    onSubmit = formValues => {
        if (this.state.selectedAreas.length > 0) {
            formValues._id = this.props.initialValues._id;
            formValues.controlledAreas = this.state.selectedAreas;
            this.props.onSubmit(formValues);
        }
    }

    assignRegions = (event) => {
        let selectedRegionIds = [];
        for (let i = 0; i < this.props.regions.length; i++) {
            for (let j = 0; j < event.target.value.length; j++) {
                const element = event.target.value[j];
                if (element.toLowerCase() === this.props.regions[i].name.toLowerCase()) {
                    selectedRegionIds.push(this.props.regions[i]._id)
                }
            }
        }
        this.setState({
            // assignedAreas: [],
            // selectedAreas: [],
            selectedRegions: selectedRegionIds,
            assignedRegions: event.target.value
        });
        this.props.getAreasByRegions({ regionids: selectedRegionIds.join(',') });
    }

    assignAreas = (event) => {
        let selectedAreaIds = [];
        for (let i = 0; i < this.props.areasByRegions.length; i++) {
            for (let j = 0; j < event.target.value.length; j++) {
                const element = event.target.value[j];
                if (element.toLowerCase() === this.props.areasByRegions[i].name.toLowerCase()) {
                    selectedAreaIds.push(this.props.areasByRegions[i]._id)
                }
            }
        }
        this.setState({
            selectedAreas: selectedAreaIds,
            assignedAreas: event.target.value
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <form onSubmit={this.props.handleSubmit(this.onSubmit)} className={classes.form}>
                <Grid container className={classes.header} spacing={3}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom>
                            Edit Area Manager
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.headGridTwo} onClick={() => { this.props.onClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Field name="firstName" component={this.renderTextField} label="First Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="lastName" component={this.renderTextField} label="Last Name" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="email" component={this.renderTextField} disabled label="Email" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="mobileNumber" component={this.renderTextField} label="Contact Number" fullWidth variant="outlined" margin="normal" />
                        </Grid>
                        <Grid item xs={6}>
                            <Field name="username" component={this.renderTextField} disabled label="Username" fullWidth variant="outlined" margin="normal" />
                        </Grid>

                        <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '2px' }} />
                        <Grid item xs={6}>
                            <MultiSelectDropDown
                                label="Select Region(s)"
                                name="regions"
                                data={this.props.regions}
                                selectedOptions={this.state.assignedRegions}
                                handleChange={this.assignRegions}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MultiSelectDropDown
                                label="Assign Area(s)"
                                name="areas"
                                data={this.props.areasByRegions}
                                selectedOptions={this.state.assignedAreas}
                                handleChange={this.assignAreas}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.footer}>
                    <Grid container spacing={2} >
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.onClose() }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="primary">
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </form>
        );
    }
}

const validate = values => {
    const errors = {}

    const requiredFields = [
        'firstName',
        'lastName',
        'email',
        'mobileNumber',
        'username',
        'password'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });

    if (values.username) {
        if (values.username.length > 15) {
            errors.username = 'Must be 15 characters or less'
        }
        if (!/^[A-Za-z0-9_@./#&+-]*$/i.test(values.username)) {
            errors.username = 'Username should be alphanumeric and can contain some special characters without space.'
        }
    }

    if (values.firstName && !/^[a-z ]+$/i.test(values.firstName)) {
        errors.firstName = 'firstName should have only alphabets'
    }

    if (values.lastName && !/^[a-z ]+$/i.test(values.lastName)) {
        errors.lastName = 'lastName should have only alphabets'
    }

    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    if (values.mobileNumber && !/^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/i.test(values.mobileNumber)) {
        errors.mobileNumber = 'Invalid mobile number'
    }

    return errors
}

export default reduxForm({
    form: 'AddRegionForm',
    validate
})(withStyles(styles)(AddRegionForm))
