import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import { Typography } from "@material-ui/core";
const headers = [
    { id: "photo", disablePadding: false, label: "" },
    { id: "serviceId", disablePadding: false, label: "ServiceId" },
    { id: "service", disablePadding: false, label: "Service" },
    { id: "head", disablePadding: false, label: "Heads" },
    { id: "subHead", disablePadding: false, label: "Subheads" },
    { id: "cost", disablePadding: false, label: "Cost" },   
   
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy} = props;
   
    return (
        <TableHead>
            <TableRow>
           
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel                          
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 1000,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        // fontSize: "12px",
        // padding: "2px 2px 2px 0px",
        // fontWeight: 500,
        // height: "48px",
        

    },
    tableCellSmall1: {
        // fontSize: "12px",
        padding: "2px 0px 2px 0px",
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    icon: {
        height: 32,
        //  width: 32
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        padding: "6px 24px 6px 0px"
    }
}));

export default function EnhancedTable(props) {

    const [rowData,setRowData]=useState([]);
    
    useEffect(() => {
        if(props.isDiscountDisabledFilter){
            let filteredArray = props.rowData.filter((ele)=>{
                var arr = [];
                arr= ele.serviceOffers.discount.offers.filter((item)=>{
                    return item.isActive
                })
                return arr.length===0
                }
            )
            setRowData(filteredArray)
        } else {
            setRowData(props.rowData)
        }
    }, [props.rowData,props.isDiscountDisabledFilter]);

    const classes = useStyles();
    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === "asc";
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    const handleSelectAllClick = async (event) => {
        if (event.target.checked) {
            const newSelecteds = rowData.map((n) => n._id);
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };
    return (
        // <div className={classes.root}>
        <Paper className={classes.paper}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={props.order}
                        orderBy={props.orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.totalCount}
                        selected={props.selected}
                        totalCount={props.totalCount}
                        numSelected={props.selected.length}
                        rowsPerPage={props.rowsPerPage}
                        onSelectAllClick={handleSelectAllClick}
                        role={props.role}
                    />
                    <TableBody>
                        {
                            rowData.map((row, index) => {
                                var discount=[];
                               
                                if(row.serviceOffers && row.serviceOffers.discount && row.serviceOffers.discount.offers){
                                    discount= row.serviceOffers.discount.offers
                                }
                                if(discount.length>0){
                                    var arr = []
                                    arr= discount.filter((item)=>{
                                        return item.isActive
                                    })
                                    console.log("arr", arr)
                                    
                                }
                               
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={index}
                                        >                                                                               <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.photos && row.photo ?
                                                    <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} src={row.photo.path} variant="square" ></Avatar>
                                                    : <Avatar style={{ marginRight: "16px", marginLeft: "16px" }} variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg" ></Avatar>}
                                            </TableCell>
                                            <TableCell align="left"className={classes.tableCellSmall}>
                                                {row.serviceId}
                                            </TableCell>
                                            <TableCell align="left" style={{ paddingRight: "16px" }} className={classes.tableCellSmall}>
                                                {row.service}
                                            </TableCell>
                                            <TableCell>
                                                          {row.head.map((head, i) => {
                                                                
                                                            return (
                                                            <Typography  align="left" >{head}</Typography>
                                                            );
                                                          })}
                                                    </TableCell>
                                           
                                                    <TableCell>
                                                        {/* {row.subHead.map((subHead, i) => {
                                                            
                                                            
                                                            return (<TableRow key={i}>
                                                                <TableCell align="left" className={classes.tableBottomBorder}>{subHead}</TableCell>                                                                
                                                            </TableRow>)
                                                        }
                                                        )} */}

                                                    {row.subHead.map((subHead, i) => {
                                                                          return (
                                                                            <Typography align="left" >{subHead}</Typography>
                                                                          );
                                                                        })}
                                                    </TableCell>       
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                            {row.cost ? row.cost : "NA"}                                            </TableCell>                                     
                                        </TableRow>

                                    );
                              
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                component="div"
                count={props.totalCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
        // </div>
    );
}
