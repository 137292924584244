import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { changeHeaderName, getAllConsumers,getAllConsumersVersions, requestBusinessStats, changeConsumerStatus, clearMessage ,getOneConsumerCartData, changeConsumerPaymentPreferace} from '../../actions/dashboardActions';
import ConsumersTable from './ConsumersTable';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from "@material-ui/core/DialogTitle";
import { getConsumerTagList} from "../../actions/consumerTagAction";
import {addconsumerTag} from "../../actions/dashboardActions";
import CloseIcon from "@material-ui/icons/Close";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';



const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(4),
        display: 'flex'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    error: {
        color: "red"
    },
    box: {
        width: "50%",
        display: "inherit",
        alignItems: "center",
        backgroundColor: "#fafafa",
        borderRadius: "8px",
        justifyContent: 'space-around',
        boxShadow: "0px 1px 1px -1px"
    },
    modal: {
        display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         paddingLeft:'400px',
         paddingRight:'400px',
         position:'fixed',
         
    },
    headerBar: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
    },
    headGridTwoButton: {
      float: "right",
      marginTop: "2px",
    },
    form: {
      margin: "5% 10%",
      backgroundColor: theme.palette.background.paper,
    },  
    dialogcontent: {
      margin: "10px 0px",
      overflow:'visible'
  
    },
    footer: {
      height: "5%",
      margin: "10px 0px 10px 0px",
      display: "flex",
      flexDirection:"row",
      alignItems: "center",
      justifyContent: "space-around",
    },
});
const foodPreferece = [ 'Veg' , 'NonVeg' , 'Egg'];
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
  },
  getContentAnchorEl: null,
};



class Consumers extends Component {
    constructor(props) {
        super(props);
        this.state = JSON.parse(window.localStorage.getItem('allConsumers')) || {
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            consumerId: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            societyName: "",
            firstName: "",
            lastName: "",
            email: "",
            pinCode: "",
            primaryMobileNumber: "",
            businessFilter: "",
            distance: "",
            lastActivetimeDateRange: "",
            registrationDateRange: "",
            lastActivetimeFilterStartDate: "",
            lastActivetimeFilterEndDate: "",
            registrationDateRangeFilterStartDate: "",
            registrationDateRangeFilterEndDate: "",
            businessFilterError: "",
            ranges: { },
            preparexportToExcel: false,
            status: "",
            statusData: null,
            confirmDialogFlag: false,
            orderCountGreaterThan: "",
            orderCountLessThan:"",
            countError:false,
            selectFoodPreference:[],
            cartAutoSaveData:"",
            blockUser:"",
            isConsumerModalOpen:false,
            selectedConsumerTags:[],
            consumerTags:[],
            selectedConsumerId:"",
            filteredConsumerTags:[],
            consumerPaymentPreferance: "",
            OSBillGenerated:"",
            currentDate:"",
            appVersions:[],
            selectedAppVersions:[]
        };
    }

    async componentDidMount() {
        console.log("in component Did mount")
        
        this.props.changeHeaderName("Consumers");
        await this.fetchConsumersByQuery();   

        this.fetchConsumerTags();
       
       await this.props.getAllConsumersVersions();
          if(this.props.consumerList.appConfig){
            let blockDate = this.props.consumerList.appConfig.consumerBlockDateNumber;
            let gracePeriod = this.props.consumerList.appConfig.gracePeriodInDays;

            console.log("blockDate",blockDate);
            let formattedDate
            let today = new Date();
        
            let dateCompare = today.getDate();
            console.log("dateCompare--->",dateCompare)
            console.log("gracePeriod----------->",gracePeriod)
            if(dateCompare > gracePeriod){
                console.log("in if---------------->")
                formattedDate = moment(new Date(today.getFullYear(), today.getMonth() + 1, blockDate)).format('DD MMM YYYY');
            } else{
                console.log("in else---------------->")

                formattedDate = moment(new Date(today.getFullYear(), today.getMonth(), gracePeriod)).format('DD MMM YYYY');
            }
          
             this.fetchConsumerVersions();
            this.setState({appVersions:this.props.dashboard.allConsumersVersions})

            this.setState({currentDate:formattedDate})
          }
        window.addEventListener("beforeunload", () => localStorage.removeItem('allConsumers'));
    }



    componentDidUpdate(prevProps, prevState) {
        if(this.props.isFetching===false){
		    if (this.props.sucessMsg) {
		    	this.setState({ sucessMsg: this.props.sucessMsg,snackOpen: true })
		    	this.props.clearMessage();
		    	this.fetchConsumersByQuery();
		    } else if (this.props.errorMsg) {
		    	this.setState({ errorMsg: this.props.errorMsg,snackOpen: true })
		    	this.props.clearMessage();
		    }
        }
	};

    fetchConsumersByQuery = async () => {
        console.log("...this.state in fetch=>",this.state);
        let hasError = false;

        if((this.state.orderCountGreaterThan && parseInt(this.state.orderCountGreaterThan)>=0) && (this.state.orderCountLessThan && parseInt(this.state.orderCountLessThan)>=0) && (parseInt(this.state.orderCountLessThan)<=parseInt(this.state.orderCountGreaterThan))){
          // console.log("if condition");
          hasError = true;
          this.setState({countError:true})
        }
        if(!hasError){
        this.props.requestBusinessStats();
        let foodVar = this.state.selectFoodPreference.join(', ');
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            consumerId: this.state.consumerId.trim(),
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            societyName: this.state.societyName.trim(),
            pinCode: this.state.pinCode.trim(),
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            businessFilter: this.state.businessFilter.trim(),
            distance: this.state.distance,
            lastActivetimeFilterStartDate: this.state.lastActivetimeFilterStartDate,
            lastActivetimeFilterEndDate: this.state.lastActivetimeFilterEndDate,
            registrationDateRangeFilterStartDate: this.state.registrationDateRangeFilterStartDate,
            registrationDateRangeFilterEndDate: this.state.registrationDateRangeFilterEndDate,
            status: this.state.status,
            exportToexcel: 0,
            orderCountGreaterThan:this.state.orderCountGreaterThan,
            orderCountLessThan:this.state.orderCountLessThan,
            foodPreference:foodVar,
            cartAutoSaveData:this.state.cartAutoSaveData,
            consumerPaymentPreferance:this.state.consumerPaymentPreferance ?this.state.consumerPaymentPreferance : "",
            filteredConsumerTags:filteredConsumerTagsVar,
            isConsumerBlockedForOSBill:this.state.blockUser,
            isConsumerOSBillGenerated:this.state.OSBillGenerated,
            appVersionsData:this.state.selectedAppVersions

        };
        await this.props.getAllConsumers(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
        this.setState({countError:false})
        this.setState({ businessFilterError: payload.businessFilter });
        localStorage.setItem("allConsumers", JSON.stringify(this.state));
    }
    }

    fetchConsumersByQueryExcelFile = async () => {
        this.props.requestBusinessStats();
        let foodVar = this.state.selectFoodPreference.join(', ');
        let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
        
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            consumerId: this.state.consumerId.trim(),
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
            societyName: this.state.societyName.trim(),
            pinCode: this.state.pinCode.trim(),
            firstName: this.state.firstName.trim(),
            lastName: this.state.lastName.trim(),
            email: this.state.email.trim(),
            primaryMobileNumber: this.state.primaryMobileNumber.trim(),
            businessFilter: this.state.businessFilter.trim(),
            distance: this.state.distance,
            lastActivetimeFilterStartDate: this.state.lastActivetimeFilterStartDate,
            lastActivetimeFilterEndDate: this.state.lastActivetimeFilterEndDate,
            registrationDateRangeFilterStartDate: this.state.registrationDateRangeFilterStartDate,
            registrationDateRangeFilterEndDate: this.state.registrationDateRangeFilterEndDate,
            status: this.state.status,
            exportToexcel: 1,
            orderCountLessThan:this.state.orderCountLessThan,
            orderCountGreaterThan:this.state.orderCountGreaterThan,
            foodPreference:foodVar,
            cartAutoSaveData:this.state.cartAutoSaveData,
            consumerPaymentPref:this.state.consumerPaymentPref,
            consumerPaymentPreferance:this.state.consumerPaymentPreferance ?this.state.consumerPaymentPreferance : "",
            isConsumerBlockedForOSBill:this.state.blockUser,
            filteredConsumerTags:filteredConsumerTagsVar,
            isConsumerOSBillGenerated:this.state.OSBillGenerated,
            appVersionsData:this.state.selectedAppVersions

        };
        await this.props.getAllConsumers(payload);
        this.setState({ preparexportToExcel: true });

        this.setState({ businessFilterError: payload.businessFilter });
    }

    fetchConsumerTags=async()=>{
        const response=await getConsumerTagList();
        this.setState({consumerTags:response.data})
    }
    fetchConsumerVersions=async()=>{
        await getAllConsumersVersions();
        console.log("this.props.dashboard==>",this.props.dashboard.allConsumersVersions);
        this.setState({appVersions:this.props.dashboard.allConsumersVersions})

    }
    

    updateConsumerStatus = (consumer,newConsumerStatus) => { this.setState({ statusData: consumer, confirmDialogFlag: true, newConsumerStatus: newConsumerStatus });}
    updateConsumerPaymentPreferanceStatus = (consumer,newConsumerPPStatus) => { this.setState({ statusData: consumer, confirmDialogFlagForPP: true, newConsumerPPStatus: newConsumerPPStatus });}

    changeEditStatusFlag = (f,i) => {this.setState({ editStatusFlag: f , editIndex: i})}
    changeEditConsumerPaymentPreferanceFlag = (f,i) => {this.setState({ editConsumerPaymentPreferanceFlag: f , editIndex: i})}

    changeNewStatusFlag = (newstatus,row,i) => {this.setState({ selectedStatus: newstatus , editIndex: i})}
    changeNewConsumerPaymentPreferanceFlag = (newstatus,row,i) => {this.setState({ selectedConsumerPaymentPreferance: newstatus , editIndex: i})}

    handleDeleteClose = () => { this.setState({ confirmDialogFlag: false, editStatusFlag:false, selectedStatus: "", confirmDialogFlagForPP: false, editConsumerPaymentPreferanceFlag: false, selectedConsumerPaymentPreferance: ""}) };

    handleChangeConsumerStatus = async () => {
        this.setState({confirmDialogFlag:false})
        let _id = this.state.statusData._id;
        let oldStatus = this.state.statusData.status ? this.state.statusData.status : "";
        let payload = { status: this.state.selectedStatus }
        await this.props.changeConsumerStatus(payload,_id,oldStatus);
        this.setState({editStatusFlag:false,selectedStatus:""})
        this.fetchConsumersByQuery();
    };
    handleChangeConsumerPaymentPreferance = async () => {
        this.setState({confirmDialogFlagForPP:false})
        let _id = this.state.statusData._id;
        let oldStatus = this.state.statusData.consumerPaymentPreferance ? this.state.statusData.consumerPaymentPreferance : "";
        let payload = { consumerPaymentPreferance: this.state.selectedConsumerPaymentPreferance }
        await this.props.changeConsumerPaymentPreferace(payload,_id,oldStatus);
        this.setState({editConsumerPaymentPreferanceFlag:false,selectedConsumerPaymentPreferance:""})
        this.fetchConsumersByQuery();
    };

    setOrder = (order) => { this.setState({ order }, () => this.fetchConsumersByQuery())  }

    setOrderBy = (orderBy) => { this.setState({ orderBy }, () => this.fetchConsumersByQuery()) }

    setPage = (page) => { this.setState({ page, editStatusFlag: false }, () => this.fetchConsumersByQuery()) }
    
    setRowsPerPage = (rowsPerPage) => { this.setState({ rowsPerPage , editStatusFlag : false})}

    handleFilterChange = (event) => {
       if(event.target.name === "orderCountLessThan"){
        this.setState({ orderCountLessThan:event.target.value,greaterError:false})
        
       }else {
        this.setState({ [event.target.name]: event.target.value }) }
        if(event.target.name === "appVersions"){
            this.setState({ selectedAppVersions:event.target.value})

        }
        }
       
       


    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            consumerId: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            societyName: "",
            firstName: "",
            lastName: "",
            email: "",
            pinCode: "",
            primaryMobileNumber: "",
            businessFilter: "",
            distance: "",
            lastActivetimeDateRange: "",
            registrationDateRange: "",
            lastActivetimeFilterEndDate: "",
            lastActivetimeFilterStartDate: "",
            registrationDateRangeFilterStartDate: "",
            registrationDateRangeFilterEndDate: "",
            businessFilterError: "",
            status: "",
            orderCountGreaterThan:"",
            orderCountLessThan:"",
            countError:false,
            selectFoodPreference:[],
            cartAutoSaveData:"",
            filteredConsumerTags:[],
            consumerPaymentPref: "",
            consumerPaymentPreferance: "",
            blockUser:"",
            OSBillGenerated:"",
            selectedAppVersions:[]
         }, () => {
            this.fetchConsumersByQuery();
        })
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }
    handleLastActiveTimeDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            // lastActivetimeFilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
            // lastActivetimeFilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
            lastActivetimeFilterStartDate: picker.startDate.format(),
            lastActivetimeFilterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ lastActivetimeDateRange: chosenLabel });
    }
    handleRegistrationDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            // lastActivetimeFilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
            // lastActivetimeFilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
            registrationDateRangeFilterStartDate: picker.startDate.format(),
            registrationDateRangeFilterEndDate: picker.endDate.format()
        });       
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ registrationDateRange: chosenLabel });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchConsumersByQuery();
        }
    }

    handleSnackClose = () => {this.setState({ snackOpen: false,sucessMsg: null,errorMsg: null, editMode: false })};

    handleCartDataChange = (event) => {
        console.log(event.target.value);
        this.setState({ cartAutoSaveData: event.target.value });
        //console.log(this.state.cartData);
        
    };
    handleBlockUserChange = (event) => {
        console.log(event.target.value);
        this.setState({ blockUser: event.target.value });
        //console.log(this.state.cartData);
        
    };
    handleOSBillGeneratedChange = (event) => {
        console.log(event.target.value);
        this.setState({blockUser:""});
        if(event.target.value === "true"){
        this.setState({ OSBillGenerated: event.target.value });
        }else if(event.target.value === "false"){
            console.log("inside osbill");
            console.log("state OSBillGenerated=>",this.state.OSBillGenerated);
            this.setState({ OSBillGenerated: event.target.value ,blockUser :""});
            console.log("state OSBillGenerated=>",this.state.OSBillGenerated);
        }
        //console.log(this.state.cartData);
        
    };
    handleConsumerTagModalClose=()=>{
        this.setState({ isConsumerModalOpen:false});
        this.setState({ selectedConsumerTags:[]});
      }

      handleSelectedTags=(e)=>{
        this.setState({ selectedConsumerTags:e.target.value});
      }
      handleSelectedVersions=(e)=>{
        this.setState({ selectedAppVersions:e.target.value});
      }

      handleClickAddTag=(id,selectedConsumerTags)=>{
        this.setState({ isConsumerModalOpen:true});
        this.setState({ selectedConsumerId:id});
        if(selectedConsumerTags){
            const selectedConsumerTagsNames=selectedConsumerTags.map((eachSelectedConsumerTag)=>eachSelectedConsumerTag.name);
            this.setState({ selectedConsumerTags:selectedConsumerTagsNames});
        }
        
      }
      handleClickConsumerVersions=(id,selectedAppVersions)=>{
        this.setState({ isConsumerModalOpen:true});
        this.setState({ selectedConsumerId:id});
        if(selectedAppVersions){
            const selectedConsumerTagsNames=selectedAppVersions.map((eachSelectedConsumerTag)=>eachSelectedConsumerTag.name);
            this.setState({ selectedAppVersions:selectedConsumerTagsNames});
        }
        
      }
      

      handleAddTagSubmit=async (id)=>{
        let finalSelectedConsumerTag=this.state.consumerTags.filter((eachConsumerTag)=>this.state.selectedConsumerTags.includes(eachConsumerTag.name));
        if(finalSelectedConsumerTag){
            let finalSelectedConsumerTagIds=finalSelectedConsumerTag.map((eachfinalselectedConsumerTag)=>eachfinalselectedConsumerTag._id);
            this.props.addconsumerTag(id,finalSelectedConsumerTagIds)
            this.setState({ isConsumerModalOpen:false});
        }
        
      }
      handleDeleteConsumerTag=async (consumerId,id,consumeTags)=>{
            let finalSelectedConsumerTag=consumeTags.filter((eachConsumerTag)=>eachConsumerTag._id!==id);
            let finalSelectedConsumerTagIds=finalSelectedConsumerTag.map((eachfinalselectedConsumerTags)=>eachfinalselectedConsumerTags._id);
        this.props.addconsumerTag(consumerId,finalSelectedConsumerTagIds)     
  }
  handleXirifyPaymentPopupSwitchChange= async (consumerId,flag)=>{
    console.log("consumerId,consumerId",consumerId,flag)
    console.log("this.props.user",this.props.user)
    // console.log("consumerId,consumerId",consumerId,flag)
    let payload = { isGracePeriodOn: flag,
        gracePeroidChangedOn:{
            changedByRoleFirstName:this.props.user.firstName,
            changedByRoleLastName:this.props.user.lastName,
            updatedOn: new Date()
        } }
    await this.props.changeConsumerStatus(payload,consumerId);
    
  }
    render() {
        console.log("this.props---inrender-------->",this.props);
        console.log("this.state---inrender-------->",this.state);
        console.log("this.props.dashboard.allConsumersVersions===========>",this.props.dashboard.allConsumersVersions);
        //    this.setState({appVersions:this.props.dashboard.allConsumersVersions})
        const { classes } = this.props;        
        let muiAlert = null;

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Consumers</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="consumerId" label="Consumer Id" value={this.state.consumerId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="firstName" label="First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="lastName" label="Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="primaryMobileNumber" label="Mobile Number" value={this.state.primaryMobileNumber} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="email" label="Email Id" value={this.state.email} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                         ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                        }}
                                        onApply={this.handleDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="lastRequestDates" label="Last Request Date Range" value={this.state.dateRange}  variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                         ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                        }}
                                        onApply={this.handleRegistrationDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="registrationDates" label="Registration Date Range" value={this.state.registrationDateRange}  variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="societyName" label="Society Name" value={this.state.societyName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="pinCode" label="Pin Code" value={this.state.pinCode} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3}>
                                    <DateRangePicker
                                        ranges={{
                                            'Today': [moment(), moment()],
                                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                        }}
                                        onApply={this.handleLastActiveTimeDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="lastActiveDateFilter" label="Last Active On" value={this.state.lastActivetimeDateRange}  variant="outlined" />
                                    </DateRangePicker>
                                </Grid>
                                <div className={classes.box}>
                                    <Grid item xs={5}>
                                        <TextField fullWidth margin="dense" name="businessFilter" label="Business Name or Id" value={this.state.businessFilter} error={(this.state.distance && (!this.state.businessFilter.trim())) ? "Please enter Business name or Id" : null} helperText={(this.state.distance && (!this.state.businessFilter)) ? "Please enter Business name or Id" : null} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField fullWidth margin="dense" name="distance" label="Distance in meters" value={this.state.distance} error={((this.state.distance || this.state.businessFilter) && (!(/^[1-9]\d*(\.\d+)?$/.test(this.state.distance)))) ? "Enter Valid Distance" : null} helperText={((this.state.distance || this.state.businessFilter) && (!(/^[1-9]\d*(\.\d+)?$/.test(this.state.distance)))) ? "Enter Valid Distance" : null} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                </div>
                                <Grid item xs={3}>
									<FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.status}
                                                name="status"
                                                onChange={this.handleFilterChange}
                                                label="Status"
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="ACTIVE" style={{ minHeight: "28px", height: "28px" }}>ACTIVE</MenuItem>
                                                <MenuItem value="DELETE" style={{ minHeight: "28px", height: "28px" }}>DELETE</MenuItem>
                                                <MenuItem value="BLOCK" style={{ minHeight: "28px", height: "28px" }}>BLOCK</MenuItem>
                                                <MenuItem value="DORMANT" style={{ minHeight: "28px", height: "28px" }}>DORMANT</MenuItem>
                                                <MenuItem value="UNINSTALL" style={{ minHeight: "28px", height: "28px" }}>UNINSTALL</MenuItem>
                                                <MenuItem value="LOGOUT" style={{ minHeight: "28px", height: "28px" }}>LOGOUT</MenuItem>
                                            </Select>
                                        </FormControl>
								</Grid>
                                  
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="orderCountGreaterThan" label="Order count greater than" value={this.state.orderCountGreaterThan ? this.state.orderCountGreaterThan.trim().replace(/\D/g, "") : ""} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                
                                <Grid item xs={3}>
                                    <TextField fullWidth margin="dense" name="orderCountLessThan" label="Order count less than" value={this.state.orderCountLessThan ? this.state.orderCountLessThan.trim().replace(/\D/g, "") : ""}  variant="outlined" onChange={this.handleFilterChange}
                                     error={this.state.countError ? true : false}
                                     helperText={this.state.countError ? "Should be greater than \"Order count at Xirify greater than\"" : ""}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Food Preference</Typography> */}
                                <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                <InputLabel id="demo-mutiple-checkbox-label">Select Food Preference</InputLabel>
                                <Select
                                    labelId="demo-mutiple-checkbox-label"
                                    id="demo-mutiple-checkbox"
                                    multiple
                                    value={this.state.selectFoodPreference}
                                    name="selectFoodPreference"
                                    onChange={this.handleFilterChange}
                                    input={<Input />}
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                    >
                                    {foodPreferece.map((fd) => (
                                        <MenuItem key={fd} value={fd} style={{ minHeight: "28px", height: "28px" }}>
                                            <Checkbox checked={this.state.selectFoodPreference.indexOf(fd) > -1} />
                                            <ListItemText primary={fd} />
                                        </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                                </Grid>      

                                <Grid item xs={3}>
									<FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="demo-simple-select-standard-label">Cart Data</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.cartAutoSaveData}
                                                name="cartData"
                                                onChange={this.handleCartDataChange}
                                                label="Cart Data"
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="true" style={{ minHeight: "28px", height: "28px" }}>Yes</MenuItem>
                                                <MenuItem value="false" style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
								</Grid>
                                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>  
                              
                                <div className={classes.box}>
                                <Grid item xs={5}>
									<FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel id="demo-simple-select-standard-label" 
                                             style={{fontSize: '0.890rem', paddingRight: '8px' }}
                                             >Out Standing Bill</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.OSBillGenerated}
                                                name="BillGenerated"
                                                onChange={this.handleOSBillGeneratedChange}
                                                label="Bill Generated"
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="true" style={{ minHeight: "28px", height: "28px" }}>Yes</MenuItem>
                                                <MenuItem value="false" style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
								</Grid>  
                                <Grid item xs={5} >
									<FormControl fullWidth margin="dense" variant="outlined">
                                            <InputLabel
                                           
                                            id="demo-simple-select-standard-label">Block User</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.blockUser}
                                                name="blockUser"
                                                onChange={this.handleBlockUserChange}
                                                label="Block User"
                                                disabled={this.state.OSBillGenerated === "true" ? false :true} 
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="true" style={{ minHeight: "28px", height: "28px" }}>Yes</MenuItem>
                                                <MenuItem value="false" style={{ minHeight: "28px", height: "28px" }}>No</MenuItem>
                                                <MenuItem value="grace" style={{ minHeight: "28px", height: "28px" }}>Grace Peroid On</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
								</Grid>
                                </div>
                                <Grid item xs={3}>
                                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">App Versions</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.selectedAppVersions}
                                            name="appVersions"
                                            onChange={this.handleSelectedVersions}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.appVersions?.length && this.state.appVersions?.map((eachConsumerAppversion) => (
                                                <MenuItem key={eachConsumerAppversion} value={eachConsumerAppversion} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.selectedAppVersions.indexOf(eachConsumerAppversion) > -1} />
                                                    <ListItemText primary={eachConsumerAppversion} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>                               
                                <Grid item xs={9}>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Post Payment Allowed Consumers
                                    </Typography>
                                    <RadioGroup row aria-label="position" name="consumerPaymentPreferance" onChange={this.handleFilterChange} value={this.state.consumerPaymentPreferance}>
                                        <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>All</Typography>} size="small" />
                                        <FormControlLabel value="ACTIVE" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Active</Typography>} size="small" />
                                        <FormControlLabel value="NOT-ELIGIBLE" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Not Eligible</Typography>} size="small" />
                                        <FormControlLabel value="BLOCKED" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Blocked</Typography>} size="small" />
                                        <FormControlLabel value="OPT-OUT" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Opt Out</Typography>} size="small" />
                                    </RadioGroup>
                                </Grid>
                                {/* <Grid item xs={}>
                                </Grid> */}
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                           // this.fetchConsumersByQuery
                                        }
                                    >
                                        Search
                                    </Button>
                                </Grid>
                                </Grid>
                            
                       
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    </Container>
                
                {this.props.consumerList && this.props.consumerList.msg ?
                    <container className={classes.paper}>
                        <Typography className={classes.error} >{this.props.consumerList.msg}</Typography>
                    </container>
                    : null}
                <Container className={classes.container}>
                    {
                        this.props.consumerList && this.props.consumerList.data ?
                            <ConsumersTable
                                data={this.props.consumerList}
                                exportToexcel={this.props.consumerList.exportToexcel}
                                businessFilter={this.state.businessFilterError}
                                distance={this.state.distance}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                currentStatus={this.state.currentStatus}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                handleFilterChange={this.handleFilterChange}
                                history={this.props.history}
                                fetchConsumersByQueryExcelFile={this.fetchConsumersByQueryExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                user={this.props.user}
                                updateConsumerStatus={(data,newConsumerStatus)=>{this.updateConsumerStatus(data,newConsumerStatus)}}
                                updateConsumerPaymentPreferanceStatus={(data,newConsumerPPStatus)=>{this.updateConsumerPaymentPreferanceStatus(data,newConsumerPPStatus)}}
                                changeEditStatusFlag={(flag,i)=>{this.changeEditStatusFlag(flag,i)}}
                                changeEditConsumerPaymentPreferanceFlag={(flag,i)=>{this.changeEditConsumerPaymentPreferanceFlag(flag,i)}}
                                editStatusFlag={this.state.editStatusFlag}
                                editConsumerPaymentPreferanceFlag={this.state.editConsumerPaymentPreferanceFlag}
                                editIndex={this.state.editIndex}
                                changeNewStatus={(newStatus,row,i)=>{this.changeNewStatusFlag(newStatus,row,i)}}
                                changeNewConsumerPaymentPreferanceStatus={(newStatus,row,i)=>{this.changeNewConsumerPaymentPreferanceFlag(newStatus,row,i)}}
                                selectedStatus={this.state.selectedStatus}
                                selectedConsumerPaymentPreferance={this.state.selectedConsumerPaymentPreferance}
                                dashboard={this.props.dashboard}
                                handleClickAddTag={this.handleClickAddTag}
                                handleClickConsumerTags={this.handleClickConsumerTags}
                                handleDeleteConsumerTag={this.handleDeleteConsumerTag}
                                handleXirifyPaymentPopupSwitchChange={(data,flag)=>{this.handleXirifyPaymentPopupSwitchChange(data,flag)}}
                                blockDate={this.state.currentDate}
                            />
                            : null
                    }
                </Container>

                <Dialog open={this.state.confirmDialogFlag} onClose={this.handleDeleteClose}>
                    <DialogContent>
                        <DialogContentText>
                        Are you sure you want to change the shopper Status to {this.state.selectedStatus}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                        <Button onClick={() => this.handleChangeConsumerStatus()} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.state.confirmDialogFlagForPP} onClose={this.handleDeleteClose}>
                    <DialogContent>
                        <DialogContentText>
                        Are you sure you want to change the shopper payment preferance to {this.state.selectedConsumerPaymentPreferance}?

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                        <Button onClick={() => this.handleChangeConsumerPaymentPreferance()} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    className={classes.modal}
                    open={this.state.isConsumerModalOpen}
                    onClose={this.handleConsumerTagModalClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={false}
                    PaperProps={{
                style: {
                  width: '350px',
                  height: '240px',
                },
              }}
            >
              <DialogTitle className={classes.headerBar} id="form-dialog-title">
                <div>
                Consumer Tag
                  <CloseIcon
                    className={classes.headGridTwoButton}
                    onClick={this.handleConsumerTagModalClose}
                  />
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialogcontent}>
              <FormControl variant="outlined" fullWidth className={classes.formControl} >
                <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                            <Select
                           label="Select Consumer Tags"
                              labelId="mutiple-select-label"
                              multiple
                              value={this.state.selectedConsumerTags}
                              onChange={this.handleSelectedTags}
                              renderValue={(selected) => selected.join(",")}
                              MenuProps={MenuProps}
                                style={ {
                                  width: '300px',
                                }}
                            
                            >
                              {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTag, i) => (
                                <MenuItem key={eachConsumerTag.name} value={eachConsumerTag.name}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        this.state.selectedConsumerTags.indexOf(eachConsumerTag.name) >
                                        -1
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={eachConsumerTag.name} />
                                </MenuItem>
                              ))}
                            </Select>
                </FormControl>
                </DialogContent>
                <DialogActions  >
                <Container className={classes.footer}>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      ///style={{ padding: "20px" }}
                      margin="normal"
                    //   color="primary"
                      onClick={this.handleConsumerTagModalClose}
                    >
                      cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      ///style={{ padding: "20px" }}
                      margin="normal"
                      color="primary"
                      onClick={()=>this.handleAddTagSubmit(this.state.selectedConsumerId)}
                    >
                      save
                    </Button>
                  </div>
                </Container>
                </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                    open={this.state.snackOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackClose}
                    ContentProps={{'aria-describedby': 'message-id'}}>
                        {muiAlert}
                </Snackbar>

                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        )
            
}
}
const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        consumerList: state.dashboard.consumerList,
        user: state.auth.user,
        sucessMsg: state.dashboard.sucessMsg,
        errorMsg: state.dashboard.errorMsg,
        dashboard : state.dashboard
    }
};

export default connect(mapStateToProps, { changeHeaderName, getAllConsumers,getAllConsumersVersions, requestBusinessStats, changeConsumerStatus, clearMessage,getOneConsumerCartData,addconsumerTag, changeConsumerPaymentPreferace })(withStyles(styles)(Consumers));