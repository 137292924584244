import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { getOtpReport } from '../../actions/adminActions';
import { changeHeaderName, getAllConsumers, requestBusinessStats } from '../../actions/dashboardActions';
import OtpTable from './OtpTable';

const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        backgroundColor: '#fff'
    },
    table: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    content: {
        flexGrow: 1,
        height: '100%',
        width: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});

class mobileOtpReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'updatedAt',
            page: 0,
            rowsPerPage: 10,
            mobileNumber: "",
            // fromDate: "",
            // toDate: "",
            userType: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
        };
    }

    componentDidMount() {
        this.props.changeHeaderName("Mobile OTP Report");
        this.fetch();
    }

    fetch = () => {
        //this.props.requestBusinessStats();
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            mobileNumber: this.state.mobileNumber,
            // fromDate: this.state.fromDate ? (moment(this.state.fromDate).format('YYYY-MM-DD, 00:00:00')) : null,
            // toDate: this.state.toDate ? (moment(this.state.toDate).format('YYYY-MM-DD, 23:59:59')) : null,
            userType: this.state.userType,
            filterStartDate: this.state.filterStartDate,
            filterEndDate: this.state.filterEndDate,
        }
        //this.props.getAllConsumers(payload);
        // if (this.state.mobileNumber || this.state.fromDate || this.state.toDate || this.state.userType ) {
            this.props.getOtpReport(payload);
        // }
    }

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        // picker.startDate.add(1, "days");
        this.setState({
            // filterStartDate: picker.startDate.format('DD MMM YYYY'),
            // filterEndDate: picker.endDate.format('DD MMM YYYY')
            filterStartDate: picker.startDate.format(),
            filterEndDate: picker.endDate.format()
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ dateRange: chosenLabel });
    }

    setOrder = (order) => {
        this.setState({ order }, () => this.fetch());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetch());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetch());
    }

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'updatedAt',
            page: 0,
            rowsPerPage: 10,
            mobileNumber: "",
            fromDate: "",
            toDate: "",
            userType: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
        }, () => {
            // let payload = {
            //     reset: true
            // };
            // this.props.getOtpReport(payload);
            this.fetch();
        })
    }


    render() {
        const { classes } = this.props;

        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                    <Typography className={classes.heading}>Search / Filter Report</Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <TextField fullWidth margin="dense" name="mobileNumber" label="Mobile Number" value={this.state.mobileNumber} error={(this.state.mobileNumber && ((this.state.mobileNumber.length < 10) ? (!/^[0-9]+$/.test(this.state.mobileNumber)) : (!/^[0-9]{10}$/i.test(this.state.mobileNumber)))) ? "Please enter Valid Phone number" : null} helperText={(this.state.mobileNumber && ((this.state.mobileNumber.length < 10) ? (!/^[0-9]+$/.test(this.state.mobileNumber)) : (!/^[0-9]{10}$/i.test(this.state.mobileNumber)))) ? "Please enter Valid Phone number" : null} variant="outlined" onChange={this.handleFilterChange} />
                        </Grid>

                        {/* <Grid item xs={4}>
                            <TextField
                                fullWidth
                                margin="dense"
                                name="fromDate"
                                label="From Date"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Please Select Valid From time" : null}
                                helperText={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Please Select Valid Date" : null}
                                value={this.state.fromDate}
                                onChange={this.handleFilterChange}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                margin="dense"
                                name="toDate"
                                label="To Date"
                                type="date"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Please Select Valid From time" : null}
                                helperText={(this.state.fromDate && this.state.toDate && moment(this.state.fromDate).isAfter(this.state.toDate)) ? "Please Select Valid Date" : null}
                                value={this.state.toDate}
                                disabled={!this.state.fromDate}
                                onChange={this.handleFilterChange}
                            />
                        </Grid> */}                        
                                  <Grid item xs={4}>
                                        <DateRangePicker
                                            ranges={this.state.ranges}
                                            onApply={this.handleDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                        >
                                            <TextField fullWidth margin="dense" name="orderDates" label="OTP report date" value={this.state.dateRange}  style={{ width: "260px" }} variant="outlined" />
                                        </DateRangePicker>
                                    </Grid>

                        <Grid item xs={6}><Typography variant="subtitle2" gutterBottom>
                            Xirify App
                            </Typography></Grid>
                        <Grid item xs={6}></Grid>
                        <Grid item xs={6}>
                            <RadioGroup row aria-label="position" name="userType" onChange={this.handleFilterChange} value={this.state.userType}>
                                <FormControlLabel value="" control={<Radio color="primary" />} label="Both" size="small" />
                                <FormControlLabel value="consumer" control={<Radio color="primary" />} label="Consumer" size="small" />
                                <FormControlLabel value="serviceprovider" control={<Radio color="primary" />} label="Business" size="small" />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={2}>
                        </Grid>
                        
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                onClick={this.resetFilter}
                            >
                                Reset
                                    </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                margin="normal"
                                color="primary"
                                onClick={
                                     ()=>this.setPage(0)
                                    //this.fetch
                                }
                            >
                                Search
                                    </Button>
                        </Grid>
                    </Grid>
                </Container>
                <Container className={classes.table}>
                    {
                        (this.props.admin && this.props.admin.otpRecords && this.props.admin.otpRecords.data) ?
                            <OtpTable
                                rowData={this.props.admin.otpRecords.data}
                                order={this.state.order === 1 ? "asc" : "desc"}
                                orderBy={this.state.orderBy}
                                page={this.state.page}
                                rowsPerPage={this.state.rowsPerPage}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPage}
                                setRowsPerPage={this.setRowsPerPage}
                                history={this.props.history}
                            />
                            : null
                    }
                </Container>
                <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        consumerList: state.dashboard.consumerList,
        serviceRequest: state.serviceRequest,
        admin: state.admin
    }
};

export default connect(mapStateToProps, { changeHeaderName, getAllConsumers, requestBusinessStats, getOtpReport })(withStyles(styles)(mobileOtpReport));