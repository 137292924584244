import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from "@material-ui/core/TextField";
import Divider from '@material-ui/core/Divider';
// import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
// import { ObjectID } from 'bson';
import _ from 'lodash';
import { addCouponSettlementNotes, getCouponSettlementsNotes } from '../../actions/campaignActions';
import moment from "moment";

const styles = theme => ({
	Checkbox: {
		fontSize: 20
	},
	grid: {
		width: '100%',
		//display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "15px",
		paddingRight: "15px",
		margin: '0px 0px',

	},
	deleteBtn: {
		cursor: 'pointer',
		color: 'grey',
		//marginTop: "7px"
	},
	header: {
		height: '20%',
		margin: '0px 0px',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: "22px",
		backgroundColor: theme.palette.primary.main,
	},
	content: {
		height: '73%',
		margin: '10px 0px',
		overflow: 'auto',
	},
	footer: {
		height: '10%',
		margin: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headGridTwo: {
		float: 'right',
		marginRight: '2px'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '100%',
		height: '100%',
		padding: '20px',
		backgroundColor: theme.palette.background.paper,
		// margin: '10px 0px',
		// overflow: 'auto',
	},
	gridElement: {
		margin: '10px 5px'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	icon: {
		color: 'white',
	},
	gridList: {
		width: '100%',
		height: '100%'
	},
	mainContainer: {
		width: '40%',
		height: '50%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
		margin: '10px 0px',
		overflow: 'auto',

	},
	tableCellSmall: {
		fontSize: "12px",
		padding: "6px 0px 6px 16px"
	},
	head: {
		fontWeight: 700,
		fontSize: "12px",
	},
	input: {
		height: 30,
		padding: '0px'
	},
	button: {
		height: 30
	},
	lightBlue: {
		color: theme.palette.getContrastText(lightBlue[50]),
		backgroundColor: lightBlue[50],
		width: theme.spacing(4),
		height: theme.spacing(4),
	},
	select: {
		height: '38px'
	},
	listItemText: {
		'margin': '0px'
	},
	buttonGroup: {
		"height": "44px",
		"marginTop": "9px",
		"width": "0px"
	},
	buttonGroupTextFeild: {
		"height": "40px"
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	}
});

const WhiteTextTypography = withStyles({
	root: {
		color: "#FFFFFF"
	}
})(Typography);

class addCouponNotesModal extends Component {
	constructor() {
		super();
		this.state = {
			notes: "",
			settlementNotes: []
		};
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
		if (!_.isEmpty(this.state.error)) {
			this.setState({ error: {} })
		}
	}

	clearData = () => {
		this.setState({
			notes: "",
			settlementNotes: []
		})
	}
	close = () => {
		this.clearData();
		this.props.onClose();
	}
	
	addNotes = async () => {
		if (this.state.notes) {
			let payload = {
				couponSettlementId: this.props.data.couponSettlementId,
				settlementNote: this.state.notes
			}
			await this.props.addCouponSettlementNotes(payload);
			await this.props.getCouponSettlementsNotes({couponSettlementId: this.props.data.couponSettlementId});
			this.clearData();
		}
	}

	render() {
		const { classes } = this.props;
        // console.log("this.props in notes modal--->",this.props)
		return (
			<Modal
				className={classes.modal}
				open={this.props.open}
				closeAfterTransition>
				<Container className={classes.mainContainer}>
					<Grid container className={classes.header} spacing={1} >
						<Grid item xs={8}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								Notes ({(this.props.data && this.props.data.rangeStartDate && this.props.data.rangeEndDate) ? moment.utc(this.props.data.rangeStartDate).format('DD MMM') + "-" + moment(this.props.data.rangeEndDate).format('DD MMM YYYY') : "--"})
							</WhiteTextTypography>
						</Grid>
						<Grid item xs={4}>
							<WhiteTextTypography variant="subtitle1" color="inherit" gutterBottom >
								<CloseIcon className={classes.headGridTwo} onClick={() => { this.close() }} />
							</WhiteTextTypography>
						</Grid>
					</Grid>
					<Container className={classes.modalContainer}>
						{(this.props && this.props.data && this.props.campaign && this.props.campaign.couponSettlementNotesById && this.props.campaign.couponSettlementNotesById.length>0 ) ?
						    this.props.campaign.couponSettlementNotesById.map((row,key)=>
                                <Grid container spacing={1} >
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle2">
                                            {row.user}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style ={{color:"green"}}variant="subtitle2">
                                            {row.userRole}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>
                                            {row.note}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>
                                            {row.noteCreatedTime ? moment(row.noteCreatedTime).format('DD-MMM-YYYY hh:mm A') : "NA"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs ={12}>
                                    <Divider />
                                    </Grid>
                                </Grid>
						    )
                            :
							<Grid item xs={8}>
							    <Typography> No Notes Available </Typography>
							</Grid>
	                    }
	                    <Grid container  spacing={1} alignItems="center">
						    <Grid item xs={10}>
                                <TextField
                                    fullWidth
                                    margin="dense"
                                    name="notes"
                                    variant="outlined"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={this.state.notes}
                                    onChange={this.handleChange}
                                />
						    </Grid>

						    <Grid item xs={2}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addNotes}
                                >
                                    Add
                                </Button>
						    </Grid>
					    </Grid>
					</Container>
				</Container>
			</Modal>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard,
		business: state.business,
		user: state.auth.user,
		admin: state.admin,
		categories: state.categories,
		env: state.auth.env,
		deliveries: state.deliveries,
        campaign: state.campaign,
	};
};
export default connect(mapStateToProps, { addCouponSettlementNotes, getCouponSettlementsNotes })(withStyles(styles)(addCouponNotesModal));