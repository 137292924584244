import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Checkbox } from "@material-ui/core";
import { ReactComponent as Collapsed } from '../../../../images/collapsed.svg';
import { ReactComponent as Expanded } from '../../../../images/expanded.svg';
import { Box, Collapse, } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 20,
    },
    headerBackground: {
        // backgroundColor: "#F2F2F2"
    },
    rowFormat: {
        padding: '0px 0px 0px 15px'
    },
    nameFormat: {
        padding: '0px 0px 0px 13px',
        minWidth: '310px!important'
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        padding: "0px 5px 0px 5px"
    },
    rowBackgroundA: {
        backgroundColor: "#FFF1F1",
        overflowX: "auto"
    },
    rowBackgroundB: {
        backgroundColor: "#F1FEFF",
        overflowX: "auto"
    },
}));



function EnhancedTableHead(props) {
    const classes = useStyles();

    return (
        <TableHead className={classes.headerBackground}>
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Item Id</TableCell>
                <TableCell align="left">Item Name</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left" >Cost</TableCell>
                <TableCell align="left" >Quantity</TableCell>
                <TableCell align="left" >Unit</TableCell>
                <TableCell align="left" >Item Tag</TableCell>
                <TableCell align="left">Head & SubHeads</TableCell>
                <TableCell> </TableCell>
            </TableRow>
        </TableHead>
    );
}
function ExpandTableHead(props) {


    return (
        <TableHead>
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left" >Addon Id</TableCell>
                <TableCell align="left" style={{ paddingLeft: '10px' }}>Addon Name</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="left">Food Type</TableCell>
                <TableCell align="left">Cost</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Unit</TableCell>
                <TableCell align="left">Linked Items</TableCell>

            </TableRow>
        </TableHead>
    );
}
function AddonGroupHeader(props) {
    
    return (
        <TableHead>
            <TableRow>
                <TableCell align="left"></TableCell>
                <TableCell align="left" >Addon Group Id</TableCell>
                <TableCell align="left" >Addon Group Name</TableCell>
                <TableCell align="left">Selection Type</TableCell>
                <TableCell align="left">Min Selection</TableCell>
                <TableCell align="left">Max Selection</TableCell>
                <TableCell align="left">Total Items</TableCell>

            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: "1 1 100%",
    },
}));
const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography
                className={classes.title}
                variant="subtitle2"
                id="tableTitle"
                component="div"
            >
                Linked Items ({props.totalCount})
            </Typography>

        </Toolbar>
    );
};

export default function EnhancedTable(props) {
    const classes = useStyles();

    let selectedAddonIds = props.addonsCheckedToUnlink;
    let selectedGroupIds = props.groupCheckedToUnlink;

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    const isAddonSelectedFlag = (_id) => {
        return props.addonsCheckedToUnlink.indexOf(_id) !== -1;
    };

    const handleSinglAddonClick = (event, _id) => {
        if (event.target.checked) {
            selectedAddonIds.push(_id);
        } else {
            selectedAddonIds = selectedAddonIds.filter((id) => id !== _id);
        }
        props.setCheckedAddonToDelete(selectedAddonIds); //To Unlink Selected Ids
    };

    const isGroupSelectedFlag = (_id) => {
        return props.groupCheckedToUnlink.indexOf(_id) !== -1;
    };

    const handleGroupSelection = (event, _id) => {
        if (event.target.checked) {
            selectedGroupIds.push(_id);
        } else {
            selectedGroupIds = selectedGroupIds.filter((id) => id !== _id);
        }
        props.setSelectedGroupToUnlink(selectedGroupIds);
    }



    // const emptyRows =
    //     props.rowsPerPage -
    //     Math.min(
    //         props.rowsPerPage,
    //         props.totalCount - props.page * props.rowsPerPage
    //     );

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    totalCount={props.totalCount}

                />
                {props.linkedRecords && props.linkedRecords.length ?
                    <TableContainer>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            size="small"
                            aria-label="a dense table"
                        >
                            <EnhancedTableHead />
                            <TableBody>
                                {
                                    props.linkedRecords.map((row, index) => {
                                        let itemTags = row.excludeItemTags === true ? "NA" : (row.mustTry === true ? "Must Try" : (row.newArrival === true ? "New" : (row.bestSeller ? "Best Seller" : "NA")));

                                        return (
                                            <>
                                                <TableRow style={{ backgroundColor : '#0000000D' , borderBottom : '4px solid white'}}>
                                                    <TableCell className={classes.rowFormat} align="center">
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => props.handleExpand(props.expand === index ? -1 : index)}
                                                            style={{ outline : 'none'}}
                                                        >
                                                            {props.expand === index ? (
                                                                <Expanded />
                                                            ) : (
                                                                <Collapsed />
                                                            )}
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell align="center" className={classes.rowFormat}>
                                                        {row.photos && row.photos[0] && row.photos[0].path ?
                                                            <Avatar src={row.photos[0].path} variant="square" style={{ height: '25px', width: '25px' }}></Avatar>
                                                            : <Avatar variant="square" style={{ height: '25px', width: '25px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.rowFormat}>{row.serviceId ? row.serviceId : "NA"}</TableCell>
                                                    <TableCell align="left" colSpan={4}>{row.service ? row.service : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.rowFormat}>{row.cost?.fixedCost ? row.cost?.fixedCost : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.rowFormat}>{row.cost?.quantity ? row.cost?.quantity : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.rowFormat}>{row.cost?.unit ? row.cost?.unit : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.rowFormat} >{itemTags}</TableCell>
                                                    {
                                                        row.heads && row.heads.length > 0 ?
                                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                                <Table
                                                                    aria-labelledby="tableTitle"
                                                                    size="small"
                                                                    aria-label="enhanced table">
                                                                    <TableHead>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {row.heads.map((head, i) => {
                                                                            // console.log("head ====> ", head);
                                                                            let subH = row.subHeads && row.subHeads.length > 0 && row.subHeads.find(sub => sub !== null && sub !== undefined && sub.parentId === head._id);
                                                                            // console.log("subH ====> ", subH);
                                                                            if (!subH) {
                                                                                subH = {
                                                                                    name: 'NA'
                                                                                }
                                                                            }
                                                                            return (<TableRow key={i}>
                                                                                <TableCell align="left" className={classes.tableBottomBorder}>{head.name}</TableCell>
                                                                                <TableCell align="left" className={classes.tableBottomBorder}>{subH.name}</TableCell>
                                                                            </TableRow>)
                                                                        }
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell> : <TableCell align="left">
                                                                <Table>
                                                                    <TableHead>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>}
                                                    {props.role === "areaManager" ?
                                                        <TableCell align="left" className={classes.rowFormat}>
                                                            <IconButton
                                                                className={classes.rowFormat}
                                                                style={{ outline : 'none'}}
                                                                onClick={() => props.handleEditLinkedOpen(row, row.addOnChildServiceItems)}>
                                                                <Avatar className={classes.lightBlue} >
                                                                    <EditIcon color="action" />
                                                                </Avatar> </IconButton></TableCell> : <TableCell></TableCell>}

                                                </TableRow>

                                                <TableRow >

                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>

                                                        <Collapse in={props.expand === index} timeout="auto" unmountOnExit>

                                                            {row.linkedAddOnGroups && row.linkedAddOnGroups.length > 0 ?
                                                                <>
                                                                    <Typography style={{ paddingLeft: '10px', fontWeight: 500, fontFamily: 'inherit' }}>Linked Addon Groups</Typography>
                                                                    <Box
                                                                        style={{
                                                                            borderRadius: "1px",
                                                                            border: "1px solid gray",
                                                                            width: "100%",
                                                                            margin: "5px 0px 10px 10px",
                                                                        }}
                                                                        className={classes.rowBackgroundA}
                                                                    >


                                                                        <Table size="small" aria-label="a dense table" style={{ overflow: "auto", width: "90%", }}>
                                                                            <AddonGroupHeader />
                                                                            <TableBody>
                                                                                {row.linkedAddOnGroups && row.linkedAddOnGroups.length && row.linkedAddOnGroups.map((element, index) => {
                                                                                    const selectedGroupRecord = isGroupSelectedFlag(element._id);
                                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                                    return (
                                                                                        <TableRow key={element._id} >
                                                                                            {props.role === "areaManager" ?
                                                                                                <TableCell style={{ padding: "0px 4px" }} >
                                                                                                    <Checkbox
                                                                                                        onClick={(event) => handleGroupSelection(event, element._id, row._id)}
                                                                                                        checked={selectedGroupRecord}
                                                                                                        aria-checked={selectedGroupRecord}
                                                                                                        selected={selectedGroupRecord}
                                                                                                        onChange={(event) => { props.handleRowInfo(event, row._id) }}
                                                                                                        inputProps={{ "aria-labelledby": labelId }}

                                                                                                    />
                                                                                                </TableCell> : <TableCell></TableCell>}

                                                                                            <TableCell align="left" className={classes.rowFormat}>{element.addOnGroupId ? element.addOnGroupId : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.nameFormat}>{element.addOnGroupName ? element.addOnGroupName : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{element.selectionType ? element.selectionType : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{element.minSelection && element.minSelection > 0 ? element.minSelection : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{element.maxSelection && element.maxSelection > 0 ? element.maxSelection : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>
                                                                                                <Button onClick={() => { props.linkedItemToGroupOpen(element.linkedServiceItems , element._id) }} style={{ padding: "2px", borderRadius: "15px" }} variant="contained" margin="normal" color="primary">{element.linkedServiceItems.length}</Button>
                                                                                            </TableCell>

                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>


                                                                    </Box>
                                                                </>
                                                                : null}

                                                            {row.addOnChildServiceItems && row.addOnChildServiceItems.length > 0 ?
                                                                <> <Typography style={{ paddingLeft: '10px', fontWeight: 500, fontFamily: 'inherit' }}>Linked Addons</Typography>
                                                                    <Box
                                                                        style={{
                                                                            borderRadius: "1px",
                                                                            border: "1px solid gray",
                                                                            width: "100%",
                                                                            margin: "5px 0px 10px 10px",
                                                                        }}
                                                                        className={classes.rowBackgroundB}
                                                                    >


                                                                        <Table size="small" aria-label="a dense table" style={{ overflow: "auto" }}>
                                                                            <ExpandTableHead />
                                                                            <TableBody>
                                                                                {row.addOnChildServiceItems && row.addOnChildServiceItems.length && row.addOnChildServiceItems.map((value, index) => {
                                                                                    const selectedAddonRecord = isAddonSelectedFlag(value._id);
                                                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                                                    return (
                                                                                        <TableRow key={value._id} >
                                                                                            {props.role === "areaManager" ?
                                                                                                <TableCell style={{ padding: "0px 4px" }} >
                                                                                                    <Checkbox
                                                                                                        onClick={(event) => handleSinglAddonClick(event, value._id, row._id)}
                                                                                                        checked={selectedAddonRecord}
                                                                                                        aria-checked={selectedAddonRecord}
                                                                                                        selected={selectedAddonRecord}
                                                                                                        onChange={(event) => { props.handleRowInfo(event, row._id) }}
                                                                                                        inputProps={{ "aria-labelledby": labelId }}

                                                                                                    />
                                                                                                </TableCell> : <TableCell></TableCell>}
                                                                                            <TableCell align="center" style={{ padding: "0px" }}>
                                                                                                {value.photos && value.photos[0] && value.photos[0].path ?
                                                                                                    <Avatar src={value.photos[0].path} variant="square" style={{ height: '25px', width: '25px' }}></Avatar>
                                                                                                    : <Avatar variant="square" style={{ height: '25px', width: '25px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                                                            </TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{value.serviceId ? value.serviceId : "NA"}</TableCell>
                                                                                            <TableCell align="left" style={{ padding: "0px 0px 0px 10px" }} colSpan={4}  >{value.service ? value.service : "NA"}</TableCell>
                                                                                            <TableCell align="left">{value.foodType ? value.foodType : "--"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{value.cost?.fixedCost ? value.cost?.fixedCost : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{value.cost?.quantity ? value.cost?.quantity : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{value.cost?.unit ? value.cost?.unit : "NA"}</TableCell>
                                                                                            <TableCell align="left" className={classes.rowFormat}>{value.addOnServiceItemParents && value.addOnServiceItemParents.length ?
                                                                                                <Container style={{ display: 'flex', paddingLeft: '1px' }}>
                                                                                                    <Typography style={{ padding: "2px", fontSize: "0.875rem", marginRight: '15px' }}>Yes</Typography>
                                                                                                    <Button onClick={() => { props.handleLinkedItemsOpen(value._id) }} style={{ padding: "2px", borderRadius: "15px" }} variant="contained" margin="normal" color="primary">{value.addOnServiceItemParents.length}</Button>
                                                                                                </Container> :
                                                                                                <Container style={{ display: 'flex', paddingLeft: '1px' }}><Typography style={{ padding: "2px", fontSize: "0.875rem" }}>No</Typography><Button style={{ padding: "2px", borderRadius: "15px" }} variant="contained" margin="normal" color="default">00</Button></Container>}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}
                                                                            </TableBody>
                                                                        </Table>


                                                                    </Box> </>
                                                                : null}
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <Typography style={{ margin: "0px 460px" }}>No Records Available</Typography>}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={props.totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}

                />
            </Paper>
        </div>
    );
}
