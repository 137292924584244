import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  { id: 'campaignName', disablePadding: true, label: 'Campaign Name' },
  { id: 'campaignNumber', disablePadding: true, label: 'Campaign Id' },
  { id: 'promoCode', disablePadding: true, label: 'Promo Code' },
  { id: 'offerCode', disablePadding: true, label: 'Offer Code' },
  { id: 'couponCode', disablePadding: true, label: 'Coupon Code' },
  { id: 'invitationCode:', disablePadding: false, label: 'Invitation Code' },
  { id: 'applicability', disablePadding: true, label: 'Applicability' },
  { id: 'category', disablePadding: true, label: 'Category' },
  { id: 'amount', disablePadding: false, label: 'Amount' },
  { id: 'issuer', disablePadding: false, label: 'Sponsored By' },
  { id: 'issuedToId', disablePadding: false, label: 'Issued To ConsumerID' },
  { id: 'issuedToName', disablePadding: false, label: 'Issued To Consumer Name' },
  { id: 'issuedAt', disablePadding: false, label: 'Issued On' },
  { id: 'expiryDate', disablePadding: false, label: 'Expiry Date' },
  { id: 'status', disablePadding: false, label: 'Status' },
  { id: 'ConsumedAt', disablePadding: false, label: 'Consumed At' },
  { id: 'consumedOn', disablePadding: false, label: 'Consumed On' },
  { id: 'serviceRequestId', disablePadding: false, label: 'Order Id' },
  { id: 'settledOn', disablePadding: false, label: 'Settled On' },
  { id: 'deleted', disablePadding: false, label: 'Is Deleted' }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, numSelected, totalCount, onSelectAllClick } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">

          <Checkbox
            indeterminate={numSelected > 0 && numSelected < totalCount}
            // checked={totalCount > 0 && numSelected === totalCount}
            checked={numSelected ? numSelected : null}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCellSmall}
            padding="checkbox"
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  exportToExcelData: PropTypes.object.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%'
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar style={{ paddingLeft: "16px" }}>
      <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
        Coupons ({props.totalCount})
      </Typography>

      {numSelected > 0 ? (
        <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            0 selected
          </Typography>
        )}

      <Typography component="div">
      {props.showDownloadIcon ?
      <CSVLink
          data={props.exportToExcelData}
          headers={props.headers}
          filename={"all_coupons.csv"}
          target="_blank"
        >
          <Tooltip title="Export to Excel">
            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
          </Tooltip>
        </CSVLink>
        :
         <Button
             variant="contained"
             margin="normal"
             style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
             onClick={props.exportCoupons}
         >Export</Button>
     }
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  formControl: {
    minWidth: 102,
  },
  tableCellSmall: {
    fontSize: "13px",
    padding: "6px 0px 6px 16px"
  },
  selectEmpty: {
    // marginTop: theme.spacing.unit * 2,
    marginTop: theme.spacing(2),
    fontSize: "13px",
  },
  tableRow: {
    cursor: 'pointer'
  },
  deleteBtn: {
    cursor: 'pointer',
    color: 'grey',
    marginTop: "7px"
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));


export default function EnhancedTable(props) {
  const classes = useStyles();
  let editStatusFlag = false;
  let selectedNewStatus = "";
  const handleRequestSort = (event, property) => {
    const isAsc = props.orderBy === property && props.order === 'asc';
    props.setOrder(isAsc ? -1 : 1);
    props.setOrderBy(property);
  };


  const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = props.data.map((n) => n._id);
       props.setSelected(newSelecteds);
        return;
      }
    props.setSelected([]);
  };

  const handleCheckBox = (event, _id) => {
    const selectedIndex = props.selectedCouponIds.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedCouponIds, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedCouponIds.slice(1));
    } else if (selectedIndex === props.selectedCouponIds.length - 1) {
      newSelected = newSelected.concat(props.selectedCouponIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedCouponIds.slice(0, selectedIndex),
        props.selectedCouponIds.slice(selectedIndex + 1),
      );
}
    props.setSelected(newSelected);
  };
  const onEditButtonClick=(data,i)=>{
    editStatusFlag = true;
    props.changeEditStatusFlag(editStatusFlag,i);
};
  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };
  const handleFilterChange = (event,row,i) => {
    selectedNewStatus = event.target.value;
    props.changeNewStatus(selectedNewStatus,row,i);
    props.updateCouponStatus(row,selectedNewStatus);
};
  let headers = [
    { key: 'campName', label: 'Campaign Name' },
    { key: 'campNumber', label: 'Campaign Id' },
    { key: 'promo', label: 'Promo Code' },
    { key: 'offer', label: 'Offer Code' },
    { key: 'couponCode', label: 'CouponCode' },
    { key: 'invitationCode', label: 'InvitationCode' },
    { key: 'applicability', label: 'Applicability' },
    { key: 'category', label: 'Category' },
    { key: 'amount', label: 'Amount' },
    { key: 'sponsoredBy', label: 'Sponsored By' }, 
    { key: 'issueToId', label: 'Issued To ConsumerID ' },
    { key: 'issueToName', label: 'Issued To Consumer Name' },       
    { key: 'issuedAt', label: 'Issued On' },
    { key: 'expiryDate', label: 'Expiry Date' },
    { key: 'status', label: 'Status' },
    { key: 'consumedAt', label: 'Consumed At' },
    { key: 'consumedOn', label: 'Consumed On' },
    { key: 'serviceRequestID', label: 'Order Id' },
    { key: 'settledOn', label: 'Settled On' },
    { key: 'isDeleted', label: 'Deleted' },
  ];

  props.exportToExcelData && props.exportToExcelData.forEach(coupon => {
    // coupon.issueToName = coupon.issuedTo ? coupon.issuedTo.firstName + " " + coupon.issuedTo.lastName : "";
    // coupon.issueToId=coupon.issuedTo ? coupon.issuedTo.consumerId : "-";
    coupon.issueToName = coupon.consumerFirstName && coupon.consumerLastName && coupon.consumerFirstName!=="" && coupon.consumerFirstName!==null && coupon.consumerFirstName!==undefined && coupon.consumerLastName!=="" && coupon.consumerLastName!==null && coupon.consumerLastName!==undefined ? coupon.consumerFirstName + " " + coupon.consumerLastName : "";
    coupon.issueToId=coupon.consumerId && coupon.consumerId!==null && coupon.consumerId!=="" && coupon.consumerId!==undefined ? coupon.consumerId : "-";
    coupon.isDeleted = coupon.deleted ? 'Yes' : 'No';
    coupon.issuedAt = moment(coupon.issuedAt).format('DD MMM YYYY hh:mm A');
    coupon.expiryDate = moment(coupon.expiryDate).format('DD MMM YYYY hh:mm A');
    let consumed = coupon.statusHistory && coupon.statusHistory.length > 0 && coupon.statusHistory.find(status => status.status === 'Consumed');
    let payBackSeller = coupon.statusHistory && coupon.statusHistory.length > 0 && coupon.statusHistory.find(status => status.status === 'PayBackSeller');
    coupon.consumedOn = consumed && consumed.time ? moment(consumed.time).format('DD MMM YYYY hh:mm A') : 'NA';
    coupon.settledOn = payBackSeller && payBackSeller.time ? moment(payBackSeller.time).format('DD MMM YYYY hh:mm A') : 'NA';
    coupon.isDeleted = coupon.deleted ? 'Yes' : 'No';
    // coupon.consumedAt = coupon.businessId && coupon.businessId.length > 0 ? coupon.businessId[0].businessName : 'NA'
    coupon.consumedAt = coupon.businessName && coupon.businessName!==null && coupon.businessName!=="" && coupon.businessName!==undefined ? coupon.businessName : "NA";
    // coupon.serviceRequestID = coupon.serviceRequestId && coupon.serviceRequestId.length > 0 ? coupon.serviceRequestId[0].serviceRequestId : 'NA';
    coupon.serviceRequestID = coupon.serviceRequestNumber && coupon.serviceRequestNumber!==null && coupon.serviceRequestNumber!=="" && coupon.serviceRequestNumber!==undefined ? coupon.serviceRequestNumber : "NA";
    coupon.status = coupon.status && coupon.status === 'PayBackSeller' ? 'Settled' : coupon.status;
    coupon.sponsoredBy = coupon.issuer && coupon.issuer === 'Platform' ? 'Xirify' : "Business";
    coupon.campName = coupon.campaignName && coupon.campaignName!==null && coupon.campaignName!=="" && coupon.campaignName!==undefined ? coupon.campaignName : "NA";
    coupon.campNumber = coupon.campaignNumber && coupon.campaignNumber!==null && coupon.campaignNumber!=="" && coupon.campaignNumber!==undefined ? coupon.campaignNumber : "NA";
    coupon.promo = coupon.promoCode && coupon.promoCode!==null && coupon.promoCode!=="" && coupon.promoCode!==undefined ? coupon.promoCode : "NA";
    coupon.offer = coupon.offerCode && coupon.offerCode!==null && coupon.offerCode!=="" && coupon.offerCode!==undefined ? coupon.offerCode : "NA";
  });

  // console.log("props in coupons table----->",props);

  const isSelected = (_id) => props.selectedCouponIds.indexOf(_id) !== -1;
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          totalCount={props.totalCount}
          headers={headers}
          exportToExcelData={props.exportToExcelData}
          numSelected={props.selectedCouponIds.length}
          showDownloadIcon={props.showDownloadIcon}
          exportCoupons={props.exportCoupons}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={props.order}
              orderBy={props.orderBy}
              rowCount={props.totalCount}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              selectedCouponIds={props.selectedCouponIds}
              totalCount={props.totalCount}
              numSelected={props.selectedCouponIds.length}
              rowsPerPage={props.rowsPerPage}
              role={props.role}
              user={props.user}
              {...props}
            />
            <TableBody>
              {stableSort(props.data, getComparator(props.order, props.orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id)
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let consumedOn = 'NA';
                  let settledOn = 'NA';
                  let issuedAt;
                  let consumed = row.statusHistory && row.statusHistory.length > 0 && row.statusHistory.find(status => status.status === 'Consumed');
                  let payBackSeller = row.statusHistory && row.statusHistory.length > 0 && row.statusHistory.find(status => status.status === 'PayBackSeller');
                  consumedOn = consumed && consumed.time ? moment(consumed.time).format('DD MMM YYYY hh:mm A') : 'NA';
                  settledOn = payBackSeller && payBackSeller.time ? moment(payBackSeller.time).format('DD MMM YYYY hh:mm A') : 'NA';
                  let selectedStatus = props.selectedStatus && props.selectedStatus!=="" && props.selectedStatus!==null && props.selectedStatus!==undefined ? props.selectedStatus : row.status;
                  let currentDate  = new Date().getTime();
                  let checkExpiry = currentDate > new Date(row.expiryDate).getTime() ? false:true;

                  let issued = row.statusHistory && row.statusHistory.length > 0 && row.statusHistory.find(status => status.status === 'Issued');
                  issuedAt = issued && issued.time ? moment(issued.time).format('DD MMM YYYY hh:mm A') : moment(row.issuedAt).format('DD MMM YYYY hh:mm A');
                  row.status = row.status && row.status === 'PayBackSeller' ? 'Settled' : row.status;
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow} 
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleCheckBox(event, row._id)}
                          checked={isItemSelected}
                          aria-checked={isItemSelected}
                          selectedCouponIds={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>{row.campaignName ? row.campaignName : "NA"}</TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>{row.campaignNumber ? row.campaignNumber : "NA"}</TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>{row.promoCode ? row.promoCode : "NA"}</TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>{row.offerCode ? row.offerCode : "NA"}</TableCell>
                      <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>{row.couponCode}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.invitationCode}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.applicability}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.category}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.amount}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.issuer && row.issuer==="Platform" ? "Xirify" : "Business" }</TableCell>
                      {/* <TableCell align="left" className={classes.tableCellSmall}>{row.issuedTo && row.issuedTo.consumerId}</TableCell> */}
                      <TableCell align="left" className={classes.tableCellSmall}>{row.consumerId && row.consumerId!=null && row.consumerId!=="" && row.consumerId!==undefined ? row.consumerId : "NA"}</TableCell>
                      {/* <TableCell align="left" className={classes.tableCellSmall}>{row.issuedTo && row.issuedTo.firstName && row.issuedTo.lastName && row.issuedTo.firstName!="" && row.issuedTo.firstName!=null && row.issuedTo.firstName!=undefined && row.issuedTo.lastName!="" && row.issuedTo.lastName!=null && row.issuedTo.lastName!=undefined ? row.issuedTo.firstName + " " + row.issuedTo.lastName : "--"}</TableCell> */}
                      <TableCell align="left" className={classes.tableCellSmall}>{row.consumerFirstName && row.consumerLastName && row.consumerFirstName!=="" && row.consumerFirstName!==null && row.consumerFirstName!==undefined && row.consumerLastName!=="" && row.consumerLastName!==null && row.consumerLastName!==undefined ? row.consumerFirstName + " " + row.consumerLastName : "--"}</TableCell>
                      {/* <TableCell align="left" className={classes.tableCellSmall}>{row.consumerName && row.consumerName!="" && row.consumerName!=null && row.consumerName!=undefined ? row.consumerName : "--"}</TableCell> */}
                      <TableCell align="left" className={classes.tableCellSmall}>{issuedAt}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.expiryDate ? moment(row.expiryDate).format('DD MMM YYYY hh:mm A') : "Lifetime"}</TableCell>
                      {props.editStatusFlag && props.editIndex === index ? 
                                                <TableCell align="left" className={classes.tableCellSmall}>
                                                    {checkExpiry ? 
                                                    <div style={{display:"flex", flexDirection:"row",alignItems:"flex-end"}}>
                                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={selectedStatus}
                                                            name="status"
                                                            onChange={(e)=>handleFilterChange(e,row,index)}
                                                        //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                                        >
                                                        <MenuItem value="Consumed" style={{ minHeight: "28px", height: "28px" }}>Consumed</MenuItem>
                                                        <MenuItem value="Issued" style={{ minHeight: "28px", height: "28px" }}>Issued</MenuItem>
                                                        </Select>
                                                        </FormControl>
                                                    </div>
                                                    :
                                                    <div style={{display:"flex", flexDirection:"row",alignItems:"flex-end"}}>
                                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            value={selectedStatus}
                                                            name="status"
                                                            onChange={(e)=>handleFilterChange(e,row,index)}
                                                        //    disabled={ props?.user?.role === "regionalHead" || props?.user?.role === "areaManager" ? true : false}
                                                        >
                                                        <MenuItem value="Consumed" style={{ minHeight: "28px", height: "28px" }}>Consumed</MenuItem>
                                                        <MenuItem value="Expired" style={{ minHeight: "28px", height: "28px" }}>Expired</MenuItem>
                                                        </Select>
                                                        </FormControl>
                                                    </div>
}
                                                </TableCell>
                                                :
                                                <TableCell align="left" className={classes.tableCellSmall}>
                                                    <div style={{display:"flex", flexDirection:"row",alignItems:"flex-end"}}>
                                                        {row.status ? row.status : "--"}
                                                         {
                                                             row.status === "GettingConsumed" && props.user.role ==="admin" ?
                                                             <EditIcon color='primary' style={{marginLeft:"10px"}} onClick={()=>{onEditButtonClick(row,index)}}></EditIcon> 
                                                             : ""
                                                         }
                                                        
                                                    </div>
                                                </TableCell>    
                                            }

                      {/* <TableCell align="left" className={classes.tableCellSmall}>{row.businessId && row.businessId.length > 0 && row.businessId[0].businessName ? row.businessId[0].businessName : 'NA'}</TableCell> */}
                      <TableCell align="left" className={classes.tableCellSmall}>{row.businessName && row.businessName!==null && row.businessName!=="" && row.businessName!==undefined ? row.businessName : "NA"}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{consumedOn}</TableCell>
                      {/* <TableCell align="left" className={classes.tableCellSmall}>{row.serviceRequestId && row.serviceRequestId.length > 0 ? row.serviceRequestId[0].serviceRequestId : 'NA'}</TableCell> */}
                      <TableCell align="left" className={classes.tableCellSmall}>{row.serviceRequestNumber && row.serviceRequestNumber!==null && row.serviceRequestNumber!=="" && row.serviceRequestNumber!==undefined ? row.serviceRequestNumber : "NA"}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{settledOn}</TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>{row.deleted ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          selectedCouponIds={props.selectedCouponIds}
          onSelectAllClick={props.handleSelectAllClick}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
