import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Modal from '@material-ui/core/Modal';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import AddAreaManagerForm from './AddAreaManagerForm';
import EditAreaManagerForm from './EditAreaManagerForm';
import AreaManagerTable from './AreaManagerTable';
import MultiSelectDropDown from './MultiSelectDropDown';
import { changeHeaderName } from '../../actions/dashboardActions';
import { requestRegions, getAllRegions } from '../../actions/regionActions';
import { requestAreaManagers, getAllAreaManagers, addAreaManager, updateAreaManager, clearMsg } from '../../actions/areaManagerActions';
import { getAreasByRegions } from '../../actions/areaActions';
import { getRegionalHeadById } from '../../actions/regionalHeadActions';

const styles = theme => ({
	appBarSpacer: theme.mixins.toolbar,
	table: {
		minWidth: 650,
	},
	head: {
		fontWeight: 700
	},
	button: {
		marginTop: theme.spacing(3),
		float: 'right'
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '0px'
	},
	modalContainer: {
		width: '45%',
		height: '75%',
		backgroundColor: theme.palette.background.paper,
		padding: '0px 0px',
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
	searchBox: {
		width: '30%',
		marginTop: '24px'
	},
	clearButton: {
		marginLeft: '25px'
	},
	content: {
		width: '100%',
		height: '100%',
	},
	container: {
		width: '100%',
		height: '100%',
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	list: {
		width: '100%',
		backgroundColor: theme.palette.background.paper,
	},
	searchPaper: {
		// padding: '0px 0px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
	formControl: {
        width: '100%',
        marginTop: '10px',
    }
});


class Region extends Component {
	constructor() {
		super();
		this.state = {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			regionSearchvalue: "",
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
			areaManagerToEdit: null,
			selectedRegions: [],
			filteredRegions: [],
			selectedAreas: [],
			filteredAreas: [],
			firstName: "",
			lastName: "",
			email: "",
			mobileNumber: "",
			pincode: "",
			username: "",
			activeInactiveStatus: "",
		};
	}

	async componentDidMount() {
		this.props.changeHeaderName("Manage Area Managers");
		await this.props.getRegionalHeadById(this.props.user.id);
		await this.getAssociatedRegions();
		this.props.getAreasByRegions({ regionids: this.props.regionalHead.regionalHead.controlledRegions.join(',') });
		this.setState({ activeInactiveStatus: 'active' }, () => {this.fetchAreaManagersByQuery()});
	}

	getAssociatedRegions = () => {
		this.props.requestRegions();
		this.props.getAllRegions({
			skip: 0,
			limit: 0,
			regionIds: this.props.regionalHead.regionalHead.controlledRegions.join(',')
		});
	}

	handleModalOpen = () => {
		this.setState({ modalOpen: true });
	}

	handleModalClose = () => {
		this.setState({ modalOpen: false, editMode: false });
	}

	openEditAreaManagerModal = (areaManager) => {
		this.props.getAreasByRegions({ regionids: this.props.regionalHead.regionalHead.controlledRegions.join(',') });
		this.setState({ editMode: true, areaManagerToEdit: areaManager, modalOpen: true });
	}

	handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ editMode: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null });
		// this.fetchAreaManagersByQuery();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.areaManager.sucessMsg) {
			this.setState({ sucessMsg: this.props.areaManager.sucessMsg })
			this.setState({ snackOpen: true });
			this.setState({ modalOpen: false, });
			this.fetchAreaManagersByQuery();
			this.getAssociatedRegions();
			this.props.clearMsg();
		} else if (this.props.areaManager.errorMsg) {
			this.setState({ errorMsg: this.props.areaManager.errorMsg })
			this.setState({ snackOpen: true });
			this.props.clearMsg();
		}
	}

	fetchAreaManagersByQuery = () => {
		this.props.requestAreaManagers();
		let payload = {
			regionids: this.state.filteredRegions.length > 0 ? this.state.filteredRegions.join(',') : this.props.regionalHead.regionalHead.controlledRegions.join(','),
			orderBy: this.state.orderBy,
			order: Number(this.state.order),
			skip: this.state.page * this.state.rowsPerPage,
			limit: this.state.rowsPerPage,
			activeInactiveStatus: this.state.activeInactiveStatus,
			selectedRegions: this.state.selectedRegions,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			email: this.state.email,
			mobileNumber: this.state.mobileNumber,
			pincode: this.state.pincode,
			username: this.state.username,
			selectedAreas: this.state.filteredAreas.join(',')
		}
		this.props.getAllAreaManagers(payload);
	}

	handleSearchSubmit = (event) => {
		event.preventDefault();
		this.setState({
			page: 0,
			clearSearch: true
		}, () => this.fetchAreaManagersByQuery())
	}

	handleClearButton = () => {
		this.setState({
			page: 0,
			regionSearchvalue: "",
			clearSearch: false
		}, () => this.fetchAreaManagersByQuery())
	}

	addAreaManager = (props) => {
		this.props.addAreaManager(props);
	}

	updateAreaManager = (payload) => {
		this.props.updateAreaManager(payload);
	}

	setOrder = (order) => {
		this.setState({ order }, () => this.fetchAreaManagersByQuery());
	}

	setOrderBy = (orderBy) => {
		this.setState({ orderBy }, () => this.fetchAreaManagersByQuery());
	}

	setPage = (page) => {
		this.setState({ page }, () => this.fetchAreaManagersByQuery());
	}

	setRowsPerPage = (rowsPerPage) => {
		this.setState({ rowsPerPage });
	}

	handleFilterChange = (event) => {
		if (event.target.name === "selectedRegions") {
			let filteredRegionIds = [];
			for (let i = 0; i < this.props.regions.data.data.length; i++) {
				for (let j = 0; j < event.target.value.length; j++) {
					const element = event.target.value[j];
					if (element.toLowerCase() === this.props.regions.data.data[i].name.toLowerCase()) {
						filteredRegionIds.push(this.props.regions.data.data[i]._id)
					}
				}
			}
			this.setState({
				selectedRegions: event.target.value,
				filteredRegions: filteredRegionIds
			});
			this.props.getAreasByRegions({ regionids: filteredRegionIds.join(',') });
		} else if (event.target.name === "selectedAreas") {
			let filteredAreaIds = [];
			for (let i = 0; i < this.props.areasByRegions.length; i++) {
				for (let j = 0; j < event.target.value.length; j++) {
					const element = event.target.value[j];
					if (element.toLowerCase() === this.props.areasByRegions[i].name.toLowerCase()) {
						filteredAreaIds.push(this.props.areasByRegions[i]._id)
					}
				}
			}
			this.setState({
				selectedAreas: event.target.value,
				filteredAreas: filteredAreaIds
			});
		} else {
			this.setState({ [event.target.name]: event.target.value });
		}
	}

	resetFilter = () => {
		this.setState({
			order: -1,
			orderBy: 'username',
			page: 0,
			rowsPerPage: 10,
			selectedRegions: [],
			filteredRegions: [],
			selectedAreas: [],
			filteredAreas: [],
			firstName: "",
			lastName: "",
			email: "",
			mobileNumber: "",
			pincode: "",
			username: "",
			activeInactiveStatus: "",
		}, () => {
			this.fetchAreaManagersByQuery();
		})
	};

	handleUserFlagSwitchChange = async (areaManagerObj) => {
		let payload = {
			_id: areaManagerObj._id,
			isActive: !areaManagerObj.isActive
		};
		this.props.updateAreaManager(payload);
	};

	render() {
		const { classes } = this.props;
		let muiAlert = null;
		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}
		return (
			<div className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container className={classes.container}>
					<Container style={{ width: '100%', height: "40px", paddingTop: '0px' }}>
					{(this.props && this.props.area && this.props.area.areasByRegions && this.props.area.areasByRegions.length)? 
						<Button
							variant="contained"
							color="primary"
							startIcon={<AddIcon />}
							style={{ float: 'right' }}
							onClick={() => this.handleModalOpen()}
						>
							Add Area Manager
          				</Button>
						  :null}
						<Modal
							className={classes.modal}
							open={this.state.modalOpen}
							onClose={this.handleModalClose}
							closeAfterTransition>
							<Container className={classes.modalContainer}>
								{
									this.state.editMode ?
										<EditAreaManagerForm
											regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []}
											areasByRegions={this.props.areasByRegions}
											initialValues={_.pick(this.state.areaManagerToEdit, '_id', 'firstName', 'lastName', 'email', 'mobileNumber', 'username', 'controlledRegions', 'controlledAreas')}
											getAreasByRegions={this.props.getAreasByRegions}
											onSubmit={this.updateAreaManager}
											onClose={this.handleModalClose}
										/>
										:
										<AddAreaManagerForm
											regions={this.props.regions.data && this.props.regions.data.data ? this.props.regions.data.data : []}
											areasByRegions={this.props.areasByRegions} 
											getAreasByRegions={this.props.getAreasByRegions}
											onSubmit={this.addAreaManager}
											onClose={this.handleModalClose}
										/>
								}

							</Container>
						</Modal>
					</Container>
					<Container style={{ paddingTop: '10px' }}>
						{
							this.props.regions && this.props.regions.data && this.props.regions.data.data.length > 0 ?
								<ExpansionPanel>
									<ExpansionPanelSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
										style={{ minHeight: '44px', height: '44px' }}
									>
										<Typography className={classes.heading}>Search Area Managers</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails>
										<Grid container spacing={1}>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="username" label="Username" value={this.state.username} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="email" label="E-mail" value={this.state.email} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="firstName" label="First Name" value={this.state.firstName} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="lastName" label="Last Name" value={this.state.lastName} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="mobileNumber" label="Contact Number" value={this.state.mobileNumber} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<TextField fullWidth margin="dense" name="pincode" label="Pin Code" value={this.state.pincode} variant="outlined" onChange={this.handleFilterChange} />
											</Grid>
											<Grid item xs={3}>
												<MultiSelectDropDown
													label="Region"
													name="selectedRegions"
													data={this.props.regions.data.data}
													selectedOptions={this.state.selectedRegions}
													handleChange={this.handleFilterChange}
													avoidErrorMsg
													disablePadding
												/>
											</Grid>
											<Grid item xs={3}>
												<MultiSelectDropDown
													label="Area"
													name="selectedAreas"
													data={this.props.areasByRegions}
													selectedOptions={this.state.selectedAreas}
													handleChange={this.handleFilterChange}
													avoidErrorMsg
													disablePadding
												/>
											</Grid>
											<Grid item xs={3}>
											<FormControl
												variant="outlined"
												fullWidth
												className={classes.formControl}
												style={{ marginTop: '0px' }}
											>
												<InputLabel id="ActiveInactiveStatus-label"> Status</InputLabel>
												<Select
												labelId="ActiveInactiveStatus-label"
												name="activeInactiveStatus"
												value={this.state.activeInactiveStatus}
												onChange={this.handleFilterChange}
												input={<Input />}
												style={{height:'32px'}}
												>
												<MenuItem
													value={'active'}
													style={{ minHeight: "28px", height: "28px" }}
												>
													<ListItemText primary="Active" />
												</MenuItem>
												<MenuItem
													value={'inactive'}
													style={{ minHeight: "28px", height: "28px" }}
												>
													<ListItemText primary="Inactive" />
												</MenuItem>
												</Select>
											</FormControl>
											</Grid>
											<Grid item xs={12}>
											</Grid>
											<Grid item xs={8}>
											</Grid>
											<Grid item xs={2} style={{ paddingTop: "12px" }}>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													margin="normal"
													onClick={this.resetFilter}
												>
													Reset
                                    			</Button>
											</Grid>
											<Grid item xs={2} style={{ paddingTop: "12px" }}>
												<Button
													type="submit"
													fullWidth
													variant="contained"
													margin="normal"
													color="primary"
													onClick={this.fetchAreaManagersByQuery}
												>
													Search
                                    </Button>
											</Grid>
										</Grid>

									</ExpansionPanelDetails>
								</ExpansionPanel>
								: null
						}

					</Container>

					<Container className={classes.container}>
						{
							(this.props && this.props.area && this.props.area.areasByRegions && this.props.area.areasByRegions.length && this.props.areaManager.areaManagers && this.props.areaManager.areaManagers.data ) ?
								<AreaManagerTable
									rowData={this.props.areaManager.areaManagers}
									// order={this.state.order === 1 ? 'asc' : 'desc'}
									// orderBy={this.state.orderBy}
									page={this.state.page}
									rowsPerPage={this.state.rowsPerPage}
									setOrder={this.setOrder}
									setOrderBy={this.setOrderBy}
									setPage={this.setPage}
									setRowsPerPage={this.setRowsPerPage}
									// updateAreaManager={this.updateAreaManager}
									// addAreaManager={this.addAreaManager}
									openEditAreaManagerModal={this.openEditAreaManagerModal}
									history={this.props.history}
									handleUserFlagSwitchChange={this.handleUserFlagSwitchChange}
								/>
								: null
						}
					</Container>
				</Container> 

				<Snackbar
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={this.state.snackOpen}
					autoHideDuration={6000}
					onClose={this.handleSnackClose}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}>
					{muiAlert}
				</Snackbar>

				<Backdrop className={classes.backdrop} 
				open={this.props.areaManager.isFetching || this.props.isRegionFetching}
				>
					<CircularProgress color="inherit" />
				</Backdrop> 
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		areaManager: state.areaManager,
		regionalHead: state.regionalHead,
		isRegionFetching: state.region.isFetching,
		regions: state.region.regions,
		areasByRegions: state.area.areasByRegions,
		user: state.auth.user,
		area: state.area
	}
};
export default connect(mapStateToProps, {
	changeHeaderName,
	requestAreaManagers,
	getAllAreaManagers,
	requestRegions,
	getAllRegions,
	updateAreaManager,
	getAreasByRegions,
	addAreaManager,
	clearMsg,
	getRegionalHeadById
})(withStyles(styles)(Region));