import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import BusinessCard from './profilecards/BusinessCard';
import CustomerCard from './profilecards/CustomerCard';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import SendIcon from '@material-ui/icons/Send';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DoneIcon from '@material-ui/icons/Done';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { addActionComment, getReportedBusinessDetails, 
        clearSucessMsg, clearErrorMsg, 
        resolveReportedBusiness, showRpd } from "../../../actions/businessActions"

const styles = theme => ({
    content: {
        width: '100%',
    },
    container: {
        width: '100%',
        marginTop: '10px'
    },
    cmtCont: {
        width: '100%',
        marginTop: '10px'
    },
    desCont : {
        width: '100%',
        marginTop: '10px'
    },
    adminCont : {
      width: '100%',
      marginTop: '10px'
    },
    form : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width : 'inherit',
      marginTop: '20px'
    },
    textInput: {
      width : '100%'
    },
    avatar: {
      width: 'max-content',
      backgroundColor: 'white',
      color: 'black',
      fontSize: 'inherit'
    },
    header : {
      backgroundColor: 'rgb(35, 47, 62)',
      height: '40px',
      color: 'white',
      width: '100%',
    },
    button : {
      margin : '20px',
      height : '40px',
      width  : '145px',
    },
    backButton : {
      height : '40px',
      width  : '145px',
    },
    bottomButtonGrp : {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    resolveBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height : '40px',
      padding: '5px',
      backgroundColor: 'mediumseagreen',
      color: 'white'
    }
});



class ReportedBusinessDetails extends Component {

  constructor() {
    super();
    this.state = {
      sucessMsg : null,
      errorMsg : null,
      snackOpen: false,
      backDropOpen: false,
      actionCommentVal: '',
    };
  }

  onChange = (event) => {
    event.preventDefault();
    this.setState({ actionCommentVal: event.target.value });
    // this.state.actionCommentVal = event.target.value;
  }

  addComment = (event) => {
    event.preventDefault();
    let request = {};
    let actionComment = {};

    if(this.state.actionCommentVal.trim()){
      request.id = this.props.business.reportedBusiness._id;
      actionComment.comment = this.state.actionCommentVal;
      actionComment.date = new Date();
      request.actionComment = actionComment;
      
      this.props.addActionComment(request);
      this.setState({ backDropOpen: true });
    }    
  }

  close = (event) => {
    event.preventDefault();
    let request = {};
    let actionComment = {};

    request.id = this.props.business.reportedBusiness._id;

    if (this.state.actionCommentVal) {
      actionComment.comment = this.state.actionCommentVal;
      actionComment.date = new Date();
      request.actionComment = actionComment;
    }

    this.props.resolveReportedBusiness(request);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.business.sucessMsg !== null) {
          this.setState({ actionCommentVal: ''});
          if (this.props.business.sucessMsg === "RESOLVED_REPORT") {
            this.setState({ backDropOpen: false });
            this.setState({ sucessMsg : "resolved reported issue" })
            this.setState({ snackOpen: true });
          } else if (this.props.business.sucessMsg === "COMMENT_ADDED") {
            this.setState({ backDropOpen: false });
            this.setState({ sucessMsg : "comment added"})
            this.setState({ snackOpen: true });
          }
          this.props.clearSucessMsg();
          this.props.getReportedBusinessDetails(this.props.business.reportedBusiness._id);
    } else if (this.props.business.errorMsg !== null) {
        if (this.props.business.errorMsg === "RESOLVED_REPORT_ERROR") {
          this.setState({ backDropOpen: false });
          this.setState({ errorMsg : "resolved reported issue failed" })
          this.setState({ snackOpen: true });
        } else if (this.props.business.errorMsg === "COMMENT_ADDED_ERROR") {
          this.setState({ backDropOpen: false });
          this.setState({ errorMsg : "comment add failed"})
          this.setState({ snackOpen: true });
        }
        this.props.clearErrorMsg();
    }
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    if (this.state.sucessMsg === "resolved reported issue") {
      this.props.showRpd(false);
    }
    this.setState({ errorMsg: null });  
    this.setState({ sucessMsg: null });
  }

  goBack = () => {
    this.props.showRpd(false);
  }

  render() {
    const { classes } = this.props;
    let muiAlert = null;
    let actionTakenCards = null;
    let commentContainer = null;
    let reportedDate = new Date(this.props.business.reportedBusiness.dateOfReporting);  

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                    {this.state.sucessMsg}
                 </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                    {this.state.errorMsg}
                 </MuiAlert>
    } 

    if (this.props.pending) {
      commentContainer = <Grid container className={classes.cmtCont}>
              <Grid item xs={12}>  
                <TextField id="standard-multiline-static" label="Comment" className={classes.textInput}
                  defaultValue="" value={this.state.actionCommentVal} 
                  multiline rows="2" variant="outlined" onChange={this.onChange}/>
              </Grid>
              <Grid item xs={12} className={classes.bottomButtonGrp}>
                <Button variant="contained" className={classes.button} color="primary" 
                      endIcon={<SendIcon />}
                      disabled={this.state.backDropOpen} 
                      onClick={(event) => this.addComment(event)}>
                      Comment
                </Button>  
                <Button variant="contained" className={classes.button} color="primary"
                    endIcon={<DoneIcon/>}
                    disabled={this.state.backDropOpen}   
                    onClick={(event) => this.close(event)}>
                        Close Issue
                </Button>
              </Grid>
          </Grid>
    } else if (this.props.resolved || this.props.business.reportedBusiness.isActionTaken === "true") {
      commentContainer = <Paper variant="outlined" square className={classes.resolveBox}>
                            ISSUE RESOLVED AND CLOSED</Paper>            
    }

    if (this.props.business.reportedBusiness.hasOwnProperty("actionComment")) {
        actionTakenCards = this.props.business.reportedBusiness.actionComment.map(actionComment => {
          let dateObj = new Date(actionComment.date); 
          return (
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="name" variant="square" className={classes.avatar}>
                        {actionComment.adminId.firstName + " " + actionComment.adminId.lastName}
                      </Avatar>
                  }
              title={actionComment.comment}
              subheader={dateObj.toLocaleDateString("en-US",{weekday: "long", year: "numeric", 
                  month: "short",day: "numeric", hour: "numeric", minute: "numeric" })}/>
          </Card>
      )})
    }
   
    return (
        <Paper square="true" className={classes.content}> 
          <Grid container className={classes.header}>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <Button variant="contained" className={classes.backButton} color="primary"
                            startIcon={<ArrowBackIcon />}  
                            onClick={(event) => this.goBack(event)}>
                                Back
                  </Button>
                </Grid>
                <Grid item lg={8} md={8} xl={8} xs={12}>
                    <Typography variant="h6" gutterBottom>
                      Report Details : {this.props.business.reportedBusiness.businessId.businessName} 
                  </Typography>
                </Grid>
          </Grid>
          <Container className={classes.container}>
            <Grid container spacing={4}>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                    <BusinessCard business={this.props.business.reportedBusiness.businessId}/>
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                    <CustomerCard consumer={this.props.business.reportedBusiness.reportedBy}/>
                    <Container style={{ marginTop: '50px' }}> 
                        <Typography variant="subtitle1" gutterBottom>
                            <b>Reported Date</b> : {reportedDate.toLocaleDateString("en-US",
                       {weekday: "long", year: "numeric", month: "short",day: "numeric" })}
                        </Typography>  
                    </Container>
                </Grid>
            </Grid>
            <Grid container className={classes.desCont}>
              <Grid item xs={12}>  
                <Paper square="true">
                  <Typography variant="h6" style={{ padding: '0px 16px' }} gutterBottom>
                      Consumer Comments  
                  </Typography>
                  <Typography variant="subtitle1" style={{ padding: '0px 16px' }} gutterBottom>
                    {this.props.business.reportedBusiness.comment}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
            <Grid container className={classes.adminCont}>
                <Grid item xs={12}>  
                  <Paper square="true">
                  <Typography variant="h6" style={{ padding: '0px 16px' }} gutterBottom>
                        Admin Comments
                  </Typography>
                    {actionTakenCards}
                 </Paper>
                </Grid>
              </Grid>
              {commentContainer}
          </Container>
          <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={6000}
              onClose={this.handleSnackClose}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}>
                  {muiAlert}
          </Snackbar>
          <Backdrop className={classes.backdrop} open={this.state.backDropOpen}>
              <CircularProgress color="inherit" />
          </Backdrop>
        </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
      business: state.business,
      auth: state.auth
    }
};

export default connect(mapStateToProps, { addActionComment, getReportedBusinessDetails,
            clearSucessMsg, resolveReportedBusiness, showRpd, clearErrorMsg })(withStyles(styles)(ReportedBusinessDetails));