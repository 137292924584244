import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
// import Modal from '@material-ui/core/Modal';
import { addSpeciality, deleteSpeciality, xirifyEnv, updateSpeciality, specialityList } from '../../actions/specialityAction';
import { Badge, Divider } from "@material-ui/core";
import { CSVLink } from "react-csv";
import Tooltip from '@material-ui/core/Tooltip';

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
};

const getItemStyle = (isDragging, draggableStyle) => ({
    // styles we need to apply on draggables
    ...draggableStyle,

    ...(isDragging && {
        background: "rgb(235,235,235)"
    })
});

const styles = theme => ({
    // appBarSpacer: theme.mixins.toolbar,
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: '168px'
    },
    secondaryButton: {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        border: '1px solid #14B7DD',
        fontWeight: 'bold'
    },
    closeBadge: {
        color: 'grey',
        cursor: 'pointer'
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: '400px'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    editBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    arrowFwdBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    avatarGrey: {
        width: theme.spacing(10),
        height: theme.spacing(6),
    },
    img: {
        width: '43px',
        height: '43px',
        // paddingLeft: '10px',
        // paddingTop: '3px'
    },
    containerTable: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        marginTop: theme.spacing(3)
    },
    table: {
        justifyContent: 'left'
    },
    tableRow: {
        cursor: 'pointer',
        height: '60px'
    },
    tableBtn: {
        padding: '0px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    form: {
        margin: '5% 10%',
        maxWidth: '350px',
        // width: '80%',
        // height: '60%',
        backgroundColor: theme.palette.background.paper,
    },
    header: {
        height: '20%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    headerBar: {
        color: 'white',
        backgroundColor: theme.palette.primary.main
    },
    headGridTwoButton: {
        float: 'right',
        marginTop: '2px'
    },
    content: {
        // height: '50%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        height: '10%',
        margin: '20px 0px 10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    gridItem: {
        margin: '0px 20px',
    }
});

class Speciality extends Component {
    constructor() {
        super();
        this.state = {
            value: 0,
            modalAddOpen: false,
            modalEditOpen: false,
            modalDeleteOpen: false,
            snackOpen: false,
            noDelMsg: null,
            sucessMsg: null,
            showExcel: false,
            errorMsg: null,
            backDropOpen: false,
            isFetching: false,
            selectedSpeciality: null,
            specialityExists: null,
            inputErrorMessage: null,
            cloudinaryData: null,
            specialityName: "",
            specialityImage: null,
            specialityData: {},
            specialityList: [],
            cloudinaryFileData: null,
            isDisabled: true,
            excelDownloadList: [],
            specialityFile:null
        };
        this.onDragEndNew = this.onDragEndNew.bind(this)
        this.inputReference = React.createRef();
    }

    onDragEndNew = async (result) => {
        // console.log("dropped outside the list")
        if (!result.destination) {
            return
        }
        const specialityList = reorder(
            this.state.specialityList,
            result.source.index,
            result.destination.index
        )

        this.setState({
            specialityList
        });

    }

    fileUploadAction = () => this.inputReference.current.click();

    componentDidMount() {
        this.props.attrs.changeHeaderName("Speciality");
        this.getSpecialityList();
        this.getCloudinaryEnv();

    }

    getSpecialityList = async () => {
      try {
        let response = await specialityList();
    
            if (response && response.data) {
                this.setState({ specialityList: response.data });
    
                let excelList = response.data.map(item => ({ name: item.name }));
                this.setState({ excelDownloadList: excelList });
            } else {
                console.error("Error: Unable to fetch speciality list. Response or response data is undefined.");
            }
        } catch (error) {
            console.error("Error fetching speciality list:", error);
        }
    }
    

    getCloudinaryEnv = async () => {
        try {
        let res = await xirifyEnv();
            if (res && res.data) {
              this.setState({ cloudinaryData: res.data });
            } else {
                console.error("Error: Unable to fetch cloudinary environment. Response or response data is undefined.");
            }
        } catch (error) {
            console.error("Error fetching cloudinary environment:", error);
        }
    }    

    removeSpecialityImage = (e) => {
        this.setState({ specialityFile: null });
    }

    handleChange = async (e) => {
        const { target: { value } } = e;
        // console.log("value-------", value);
        if (e.target.files) {
            this.setState({ specialityImage: e.target.files[0] });
            let files = e.target.files;
            let data = new FormData();
            let folderPath = "Marketplace_Business";
            let temp = files[0].name.split(".");
            let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];

            data.append("file", files[0]);
            data.append("folder", folderPath);
            data.append(
                "public_id",
                `${fileName}`
            );
            data.append(
                "upload_preset",
                this.state.cloudinaryData.cloudinaryBusinessImagesUploadPreset
            );
            let res = await fetch(this.state.cloudinaryData.cloudinaryUploadUrl, {
                method: "POST",
                body: data,
            });

            let file = await res.json();
            this.setState({ specialityFile: file });

        } else {

            this.setState({ specialityName: value });
            if (!value.trim()) {
                this.setState({ inputErrorMessage: "Speciality Name Cannot Be Empty" });
                this.setState({ specialityName: "", isDisabled: true });
            } else {
                this.setState({ inputErrorMessage: null, isDisabled: false });
            }


        }

    }

    handleModalAddOpen = () => {
        this.setState({ modalAddOpen: true });
        this.setState({ specialityName: "" })
        this.setState({ selectedSpeciality: null });
        this.setState({ specialityImage: null, specialityFile: null });
    }

    handleModalAddClose = () => {
        this.setState({ modalAddOpen: false, inputErrorMessage: null });
        this.setState({ specialityImage: null, specialityFile: null, specialityName: '' });
    }

    handleRank = async () => {

        let payload = [];

        this.state.specialityList.forEach((e, i) => {
            // console.log("i", i);
            e.rank = i + 1;
            payload.push(e);
        });

        let response = await updateSpeciality(payload);

        if (response) {
            this.setState({ sucessMsg: "Updated Rank Successfully" });
        } else {
            this.setState({ errorMsg: "Error in updating the Rank" });
        }
        
    }

    handleDialogueDeleteOpen = (specialityType) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedSpeciality: specialityType });

    }

    handleDialogueDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
    }

    handleEditModalOpen = (specialityType) => {
        this.setState({ selectedSpeciality: { ...specialityType } });
        this.setState({ specialityName: specialityType.name });
        this.setState({ specialityImage: (specialityType?.image?.path) ? specialityType.image.path : null });
        if (specialityType?.image?.path) {
            this.setState({ specialityFile: { secure_url: specialityType?.image?.path } });

        } else {
            this.setState({ specialityFile: null });
        }
        this.setState({ modalEditOpen: true });

    }

    handleEditModalClose = () => {
        this.setState({ modalEditOpen: false });
        this.setState({ specialityName: "" });
        this.setState({ specialityImage: null, inputErrorMessage: null });
    }

    handleEditSubmit = async (event) => {

        event.preventDefault();

        if (!this.state.specialityName) {
            this.setState({ errorMsg: "Speciality Name Cannot Be Empty" });
            this.setState({ snackOpen: true });
            return;
        }
    
        let fileUpdated = false;
          if (this.state.specialityFile && !this.state.specialityFile.error) {
          fileUpdated = true;
          }
    
          if (!fileUpdated) {
            this.setState({ errorMsg: "Icon is mandatory" });
            this.setState({ snackOpen: true });
            return;
         }
    
            for (let i = 0; i < this.state.specialityList.length; i++) {
                if (this.state.selectedSpeciality._id !== this.state.specialityList[i]._id && this.state.specialityName.trim().toLowerCase() === this.state.specialityList[i].name.trim().toLowerCase()) {
                    this.setState({ inputErrorMessage: "Speciality With the Same Name Already Exists" });
                    return;
                }
            }

            // let res = await fetch(this.state.cloudinaryData.cloudinaryUploadUrl, {
            //     method: "POST",
            //     body: this.state.cloudinaryFileData,
            // });

            // let file = await res.json();
            let payload = {
                _id: this.state.selectedSpeciality._id,
                name: this.state.specialityName
            };

            if (fileUpdated) {
                payload["image"] = {
                    title: "speciality",
                    path: this.state.specialityFile.secure_url,
                    public_id: this.state.specialityFile.public_id
                }
            } else {
                payload.image = null;
            }

            let response = await updateSpeciality(payload);

            if (response) {
                this.setState({ sucessMsg: "Updated Speciality Successfully" });
                this.setState({ modalEditOpen: false });
                this.getSpecialityList();
            } else {
                this.setState({ errorMsg: "Error in updating the Speciality" });
                this.setState({ snackOpen: true });
            }
        }    

    handleAddSubmit = async (event) => {
        event.preventDefault();

        this.setState({ isDisabled: true });
        if (!this.state.specialityName) {
            this.setState({ errorMsg: "Speciality Name Cannot Be Empty" });
            this.setState({ snackOpen: true });
            this.setState({ isDisabled: false });
            return;
        }
    
        if (!this.state.specialityFile && !this.state.specialityImage) {
            this.setState({ errorMsg: "Icon is mandatory" });
            this.setState({ snackOpen: true });
            this.setState({ isDisabled: false });
            return;
        }
    
            for (let i = 0; i < this.state.specialityList.length; i++) {
                if (this.state.specialityName.trim().toLowerCase() === this.state.specialityList[i].name.trim().toLowerCase()) {
                    this.setState({ inputErrorMessage: "Speciality With the Same Name Already Exists" });
                    return;
                }
            }

            let payload = {
                name: this.state.specialityName
            };

            if (this.state.specialityFile && !this.state.specialityFile.error) {
                payload["image"] = {
                    title: "Speciality",
                    path: this.state.specialityFile.secure_url,
                    public_id: this.state.specialityFile.public_id
                }
            }

            let response = await addSpeciality(payload);

            if (response) {
                this.setState({ sucessMsg: "Added Speciality Successfully" });
                this.setState({ modalAddOpen: false, specialityName: '', specialityFile: null });
                this.getSpecialityList();
            } else {
                this.setState({ errorMsg: "Error in Adding the Speciality" });
                this.setState({ snackOpen: true });

            }
        


    }

    handleDeleteCategory = async () => {
        // request began and thus showing the spinner
        let response = await deleteSpeciality(this.state.selectedSpeciality._id);
        if (response) {
            this.setState({ modalDeleteOpen: false });
            this.getSpecialityList();
        }
    }

    handleSnackClose = () => {
        this.setState({ snackOpen: false });
        this.setState({ noDelMsg: null });
        this.setState({ errorMsg: null });
        this.setState({ sucessMsg: null });
    }

    showExcel = async () => {
        this.getSpecialityList();
        this.setState({ showExcel: true });
    }

    render() {

        const { classes } = this.props;
        let muiAlert = null;
        let headers = [
            { label: 'Speciality Name', key: 'name' }
        ];

        if (this.state.sucessMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                {this.state.sucessMsg}
            </MuiAlert>
        } else if (this.state.errorMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.errorMsg}
            </MuiAlert>
        } else if (this.state.noDelMsg) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.noDelMsg}
            </MuiAlert>
        } else if (this.state.specialityExists) {
            muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
                {this.state.categoryExists}
            </MuiAlert>
        }

        return (
            <Container>
                <Grid container justify="space-between">
                    <Grid item style={{ paddingRight: '460px' }}>
                        <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}
                            onClick={this.handleModalAddOpen}>
                            SPECIALITY
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" className={classes.button}
                            onClick={this.handleRank}>
                            SAVE SEQUENCE
                        </Button>
                    </Grid>
                    <Grid alignContent="right" >
                        {this.state.showExcel ?
                            (this.state.excelDownloadList.length > 0 ?
                                <CSVLink
                                    data={this.state.excelDownloadList}
                                    headers={headers}
                                    filename={"specialities.csv"}
                                    className="btn btn"
                                    target="_blank"
                                >
                                    <Tooltip title="Export to Excel">
                                        <img alt="Export To Excel" src="../../../images/exportToExcel.png" width="25" height="25" />
                                    </Tooltip>
                                </CSVLink>
                                : null)
                            :
                            <Button variant="contained" color="primary"
                                onClick={() => this.showExcel()}>
                                DOWNLOAD LIST
                            </Button>
                        }
                    </Grid>
                </Grid>
                {this.state.specialityList.length > 0 &&
                    <Container className={classes.containerTable}>
                        <TableContainer className={classes.table} component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableBody component={DroppableComponentNew(this.onDragEndNew)} >
                                    {this.state.specialityList.map((specialityType, index) => {
                                        return (
                                            <TableRow component={DraggableComponentNew(specialityType._id, index)} className={classes.tableRow} hover key={specialityType.name}>
                                                <TableCell align="left" style={{ width: '100px' }}>
                                                    {specialityType?.image?.path &&
                                                        <img style={{ "objectFit": "contain" }} variant="square" src={specialityType?.image?.path} alt=" " className={classes.avatarGrey} />
                                                    }
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                                                        {specialityType.name}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell align="right" className={classes.tableBtn} style={{ width: '100px' }}>
                                                    <div>
                                                        <EditIcon className={classes.editBtn} onClick={() => this.handleEditModalOpen(specialityType)} />
                                                    </div>
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableBtn} style={{ width: '100px' }}>
                                                    <DeleteIcon className={classes.deleteBtn} onClick={() => this.handleDialogueDeleteOpen(specialityType)} />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Container>
                }
                <Dialog
                    className={classes.modal}
                    open={this.state.modalAddOpen}
                    onClose={this.handleModalAddClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={false}
                >
                    <DialogTitle className={classes.headerBar} id="form-dialog-title">
                        <div >Add Speciality
                            <CloseIcon className={classes.headGridTwoButton} onClick={this.handleModalAddClose} />
                        </div>
                    </DialogTitle>
                    <form className={classes.form} onSubmit={this.handleAddSubmit}>
                        {/* Header of the FORM */}
                        {/* <Grid container style={{ 'paddingLeft': '24px' }} className={classes.header}>
                            <Grid item xs={11}>
                                <Typography variant="h5" gutterBottom>
                                    ADD SPECIALITY
                                </Typography>
                            </Grid>
                            <Grid item xs={1} >
                                <CloseIcon className={classes.headGridTwoButton} onClick={this.handleModalAddClose} />
                            </Grid>
                        </Grid> */}
                        {/* Content of the FORM */}

                        <Grid container className={classes.content}>
                            <TextField
                                name="addSpeciality"
                                label="Speciality Name"
                                // placeholder="Speciality Name"
                                value={this.state.specialityName}
                                fullWidth variant="outlined"
                                margin="normal"
                                inputProps={{
                                    maxLength: 30,
                                  }}
                                onChange={this.handleChange}
                                error={Boolean(this.state.inputErrorMessage)}
                                helperText={this.state.inputErrorMessage}
                            />

                            <Grid container style={{ margin: '20px 0px' }} alignItems="center" justify="space-around">
                                <Grid item xs={6} >
                                    {this.state.specialityFile ?
                                        <Badge className={classes.closeBadge} badgeContent={<CancelIcon onClick={this.removeSpecialityImage} />}>
                                            <div className={classes.img}>
                                                <img border="0" className={classes.img} src={this.state.specialityFile.secure_url} alt="" />
                                            </div >
                                        </Badge>
                                        :
                                        <div >
                                            <Typography >
                                                No File Chosen
                                            </Typography>                                            
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Button variant="contained" htmlFor="myInput2" style={{ float: 'right' }} onClick={this.fileUploadAction} color="primary" className={classes.secondaryButton}>
                                        {this.state.specialityFile ? 'Change Icon' : 'Add Icon'}
                                    </Button>
                                    <input
                                        ref={this.inputReference}
                                        id="myInput2"
                                        name="Speciality"
                                        style={{ display: 'none' }}
                                        type={"file"}
                                        onChange={this.handleChange}
                                        accept="image/x-png,image/jpeg"
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Divider></Divider>
                        {/* Button for  FORM */}
                        <Container className={classes.footer}>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    ///style={{ padding: "20px" }}
                                    margin="normal"
                                    color="primary"
                                    disabled={this.state.isDisabled}
                                    onClick={this.handleAddSubmit}>
                                    Confirm
                                </Button>
                            </div>
                        </Container>
                    </form>
                </Dialog>
                <Dialog
                    className={classes.modal}
                    open={this.state.modalEditOpen}
                    onClose={this.handleEditModalClose}
                    closeAfterTransition
                    fullWidth={true}
                    maxWidth={false}
                >
                    <DialogTitle className={classes.headerBar} id="form-dialog-title">
                        <div >Edit Speciality
                            <CloseIcon className={classes.headGridTwoButton} onClick={this.handleEditModalClose} />
                        </div>
                    </DialogTitle>
                    <form className={classes.form} onSubmit={this.handleEditSubmit}>
                        {/* Header of the FORM */}
                        {/* <Grid container style={{ 'paddingLeft': '24px' }} className={classes.header} >
                            <Grid item xs={11}>
                                <Typography variant="h5" gutterBottom>
                                    EDIT SPECIALITY
                                </Typography>
                            </Grid>
                            <Grid item xs={1} >
                                <CloseIcon className={classes.headGridTwoButton} onClick={this.handleEditModalClose} />
                            </Grid>
                        </Grid> */}
                        {/* Content of the FORM */}

                        <Grid container className={classes.content}>
                            <TextField
                                name="editSpeciality"
                                label="Speciality Name"
                                // placeholder="Speciality Name"
                                value={this.state.specialityName}
                                fullWidth variant="outlined"
                                margin="normal"
                                inputProps={{
                                    maxLength:30,
                                }}
                                onChange={this.handleChange}
                                error={Boolean(this.state.inputErrorMessage)}
                                helperText={this.state.inputErrorMessage}
                            />
                            <Grid container style={{ margin: '20px 0px' }} alignItems="center" justify="space-around">
                                <Grid item xs={6} >
                                    {this.state.specialityFile ?
                                        <Badge className={classes.closeBadge} badgeContent={<CancelIcon onClick={this.removeSpecialityImage} />}>
                                            <div className={classes.img}>
                                                <img border="0" className={classes.img} src={this.state.specialityFile.secure_url} alt="" />
                                            </div >
                                        </Badge>
                                        :
                                        <div >
                                            <Typography >
                                                No File Chosen
                                            </Typography>
                                        </div>
                                    }
                                </Grid>
                                <Grid item xs={6} >
                                    <Button variant="contained" htmlFor="myInput2" style={{ float: 'right' }} onClick={this.fileUploadAction} color="primary" className={classes.secondaryButton}>
                                        {this.state.specialityFile ? 'Change Icon' : 'Add Icon'}
                                    </Button>
                                    <input
                                        ref={this.inputReference}
                                        id="myInput2"
                                        name="Speciality"
                                        style={{ display: 'none' }}
                                        type={"file"}
                                        onChange={this.handleChange}
                                        accept="image/x-png,image/jpeg"
                                    />
                                </Grid>

                            </Grid>

                            {/* <Grid container>
                                <Grid item xs={1} className={classes.img}>
                                    <a href={this.state.specialityImage} target="_blank" rel="noopener noreferrer">
                                        <img border="0" className={classes.img} src={this.state?.specialityImage} alt="" />
                                    </a>
                                </Grid >
                                <Grid item xs={3} >
                                    <Button variant="contained" htmlFor="myInput2" onClick={this.fileUploadAction} color="primary" className={classes.button}>
                                        Change Icon
                                    </Button>
                                    <input
                                        ref={this.inputReference}
                                        id="myInput2"
                                        name="Speciality"
                                        style={{ display: 'none' }}
                                        type={"file"}
                                        onChange={this.handleChange}
                                        accept="image/x-png,image/jpeg"
                                    />
                                </Grid>
                            </Grid> */}
                        </Grid>
                        <Divider></Divider>
                        {/* Button for  FORM */}
                        <Container className={classes.footer}>
                            <div>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    ///style={{ padding: "20px" }}
                                    margin="normal"
                                    color="primary"
                                    onClick={this.handleEditSubmit}>
                                    Confirm
                                </Button>
                            </div>
                        </Container>
                    </form>
                </Dialog>
                <Dialog
                    open={this.state.modalDeleteOpen}
                    onClose={this.handleDialogueDeleteClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Delete </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you want to delete the speciality?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={this.handleDialogueDeleteClose} className={classes.secondaryButton}>
                            No
                        </Button>
                        <Button variant="contained" onClick={this.handleDeleteCategory} color="primary" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={6000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>

            </Container >
        )

    }
}

const DraggableComponentNew = (id, index) => (props) => {
    return (
        <Draggable draggableId={id} index={index}>
            {(provided, snapshot) => (
                <TableRow
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

                    {...props}
                >
                    {props.children}
                </TableRow>
            )}
        </Draggable>
    )
}

const DroppableComponentNew = (onDragEnd) => (props) => {

        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'1'} direction="vertical">
                    {(provided) => {
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        )
                    }}
                </Droppable>
            </DragDropContext>
        )
    }

export default (withStyles(styles)(Speciality));