import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CategoryForm from './CategoryForm';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { changeHeaderName } from "../../actions/dashboardActions";
import {
  getAllCategoryType,
  setCatergoryHistory,
  getCategoryTree,
  removeCategoryHistory,  
  addToCategory,
  selectCategory,
  deleteCategory,
  editCategory,
  requestCategories,
  clearMsg,
  getCategoriesList,
  changeRank
} from "../../actions/categoriesActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
})
const styles = theme => ({
  // appBarSpacer: theme.mixins.toolbar,
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    width: '168px'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px'
  },
  deleteBtn: {
    cursor: 'pointer',
    color: 'grey',
    margin: "0px 10px"
  },
  editBtn: {
    cursor: 'pointer',
    color: 'grey',
    margin: "0px 10px"
  },
  arrowFwdBtn: {
    cursor: 'pointer',
    color: 'grey',
    margin: "0px 10px"
  },
  avatarGrey: {
    width: theme.spacing(10),
    height: theme.spacing(6),
  },
  avatarWhite: {
    width: theme.spacing(10),
    height: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
  },
  containerTable: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3)
  },
  table: {
    width: '80%'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableBtn: {
    padding: '0px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});


class Categories extends Component {

  constructor() {
    super();
    this.state = {
      modalAddOpen: false,
      modalEditOpen: false,
      modalDeleteOpen: false,
      typeToAdd: null,
      snackOpen: false,
      noDelMsg: null,
      sucessMsg: null,
      errorMsg: null,
      categoryExists: null,
      backDropOpen: false,
      // showExcel: false,
      categoriesList: []
    };
    this.onDragEndNew = this.onDragEndNew.bind(this)
  }

  onDragEndNew = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const categoriesList = reorder(
      this.state.categoriesList,
      result.source.index,
      result.destination.index
    )

    this.setState({
      categoriesList
    })
    // console.log(`dragEnd ${result.source.index} to  ${result.destination.index}`)
    let payload = {
      oldRank: result.source.index + 1,
      newRank: result.destination.index + 1,
      parentCategory: this.props.categories.categoryHistory[this.props.categories.categoryHistory.length - 1]
    }
    await this.props.changeRank(payload);
    // this.props.getCategoryTree(this.props.categories.categoryHistory[this.props.categories.categoryHistory.length-1]);

  }
  // get all CategoryType at the Top Level
  componentDidMount() {
    this.props.changeHeaderName("Categories");
    this.props.getAllCategoryType();
    this.props.removeCategoryHistory(-1);
    this.props.clearMsg();
    this.props.setCategoriesProps(this.props);
    this.setState({ noDelMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ sucessMsg: null });
  }
  // showExcel = async () => {
  //   await this.props.getCategoriesList();
  //   await this.setState({ showExcel: true });
  // }
  /* Handlers for Edit in the Category List */
  handleEditModalOpen = (category) => {
    this.props.selectCategory(category);
    this.setState({ modalEditOpen: true });
  }

  handleEditModalClose = () => {
    this.setState({ modalEditOpen: false });
  }

  /* Handlers for Add  in the Category List */
  handleModalAddOpen = (type) => {
    this.setState({ typeToAdd: type });
    this.setState({ modalAddOpen: true });
  }

  handleModalAddClose = () => {
    this.setState({ modalAddOpen: false });
  }

  /* Handlers for Delete in the Category List */
  handleDialogueDeleteOpen = (category) => {

    /*
     * If categorytype, category and subcategory does not have children
     * then only open the Delete Dialogue box to Delete them
     * Or else open the snackbar to give out the error message "Cannot Delete"
     */

    if (category.type === 'categorytype') {
      if (category.childrenNames.length > 0) {
        this.setState({ noDelMsg: "Cannot Delete as " + category.name + " has children" });
        this.setState({ snackOpen: true });
        return;
      }
    } else if (category.type === 'category') {
      // console.log("category ----------->",category)
        if (category.businessId) {         
          this.setState({ noDelMsg: `Please remove all businesses from the category ${category.name} before deleting`});
          this.setState({ snackOpen: true });
          return;        
      }
      if (category.hasOwnProperty("services")) {
        if (category.services.length > 0) {
          this.setState({ noDelMsg: "Cannot Delete as " + category.name + " has children" });
          this.setState({ snackOpen: true });
          return;
        }
      }
    } else if (category.type === 'subcategory') {
      if (category.children.length > 0) {
        this.setState({ noDelMsg: "Cannot Delete as " + category.name + " has children" });
        this.setState({ snackOpen: true });
        return;
      }
    }

    this.props.selectCategory(category);
    this.setState({ modalDeleteOpen: true });
  }

  handleDialogueDeleteClose = () => {
    this.setState({ modalDeleteOpen: false });
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.props.clearMsg();
    this.setState({ noDelMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ sucessMsg: null });
  }

  /* End of all Handlers for Add Edit and Delete Buttons in the Category List */

  /* 
   * In handleNextLevel we add to category history. 
   * Then we get the category documents under the categorytype,
   * category and subcategory
   */

  handleNextLevel(category) {
    if (category.type !== 'service') {
      this.props.setCatergoryHistory(category);
      // request began and thus showing the spinner
      this.props.requestCategories();
      // request to fetch the categories
      this.props.getCategoryTree(category);
    }
  }

  /* 
   * Called when the BreadCrumb is clicked
   */
  handleBreadCrumbClick = (index) => {
    index = index - 1;
    this.props.removeCategoryHistory(index);
    // request began and thus showing the spinner
    this.props.requestCategories();
    // request to fetch the categories
    if (index >= 0) {
      this.props.getCategoryTree(this.props.categories.categoryHistory[index]);
    } else {
      this.props.getAllCategoryType();
    }
  }

  addCategory = (props) => {
    let categoryObj = {};

    for (let i = 0; i < this.props.categories.categories.length; i++) {
      if (this.props.categories.categories[i].name.toUpperCase() === props.name.toUpperCase()) {
        this.handleModalAddClose();
        this.setState({ categoryExists: props.name + " name already present. Use different name." });
        this.setState({ snackOpen: true });
        return;
      }
    }

    /*
      * We calculate the lastIndex to get the parent 
      * of category object to Add. Looking at the Parent 
      * we make a decision the type to add under the parent
      *
      */

    if (this.props.categories.categoryHistory.length === 0) {
      categoryObj.type = 'categorytype';
      categoryObj.parentId = null;
    } else {

      /*
       * We calculate the lastIndex to get the parent 
       * of category object to Add. Looking at the Parent 
       * we make a decision on what type to add under the parent
       *
       */

      let lastIndex = this.props.categories.categoryHistory.length - 1;
      let categorytype = this.props.categories.categoryHistory[lastIndex].type;
      if (categorytype === 'categorytype') {
        // Add a category inside categorytype
        categoryObj.type = 'category';
      } else if (categorytype === 'category') {

        /*
         * categorytype = category. type will be of the user typed 
         * Add subcategory or Add services button.
         */

        if (this.state.typeToAdd === "subcategory") {
          categoryObj.type = 'subcategory';
        }
        if (this.state.typeToAdd === "service") {
          categoryObj.type = 'service';
        }

      } else if (categorytype === 'subcategory') {
        categoryObj.type = 'service';
      }

      // Set the parentId for the new category
      categoryObj.parentId = this.props.categories.categoryHistory[lastIndex]._id;
    }

    // Create the category object to add
    categoryObj.name = props.name;
    categoryObj.icon = {};
    // categoryObj.icon.white = props.icon.whiteImgIcon.name.split('.')[0];
    categoryObj.icon.whiteImgIcon = props.icon.whiteImgIcon;
    // categoryObj.icon.grey = props.icon.greyImgIcon.name.split('.')[0];
    categoryObj.icon.greyImgIcon = props.icon.greyImgIcon;
    categoryObj.icon.colouredImgIcon = props.icon.colouredImgIcon
    // request began and thus showing the spinner
    this.props.requestCategories();
    categoryObj.env = this.props.env;
    // Add the category
    this.props.addToCategory(categoryObj);
    this.handleModalAddClose();
  }

  editCategory = (props) => {
    let categoryObj = {};

    for (let i = 0; i < this.props.categories.categories.length; i++) {
      if (this.props.categories.categories[i]._id !== props._id && this.props.categories.categories[i].name === props.name) {
        this.handleEditModalClose();
        this.setState({ categoryExists: props.name + " name already present. Use different name." });
        this.setState({ snackOpen: true });
        return;
      }
    }
    categoryObj.changedCategoryType = props.changedCategoryType;
    categoryObj.name = props.name;
    categoryObj.icon = {};
    categoryObj.id = this.props.categories.selectedCategory._id;

    // If Files or not changed or one of file is changed
    categoryObj.icon.white = props.icon.white;
    categoryObj.icon.grey = props.icon.grey;
    categoryObj.icon.coloured = props.icon.coloured;
    if (props.icon.hasOwnProperty("whiteImgIcon")) {
      // categoryObj.icon.white = props.icon.whiteImgIcon.name.split('.')[0];
      categoryObj.icon.whiteImgIcon = props.icon.whiteImgIcon;
    }

    if (props.icon.hasOwnProperty("greyImgIcon")) {
      // categoryObj.icon.grey = props.icon.greyImgIcon.name.split('.')[0];
      categoryObj.icon.greyImgIcon = props.icon.greyImgIcon;
    }

    if (props.icon.hasOwnProperty("colouredImgIcon")) {
      // categoryObj.icon.grey = props.icon.greyImgIcon.name.split('.')[0];
      categoryObj.icon.colouredImgIcon = props.icon.colouredImgIcon;
    }

    // request began and thus showing the spinner
    this.props.requestCategories();
    categoryObj.env = this.props.env;
    this.props.editCategory(categoryObj);
    this.handleEditModalClose();
  }

  handleDeleteCategory = (category) => {
    // request began and thus showing the spinner
    // console.log("this.props.categories.selectedCategory handleDeleteCategory------->",this.props.categories.selectedCategory)
    this.props.requestCategories();

    this.props.deleteCategory(category);
    this.setState({ modalDeleteOpen: false });
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.categories.sucessMsg !== prevState.sucessMsg) {
      return { sucessMsg: nextProps.categories.sucessMsg };
    } else if (nextProps.categories.errorMsg !== prevState.errorMsg) {
      return { errorMsg: nextProps.categories.errorMsg };
    }
  }

  componentDidUpdate(prevProps, prevState) {

    let lastIndex = this.props.categories.categoryHistory.length - 1;

    if ((prevState.sucessMsg === null && this.state.sucessMsg) ||
      (prevState.errorMsg === null && this.state.errorMsg)) {
      this.setState({ snackOpen: true });
    }


    if (prevState.sucessMsg !== this.state.sucessMsg) {
      // Refresh the category list with the new category entry
      if (this.props.categories.categoryHistory.length === 0) {
        this.props.getAllCategoryType();
      } else {
        this.props.getCategoryTree(this.props.categories.categoryHistory[lastIndex]);
      }
    }
    if (prevProps.categories.categories !== this.props.categories.categories) {
      this.setState({ categoriesList: this.props.categories.categories });
    }
  }


  render() {
    const { classes } = this.props;
    let breadCrumbList;
    let chLastIndex = this.props.categories.categoryHistory.length - 1;
    let lastBreadCrumbName;
    let breadCrumbLast = null;
    let additionalButton = null;
    let typeToAdd = null;
    let muiAlert = null
    /*
     * Below we create the BreadCrumb for the page and also set the
     * typeToAdd variable which stores which type to add according to the 
     * Page we are onto.
     */

    if (this.props.categories.categoryHistory.length === 0) {
      // BreadCrumb creation for type = categorytype
      lastBreadCrumbName = 'Category Type';
      typeToAdd = 'Category Type';
      breadCrumbList = <Link color="inherit">
        <Typography color="textPrimary" variant="h5" gutterBottom>{lastBreadCrumbName}</Typography>
      </Link>
    } else {
      // Create the breadcrumb list with objects in categoryHistory
      breadCrumbList = this.props.categories.categoryHistory.map((categories, index) => {
        // console.log("categories.type", categories.type);
        return (
          <Link color="inherit" onClick={() => this.handleBreadCrumbClick(index)}>
            <Typography color="textPrimary" variant="h5" gutterBottom>{(categories.type.toLowerCase() === "categorytype") ? `Category Type` : categories.type}</Typography>
          </Link>
        )
      });

      if (this.props.categories.categoryHistory[chLastIndex].type === 'categorytype') {
        // last BreadCrumb is of Type = category
        lastBreadCrumbName = 'Category';
        typeToAdd = 'category';
      } else if (this.props.categories.categoryHistory[chLastIndex].type === 'category') {
        /*
         * Inside Type = category there can be subcategory or services
         */

        if (this.props.categories.categoryHistory[chLastIndex].hasOwnProperty("subcategory")) {
          lastBreadCrumbName = 'subcategory';
          typeToAdd = 'subcategory';
        }

        if (this.props.categories.categoryHistory[chLastIndex].hasOwnProperty("services")) {
          lastBreadCrumbName = 'service';
          typeToAdd = 'service';
        }

        /*
         *  If lastBreadCrumbName == NULL that means category is empty and Admin can add
         *  either subcategory or service inside it.
         */

        if (!lastBreadCrumbName) {
          if (this.props.categories.categories.length > 0) {

            /*
             * case when a subcategory or service is added to a empty
             * category
             */
            typeToAdd = this.props.categories.categories[0].type;
            lastBreadCrumbName = this.props.categories.categories[0].type;
          } else {
            lastBreadCrumbName = '';
            typeToAdd = 'subcategory';
            // Additional button of service is will be shown on the Page
            /* additionalButton = <Grid item xs={3}>
              <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}
                onClick={() => this.handleModalAddOpen("service")}>
                service
                                    </Button>
            </Grid> */
          }
        }
      } else if (this.props.categories.categoryHistory[chLastIndex].type === 'subcategory') {
        /*
         * Inside Type = subcategory there only services
         */
        lastBreadCrumbName = 'service';
        typeToAdd = 'service';
      }
      // Creation of the last BreadCrumb
      breadCrumbLast = <Typography color="textPrimary" variant="h5" gutterBottom>{lastBreadCrumbName}</Typography>;
    }

    if (this.state.sucessMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
        {this.state.sucessMsg}
      </MuiAlert>
    } else if (this.state.errorMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.errorMsg}
      </MuiAlert>
    } else if (this.state.noDelMsg) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.noDelMsg}
      </MuiAlert>
    } else if (this.state.categoryExists) {
      muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
        {this.state.categoryExists}
      </MuiAlert>
    }
    
    return (
      <Container maxWidth="lg">

        {/* <div className={classes.appBarSpacer} /> */}
        {/* BreadCrumb Container */}
        <Container style={{ marginTop: '24px', marginBottom: '8px' }}>
          <Breadcrumbs>
            {breadCrumbList}
            {breadCrumbLast}
          </Breadcrumbs>
        </Container>
        {/* Add categorytype,category,subcategory and service button */}
        <Grid container style={{ margin: '8px 0px', 'paddingLeft': '24px' }}>
          <Grid item xs={3}>
            <Button variant="contained" color="primary" className={classes.button} startIcon={<AddIcon />}
              onClick={() => this.handleModalAddOpen(typeToAdd)}>
              {typeToAdd}
            </Button>
          </Grid>
          <Grid item xs={6}>
          </Grid>
          {/* <Grid item xs={3}>
            {this.state.showExcel ?
              (this.props.categories && this.props.categories.categoriesList ?
                <CSVLink
                  data={this.props.categories.categoriesList}
                  headers={headers}
                  filename={"categories.csv"}
                  className="btn btn"
                  target="_blank"
                >
                  <Tooltip title="Export to Excel">
                    <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                  </Tooltip>
                </CSVLink>
                : null)
              :
              <Button variant="contained" color="secondary"
                onClick={() => this.showExcel()}>
                Export Business List
              </Button>
            }
          </Grid> */}

          {additionalButton}
        </Grid>
        {/* Main Category table list on the Page */}
        <Container className={classes.containerTable}>
          <TableContainer className={classes.table} component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody component={DroppableComponentNew(this.onDragEndNew)} >
                {this.state.categoriesList.map((categoryType, index) => {
                  // let iconGrey = "2.0/" + categoryType.icon.grey + ".svg";
                  // let iconWhite = "2.0/" + categoryType.icon.white + ".svg";

                  let goToNextLevel = true;
                  if (categoryType.type === 'category' || categoryType.type === 'subcategory') {

                    goToNextLevel = false;
                  }
                  /* if (categoryType.type === 'category' && !categoryType.subcategory) {
                    goToNextLevel = false;
                  } */

                  return (
                    <TableRow component={DraggableComponentNew(categoryType._id, index)} className={classes.tableRow} hover key={categoryType.name}>
                      <TableCell align="left">
                        <Avatar imgProps={{ style: { "objectFit": "contain" } }} variant="square" alt={categoryType.name} className={classes.avatarGrey} src={categoryType.icon.grey} />
                      </TableCell>
                      <TableCell align="left">
                        <Avatar imgProps={{ style: { "objectFit": "contain" } }} variant="square" alt={categoryType.name} className={classes.avatarWhite} src={categoryType.icon.white} />
                      </TableCell>
                      <TableCell align="left">
                        {categoryType && categoryType.icon && categoryType.icon.coloured ?
                          <Avatar imgProps={{ style: { "objectFit": "contain" } }} variant="square" alt={categoryType.name} className={classes.avatarGrey} src={categoryType.icon.coloured} />
                          :
                          <Avatar imgProps={{ style: { "objectFit": "contain" } }} variant="square" alt={categoryType.name} className={classes.avatarGrey} src="https://res.cloudinary.com/xirifydev/image/upload/v1628497507/Xirify_Icons/gsbfi7ma89gpmoldtmuz.svg" />
                        }
                      </TableCell>
                      <TableCell align="left">
                        <Typography color="textPrimary" variant="subtitle1" gutterBottom>
                          {categoryType.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left" className={classes.tableBtn}>
                        <DeleteIcon className={classes.deleteBtn} onClick={() => this.handleDialogueDeleteOpen(categoryType)} />
                      </TableCell>
                      <TableCell align="left" className={classes.tableBtn}>
                        <EditIcon className={classes.editBtn} onClick={() => this.handleEditModalOpen(categoryType)} />
                      </TableCell>
                      {goToNextLevel ? <TableCell align="left" className={classes.tableBtn}>
                        <ArrowForwardIosIcon className={classes.arrowFwdBtn} onClick={() => this.handleNextLevel(categoryType)} />
                      </TableCell> : <TableCell align="left"></TableCell>}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
        {/* Add categorytype, category,subcategory and services Modal */}
        <Modal open={this.state.modalAddOpen}
          onClose={this.handleModalAddClose}
          className={classes.modal}
          closeAfterTransition>
          <CategoryForm
            addForm
            type={lastBreadCrumbName}
            onSubmit={this.addCategory}
            onClose={this.handleModalAddClose}
          />
        </Modal>
        {/* Edit categorytype, category,subcategory and services Modal */}
        <Modal
          className={classes.modal}
          open={this.state.modalEditOpen}
          onClose={this.handleEditModalClose}
          closeAfterTransition>
          <CategoryForm
            editForm
            initialValues={_.pick(this.props.categories.selectedCategory, '_id', 'name', 'icon.white', 'icon.grey', 'icon.coloured')}
            onSubmit={this.editCategory}
            onClose={this.handleEditModalClose}
          />
        </Modal>
        {/* Delete Dialogue for categorytype, category,subcategory and services */}
        <Dialog
          open={this.state.modalDeleteOpen}
          onClose={this.handleDialogueDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete {this.props.categories.selectedCategory.type} {this.props.categories.selectedCategory.name}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete {this.props.categories.selectedCategory.type} {this.props.categories.selectedCategory.name} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogueDeleteClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.handleDeleteCategory(this.props.categories.selectedCategory)} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {/* Snackbar for all the Actions */}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}>
          {muiAlert}
        </Snackbar>
        {/* backdrop for all the HTTP actions */}
        <Backdrop className={classes.backdrop} open={this.props.categories.isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}
const DraggableComponentNew = (id, index) => (props) => {
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}

          {...props}
        >
          {props.children}
        </TableRow>
      )}
    </Draggable>
  )
}

const DroppableComponentNew = (onDragEnd) => (props) => {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'1'} direction="vertical">
          {(provided) => {
            return (
              <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                {props.children}
                {provided.placeholder}
              </TableBody>
            )
          }}
        </Droppable>
      </DragDropContext>
    )
  }
const mapStateToProps = state => {
  return {
    categories: state.categories,
    env: state.auth.env
  }
};

export default connect(mapStateToProps, {
  changeHeaderName, getAllCategoryType, setCatergoryHistory,
  getCategoryTree, removeCategoryHistory, addToCategory,
  selectCategory, deleteCategory, clearMsg, editCategory, requestCategories, getCategoriesList, changeRank
})(withStyles(styles)(Categories));