import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { changeHeaderName } from '../../actions/dashboardActions';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CampaignAddForm from './campaignAddForm';
import CampaignEditForm from './campaignEditForm';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Table from '@material-ui/core/Table';
import { lightBlue } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import { 
    getAllCampaignList, 
    addNewCampaign, 
    checkPromoCode, 
    updateCampaignStatus, 
    deleteCampaign, 
    getCampaignExportToExcel, 
    editCampaign, 
    clearMsg,
    requestCampaign } from '../../actions/campaignActions';
import { getBusinessListApprovedForCampaigns} from "../../actions/businessActions";
import {getCategoriesByType} from "../../actions/categoriesActions";
import { fetchEnvs } from '../../actions/authActions';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import TablePagination from '@material-ui/core/TablePagination';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../utils/TabPanel"
import { ReactComponent as BusinessSVG } from '../../images/AssignBusiness.svg';
import {unique} from 'underscore';
import AssignBusinessModal from './AssignBusinessModal';
import OfferCodeCampaign from './OfferCodeCampaign';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


const styles = theme => ({
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	buttonContainer: {
		width: '100%',
		paddingTop: '10px'
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
	},
	appBarSpacer: theme.mixins.toolbar,
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
	snackClose: {
		padding: theme.spacing(0.5),
	},
    containerin: {
        width: '90%',
        height: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "150px",
        width: "100%",
      },
      lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    
    disabledrow: {
        backgroundColor: '#dcdcdc',
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
      },
      tableCellSmall11: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 150,
        minWidth:120,
        width: 150,
      },
      tableCellSmall12: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 200,
        minWidth:140,
        width: 200,
      },
      tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 700,
        minWidth:300,
        width: 700,
      },
    tableRow: {
        cursor: 'pointer'
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
});

let arrayList = [];
let totalCount;


class Campaigns extends Component {
    constructor() {
		super();
		this.state = JSON.parse(window.localStorage.getItem('campaignBusinessDetails')) || {
			modalOpen: false,
			snackOpen: false,
			sucessMsg: null,
			errorMsg: null,
			clearSearch: false,
			order: -1,
			orderBy: 'name',
			page: 0,
			rowsPerPage: 10,
			editMode: false,
            campaignState: [],
            campaignName: "",
            rewardType: [],
            campaignTimeDateRangeEnd: "",
            campaignTimeDateRangeStart: "",
            campaignId: "",
            campaignStatus: "",
            sponsoredBy: "",
            showDownloadIcon: false,
            headers: [
                { key: 'campaignId', label: 'Campaign ID' },
                { key: 'campaignState', label: 'Campaign State' },
                { key: 'campaignName', label: 'Campaign Name' },
                { key: 'sponsoredBy', label: 'Sponsored By' },
                { key: 'rewardType', label: 'Reward Type' },
                { key: 'couponName', label: 'Coupon Name'},
                { key: 'totalCouponAmountPerUser', label: 'Coupon Total Amount Per User'},
                { key: 'valuePerCoupon', label: 'Coupon Value'},
                { key: 'campaignStartDate', label: 'Campaign Start Date'},
                { key: 'campaignEndDate', label: 'Campaign End Date'},
                { key: 'couponValidityDays', label: 'Coupon Validity Days'},
                { key: 'couponExpiryDate', label: 'Coupon Expiry Date'},
                { key: 'description', label: 'Coupon Description'},
                { key: 'actualCouponsIssuedAmount', label: 'Total Coupon Amount'},
                { key: 'totalCouponsInExecution', label: 'Coupons Issued'},
                { key: 'totalClaimedCoupon', label: 'Coupons Claimed'},
                { key: 'couponsRemainingToIssue', label: 'Coupons Remaining'},
                { key: 'expiredCoupons', label: 'Coupons Expired'},
                { key: 'totalBudget', label: 'Budget Total'},
                { key: 'claimedBudget', label:'Budget Claimed'},
                { key: 'remainingBudget', label:'Budget Remaining'},
                { key: 'campaignStatus', label:'Campaign Status'},
                { key: 'delete', label: 'isDelete'},
                { key: 'codes', label: 'Promo Codes'},
                { key: 'businesses', label: 'Businesses'}
            ],
            campaignImage: "",
            responsePromoCodeError: "",
            // stateOfCampaign: "",
            campaignData: {},
            campaignIdFilter: "",
            promocodes: [],
            duplicateCampaignFlag: false,
            editCampaignFlag: false,
            modalDeleteOpen: false,
            selectedCampaign: null,
            selectedCampaignBusinessList:[],
            selectedCampaignConsumerList: [],
            campaignCode: "",
            value: 0,
		};
	}

    async componentDidMount() {
        await  this.fetchCampaignWithFilter();
        this.props.changeHeaderName("Campaigns");
        this.props.getCategoriesByType("category");
        this.props.requestCampaign();
        this.props.getBusinessListApprovedForCampaigns()
        this.props.fetchEnvs();
        // this.props.getAllCampaignList();
        window.addEventListener("beforeunload", () => localStorage.removeItem('campaignBusinessDetails'));
        this.setState({campaignCode: ( this.props.history.campaignCode && this.props.history.campaignCode === "offerCode") ? "offerCode" : "promoCode", value: (this.props.history.tabValueIndex && this.props.history.tabValueIndex === 1) ? 1 : 0})
        
    };

    fetchCampaignWithFilter = async ()=>{
        await  this.props.requestCampaign();
        // this.props.campaign.campaignListData.campaignList = [];
        // this.props.campaign.campaignListData.total = 0; 
        let payload ={
            order: Number(this.state.order),
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            
            campaignState:this.state.campaignState,
            campaignName:this.state.campaignName.trim(),
            campaignId:this.state.campaignId.trim(),
            rewardType :this.state.rewardType,
            campaignTimeDateRangeStart : this.state.campaignTimeDateRangeStart,
            campaignTimeDateRangeEnd:this.state.campaignTimeDateRangeEnd,
            campaignStatus:this.state.campaignStatus,
            sponsoredBy:this.state.sponsoredBy,
        }
       this.props.getAllCampaignList(payload);
       this.setState({showDownloadIcon:false});
       localStorage.setItem("campaignBusinessDetails", JSON.stringify(this.state));

    };

    handleSnackClose = () => {
		this.setState({ snackOpen: false });
		this.setState({ modalOpen: false });
		this.setState({ sucessMsg: null });
		this.setState({ errorMsg: null, editMode: false });
		// this.fetchCampaignWithFilter();//getAllCampaignList();
	};

    componentDidUpdate(prevProps, prevState) {
        if(this.props.campaign.isFetching===false){
		    if (this.props.campaign.sucessMsg) {
		    	this.setState({ sucessMsg: this.props.campaign.sucessMsg })
		    	this.setState({ snackOpen: true });
		    	this.setState({ modalOpen: false });
		    	this.props.clearMsg();
		    	// this.fetchCampaignWithFilter();//getAllCampaignList();
		    } else if (this.props.campaign.errorMsg) {
		    	this.setState({ errorMsg: this.props.campaign.errorMsg })
		    	this.setState({ snackOpen: true });
		    	this.props.clearMsg();
		    }
        }
	};

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleModalOpen = () => {
        this.setState({ modalOpen: true, responsePromoCodeError: "" });
        arrayList = [];
    };

    handleModalClose = () => {
        this.setState({ modalOpen: false, duplicateCampaignFlag: false,
            editCampaignFlag: false, responsePromoCodeError: "", promocodes: []  });
    };

    handleAssignBusinessModalOpen = (isOpen) => {
        this.setState({ assignBusinessModalOpen: isOpen});
    };

    handleAssignShopperModalOpen = (isOpen) => {
        this.setState({ assignShopperModalOpen: isOpen});
    };

    fetchExportToExcel = async () => {
        this.props.requestCampaign();
        let payload ={
            campaignState:this.state.campaignState,
            campaignName:this.state.campaignName.trim(),
            campaignId:this.state.campaignId.trim(),
            rewardType :this.state.rewardType,
            campaignTimeDateRangeStart : this.state.campaignTimeDateRangeStart,
            campaignTimeDateRangeEnd:this.state.campaignTimeDateRangeEnd,
            campaignStatus:this.state.campaignStatus,
            sponsoredBy:this.state.sponsoredBy,
        }
        await this.props.getCampaignExportToExcel(payload);
        this.setState({showDownloadIcon:true});
    };

    redirectToCampaignReport = (campaignObj) => {
        this.props.history.push(`/campaigns/${campaignObj._id}`); //`/campaigns/${campaignObj._id}`
        this.props.history.campaignCode = this.state.campaignCode;
    };

    addCampaign = async (payload) => {
        // console.log("payload in add campaign---->",payload)
        let promocodeArray = [];
        if(payload.arrayList.length > 0) {
            for(let i = 0; i < payload.arrayList.length; i++){
                promocodeArray.push(payload.arrayList[i].promocode);
            }
        }        
        let payloadCampaign = {
            imageUrl: payload.campaignImage,
            campaignName: payload.campaignName,
            campaignStartDate: payload.campaignTimeDateRangeStart,
            campaignEndDate: payload.campaignTimeDateRangeEnd,
            totalBudget: payload.campaignTotalBudget,
            couponDescription: payload.couponDescription,
            couponName: payload.couponName,
            totalCouponAmountPerUser: payload.couponTotalAmountUser,
            couponValidityDays: payload.couponValidityDays,
            couponMinimumOrderValue: payload.minOrderValuePerCoupon,
            noOfCouponsPerUser: payload.numberofCoupons,
            promoCodes: promocodeArray,
            termsAndConditions: payload.termsAndConditions,
            rewardType: payload.rewardType,
            valuePerCoupon: payload.valueperCoupon,
            sponsoredBy: payload.sponsoredBy,
        }
        // console.log("payload before sending----->",payloadCampaign)
        await this.props.addNewCampaign(payloadCampaign);
        await this.fetchCampaignWithFilter();//getAllCampaignList();
        this.setState({modalOpen: false, editCampaignFlag: false, duplicateCampaignFlag: false, promocodes: [] })
    };

    updateCampaign = async (payload) => {
        if(payload.editFlag === true){
            let promocodeArray = [];
            
            //if not adding new promocode for edit campaign
            if(payload.initalPromocodes){
                if(payload.initalPromocodes.length > 0) {
                    for(let i = 0; i < payload.initalPromocodes.length; i++){
                        promocodeArray.push(payload.initalPromocodes[i]);
                    }
                }
            }
            
            if(arrayList){
                if(payload.arrayList.length > 0) {
                    for(let i = 0; i < payload.arrayList.length; i++){
                        promocodeArray.push(payload.arrayList[i].promocode);
                    }
                }
            }

            let payloadid = payload._id;
            let payloadCampaignEdit = {
                imageUrl: payload.campaignImage,
                campaignName: payload.campaignName,
                campaignStartDate: moment(payload.campaignTimeDateRangeStart).format('YYYY-MM-DD'),
                campaignEndDate: moment(payload.campaignTimeDateRangeEnd).format('YYYY-MM-DD'),
                totalBudget: payload.campaignTotalBudget,
                couponDescription: payload.couponDescription,
                couponName: payload.couponName,
                totalCouponAmountPerUser: payload.couponTotalAmountUser,
                couponValidityDays: payload.couponValidityDays,
                couponMinimumOrderValue: payload.minOrderValuePerCoupon,
                noOfCouponsPerUser: payload.numberofCoupons,
                rewardType: payload.rewardType,
                valuePerCoupon: payload.valueperCoupon,
                promoCodes: promocodeArray,
                termsAndConditions: payload.termsAndConditions,
                sponsoredBy: payload.sponsoredBy,
            }
            // console.log("Edit campaign-----> Payload before sending---->",payloadCampaignEdit)
            await this.props.editCampaign(payloadCampaignEdit, payloadid);//current LOC
        }
        if(payload.duplicateFlag === true){
            let promocodeArray = [];
            if(payload.arrayList.length > 0) {
                for(let i = 0; i < payload.arrayList.length; i++){
                    promocodeArray.push(payload.arrayList[i].promocode);
                }
            }
            let payloadCampaignDuplicate = {
                imageUrl: payload.campaignImage,
                campaignName: payload.campaignName,
                campaignStartDate: payload.campaignTimeDateRangeStart,
                campaignEndDate: payload.campaignTimeDateRangeEnd,
                totalBudget: payload.campaignTotalBudget,
                couponDescription: payload.couponDescription,
                couponName: payload.couponName,
                totalCouponAmountPerUser: payload.couponTotalAmountUser,
                couponValidityDays: payload.couponValidityDays,
                couponMinimumOrderValue: payload.minOrderValuePerCoupon,
                noOfCouponsPerUser: payload.numberofCoupons,
                rewardType: payload.rewardType,
                valuePerCoupon: payload.valueperCoupon,
                promoCodes: promocodeArray,
                termsAndConditions: payload.termsAndConditions,
                sponsoredBy: payload.sponsoredBy,
            }
            // console.log("duplicate campaign-----> Payload before sending---->",payloadCampaignDuplicate);
            await this.props.addNewCampaign(payloadCampaignDuplicate);        
        }
        await this.fetchCampaignWithFilter();//getAllCampaignList();
        this.setState({modalOpen: false, editCampaignFlag: false, duplicateCampaignFlag: false, promocodes: [] })
    };

    updateCampaignAssignedBusiness = async (id) => {
        let businessesArray = this.state.selectedCampaignBusinessList.filter(ele=>ele.isSelected).map(ele=>ele.businessId)
        if (!businessesArray) {
          businessesArray = [];
        }
        this.props.requestCampaign();
        businessesArray = unique(businessesArray);
        let payloadCampaignEdit = {
          businesses: businessesArray,
        };
        await this.props.editCampaign(payloadCampaignEdit, id);
        this.setState({selectedCampaignBusinessList:[]});
        await this.fetchCampaignWithFilter();
    };

    handleCampaignStatusSwitchChange = async (event, data) => {
        let campaignStatusToggle = "";
    
        if (event.target.checked === true) {
            campaignStatusToggle = true
        }
        else {
            campaignStatusToggle = false
        }
    
        let payload = {
          campaignId: data._id,
          data: {
            campaignStatus : campaignStatusToggle
          }
        }
        await this.props.updateCampaignStatus(payload);
        await this.fetchCampaignWithFilter();//getAllCampaignList();
    };

    handleDeleteCampaign = async (data) => {
        let payload = {
            campaignId: data._id,
        }
        await this.props.deleteCampaign(payload);
        await this.fetchCampaignWithFilter();//getAllCampaignList();
        this.setState({ modalDeleteOpen: false })
    };

    handleEditCampaignOpen = async (event, data) => {
        let payload = { 
            campaignName: data.campaignName,
            campaignStatus: data.campaignStatus,
            campaignTimeDateRangeStart: moment(data.campaignStartDate).format('YYYY-MM-DD'), //"2020-06-06",
            campaignTimeDateRangeEnd: moment.utc(data.campaignEndDate).format('YYYY-MM-DD'),//"2020-07-10",
            campaignTotalBudget: data.totalBudget,
            rewardType: data.rewardType,
            couponName: data.couponName,
            couponTotalAmountUser: data.totalCouponAmountPerUser,
            numberofCoupons: data.noOfCouponsPerUser,
            valueperCoupon: data.valuePerCoupon,
            minOrderValuePerCoupon: data.couponMinimumOrderValue,
            couponValidityDays: String(data.couponValidityDays),
            couponDescription: data.couponDescription,
            campaignImage: data.imageUrl,
            _id: data._id,
            promo: data.promoCodes,
            termsAndConditions: data.termsAndConditions,
            sponsoredBy: data.sponsoredBy,
        }
        this.setState({editCampaignFlag: true, campaignData: payload});
        this.handleModalOpen();
    };

    handleDuplicateCampaignOpen = async (event, data) => {
        let payload = { 
            campaignName: data.campaignName,
            campaignTimeDateRangeStart: moment(data.campaignStartDate).format('YYYY-MM-DD'),
            campaignTimeDateRangeEnd: moment.utc(data.campaignEndDate).format('YYYY-MM-DD'),
            campaignTotalBudget: data.totalBudget,
            rewardType: data.rewardType,
            couponName: data.couponName,
            couponTotalAmountUser: data.totalCouponAmountPerUser,
            numberofCoupons: data.noOfCouponsPerUser,
            valueperCoupon: data.valuePerCoupon,
            minOrderValuePerCoupon: data.couponMinimumOrderValue,
            couponValidityDays: String(data.couponValidityDays),
            couponDescription: data.couponDescription,
            campaignImage: data.imageUrl,
            _id: data._id,
            termsAndConditions: data.termsAndConditions,
            // sponsoredBy: data.sponsoredBy,
        }
        this.setState({duplicateCampaignFlag: true, campaignData: payload});
        this.handleModalOpen();
    };

    handleAssignBusinessToCampaign = async (event, data) => {
        // console.log("Data in handleAssignBusinessToCampaign----->",data)
        let selectedCampaignBusinessList =
        data.business.length > 0 &&
        data.business.map((item) => {
              return {
                businessId: item.businessId,
                isSelected: true,
                businessName: item.businessName,
            }
          });
          if(!selectedCampaignBusinessList) selectedCampaignBusinessList = []
        this.setState({selectedCampaign:data,selectedCampaignBusinessList}
            ,()=>this.handleAssignBusinessModalOpen(true))
        // console.log("handleAssignBusinessToCampaign selectedCampaignBusinessList------->",selectedCampaignBusinessList)
    };

    // handleAssignShopperToCampaign = async (event, data) => {
    //     console.log("Data in handleAssignShopperToCampaign----->",data)
    //     let selectedCampaignConsumerList =
    //     data.consumer && data.consumer.length > 0 &&
    //     data.consumer.map((item) => {
    //           return {
    //             consumerId: item.consumerId,
    //             isSelected: true,
    //             firstName: item.firstName,
    //             lastName: item.lastName,
    //         }
    //       });
    //       if(!selectedCampaignConsumerList) selectedCampaignConsumerList = []
    //     this.setState({selectedCampaign:data,selectedCampaignConsumerList}
    //         ,()=>this.handleAssignShopperModalOpen(true))
    // }
    
    resetFilter = async () => {
        this.setState({
            campaignState: [],
            campaignName: "",
            campaignId: "",
            rewardType: [],
            campaignTimeDateRangeStart : "",
            campaignTimeDateRangeEnd: "",
            campaignStatus: "",
            sponsoredBy: "",

        }, () => {
            this.fetchCampaignWithFilter();
        });
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleChangePage = (event, newPage) => {
        this.setPage(newPage);
    };

    setPage = (page) => {
        this.setState({ page }, () => this.fetchCampaignWithFilter());
    };

    handleChangeRowsPerPage = (event) => {
        this.setRowsPerPage(parseInt(event.target.value, 10));
        this.setPage(0);
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };

    handleDeleteOpen = (event,row) => {
        this.setState({ modalDeleteOpen: true });
        this.setState({ selectedCampaign: row });
    };

    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false });
        
    };

     tabChange = async (tabValue) =>  {
        console.log(tabValue);
        this.setState({ campaignCode: tabValue });
        if(tabValue === "promoCode"){
        this.fetchCampaignWithFilter();
            this.props.history.campaignOfferCode = tabValue
            this.props.history.tabValueIndex = 0
        }if(tabValue === "offerCode") {
          this.props.history.campaignOfferCode = tabValue
          this.props.history.tabValueIndex = 1
        }
           
        
    }


    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });

    };




    render() {
        const { classes } = this.props;
        //  console.log("this.props---->",this.props);
        // console.log("this.state---->",this.state);
        // console.log("data for campaign-->",this.state.campaignData)
        
        let muiAlert = null;

		if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}
        totalCount = this.props.campaign.campaignListData.total;
        return (
            <div className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container className={classes.container}>
                <Tabs value={this.state.value} onChange={this.handleChangeTab} indicatorColor="primary" textColor="primary" centered={false} >
                    <Tab label="Promo Code Campaign" onClick={()=>this.tabChange("promoCode")} />
                    <Tab label="Offer Code Campaign" onClick={()=>this.tabChange("offerCode")} />
                </Tabs>

                {/* Promo Code Campaign Flow */}
                <TabPanel value={this.state.value} index={0} classes={{ root: classes.tab }}>
                <Container className={classes.searchPaper}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>Search Campaign</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                            <Grid container spacing={1}>
                                <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Campaign State
                                    </Typography>
                                    {/*<CampaignStateSelectDropdown 
                                        handleFilterChange={this.handleFilterChange}
                                        campaignState={this.state.campaignState}
                                    /> */}
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        {/* <InputLabel id="demo-simple-select-standard-label">Campaign State</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.campaignState}
                                            name="campaignState"
                                            onChange={this.handleFilterChange}
                                            // style={{ width: "225px" }}
                                        >
                                             
                                            <MenuItem value="Live" style={{ minHeight: "28px", height: "28px" }}>Live</MenuItem>
                                            <MenuItem value="Completed" style={{ minHeight: "28px", height: "28px" }}>Completed</MenuItem>
                                            <MenuItem value="Offline" style={{ minHeight: "28px", height: "28px" }}>Offline</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Campaign Name
                                    </Typography>
                                    <TextField fullWidth margin="dense" name="campaignName" value={this.state.campaignName} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Campaign ID
                                    </Typography>
                                    <TextField fullWidth margin="dense" name="campaignId" value={this.state.campaignId} variant="outlined" onChange={this.handleFilterChange} />
                                </Grid>
                                <Grid item xs={3} >
                                    <Typography variant="subtitle2" gutterBottom>
                                        Reward Type
                                    </Typography>
                                    {/*<RewardTypeSelectDropdown
                                        handleFilterChange={this.handleFilterChange}
                                        rewardType={this.state.rewardType}
                                    /> */}
                                    <FormControl fullWidth margin="dense" variant="outlined">
                                        {/* <InputLabel id="demo-simple-select-standard-label">Reward Type</InputLabel> */}
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={this.state.rewardType}
                                            name="rewardType"
                                            onChange={this.handleFilterChange}
                                            // style={{ width: "225px" }}
                                        >
                                             
                                            <MenuItem value="Coupons" style={{ minHeight: "28px", height: "28px" }}>Coupons</MenuItem>
                                           
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}  >
                                        <Typography variant="subtitle2" gutterBottom>
                                            Campaign Start Date
                                        </Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="campaignTimeDateRangeStart"
                                            type="date"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={this.handleFilterChange}
                                            value={this.state.campaignTimeDateRangeStart}
                                            // style={{ width: "225px" }}
                                        />
                                </Grid>
                                    <Grid item xs={3}  >
                                        <Typography variant="subtitle2" gutterBottom>
                                            Campaign End Date
                                        </Typography>
                                        
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="campaignTimeDateRangeEnd"
                                            type="date"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={this.state.campaignTimeDateRangeEnd}
                                            onChange={this.handleFilterChange}
                                            // style={{ width: "225px" }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Sponsored By
                                        </Typography>
                                        {/* <RadioGroup row aria-label="position" name="sponsoredBy" onChange={this.handleFilterChange} value={this.state.sponsoredBy}>
                                            <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                                            <FormControlLabel value="business" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Business</Typography>} size="small" />
                                            <FormControlLabel value="xirify" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Xirify</Typography>} size="small" />
                                        </RadioGroup> */}
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                            {/* <InputLabel id="demo-simple-select-standard-label">Sponsored By</InputLabel> */}
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.sponsoredBy}
                                                name="sponsoredBy"
                                                onChange={this.handleFilterChange}
                                                // style={{ width: "225px" }}
                                            >
                                                <MenuItem value="business" style={{ minHeight: "28px", height: "28px" }}>Business</MenuItem>
                                                <MenuItem value="xirify" style={{ minHeight: "28px", height: "28px" }}>Xirify</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Status
                                        </Typography>
                                        <RadioGroup row aria-label="position" name="campaignStatus" onChange={this.handleFilterChange} value={this.state.campaignStatus}>
                                            <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                                            <FormControlLabel value="Active" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Active</Typography>} size="small" />
                                            <FormControlLabel value="InActive" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>InActive</Typography>} size="small" />
                                        </RadioGroup>
                                    </Grid>
                        
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        onClick={this.resetFilter}
                                        style={{marginTop:"20px"}}
                                        >
                                        Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        margin="normal"
                                        color="primary"
                                        onClick={
                                            ()=>this.setPage(0)
                                            // this.fetchCampaignWithFilter
                                        }
                                        style={{marginTop:"20px"}}
                                        >
                                        Search
                                        </Button>
                                    </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Container>
       
                <Container className={classes.containerin}>
                    {this.state.showDownloadIcon ?
                        <CSVLink
                            style={{ "marginRight": "28px", "float": "right" }}
                            headers={this.state.headers}
                            data={this.props.campaign.campaignExcelRecords}
                            filename={"promo_code_campaigns_allList.csv"}
                            className="btn btn"
                            target="_blank"
                            >
                                <Tooltip title="Export to Excel">
                                    <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                </Tooltip>
                        </CSVLink>
                                :
                        <Button 
                            style={{ "marginRight": "28px", "float": "right" }}
                            variant="contained"
                            color="primary"
                            onClick={this.fetchExportToExcel}
                            >
                                Export
                        </Button>
                    }

                    {this.props.user.role === "admin" ? 
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleModalOpen}
                            style={{ "marginRight": "28px", "float": "right" }}
                        >
                            Add New
                        </Button>
                    :null}
                </Container>

                <Modal 
                    open={this.state.modalOpen}
                    className={classes.modal}
                    onClose={this.handleModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {!(this.state.editCampaignFlag || this.state.duplicateCampaignFlag) ?
                        <CampaignAddForm 
                        showLoader={()=>this.props.requestCampaign()}
                        onClose={this.handleModalClose}
                        onSubmit={this.addCampaign}
                        campaign={this.props.campaign}
                        envs = {this.props}
                        images={this.state.campaignImage}
                        responsePromoCodeError={this.state.responsePromoCodeError}
                        clearPromoCodeError={()=>{this.setState({responsePromoCodeError: ""})}}
                        promocodes={this.state.promocodes}
                        campaignCode={this.state.campaignCode}
                        />
                        :
                        <CampaignEditForm 
                        showLoader={()=>this.props.requestCampaign()}
                        campaignData={this.state.campaignData}
                        onClose={this.handleModalClose}
                        onSubmit={this.updateCampaign}
                        initialValues={this.state.campaignData}
                        campaign={this.props.campaign}
                        envs = {this.props}
                        images={this.state.campaignImage}
                        responsePromoCodeError={this.state.responsePromoCodeError}
                        clearPromoCodeError={()=>{this.setState({responsePromoCodeError: ""})}}
                        promocodes={this.state.promocodes}
                        duplicateFlag={this.state.duplicateCampaignFlag}
                        editFlag={this.state.editCampaignFlag}
                        campaignCode={this.state.campaignCode}
                    />
                    }
                    
                </Modal>

                <Modal 
                    open={this.state.assignBusinessModalOpen}
                    className={classes.modal}
                    onClose={()=>this.handleAssignBusinessModalOpen(false)}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                   <AssignBusinessModal
                       categoryList={this.props.categories}
                       selectedCampaign={this.state.selectedCampaign}
                       selectedCampaignBusinessList={this.state.selectedCampaignBusinessList}
                       setSelectedCampaignBusinessList={(selectedCampaignBusinessList)=>{this.setState({selectedCampaignBusinessList})}}
                       businessList={this.props.business.busApprovedListCampaign}
                       onClose={()=>{this.handleAssignBusinessModalOpen(false);
                        this.setState({selectedCampaignBusinessList:[]})
                        }}
                       onSave={(payload)=>this.updateCampaignAssignedBusiness(payload)}
                       fetchBusinesses={this.props.getBusinessListApprovedForCampaigns}                
                   />
                </Modal>

                <Container className={classes.container}>
                    <Paper className={classes.paper}>
                        <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                            Promo Code Campaigns ({totalCount ? totalCount : 0})
                        </Typography>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                aria-labelledby="tableTitle"
                                size="small"
                                aria-label="enhanced table"
                            >
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableCellSmall}>Campaign State</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Sponsored By</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Campaign ID</TableCell>
                                    <TableCell className={classes.tableCellSmall} scope="row">Campaign Name</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Reward Type</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupon Name</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupon Total Amount/User {'\u20B9'}</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupon Value {'\u20B9'}</TableCell>
                                    <TableCell className={classes.tableCellSmall11}>Start Date</TableCell>
                                    <TableCell className={classes.tableCellSmall11}>End Date</TableCell>
                                    <TableCell className={classes.tableCellSmall12}>Coupon Validity (days)</TableCell>
                                    <TableCell className={classes.tableCellSmall13}>Description</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Total Coupons Amt {'\u20B9'}</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupons <span style={{color: '#07c6db'}}>Issued</span></TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupons <span style={{color: '#63db5c'}}>Claimed</span></TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupons Remaining</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Coupons Expired</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Budget <span style={{color: '#93948d'}}>Total {'\u20B9'}</span></TableCell>
                                    <TableCell className={classes.tableCellSmall}>Budget <span style={{color: '#63db5c'}}>Claimed {'\u20B9'}</span> </TableCell>
                                    <TableCell className={classes.tableCellSmall}>Budget Remaining {'\u20B9'}</TableCell>
                                    <TableCell className={classes.tableCellSmall}>Status</TableCell>
                                    {this.props.user.role === "admin"?<TableCell></TableCell>:null}
                                    {this.props.user.role === "admin"?<TableCell></TableCell>:null}
                                    {this.props.user.role === "admin"?<TableCell></TableCell>:null}
                                    {this.props.user.role === "admin"?<TableCell className={classes.tableCellSmall}>Assign Business</TableCell>:null}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {(this.props.campaign.campaignListData && this.props.campaign.campaignListData.campaignList) ? this.props.campaign.campaignListData.campaignList
                                    .map((row, index) => {                                    

                                        //this works
                                        // ((endDate < currentDate && (row.campaignStatus==true || row.campaignStatus==false) ? this.state.stateOfCampaign = 'Completed' : (((row.campaignStatus === false) ? this.state.stateOfCampaign = 'Offline' : this.state.stateOfCampaign = "Live"))));
                                        if(row.sortOrder===10){
                                            row.stateOfCampaign="Live"
                                        } else if(row.sortOrder===20){
                                            row.stateOfCampaign="Offline"
                                        } else if(row.sortOrder===30){
                                            row.stateOfCampaign="Completed"
                                        } 

                                    return <TableRow hover className={classes.tableRow} key={index}
                                                style={{opacity:row.delete?"0.5":"1",cursor:row.delete?"default":"pointer"}} >
                                        <TableCell className={classes.tableCellSmall} style={row.stateOfCampaign === 'Live' ? {color: '#63db5c'} : (row.stateOfCampaign === 'Completed' ? {color: 'red'} : {color: '#edd02b'})  } scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.stateOfCampaign}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row?.sponsoredBy||"NA"}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.campaignId}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.campaignName}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.rewardType}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.couponName}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.totalCouponAmountPerUser}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.valuePerCoupon}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{moment(row.campaignStartDate).format('DD-MMM-YYYY')} </TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{moment.utc(row.campaignEndDate).format('DD-MMM-YYYY')}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>
                                            { 
                                            <span>{row.couponValidityDays>0 ? row.couponValidityDays :"Indefinite"}</span>}
                                            </TableCell>
                                        <TableCell className={classes.tableCellSmall13} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>
                                            {/* {row.couponDescription} */}
                                            <div dangerouslySetInnerHTML={{ __html: row.couponDescription }} />
                                        </TableCell>

                                        <TableCell className={classes.tableCellSmall} style={{color: '#07c6db'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.actualCouponsIssuedAmount}</TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#07c6db'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.totalCouponsInExecution}</TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#63db5c'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.totalClaimedCoupon}</TableCell>
                                                                {/* 5ecc58 for green */}
                                        <TableCell className={classes.tableCellSmall} style={{color: '#07c6db'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>
                                            {row.couponsRemainingToIssue}
                                            </TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#07c6db'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>
                                            {row.expiredCoupons}
                                            </TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#93948d'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.totalBudget}</TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#63db5c'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.claimedBudget}</TableCell>
                                        <TableCell className={classes.tableCellSmall} style={{color: '#07c6db'}} scope="row" onClick={(e) => !row.delete && this.redirectToCampaignReport(row,e)}>{row.remainingBudget}</TableCell>
                                        <TableCell className={classes.tableCellSmall} scope="row" >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        key={`campaignStatusSwitch_${row.campaignId}_${index}`}
                                                        checked={row.campaignStatus === true}
                                                        onChange={this.props.user.role === "admin"?(e) => this.handleCampaignStatusSwitchChange(e, row):null}
                                                        color="primary"
                                                        disabled={row.stateOfCampaign ==='Completed'|| row.delete}
                                                    />
                                                    }
                                                    style={{ fontSize: "10px", color: row.campaignStatus === true ? 'black' : 'red' }}
                                                 //   label={row.campaignStatus === true ? 'Active' : 'Inactive'}
                                                 label={row.campaignStatus === false  || row.stateOfCampaign === 'Completed' ? 'Inactive' : 'Active'} // changes for the alignment of the status column

                                            />
                                        </TableCell>
                                        {this.props.user.role === "admin"?<TableCell className={classes.tableCellSmall} scope="row">
                                            <IconButton className={classes.icon} style={{ float: "right" }} 
                                                onClick={(e) => this.handleDuplicateCampaignOpen(e, row)}
                                                disabled={row.delete}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <FileCopyIcon color="action" />
                                                </Avatar>
                                            </IconButton>
                                        </TableCell>:null}
                                        {this.props.user.role === "admin"?<TableCell className={classes.tableCellSmall} scope="row" >
                                            <IconButton className={classes.icon}
                                                style={{float: "right",
                                                 opacity:row.stateOfCampaign ==='Completed'|| row.delete?"0.5":"1",
                                                 cursor:row.stateOfCampaign ==='Completed'|| row.delete?"default":"pointer"}}
                                                onClick={(e) => this.handleEditCampaignOpen(e, row)}
                                                disabled={row.stateOfCampaign ==='Completed'|| row.delete}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <EditIcon color="action" />
                                                </Avatar>
                                            </IconButton>                
                                        </TableCell>:null}
                                        {this.props.user.role === "admin"?<TableCell className={classes.tableCellSmall} scope="row"> 
                                            <IconButton className={classes.icon} 
                                                style={{float: "right",
                                                 opacity:row.stateOfCampaign ==='Completed' || row.campaignStatus?"0.5":"1",
                                                 cursor:row.stateOfCampaign ==='Completed' || row.campaignStatus?"default":"pointer"}}
                                                onClick={(e)=>{this.handleDeleteOpen(e,row)}}
                                                disabled={row.stateOfCampaign ==='Completed' || row.campaignStatus === true || row.delete}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <DeleteIcon color="action" />
                                                </Avatar>
                                            </IconButton>                             
                                        </TableCell>:null}
                                        {this.props.user.role === "admin"?<TableCell className={classes.tableCellSmall} scope="row" >
                                            <IconButton className={classes.icon}
                                                style={{float: "right",
                                                 opacity:row.stateOfCampaign ==='Completed'|| row.delete?"0.5":"1",
                                                 cursor:row.stateOfCampaign ==='Completed'|| row.delete?"default":"pointer"}}
                                                onClick={(e) => this.handleAssignBusinessToCampaign(e, row)}
                                                disabled={row.stateOfCampaign ==='Completed'|| row.delete}
                                            >
                                                <Avatar className={classes.lightBlue}>
                                                    <BusinessSVG className={classes.icon} />
                                                </Avatar>
                                            </IconButton>                
                                        </TableCell>:null}
                                    </TableRow>
                                    })
                                    : null
                                } 
                                {/* {emptyRows > 0 && (
                                    <TableRow style={{ height: 33 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                        </TableRow>
                                )} */}
                            </TableBody> 
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                </Container>
                </TabPanel>

                {/* Offer Code Campaign Flow */}
                <TabPanel value={this.state.value} index={1} classes={{ root: classes.root}}>
                    <OfferCodeCampaign 
                        campaignCode = {this.state.campaignCode}
                        history = {this.props.history}
                    />
                </TabPanel>

            </Container>

            <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                <DialogTitle>Delete Campaign</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Do you want to delete the campaign?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                    <Button onClick={() => this.handleDeleteCampaign(this.state.selectedCampaign)} color="primary" autoFocus> Yes </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                open={this.state.snackOpen}
                autoHideDuration={2000}
                onClose={this.handleSnackClose}
                ContentProps={{'aria-describedby': 'message-id'}}>
                    {muiAlert}
            </Snackbar>
            <Backdrop className={classes.backdrop} open={this.props.campaign.isFetching}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </div>
        )
    }
}


const mapStateToProps = state => {
	return {
		campaign: state.campaign,
        user: state.auth.user,
        env: state.auth.env,
        business: state.business,
        categories: state.categories.categoriesByType
	}
};
export default connect(mapStateToProps, { changeHeaderName, getAllCampaignList, addNewCampaign, fetchEnvs, checkPromoCode, updateCampaignStatus, deleteCampaign, getCampaignExportToExcel, editCampaign, clearMsg, requestCampaign,getBusinessListApprovedForCampaigns,getCategoriesByType })(withStyles(styles)(Campaigns));