import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const headCells = [
    { id: 'businessData.businessId', disablePadding: true, label: 'Business Id' },
    { id: 'businessData.businessName', disablePadding: true, label: 'Business Name' },
    { id: 'consumerData.consumerId', disablePadding: true, label: 'Consumer Id' },
    { id: 'consumerData.firstName', disablePadding: true, label: 'Consumer Name' },
    { id: 'serviceRequestId', disablePadding: true, label: 'Service Request Id' },
    { id: 'review', disablePadding: true, label: 'Review' },
    { id: 'rating', disablePadding: true, label: 'Rating' },
    { id: 'time', disablePadding: true, label: 'Time' },
    { id: 'status', disablePadding: true, label: 'Status' }
];

function EnhancedTableHead(props) {
    const { classes } = props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };
    
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >{headCell.label}

                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    //order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    //  orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Reviews & Ratings Report ({props.total})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon && props.data.length?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"Reviews_report.csv"}
                        className="btn btn"
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchExcelFile}
                    >
                        Export
                </Button>
                }
            </Typography>
        </Toolbar>
    );
};



/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        fontSize: "12px",
        padding: "0px 0px 6px 0px"
    },
    tableBottomBorder1: {
        borderBottom: "none",
        minWidth: 3,
        fontSize: "13px",
        padding: "0px 0px 6px 0px"
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    // const handleRequestSort = (event, property) => {
    //     const isAsc = props.orderBy === property && props.order === 'asc';
    //     props.setOrder(isAsc ? -1 : 1);
    //     props.setOrderBy(property);
    // };
    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'businessData.businessId', label: 'Business Id' },
        { key: 'businessData.businessName', label: 'Business Name' },
        { key: 'consumerData.consumerId', label: 'Consumer Id' },
        { key: 'consumerData.firstName', label: 'Consumer First Name' },
        { key: 'consumerData.lastName', label: 'Consumer Last Name' },
        { key: 'serviceRequestId', label: 'Service Request Id' },
        { key: 'review', label: 'Review' },
        { key: 'rating', label: 'Rating' },
        { key: 'reviewTime', label: 'Time' },
        { key: 'status', label: 'Status' }
    ];

    const handleReviewSwitchChange =(event, serviceRequestId)=>{      
        let payload = {
            // _id: reviews._id,
            serviceRequestId: serviceRequestId,
            isActive: event.target.checked
        }
        props.handleReviewSwitchChange(payload);  
    }     
    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.total - props.page * props.rowsPerPage);
    let data = props.data.exportToExcel;
    data && data.forEach(element => {
        if(element.reviewTime){
            element.reviewTime = moment(element.reviewTime).format('DD MMM YYYY hh:mm A');
        }
        else{
         element.reviewTime = "NA";
        }
        element.status = element.isReviewActive === true ? "Active" :"Inactive"
    });
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar total={props.data.total}
                    headers={headers}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchExcelFile={props.fetchExcelFile}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            // order={props.order}
                            //  orderBy={props.orderBy}
                            //  onRequestSort={handleRequestSort}
                            rowCount={props.data.total}
                        />
                        <TableBody>
                            {//stableSort(props.data.data, getComparator(props.order, props.orderBy))
                                // .slice(props.page * props.rowsPerPage, props.page * props.rowsPerPage + props.rowsPerPage)
                                props.data.data.map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.serviceRequestId}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.businessData.businessId}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.businessData.businessName}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.consumerData.consumerId}
                                                {/* {row.consumerData ? row.consumerData.consumerId : "NA"} */}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {/* <span>{row.consumerDetails.consumer ? row.consumerDetails.consumer.firstName + " " + row.consumerDetails.consumer.lastName : "NA"}</span> */}
                                                <span>{row.consumerData.firstName + " " + row.consumerData.lastName}</span>
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.serviceRequestId}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.review}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.rating}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.reviewTime ? moment(row.reviewTime).format('DD MMM YYYY hh:mm A') : "NA"}
                                                {/* {moment(row.reviewTime).format('DD MMM YYYY hh:mm A')} */}
                                                </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                            <FormControlLabel
                        control={
                            <Switch
                            size="small"
                            checked={row.isReviewActive}
                            onChange={(e) => handleReviewSwitchChange(e, row.serviceRequestId)}
                            color="primary"
                            />
                        }
                        style={{ fontSize: "10px", color: row.isReviewActive? 'black' : 'red' }}
                        label={row.isReviewActive ?'Active':'Inactive'}
                        />
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}