import Container from "@material-ui/core/Container";
import React, { Component } from "react";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import MuiAlert from "@material-ui/lab/Alert";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { changeHeaderName } from "../../actions/dashboardActions";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import {
  getAppMaintenanceData,
  saveOrUpdateMaintenance,
  clearMsg,
} from "../../actions/appMaintenanceAction";
import moment from "moment";

const styles = (theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: "100%",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  table: {
    minWidth: 650,
    // paddingBottom: theme.spacing(4),
    //borderSpacing: "0px 4px",
    //marginLeft: "1rem",
    // marginRight: "2rem",
  },
  head: {
    fontWeight: 700,
  },
  button: {
    marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(2),
    float: "right",
  },
  header: {
    marginTop: theme.spacing(3),
  },
  header3: {
    marginTop: theme.spacing(2),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    width: "100%",
  },
  modalContainer: {
    width: "50%",
    height: "90%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  modalContainer10: {
    width: "100%",
    height: "10%",
    backgroundColor: theme.palette.background.paper,
  },
  snackClose: {
    padding: theme.spacing(0.5),
  },
  tableRow: {
    cursor: "pointer",
  },
  content: {
    height: "60%",
    margin: "10px 0px",
    overflow: "auto",
  },
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  tableCellSmall: {
    //fontSize: "15px",
    cursor: "pointer",
    padding: "20px 0px 20px 0px",
    //"6px 6px 6px 16px",
  },
  tableCellSmall1: {
    //fontSize: "15px",
    cursor: "pointer",
    padding: "6px 0px 6px 0px",
    // padding: "20px 0px 20px 0px",
    margin: "0px 0px 0px -25px",
    //width: "70%",
  },
  unclickableTableCellSmall: {
    // fontSize: "12px",
    padding: "6px 0px 6px 16px",
    //fontSize: "14px",
  },
  addModalContainer: {
    width: "50%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  editModalContainer: {
    width: "50%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  }, 
  icon: {
    height: 32,
    //  width: 32
  },
  mainContainer1: {
    width: "40%",
    height: "50%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  mainContainer2: {
    width: "46%",
    height: "27%",
    backgroundColor: theme.palette.background.paper,
    padding: "0px 0px",
  },
  headGridTwo: {
    float: "right",
    marginRight: "2px",
  },
  gridItem: {
    padding: "10px",
    // marginBottom: "1rem",
  },
  modalContainer2: {
    width: "100%",
    height: "100%",
    // padding: '0px 0px',
    backgroundColor: theme.palette.background.paper,
    margin: "10px 0px",
    overflow: "auto",
  },
  header2: {
    height: "15%",
    margin: "0px 0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "22px",
    backgroundColor: theme.palette.primary.main,
  },
  grid: {
    width: "100%",
    //display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "15px",
    paddingRight: "15px",
    margin: "0px 0px",
  },
  footer: {
    height: "26%",
    margin: "0px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  header4: {
    height: "25%",
    margin: "0px -24px 0px -24px",
    width: "107.3%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    //paddingLeft: "10px",

    backgroundColor: theme.palette.primary.main,
  },
  outerContainer: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
});

const headers = [
  { id: "appName", disablePadding: true, label: "App" },
  { id: "platform", disablePadding: false, label: "Platform" },
  { id: "maintenanceFlag", disablePadding: false, label: "Apply Maintenance" },
  {
    path: "appMaintenanceToggleActionHistory.actionDate",
    disablePadding: false,
    label: "Status",
  },
];

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF",
  },
})(Typography);

class AppMaintenance extends Component {
  state = {
    currentDateTime: Date().toLocaleString(),
    modalOpen: false,
    snackOpen: false,
    sucessMsg: null,
    errorMsg: null,
    editMode: false,
    flagToEdit: false,
    data: {},
    maintenanceFlag: false,
    appName: "",
    platform: "",
    _id: "",
    appMaintenanceToggleActionHistory: [],
  };

  handleModalOpen = (r) => {
    let flag = {
      _id: r._id,
      appName: r.appName,
      platform: r.platform,
      maintenanceFlag: r.maintenanceFlag,
    };
    this.setState({
      modalOpen: true,
      maintenanceFlag: flag.maintenanceFlag,
      appName: flag.appName,
      platform: flag.platform,
    });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false });
  };

  handleSwitchChangeAppMaintenanceFlag = async (
    id,
    appName,
    platform,
    maintenanceFlag,
    appMaintenanceToggleActionHistory
  ) => {
    const payload = {
      _id: id,
      appName: appName,
      platform: platform,
      maintenanceFlag: !maintenanceFlag,
      appMaintenanceToggleActionHistory: appMaintenanceToggleActionHistory,
    };
    await this.props.saveOrUpdateMaintenance(payload);
    await this.props.getAppMaintenanceData();
    await this.handleModalClose();
  };

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null, editMode: false });

    this.props.getAppMaintenanceData();
  };

  componentDidMount() {
    this.props.changeHeaderName("App Maintenance");
    this.props.getAppMaintenanceData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.appMaintenance.sucessMsg) {
      this.setState({ sucessMsg: this.props.appMaintenance.sucessMsg });
      this.setState({ snackOpen: true });
      this.setState({ modalOpen: false });
      this.props.clearMsg();
      this.props.getAppMaintenanceData();
    } else if (this.props.appMaintenance.errorMsg) {
      this.setState({ errorMsg: this.props.appMaintenance.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
  }

  render() {
    const { classes } = this.props;
    let muiAlert = null;

    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }

    return (
      <Container className={classes.outerContainer}>
        <Container className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: "flex" }}>
              <Grid
                item
                xs={4}
                style={{
                  marginTop: "4rem",
                }}
              ></Grid>
            </Grid>
          </Grid>
        </Container>

        <TableContainer component={Paper}>
          <div />
          <Typography
            className={classes.header}
            variant="subtitle2"
            gutterBottom
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "25px",
            }}
          >
            Apply Maintenance
          </Typography>

          <Table
            className={classes.table}
            aria-label="simple table"
            align="left"
            style={{
              marginLeft: "25px",
              width: "75vw",
            }}
          >
            <TableHead>
              <TableRow>
                {headers.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={"left"}
                    className={classes.tableCellSmall}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
                <div />
              </TableRow>
            </TableHead>

            <TableBody>
              {this.props.appMaintenance.appMaintenance.map((r) => (
                <TableRow key={r._id} className={classes.tableRow}>
                  <TableCell align="left" className={classes.tableCellSmall1}>
                    {r.appName}
                  </TableCell>
                  <TableCell align="left" className={classes.tableCellSmall1}>
                    {r.platform}
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.unclickableTableCellSmall}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={r.maintenanceFlag}
                          color="primary"
                          onClick={() => this.handleModalOpen(r)}
                        />
                      }
                      label={r.maintenanceFlag ? "On" : "Off"}
                    />
                  </TableCell>
                  <TableCell align="left" className={classes.tableCellSmall1}>
                    {r.appMaintenanceToggleActionHistory &&
                    r.appMaintenanceToggleActionHistory.length > 0 ? (
                      r.maintenanceFlag ? (
                        <Typography style={{ fontSize: "14px" }}>
                          {moment(
                            r.appMaintenanceToggleActionHistory[
                              r.appMaintenanceToggleActionHistory.length - 1
                            ].actionDate
                          ).format("[Applied on] DD-MMMM-YYYY [at] hh:mm A ")}
                        </Typography>
                      ) : (
                        <Typography style={{ fontSize: "14px" }}>
                          {moment(
                            r.appMaintenanceToggleActionHistory[
                              r.appMaintenanceToggleActionHistory.length - 2
                            ].actionDate
                          ).format("[Last Applied from] DD-MMMM-YYYY hh:mm A ")}
                         
                          {moment(
                            r.appMaintenanceToggleActionHistory[
                              r.appMaintenanceToggleActionHistory.length - 1
                            ].actionDate
                          ).format("[to] DD-MMMM-YYYY hh:mm A ")}
                        </Typography>
                      )
                    ) : (
                      " "
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Modal
          className={classes.modal}
          open={this.state.modalOpen}
          onClose={this.handleModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Container className={classes.mainContainer2}>
            <Container>
              <Grid container className={classes.header4} spacing={2}>
                <Grid item xs={8}>
                  <WhiteTextTypography
                    variant="subtitle1"
                    color="inherit"
                    gutterBottom
                  >
                    {this.state.maintenanceFlag === true
                      ? "Turn Off Maintenance"
                      : "Turn On Maintenance"}
                  </WhiteTextTypography>
                </Grid>
                <Grid item xs={4}>
                  <WhiteTextTypography
                    variant="subtitle1"
                    color="inherit"
                    gutterBottom
                  >
                    <CloseIcon
                      className={classes.headGridTwo}
                      onClick={this.handleModalClose}
                    />
                  </WhiteTextTypography>
                </Grid>
              </Grid>
            </Container>
            <Container style={{ marginTop: "1rem" }}>
              <Grid container className={classes.appBarSpacer}>
                {this.state.maintenanceFlag === true
                  ? "Are you sure you want to TURN OFF maintenance mode for selected app?"
                  : "Are you sure you want to TURN ON maintenance mode for selected app?"}
              </Grid>
            </Container>

            <Container className={classes.footer}>
              <Grid container spacing={2}>
                <Grid item xs={6}></Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="default"
                    onClick={this.handleModalClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={3} className={classes.gridItem}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color={
                      this.state.maintenanceFlag === true ? "default" : "primary"
                    }
                    onClick={() =>
                      this.handleSwitchChangeAppMaintenanceFlag(
                        this.state._id,
                        this.state.appName,
                        this.state.platform,
                        this.state.maintenanceFlag,
                        this.state.appMaintenanceToggleActionHistory
                      )
                    }
                  >
                    {this.state.maintenanceFlag === true
                      ? "Turn off"
                      : "Turn on"}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Container>
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>
      </Container>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    dashboard: state.dashboard,
    user: state.auth.user,
    admin: state.admin,
    appMaintenance: state.appMaintenance,
  };
};
export default connect(mapStateToProps, {
  changeHeaderName,
  getAppMaintenanceData,
  saveOrUpdateMaintenance,
  clearMsg,
})(withStyles(styles)(AppMaintenance));
