import React from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";

const headCells = [
    { id: 'invoiceNumber', disablePadding: true, label: 'Invoice Number' },
    { id: 'invoiceDate', disablePadding: true, label: 'Invoice Date' },
    { id: 'expenseName', label: 'Name' },
    { id: 'expenseVendorName', disablePadding: false, label: 'Vendor Name' },
    { id: 'expenseDescription', disablePadding: false, label: 'Description' },
    { id: 'isGstEnabled', disablePadding: false, label: 'GST' },
    { id: 'totalInvoiceAmount', label: 'Total Invoice Amount' },
    { id: 'expenseHeads', disablePadding: false, label: 'Heads' },
    { id: 'edit', disablePadding: false, label: '' },
    { id: 'delete', disablePadding: false, label: '' },   
];

function EnhancedTableHead(props) {

    const {classes} = props;
    
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall1}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    // console.log("props~~~~~~~~~~~~~~~~~~>>>",props)
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Expenses({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"expense.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                : <Button
                    variant="contained"
                    margin="normal"
                    style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                    onClick={props.fetchExpenseExcel}
                    >
                    Export
                </Button>
                } 
            </Typography> 

        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 20px"
    },
    tableCellSmall1: {
        fontSize: "13px",
        textAlign: "center"
    },
    tableRow: {
        // cursor: 'pointer'
    },
    tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 300,
        minWidth:155,
        width: 700,
    },
    tableCellSmall14: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px"
    },
    icon: {
        height: 32,
      },
    lightBlue: {
      color: theme.palette.getContrastText(lightBlue[50]),
      backgroundColor: lightBlue[50],
      width: theme.spacing(4),
      height: theme.spacing(4),
      cursor: 'pointer'
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    
    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const  editExpenseDetails= (e,row) => { props.handleEditOpen(e,row) };

    const isExpenseSelected = (_id) => {
        return props.data.indexOf(_id) !== -1;
      };

    const handleDeleteOpen = (e,row) => { props.handleDeleteOpen(e,row) };

    const handleDeleteClose = () => { props.handleDeleteClose() };

    const handleDeleteExpense = (data) => {
        props.handleDeleteExpense() 
    };

    const headers = [
        { key: 'invoiceNumber', label: 'Invoice Number' },
        { key: 'invoiceDate', label: 'Invoice Date' },
        { key: 'expenseName', label: 'Name' },
        { key: 'expenseVendorName', label: 'Vendor Name' },
        { key: 'expenseDescription',label: 'Description' },
        { key: 'isGstEnabled',  label: 'GST' },
        { key: 'totalInvoiceAmount', label: 'Total Invoice Amount' },
        { key: 'expenseHeads', label: 'Heads' },
    ];

    let data=[];
    props.exportToExcel && props.exportToExcel.forEach(element => {
        // console.log('element',typeof(element.isGstEnabled))
        data.push({
            invoiceNumber : element.invoiceNumber ? element.invoiceNumber : "NA",
            invoiceDate : element.invoiceDate ? moment.utc(element.invoiceDate).format('DD MMM YYYY') : "NA",
            expenseName: element.expenseName ? element.expenseName : "NA",
            expenseVendorName : element.expenseVendorName ? element.expenseVendorName : "NA",
            expenseDescription : element.expenseDescription ? element.expenseDescription : "NA",
            isGstEnabled : element.isGstEnabled === true ? 'Yes' : 'No',
            totalInvoiceAmount : element.totalInvoiceAmount ? element.totalInvoiceAmount : "NA",
            expenseHeads : element.expenseHeads ? element.expenseHeads : "NA",
         });
    })
    // console.log('data========>',data)
    // console.log('props EnhancedTableToolbar====>',props)
    
    let totalCount = props.totalCount;
  
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    headers={headers}
                    totalCount={props.totalCount}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchExpenseExcel={props.fetchExpenseExcel}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                            totalCount={props.totalCount}
                        />
                        <TableBody>
                            {
                                props && props.data && props.data.map((row, index) => {
                                 const selectedExpenseRecord = isExpenseSelected(row._id);
                                    // const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                            checked={selectedExpenseRecord}
                                            aria-checked={selectedExpenseRecord}
                                            selected={selectedExpenseRecord}
                                        >
                                            <TableCell align="left" className={classes.tableCellSmall3} >{row.invoiceNumber ? row.invoiceNumber : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall3} title={row.businessName} >{row.invoiceDate ? moment(row.invoiceDate).format('DD MMM YYYY ') : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13}>{row.expenseName ? row.expenseName : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall13} title={row.vendor} >{row.expenseVendorName ? row.expenseVendorName : "NA"}</TableCell> 
                                            <TableCell align="left" className={classes.tableCellSmall13} title={row.description}>{row.expenseDescription ? row.expenseDescription : "NA"}</TableCell>                                  
                                            <TableCell align="left" className={classes.tableCellSmall} title={row.GST}>{row.isGstEnabled === true ? 'Yes' : 'No' }</TableCell>                                  
                                            <TableCell align="left" className={classes.tableCellSmall13} >{row.totalInvoiceAmount ? '\u20B9 '+row.totalInvoiceAmount.toFixed(2) : "NA"}</TableCell>
                                            <TableCell align="left"className={classes.tableCellSmall13} >{row.expenseHeads ? row.expenseHeads : 'NA'}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}> <IconButton className={classes.icon} style={{ float: "right" }} onClick={(e) => editExpenseDetails(e,row)}> <Avatar className={classes.lightBlue}> <EditIcon color="action" /> </Avatar>  </IconButton> </TableCell> 
                                            <TableCell className={classes.tableCellSmall} scope="row"> <IconButton className={classes.icon} onClick={(e)=>{handleDeleteOpen(e,row)}} > <Avatar className={classes.lightBlue}> <DeleteIcon color="action" /> </Avatar> </IconButton>                             
                                            </TableCell>
                                        </TableRow>
                                    )
                                }) 
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage ? props.rowsPerPage : 0}
                    page={props.page ? props.page : 0}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Dialog open={props.deleteFlag} onClose={handleDeleteClose} >
                    <DialogTitle>Delete Expense</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                        Do you want to delete the selected Expense data?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteClose} color="primary" autoFocus> No </Button>
                        <Button onClick={handleDeleteExpense} color="primary"> Yes </Button>
                    </DialogActions>
                </Dialog> 
            </Paper>
        </div>
    );
}