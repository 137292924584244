import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { CSVLink } from "react-csv";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Container } from '@material-ui/core';



function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'status', label: "Status" },
    { id: 'pushNotificationId', label: "PushNotificationId" },
    { id: 'businessId', label: 'BusinessID' },
    { id: 'businessName', label: 'BusinessName' },
    { id: 'serviceId', disablePadding: false, label: 'Service ID(s)' },
    { id: 'distance', disablePadding: false, label: 'Distance(km)' },
    { id: 'scheduleType', disablePadding: false, label: 'Schedule Type' },
    { id: 'notificationPushedOn', disablePadding: false, label: 'Notification Scheduled/Pushed On Time' },
    { id: 'startDate', disablePadding: false, label: 'Start Date' },
    { id: 'endDate', disablePadding: false, label: 'End Date' },
    { id: 'notificationImage', disablePadding: false, label: 'Notification Image' },
    { id: 'notification', disablePadding: false, label: 'Notification' },
    { id: 'notificationName', disablePadding: false, label: 'Notification Name' },
    { id: 'consumers', disablePadding: false, label: 'Consumers' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy } = props;
    // const createSortHandler = (property) => (event) => {
    //     onRequestSort(event, property);
    // };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                        ) : null}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Notifications ({props.total})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"notifications.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    :
                    <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchNotificationWithExcelFile}
                    >
                        Export
                    </Button>
                }
            </Typography>
        </Toolbar>
    );
};

/* EnhancedTableToolbar.propTypes = {
    exportToExcel: PropTypes.func.exportToExcel,
    total: PropTypes.number.isRequired
}; */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    table1: {
        minWidth: 100,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px",
    },
    tableRow: {
        cursor: 'pointer'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        fontSize: "12px",
        padding: "0px 0px 6px 0px"
    },
    tableBottomBorder1: {
        borderBottom: "none",
        minWidth: 3,
        fontSize: "13px",
        padding: "0px 0px 6px 0px"
    },
    tableBtn: {
        padding: '0px'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "0px 10px"
    },
    editBtn: {
        cursor: 'pointer',
        color: 'grey',
        margin: "10px 10px"
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    // const handleDeleteOpen = (notificationObj) => {
    //     // this.setState({ modalDeleteOpen: true });
    //     // this.props.setAdmin(adminObj);
    // }

    // const handleDeleteClose = () => {
    //     // this.setState({ modalDeleteOpen: false });
    // }

    // const handleDeleteAdmin = (adminObj) => {
    //     // this.props.deleteAdmin(adminObj);
    //     // this.setState({ modalDeleteOpen: false });
    // }


    let headers = [
        { key:'status', label: 'Status' },
        { key: 'pushNotificationId', label: "PushNotificationId" },
        { key: 'businessId', label: 'BusinessID' },
        { key: 'businessName', label: 'BusinessName' },
        { key: 'serviceIds', label: 'Service ID(s)' },
        { key: 'distanceInKM', label: 'Distance(km)' },
        { key: 'scheduleType', label: 'Schedule Type' },
        { key: 'interval', label: 'Interval' },
        { key: 'daysOfWeek', label: 'Days Of Week' },
        { key: 'daysOfMonth', label: 'Days Of Month' },
        { key: 'startDate', label: 'Start Date' },
        { key: 'endDate', label: 'End Date' },
        { key: 'createdAt', label: 'Created At' },
        { key: 'scheduledDate', label: 'Scheduled Date' },
        { key: 'scheduledTime', label: 'Scheduled Time' },
        { key: 'photo.path', label: 'Notification Image' },
        { key: 'notificationTitle', label: 'notification Title' },
        { key: 'notificationText', label: 'notification Text' }
        
    ];
    // console.log("props.exportToexcel",props.exportToexcel)
    let data = props.exportToexcel;
    data.forEach(async(element) => {
         // console.log("element-------------->",element.endDate)
        element.endDate = (element.endDate && element.endDate!=="Invalid date" && element.endDate!==undefined && element.endDate!=="NA")? moment(element.endDate).format('DD MMM YYYY') : "NA"
        element.startDate = (element.startDate  && element.startDate!=="Invalid date"  && element.startDate!==undefined&& element.startDate!=="NA")? moment(element.startDate).format('DD MMM YYYY') : "NA"
         element.createdAt =  (element.createdAt && element.createdAt !== "Invalid date"  && element.createdAt!==undefined && element.createdAt!=="NA")? moment(element.createdAt).format('DD MMM YYYY hh:mm A') : "NA"
        element.scheduledDate = (element.scheduledDate && element.scheduledDate !== "Invalid date" && element.scheduledDate!==undefined && element.scheduledDate!=="NA" )? moment(element.scheduledDate).format('DD MMM YYYY ') : "NA"
        //     // console.log("element22-------------->",element)
        });
    // props.exportToexcel.forEach(async(element) => {
    //     element.endDate = element.endDate && element.endDate !== "Invalid date" && moment(element.endDate)? moment(element.endDate).format('DD MMM YYYY') : "NA"
    //     element.startDate = element.startDate && element.startDate !== "Invalid date" && moment(element.startDate)? moment(element.startDate).format('DD MMM YYYY') : "NA"
    //     element.createdAt = element.createdAt && element.createdAt !== "Invalid date" && moment(element.createdAt)? moment(element.createdAt).format('DD MMM YYYY hh:mm A') : "NA"
    //     element.scheduledDate = (element.scheduledDate && element.scheduledDate !== "Invalid date" && moment(element.scheduledDate))? moment(element.scheduledDate).format('DD MMM YYYY ') : "NA"
    //     // element.startDate = element.startDate ? moment(element.startDate).format("DD/MM/YYYY h:mm a") : "NA"
    //     // element.endDate = element.endDate ? moment(element.endDate).format("DD/MM/YYYY h:mm a") : "NA"
    // });
    props.consumerData.forEach(async (element) => {
     element.lastActiveTime = await element.lastActiveTime ? moment(element.lastActiveTime).format("DD/MM/YYYY h:mm a") : "NA"
    });
    let consumerHeaders = [
        { key: 'consumerId', label: 'consumerId' },
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'email', label: 'Email' },
        { key: 'primaryMobileNumber', label: 'Mobile Number' },
        { key: 'lastActiveTime', label: 'last Active On' }
    ]
    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.total - props.page * props.rowsPerPage);
   // console.log("push notification",props);
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar headers={headers} total={props.total} data={data} showDownloadIcon={props.showDownloadIcon} fetchNotificationWithExcelFile={props.fetchNotificationWithExcelFile} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.total}
                        />
                        <TableBody>
                            {stableSort(props.data, getComparator(props.order, props.orderBy))
                                .map((row, index) => {
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.status === "Expired" ? <div style={{ color: "red" }} >{row.status}</div>
                                                    : <div style={{ color: "green" }} >{row.status}</div>}
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" className={classes.tableCellSmall}>
                                                {row.pushNotificationId}
                                            </TableCell>
                                            <TableCell scope="row" className={classes.tableCellSmall}>
                                                {row.businessId}
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.businessName}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.serviceIds}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.distanceInKM}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.scheduleType}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{
                                                row.scheduleType === "Once-Now" ?
                                                    moment(row.createdAt).format('DD MMM YYYY HH:mm ')
                                                    :
                                                    row.scheduleType === "Once-Schedule" ? (
                                                        <Container>
                                                            <Typography variant="caption">
                                                                {moment(row.scheduledDate).format('DD MMM YYYY')}
                                                            </Typography>
                                                            <Typography variant="caption">
                                                                {row.scheduledTime}
                                                            </Typography>
                                                        </Container>
                                                    )
                                                        :
                                                        row.scheduleType === "Recurring" ?
                                                            <Container>
                                                                <Typography variant="caption">
                                                                    {
                                                                        row.interval === "Weekly" ?
                                                                            `Weekly on ${row.daysOfWeek}`
                                                                            :
                                                                            row.interval === "Monthly" ?
                                                                                `Monthly on ${row.daysOfMonth}`
                                                                                :
                                                                                "Daily"

                                                                    }
                                                                </Typography>
                                                                {row.scheduleType === "Once-Now" ? null
                                                                    :
                                                                    <Typography variant="caption">
                                                                        {` at  ${row.scheduledTime}`}
                                                                    </Typography>
                                                                }
                                                            </Container>
                                                            : "NA"
                                            }</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.startDate ? moment(row.startDate).format('DD MMM YYYY ') : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>{row.endDate ? moment(row.endDate).format('DD MMM YYYY ') : "NA"}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                {row.photo ?
                                                    <Avatar alt="Name" src={row.photo.path} className={classes.small} variant="square" /> : "NA"}
                                            </TableCell>
                                            <TableCell align="left"   >
                                                <Table className={classes.table1}
                                                    aria-labelledby="tableTitle"
                                                    size="small"
                                                    aria-label="enhanced table">
                                                    <TableBody>
                                                        <TableRow >
                                                            <TableCell align="left" className={classes.tableBottomBorder} > {row.notificationTitle}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell align="left" className={classes.tableBottomBorder} >{row.notificationText}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} >{row.notificationName ? row.notificationName : "--"}</TableCell>

                                            <TableCell align="left" className={classes.tableCellSmall}>{row.consumers}
                                                <Typography component="div">
                                                    {props.excelFilePushNotificationId.toString() === row.pushNotificationId.toString() ?
                                                        <CSVLink
                                                            data={props.consumerData}
                                                            headers={consumerHeaders}
                                                            filename={"Notification_Consumers.csv"}
                                                            className="btn btn"
                                                            target="_blank"
                                                        >
                                                            <Tooltip title="Export to Excel">
                                                                <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                                                            </Tooltip>
                                                        </CSVLink>
                                                        :
                                                        <Button
                                                            size='small'
                                                            variant="contained"
                                                            margin="normal"
                                                            style={{ backgroundColor: "green", color: "white", fontFamily: "bold", }}
                                                            onClick={() => props.fetchConsumerDetails(row.pushNotificationId)}
                                                        >
                                                            Export
                                                        </Button>
                                                    }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableBtn}>
                                                    {row.status === "Expired" || row.status=== "Deleted" ? null
                                                    :<DeleteIcon className={classes.deleteBtn}
                                                        onClick={() => props.deleteNotification(row._id)}
                                                    />}
                                            </TableCell>

                                            <TableCell align="left" className={classes.tableBtn}>
                                                {row.status === "Expired" || row.status === "Deleted" ? null :
                                                    <EditIcon className={classes.editBtn}
                                                        onClick={() => props.editNotification(row)}
                                                    />}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.total}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}