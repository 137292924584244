import React, { Component } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import CategoryIcon from "@material-ui/icons/Category";
import HelpIcon from "@material-ui/icons/Help";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { connect } from "react-redux";
import { changeHeaderName } from "../../actions/dashboardActions";
import BusinessIcon from "@material-ui/icons/Business";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import BlockIcon from "@material-ui/icons/Block";
import ReportIcon from "@material-ui/icons/Report";
import ViewListIcon from "@material-ui/icons/ViewList";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NaturePeopleIcon from "@material-ui/icons/NaturePeople";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ScreenLockPortraitIcon from "@material-ui/icons/ScreenLockPortrait";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";
import { ReactComponent as CampaignSVG } from "../../images/Campaign.svg";
import { ReactComponent as DeliverySVG } from "../../images/Delivery.svg";
import { ReactComponent as SettlementSVG } from "../../images/Settlement.svg";
import { ReactComponent as COMMUNICATIONSVG } from "../../images/COMMUNICATIONSVG.svg";
import {
  getAllCategoryType,
  removeCategoryHistory,
} from "../../actions/categoriesActions";

const styles = (theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  listText: {
    color: "#fff",
  },
  icon: {
    color: "#fff",
  },
});

class ListSideBar extends Component {
  constructor() {
    super();
    this.state = {
      openNestBusiness: false,
      openAdminManagement: false,
      openReports: false,
      openNestOffersManagement: false,
      openNestSettlement: false,
      openNestTags:false
    };
  }

  handleLocalStorageActions = (pathname) => {
    this.props.history.push(pathname);
    localStorage.setItem("lastVisitedPage", pathname);
    localStorage.setItem("lastVisitedUser", this.props.user.role);
    if (localStorage.approvedBusinessDetails) {
      localStorage.removeItem("approvedBusinessDetails");
    }
    if (localStorage.serviceRequestDetails) {
      localStorage.removeItem("serviceRequestDetails");
    }
    if (localStorage.serviceProviderDetails) {
      localStorage.removeItem("serviceProviderDetails");
    }
    if (localStorage.callIconHitData) {
      localStorage.removeItem("callIconHitData");
    }
    if (localStorage.cronJobPerfData) {
      localStorage.removeItem("cronJobPerfData");
    }
    if (localStorage.disabledBusinessDetails) {
      localStorage.removeItem("disabledBusinessDetails");
    }
    if (localStorage.campaignBusinessDetails) {
      localStorage.removeItem("campaignBusinessDetails");
    }
    // if (localStorage.offerCodeCampaignBusinessDetails) {
    //   localStorage.removeItem('offerCodeCampaignBusinessDetails');
    // }
    if (localStorage.campaignBusinessConsumerDetails) {
      localStorage.removeItem("campaignBusinessConsumerDetails");
    }
    if (localStorage.businessCouponSettlementDetails) {
      localStorage.removeItem("businessCouponSettlementDetails");
    }
    if (localStorage.allConsumers) {
      localStorage.removeItem("allConsumers");
    }
  };

  handleClickDashboard = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/dashboard");
  };

  handleClickBusiness = () => {
    this.setState({ openNestBusiness: !this.state.openNestBusiness });
  };

  handleClickTags= () => {
    this.setState({
      openNestTags: !this.state.openNestTags,
    });
  };

  handleClickOffersManagement = () => {
    this.setState({
      openNestOffersManagement: !this.state.openNestOffersManagement,
    });
  };

  handleClickSettlement = () => {
    this.setState({ openNestSettlement: !this.state.openNestSettlement });
  };

  handleClickBusinessPending = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/pendingBusiness");
  };

  handleClickBusinessApproved = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/approvedBusiness");
  };

  handleClickBusinessDisabled = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/disabledBusiness");
  };

  handleClickBusinessRejected = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/rejectedBusiness");
  };

  handleClickReportedBusiness = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/reportedbusiness");
  };

  handleClickAdminManagement = (e) => {
    this.setState({ openAdminManagement: !this.state.openAdminManagement });
  };

  handleClickReports = (e) => {
    this.setState({ openReports: !this.state.openReports });
  };

  handleClickCommunication = () => {
    this.setState({ openNestCommunication: !this.state.openNestCommunication });
  };

  handleClickPlatformAdmin = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/admin");
  };

  handleClickRegionalHead = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/regionalheads");
  };

  handleClickAreaManager = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/areamanagers");
  };

  handleClickManageRegions = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/regions");
  };

  handleClickManageAreas = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/areas");
  };

  handleClickCategories = (e) => {
    this.props.getAllCategoryType();
    this.props.removeCategoryHistory(-1);
    e.preventDefault();
    this.handleLocalStorageActions("/categories");
  };

  handleClickConsumerTag = (e) => {
    // this.props.getAllCategoryType();
    // this.props.removeCategoryHistory(-1);
    e.preventDefault();
    this.handleLocalStorageActions("/consumerTag");
  };

  handleClickBusinessTag = (e) => {
    // this.props.getAllCategoryType();
    // this.props.removeCategoryHistory(-1);
    e.preventDefault();
    this.handleLocalStorageActions("/businessTag");
  };

  handleClickCoupon = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/coupon");
  };

  handleClickCampaigns = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/campaigns");
  };

  handleClickCampaign = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/campaignsCouponSettlement");
  };

  handleClickFAQ = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/faq");
  };

  handleClickAllOrders = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/servicerequests");
  };

  handleClickAllConsumers = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/consumers");
  };

  handleClickBusinessPerformanceReport = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/businessperformance");
  };

  handleClickAllServiceProviders = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/serviceproviders");
  };

  handleClickTopPerformingBusinesses = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/topperformingbusinesses");
  };
  handleClickInvites = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/referrals");
  };

  handleClickMonthlyBusinessReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/businessreports");
  };

  handleClickOnlinePaymentReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/onlinePaymentReports");
  };

  handleClickCallIconHitReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/callIconHitReports");
  };

  handleClickOpenCloseBusinessLogs = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/businessOpenClose");
  };

  handleClickItemsAvailabilityLogs = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/itemsAvailability");
  };

  handlePaymentSummaryReport = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/paymentSummaryReport");
  };

  handleClickCronJobPerfReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/cronJobPerfReports");
  };
  handleTandC = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/termsandconditions");
  };
  handleConfig = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/configurations");
  };
  handleClickOtpReport = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/mobileOtpReport");
  };

  handleClickActiveBusinessUsersReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/BusinessUsersReport");
  };

  handleClickReviewReport = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/ReviewReport");
  };

  handleClickAppManagement = (e) => {
    this.setState({ openAppManagement: !this.state.openAppManagement });
  };
  handleClickManageReleases = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/releases");
  };

  handleMasterOTP = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/MasterOTP");
  };

  handlePushNotification = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/pushNotifications");
  };

  handleClickDeliveriesReports = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/deliveriesreport");
  };

  handleMPin = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/MasterMPin");
  };
  handleAppMaintenace = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/AppMaintenance");
  };
  handlePromoCodeReport = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/promoCodeReport");
  };
  // handleOfferCodeReport = e => {
  //   e.preventDefault();
  //   this.handleLocalStorageActions("/offerCodeReport");
  // };

  handleClickBusinessItemTag = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/businessItemTag");
  };

  handleClickInAppNotice = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/inAppNotice");
  };

  handleExpense = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/expense");
  };

  handleClickPrePaymentOrders = (e) => {
    e.preventDefault();
    this.handleLocalStorageActions("/servicerequests/getAllPrepaymentOrders");
  };

  render() {
    const { classes, user } = this.props;

    return (
      <List
      //  style={{ width: 260 }}
      >
        <ListItem button onClick={this.handleClickDashboard}>
          <ListItemIcon>
            <DashboardIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.listText} primary="Dashboard" />
        </ListItem>

        <ListItem button onClick={this.handleClickBusiness}>
          <ListItemIcon>
            <BusinessIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.listText} primary="Business" />
          {this.state.openNestBusiness ? (
            <ExpandLess className={classes.icon} />
          ) : (
            <ExpandMore className={classes.icon} />
          )}
        </ListItem>
        <Collapse in={this.state.openNestBusiness} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickBusinessPending}
            >
              <ListItemIcon>
                <HourglassFullIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Pending" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickBusinessRejected}
            >
              <ListItemIcon>
                <ThumbDownIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Rejected" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickBusinessApproved}
            >
              <ListItemIcon>
                <ThumbUpIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Approved" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickBusinessDisabled}
            >
              <ListItemIcon>
                <BlockIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Disabled" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickReportedBusiness}
            >
              <ListItemIcon>
                <ReportIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Reported" />
            </ListItem>
          </List>
        </Collapse>

        {/* Admin */}
        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickManageRegions}>
            <ListItemIcon>
              <AccountTreeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Manage Regions"
            />
          </ListItem>
        ) : null}

        {/* Reagion head */}
        {user.role === "regionalHead" ? (
          <ListItem button onClick={this.handleClickManageAreas}>
            <ListItemIcon>
              <AccountTreeIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Manage Areas" />
          </ListItem>
        ) : null}

        {user.role !== "areaManager" ? (
          <ListItem button onClick={this.handleClickAdminManagement}>
            <ListItemIcon>
              <SupervisorAccountIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="User Management"
            />
            {this.state.openAdminManagement ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        ) : null}
        <Collapse
          in={this.state.openAdminManagement}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* Admin */}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleClickPlatformAdmin}
              >
                <ListItemIcon>
                  <AccountBoxIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Platform Admins"
                />
              </ListItem>
            ) : null}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleClickRegionalHead}
              >
                <ListItemIcon>
                  <NaturePeopleIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Regional Heads"
                />
              </ListItem>
            ) : null}

            {/* Reagion head */}
            {user.role === "regionalHead" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleClickAreaManager}
              >
                <ListItemIcon>
                  <NaturePeopleIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Area Managers"
                />
              </ListItem>
            ) : null}
          </List>
        </Collapse>

        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickAppManagement}>
            <ListItemIcon>
              <PhonelinkSetupIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="App Management"
            />
            {this.state.openAdminManagement ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        ) : null}

        <Collapse
          in={this.state.openAppManagement}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {/* Admin */}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleMasterOTP}
              >
                <ListItemIcon>
                  <ScreenLockPortraitIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Master OTP"
                />
              </ListItem>
            ) : null}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleMPin}
              >
                <ListItemIcon>
                  <ScreenLockPortraitIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Master M-PIN"
                />
              </ListItem>
            ) : null}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleClickManageReleases}
              >
                <ListItemIcon>
                  <DynamicFeedIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Manage Releases"
                />
              </ListItem>
            ) : null}
            {user.role === "admin" ? (
              <ListItem
                button
                className={classes.nested}
                onClick={this.handleAppMaintenace}
              >
                <ListItemIcon>
                  <PermDeviceInformationIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="App Maintenance"
                />
              </ListItem>
            ) : null}
          </List>
        </Collapse>
        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickCategories}>
            <ListItemIcon>
              <CategoryIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Categories/Speciality"
            />
          </ListItem>
        ) : null}


        {user.role === "admin" || user.role === "areaManager" ? (
          <ListItem button onClick={this.handleClickTags}>
            <ListItemIcon>
              <BookmarkIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Tags" />
            {this.state.openNestTags ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        ) : null}
        <Collapse
          in={this.state.openNestTags}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={this.handleClickConsumerTag}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalOfferIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Consumer Tag" />
            </ListItem>

            <ListItem
              button
              onClick={this.handleClickBusinessTag}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalOfferIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Business Tag" />
            </ListItem>
          </List>
        </Collapse>

        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickSettlement}>
            <ListItemIcon>
              <SettlementSVG className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Settlement" />
            {this.state.openNestSettlement ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        ) : null}
        <Collapse
          in={this.state.openNestSettlement}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              // onClick={this.handleClickDeliveries}
              className={classes.nested}
            >
              <ListItemIcon>
                <DeliverySVG className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Deliveries" />
            </ListItem>

            <ListItem
              button
              onClick={this.handleClickCampaign}
              className={classes.nested}
            >
              <ListItemIcon>
                <CampaignSVG className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Campaign" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handleClickOffersManagement}>
          <ListItemIcon>
            <LocalOfferIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText
            className={classes.listText}
            primary="Offer Management"
          />
          {this.state.openNestOffersManagement ? (
            <ExpandLess className={classes.icon} />
          ) : (
            <ExpandMore className={classes.icon} />
          )}
        </ListItem>
        <Collapse
          in={this.state.openNestOffersManagement}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={this.handleClickCoupon}
              className={classes.nested}
            >
              <ListItemIcon>
                <ConfirmationNumberIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Coupons" />
            </ListItem>

            <ListItem
              button
              onClick={this.handleClickCampaigns}
              className={classes.nested}
            >
              <ListItemIcon>
                <LocalActivityIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Campaigns" />
            </ListItem>
          </List>
        </Collapse>

        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickCommunication}>
            <ListItemIcon>
              <COMMUNICATIONSVG className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Communication"
            />
            {this.state.openNestCommunication ? (
              <ExpandLess className={classes.icon} />
            ) : (
              <ExpandMore className={classes.icon} />
            )}
          </ListItem>
        ) : null}
        <Collapse
          in={this.state.openNestCommunication}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <ListItem
              button
              onClick={this.handlePushNotification}
              className={classes.nested}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Push Notifications"
              />
            </ListItem>

            <ListItem
              button
              onClick={this.handleClickInAppNotice}
              className={classes.nested}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="In App Notice"
              />
            </ListItem>
          </List>
        </Collapse>

        <ListItem button onClick={this.handleClickReports}>
          <ListItemIcon>
            <AssessmentIcon className={classes.icon} />
          </ListItemIcon>
          <ListItemText className={classes.listText} primary="Reports" />
          {this.state.openReports ? (
            <ExpandLess className={classes.icon} />
          ) : (
            <ExpandMore className={classes.icon} />
          )}
        </ListItem>
        <Collapse in={this.state.openReports} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickAllOrders}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Service Requests"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickPrePaymentOrders}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Preservice Requests"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickAllConsumers}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Consumers" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickAllServiceProviders}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Service Providers"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickTopPerformingBusinesses}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Top Businesses"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickInvites}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Referral Report"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handlePromoCodeReport}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Promo Code Report"
              />
            </ListItem>
            {/* <ListItem
                button
                className={classes.nested}
                onClick={this.handleOfferCodeReport}
              >
                <ListItemIcon>
                  <ViewListIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText
                  className={classes.listText}
                  primary="Offer Code Report"
                />
              </ListItem> */}

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickMonthlyBusinessReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Business Report"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              style={{ paddingRight: " 0px" }}
              onClick={this.handleClickBusinessPerformanceReport}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="User/Order Growth"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickDeliveriesReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText className={classes.listText} primary="Deliveries" />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickOtpReport}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Mobile OTP Report"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickActiveBusinessUsersReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Business Users"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickReviewReport}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Reviews & Ratings"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickOnlinePaymentReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Online Payments"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickCronJobPerfReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Cron Performance"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickCallIconHitReports}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Call Icon Hit Report"
              />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickOpenCloseBusinessLogs}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Business Open Close"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickItemsAvailabilityLogs}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Items Availability"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handleClickBusinessItemTag}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Business Item Tag"
              />
            </ListItem>

            <ListItem
              button
              className={classes.nested}
              onClick={this.handlePaymentSummaryReport}
            >
              <ListItemIcon>
                <ViewListIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                className={classes.listText}
                primary="Transactions"
              />
            </ListItem>

            
          </List>
        </Collapse>
        {user.role === "admin" ? (
          <ListItem button onClick={this.handleClickFAQ}>
            <ListItemIcon>
              <HelpIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="FAQ" />
          </ListItem>
        ) : null}

        {user.role === "admin" ? (
          <ListItem button onClick={this.handleTandC}>
            <ListItemIcon>
              <ListAltIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Terms & Conditions"
            />
          </ListItem>
        ) : null}
        {user.role === "admin" ? (
          <ListItem button onClick={this.handleConfig}>
            <ListItemIcon>
              <ListAltIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listText}
              primary="Configurations"
            />
          </ListItem>
        ) : null}
        {user.role === "admin" ? (
          <ListItem button onClick={this.handleExpense}>
            <ListItemIcon>
              <ListAltIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText className={classes.listText} primary="Expenses" />
          </ListItem>
        ) : null}
      </List>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {
  changeHeaderName,
  getAllCategoryType,
  removeCategoryHistory,
})(withRouter(withStyles(styles)(ListSideBar)));
