import {
	SET_CATEGORIES,
	SET_CATERGORY_HISTORY,
	REMOVE_CATEGORY_HISTORY,
	SELECT_CATEGORY,
	SET_CATEGORY_SUCCESS_MESSAGE,
	SET_CATEGORY_ERROR_MESSAGE,
	REQUEST_CATEGORIES,
	SET_CATEGORIES_BY_TYPE,
	SET_FOOD_TYPE_SUCCESS_MESSAGE,
	SET_FOOD_TYPE_ERROR_MESSAGE,
	GET_CATEGORIES_LIST,
	SET_CATEGORIES_LIST
} from "../actions/types";

const INITIAL_STATE = {
	categories: [],
	categoriesByType: [],
	categoryHistory: [],
	selectedCategory: {},
	sucessMsg: null,
	errorMsg: null,
	isFetching: false,
	ops: "",          // "ADD", "EDIT" and "DELETE"
	categoriesList: [],
	categoryTypes: []
};

export default function catagoriesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case REQUEST_CATEGORIES:
			return { ...state, isFetching: action.payload };
		case SELECT_CATEGORY:
			return { ...state, selectedCategory: action.payload };
		case SET_CATEGORIES:
			return {
				...state,
				categories: action.payload,
				isFetching: false
			};
		case SET_CATEGORIES_BY_TYPE:
			return {
				...state,
				categoriesByType: action.payload,
				isFetching: false
			};
		case SET_CATERGORY_HISTORY:
			return {
				...state,
				categoryHistory: [
					...state.categoryHistory,
					action.payload
				],
			};
		case REMOVE_CATEGORY_HISTORY:
			let historyTemp = state.categoryHistory.filter((item, index) => {
				if (index <= action.payload) {
					return true;
				}
				return false;
			});
			return {
				...state,
				categoryHistory: [
					...historyTemp
				],
			};
		case SET_CATEGORY_SUCCESS_MESSAGE:
			return {
				...state,
				sucessMsg: action.payload.msg,
				ops: action.payload.ops,
				isFetching: false
			};
		case SET_CATEGORY_ERROR_MESSAGE:
			return {
				...state,
				errorMsg: action.payload.msg,
				ops: action.payload.ops,
				isFetching: false
			};
		case SET_FOOD_TYPE_SUCCESS_MESSAGE:
			return {
				...state,
				sucessMsg: action.payload.msg,
				ops: action.payload.ops,
				isFetching: false
			};
		case SET_FOOD_TYPE_ERROR_MESSAGE:
			return {
				...state,
				errorMsg: action.payload.msg,
				ops: action.payload.ops,
				isFetching: false
			};
		case GET_CATEGORIES_LIST:
			return {
				...state,
				categoriesList: action.payload

			};
		case SET_CATEGORIES_LIST:
			return {
				...state,
				categoryTypes: action.payload
			};

		default:
			return state;
	}
}