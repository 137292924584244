import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import _ from 'lodash';
import { connect } from "react-redux";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as UnlinkallIcon } from '../../../../images/UnlinkAll_Icon.svg';
import { ReactComponent as LinkAllIcon } from '../../../../images/LinkAll_Icon.svg';
import { ReactComponent as UnlinkIcon } from '../../../../images/Unlink_Icon.svg';
import { ReactComponent as LinkIcon } from '../../../../images/Link_Icon.svg';
import { getItemsToLinkAddon } from '../../../../actions/addonsAction'
import { fetchAddonGroupToLink } from '../../../../actions/addonGroupAction';
import { Chip} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";



const styles = (theme) => ({
    form: {
        position: "fixed",
        top: 4,
        bottom: 4,
        margin: '0px 200px',
        width: '78.05%',
        background: "white",
        overflow: "auto",
        borderRadius: "1px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        },
        display: 'center'
    },
    dynamicForm: {
        position: "absolute",
        top: 100,
        right: 180,
        left: 180,
        bottom: 150,
        background: "white",
        overflow: "auto",
        borderRadius: "3px!important", '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px'
        }
    },
    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    dynamicHeader: {
        height: '6%',
        margin: '0px 0px',
        width: '78.05%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        position: 'fixed!important',
        ' z-index': 60
    },
    CloseIcon: {
        float: "right",
        color: "white",

    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    topcontainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: "50%",
    },

    bottomcontainer: {
        position: "relative",
        top: "50%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    bottomcontainerDynamic: {
        position: "absolute",
        top: "40%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    table: {
        border: "1px"
    },
    cellPadding: {
        padding: "0px 0px 0px 0px"
    },
    dynamicCellPadding: {
        padding: "50px 0px 0px 0px"
    },
    paper: {
        border: "1px solid #dcdcdc"
    },
    footer: {
        height: '10%',
        float: "right"

    },
});
const useStyles = makeStyles((theme) => ({

    header: {
        height: '8%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,

    },
    CloseIcon: {
        float: "right",
        color: "white",

    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    topcontainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: "50%",
    },

    bottomcontainer: {
        position: "relative",
        top: "50%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    bottomcontainerDynamic: {
        position: "absolute",
        top: "40%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    table: {
        border: "1px"
    },
    cellPadding: {
        padding: "0px"
    },
    paper: {
        border: "1px solid #dcdcdc"
    },
    footer: {
        height: '10%',
        float: "right"

    },
    headerPadding: {
        padding: '0px 0px 0px 10px'
    },
    graySection: {
        background: '#DCDCDC',
        borderRadius: '4px 4px 0px 0px',

    },
    lockedGroup: {
        background: '#ffd1d9'
    },
    gridA: {
        margin: '10px 0px 10px 20px',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        width: '250px',
        fontFamily: 'Georgia'
    },
    gridB: {
        margin: '10px 0px 10px 10px',
        fontSize: '14px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        width: '130px',
        fontWeight: 400,
        fontFamily: 'Georgia'

    },
    flexBox: {
        display: 'flex',
        padding: 0,
    },
    textFormatB: {
        padding: theme.spacing(0, 0, 0, 2),
        textAlign: 'left',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0px',
        color: '#202035',
        margin: theme.spacing(1),
        width: '700px',
        fontFamily: 'Georgia'
    },
    flexEnd: {
        display: 'flex'
    },
    showAddons: {
        color: '#14B7DD',
        display: 'flex',
        cursor: 'pointer',
        padding: '10px 20px 10px 20px'
    },
    showInfo: {
        color: '#14B7DD',
        display: 'flex',
        cursor: 'pointer',
        padding: '10px 50px 10px 20px'
    },

    dynamicBox: {
        height: 'fit-content',
        width: '100%',
        display: "flex", flexWrap: "wrap"
    },
    chipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#FFB300'
    },
    dynamicChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#15B5D8'
    },
    hiddenItemChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#CCCCCC'
    },


}));


export function EnhancedTableToolbar(props) {

    const { showUnSelectedGroup, showSelectedGroup, selectedGroupCount, unSelectedGroupCount, totalAddonGroupCount } = props;
    return (
        <Toolbar style={{ display: "flex!important", padding: "0px" }}>
            <Grid style={{ padding: "0px" }}>
                <Typography align="left">
                    <Checkbox
                        indeterminate={selectedGroupCount > 0 && (selectedGroupCount < totalAddonGroupCount && selectedGroupCount !== totalAddonGroupCount)}
                        checked={selectedGroupCount > 0 ? true : false}
                        onChange={(event) => props.handleGroupSelectAll(event)}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                    Select All
                </Typography>

            </Grid>

            {
                showUnSelectedGroup === false ?
                    <Grid style={{ padding: "0px 0px 0px 60px" }}>
                        <Typography >
                            <Checkbox
                                onChange={(event) => { props.handleShowSelectedGroup(!showSelectedGroup, event) }}
                                checked={showSelectedGroup}
                                disabled={selectedGroupCount > 0 ? false : true}

                            />
                            Show Selected({selectedGroupCount > 0 ? selectedGroupCount : `00`})
                        </Typography>
                    </Grid> : <Grid style={{ padding: "0px 0px 0px 50px" }}></Grid>
            }
            {
                showSelectedGroup === false ?
                    <Grid style={{ padding: "0px 0px 0px 30px" }}>
                        <Typography >
                            <Checkbox
                                onChange={(event) => { props.handleShowUnselectedGroup(!showUnSelectedGroup, event) }}
                                checked={showUnSelectedGroup}
                                disabled={unSelectedGroupCount > 0 ? false : true}


                            />
                            Show Unselected({unSelectedGroupCount > 0 ? unSelectedGroupCount : `00`})

                        </Typography>
                    </Grid> : <Grid style={{ padding: "0px 0px 0px 30px" }}></Grid>
            }
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 200px" }}>
                    Total Addons Found({totalAddonGroupCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function EnhancedTableToolbar2(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 770px" }}>
                    Total Items Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function EnhancedTableToolbar3(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 760px" }}>
                    Total Items Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}

export function EnhancedTableHeadItem(props) {
    const classes = useStyles();

    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Id</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Name</TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell align='left' className={classes.headerPadding}>Food Type</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Quantity</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Unit</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Cost</TableCell>
                <TableCell style={{ padding: '7px 0px 0px 0px' }}><Button onClick={() => props.handleLinkAllItem()}><LinkAllIcon></LinkAllIcon></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function EnhancedTableHeadLinkedItem(props) {
    const classes = useStyles();

    return (
        <TableHead style={{ backgroundColor: "white" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Id</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Item Name</TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell > </TableCell>
                <TableCell align='left' className={classes.headerPadding}>Food Type</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Quantity</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Unit</TableCell>
                <TableCell align='left' className={classes.headerPadding}>Cost</TableCell>
                <TableCell style={{ padding: '7px 0px 0px 0px' }}><Button onClick={() => props.handleUnlinkAll()}><UnlinkallIcon /></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function AddonGroupTable(props) {
    const classes = useStyles();

    return (
        <Container style={{ padding: '0px' }} >
            {props.fetchedGroupRecords && props.fetchedGroupRecords.length ?
                <Container>
                    <EnhancedTableToolbar
                        showUnSelectedGroup={props.showUnSelectedGroup}
                        showSelectedGroup={props.showSelectedGroup}
                        selectedGroupCount={props.selectedGroupCount}
                        isGroupSelectAll={props.isGroupSelectAll}
                        totalAddonGroupCount={props.totalAddonGroupCount}
                        handleShowUnselectedGroup={props.handleShowUnselectedGroup}
                        handleShowSelectedGroup={props.handleShowSelectedGroup}
                        handleGroupSelectAll={props.handleGroupSelectAll}
                        unSelectedGroupCount={props.unSelectedGroupCount}
                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <TableBody>
                                    {props.fetchedGroupRecords && props.fetchedGroupRecords.length ? props.fetchedGroupRecords.map((row, index) => (
                                        <>
                                            <TableRow className={row.isLocked === false ? classes.graySection : classes.lockedGroup} key={row._id}>
                                                <TableCell className={classes.flexBox}  >

                                                    <div className={classes.textFormatB}>
                                                        Group Name:<b> {row.addOnGroupName}</b>
                                                    </div>
                                                    <div>

                                                        {props.showAddonIndex === index ?
                                                            <Typography className={classes.showInfo} onClick={() => props.handleShowAddons(props.showAddonIndex === index ? -1 : index)}>SHOW INFO</Typography>
                                                            :
                                                            <Typography className={classes.showAddons} onClick={() => props.handleShowAddons(props.showAddonIndex === index ? -1 : index)}>SHOW ADDONS</Typography>
                                                        }

                                                    </div>
                                                    <div className={classes.flexEnd}>
                                                        <Checkbox checked={row.isSelected} onClick={(event) => props.handleGroupSelection(event, row._id)} />
                                                    </div>

                                                </TableCell>
                                            </TableRow>

                                            <TableRow  >
                                                {props.showAddonIndex === index ?

                                                    <Container>
                                                        {row.linkedAddOns ?
                                                            <Box className={classes.dynamicBox}>
                                                                {row.linkedAddOns.map((item, index) => {
                                                                    const text = `${item.service} ${` ${`₹`} ${item.cost.fixedCost}`} (${item.cost.quantity ? item.cost.quantity : 'per'} ${item.cost.unit})`;
                                                                    return (
                                                                        <div style={{ margin: '4px' }}>
                                                                            <Chip
                                                                                label={text}
                                                                                className={(item.isDefault === true && item.isDisplay === true && item.isEnabled === true) ? classes.chipBody : (item.isDefault === true && (item.isDisplay === false || item.isEnabled === false)) ? classes.defaultAndHiddenItem : (item.isDisplay === false || item.isEnabled === false) ? classes.hiddenItemChipBody : classes.dynamicChipBody}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Box> : null}
                                                    </Container>
                                                    :
                                                    <Container className={classes.flexBox}>
                                                        <div className={classes.gridA}>
                                                            Selection Type: <b>{row.selectionType}</b>
                                                        </div>
                                                        <div className={classes.gridB}>
                                                            Total Addons: <b>{row.linkedAddOns && row.linkedAddOns.length ? row.linkedAddOns.length : null}</b>
                                                        </div>
                                                        <div className={classes.gridB}>
                                                            Min Selection: <b>{row.minSelection === 0 ? 'NA' : row.minSelection} </b>
                                                        </div>
                                                        <div className={classes.gridB}>
                                                            Max Selection: <b>{row.maxSelection === 0 ? 'NA' : row.maxSelection}</b>
                                                        </div>

                                                    </Container>
                                                }
                                            </TableRow>

                                        </>
                                    )) : <Typography style={{ margin: "0px 460px" }}>No Records Available</Typography>}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noGroupRecordFound === true ? <Typography style={{ margin: "20px 320px" }}>No Results Found </Typography> : null}
        </Container>
    )


}
export function LinkedItemTable(props) {
    const classes = useStyles();


    return (
        <div>
            {props.selecteItemToLink && props.selecteItemToLink.length > 0 ?
                <Container>
                    <EnhancedTableToolbar3
                        totalCount={props.selecteItemToLink.length}

                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHeadLinkedItem
                                    handleUnlinkAll={props.handleUnlinkAll}
                                />
                                <TableBody>
                                    {props.selecteItemToLink.length ? props.selecteItemToLink.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >

                                                <TableCell align="left" style={{ padding: "2px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square" style={{ height: '25px', width: '25px' }} ></Avatar>
                                                        : <Avatar variant="square" style={{ height: '25px', width: '25px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }} colSpan={4}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.foodType ? row.foodType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px' }}><Button onClick={() => props.handleUnLinkItem(row._id)}><UnlinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noItemRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </div>
    )
}
export function ItemTable(props) {
    const classes = useStyles();


    return (
        <div>
            {props.fetchedItemRecords && props.fetchedItemRecords.length > 0 ?
                <Container>
                    <EnhancedTableToolbar2
                        totalCount={props.fetchedItemRecords.length}

                    />
                    <Paper className={classes.paper}>
                        <TableContainer>
                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >
                                <EnhancedTableHeadItem
                                    handleLinkAllItem={props.handleLinkAllItem}
                                />
                                <TableBody>
                                    {props.fetchedItemRecords && props.fetchedItemRecords.length ? props.fetchedItemRecords.map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >

                                                <TableCell align="left" style={{ padding: "2px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square" style={{ height: '25px', width: '25px' }} ></Avatar>
                                                        : <Avatar variant="square" style={{ height: '25px', width: '25px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 5px' }} colSpan={4}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.foodType ? row.foodType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost.quantity ? row.cost.quantity : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 14px' }}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 12px' }}>
                                                    {row.cost.fixedCost ? row.cost.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px' }}><Button onClick={() => props.handleLinkItem(row)}><LinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noItemRecordsFound === true ? <Typography style={{ margin: "10px 320px" }}>No Results Found </Typography> : null}
        </div>
    )
}
class LinkAddonGroup extends React.Component {
    constructor() {
        super();
        this.state = {
            itemIdToLink: "",
            groupIdToLink: "",
            addonGroupId: "",
            fetchedItemRecords: [],
            selecteItemToLink: [],
            fetchedGroupRecords: [],
            noItemRecordsFound: false,
            noGroupRecordFound: false,
            showAddons: false,
            showAddonIndex: null,
            showUnSelectedGroup: false,
            showSelectedGroup: false,
            selectedGroupCount: 0,
            unSelectedGroupCount: 0,
            isGroupSelectAll: false,
            totalAddonGroupCount: 0,
            stateCheck: 0,
            error: {},
            snackOpen: false ,
            modalOpen: false ,
            sucessMsg: null ,
            errorMsg: null ,
            editMode: false ,
        }

    }

    componentDidMount() {

    };

    reset = () => {
        this.setState({
            itemIdToLink: "",
            groupIdToLink: "",
            addonGroupId: "",
            fetchedItemRecords: [],
            selecteItemToLink: [],
            fetchedGroupRecords: [],
            noItemRecordsFound: false,
            noGroupRecordFound: false,
            showAddons: false,
            showAddonIndex: null,
            showUnSelectedGroup: false,
            showSelectedGroup: false,
            selectedGroupCount: 0,
            unSelectedGroupCount: 0,
            isGroupSelectAll: false,
            totalAddonGroupCount: 0,
            error: {},
            // modalOpen: false ,
            // sucessMsg: null ,
            // errorMsg: null ,
            // editMode: false ,
        })
    }


    handleFilterChange = async (event) => {
        this.setState({ [event.target.name]: event.target.value });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        };
    }

    onItemToLinkSearch = async () => {
        if (this.state.itemIdToLink && !this.state.groupIdToLink) {
            let payload = {
                businessId: this.props.businessId,
                itemId: this.state.itemIdToLink.trim(),
                isSearched: true
            }
            await this.props.getItemsToLinkAddon(payload)
            this.setState({ fetchedItemRecords: this.props.addons.itemRecordstoLink.data, noItemRecordsFound: this.props.addons.itemRecordstoLink?.noRecordsFound })
        }
        if (this.state.groupIdToLink && !this.state.itemIdToLink) {
            let payload = {
                businessId: this.props.businessId,
                groupId: this.state.groupIdToLink.trim(),
                isSearched: true
            }
            await this.props.getItemsToLinkAddon(payload);
            this.setState({ fetchedItemRecords: this.props.addons.itemRecordstoLink.data, noItemRecordsFound: this.props.addons.itemRecordstoLink?.noRecordsFound })
        }
    };

    resetSearchedItem = () => {
        this.setState({ itemIdToLink: "", groupIdToLink: "", fetchedItemRecords: [], noItemRecordsFound: false, error: {} })

    }
    resetAddonGroupSearch = () => {
        this.setState({
            addonGroupId: "",
            fetchedGroupRecords: [],
            noGroupRecordFound: false,
            showAddons: false,
            showAddonIndex: null,
            showUnSelectedGroup: false,
            showSelectedGroup: false,
            selectedGroupCount: 0,
            unSelectedGroupCount: 0,
            isGroupSelectAll: false,
            totalAddonGroupCount: 0,
            error: {}
        })

    }

    addonGroupSearch = async () => {
        this.setState({ selectedGroupCount: 0, unSelectedGroupCount: 0, showUnSelectedGroup: false, showSelectedGroup: false, })
        if (this.state.addonGroupId) {
            let payload = {
                businessId: this.props.businessId,
                addonGroupId: this.state.addonGroupId.trim(),
            }
            await this.props.fetchAddonGroupToLink(payload);
            const fetchedGroupRecords = this.props.addonGroup.addonGroupToLink.data
            fetchedGroupRecords.forEach(value => value.isSelected = false)
            this.setState({ fetchedGroupRecords: fetchedGroupRecords, noGroupRecordFound: this.props.addonGroup.addonGroupToLink.noGroupRecordFound })
        }
    }

    handleLinkItem = (item) => {
        let tempArray = [];
        if (item) {
            tempArray.push(...this.state.selecteItemToLink, item)
        };
        let selectedArray = new Set(tempArray.map(obj => obj._id))
        let unSelectedArray = this.state.fetchedItemRecords.filter(obj => !selectedArray.has(obj._id));
        let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
        this.setState({ fetchedItemRecords: unSelectedArray, selecteItemToLink: uniqueSelectedItems })
    }

    handleUnLinkItem = (_id) => {
        let unlinkLinkedItem = _id
        let itemRecords = this.props.addons.itemRecordstoLink.data
        let selecteItemToLink = this.state.selecteItemToLink.filter(obj => obj._id !== unlinkLinkedItem.toString());
        let itemIds = new Set(selecteItemToLink.map(obj => obj._id))
        let unSelectedArray = itemRecords.filter(obj => !itemIds.has(obj._id));
        this.setState({ fetchedItemRecords: unSelectedArray, selecteItemToLink: selecteItemToLink })
    }

    handleLinkAllItem = () => {
        if (this.state.fetchedItemRecords.length) {
            let tempArray = [];
            tempArray.push(...this.state.selecteItemToLink, ...this.state.fetchedItemRecords)
            let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
            this.setState({ selecteItemToLink: uniqueSelectedItems, fetchedItemRecords: [] })
        }
    }

    handleUnlinkAll = () => {
        let itemRecords = this.props.addons.itemRecordstoLink.data
        if (this.state.selecteItemToLink.length) {
            this.setState({ fetchedItemRecords: itemRecords, selecteItemToLink: [] })
        }
    }

    handleShowAddons = (index) => {

        this.setState({ showAddonIndex: index })
    }

    handleGroupSelection = (event, _id) => {
        const { fetchedGroupRecords } = this.state
        for (let index = 0; index < fetchedGroupRecords.length; index++) {
            if (JSON.stringify(fetchedGroupRecords[index]._id) === JSON.stringify(_id)) {
                fetchedGroupRecords[index].isSelected = event.target.checked;
                break;
            }
        }
        const selectedGroupCount = fetchedGroupRecords.filter(element => element.isSelected === true)
        const unSelectedGroupCount = fetchedGroupRecords.filter(element => element.isSelected === false || !element.hasOwnProperty('isSelected'));
        this.setState({ fetchedGroupRecords: fetchedGroupRecords, selectedGroupCount: selectedGroupCount.length, unSelectedGroupCount: unSelectedGroupCount.length })
    }

    handleShowSelectedGroup = (showSelectedGroup, event) => {
        const { fetchedGroupRecords } = this.state
        if (event.target.checked) {
            const selectedGroup = fetchedGroupRecords.filter(element => element.isSelected === true)
            this.setState({ fetchedGroupRecords: selectedGroup, showSelectedGroup: showSelectedGroup })
        } else {
            const fetchedGroupRecords = this.props.addonGroup.addonGroupToLink.data
            this.setState({ fetchedGroupRecords: fetchedGroupRecords, showSelectedGroup: showSelectedGroup })

        }
    }
    handleShowUnselectedGroup = (showUnSelectedGroup, event) => {
        const { fetchedGroupRecords } = this.state
        if (event.target.checked) {
            const selectedGroup = fetchedGroupRecords.filter(element => element.isSelected === false || !element.hasOwnProperty('isSelected'));
            this.setState({ fetchedGroupRecords: selectedGroup, showUnSelectedGroup: showUnSelectedGroup })
        } else {
            const fetchedGroupRecords = this.props.addonGroup.addonGroupToLink.data
            this.setState({ fetchedGroupRecords: fetchedGroupRecords, showUnSelectedGroup: showUnSelectedGroup, showSelectedGroup: false })

        }
    }

    handleGroupSelectAll = (event) => {
        if (event.target.checked) {
            const { fetchedGroupRecords } = this.state
            for (let k = 0; k < fetchedGroupRecords.length; k++) {
                fetchedGroupRecords[k].isSelected = event.target.checked;
            }
            const selectedGroupCount = fetchedGroupRecords.filter(element => element.isSelected === true)
            this.setState({ fetchedGroupRecords: fetchedGroupRecords, selectedGroupCount: selectedGroupCount.length, unSelectedGroupCount: 0 })

        } else {
            const { fetchedGroupRecords } = this.state
            for (let k = 0; k < fetchedGroupRecords.length; k++) {
                fetchedGroupRecords[k].isSelected = event.target.checked;
            }
            this.setState({ fetchedGroupRecords: fetchedGroupRecords, selectedGroupCount: 0, unSelectedGroupCount: fetchedGroupRecords.length, })
        }
    }

    onLinkSubmit = async() => {
        let hasError = false
        const { fetchedGroupRecords, selecteItemToLink, error } = this.state
        const selectedAddonGroupIds = fetchedGroupRecords.filter(element => element.isSelected === true).map(value => value._id);
        const serviceIds = selecteItemToLink.map(element => element._id)
        if (selectedAddonGroupIds.length === 0) {
            error.groupError = 'Please Select Group Records to Link Item'
            this.setState({ error: error });
            hasError = true
        }
        if (serviceIds.length === 0) {
            error.itemError = 'Please Select Item Records to Link Addons Group'
            this.setState({ error: error });
            hasError = true
        }

        if (hasError) {
            return
        }
        let payload = {
            addonGroupIds: selectedAddonGroupIds,
            serviceIds: serviceIds
        }
        this.props.handleLinkingRecordsSubmit(payload)
        this.reset()
    }
      
  componentDidUpdate(prevProps, prevState) {
   
    if (this.props.addonGroup.sucessMsg) {
      this.setState({ sucessMsg: this.props.addonGroup.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    } else if (this.props.addonGroup.errorMsg) {
      this.setState({ errorMsg: this.props.addonGroup.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };

    render() {
        const { classes } = this.props;
        const { fetchedItemRecords, selecteItemToLink, noItemRecordsFound, noGroupRecordFound, fetchedGroupRecords } = this.state
        const groupTotalCount = this.props.addonGroup.addonGroupToLink.data && this.props.addonGroup.addonGroupToLink.data.length
        let foundResults = (fetchedItemRecords && fetchedItemRecords.length) || ((selecteItemToLink && selecteItemToLink.length) || (fetchedGroupRecords && fetchedGroupRecords.length));
        let muiAlert = null;
        if (this.state.sucessMsg) {
          muiAlert = (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.handleSnackClose}
              severity="success"
            >
              {this.state.sucessMsg}
            </MuiAlert>
          );
        } else if (this.state.errorMsg) {
          muiAlert = (
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={this.handleSnackClose}
              severity="error"
            >
              {this.state.errorMsg}
            </MuiAlert>
          );
        }

        return (

            <div className={foundResults > 0 ? classes.form : classes.dynamicForm}>
                <Grid container className={foundResults > 0 ? classes.dynamicHeader : classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom className={classes.headerText}>
                            Link AddonGroups to Items
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.onAddonGroupClose() }} />
                    </Grid>
                </Grid>
                <Container className={foundResults > 0 ? classes.dynamicCellPadding : classes.cellPadding}>
                    <Container className={classes.topcontainer}>
                        <Typography variant="h5" gutterBottom className={classes.addonHeader}>
                            Addon Groups
                        </Typography>
                        <Container style={{ display: "flex!important", padding: "0px" }}>
                            <TextField label="Addon Group Name / ID" margin="dense" variant="outlined" name="addonGroupId" value={this.state.addonGroupId} onChange={this.handleFilterChange}
                                placeholder="Addon Group Name / ID" style={{ width: "75%" }} error={this.state.error.groupError} helperText={this.state.error.groupError} />
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                onClick={this.addonGroupSearch}
                                color='primary'>
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                onClick={this.resetAddonGroupSearch}
                                color='default'>
                                Reset
                            </Button>
                        </Container>

                        <AddonGroupTable
                            noGroupRecordFound={noGroupRecordFound}
                            fetchedGroupRecords={this.state.fetchedGroupRecords}
                            handleShowAddons={this.handleShowAddons}
                            showAddons={this.state.showAddons}
                            showAddonIndex={this.state.showAddonIndex}
                            handleGroupSelection={this.handleGroupSelection}
                            showUnSelectedGroup={this.state.showUnSelectedGroup}
                            showSelectedGroup={this.state.showSelectedGroup}
                            selectedGroupCount={this.state.selectedGroupCount}
                            isGroupSelectAll={this.state.isGroupSelectAll}
                            totalAddonGroupCount={groupTotalCount}
                            handleShowUnselectedGroup={this.handleShowUnselectedGroup}
                            handleShowSelectedGroup={this.handleShowSelectedGroup}
                            handleGroupSelectAll={this.handleGroupSelectAll}
                            unSelectedGroupCount={this.state.unSelectedGroupCount}

                        />
                    </Container>



                    <Container>
                        <Container className={classes.cellPadding}>
                            <LinkedItemTable
                                handleUnLinkItem={this.handleUnLinkItem}
                                selecteItemToLink={selecteItemToLink}
                                handleUnlinkAll={this.handleUnlinkAll}

                            />
                        </Container>
                        <hr></hr>

                        <Typography variant="h5" gutterBottom className={classes.addonHeader}>
                            Items and Variation Groups
                        </Typography>
                        <Container style={{ display: "flex!important", padding: "0px" }}>
                            <TextField label="Item Name / ID" margin="dense" variant="outlined" name='itemIdToLink' value={this.state.itemIdToLink} onChange={this.handleFilterChange} placeholder="Item Name | ID" style={{ width: "37.5%" }} error={(this.state.error.itemError) ? true : false} helperText={this.state.error.itemError} />
                            <TextField label="Group Name / ID" margin="dense" variant="outlined" name='groupIdToLink' value={this.state.groupIdToLink} onChange={this.handleFilterChange} placeholder="Group Name| ID" style={{ width: "37.5%", marginLeft: "10px" }} />
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                onClick={this.onItemToLinkSearch}
                                color='primary'>
                                Search
                            </Button>
                            <Button
                                variant="contained"
                                margin="normal"
                                style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                onClick={() => this.resetSearchedItem()}
                                color='default'>
                                Reset
                            </Button>
                        </Container>
                        <ItemTable
                            fetchedItemRecords={fetchedItemRecords}
                            handleLinkItem={this.handleLinkItem}
                            handleLinkAllItem={this.handleLinkAllItem}
                            noItemRecordsFound={noItemRecordsFound}


                        />
                        <hr></hr>
                        <Container className={classes.footer}>
                            <Button
                                type="submit"
                                variant="contained"
                                margin="normal"
                                onClick={() => { this.onLinkSubmit() }}
                                style={{ maxWidth: "60px", float: "right", margin: "1px 0px 30px 0px", padding: '5px 50px' }}
                                color="primary">
                                SAVE
                            </Button>

                            <Button
                                type="submit"
                                variant="contained"
                                margin="normal"
                                color="default"
                                onClick={() => { this.props.onAddonGroupClose() }}
                                style={{ maxWidth: "60px", float: "right", margin: "1px 10px 5px 0px", padding: '5px 50px' }}
                            >
                                CANCEL
                            </Button>
                        </Container>
                        <Snackbar
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                            open={this.state.snackOpen}
                            autoHideDuration={4000}
                            onClose={this.handleSnackClose}
                            ContentProps={{
                                "aria-describedby": "message-id",
                            }}
                        >
                            {muiAlert}
                        </Snackbar>
                    </Container>
                </Container>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        addons: state.addons,
        addonGroup: state.addonGroup,
    };
};
export default connect(mapStateToProps, {

    getItemsToLinkAddon, fetchAddonGroupToLink,

})(withStyles(styles)(LinkAddonGroup));