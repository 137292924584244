import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  {label:"QUOTED", value:"quoted"},
  { label: "CREATED", value: "created" },
  { label: "QUEUED", value: "queued" },
  { label: "RUNNER ACCEPTED", value: "Runner Accepted" },
  // { label: "ORDER ACCEPTED", value: "order_accepted" },
  { label: "REACHED FOR PICKUP", value: "Reached for pickup" },
  { label: "PICKUP COMPLETE", value: "Pickup Complete" },
  // { label: "STARTED FOR DELIVERY", value: "Out for delivery" }, // old LOC
  { label: "OUT FOR DELIVERY", value: "Out for delivery" },
  { label: "ORDER START TRIP", value: "order_start_trip" },
  { label: "REACHED FOR DELIVERY", value: "Reached for Delivery" },
  { label: "DELIVERED", value: "Delivered" },
  // { label: "ORDER END JOB", value: "order_end_job" },
  { label: "ORDER CANCEL", value: "order_cancel" },
  { label: "CANCELLED", value: "Cancelled" },
  { label: "RUNNER CANCELLED", value: "Runner Cancelled" },
  { label: "DELAYED", value: "delayed" },
  { label: "FAILED", value: "failed" },
  { label: "DRAFT", value: "draft" },
  { label: "INVALID", value: "invalid" },
  { label: "RETURN ACTIVE", value: "return_active" },
  { label: "RETURN FINISHED", value: "return_finished" },
  { label: "DELETED", value: "deleted" },
];

export default function MultipleSelect(props) {
  const classes = useStyles();

  const handleChange = (event) => {
    props.handleFilterChange(event);
  };

  return (
    <div>
      <FormControl variant="outlined" fullWidth className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Delivery Status</InputLabel>
        <Select
          // defaultValue="DELIVERED"
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={props.status}
          name="status"
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name.value}
              style={{ minHeight: "28px", height: "28px" }}
            >
               <Checkbox checked={props.status.indexOf(name.value) > -1} />
               <ListItemText primary={name.label} /> 
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
