import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import _ from 'lodash';
import { 
    getBusinessAgreementDetails,
    createBusinessAgreement,
    clearMsg,
    isFetching,
    isFetchingReset,
    editBusinessAgreement
} from '../../../actions/businessActions';
import BusinessAgreementDetails from "./BusinessAgreementDetails";
import BusinessAgreementForm from "./BusinessAgreementForm";
import Modal from "@material-ui/core/Modal";
import { getBusinessAgreementByBusinessId } from '../../../actions/businessAgreementActions';
import BusinessAgreementDocument from "../../common/BusinessAgreementDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";

const styles = theme => ({
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
        // backgroundColor: "blue"
    },
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
    },
    modalBusinessAgreement: {
        width: "67%",
        height: "95%",
        backgroundColor: theme.palette.background.paper,
        padding: "0px 0px",
        display: "flex",
    },
    modalBG: {
        // flexGrow: 1,
        // justifyContent: "space-between",
        // display: "flex",
        alignItems: "left",
        justifyContent: "left",
        padding: "0px",
    //   flexDirection: "column",
        // width: "130%",
        width: "100%",
        height: "150%",
        // overflow: "auto",
        // backgroundColor: "red",
        marginLeft: "-20px"
    },
    modalCon: {
        // display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
        margin: "0px",
        // flexDirection: "column",
        width: "85%",
        // width: "75%",
        height: "150%",
        // overflow: "hidden"
        // backgroundColor: "blue",
    },
    headerOne: {
        // height: '13%',
        margin: '20px 0px 0px 0px',
        padding: '15px 0px 15px 20px', //top right bottom left
        // width: '100%',
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'left',
        // backgroundColor: 'red',
        borderRadius:"15px 15px 0px 0px",
        fontSize:"14px",
        // backgroundColor: '#e0e0e0'
        backgroundColor: '#EFEFEF'
    },
    contentCon: {
        // height: "60%",
        margin: '0px 0px 0px 0px',
        padding: "20px 50px 20px 30px",
        //   overflow: "auto",
        // overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
    },
});

class BusinessAgreementSection extends Component {
    constructor() {
		super();
		this.state = {
            resetButtonDisabled : false,
            bussinessAgreementModalOpen: false,
            createContractFlag: false,
            editContractFlag: false,
            initialData: {},
            editData: {},
            selectedBA: [],
            addBusinessAgreementDataSucessMsg: null,
            addBusinessAgreementDataErrorMsg: null,
            snackOpen: false
		};
        this.getBusinessAgreementDetails = this.getBusinessAgreementDetails.bind(this);
        this.getBusinessAgreementByBusinessId = this.getBusinessAgreementByBusinessId.bind(this);
        this.createBusinessAgreement = this.createBusinessAgreement.bind(this);
	}

    async componentDidMount() {
        this.getBusinessAgreementDetails(this.props.businessId);
        // await this.setState({selectedBA : this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length > 0 ? this.props.business.businessAgreementDetails : []});
        // this.getBusinessAgreementByBusinessId(this.props.businessId);
    };

    getBusinessAgreementDetails = async (id) => {
        await this.props.isFetching();
        // console.log("==== inside getBusinessAgreementDetails ====");
        let payload = {
            businessId: id,
        }
        await this.props.getBusinessAgreementDetails(payload);
        await this.props.isFetchingReset();
        await this.setState({selectedBA : this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length > 0 ? this.props.business.businessAgreementDetails : []});
    };

    getBusinessAgreementByBusinessId = async () => {
        // console.log("==== inside getBusinessAgreementByBusinessId ====");
        let payload = {
            businessId: this.props.businessId,
        }
        await this.props.getBusinessAgreementByBusinessId(payload);
    }

    // Create a new business contract / agreement
    createBusinessAgreement = async (formValues) => {
        // console.log("===== inside createBusinessAgreement =====");
        // console.log("Payload received: " , formValues);

        let id = this.props.dashboard.setSelectedBusiness._id;
        let autorenewal = parseInt(formValues.autoRenewalYear);
        // console.log(autorenewal)

        let tempDate = moment(formValues.contractStartDate).startOf("day").subtract(1,'d');
        // console.log("tempDate------>", tempDate);
        let rangeContractStartTemp = new Date(moment(tempDate).startOf("day"));
        // console.log("rangeContractStartTemp------>", rangeContractStartTemp);
        let rangeContractEndTemp = new Date(moment(tempDate)
          .add(autorenewal, 'y')
          .add(1,'d')
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss"));

        // console.log(rangeContractEndTemp);
        // console.log(moment(rangeContractEndTemp).format('YYYY-MM-DD[T18:29:59.999Z]'));

        let tempEffectiveDate = moment(formValues.effectiveDate).startOf("day").subtract(1,'d');
        // console.log("tempEffectiveDate------>", tempEffectiveDate);
        let rangeEffectiveStartTemp = new Date(moment(tempEffectiveDate).startOf("day"));
        // console.log("rangeEffectiveStartTemp------>", rangeEffectiveStartTemp);
        // console.log(moment(rangeEffectiveStartTemp).format('YYYY-MM-DD[T18:30:00.000Z]'));

        let payload = {
            businessId: id,
            startDate: moment(rangeContractStartTemp).format('YYYY-MM-DD[T18:30:00.000Z]'),
            endDate: moment(rangeContractEndTemp).format('YYYY-MM-DD[T18:29:59.999Z]'),
            isAutoRenewal: formValues.autoRenewalToggle,
            autoRenewalDate: { years: autorenewal },
            merchantName: formValues.merchantName,
            gstNumber: formValues.gstNumber,
            legalEntityName: formValues.legalEntityName,
            FSSAILicNumber: formValues.fssai,
            shopActNumber: formValues.shopAct,
            address: {
                lineOne: formValues.addressLineOne,
                lineTwo: formValues.addressLineTwo,
                country: formValues.addressCountry,
                city: formValues.addressCity,
                state: formValues.addressState,
                pinCode: parseInt(formValues.addressPinCode)
            },
            businessEmailId: formValues.businessEmail,
            contactPerson: formValues.contactPerson,
            contactNumber: formValues.contactNumber,
            oneTimeSignUpFee: { 
                outletEntity: formValues.outletEntity, 
                rupees: formValues.outletEntitySignUpFee && formValues.outletEntitySignUpFee !== "" && formValues.outletEntitySignUpFee !== undefined && formValues.outletEntitySignUpFee !== null ? _.ceil((parseFloat(formValues.outletEntitySignUpFee)),2) : null
            },
            cancellationFeePerTransaction: { 
                cost: formValues.cancellationFee && formValues.cancellationFee !== "" && formValues.cancellationFee !== undefined && formValues.cancellationFee !== null ? _.ceil((parseFloat(formValues.cancellationFee)),2) : null
            },
            pushNotificationServiceCharges: { 
                cost: formValues.pushNotificationServicesFee && formValues.pushNotificationServicesFee !== "" && formValues.pushNotificationServicesFee !== undefined && formValues.pushNotificationServicesFee !== null ? _.ceil((parseFloat(formValues.pushNotificationServicesFee)),2) : null
            },
            otherMarketingPackages: { 
                type: formValues.otherMarketingPackages, 
                cost: formValues.otherMarketingPackagesCost && formValues.otherMarketingPackagesCost !== "" && formValues.otherMarketingPackagesCost !== undefined && formValues.otherMarketingPackagesCost !== null ? _.ceil((parseFloat(formValues.otherMarketingPackagesCost)),2) : null, 
                description: formValues.otherMarketingPackagesDescription 
            },
            servicesOpted: {
                isDeliveryOrders: formValues.isDeliveryOrders,
                isOnlineBusinessListingOnXirify: formValues.isOnlineBusinessListingOnXirify,
                isPickUpOrders: formValues.isPickUpOrders,
                isServiceAtConsumerLocation: formValues.isServiceAtConsumerLocation,
                isServiceAtBusinessLocation: formValues.isServiceAtBusinessLocation,
                isSelfDelivery: formValues.isSelfDelivery,
                isXirifyPartnerDelivery: formValues.isXirifyPartnerDelivery,
                isONDCRegistration: formValues.isONDCRegistration,
                isONDCExclusiveRegistration: formValues.isONDCExclusiveRegistration
            },
            businessConvCharges: { 
                percentage: formValues.bccPercentage && formValues.bccPercentage !== "" && formValues.bccPercentage !== undefined && formValues.bccPercentage !== null ? _.ceil((parseFloat(formValues.bccPercentage)),2) : null
            },
            effectiveDate: formValues.effectiveDate && formValues.effectiveDate !== "" && formValues.effectiveDate !== null && formValues.effectiveDate !== undefined ? moment(rangeEffectiveStartTemp).format('YYYY-MM-DD[T18:30:00.000Z]') : null,
            notifyMerchantATD: { 
                monthlyNotify: formValues.notifyMerchantMonthSelection && formValues.notifyMerchantMonthSelection !== "" && formValues.notifyMerchantMonthSelection !== undefined && formValues.notifyMerchantMonthSelection !== null ? parseInt(formValues.notifyMerchantMonthSelection) : null, 
                weeklyNotify: formValues.notifyMerchantWeekSelection && formValues.notifyMerchantWeekSelection !== "" && formValues.notifyMerchantWeekSelection !== undefined && formValues.notifyMerchantWeekSelection !== null ? parseInt(formValues.notifyMerchantWeekSelection) : null, 
                dailyNotify: formValues.notifyMerchantDaySelection && formValues.notifyMerchantDaySelection !== "" && formValues.notifyMerchantDaySelection !== undefined && formValues.notifyMerchantDaySelection !== null ? parseInt(formValues.notifyMerchantDaySelection) : null
            },
            restartMerchantATD: { 
                monthlyRestart: formValues.restartMerchantMonthSelection && formValues.restartMerchantMonthSelection !== "" && formValues.restartMerchantMonthSelection !== undefined && formValues.restartMerchantMonthSelection !== null ? parseInt(formValues.restartMerchantMonthSelection) : null, 
                weeklyRestart: formValues.restartMerchantWeekSelection && formValues.restartMerchantWeekSelection !== "" && formValues.restartMerchantWeekSelection !== undefined && formValues.restartMerchantWeekSelection !== null ? parseInt(formValues.restartMerchantWeekSelection) : null, 
                dailyRestart: formValues.restartMerchantDaySelection && formValues.restartMerchantDaySelection !== "" && formValues.restartMerchantDaySelection !== undefined && formValues.restartMerchantDaySelection !== null ? parseInt(formValues.restartMerchantDaySelection) : null 
            },
            businessConvFees: formValues.businessConvFees
        };

        console.log("PAYLOAD ====> ", payload);

        if(formValues.editContractFlag && formValues.editContractFlag === true) {
            console.log("edit api will be called");
            await this.props.editBusinessAgreement(payload);
        } else {
            console.log("create api will be called");
            await this.props.createBusinessAgreement(payload);
        }

        this.setState({ bussinessAgreementModalOpen: false });
        this.getBusinessAgreementDetails(this.props.businessId);
    };

    handleBussinessAgreementModalOpen = () => {
        this.setState({ 
            bussinessAgreementModalOpen: true, createContractFlag: true,
            initialData: {
                merchantName:this.props.selectedBusinessData && this.props.selectedBusinessData.businessName ? this.props.selectedBusinessData.businessName : "",
                gstNumber: this.props.selectedBusinessData && this.props.selectedBusinessData.gstin ? this.props.selectedBusinessData.gstin : "",
                fssai: this.props.selectedBusinessData && this.props.selectedBusinessData.FSSAILicNumber ? this.props.selectedBusinessData.FSSAILicNumber : "",
                shopAct: this.props.selectedBusinessData && this.props.selectedBusinessData.shopAct && this.props.selectedBusinessData.shopAct.number ? this.props.selectedBusinessData.shopAct.number : "",
                businessEmail: this.props.selectedBusinessData && this.props.selectedBusinessData.ownerId && this.props.selectedBusinessData.ownerId.email ? this.props.selectedBusinessData.ownerId.email : "",
                contactPerson: this.props.selectedBusinessData && this.props.selectedBusinessData.ownerId && this.props.selectedBusinessData.ownerId.firstName && this.props.selectedBusinessData.ownerId.lastName ? (this.props.selectedBusinessData.ownerId.firstName + " " + this.props.selectedBusinessData.ownerId.lastName ) : "",
                contactNumber: this.props.selectedBusinessData && this.props.selectedBusinessData.ownerId && this.props.selectedBusinessData.ownerId.primaryMobileNumber ? this.props.selectedBusinessData.ownerId.primaryMobileNumber : ""
            } 
        });
    };

    handleEditBAModalOpen = () => {
        console.log("=== inside editBAModal ===");
        console.log(this.state);
        let baObj = this.state.selectedBA && this.state.selectedBA.length > 0 ? this.state.selectedBA[0] : {};
        this.setState({ 
            bussinessAgreementModalOpen: true, editContractFlag: true,
            editData: {
                merchantName: baObj.merchantName ? baObj.merchantName : "",
                legalEntityName: baObj.legalEntityName ? baObj.legalEntityName : "",
                gstNumber: baObj.gstNumber ? baObj.gstNumber : "",
                fssai: baObj.FSSAILicNumber ? baObj.FSSAILicNumber : "",
                shopAct: baObj.shopActNumber ? baObj.shopActNumber : "",
                addressLineOne: baObj.address && baObj.address.lineOne ? baObj.address.lineOne : "",
                addressLineTwo: baObj.address && baObj.address.lineTwo ? baObj.address.lineTwo : "",
                addressCountry: baObj.address && baObj.address.country ? baObj.address.country : "",
                addressState: baObj.address && baObj.address.state ? baObj.address.state : "",
                addressCity: baObj.address && baObj.address.city ? baObj.address.city : "",
                addressPinCode: baObj.address && baObj.address.pinCode ? baObj.address.pinCode : "",
                businessEmail: baObj.businessEmailId ? baObj.businessEmailId : "",
                contactPerson: baObj.contactPerson ? baObj.contactPerson : "",
                contactNumber: baObj.contactNumber ? baObj.contactNumber : "",
                contractStartDate: baObj.startDate ? moment(baObj.startDate).format('YYYY-MM-DD') : "",
                autoRenewalYear: baObj.autoRenewalDate && baObj.autoRenewalDate.years ? baObj.autoRenewalDate.years : "",
                autoRenewalToggle: baObj.isAutoRenewal ? true : false,
                outletEntity: baObj.oneTimeSignUpFee && baObj.oneTimeSignUpFee.outletEntity ? baObj.oneTimeSignUpFee.outletEntity : "",
                outletEntitySignUpFee: baObj.oneTimeSignUpFee && baObj.oneTimeSignUpFee.rupees !== null && baObj.oneTimeSignUpFee.rupees !== undefined && baObj.oneTimeSignUpFee.rupees >= 0 ? baObj.oneTimeSignUpFee.rupees : "",
                cancellationFee: baObj.cancellationFeePerTransaction && baObj.cancellationFeePerTransaction.cost !== null && baObj.cancellationFeePerTransaction.cost !== undefined && baObj.cancellationFeePerTransaction.cost >= 0 ? baObj.cancellationFeePerTransaction.cost : "",
                pushNotificationServicesFee: baObj.pushNotificationServiceCharges && baObj.pushNotificationServiceCharges.cost !== null && baObj.pushNotificationServiceCharges.cost !== undefined && baObj.pushNotificationServiceCharges.cost >= 0 ? baObj.pushNotificationServiceCharges.cost : "",
                otherMarketingPackages: baObj.otherMarketingPackages && baObj.otherMarketingPackages.type ? baObj.otherMarketingPackages.type : "",
                otherMarketingPackagesCost: baObj.otherMarketingPackages && baObj.otherMarketingPackages.cost !== null && baObj.otherMarketingPackages.cost !== undefined && baObj.otherMarketingPackages.cost >= 0 ? baObj.otherMarketingPackages.cost : "",
                otherMarketingPackagesDescription: baObj.otherMarketingPackages && baObj.otherMarketingPackages.description ? baObj.otherMarketingPackages.description : "",
                isOnlineBusinessListingOnXirify: baObj.servicesOpted && baObj.servicesOpted.isOnlineBusinessListingOnXirify ? true : false,
                isDeliveryOrders: baObj.servicesOpted && baObj.servicesOpted.isDeliveryOrders ? true : false,
                isPickUpOrders: baObj.servicesOpted && baObj.servicesOpted.isPickUpOrders ? true : false,
                isServiceAtConsumerLocation: baObj.servicesOpted && baObj.servicesOpted.isServiceAtConsumerLocation ? true : false,
                isServiceAtBusinessLocation: baObj.servicesOpted && baObj.servicesOpted.isServiceAtBusinessLocation ? true : false,
                isSelfDelivery: baObj.servicesOpted && baObj.servicesOpted.isSelfDelivery ? true : false,
                isXirifyPartnerDelivery: baObj.servicesOpted && baObj.servicesOpted.isXirifyPartnerDelivery ? true : false,
                isONDCRegistration: baObj.servicesOpted && baObj.servicesOpted.isONDCRegistration ? true : false,
                isONDCExclusiveRegistration: baObj.servicesOpted && baObj.servicesOpted.isONDCExclusiveRegistration ? true : false,
                bccPercentage: baObj.businessConvCharges && baObj.businessConvCharges.percentage !== null && baObj.businessConvCharges.percentage !== undefined && baObj.businessConvCharges.percentage >= 0 ? baObj.businessConvCharges.percentage : "",
                effectiveDate: baObj.effectiveDate ? moment(baObj.effectiveDate).format('YYYY-MM-DD') : "",
                notifyMerchantMonthSelection: baObj.notifyMerchantATD && baObj.notifyMerchantATD.monthlyNotify ? baObj.notifyMerchantATD.monthlyNotify : "",
                notifyMerchantWeekSelection: baObj.notifyMerchantATD && baObj.notifyMerchantATD.weeklyNotify ? baObj.notifyMerchantATD.weeklyNotify : "",
                notifyMerchantDaySelection: baObj.notifyMerchantATD && baObj.notifyMerchantATD.dailyNotify ? baObj.notifyMerchantATD.dailyNotify : "",
                restartMerchantMonthSelection: baObj.restartMerchantATD && baObj.restartMerchantATD.monthlyRestart ? baObj.restartMerchantATD.monthlyRestart : "",
                restartMerchantWeekSelection: baObj.restartMerchantATD && baObj.restartMerchantATD.weeklyRestart ? baObj.restartMerchantATD.weeklyRestart : "",
                restartMerchantDaySelection: baObj.restartMerchantATD && baObj.restartMerchantATD.dailyRestart ? baObj.restartMerchantATD.dailyRestart : "",
                businessConvFees: baObj.businessConvFees && baObj.businessConvFees.length > 0 ? baObj.businessConvFees : []
            } 
        });
    };

    handleBussinessAgreementModalClose = () => {
        this.setState({ bussinessAgreementModalOpen: false, editContractFlag: false, createContractFlag: false, editData: {}, initialData: {}});
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.business.addBusinessAgreementDataSucessMsg) {
		    this.setState({ addBusinessAgreementDataSucessMsg: this.props.business.addBusinessAgreementDataSucessMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
            this.getBusinessAgreementDetails(this.props.businessId);
		}  
        if (this.props.business.addBusinessAgreementDataErrorMsg) {
		    this.setState({ addBusinessAgreementDataErrorMsg: this.props.business.addBusinessAgreementDataErrorMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
            this.getBusinessAgreementDetails(this.props.businessId);
		}   
	};

    handleSnackClose = () => {
		this.setState({ 
            snackOpen: false, 
            addBusinessAgreementDataSucessMsg: null,
            addBusinessAgreementDataErrorMsg: null
        });
	};

    render() {
        const { classes } = this.props;
        // console.log("this.props in business agreement section---->",this.props);
        // console.log("this.state in business agreement section---->",this.state);
        
        let muiAlert = null;

        if (this.state.addBusinessAgreementDataSucessMsg) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="success"
                    onClose={this.handleSnackClose}
                >
                    {this.state.addBusinessAgreementDataSucessMsg}
                </MuiAlert>
            );
        } else if (this.state.addBusinessAgreementDataErrorMsg) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    onClose={this.handleSnackClose}
                >
                    {this.state.addBusinessAgreementDataErrorMsg}
                </MuiAlert>
            );
        }
        
        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                    {
                        this.props.user.role === "areaManager" ? 
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.handleBussinessAgreementModalOpen}
                                style={{ cursor: "pointer", marginLeft: "0px" }}
                                disabled={this.props.business && this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length > 0 ? true : false}
                            >
                                Create Contract
                            </Button>
                        : null
                    }

                    {
                        this.props.user.role === "areaManager" ? 
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={this.handleEditBAModalOpen}
                                style={{ cursor: "pointer", marginLeft: "12px" }}
                                disabled={this.props.business && this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length === 0 ? true : false}
                            >
                                Edit
                            </Button>
                         : null
                    }
                    {
                        this.props.user.role === "areaManager" ? 
                            <PDFDownloadLink
                                document={
                                <BusinessAgreementDocument
                                    // businessAgreement={this.props.businessAgreement[0]}
                                    businessAgreement={this.state.selectedBA[0]}
                                    startDate={moment(this.props.businessAgreement[0]?.startDate).format('DD MMM YYYY')}
                                    effectiveDate={moment(this.state.selectedBA[0]?.effectiveDate).format('DD MMM YYYY')}
                                />
                                }
                                fileName={this.props.dashboard.setSelectedBusiness.businessName +`_Business_Agreement`}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={this.getBusinessAgreementByBusinessId}
                                    style={{ cursor: "pointer", marginLeft: "12px" }}
                                    disabled={this.props.business && this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length === 0 ? true : false}
                                >
                                    Download
                                </Button>
                            </PDFDownloadLink>
                           
                        : null
                    }

                    <Grid item xs={12}></Grid>
                    <Container style={{ paddingBottom: '0px' }}>
                        {
                            this.props.business && this.props.business.businessAgreementDetails && this.props.business.businessAgreementDetails.length > 0 ?
                                <BusinessAgreementDetails
                                    updatehistory = {this.props.business.businessAgreementUpdateHistoryDetails}
                                    businessAgreementDetails = {this.props.business.businessAgreementDetails}
                                />
                            : 
                                <div className={classes.modalBG}>
                                <div className={classes.modalCon}>
                                    <Container className={classes.headerOne}><b>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES</b></Container>
                                    <Container className={classes.contentCon} alignitems="center" justifycontent="center">
                                        <Grid container spacing={1} >No Contract Created</Grid>
                                        <Grid container spacing={1} justifycontent="center">
                                        <Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid><Grid item xs={12}></Grid>
                                        </Grid>
                                    </Container>
                                </div>
                                </div>
                        }
                    </Container>

                     {/* Modal for create business aggrement - like creating new business contract */}
                    <Modal
                        className={classes.modal}
                        open={this.state.bussinessAgreementModalOpen}
                        onClose={this.handleBussinessAgreementModalClose}
                        closeAfterTransition
                    >
                        <Container className={classes.modalBusinessAgreement}>
                            <BusinessAgreementForm
                                onClose={this.handleBussinessAgreementModalClose}
                                createContractFlag={this.state.createContractFlag}
                                editContractFlag={this.state.editContractFlag}
                                selectedBusiness={this.props.setSelectedBusiness}
                                initialValues={this.state.editContractFlag ? this.state.editData : this.state.initialData}
                                onSubmit={this.createBusinessAgreement}
                            />
                        </Container>
                    </Modal>


                    <Snackbar
                        anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                        open={this.state.snackOpen}
                        autoHideDuration={2000}
                        onClose={this.handleSnackClose}
                        ContentProps={{'aria-describedby': 'message-id'}}>
                            {muiAlert}
                    </Snackbar>
                    <Backdrop className={classes.backdrop} open={this.props.business.isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        user: state.auth.user,
        business: state.business,
        dashboard: state.dashboard,
        businessAgreement : state.businessAgreement.businessAgreement
	}
};
export default connect(mapStateToProps, { 
    getBusinessAgreementDetails,
    createBusinessAgreement,
    clearMsg,
    isFetching,
    isFetchingReset,
    editBusinessAgreement,
    getBusinessAgreementByBusinessId
})(withStyles(styles)(BusinessAgreementSection));