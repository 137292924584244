export const HEADLIST = "HEADLIST";
export const SUBHEADLIST = "SUBHEADLIST";
export const HEADSERVICELIST = "HEADSERVICELIST";
export const SUBHEADSERVICELIST = "SUBHEADSERVICELIST";
export const BACKBUTTONTEXT = "BACK";
export const CANCELBUTTONTEXT = "CANCEL";
export const SAVEBUTTONTEXT = "SAVE";
export const BACKBUTTONCONFIRMATIONDIALOGTITLE =
  "Are you sure you want to go back?";
export const BACKBUTTONCONFIRMATIONDIALOGCONTENT =
  "Your changes have not been saved. All unsaved changes will be lost.";

export const NEXTBUTTONCONFIRMATIONDIALOGTITLE =
  "Your changes have not been saved.";
export const NEXTBUTTONCONFIRMATIONDIALOGCONTENT =
  "Please save or cancel changes before proceeding.";
