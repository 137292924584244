import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { changeHeaderName } from "../../actions/dashboardActions";
import {
  getBusinessItemTagRecords,
  exportBusinessItemTag,
  requestBusinessItemTagLoader,
  requestBusinessItemTagLoaderFalse,
} from "../../actions/businessItemTagAction";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import BusinessItemTagReportTable from "./BusinessItemTagReportTable";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBusinessTagList} from "../../actions/businessTagAction";
import { getConsumerTagList} from "../../actions/consumerTagAction";
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

const styles = (theme) => ({
  container: {
    width: "100%",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#fff",
  },
  table: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  formControl: {
    minWidth: 225,
    maxWidth: 300,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 500,
    marginleft: "4px",
  },
});

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class BusinessItemTag extends Component {
  constructor(props) {
    super(props);
    this.state = JSON.parse(window.localStorage.getItem("businessItemTagData")) || {
      businessId: "",
      consumerId: "",
      serviceId: "",
      serviceRequestId: "",
      isFrequentlyOrdered: "",
      excludeItemTags: "",
      isMenuEnable: "",
      filterStartDate: "",
      filterEndDate: "",
      timeDateRange: "",
      page: 0,
      rowsPerPage: 10,
      rows: [],
      showDownloadIcon: false,
      order: -1,
      consumerTags:[],
            businessTags:[],
            filteredConsumerTags:[],
            filteredBusinessTags:[]
    };
  }

  componentDidMount() {
    this.props.changeHeaderName("Business Item Tag Report");
    this.fetchBusinessItemTagFilter();
    this.fetchBusinessTags();
    this.fetchConsumerTags();
    window.addEventListener("beforeunload", () => localStorage.removeItem("businessItemTagData"));
  }

  resetFilter = async () => {
    this.setState(
      {
        businessId: "",
        consumerId: "",
        serviceId: "",
        serviceRequestId: "",
        isFrequentlyOrdered: "",
        excludeItemTags: "",
        isMenuEnable: "",
        filterStartDate: "",
        filterEndDate: "",
        timeDateRange: "",
        page: 0,
        filteredBusinessTags:[],
        filteredConsumerTags:[]
      }, () => {
        this.fetchBusinessItemTagFilter();
      }
    );
  };

  fetchBusinessItemTagFilter = async () => {
    this.props.requestBusinessItemTagLoader();
    let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
    let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
    let payload = {
      order: Number(this.state.order),
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      businessId: this.state.businessId.trim(),
      consumerId: this.state.consumerId.trim(),
      serviceId: this.state.serviceId.trim(),
      serviceRequestId: this.state.serviceRequestId.trim(),
      isFrequentlyOrdered: this.state.isFrequentlyOrdered,
      isMenuEnable: this.state.isMenuEnable,
      excludeItemTags: this.state.excludeItemTags,
      filteredBusinessTags:filteredBusinessTagsVar,
      filteredConsumerTags:filteredConsumerTagsVar,
    };
    await this.props.getBusinessItemTagRecords(payload);
    this.setState({ showDownloadIcon: false });
    localStorage.setItem("businessItemTagData", JSON.stringify(this.state));
    this.props.requestBusinessItemTagLoaderFalse();
  };
  fetchBusinessItemTagExcel = async () => {
    this.props.requestBusinessItemTagLoader();
    let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
    let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
    let payload = {
      order: Number(this.state.order),
      filterStartDate: this.state.filterStartDate,
      filterEndDate: this.state.filterEndDate,
      businessId: this.state.businessId.trim(),
      consumerId: this.state.consumerId.trim(),
      serviceId: this.state.serviceId.trim(),
      serviceRequestId: this.state.serviceRequestId.trim(),
      isFrequentlyOrdered: this.state.isFrequentlyOrdered,
      isMenuEnable: this.state.isMenuEnable,
      excludeItemTags: this.state.excludeItemTags,
      filteredBusinessTags:filteredBusinessTagsVar,
      filteredConsumerTags:filteredConsumerTagsVar,
    };
    await this.props.exportBusinessItemTag(payload);
    this.setState({ showDownloadIcon: true });
    this.props.requestBusinessItemTagLoaderFalse();
  };
  fetchConsumerTags=async()=>{
    const response=await getConsumerTagList();
    if(response){
    this.setState({consumerTags:response.data})
}else{
    this.setState({consumerTags:[]})
  }
}

  fetchBusinessTags=async()=>{
    const response=await getBusinessTagList();
    if(response){
      this.setState({businessTags:response.data})
    }else{
      this.setState({businessTags:[]})
    }
    
}

  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };

  setPage = (page) => {
    this.setState({ page }, () => this.fetchBusinessItemTagFilter());
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchBusinessItemTagFilter());
  };

  handleOrderRequestDateRange = (event, picker) => {
    let orderRequestRange =
      picker.startDate.format("DD MMM YYYY") +
      " - " +
      picker.endDate.format("DD MMM YYYY");
    this.setState({
      filterStartDate: picker.startDate.format(),
      filterEndDate: picker.endDate.format(),
    });
    let chosenLabel =
      picker.chosenLabel !== "Custom Range"
        ? picker.chosenLabel
        : orderRequestRange;
    this.setState({ timeDateRange: chosenLabel });
  };

  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

 

  render() {
    const { classes } = this.props;
 
    let businessItemTagData = this.props.businessItemTag.businessItemTagList.data
    let totalCount = this.props.businessItemTag.businessItemTagList.totalCount
    return (
      <Container className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }}
        >
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Search/Filter</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="businessId"
                    label="Business Id / Name"
                    value={this.state.businessId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="consumerId"
                    label="Shopper Id / Name"
                    value={this.state.consumerId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="serviceId"
                    label="Item Id / Name"
                    value={this.state.serviceId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    margin="dense"
                    name="serviceRequestId"
                    label="Order Id"
                    value={this.state.serviceRequestId}
                    variant="outlined"
                    onChange={this.handleFilterChange}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Is Frequently Ordered
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={this.state.isFrequentlyOrdered}
                      name="isFrequentlyOrdered"
                      onChange={this.handleFilterChange}
                      label="Is Frequently Ordered"
                    >
                      <MenuItem
                        value="Yes"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value="No"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Is Item Disabled
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="isMenuEnable"
                      label="Is Item Disabled"
                      value={this.state.isMenuEnable}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem
                        value="Yes"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value="No"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel id="demo-simple-select-standard-label">
                      Exclude Item Tags
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="excludeItemTags"
                      label="Excluded Item Tags"
                      value={this.state.excludeItemTags}
                      onChange={this.handleFilterChange}
                    >
                      <MenuItem
                        value="Yes"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        Yes
                      </MenuItem>
                      <MenuItem
                        value="No"
                        style={{ minHeight: "28px", height: "28px" }}
                      >
                        No
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <DateRangePicker
                    ranges={{
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [moment().subtract(29, "days"), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    onApply={this.handleOrderRequestDateRange}
                    maxSpan={{ months: 12 }}
                    maxDate={moment()}
                    showDropdowns
                  >
                    <TextField
                      fullWidth
                      margin="dense"
                      name="timeDateRange"
                      label="Order Request Date"
                      value={this.state.timeDateRange}
                      style={{ width: "225px" }}
                      variant="outlined"
                    />
                  </DateRangePicker>
                </Grid>
                <Grid item xs={3}>
                    {/* <Typography variant="subtitle2" gutterBottom> Select Business Tags</Typography> */}
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                        <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={this.state.filteredBusinessTags}
                            name="filteredBusinessTags"
                            onChange={this.handleFilterChange}
                            input={<Input />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            >
                            {this.state.businessTags.map((eachBusinessTagObj) => (
                                <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                    <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                    <ListItemText primary={eachBusinessTagObj.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> 
                <Grid item xs={3}>
                {/* <Typography variant="subtitle2" gutterBottom> Select Consumer Tags</Typography> */}
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            id="demo-mutiple-checkbox"
                            multiple
                            value={this.state.filteredConsumerTags}
                            name="filteredConsumerTags"
                            onChange={this.handleFilterChange}
                            input={<Input />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                            >
                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                    <ListItemText primary={eachConsumerTagObj.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> 

                <Grid item xs={12}></Grid>
                <Grid item xs={8}></Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    onClick={this.resetFilter}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    margin="normal"
                    color="primary"
                    onClick={
                      () => this.setPage(0)
                     
                    }
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Container>
        <Container className={classes.table}>
          <BusinessItemTagReportTable
            data={businessItemTagData}
            totalCount={totalCount}
            exportToExcel={this.props.businessItemTag.exportToExcel}
            page={this.state.page}
            rowsPerPage={this.state.rowsPerPage}
            setPage={this.setPage}
            setRowsPerPage={this.setRowsPerPage}
            showDownloadIcon={this.state.showDownloadIcon}
            fetchBusinessItemTagExcel={this.fetchBusinessItemTagExcel}
            
          />
        </Container>
        <Backdrop className={classes.backdrop} open={this.props.businessItemTag.isFetching}> <CircularProgress color="inherit" /> </Backdrop>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessItemTag : state.businessItemTag
  };
};

export default connect(mapStateToProps, {
  changeHeaderName,
  getBusinessItemTagRecords,
  exportBusinessItemTag,
  requestBusinessItemTagLoader,
  requestBusinessItemTagLoaderFalse
})(withStyles(styles)(BusinessItemTag));
