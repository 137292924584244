import axios from "axios";
import {
  SET_NOTICE_APP_SUCCESS_MESSAGE,
  SET_NOTICE_APP_ERROR_MESSAGE,
  GET_ALL_NIOTICES,
  GET_ALL_EXPORT_NOTICES,
  GET_UPDATE_FLAG_SUCCESS_MESSAGE,
  GET_UPDATE_FLAG_ERROR_MESSAGE,
  GET_UPDATE_NOTICE_SUCCESS_MESSAGE,
  GET_UPDATE_NOTICE_ERROR_MESSAGE,
  GET_DELETED_NOTICE_SUCCESS_MESSAGE,
  GET_DELETED_NOTICE_ERROR_MESSAGE

} from "./types";

export const clearMsg = () => async dispatch => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_NOTICE_APP_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_NOTICE_APP_ERROR_MESSAGE, payload: data });
  dispatch({ type: GET_UPDATE_FLAG_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: GET_UPDATE_FLAG_ERROR_MESSAGE, payload: data });
  dispatch({ type: GET_UPDATE_NOTICE_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: GET_UPDATE_NOTICE_ERROR_MESSAGE, payload: data });
  dispatch({ type: GET_DELETED_NOTICE_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: GET_DELETED_NOTICE_ERROR_MESSAGE, payload: data });
};

export const addNewNotice = (payload) => async (dispatch) => {

  try {
    await axios.post("/api/noticeApp/createNotice", payload);
    dispatch({ type: SET_NOTICE_APP_SUCCESS_MESSAGE, payload: { ops: "ADD", msg: "Notice added successfully." } });
  } catch (err) {    
      dispatch({ type: SET_NOTICE_APP_ERROR_MESSAGE, payload: { ops: "ADD", msg: "Failed to add Notice." } });
    
  }
};

export const getAllNotices = (payload) => async (dispatch) => {
  try {
    const response = await axios.get("/api/noticeApp/getallnotices", {
      params: payload,
    });
    dispatch({ type: GET_ALL_NIOTICES, payload: response.data });
  } catch (error) {
    dispatch({ type: GET_ALL_NIOTICES, payload: error });
  }
};
export const updateNoticeFlag = (payload) => async (dispatch) => {
  try {
    await axios.patch(`/api/noticeApp/updateflag`, payload);
    dispatch({
      type: GET_UPDATE_FLAG_SUCCESS_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Notice Flag Updated Successfully",
      },
    });
  } catch (err) {
    dispatch({
      type: GET_UPDATE_FLAG_ERROR_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Failed to update Notice Flag.",
      },
    });
  }
};
export const updateNotice = (payload) => async (dispatch) => {
  try {
    await axios.patch(`/api/noticeApp/updateNotice`, payload);
    dispatch({
      type: GET_UPDATE_NOTICE_SUCCESS_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Notice Updated Successfully",
      },
    });
  } catch (err) {
    dispatch({
      type: GET_UPDATE_NOTICE_ERROR_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Failed to update Notice .",
      },
    });
  }
};

export const deleteNotice = (payload) => async (dispatch) => {
  try {
   await axios.patch("/api/noticeApp/deleteNotice", payload);
    dispatch({
      type: GET_DELETED_NOTICE_SUCCESS_MESSAGE,
      payload: {
        ops: "Delete",
        msg: "Notice Deleted Successfully",
      },
    });
  } catch (error) {
    dispatch({
      type: GET_DELETED_NOTICE_ERROR_MESSAGE,
      payload: {
        ops: "Delete",
        msg: "Failed Deleted Successfully",
      },
    });
  }
};


export const exportNotices = (payload) => async (dispatch) => {
  try {
    const response = await axios.get(
      "/api/noticeApp/exportnotices",
      {
        params: payload,
      }
    );
    dispatch({
      type: GET_ALL_EXPORT_NOTICES,
      payload: response.data.exportToExcel,
    });
  } catch (error) {
    dispatch({ type: GET_ALL_EXPORT_NOTICES, payload: error });
  }
};
