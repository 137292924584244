import axios from "axios";
import * as actions from "./types";

export const getAppMaintenanceData = (payload) => async (dispatch) => {
  try {
    let appMaintenanceData = await axios.get(
      "/api/appmaintenance/getappmaintenancedata",
      {
        params: payload,
      }
    );
    dispatch({
      type: actions.GET_APPMAINTENANCE_DATA,
      payload: appMaintenanceData.data,
    });
  } catch (err) {
    dispatch({
      type: actions.SET_APPMAINTENANCE_DATA_ERROR_MESSAGE,
      payload: "Failed to get data for app maintenance.",
    });
  }
};

export const saveOrUpdateMaintenance = (payload) => async (dispatch) => {
  try {
    await axios.post(`/api/appmaintenance/saveorupdateappmaintenance`, payload);
    dispatch({
      type: actions.SET_APPMAINTENANCE_DATA_SUCCESS_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "App Maintenance Flag updated successfully.",
      },
    });
  } catch (err) {
    dispatch({
      type: actions.SET_APPMAINTENANCE_DATA_ERROR_MESSAGE,
      payload: {
        ops: "UPDATE",
        msg: "Failed to update App Maintenance Flag .",
      },
    });
  }
};

export const clearMsg = () => async (dispatch) => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({
    type: actions.SET_APPMAINTENANCE_DATA_SUCCESS_MESSAGE,
    payload: data,
  });
  dispatch({
    type: actions.SET_APPMAINTENANCE_DATA_ERROR_MESSAGE,
    payload: data,
  });
};
