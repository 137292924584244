import React from "react";
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton, Checkbox } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 20,
  },
  headerBackground: {
    //   backgroundColor: "#F2F2F2"
  },
  rowFormat: {
    padding: "0px 9px 0px 13px"
  },
  rowFormatA: {
    padding: "0px 9px 0px 14px"
  }
}));



function EnhancedTableHead(props) {
  const classes = useStyles();
  const { onAddonSelectAll, isSelectedAddonExist, rowsPerPage } = props;

  return (
    <TableHead className={classes.headerBackground}>
      <TableRow>
        {props.role === 'areaManager' ? <TableCell align="left" style={{ padding: "0px 0px 0px 4px" }}>
          <Checkbox
            indeterminate={isSelectedAddonExist > 0 && (isSelectedAddonExist < rowsPerPage && isSelectedAddonExist !== rowsPerPage)}
            checked={isSelectedAddonExist ? isSelectedAddonExist : null}
            onChange={onAddonSelectAll}
            inputProps={{ "aria-label": "select all desserts" }}

          />  </TableCell> : <TableCell></TableCell>}
        <TableCell align="left"></TableCell>
        <TableCell align="left">Addon Id</TableCell>
        <TableCell align="left">Addon Name</TableCell>
        <TableCell align="left" >Food Type</TableCell>
        <TableCell align="left">Cost</TableCell>
        <TableCell align="left">Quantity</TableCell>
        <TableCell align="left">Unit</TableCell>
        <TableCell align="left">Linked Items</TableCell>
        {/* <TableCell align="left" style={{ paddingLeft : '0px'}}>Availability</TableCell> */}
        <TableCell align="left">Is Enabled</TableCell>
        <TableCell align="left">Hide/Display</TableCell>
        <TableCell> </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));
const EnhancedTableToolbar = (props) => {
  // console.log(`In the props - -- - -  - ->`, props)
  const classes = useToolbarStyles();
  return (
    <Toolbar style={{ paddingLeft: "16px" }}>
      <Typography
        className={classes.title}
        variant="subtitle2"
        id="tableTitle"
        component="div"
      >
        Addons List ({props.totalCount})
      </Typography>

    </Toolbar>
  );
};

export default function EnhancedTable(props) {
  let selectedAddonIds = props.selectedAddonToDelete
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  const isAddonSelected = (_id) => {
    return props.selectedAddonToDelete.indexOf(_id) !== -1;
  };

  const handleSinglAddonClick = (event, _id) => {
    if (event.target.checked) {
      selectedAddonIds.push(_id);
    } else {
      selectedAddonIds = selectedAddonIds.filter((id) => id !== _id);
    }
    props.setSelectedAddonDelete(selectedAddonIds);
  }

  const handleAddonSelectAll = async (event) => {
    if (event.target.checked) {
      const allAddonIds = props.addonList.map((n) => n._id);
      props.setSelectedAddonDelete(allAddonIds);
      return;
    }
    props.setSelectedAddonDelete([]);
  };


  // const emptyRows =
  //   props.rowsPerPage -
  //   Math.min(
  //     props.rowsPerPage,
  //     props.totalCount - props.page * props.rowsPerPage
  //   );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          totalCount={props.totalCount}

        />
        {props.addonList && props.addonList.length ?
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size="small"
              aria-label="a dense table"
            >
              <EnhancedTableHead
                role={props.role}
                isSelectedAddonExist={props.selectedAddonToDelete.length}
                selectedAddonToDelete={props.selectedAddonToDelete}
                onAddonSelectAll={handleAddonSelectAll}
                totalCount={props.totalCount}
                rowCount={props.totalCount}
                rowsPerPage={props.rowsPerPage}
              />
              <TableBody>
                {props.addonList.map((row, index) => {
                  const selectedAddonRecord = isAddonSelected(row._id);
                  // console.log(`------- selectedAddonRecord>`, selectedAddonRecord);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow key={row._id}>
                      {props.role === "areaManager" ?
                        <TableCell style={{ padding: "4px" }} >
                          <Checkbox
                            onClick={(event) => handleSinglAddonClick(event, row._id)}
                            checked={selectedAddonRecord}
                            aria-checked={selectedAddonRecord}
                            selected={selectedAddonRecord}
                            inputProps={{ "aria-labelledby": labelId }}

                          />
                        </TableCell> : <TableCell></TableCell>}
                      <TableCell align="left" style={{ padding: "4px" }}>
                        {row.photos && row.photos[0] && row.photos[0].path ?
                          <Avatar src={row.photos[0].path} variant="square" ></Avatar>
                          : <Avatar variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                      </TableCell>
                      <TableCell align="left" className={classes.rowFormat}>{row.serviceId ? row.serviceId : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormat} style={{ minWidth : '250px'}}>{row.service ? row.service : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormat}>{row.foodType ? row.foodType : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormatA}>{row.cost?.fixedCost ? row.cost?.fixedCost : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormatA}>{row.cost?.quantity ? row.cost?.quantity : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormatA}>{row.cost?.unit ? row.cost?.unit : "NA"}</TableCell>
                      <TableCell align="left" className={classes.rowFormat}>{row.addOnServiceItemParents && row.addOnServiceItemParents.length ?
                        <Container style={{ display: 'flex' ,paddingLeft : '1px' }}>
                          <Typography style={{ fontSize: "0.875rem", marginRight: "16px", display: 'inlineSize!important' }}>Yes</Typography>
                          <Button onClick={() => { props.handleLinkedItemsOpen(row._id) }} style={{ padding: "2px", borderRadius: "15px" }} variant="contained" margin="normal" color="primary">{row.addOnServiceItemParents.length}</Button>
                        </Container> :
                        <Container style={{ display: 'flex' , paddingLeft : '1px' }}><Typography style={{ fontSize: "0.875rem", marginRight: "18px", display: 'inlineSize!important' }}>No</Typography><Button style={{ padding: "2px", borderRadius: "15px" }} variant="contained" margin="normal" color="default">00</Button></Container>}
                      </TableCell>
                      {/* <TableCell align="center" className={classes.rowFormat}>
                        {props.role === "areaManager" ?
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={row.availability.isActive}
                                onChange={(e) => props.handleSwitchChange(e, row)}
                                name="availability"
                                color="primary"
                              />
                            }
                            style={{ font: "0.87rem", color: row.availability.isActive ? 'black' : 'red' }}
                            label={row.availability.isActive ? 'Available' : 'Unavailable'}
                          /> : row.availability.isActive ? 'Available' : 'Unavailable'}
                      </TableCell> */}
                      <TableCell align="center" className={classes.rowFormat}>
                        {props.role === "areaManager" ?
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                checked={row.isEnabled}
                                onChange={(e) => props.handleSwitchChange(e, row)}
                                name="isEnabled"
                                color="primary"
                              />
                            }
                            style={{ font: "0.87rem", color: row.isEnabled ? 'black' : 'red' }}
                            label={row.isEnabled ? 'Yes' : 'No'}
                          />
                          : row.isEnabled ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="center"  className={classes.rowFormat}>
                     {props.role === "areaManager" ?
                       <FormControlLabel
                         control={
                           <Switch
                             size="small"
                             checked={row.isDisplay}
                             onChange={(e) => props.handleSwitchChange(e, row)}
                             name="isDisplay"
                             color="primary"
                           />
                         }
                         style={{ font: "0.87rem", color: row.isDisplay ? 'black' : 'red' }}
                         label={row.isDisplay ? 'Display' : 'Hide'}
                       />
                       : row.isDisplay ? 'Display' : 'Hide'}
                   </TableCell>
                      {props.role === "areaManager" ?
                        <TableCell align="left" className={classes.rowFormat}>
                          <IconButton
                            className={classes.rowFormat}
                            onClick={() => props.handleEditAddonOpen(row)}
                          >
                            <Avatar className={classes.lightBlue} >
                              <EditIcon color="action" />
                            </Avatar> </IconButton></TableCell> : <TableCell></TableCell>}
                    </TableRow>
                  )

                })}
              </TableBody>
            </Table>
          </TableContainer>
          : <Typography style={{ margin: "0px 460px" }}>No Records Available</Typography>}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 75, 100]}
          component="div"
          count={props.totalCount}
          rowsPerPage={props.rowsPerPage}
          page={props.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}

        />
      </Paper>
    </div>
  );
}
