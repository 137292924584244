import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { lightBlue } from '@material-ui/core/colors';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Button, Container, Typography } from "@material-ui/core";
const headers = [
    { id: "photo", disablePadding: false, label: "" },
    { id: "serviceId", disablePadding: false, label: "Item Id" },
    { id: "petPoojaItemId", disablePadding: false, label: "Petpooja Item ID" },
    { id: "petPoojaVariationId", disablePadding: false, label: "Petpooja Variation ID" },
    { id: "service", disablePadding: false, label: "Item Name" },
    { id: "cost", disablePadding: false, label: "Cost" },
    { id: "quantity", disablePadding: false, label: "Quantity" },
    { id: "variationGroupName", disablePadding: false, label: "Group Name" },
    { id: "unit", disablePadding: false, label: "Unit" },
    { id: "linkedAddons", disablePadding: false, label: "Linked Addons" },
    { id: "availabilty", disablePadding: false, label: "Availability" },
    { id: "isEnabled", disablePadding: false, label: "Is Enabled" },
    { id: "isDisplay", disablePadding: false, label: "Hide/Display" },
    { id: "itemTag", disablePadding: false, label: "Item Tag" },
    { id: "head", disablePadding: false, label: "Heads & Subheads" },
    { id: "editAndDeleteButton", disablePadding: false, label: "" }
];
const headersWOPetpooja = [
    { id: "photo", disablePadding: false, label: "" },
    { id: "serviceId", disablePadding: false, label: "Item Id" },
    { id: "service", disablePadding: false, label: "Item Name" },
    { id: "cost", disablePadding: false, label: "Cost" },
    { id: "quantity", disablePadding: false, label: "Quantity" },
    { id: "variationGroupName", disablePadding: false, label: "Group Name" },
    { id: "unit", disablePadding: false, label: "Unit" },
    { id: "linkedAddons", disablePadding: false, label: "Linked Addons" },
    { id: "availabilty", disablePadding: false, label: "Availability" },
    { id: "isEnabled", disablePadding: false, label: "Is Enabled" },
    { id: "isDisplay", disablePadding: false, label: "Hide/Display" },
    { id: "itemTag", disablePadding: false, label: "Item Tag" },
    { id: "head", disablePadding: false, label: "Heads & Subheads" },
    { id: "editAndDeleteButton", disablePadding: false, label: "" }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, role, isSelectionExist, onSelectAllClick, rowsPerPage } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };


    return (
        <TableHead>
            <TableRow>
                {
                    role === "areaManager" ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={isSelectionExist > 0 && (isSelectionExist < rowsPerPage && isSelectionExist !== rowsPerPage)}
                                checked={isSelectionExist ? isSelectionExist : null}
                                onChange={onSelectAllClick}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                        </TableCell> : null
                }

                {props.isPetpoojaEnabled ?
                    headers.map((headCell) => {
                        if (headCell.id === 'petPoojaItemId' && headCell.id === 'petPoojaVariationId' && props.businessCategoryType.toLowerCase() !== 'shopping') {
                            return;
                        }
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                sortDirection={orderBy === headCell.id ? order : false}
                                className={classes.tableCellSmall}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </span>
                                ) : null}
                            </TableCell>
                        )
                    }) :
                    headersWOPetpooja.map((headCell) => {
                        if ((props.businessCategoryType.toLowerCase() !== 'services') && (props.businessCategoryType.toLowerCase() !== 'shopping')) {
                            return;
                        }

                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                sortDirection={orderBy === headCell.id ? order : false}
                                className={classes.tableCellSmall}
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={orderBy === headCell.id ? order : "asc"}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <span className={classes.visuallyHidden}>
                                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                                        </span>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )
                    })
                }
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowData: PropTypes.object.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "auto",
    },
    paper: {
        marginTop: theme.spacing(2),
        width: "auto",
        marginBottom: theme.spacing(2),
    },
    table: {
        width: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        padding: "0px 2px 0px 2px",

    },
    tableCellSmall1: {
        // fontSize: "12px",
        padding: "2px 0px 2px 0px",
    },
    lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    icon: {
        height: 32,
        //  width: 32
    },
    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        padding: "6px 24px 6px 0px"
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    let selectedServices = props.selected;
    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === "asc";
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };


    const handleSelectAllClick = async (event) => {
        if (event.target.checked) {
            const newSelecteds = props.rowData.map((n) => n._id);
            props.setSelected(newSelecteds);
            return;
        }
        props.setSelected([]);
    };


    function handleClick(event, _id) {
        if (event.target.checked === true) {
            selectedServices.push(_id);
        } else {
            selectedServices = selectedServices.filter(id => id !== _id)
        }
        props.setSelected(selectedServices);
    }

    const isSelected = (_id) => props.selected.indexOf(_id) !== -1;

    // console.log("props in services table----->", props);

    return (
       
        <Paper className={classes.paper}>
    { props.rowData && props.rowData.length ? 
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size="small"
                    aria-label="enhanced table"
                >
                    
                    <EnhancedTableHead
                        classes={classes}
                        order={props.order}
                        orderBy={props.orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={props.totalCount}
                        selected={props.selected}
                        totalCount={props.totalCount}
                        isSelectionExist={props.selected.length}
                        rowsPerPage={props.rowsPerPage}
                        onSelectAllClick={handleSelectAllClick}
                        role={props.role}
                        {...props}
                    />
                     <TableBody>
                        {
                            props.rowData.map((row, index) => {
                                // console.log("row ===> ", row);
                                const isItemSelected = isSelected(row._id)
                                const labelId = `enhanced-table-checkbox-${index}`;
                                let itemTags = row.excludeItemTags === true ? "NA" : ( row.mustTry === true ? "Must Try" : ( row.newArrival === true ? "New" : ( row.bestSeller ? "Best Seller" : "NA" ) ) );
                                let sequenceHeadSubheadArray=[]
                                
                                return (
                                    <TableRow
                                        hover
                                        className={classes.tableRow}
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                    >
                                        {props.role === "areaManager" ?
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    onClick={(event) => handleClick(event, row._id)}
                                                    checked={isItemSelected}
                                                    aria-checked={isItemSelected}
                                                    selected={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </TableCell> : null}
                                        <TableCell align="left" >
                                            {row.photos[0] && row.photos[0].path ?
                                                <Avatar  src={row.photos[0].path} variant="square"  ></Avatar>
                                                : <Avatar variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                        </TableCell>
                                        <TableCell align="left" style={{ paddingLeft : "2px"}}>
                                            {row.serviceId}
                                        </TableCell>
                                        {(props.businessCategoryType.toLowerCase() === 'shopping' || props.businessCategoryType.toLowerCase() === 'services') && props.isPetpoojaEnabled === true ?
                                            <TableCell align="left" style={{ paddingLeft : "4px" ,paddingRight : "4px" ,minWidth : "120px"}} >
                                                 {row.petPoojaItemId}
                                            </TableCell> : null
                                        }
                                        {(props.businessCategoryType.toLowerCase() === 'shopping' || props.businessCategoryType.toLowerCase() === 'services') && props.isPetpoojaEnabled === true ?
                                            <TableCell align="left" style={{ paddingLeft : "4px" ,paddingRight : "4px" ,minWidth : "120px"}} >
                                         {row.petPoojaVariationId}
                                            </TableCell> : null
                                        }
                                        <TableCell align="left"  style={{ paddingLeft : "4px" ,paddingRight : "15px"  ,minWidth : "280px"}} > {row.service} </TableCell>
                                        <TableCell align="left" style={{  paddingLeft : "2px" , display : 'inlineSize!important' ,minWidth : "60px"}}>
                                            {(row.cost && (row.cost.costType === "Approx")) ? `${row.cost.from} - ${row.cost.to}` : (row.cost && row.cost.fixedCost ? row.cost.fixedCost : "")}
                                        </TableCell>
                                        <TableCell align="left"  style={{paddingLeft : "1px" , display : 'inlineSize!important' ,minWidth : "80px"}}>{row.cost?.quantity ? row.cost?.quantity : "NA"}</TableCell>

                                        <TableCell align="left" style={{ paddingLeft : "6px" ,paddingRight : "25px" ,minWidth : "190px"}} >{row.variation && row.variation?.variationGroupName ? row.variation?.variationGroupName : "NA"}</TableCell>

                                        <TableCell align="left" style={{paddingLeft : "0px", display : 'inlineSize!important'}}>{row.cost?.unit ? row.cost?.unit : "NA"}</TableCell>

                                        <TableCell align="left" style={{ paddingLeft : "0px"}}>{row.addOnChildServiceItems && row.addOnChildServiceItems.length ?
                                            <Container style={{ display: 'flex' ,  paddingLeft : '1px' }}>
                                                <Typography style={{  fontSize: "0.875rem" ,marginRight : "22px" ,display : 'inlineSize!important' }}>Yes</Typography>
                                                <Button onClick={() => { props.handleLinkedAddonOpen(row._id) }} style={{ padding: "2px", borderRadius: "25px" }} variant="contained" margin="normal" color="primary">{row.addOnChildServiceItems.length}</Button>
                                            </Container> :
                                            <Container style={{ display: 'flex'  ,  paddingLeft : '1px'}}><Typography style={{  fontSize: "0.875rem" ,marginRight : "22px" ,display : 'inlineSize!important'}}>No</Typography><Button style={{ padding: "2px", borderRadius: "25px" }} variant="contained" margin="normal" color="default">00</Button></Container>}
                                        </TableCell>

                                        <TableCell align="left" style={{ paddingLeft : '0px'}}>
                                            {
                                                props.role === "areaManager" ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={row.availability.isActive}
                                                                onChange={(e) => props.handleSwitchChange(e, row)}
                                                                name="availability"
                                                                color="primary"
                                                            />
                                                        }
                                                        style={{ fontSize: "10px", color: row.availability.isActive ? 'black' : 'red' }}
                                                        label={row.availability.isActive ? 'Available' : 'Unavailable'}
                                                    />
                                                    :
                                                    row.availability.isActive ? 'Available' : 'Unavailable'
                                            }
                                        </TableCell>
                                        <TableCell align="left" style={{ paddingLeft : '0px'}}>
                                            {
                                                props.role === "areaManager" ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={row.isEnabled}
                                                                onChange={(e) => props.handleSwitchChange(e, row)}
                                                                name="isEnabled"
                                                                color="primary"
                                                            />
                                                        }
                                                        style={{ fontSize: "10px", color: row.isEnabled ? 'black' : 'red' }}
                                                        label={row.isEnabled ? 'Yes' : 'No'}
                                                    />
                                                    :
                                                    row.isEnabled ? 'Enabled' : 'Disabled'
                                            }
                                        </TableCell>
                                        <TableCell align="left" style={{ paddingLeft : '0px'}}>
                                            {
                                                props.role === "areaManager" ?
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={row.isDisplay}
                                                                onChange={(e) => props.handleSwitchChange(e, row)}
                                                                name="isDisplay"
                                                                color="primary"
                                                            />
                                                        }
                                                        style={{ fontSize: "10px", color: row.isDisplay ? 'black' : 'red' }}
                                                        label={row.isDisplay ? 'Display' : 'Hide'}
                                                    />
                                                    :
                                                    row.isDisplay ? 'Display' : 'Hide'
                                            }
                                        </TableCell>
                                       
                                        <TableCell align="left" style={{ paddingLeft : '0px'}} >{itemTags}</TableCell>
                                        
                                        {
                                            row.heads && row.heads.length > 0 ?
                                                <TableCell align="left" >
                                                    <Table
                                                        aria-labelledby="tableTitle"
                                                        size="small"
                                                        aria-label="enhanced table">
                                                        <TableHead>
                                                        </TableHead>
                                                        <TableBody>
                                                        
                                                            {row.heads.map((head, i) => {
                                                              
                                                                // console.log("head ====> ", head);
                                                                let subH = row.subHeads && row.subHeads.length > 0 && row.subHeads.find(sub => sub !== null && sub !== undefined && sub.parentId === head._id && !sequenceHeadSubheadArray.includes(sub.sequenceHeadSubhead));
                                                                // console.log("subH ====> ", subH);
                                                                if (!subH) {
                                                                    subH = {
                                                                        name: 'NA'
                                                                    }
                                                                }
                                                                sequenceHeadSubheadArray.push(subH.sequenceHeadSubhead
                                                                    )
                                                                return (<TableRow key={i}>
                                                                    <TableCell align="left" className={classes.tableBottomBorder}>{head.name}</TableCell>
                                                                    <TableCell align="right" className={classes.tableBottomBorder}>{subH.name}</TableCell>
                                                                </TableRow>)
                                                            }
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                </TableCell> : <TableCell align="left">
                                                    <Table>
                                                        <TableHead>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                                <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>}

                                        <TableCell align="left" >
                                            {props.role === "areaManager" ?
                                                <IconButton className={classes.icon} style={{ float: "left" }} onClick={() => props.handleEditOpen(row)}>
                                                    <Avatar className={classes.lightBlue}>
                                                        <EditIcon color="action" />
                                                    </Avatar>
                                                </IconButton> : <Avatar className={classes.lightBlue}> <VisibilityIcon onClick={(e) => props.handleListItemClick(row, e)} color="action" /></Avatar>}
                                        </TableCell>
                                    </TableRow>
                                )

                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>      : <Container><Typography style={{ padding :"50px 10px 10px 500px"}}>No Records Available</Typography></Container>}
            <TablePagination
                rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                component="div"
                count={props.totalCount}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Paper>
    );
}
