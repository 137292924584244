import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { getBusinessListRejected, searchRejectedBusiness } from "../../../actions/businessActions";
import { changeHeaderName, showPendingBusinessDetails } from "../../../actions/dashboardActions";

const styles = theme => ({
    content: {
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    table: {
        minWidth: 650,
    },
    head: {
        fontWeight: 700
    },
    searchPaper: {
        padding: '0px 0px',
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
    },
    clearButton: {
        marginLeft: '25px'
    },
    tableRow: {
        cursor: 'pointer'
    }
});


class RejectedBusiness extends Component {

    constructor() {
        super();
        this.state = {
            skip: 0,
            limit: 10,
            pageNumber: 0,
            value: '',
            clearSearch: false,
        };
    }

    componentDidMount() {
        this.props.getBusinessListRejected(this.state.skip, this.state.limit);
        this.props.changeHeaderName("Rejected Business");
    }

    handleListItemClick = (busObj) => {
        this.props.showPendingBusinessDetails(busObj);
        this.props.history.push("/RejectedBusinessDetails");
    }

    handleChangePage = (event, newPage) => {
        var skip;

        if (this.state.clearSearch) {
            this.setState({ pageNumber: newPage });
            skip = this.state.limit * newPage;
            this.setState({ skip: skip });

            var request = {
                skip: skip,
                limit: this.state.limit,
                searchtags: this.state.value,
                status: "REJECTED",
            };

            this.props.searchRejectedBusiness(request);

        } else {

            this.setState({ pageNumber: newPage });
            skip = this.state.limit * newPage;
            this.setState({ skip: skip });
            this.props.getBusinessListRejected(skip, this.state.limit);
        }

    };

    handleChangeRowsPerPage = event => {

        this.setState({ pageNumber: 0 });
        this.setState({ limit: event.target.value });
        this.setState({ skip: 0 });

        this.props.getBusinessListRejected(0, event.target.value);
    };

    handleSearchChange = (event) => {
        this.setState({ value: event.target.value });
    }

    handleClearButton = () => {

        this.setState({ pageNumber: 0 });
        this.setState({ skip: 0 });
        this.setState({ limit: 10 });

        this.props.getBusinessListRejected(this.state.skip, this.state.limit);

        this.setState({ value: "", clearSearch: false });
    }

    handleSearchSubmit = (event) => {
        event.preventDefault();

        this.setState({ pageNumber: 0 });
        this.setState({ skip: 0 });
        this.setState({ clearSearch: true });

        var request = {
            skip: 0,
            limit: this.state.limit,
            searchtags: this.state.value.trim(),
            status: "REJECTED",
        };

        this.props.searchRejectedBusiness(request);
    }

    render() {
        const { classes } = this.props;
        let clearSearchButton = null;

        if (this.state.clearSearch) {
            clearSearchButton = <Button variant="contained"
                color="primary"
                onClick={() => this.handleClearButton()}
                className={classes.clearButton}>
                Clear
                              </Button>
        }

        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <Container className={classes.searchPaper}>
                        <Paper>
                            <form onSubmit={this.handleSearchSubmit}>
                                <InputBase
                                    placeholder="Search"
                                    value={this.state.value}
                                    style={{ width: '240px', margin: '0px 10px' }}
                                    onChange={this.handleSearchChange}
                                />
                                <IconButton type="submit">
                                    <SearchIcon style={{ float: 'right' }} />
                                </IconButton>
                            </form>
                        </Paper>
                        {clearSearchButton}
                    </Container>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead className={classes.head}>
                                <TableRow>
                                    <TableCell>Business Name</TableCell>
                                    <TableCell>Business Id</TableCell>
                                    <TableCell>Owner Name</TableCell>
                                    <TableCell>Contact Number</TableCell>
                                    <TableCell>Category Type</TableCell>
                                    <TableCell>Category</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.business.busRejectedList.map((busObj, index) => {

                                    let ownerName = busObj.ownerId ?
                                        busObj.ownerId.firstName + " " + busObj.ownerId.lastName : 'NOT AVAILABLE';
                                    let categoryTypeName = busObj.categoryType ? busObj.categoryType.name : 'NOT AVAILABLE';
                                    let categoryName = busObj.category ? busObj.category.name : 'NOT AVAILABLE';

                                    return (
                                        <TableRow className={classes.tableRow} hover key={index} button="true" onClick={(e) => this.handleListItemClick(busObj, e)}>
                                            <TableCell component="th" scope="row">
                                                {busObj.businessName}
                                            </TableCell>
                                            <TableCell>{busObj.businessId}</TableCell>
                                            <TableCell>{ownerName}</TableCell>
                                            <TableCell>{busObj.primaryContactNumber}</TableCell>
                                            <TableCell>{categoryTypeName}</TableCell>
                                            <TableCell>{categoryName}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[10]}
                                        component="div"
                                        count={this.props.business.busRejectedListCount}
                                        rowsPerPage={this.state.limit}
                                        page={this.state.pageNumber}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        business: state.business,
        dashboard: state.dashboard,
    }
};

export default connect(mapStateToProps, { getBusinessListRejected, changeHeaderName, showPendingBusinessDetails, searchRejectedBusiness })(withStyles(styles)(RejectedBusiness));