import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Rbtable from './Rbtable';
import Rbsearch from './Rbsearch';
import { getRptAtBusiness } from "../../../actions/businessActions"

const styles = theme => ({
  
});


class ActionTakenList extends Component {
    constructor() {
        super();
        this.state = {
          searchVal: null,
          clearSearch : false,
        };
    }

    componentDidMount() {
        /* 
        * Get the reported business where Action is pending
        */
        var pagi = {};
        pagi.page = 0;
        pagi.skip = 0;
        pagi.limit = 10;
        this.props.getRptAtBusiness(pagi);
    }

    onChangePage = (event, newPage) => {
      
      var pagi = {};

      if (this.state.clearSearch) {
        
        pagi.page = newPage;
        pagi.limit = this.props.business.limit;
        pagi.skip = this.props.business.limit * newPage;
        pagi.businessName = this.state.searchVal;
        this.setState({ clearSearch: true });
        this.props.getRptAtBusiness(pagi);
      
      } else {

        pagi.page = newPage;
        pagi.limit = this.props.business.limit;
        pagi.skip = this.props.business.limit * newPage;
        this.props.getRptAtBusiness(pagi);
      }

    };

    onChange = (event) => {
      this.setState({ searchVal: event.target.value });
    }

    onSubmit = (event) => {
      
      event.preventDefault();
      var pagi = {};
      pagi.page = 0;
      pagi.limit = this.props.business.limit;
      pagi.skip = 0;
      pagi.businessName = this.state.searchVal.trim();
      this.setState({ clearSearch: true });
      this.props.getRptAtBusiness(pagi);
    }

    
    handleClearButton = () => {
      var pagi = {};
      pagi.page = 0;
      pagi.skip = 0;
      pagi.limit = 10;
      this.props.getRptAtBusiness(pagi);
      this.setState({ searchVal: "", clearSearch: false });
    }

    render() {


    return (
      <div>
          <Rbsearch onSubmit={this.onSubmit} searchVal={this.state.searchVal}
                    handleClearButton={this.handleClearButton}
                    onChange={this.onChange}
                    clearSearch={this.state.clearSearch} />
          <Rbtable onChangePage={this.onChangePage}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
  }
};

export default connect(mapStateToProps, { getRptAtBusiness })(withStyles(styles)(ActionTakenList));