import axios from "axios";
import {
    SET_CONTRACT_LIST,
    SET_CONTRACT_SUCCESS_MESSAGE,
    SET_CONTRACT_ERROR_MESSAGE,
    REQUEST_CONTRACTS
} from "./types";

import { approveBusiness } from './businessActions';

export const requestContracts = () => {
    return {
        type: REQUEST_CONTRACTS,
        payload: true
    };
};

export const clearContractMsgs = () => async dispatch => {
    let data = {};
    data.msg = null;
    data.ops = null;
    dispatch({ type: SET_CONTRACT_SUCCESS_MESSAGE, payload: data });
    dispatch({ type: SET_CONTRACT_ERROR_MESSAGE, payload: data });
};

//Get all the FAQs.
export const getContractsByBusiness = (userType) => async dispatch => {
    const response = await axios.get(`/api/faqs/user/${userType}`);
    dispatch({ type: SET_CONTRACT_LIST, payload: response.data });
};

// Add a FAQ
export const addContract = (payload) => async dispatch => {
    let data = {};
    try {
        // console.log(payload)
        let contract = await axios.post("/api/contract", payload.data);
        dispatch(approveBusiness(payload.businessId, payload.businessName, contract.data._id, payload.businessStatus));
        // data.msg = "Contract added successfully";
        // data.ops = "ADD";
        // dispatch({ type: SET_CONTRACT_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log("Error while adding Contract : ", err);
        data.msg = "Failed to add Contract";
        data.ops = "ADD";
        dispatch({ type: SET_CONTRACT_ERROR_MESSAGE, payload: "Failed to add Contract" });
    }
};

// Edit FAQ
export const updateContract = (payload) => async dispatch => {
    let data = {};
    try {
        await axios.patch(`/api/contract/${payload.contractId}`, payload.data);

        data.msg = "Contract updated successfully.";
        data.ops = "EDIT";
        dispatch({ type: SET_CONTRACT_SUCCESS_MESSAGE, payload: data });
    } catch (err) {
        console.log("Error while updating Contract : ", err);
        data.msg = "Failed to update Contract";
        data.ops = "EDIT";
        dispatch({ type: SET_CONTRACT_ERROR_MESSAGE, payload: "Failed to update Contract" });
    }
};


// Creating od new business agreement as per new functionality and then accepting the business from business collection
export const createBusinessAgreementNewContract = (payload) => async dispatch => {
    let data = {};
    try {
       await axios.post(`/api/businessAgreement`, payload);
        dispatch(approveBusiness(payload.businessId, payload.businessName, null, payload.businessStatus));
    } catch (err) {
        console.log("Error while adding Business Agreement : ", err);
        data.msg = "Failed to add Business Agreement";
        data.ops = "ADD";
        dispatch({ type: SET_CONTRACT_ERROR_MESSAGE, payload: "Failed to add Business Agreement" });
    }
};
