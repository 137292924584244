import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSideBar from './ListSideBar';
import MenuItem from '@material-ui/core/MenuItem';
import { logoutUser } from "../../actions/authActions";
import { showPendingBusinessDetails,getAllMasterOtp } from "../../actions/dashboardActions";
import { changeAdminPassword } from '../../actions/adminActions';
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import ChangePassword from '../Admin/ChangePassword';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 250;

const styles = theme => ({
  root: {
    display: 'flex',
    height: "1000vh"
  },
  header: {
    backgroundColor: 'rgb(35, 47, 62)',
    width : '250px',
    height: '64px',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: '100%',
  },
  drawerPaper: {
    backgroundColor: 'rgba(0, 0, 0, 0.87)',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '17px',
  },
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '0px'
  },
  modalContainer: {
      width: '30%',
      height: '80%',
      backgroundColor: theme.palette.background.paper,
  },
  avatar: {
    marginTop: '18px',
    marginLeft: '15px',
    width: "145px",
    height: "30px"
  }
});

class Header extends Component {
  constructor() {
    super();
    this.state = {
      anchorEl: null,
      modalCPOpen: false,
      snackOpen: false,
    };
  }
async componentDidMount(){
  await this.props.getAllMasterOtp();
}
  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalCPOpen: false });
    this.setState({ anchorEl: null });
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onLogoutClick = e => {
    e.preventDefault();
    this.setState({ anchorEl: null });
    this.props.logoutUser();
    localStorage.removeItem("lastVisitedPage");
    localStorage.removeItem("serviceRequestDetails");
    localStorage.removeItem('serviceProviderDetails');
    localStorage.removeItem('approvedBusinessDetails');
    localStorage.removeItem('disabledBusinessDetails');
    this.props.history.push("/");
  };

  handleNavigateBack = () => {
    this.props.showPendingBusinessDetails(null);
    this.props.history.push("/pendingBusiness");
  };

  openCPModal = () => {
    this.setState({ modalCPOpen: true });
  }

  closeCPModal = () => {
    this.setState({ modalCPOpen: false });
  }

  changePassword = (props) => {
    props.id = this.props.auth.user.id;
    this.props.changeAdminPassword(props);
    this.setState({ snackOpen: true });
  }
  //AM - 4333 - user role
  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    console.log("this.props++++++++++",this.props.dashboard.getMasterOTP.data);
    // if(this.prop)
    let webLogo =this.props?.dashboard?.getMasterOTP?.data && this.props?.dashboard?.getMasterOTP?.data.length>0 && this.props?.dashboard?.getMasterOTP?.data[0]?.webLogo ? this.props.dashboard.getMasterOTP.data[0].webLogo : "../../images/xirify_logo.png";
    let brandName = this.props?.dashboard?.getMasterOTP?.data && this.props?.dashboard?.getMasterOTP?.data.length>0 && this.props?.dashboard?.getMasterOTP?.data[0]?.brandName ? this.props.dashboard.getMasterOTP.data[0].brandName : "../../images/xirify_logo.png";
    console.log("this.webLogo++++++++",webLogo);
    console.log("this.brandName++++++++",brandName);



    return (
      <div style={{ height: '100%' }}>
        <CssBaseline />
        {/* AppBar */}
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap style={{ flexGrow: "1" }}>
              {this.props.dashboard.headerName}
            </Typography>
            <div>
              <Button aria-controls="simple-menu" aria-haspopup="true" color="inherit" onClick={this.handleClick}>
                {this.props.auth.user.firstName + " " + this.props.auth.user.lastName}
                <MoreVertRoundedIcon color="inherit" onClick={this.handleClick} />
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={() => this.openCPModal()}>Change Password</MenuItem>
                <MenuItem onClick={this.onLogoutClick}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
          <Drawer variant="permanent" className={classes.drawer} classes={{ paper: classes.drawerPaper}}>
            <div className={classes.header}>
              <img className={classes.avatar}  alt="XIRIFY" src= {webLogo} ></img>
                       <span style={{ 'verticalAlign' : 'super', color: '#fff', fontSize : '0.7rem'}}>
                          {(this.props.auth.user.role === "admin") ? this.props.auth.user.role.toUpperCase() : 
                           (this.props.auth.user.role === "areaManager") ? "AREA MANAGER" :   (this.props.auth.user.role === "regionalHead") ? "REGIONAL HEAD" : ""} 
                        </span>
                        
            </div>
            <Divider />
            <List >
              <ListSideBar />
            </List>
          </Drawer>
          <Container maxWidth="lg">
            <Modal
              className={classes.modal}
              open={this.state.modalCPOpen}
              onClose={this.closeCPModal}
              closeAfterTransition>
              <Container style={{ width: '40%', padding: '0px', height: '65%' }} className={classes.modalContainer}>
                <ChangePassword onSubmit={this.changePassword} onClose={this.closeCPModal} />
              </Container>
            </Modal>
          </Container>
        <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={6000}
            onClose={this.handleSnackClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}>
                <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
                  Successfully changed password.
                </MuiAlert>
        </Snackbar>  
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    dashboard: state.dashboard,
    auth: state.auth
    }
};

export default withRouter(connect(mapStateToProps, { logoutUser, showPendingBusinessDetails, changeAdminPassword ,getAllMasterOtp})(withStyles(styles)(Header)));