import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image ,Font } from '@react-pdf/renderer';
import OpenSansBold from '../../fonts/OpenSansSemiBold.ttf';



Font.register({
   family: "Helvetica",
   src: OpenSansBold,

      });

// Create styles  
const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
    },
    logo:{
        height :"31.32px",width :"100.94px",marginTop:'7px',marginLeft:'100px'
    },
    logoFooter:{
        height :"11.12px",width :"11.07px"
    },
    
    section: {
        margin: 10,
        padding: 20,
        flexGrow: 1,
        
       
    },
    textAlignRight:{
        color: 'black',
        fontWeight: 400, 
        fontSize: 10,
        textAlign: 'right',
        marginRight:'30px',
       
    },
    header: {
        marginBottom: 10,
        backgroundColor:'#f5f5f5',
        paddingTop:'10px',
        paddingBottom:'10px',
        paddingLeft:'30px',
        paddingRight:'50px',
        flexDirection: "row", 
        border:0.3     

      },
    view2Header: {
        backgroundColor:'#f5f5f5',
        paddingTop:'10px',
        paddingBottom:'10px',
        paddingLeft:'30px',
        paddingRight:'50px',
        flexDirection: "row", 
        border:0.3     

      },
      footer: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        flexDirection: "row", 
        backgroundColor:'#FAFAFA'
      },
      footerTwo: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        flexDirection: "row", 
        backgroundColor:'#EBEBEB',       
      },
      footerPage: {
        position: 'absolute',
        bottom: 45,
        left: 0,
        right: 0,
        flexDirection: "row", 
     
      },
});

// Create Document Component
const BusinessAgreementDocument = (props) => {
    // console.log("===BusinessAgreementDocument =====", props);
    let bcfObj = {};
    let bcfSlabs = [];
    let separatorFlag = false;
    let bcf = "";
    let ATDN="";
    let ATDR="";

    if(props.businessAgreement){
        ATDN = props.businessAgreement.notifyMerchantATD ? props.businessAgreement.notifyMerchantATD.monthlyNotify ? props.businessAgreement.notifyMerchantATD.monthlyNotify === 1 ? props.businessAgreement.notifyMerchantATD.monthlyNotify +' Month' : 
        props.businessAgreement.notifyMerchantATD.monthlyNotify+ ' Months' : (props.businessAgreement.notifyMerchantATD && props.businessAgreement.notifyMerchantATD.weeklyNotify ? props.businessAgreement.notifyMerchantATD.weeklyNotify === 1 ? props.businessAgreement.notifyMerchantATD.weeklyNotify  + ' Week' :props.businessAgreement.notifyMerchantATD.weeklyNotify + ' Weeks' : (props.businessAgreement.notifyMerchantATD && props.businessAgreement.notifyMerchantATD.dailyNotify=== 1  ? props.businessAgreement.notifyMerchantATD.dailyNotify + ' Day': props.businessAgreement.notifyMerchantATD.dailyNotify +' Days' )) : "NA"
        // console.log("..ATDN......",ATDN);
        ATDR = props.businessAgreement.restartMerchantATD ? props.businessAgreement.restartMerchantATD.monthlyRestart ? props.businessAgreement.restartMerchantATD.monthlyRestart === 1 ? props.businessAgreement.restartMerchantATD.monthlyRestart + ' Month':  props.businessAgreement.restartMerchantATD.monthlyRestart + ' Months': (props.businessAgreement.restartMerchantATD.weeklyRestart ? props.businessAgreement.restartMerchantATD.weeklyRestart === 1 ? props.businessAgreement.restartMerchantATD.weeklyRestart + ' Week' : props.businessAgreement.restartMerchantATD.weeklyRestar + ' Weeks' : (props.businessAgreement.restartMerchantATD && props.businessAgreement.restartMerchantATD.dailyRestart ? props.businessAgreement.restartMerchantATD.dailyRestart === 1 ?props.businessAgreement.restartMerchantATD.dailyRestart + ' Day': props.businessAgreement.restartMerchantATD.dailyRestart +' Days' : "NA")) : "NA"
        // console.log("..ATDN......",ATDR);

    if(props.businessAgreement) {
        bcfObj = props.businessAgreement && props.businessAgreement.businessConvFees && props.businessAgreement.businessConvFees.length > 0 ? props.businessAgreement.businessConvFees[0] : {};
        bcfSlabs = props.businessAgreement.businessConvFees && props.businessAgreement.businessConvFees.length > 0 && props.businessAgreement.businessConvFees[0].slabs ? props.businessAgreement.businessConvFees[0].slabs : [];
        if(props.businessAgreement.businessConvFees && props.businessAgreement.businessConvFees.length > 0 && props.businessAgreement.businessConvFees[0].slabs && props.businessAgreement.businessConvFees[0].slabs.length > 1 ) separatorFlag = true;
    }
    } 
    // console.log("bcfObj in details screen ===>", bcfObj);
    // console.log("bcfSlabs in details screen ===>", bcfSlabs);

    if(bcfObj && bcfObj !== undefined && bcfObj !== null) {
        if(bcfSlabs && bcfSlabs.length === 0){
            bcf = bcf + (bcfObj && bcfObj.chargeType === 'percentage' ? bcfObj.value + '%' : (bcfObj && bcfObj.chargeType === 'fixed' ? 'Rs.' + bcfObj.value.toFixed(2) + " " : "NA"))
        } else {
            bcfSlabs.map((slab) => {
                // console.log(slab)
                let separator = separatorFlag ? ', ' : ' ';
                if (slab.chargeType === 'percentage') {
                    
                    if(!slab.infi){
                        bcf = bcf + slab.from + ' to ' + slab.to + ' = ' + slab.value + '%' + separator 
                    } else{
                        bcf = bcf + slab.from + ' to Infinity = ' + slab.value + '%' + separator 
                    }
                } else if (slab.chargeType === 'fixed') {
                    if(!slab.infi){
                        bcf = bcf + slab.from + ' to ' + slab.to + ' = Rs.' + slab.value.toFixed(2) + separator 
                    } else{
                        bcf = bcf + slab.from + ' to Infinity = Rs.' + slab.value.toFixed(2) + separator 
                    }
                }
                // console.log("bcf =  " , bcf);
            })
        }
    }

    return (
    
    <Document>
        <Page size="A4" style={styles.page}>
        <Image 
         style={{
              position: 'absolute',
               width: '430px',
               height: '481px',
               top: "181px",
               left: "83px",
               opacity: 0.8, 
               justifyContent: 'flex-end',
               border:0
            }}
             src="https://res.cloudinary.com/xirifydev/image/upload/v1691484016/Agreement%20Icon/Group_33611_lvgx9j.png"/>  
        <View style={styles.header}>
        <Text style={{marginTop: "7px",
        // marginLeft: "5px",
        width: "300px",
        fontFamily:'Helvetica',     
        textAlign: "left",
        fontSize:'16px',      
        color:'#76747F',
        opacity: 1}}>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES
         </Text>
         <Image style={styles.logo} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391183/Agreement%20Icon/Group_32337_2x_kkbvww.png"></Image>       
        </View>
            <View style={styles.section}>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"0px",textAlign:'left',marginLeft:"10px",fontStyle: 'italic'}} >  Merchant Name: {props.businessAgreement?.merchantName}</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",textAlign:'left',marginLeft:"10px"}} >  Legal Entity Name (“Merchant Partner”): {props.businessAgreement?.legalEntityName}</Text>
             
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px"}}>  Address: {props.businessAgreement?.address?.lineOne}, { props.businessAgreement?.address?.lineTwo}, {props.businessAgreement?.address?.city}, {props.businessAgreement?.address?.state},{props.businessAgreement?.address?.country}, {props.businessAgreement?.address?.pinCode}  </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"20px",marginLeft:"10px" }} >  Contract Start Date: {props.startDate}  </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px"}} > Contract End Date: {props.businessAgreement?.autoRenewalDate?.years === 1 ?props.businessAgreement?.autoRenewalDate?.years + " year" :props.businessAgreement?.autoRenewalDate?.years+" years"}  {props.businessAgreement?.isAutoRenewal ? "- Auto Renewal" :""}  </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px",marginRight:"30px" ,lineHeight:1.9}} >  Contract Number: {(props.businessAgreement?.businessAgreementId)} and will be saved in Versions when any changes are made in the Payment Schedule amounts</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px" }} >  New Sign-Up </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px"}} >  Contact Person: {props.businessAgreement?.contactPerson} </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px"}} >  Phone: {props.businessAgreement?.contactNumber}                                                           Email Id: {props.businessAgreement?.businessEmailId} </Text>
               
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px",marginRight:"30px" ,lineHeight:1.9}} >  I.  Payment Schedule: The Merchant Partner shall be liable to pay Xirify the amounts set out below (as applicable): </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"0px",marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9,paddingTop:"0px" }} >  a. One Time Sign-Up Fee  </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"0px",marginLeft:"45px" }} >  (i).{props.businessAgreement?.oneTimeSignUpFee?.outletEntity ==="single"?"Single" :props.businessAgreement?.oneTimeSignUpFee?.outletEntity ==="multiple" ? "Multiple" :"" } Outlet Entity: {props.businessAgreement?.oneTimeSignUpFee?.rupees ? "Rs." +props.businessAgreement?.oneTimeSignUpFee?.rupees.toFixed(2) :  "NA"} </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9  }} > b. Business Convenience Charges (BCC) On Transaction {bcf} on Invoice Value of each Orders Which Includes Item Cost, Delivery Charge (Self + Partner) and Xirify Sponsored Coupons if applicable.</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"25px" }} > c.  Cancellation Fee {props.businessAgreement?.cancellationFeePerTransaction?.cost ?"Rs." + props.businessAgreement?.cancellationFeePerTransaction?.cost.toFixed(2): "NA"}/Cancelled Transactions</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"25px" }} > d.  Push Notification Services {props.businessAgreement?.pushNotificationServiceCharges?.cost ? "Rs." +props.businessAgreement?.pushNotificationServiceCharges?.cost.toFixed(2): "NA"}/PN </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"25px"  }} > e.  Other Marketing Packages:  {props.businessAgreement?.otherMarketingPackages?.type === "oneTime" ? "One Time" : props.businessAgreement?.otherMarketingPackages?.type === "monthly" ? "Monthly" : props.businessAgreement?.otherMarketingPackages?.type === "threeMonths" ? "Three Months" : props.businessAgreement?.otherMarketingPackages?.type === "sixMonths" ? "Six Months" : props.businessAgreement?.otherMarketingPackages?.type === "twelveMonths"?"Twelve Months" :"NA"}</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"45px"  }} >Description: {props.businessAgreement?.otherMarketingPackages?.description || "NA"}</Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"45px"  }} >Cost: {props.businessAgreement?.otherMarketingPackages?.cost ? "Rs."+ props.businessAgreement?.otherMarketingPackages?.cost.toFixed(2): "NA"}  </Text>
                <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"25px" ,marginRight:"30px" ,lineHeight:1.9}} >All charges (Delivery Charges + Business Convenience Charges) will be deducted by Xirify for each transaction in case of Online Payment. For order / transactions where shoppers pay directly to merchant for order generated through Xirify, Merchant Partner is liable to pay Xirify all charges for consecutive orders / transactions or via Online Payment (Xirify representative will share the link or UPI ID for online payment with the Merchant Partner).  </Text>

            </View>
            <View style={styles.footerPage}>
            <Text style={{fontSize: 10,color:'#C4C4C4',textAlign:"right",marginRight:'40px'}}> Page 1 </Text>   
            </View>
            <View style={styles.footer}>
            <Image style={ {height :"11.12px",width :"11.07px",marginLeft:"30px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391129/Agreement%20Icon/Group_33613_2x_pjssjd.png"></Image>
            <Text style={{ color: 'black',fontSize: 10}}> +91 8007002856 </Text>
            <Image style={{height :"11.12px",width :"11.07px",marginLeft:"252px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391160/Agreement%20Icon/Group_33612_2x_bpl0ev.png"></Image>
            <Text style={{ color: 'black',fontSize: 10 ,marginLeft:"5px"}}> info@xirify.com </Text>
            <Image style={styles.logoFooter} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391147/Agreement%20Icon/Path_143068_2x_dsajn1.png"></Image>
            <Text style={{ color: 'black',fontSize: 10,marginLeft :"5px"}}> www.xirify.com </Text>
           </View>
           <View style={styles.footerTwo}>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"30px"}}>Confidential Documnet</Text>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"260px"}}>Copyright © 2023 Xirify Systems Private Limited</Text>
           </View>
        </Page>
        <Page size="A4" style={styles.page}>
        <Image 
         style={{
              position: 'absolute',
               width: '430px',
               height: '481px',
               top: "181px",
               left: "83px",
               opacity: 0.8, 
               justifyContent: 'flex-end',
               border:0
            }}
             src="https://res.cloudinary.com/xirifydev/image/upload/v1691484016/Agreement%20Icon/Group_33611_lvgx9j.png"/>  
        <View style={styles.view2Header}>
        <Text style={{marginTop: "7px",
        // marginLeft: "5px",
        width: "300px",
        fontFamily:'Helvetica',     
        textAlign: "left",
        fontSize:'16px',      
        color:'#76747F',
        opacity: 1}}>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES
         </Text>
         <Image style={styles.logo} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391183/Agreement%20Icon/Group_32337_2x_kkbvww.png"></Image>       
        </View>
        <View style={styles.section}>
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"10px"}} >Services Opted For:</Text>
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"35px" }} >1. Online Business Listing On Xirify - {props.businessAgreement && props.businessAgreement.servicesOpted && props.businessAgreement?.servicesOpted?.isOnlineBusinessListingOnXirify ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >2. Delivery Orders - {props.businessAgreement?.servicesOpted?.isDeliveryOrders ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px"}} >3. Pick Up Orders  - {props.businessAgreement?.servicesOpted?.isPickUpOrders ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >4. Service at customer Location  - {props.businessAgreement?.servicesOpted?.isServiceAtConsumerLocation ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >5. Service at Business Location- {props.businessAgreement?.servicesOpted?.isServiceAtBusinessLocation ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >6. Self-Delivery - {props.businessAgreement?.servicesOpted?.isSelfDelivery ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >7. Xirify Partner Delivery - {props.businessAgreement?.servicesOpted?.isXirifyPartnerDelivery ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >8. ONDC Registration - {props.businessAgreement?.servicesOpted?.isONDCRegistration ? "YES" :"NO"}</Text>               
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:"35px" }} >9. ONDC Exclusive Registration - {props.businessAgreement?.servicesOpted?.isONDCExclusiveRegistration ? "YES" :"NO"}</Text>    
            <Text style={{ color: 'black', fontSize: 10,marginTop:"20px",marginLeft:"10px" }} > II.	Additional Terms:</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"35px" }} >1.	Business Convenience Charge: {props.businessAgreement?.businessConvCharges?.percentage || "NA"}% (GST Inclusive)</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"45px" }} >(i)	Effective Date: {props.effectiveDate}</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"55px" ,marginRight:"30px" ,lineHeight:1.9}} >a.	For New Sign-Up shall mean the date the Merchant Partner receives its first Order</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"55px" ,marginRight:"30px" ,lineHeight:1.9}} >b.	For Existing Merchant Partner shall mean from the Execution Date of this form or as per the notification sent by Xirify to the Merchant Partner.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"45px",marginRight:"30px" ,lineHeight:1.9  }} >(ii)	The Contract shall be automatically renewed at the end of every term at the sole discretion of Xirify for the same period as the previous term. In the event, the Merchant Partner does not wish to automatically renew the term for an additional period, the Merchant Partner shall provide Xirify a written notice of 7 days prior to the expiry of the term.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px", marginLeft:"35px"}} >2. Logistics Services and Charges:</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"45px" ,marginRight:"30px" ,lineHeight:1.9 }} >(i) The Logistics Services shall be rendered in accordance with the terms.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px", marginLeft:"45px" ,marginRight:"30px" ,lineHeight:1.9}} >(ii) The Merchant Partner agrees it shall not charge delivery charges from Customers for Orders for which Xirify undertakes Logistics Services. </Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"7px",marginLeft:"45px" ,marginRight:"30px" ,lineHeight:1.9}} >(iii) Xirify has no control, in any manner whatsoever, with respect to the ratings or reviews received by the Merchant Partner for the Logistics Services availed through Xirify, as such ratings are dependent on multiple factors including but not limited to each customer's experience.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px", marginLeft:"35px",marginRight:"30px" ,lineHeight:1.9 }} >3. Taxes: All amounts payable herein shall be inclusive of applicable taxes, which shall be payable by the Merchant Partner.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"35px",marginRight:"30px" ,lineHeight:1.9  }} >4. Pickup Order: In the event Merchant Partner has signed up for Pickup, the Merchant Partner agrees to be bound by the terms for Pickup, as set out in the Terms.</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"35px" ,marginRight:"30px" ,lineHeight:1.9 }} >5. Merchant Partner Compensation: As applicable and in accordance to the Terms.</Text>           
                  
        </View>
        <View style={styles.footerPage}>
            <Text style={{fontSize: 10,color:'#C4C4C4',textAlign:"right",marginRight:'40px'}}> Page 2</Text>   
         </View>
         <View style={styles.footer}>
            <Image style={ {height :"11.12px",width :"11.07px",marginLeft:"30px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391129/Agreement%20Icon/Group_33613_2x_pjssjd.png"></Image>
            <Text style={{ color: 'black',fontSize: 10}}> +91 8007002856 </Text>
            <Image style={{height :"11.12px",width :"11.07px",marginLeft:"252px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391160/Agreement%20Icon/Group_33612_2x_bpl0ev.png"></Image>
            <Text style={{ color: 'black',fontSize: 10 ,marginLeft:"5px"}}> info@xirify.com </Text>
            <Image style={styles.logoFooter} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391147/Agreement%20Icon/Path_143068_2x_dsajn1.png"></Image>
            <Text style={{ color: 'black',fontSize: 10,marginLeft :"5px"}}> www.xirify.com </Text>
          </View>
           <View style={styles.footerTwo}>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"30px"}}>Confidential Documnet</Text>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"260px"}}>Copyright © 2023 Xirify Systems Private Limited</Text>
           </View>
        </Page>
        <Page size="A4" style={styles.page}>
        <Image 
         style={{
              position: 'absolute',
               width: '430px',
               height: '481px',
               top: "181px",
               left: "83px",
               opacity: 0.8, 
               justifyContent: 'flex-end',
               border:0
            }}
             src="https://res.cloudinary.com/xirifydev/image/upload/v1691484016/Agreement%20Icon/Group_33611_lvgx9j.png"/>  
        <View style={styles.header}>
        <Text style={{marginTop: "7px",
        // marginLeft: "5px",
        width: "300px",
        fontFamily:'Helvetica',     
        textAlign: "left",
        fontSize:'16px',      
        color:'#76747F',
        opacity: 1}}>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES
         </Text>
         <Image style={styles.logo} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391183/Agreement%20Icon/Group_32337_2x_kkbvww.png"></Image>       
        </View>
            <View style={styles.section}>
            
            <Text style={{ color: 'black', fontSize: 10,marginLeft:"10px"  }} >III.  Payment Settlement Process:</Text>           
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:"25px" ,marginRight:"30px" ,lineHeight:1.9 }} >Xirify shall transfer the Order Value received to the Merchant Partner after deduction of the following amounts as applicable:</Text>    
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"45px" }} >(i)	Business Convenience Charges (BCC)</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px", marginLeft:"45px"}} >(ii)	Payment Mechanism Fee if applicable</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"45px" }} >(iii)	One Time Sign-up Fee if applicable</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"45px" }} >(iv)	Cancellation Fee applicable </Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"45px",marginRight:"30px"  }} >(v)	Any other amounts, charges that are due to Xirify</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"25px", marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9}} >The Parties acknowledge and agree that after the deduction of the aforementioned amounts, Xirify shall remit the Order Value due to the Merchant Partner.</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"25px" ,marginRight:"30px" ,lineHeight:1.9 }} >Within 48hrs (Excluding Bank Holidays), after allowing reasonable time for adjustments towards orders for which the customers have either refused to pay or have claimed a refund, as applicable (but in accordance with the guidelines prescribed by Reserve Bank of India for payment systems and nodal account). </Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9  }} >Xirify allows post-payments from shoppers. For any fulfilled and undisputed delivery orders Xirify Systems Pvt.Ltd. takes full responsibility of collecting or compensating the merchant for any unpaid order by the 15th of next month.  </Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9}} >Xirify shall remit the order value due to the Merchant Partner within a period of 3 bank working days from the date the payment of the order value is received by Xirify.  </Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"25px",marginRight:"30px" ,lineHeight:1.9  }} >For the avoidance of doubt, for all order value received by Xirify on COD/Direct Payment method, the Merchant Partner must mark the order as paid on the App. Xirify will not take any liability for pickup orders if the shoppers confirms he/she has paid the merchant directly. The Merchant Partner shall thereafter transfer all amounts payable towards the applicable (a) BCC (b)Delivery Charges if collected form shoppers on Xirify partner delivery service (c) Cancellation Fee (f) Any other amounts, charges that are due to Xirify or the same shall be adjusted by Xirify against online paid orders. </Text>   
               
            </View>
            <View style={styles.footerPage}>
            <Text style={{fontSize: 10,color:'#C4C4C4',textAlign:"right",marginRight:'40px'}}> Page 3</Text>   
         </View>
         <View style={styles.footer}>
            <Image style={ {height :"11.12px",width :"11.07px",marginLeft:"30px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391129/Agreement%20Icon/Group_33613_2x_pjssjd.png"></Image>
            <Text style={{ color: 'black',fontSize: 10}}> +91 8007002856 </Text>
            <Image style={{height :"11.12px",width :"11.07px",marginLeft:"252px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391160/Agreement%20Icon/Group_33612_2x_bpl0ev.png"></Image>
            <Text style={{ color: 'black',fontSize: 10 ,marginLeft:"5px"}}> info@xirify.com </Text>
            <Image style={styles.logoFooter} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391147/Agreement%20Icon/Path_143068_2x_dsajn1.png"></Image>
            <Text style={{ color: 'black',fontSize: 10,marginLeft :"5px"}}> www.xirify.com </Text>
          </View>
           <View style={styles.footerTwo}>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"30px"}}>Confidential Documnet</Text>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"260px"}}>Copyright © 2023 Xirify Systems Private Limited</Text>
           </View>
        </Page>
        <Page size="A4" style={styles.page}>
        <Image 
         style={{
              position: 'absolute',
               width: '430px',
               height: '481px',
               top: "181px",
               left: "83px",
               opacity: 0.8, 
               justifyContent: 'flex-end',
               border:0
            }}
             src="https://res.cloudinary.com/xirifydev/image/upload/v1691484016/Agreement%20Icon/Group_33611_lvgx9j.png"/>  
        <View style={styles.header}>
        <Text style={{marginTop: "7px",
        // marginLeft: "5px",
        width: "300px",
        fontFamily:'Helvetica',     
        textAlign: "left",
        fontSize:'16px',      
        color:'#76747F',
        opacity: 1}}>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES
         </Text>
         <Image style={styles.logo} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391183/Agreement%20Icon/Group_32337_2x_kkbvww.png"></Image>       
        </View>
            <View style={styles.section}>
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"10px",marginRight:"30px" ,lineHeight:1.9}} > Account Take Down:   </Text>  
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"30px",marginRight:"30px" ,lineHeight:1.9}} >
                 1. If the Account of the Merchant is found to be inactive for a period of {ATDN}, same will be notified to the Merchant. If the merchant fails to restart use of the account within {ATDR} of the notification, the Account of the Merchant will be taken down from the Application and the Agreement between the Company and the Merchant will stand dissolved. 
    
            </Text>  
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"30px",marginRight:"30px" ,lineHeight:1.9}} >    2. If the Merchant is found to be non-responsive on orders of the users, the same procedure as that mentioned in above subclause will be followed with deletion of merchant account being the final action.  </Text>  
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"30px",marginRight:"30px" ,lineHeight:1.9}} >    3. All the rights for the Merchant’s account take down at any level are reserved with the Xirify.  </Text>  

            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"10px",marginRight:"30px" ,lineHeight:1.9}} >Declaration:</Text>   
            <Text style={{ color: 'black', fontSize: 10,marginTop:"15px",marginLeft:"30px",marginRight:"30px" ,lineHeight:1.9,justifyContent:'space-around'}} >I/We affirm, confirm, and undertake that I/we have read and understood the terms for usage of online ordering services offered by Xirify Systems Pvt.Ltd. (“Xirify”) which are displayed on Xirify contract in Xirify for business app maintained by Xirify. Further, I/we accept and agree with the terms for usage of online ordering services of Xirify and all my/our rights, obligations and liabilities would be governed by the said terms by my/our act of accepting customer orders received through Xirify online ordering services. I/we thereby agree to be subject to and comply with all the provisions of the terms displayed on https://www.xirify.com/terms-policy, as may be amended from time to time, which are incorporated by reference herein and deemed to be part of this form to the same extent as if such provisions had been set forth in full herein. Hard copy of the terms is also available upon request. The terms and asset handover form should be read along with this form and shall be binding on the Merchant Partner. The terms not defined herein shall have meaning to them as ascribed in the terms. Except as expressly set- out herein, the parties hereby agree that this form supersedes and replaces any and all previous agreements/forms between the parties for the provision of Online Ordering Services.</Text>
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'10px' }} > Prohibited Services </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} > 1) Alcohol or Alcoholic goods and services which includes pornography and other sexually suggestive materials (including literature, imagery and other media); escort or prostitution services.	 </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >2) Alcohol which includes alcohol or alcoholic beverages such as beer, liquor, wine, or champagne. </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >3) Copyright unlocking devices which includes Mod chips or other devices designed to circumvent copyright protection. </Text> 
             
            <View style={styles.footerPage}>
            <Text style={{fontSize: 10,color:'#C4C4C4',textAlign:"right",marginRight:'40px'}}> Page 4</Text>   
         </View>
         <View style={styles.footer}>
            <Image style={ {height :"11.12px",width :"11.07px",marginLeft:"30px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391129/Agreement%20Icon/Group_33613_2x_pjssjd.png"></Image>
            <Text style={{ color: 'black',fontSize: 10}}> +91 8007002856 </Text>
            <Image style={{height :"11.12px",width :"11.07px",marginLeft:"252px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391160/Agreement%20Icon/Group_33612_2x_bpl0ev.png"></Image>
            <Text style={{ color: 'black',fontSize: 10 ,marginLeft:"5px"}}> info@xirify.com </Text>
            <Image style={styles.logoFooter} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391147/Agreement%20Icon/Path_143068_2x_dsajn1.png"></Image>
            <Text style={{ color: 'black',fontSize: 10,marginLeft :"5px"}}> www.xirify.com </Text>
          </View>
           <View style={styles.footerTwo}>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"30px"}}>Confidential Documnet</Text>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"260px"}}>Copyright © 2023 Xirify Systems Private Limited</Text>
           </View>
            </View>
        </Page>
        <Page size="A4" style={styles.page}>
        <Image 
         style={{
              position: 'absolute',
               width: '430px',
               height: '481px',
               top: "181px",
               left: "83px",
               opacity: 0.8, 
               justifyContent: 'flex-end',
               border:0
            }}
             src="https://res.cloudinary.com/xirifydev/image/upload/v1691484016/Agreement%20Icon/Group_33611_lvgx9j.png"/>  
        <View style={styles.header}>
        <Text style={{marginTop: "7px",
        // marginLeft: "5px",
        width: "300px",
        fontFamily:'Helvetica',     
        textAlign: "left",
        fontSize:'16px',      
        color:'#76747F',
        opacity: 1}}>MERCHANT PARTNER AGREEMENT FOR ONLINE ORDERING SERVICES
         </Text>
         <Image style={styles.logo} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391183/Agreement%20Icon/Group_32337_2x_kkbvww.png"></Image>       
        </View>
        <View style={styles.section}>
        <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >4) Illegal goods which includes materials, products, or information promoting illegal goods or enabling illegal acts.</Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >5) Miracle cures which includes unsubstantiated cures, remedies or other items marketed as quick health fixes. </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >6) Offensive goods or images which includes literature, products or other materials that: a) Defame or slander any person or groups of people based on race, ethnicity, national origin, religion, sex, or other factors b) Encourage or incite violent acts c) Promote intolerance or hatred.</Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >7) Any Goods or Services prohibited by Gov for Online Selling. 	</Text>
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' ,marginRight:"30px" ,lineHeight:1.9}} >8) Copyrighted media which includes unauthorized copies of books, music, movies, images and other licensed or protected materials. </Text>    
        <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' }} >Chargeback/Disputes/Fradulent Transaction and Refund</Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px' }} >Refund and Recourse:  </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'30px',marginRight:"30px" }} >Events when the price of Customer Order is to be credited back in the Customer Bank Account: </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'55px',marginRight:"30px" ,lineHeight:1.9}} >i) Merchant for whatsoever reason expresses inability to deliver and/or perform the Customer Order fully or partially after capturing the transaction and before the Delivery but post settlement. </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'55px',marginRight:"30px" ,lineHeight:1.9}} >ii) Merchant accepts the Customer claims/Disputes for crediting his account back post Delivery and settlement. Merchant must follow as below:  </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"5px",marginLeft:'55px',marginRight:"30px" ,lineHeight:1.9}} >a. Merchant shall not process a Refund transaction and/or make a cash refund directly to Customer.  </Text> 
            <Text style={{ color: 'black', fontSize: 10,marginTop:"10px",marginLeft:'55px',marginRight:"30px" ,lineHeight:1.9}} >b. In situations where any dispute or refund request is not mutually agreed between the Merchant and the Shopper. Xirify decision will be final.</Text> 
           
        </View>
        <View style={styles.footerPage}>
            <Text style={{fontSize: 10,color:'#C4C4C4',textAlign:"right",marginRight:'40px'}}> Page 5</Text>   
         </View>
         <View style={styles.footer}>
            <Image style={ {height :"11.12px",width :"11.07px",marginLeft:"30px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391129/Agreement%20Icon/Group_33613_2x_pjssjd.png"></Image>
            <Text style={{ color: 'black',fontSize: 10}}> +91 8007002856 </Text>
            <Image style={{height :"11.12px",width :"11.07px",marginLeft:"252px"}} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391160/Agreement%20Icon/Group_33612_2x_bpl0ev.png"></Image>
            <Text style={{ color: 'black',fontSize: 10 ,marginLeft:"5px"}}> info@xirify.com </Text>
            <Image style={styles.logoFooter} src="https://res.cloudinary.com/xirifydev/image/upload/v1691391147/Agreement%20Icon/Path_143068_2x_dsajn1.png"></Image>
            <Text style={{ color: 'black',fontSize: 10,marginLeft :"5px"}}> www.xirify.com </Text>
          </View>
           <View style={styles.footerTwo}>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"30px"}}>Confidential Documnet</Text>
           <Text style={{ color: 'black',fontSize: 8,marginLeft:"260px"}}>Copyright © 2023 Xirify Systems Private Limited</Text>
           </View>
           
        </Page>

    </Document>
   
)
        }

export default BusinessAgreementDocument;