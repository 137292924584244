import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { forgotPassword, loadForgot,fetchBrandName } from "../../actions/authActions";
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
    card: {
        minWidth: 275,
    },
    message: {
        fontSize: 16,
        color: 'limegreen',
    },
});


class Forgot extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            email: ""
        };
    }

    componentDidMount() {
        this.props.loadForgot();
        this.props.fetchBrandName();
    }

    onChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = event => {
        event.preventDefault();
     
        const forgotPassData = {
            userName: this.state.username,
            email: this.state.email
        };

        this.props.forgotPassword(forgotPassData);
    };

    clickLoginPage = e => {
        e.preventDefault();
        this.props.history.push("/");
    }
    
    render() {
        const { classes } = this.props;
        console.log("this.props==>",this.props);
        let webLogo = this.props?.dashboard?.data && this.props?.dashboard?.data.length>0 && this.props?.dashboard?.data[0]?.webLogo ? this.props.dashboard.data[0].webLogo : "../../images/xirify_logo.png";

        let middleDiv;

        if (this.props.auth.passwordReset === null) {
            middleDiv = <form className={classes.form} noValidate onSubmit={this.onSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="User Name"
                                name="username"
                                autoComplete="User Name"
                                autoFocus
                                value={this.state.username}
                                onChange={this.onChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="Email"
                                value={this.state.email}
                                onChange={this.onChange}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}>
                                Reset
                            </Button>
                        </form>
        } else if (this.props.auth.passwordReset === true) {
            middleDiv =  <Card className={classes.card} variant="outlined">
                            <CardContent>
                            <Typography className={classes.message} gutterBottom>
                            An Email with your new password is sent to you. Please use the new password to Login.
                            </Typography>
                            </CardContent>
                         </Card>
        } else {
            let errorMes = ' Error: ' +  this.props.auth.passResetErr;
            middleDiv = <Card className={classes.card} variant="outlined">
                            <CardContent>
                            <Typography style={{  fontSize: '16px' }} color="error" gutterBottom>
                                    {errorMes}
                            </Typography>
                            </CardContent>
                        </Card>
        }

        return (
            <div>
                <Container maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <img className={classes.avatar} alt="XIRIFY" src={webLogo}></img>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon fontSize='large'/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Forgot your password?
                        </Typography>
                        {middleDiv}
                        <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body1" onClick={this.clickLoginPage}>
                                        Back to login
                                    </Link>
                                </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        );
    }

}

Forgot.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    brand:state.auth.brand
});

export default connect(mapStateToProps, { forgotPassword, loadForgot ,fetchBrandName})(withStyles(styles)(Forgot));