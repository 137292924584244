import React, { Component } from "react";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import { getBusinessListDisabled, searchDisabledBusiness,getUsersRecords,getRegionsRecords, updateBusiness, requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,addDisabledBusinessTag } from "../../../actions/businessActions";
import { changeHeaderName, showApprovedBusinessDetails } from "../../../actions/dashboardActions";
import { getCategoriesByType } from "../../../actions/categoriesActions";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import DateRangePicker from "react-bootstrap-daterangepicker";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Switch from '@material-ui/core/Switch';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { getBusinessTagList} from "../../../actions/businessTagAction";
import {fetchBrandName} from "../../../actions/authActions";


const styles = theme => ({
  content: {
    // width: '100%',
    // height: '100%',

    flexGrow: 1,
		height: '100%',
		overflow: 'auto',
  },
  backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
	},
  appBarSpacer: theme.mixins.toolbar,
  container: {
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  list: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    minWidth: 650,
  },
  searchPaper: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '25px',
  },
  clearButton: {
    marginLeft: '25px'
  },
  tableRow: {
    cursor: 'pointer'
  },
  tableCellSmall: {
    fontSize: "13px",
    padding: "6px 0px 6px 10px"
  },
  box: {
  width: "50%",
  display: "inherit",
  alignItems: "center",
  backgroundColor: "#fafafa",
  borderRadius: "8px",
  justifyContent: 'space-around',
  boxShadow: "0px 1px 1px -1px"
},
button: {
  // width: "110px",
  borderRadius:"30px",
  fontSize:"12px",
  marginRight:'10px',
  height:"30px",
  marginLeft:'5px',
  marginTop:'5px'
},

 businessTagsContainer:{
  display:'flex',
  width:'max-content',
  maxWidth:'380px',
  flexWrap:'wrap'
 },
 chip: {
  margin: theme.spacing(0.5),
  borderRadius: '20px',
  backgroundColor: '#F5F5F5'
},
modal: {
  display: 'flex',
   alignItems: 'center',
   justifyContent: 'center',
   paddingLeft:'400px',
   paddingRight:'400px',
   position:'fixed',
   
},
headerBar: {
color: "white",
backgroundColor: theme.palette.primary.main,
},
headGridTwoButton: {
float: "right",
marginTop: "2px",
},
form: {
margin: "5% 10%",
backgroundColor: theme.palette.background.paper,
},  
dialogcontent: {
  margin: "10px 0px",
  overflow:'visible'

},
footer: {
  height: "5%",
  margin: "10px 0px 10px 0px",
  display: "flex",
  flexDirection:"row",
  alignItems: "center",
  justifyContent: "space-around",
},
});
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,

};

function uD(regions){
  var myVar4 = regions.join(',');

  let payload ={
    regionList:myVar4,
    businessStatus:"DISABLED"
  }
  return payload
  // this.props.getUsersRecords(payload)
}

function usersData(regions,users,webRole){
  // console.log("....regions",regions);
  // console.log("....users",users);
  // console.log("....webrole",webRole);
  let selectedUsers = [];
let selectedAccountM=[];
if(webRole.role === "regionalHead" || webRole.role === "admin"){
  regions.length >0 && regions.forEach((r)=>{
    users.length>0 &&  users.forEach((u)=>{
      u.businessDocId.length>0 && u.businessDocId !==null && u.businessDocIdregions.forEach((busDocId)=>{
        if(busDocId.regionBelongsTo === r){
          selectedUsers.push(u)
        }
      })
  
      })
    })
}else if(webRole.role === "areaManager"){
  // console.log("inside areamanager",users)
  users.length>0 &&  users.forEach((u)=>{
   if( u.businessDocId.length>0 && u.businessDocId !=null){
    selectedUsers.push(u)
   }
  })

}

  selectedAccountM = Array.from(new Set(selectedUsers));
  // console.log("selectedAccountM...",selectedAccountM);
  return selectedAccountM
}
   

class DisabledBusiness extends Component {

  constructor() {
    super();
    this.state = JSON.parse(window.localStorage.getItem('disabledBusinessDetails')) || {
      skip: 0,
      limit: 10,
      pageNumber: 0,
      value: '',
      clearSearch: false,
      businessFilter:"",
      ownerNameFilter:"",
      contactNumberFilter:"",
      categoryFilter:[],
      contractEndDateFilter:"",
      FilterEndDate:"",
      FilterStartDate:"",
      FilterBusinessAgreementEndDate:"",
      FilterBusinessAgreementStartDate:"",
      timeDateRange:"",
      businessAgreementTimeDateRange:"",
      xirifyAssuredFilter:"",
      xirifyReferralFilter:"",
      businessStatusFilter:"",
      deliveryPartnerAvailabilityFilter:"",
      deliveryPartnerSelected:"",
      isCashfreeEnabledFilter : "",
      isPGEnabledFilter : "",
      ranges: { },
      regionFilter:[],
      accountManagerFilter:"",
      accountManagerFilterError:false,
      areaRoleCheck:false,
      typeOrderFilter:"",
      isBusinessTagModalOpen:false,
      selectedBusinessId:"",
      selectedBusinessTags:[],
      businessTags:[],
      filteredBusinessTags:[],
      allowCancelOrdersFilter: ""
    };
  }

  // async componentDidMount() {
 async  componentDidMount() {  
    // this.props.getBusinessListDisabled(this.state.skip, this.state.limit);
    this.props.changeHeaderName("Disabled Business");
    let areaRole = this.props.user.role
    let payload ={
      businessStatus:"DISABLED"
    }
    // console.log(".....areaRole",areaRole);
    if(areaRole === "areaManager"){
      this.setState({areaRoleCheck:true})
      await this.props.getUsersRecords(payload)
    }else {
      await this.props.getRegionsRecords();
      this.setState({areaRoleCheck:false})
    }

    // await this.fetchDisabledBusinessRecords();
    this.fetchDisabledBusinessRecords();
    this.props.getCategoriesByType("category");
    this.fetchBusinessTags();
    // this.props.getRegionsUsersRecords();
    await this.props.fetchBrandName();
    console.log("this.props component ==>",this.props);
    let brandName =this.props && this.props.brand && this.props.brand.brandName ?this.props.brand.brandName :"Xirify";
    console.log("brandName-=--=-in componentDidMount=-",brandName)
    // this.setState({brandName:brandName});
    this.setState({
      brandName: brandName,
      headers: [
        { key: 'businessName', label: 'Business Name' },        
        { key: 'businessId', label: 'Business Id' },
        { key: 'ownerFirstName', label: 'Owner First Name' },
        { key: 'ownerLastName', label: 'Owner Last Name' },
        { key: 'contactNumber', label: 'Contact Number' },
        { key: 'category', label: 'Category' },
        { key: 'activeContract.endDate', label: 'Contract End Date' },
        { key: 'pendingAmount', label: 'Contract Pending Amount' },
        { key: 'businessAgreementEndDate', label: 'Contract End Date (New)' },
        { key: 'status', label: 'Business Status' },
        { key: 'isPreOrder', label: 'Pre-Order Status' }, 
        {key:'isSendQuote', label:'Send Quote'},
        { key: 'resumeBusinessTime', label: 'Business Resume Time' }, 
        { key: 'xirifyAssured', label: `${brandName} Assured` },
        { key: 'xirifyReferral', label: `${brandName} Referral` },
        { key: 'deliveryPartnerAvailability', label: 'Delivery Partner Available' },
        { key: 'deliveryPartnerSelected', label: 'Delivery Partner Selected' },
        { key: 'address[1]', label: 'Latitude' }, 
        { key: 'address[0]', label: 'Longitude' },         
        { key: 'isExotel', label: 'Exotel Status'},
        { key: 'paymentFeature', label: 'Payment Features' },
        { key: 'isPetpoojaEnabled', label: 'Petpooja Status' },
        { key: 'isCashfreeEnabled', label: 'Cashfree Split Enabled' },
        { key: 'isPackagingChargesEnabled', label: 'Business Packaging Charges Status' },
        { key: 'businessPackagingCharges', label: 'Business Packaging Charges' },
        { key: 'isPGEnabled', label: 'Online Payment Gateway' },
        { key: 'isSerivicePackagingChargesAvailable', label: 'Item Packaging Charges' },
        {key : 'overallRating', label : 'Rating'},
        { key: 'isTypeOrderEnabled', label: 'Type-Order Status' },
        { key: 'allowCancelOrders', label: 'Allow Cancel Orders' },
        {key : 'reviews' , label : 'Reviews'},
        {key: 'uniqueReviewsCount' , label : 'Unique Reviews'},         
        {key: 'area' , label : 'Area'} , 
        {key : 'region' , label : 'Region'},
        {key : 'accountManagerDetails' , label : 'Account Manager'},
        { key: 'sortOrder', label: 'Sort Order' }, 
        { key: 'BusinessTags', label: 'Business Tags' },
      ] ,        

     
    });
    window.addEventListener("beforeunload", () => localStorage.removeItem('disabledBusinessDetails'));
  }

  handleListItemClick = (busObj) => {
    this.props.showApprovedBusinessDetails(busObj);
    this.props.history.push("/DisabledBusinessDetails");
  }

  handleChangePage = async (event, newPage) => {
      var skip;
      await this.setState({ pageNumber: newPage });
      skip = this.state.limit * newPage;
      this.setState({ skip: skip });

      // this.props.getBusinessListDisabled(skip, this.state.limit);
      await this.fetchDisabledBusinessRecords();
  };

  handleChangeRowsPerPage = async (event) => {

    this.setState({ pageNumber: 0 });
    this.setState({ limit: event.target.value });
    this.setState({ skip: 0 });

    this.props.getBusinessListDisabled(0, event.target.value);
    // await this.fetchDisabledBusinessRecords();
  };

 

fetchDisabledBusinessFilter =async ()=>{
  if(this.state.regionFilter.length !==0){
    if(!this.state.accountManagerFilter){
      this.setState({accountManagerFilterError :true});
    
    }
 }
  
    this.props.requestApprovedBusinessRecords();
    var myVar4 = this.state.regionFilter.join(', ');
    let filteredBusinessTagsVar = this.state.filteredBusinessTags.join(', ');
    let payload ={
      businessName:this.state.businessFilter.trim(),
      ownerName:this.state.ownerNameFilter.trim(),
      mobileNumber:this.state.contactNumberFilter.trim(),
      category:this.state.categoryFilter,
      region:myVar4,
      contractFilterEndDate :this.state.FilterEndDate,
      contractFilterStartDate : this.state.FilterStartDate,
      businessAgreementFilterEndDate :this.state.FilterBusinessAgreementEndDate,
      businessAgreementFilterStartDate : this.state.FilterBusinessAgreementStartDate,
      xirifyAssured:this.state.xirifyAssuredFilter,
      xirifyReferral:this.state.xirifyReferralFilter,
      status: this.state.businessStatusFilter,
      isDeliveryPartnerAllowed:this.state.deliveryPartnerAvailabilityFilter,
      isDeliveryByPartner:this.state.deliveryPartnerSelected,
      isPGEnabled : this.state.isPGEnabledFilter,
      isCashfreeEnabled : this.state.isCashfreeEnabledFilter,
      skip : 0,
      limit: 10,
      businessStatus:"DISABLED",
      regionFilter:[],
      accountManagerFilter:this.state.accountManagerFilter,
      typeOrderFilter :this.state.typeOrderFilter,
      allowCancelOrdersFilter :this.state.allowCancelOrdersFilter,
      filteredBusinessTags:filteredBusinessTagsVar
     
    }
   await this.props.searchDisabledBusiness(payload);
   this.setState({limit:10,pageNumber:0});
   this.props.requestApprovedBusinessRecordsFalse();
   localStorage.setItem("disabledBusinessDetails", JSON.stringify(this.state));
  }

  fetchBusinessTags=async()=>{
    const response=await getBusinessTagList();
    if(response){
      this.setState({businessTags:response.data})
    }else{
      this.setState({businessTags:[]})
    }
    
}

  handleFilterChange = async (event) => {
    if(event.target.name === "accountManagerFilter"){
      let accountString = event.target.value
      this.setState({accountManagerFilterError :false,
      accountManagerFilter:accountString})
    }else if(event.target.name === "regionFilter"){
       await this.setState({
        regionFilter:event.target.value,
        accountManagerFilter:""
      })
      if(this.state.regionFilter.length >0){
        let user = uD(this.state.regionFilter)
      await this.props.getUsersRecords(user)
      }
    }
    else {
      this.setState({ [event.target.name]: event.target.value, invalidTimeFlag: false });
    }
}
  

  resetFilter =async ()=>{
    this.props.requestApprovedBusinessRecords();
    this.setState({
      businessFilter:"",
      ownerNameFilter:"",
      contactNumberFilter:"",
      categoryFilter:[],
      contractEndDateFilter:"",
      FilterEndDate:"",
      FilterStartDate:"",
      FilterBusinessAgreementEndDate:"",
      FilterBusinessAgreementStartDate:"",
      timeDateRange:"",
      businessAgreementTimeDateRange:"",
      xirifyAssuredFilter:"",
      xirifyReferralFilter:"",
      businessStatusFilter:"",
      deliveryPartnerAvailabilityFilter:"",
      deliveryPartnerSelected:"",
      isCashfreeEnabledFilter : "",
      isPGEnabledFilter :"",
      limit:10,
      pageNumber:0,
      regionFilter:[],
      accountManagerFilter:"",
      accountManagerFilterError:false,
      typeOrderFilter:"",
      allowCancelOrdersFilter: "",
      filteredBusinessTags:[]
    } , () => {
      this.fetchDisabledBusinessRecords();
    }); 
   let payload={ 
    skip : this.state.limit * this.state.pageNumber,
    limit: this.state.limit,
    businessStatus:"DISABLED"
  }
  // await this.props.getBusinessListDisabled(payload);
  await this.props.searchDisabledBusiness(payload);
  this.props.requestApprovedBusinessRecordsFalse();
}

fetchDisabledBusinessRecords=async()=>{
    this.props.requestApprovedBusinessRecords();
    let payload ={
      businessName:this.state.businessFilter.trim(),
      ownerName:this.state.ownerNameFilter.trim(),
      mobileNumber:this.state.contactNumberFilter.trim(),
      category:this.state.categoryFilter,
      contractFilterEndDate :this.state.FilterEndDate,
      contractFilterStartDate : this.state.FilterStartDate,
      xirifyAssured:this.state.xirifyAssuredFilter,
      xirifyReferral:this.state.xirifyReferralFilter,
      status: this.state.businessStatusFilter,
      isDeliveryPartnerAllowed:this.state.deliveryPartnerAvailabilityFilter,
      isDeliveryByPartner:this.state.deliveryPartnerSelected,
      isCashfreeEnabled : this.state.isCashfreeEnabledFilter,
      isPGEnabled : this.state.isPGEnabledFilter,
      skip : this.state.limit * this.state.pageNumber,
      limit: this.state.limit,
      businessStatus:"DISABLED",
      accountManagerFilter:this.state.accountManagerFilter,
      typeOrderFilter:this.state.typeOrderFilter,
      allowCancelOrdersFilter:this.state.allowCancelOrdersFilter,
      filteredBusinessTags:this.state.filteredBusinessTags
    }
  // await this.props.getBusinessListDisabled(payload);
  await this.props.searchDisabledBusiness(payload);
  this.props.requestApprovedBusinessRecordsFalse();
  localStorage.setItem("disabledBusinessDetails", JSON.stringify(this.state));
  }

handleDateRangeChange = (event, picker) => {
    let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
    this.setState({
        FilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
        FilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
    });
    let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
    this.setState({ timeDateRange: chosenLabel });
};
handleBusinessAgreementDateRangeChange = (event, picker) => {
  
    let reportTimeRangeBA = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
    this.setState({
        FilterBusinessAgreementEndDate:picker.endDate.format(),
        FilterBusinessAgreementStartDate: picker.startDate.format()       
    });
    let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRangeBA;
    this.setState({ businessAgreementTimeDateRange: chosenLabel });
}

handleXirifyAssuredSwitchChange = async (event, data) => {
  let payload = {
    businessId: data._id,
    data: {
      xirifyAssured: event.target.checked
    }
  }
  await this.props.updateBusiness(payload);
  // let skip = this.state.limit * this.state.pageNumber;
  await this.fetchDisabledBusinessRecords();
  // this.props.getBusinessListApproved(skip, this.state.limit);
};

handleXirifyReferralSwitchChange = async (event, data) => {
  let payload = {
    businessId: data._id,
    data: {
      xirifyReferral: event.target.checked
    }
  }
  await this.props.updateBusiness(payload);
  await this.fetchDisabledBusinessRecords();
};

handleClickAddTag=(id,selectedBusinessTags)=>{
  this.setState({ isBusinessTagModalOpen:true});
  this.setState({ selectedBusinessId:id});
  if(selectedBusinessTags){
    const selectedBusinessTagsNames=selectedBusinessTags.map((eachSelectedBusinessTag)=>eachSelectedBusinessTag.name);
    this.setState({ selectedBusinessTags:selectedBusinessTagsNames});
  }
 
  
}

handleBusinessTagModalClose=()=>{
  this.setState({ isBusinessTagModalOpen:false});
      this.setState({ selectedBusinessTags:[]});

}
handleSelectedTags=(e)=>{
  this.setState({ selectedBusinessTags:e.target.value});
}
handleAddTagSubmit=async (id)=>{
  let finalSelectedBusinessTag=this.state.businessTags.filter((eachBusinessTag)=>this.state.selectedBusinessTags.includes(eachBusinessTag.name));
  if(finalSelectedBusinessTag){
    let finalSelectedBusinessTagIds=finalSelectedBusinessTag.map((eachfinalselectedBusinessTag)=>eachfinalselectedBusinessTag._id);
    this.props.addDisabledBusinessTag(id,finalSelectedBusinessTagIds)
    this.setState({ isBusinessTagModalOpen:false});
  }
  
}
handleDeleteBusinessTag=(businessId,id,businessTags)=>{
  let finalSelectedBusinessTag=businessTags.filter((eachBusinessTag)=>eachBusinessTag._id!==id);
          let finalSelectedBusinessTagIds=finalSelectedBusinessTag.map((eachfinalselectedBusinessTags)=>eachfinalselectedBusinessTags._id);
      this.props.addDisabledBusinessTag(businessId,finalSelectedBusinessTagIds)  
}


// handleUsersData = async (user) => {
//   await this.props.getUsersRecords(uD(this.state.regionFilter))
 
//  }
  render() {
    const { classes } = this.props;
    let dataRegion =[];
    dataRegion = this.props.business.regionsData;
    // console.log("this.props in disabled business table========",this.props);
    let brandName =this.props && this.props.brand && this.props.brand.brandName ?this.props.brand.brandName :"Xirify";
    console.log("brandName-=--inrender=-=-",brandName);
    let usersDataforAM = usersData(this.state.regionFilter,this.props.business.usersData,this.props.user)

    return (
      <div className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container className={classes.container}>
          <Container className={classes.searchPaper}>
          <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Search Business</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                <Grid container spacing={1}>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Business Id / Name
                    </Typography>
                    <TextField fullWidth margin="dense" name="businessFilter" style={{ width: "225px" }} value={this.state.businessFilter} variant="outlined" onChange={this.handleFilterChange}/>
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Owner Name
                    </Typography>
                    <TextField fullWidth margin="dense" name="ownerNameFilter" style={{ width: "225px" }} value={this.state.ownerNameFilter} variant="outlined" onChange={this.handleFilterChange}/>
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Contact Number
                    </Typography>
                    <TextField fullWidth margin="dense" name="contactNumberFilter" style={{ width: "225px" }}value={this.state.contactNumberFilter} variant="outlined" onChange={this.handleFilterChange} />
                  </Grid>
                  <Grid item xs={3} >
                  <Typography variant="subtitle2" gutterBottom>
                      Category
                    </Typography>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                        <InputLabel id="weeklyDays">Select Category</InputLabel>
                            <Select
                                labelId="weeklyDays"
                                multiple
                                value={this.state.categoryFilter}
                                name="categoryFilter"
                                onChange={this.handleFilterChange}
                                input={<Input />}
                                renderValue={(selected) => selected.join(',')}
                                MenuProps={MenuProps}
                            >                                      
                              {this.props.categories.map((category) => (
                                <MenuItem key={category._id} value={category.name} style={{ maxWidth: "100%", overflow:"hidden", wordWrap:"normal", whiteSpace:"normal"  }}>
                                <Checkbox checked={this.state.categoryFilter.indexOf(category.name) > -1} />
                                    <ListItemText primary={category.name} />
                                </MenuItem>
                                ))}
                              </Select>
                    </FormControl> 
                  </Grid>
                  <Grid item xs={3}  >
                  <Typography variant="subtitle2" gutterBottom>
                      Contract End Date
                    </Typography>
                    <DateRangePicker
                        ranges={{
                          'Today': [moment(), moment()],
                          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                          'This Month': [moment().startOf('month'), moment().endOf('month')],
                          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                          
                        }}
                        onApply={this.handleDateRangeChange}
                        maxSpan={{ "months": 12 }}
                        maxDate={moment()}
                        showDropdowns
                    >
                      <TextField fullWidth margin="dense" name="DateFilter" value={this.state.timeDateRange}style={{ width: "225px" }} variant="outlined" />
                    </DateRangePicker>
                  </Grid>
                  <Grid item xs={3}  >
                  <Typography variant="subtitle2" gutterBottom>
                      Contract End Date (New)
                    </Typography>
                    <DateRangePicker
                                        ranges={{
                                       'Today': [moment(), moment()],
                                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]}}
                                        onApply={this.handleBusinessAgreementDateRangeChange}
                                        maxSpan={{ "months": 12 }}
                                        maxDate={moment()}
                                        showDropdowns
                                    >
                                        <TextField fullWidth margin="dense" name="DateFilter"  value={this.state.businessAgreementTimeDateRange} style={{ width: "225px" }} variant="outlined" />
                                    </DateRangePicker>
                  </Grid>
                  {
                      !this.state.areaRoleCheck ?
                    
                  <div className={classes.box}>
                  <Grid item xs={5} >
                  <Typography variant="subtitle2" gutterBottom>
                      Region
                    </Typography>
                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <InputLabel  id="Regions">Select Region</InputLabel>
                                            <Select
                                                labelId="Regions"
                                                multiple
                                                value={this.state.regionFilter}
                                                name="regionFilter"
                                                style ={{width: "225px"}}
                                                 onChange={this.handleFilterChange}
                                                 onSelect={this.handleUsersData}
// 
                                                input={<Input />}
                                                renderValue={(selected) =>
                                                  {
                                                    let nameArray=[];
                                                      selected.forEach((m)=>{
                                                        dataRegion.forEach((d)=>{
                                                        if(d._id === m){
                                                          if(nameArray.length=== 0)
                                                          nameArray.push(d.name);
                                                          else
                                                          nameArray.push( ", "+d.name)
                                                        }
                                                      })
                                                     
                                                    })
                                                      return nameArray}}
                                                MenuProps={MenuProps}
                                            >
                                                {dataRegion.map((region) => (
                                                    <MenuItem key={region._id} value={region._id} style={{ maxWidth: "100%", overflow:"hidden", 
                                                    wordWrap:"normal", whiteSpace:"normal" }}>
                                                        <Checkbox  checked={this.state.regionFilter.indexOf(region._id) > -1 } 
                                                        />
                                                        <ListItemText  primary={region.name} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            
                                        </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                    <FormControl fullWidth margin="dense" variant="outlined">
                      {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="fronpm run devm"
                        value={this.state.accountManagerFilter}
                        name="accountManagerFilter"
                        onChange={this.handleFilterChange}
                        // label="Business OPEN Time"
                        style={{ width: "225px" }}
                      >
                        {usersDataforAM.map((user) => {
                              return (
                                <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                  <Typography noWrap 
                               >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                                </MenuItem>
                              )
                        })}
                      </Select>
                      {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                      : null
                 }
                    </FormControl>
                  </Grid>

                  </div>
                  :
                  
                  <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom> Account Manager </Typography>
                  <FormControl fullWidth margin="dense" variant="outlined">
                    {/* <InputLabel id="demo-simple-select-standard-label">Business OPEN Time</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="fronpm run devm"
                      value={this.state.accountManagerFilter}
                      name="accountManagerFilter"
                      onChange={this.handleFilterChange}
                      // label="Business OPEN Time"
                      style={{ width: "225px" }}
                    >
                      {usersDataforAM.map((user) => {
                            return (
                              <MenuItem key={user} value={user.username} style={{ minHeight: "28px", height: "28px" }}>
                                <Typography noWrap 
                             >{user.firstName}{" "}{user.lastName}{"("}{user.username}{")"}</Typography>
                              </MenuItem>
                            )
                      })}
                    </Select>
                    {this.state.accountManagerFilterError ? <Typography gutterBottom style={{ color: "red" }}>{"Select Account Manager"} </Typography>
                    : null
               }
                  </FormControl>
                </Grid>
                }
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {brandName} Assured
                    </Typography>
                    <RadioGroup row aria-label="position" name="xirifyAssuredFilter" onChange={this.handleFilterChange} value={this.state.xirifyAssuredFilter} >
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      {brandName} Referral
                    </Typography>
                    <RadioGroup row aria-label="position" name="xirifyReferralFilter" onChange={this.handleFilterChange} value={this.state.xirifyReferralFilter} >
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Business Status
                    </Typography>
                    <RadioGroup row aria-label="position" name="businessStatusFilter"  onChange={this.handleFilterChange} value={this.state.businessStatusFilter} >
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Open" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Open</Typography>} size="small" />
                      <FormControlLabel value="Closed" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Closed</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Delivery Partner Availability
                    </Typography>
                    <RadioGroup row aria-label="position" name="deliveryPartnerAvailabilityFilter" onChange={this.handleFilterChange} value={this.state.deliveryPartnerAvailabilityFilter} >
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="On" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>On</Typography>} size="small" />
                      <FormControlLabel value="Off" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Off</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Delivery Partner Selected
                    </Typography>
                    <RadioGroup row aria-label="position" name="deliveryPartnerSelected" onChange={this.handleFilterChange} value={this.state.deliveryPartnerSelected}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                    Cashfree Split Enabled
                    </Typography>
                    <RadioGroup row aria-label="position" name="isCashfreeEnabledFilter" onChange={this.handleFilterChange} value={this.state.isCashfreeEnabledFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                    Online Payment Gateway
                    </Typography>
                    <RadioGroup row aria-label="position" name="isPGEnabledFilter" onChange={this.handleFilterChange} value={this.state.isPGEnabledFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Type-Order Status
                    </Typography>
                    <RadioGroup row aria-label="position" name="typeOrderFilter" onChange={this.handleFilterChange} value={this.state.typeOrderFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="subtitle2" gutterBottom>
                      Allow Cancel Orders
                    </Typography>
                    <RadioGroup row aria-label="position" name="allowCancelOrdersFilter" onChange={this.handleFilterChange} value={this.state.allowCancelOrdersFilter}>
                      <FormControlLabel value="" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Any</Typography>} size="small" />
                      <FormControlLabel value="Yes" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Yes</Typography>} size="small" />
                      <FormControlLabel value="No" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>No</Typography>} size="small" />
                    </RadioGroup>
                  </Grid>
                  {/* <Grid item xs={8}></Grid> */}
                  <Grid item xs={3}>
                  <Typography variant="subtitle2" gutterBottom>Business Tags</Typography>
                          <FormControl variant="outlined" fullWidth className={classes.formControl} >
                              <InputLabel id="demo-mutiple-checkbox-label">Select Business Tags</InputLabel>
                              <Select
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.filteredBusinessTags}
                                  name="filteredBusinessTags"
                                  onChange={this.handleFilterChange}
                                  input={<Input />}
                                  renderValue={(selected) => selected.join(', ')}
                                  MenuProps={MenuProps}
                                  >
                                  {this.state.businessTags.forEach((eachBusinessTagObj) => (
                                      <MenuItem key={eachBusinessTagObj._id} value={eachBusinessTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                          <Checkbox checked={this.state.filteredBusinessTags.indexOf(eachBusinessTagObj.name) > -1} />
                                          <ListItemText primary={eachBusinessTagObj.name} />
                                      </MenuItem>
                                  ))}
                              </Select>
                          </FormControl>
                      </Grid> 
                  <Grid item xs={9}>
                                </Grid>
                  <Grid item xs={12}>
                                </Grid>
                                <Grid item xs={8}>
                                </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          margin="normal"
                          onClick={this.resetFilter}
                        >
                          Reset
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          margin="normal"
                          color="primary"
                          onClick={this.fetchDisabledBusinessFilter}
                        >
                          Search
                        </Button>
                      </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Container>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Business Name</TableCell>
                  <TableCell>Business Id</TableCell>
                  <TableCell>Owner Name</TableCell>
                  <TableCell>Contact Number</TableCell>
                  {/* <TableCell>Category Type</TableCell> */}
                  <TableCell>Category</TableCell>
                  <TableCell>Contract Pending Amount</TableCell>
                  <TableCell>Contract End Date</TableCell>
                  <TableCell>Contract End Date (New)</TableCell>
                  <TableCell>Business Status</TableCell>
                  <TableCell>{brandName} Assured</TableCell>
                  {/* <TableCell>Xirify Promoted</TableCell> */}
                  <TableCell>{brandName} Referral</TableCell>
                  <TableCell>Delivery Partner Availability</TableCell>
                  <TableCell>Delivery Partner Selected</TableCell>
                  <TableCell>Type-Order Status</TableCell>
                  <TableCell>Account Manager</TableCell>
                  <TableCell>Tags</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.business.busDisabledList.map((busObj, index) => {
                //  console.log(".....in disabled business busObj",busObj);

                 let accManager ="";
                 let accManagerUsername = "";
                 if(busObj.userIsActive){
                if( Array.isArray(busObj.accountManagerDetails) ){ 
                 if(busObj.accountManagerDetails.length === 0) {
                   accManager= "NA";
                   accManagerUsername="" }
               }else if(busObj.accountManagerDetails && busObj.accountManagerDetails != null){ accManager = busObj.accountManagerDetails.firstName +" " + busObj.accountManagerDetails.lastName ;
                 accManagerUsername = "(" + busObj.accountManagerDetails.userName + ")" };
                
              } else{
                accManager="NA"
              }

                  let ownerName = busObj.ownerId ?
                    busObj.ownerId.firstName + " " + busObj.ownerId.lastName : 'NOT AVAILABLE';
                    // let categoryTypeName = busObj.categoryType ? busObj.categoryType.name : 'NOT AVAILABLE';
                  let categoryName = busObj.category ? busObj.category.name : 'NOT AVAILABLE';

                  let totalPaidAmount = 0;
                  let pendingAmount = 0;
                  let paymentStatus = 'NIL';
                  let activeContract =  busObj.contracts && busObj.contracts.length === 0 ? null : busObj.contracts.filter(contract => { return contract.isActive })[0];
                  if (activeContract && activeContract !== null && activeContract !== undefined) {
                    activeContract.payments.forEach(payment => {
                      totalPaidAmount += Number(payment.amount);
                    });
                    pendingAmount = Number(activeContract.agreedAmount) - Number(totalPaidAmount);
                    paymentStatus = pendingAmount > 0 ? pendingAmount : 'NIL';
                  }

                  return (
                    <TableRow className={classes.tableRow} hover key={`tableRow_${busObj.businessId}_${index}`} button="true" >
                      <TableCell className={classes.tableCellSmall} component="th" scope="row" onClick={(e) => this.handleListItemClick(busObj, e)}>
                        {busObj.businessName}
                      </TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.businessId}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{ownerName}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.primaryContactNumber}</TableCell>
                      {/* <TableCell>{categoryTypeName}</TableCell> */}
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)} >{categoryName}</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{paymentStatus}</TableCell>
                      {/* <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{moment(activeContract ? activeContract.endDate : new Date()).format('DD MMM YYYY')}</TableCell> */}
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{activeContract && activeContract.endDate ? moment(activeContract.endDate).format('DD MMM YYYY') : "NA" }</TableCell>
                      <TableCell className={classes.tableCellSmall} onClick={(e) => this.handleListItemClick(busObj, e)}>{busObj.businessagreements && busObj.businessagreements.length !== 0 && busObj.businessagreements[0].endDate ? moment(busObj.businessagreements[0].endDate).format('DD MMM YYYY') : "NA"}</TableCell>
                      <TableCell  className={classes.unclickableTableCellSmall}  align="left">
                      {this.props.user === "areaManager" ? 
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              checked={busObj.availability.status === 'ACTIVE'}
                              onChange={(e) => this.handleXirifyAvailabilitySwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.availability.status === 'ACTIVE' ? 'black' : 'red' }}
                          label={busObj.availability.status === 'ACTIVE' ? 'Open' : 'Closed'}
                        /> : busObj.availability.status === 'ACTIVE' ? 'Open' : 'Closed'
                        }
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyAssuredSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.xirifyAssured}
                              onChange={(e) => this.handleXirifyAssuredSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.xirifyAssured ? 'black' : 'red' }}
                          label={busObj.xirifyAssured ? 'Yes' : 'No'}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                        <FormControlLabel
                          control={
                            <Switch
                              size="small"
                              key={`xirifyReferralSwitch_${busObj.businessId}_${index}`}
                              checked={busObj.xirifyReferral}
                              onChange={(e) => this.handleXirifyReferralSwitchChange(e, busObj)}
                              color="primary"
                            />
                          }
                          style={{ fontSize: "10px", color: busObj.xirifyReferral ? 'black' : 'red' }}
                          label={busObj.xirifyReferral ? 'Yes' : 'No'}
                        />
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isDeliveryPartnerAllowed && busObj.isDeliveryPartnerAllowed === true ? 'Yes' : 'No'}
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isDeliveryByPartner && busObj.isDeliveryByPartner === true ? 'Yes' : 'No'}  
                      </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall}>
                      {busObj.isTypeOrderEnabled && busObj.isTypeOrderEnabled === true ? 'Yes' : 'No'}
                       </TableCell>
                      <TableCell align="left" className={classes.unclickableTableCellSmall1}> {  accManager ? accManager + " " + accManagerUsername: "NA"} </TableCell>
                      <TableCell align="left" className={classes.tableCellSmall}>
                          <Grid className={classes.businessTagsContainer}>
                          {busObj.businessTagIds && busObj.businessTagIds.map((eachSelectedBusinessTag)=>this.props.user && this.props.user?.role !== 'regionalHead'?<Chip
                                  className={classes.chip}
                                  label={eachSelectedBusinessTag.name}
                                  key={eachSelectedBusinessTag._id}
                                  variant="outlined"
                                  deleteIcon={<CancelIcon color="primary"/>}
                                  onDelete={() =>this.handleDeleteBusinessTag(busObj._id,eachSelectedBusinessTag._id,busObj.businessTagIds)}
                                />:<Chip
                                className={classes.chip}
                                label={eachSelectedBusinessTag.name}
                                key={eachSelectedBusinessTag._id}
                                variant="outlined"
                              />)}
                                {this.props.user && this.props.user?.role === 'regionalHead' && (busObj?.businessTagIds?.length===0 || busObj?.businessTagIds===undefined || busObj?.businessTagIds===null)?<Typography className={classes.tableCellSmall}>No Tags Assigned</Typography>:null}
                                {this.props.user && this.props.user.role !== 'regionalHead'?<Button
                                  variant="contained"
                                  color="primary"
                                  className={classes.button}
                                  // startIcon={<AddIcon />}
                                  onClick={()=>this.handleClickAddTag(busObj._id,busObj.businessTagIds)}
                                >
                                  <AddIcon style={{fontSize:'18px'}}/>
                                <Typography style={{fontSize:'12px',}}>ADD TAG</Typography>
                                </Button>:null}
                          </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    // component="div"
                    count={this.props.business.busDisabledListCount}
                    rowsPerPage={this.state.limit}
                    page={this.state.pageNumber}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    style={{ overflow: 'unset' }}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Container>
            <Dialog
              className={classes.modal}
              open={this.state.isBusinessTagModalOpen}
              onClose={this.handleBusinessTagModalClose}
              closeAfterTransition
              fullWidth={true}
              maxWidth={false}
              PaperProps={{
                style: {
                  width: '350px',
                  height: '240px',
                },
              }}
            >
              <DialogTitle className={classes.headerBar} id="form-dialog-title">
                <div>
                Assign Business Tag/s
                  <CloseIcon
                    className={classes.headGridTwoButton}
                    onClick={this.handleBusinessTagModalClose}
                  />
                </div>
              </DialogTitle>
              <DialogContent className={classes.dialogcontent}>
              <FormControl variant="outlined" fullWidth className={classes.formControl} >
                          <InputLabel id="mutiple-select-label">
                              Select Business Tag
                            </InputLabel>
                            <Select
                              label="Select Business Tags"
                              labelId="mutiple-select-label"
                              multiple
                              value={this.state.selectedBusinessTags}
                              onChange={this.handleSelectedTags}
                              renderValue={(selected) => selected.join(",")}
                              MenuProps={MenuProps}
                                style={ {
                                  width: '300px',
                                }}
                            
                            >
                              {this.state.businessTags?.length && this.state.businessTags?.map((eachBusinessTag, i) => (
                                <MenuItem key={eachBusinessTag.name} value={eachBusinessTag.name}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={
                                        this.state.selectedBusinessTags.indexOf(eachBusinessTag.name) >
                                        -1
                                      }
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={eachBusinessTag.name} />
                                </MenuItem>
                              ))}
                            </Select>
                </FormControl>
                </DialogContent>
                <DialogActions  >
                <Container className={classes.footer}>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      margin="normal"
                      onClick={this.handleBusinessTagModalClose}
                    >
                      cancel
                    </Button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      ///style={{ padding: "20px" }}
                      margin="normal"
                      color="primary"
                      onClick={()=>this.handleAddTagSubmit(this.state.selectedBusinessId)}
                    >
                      save
                    </Button>
                  </div>
                </Container>
                </DialogActions>
            </Dialog>

        <Backdrop className={classes.backdrop} open={this.props.business.isFetching}> <CircularProgress color="inherit" /> </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    business: state.business,
    user: state.auth.user,
    categories: state.categories.categoriesByType,
    region:state.region.regions,
    brand:state.auth.brand
  };
};

export default connect(mapStateToProps, { getBusinessListDisabled,getRegionsRecords,getUsersRecords, changeHeaderName, showApprovedBusinessDetails, searchDisabledBusiness, getCategoriesByType, updateBusiness, requestApprovedBusinessRecords, requestApprovedBusinessRecordsFalse,addDisabledBusinessTag,fetchBrandName })(withStyles(styles)(DisabledBusiness));