import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


const headers = [
    { id: 'regionCode', disablePadding: true, label: 'Region Code' },
    { id: 'name', disablePadding: false, label: 'Region Name' },
    { id: 'pincode', disablePadding: false, label: 'Pin code' },
    { id: 'regionHeads', disablePadding: false, label: 'Regional Heads' },
    { id: 'controlledAreas', disablePadding: false, label: 'Areas' },
    { id: 'businesses', disablePadding: false, label: 'Businesses' },
    { id: 'isActive', disablePadding: false, label: 'Status' },
    { id: 'refer&Earn', disablePadding: false, label: 'Refer & Earn' },
    { id: 'referralAmount', disablePadding: false, label: 'Referral Amount' },
    { id: 'referralMinOrder', disablePadding: false, label: 'Minimum Order Value' },
    { id: '', disablePadding: false, label: '' },
]

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        className={classes.tableCellSmall}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Regions ({props.totalcount})
            </Typography>
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    tableCellSmall: {
        // fontSize: "12px",
        cursor: 'pointer',
        padding: "6px 0px 6px 16px"
    },
    unclickableTableCellSmall: {
        // fontSize: "12px",
        padding: "6px 0px 6px 16px"
    }
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleSwitchChange = (event, data) => {
        let payload = {
            _id: data._id,
            isActive: event.target.checked
        }
        props.updateRegion(payload);
    };

    const handleReferAndEarnSwitchChange = (event, data) => {
        if (event.target.checked) {
            data.tempReferAndEarn = true;
            props.openEditRegionModal(data);
        } else {
            let payload = {
                _id: data._id,
                referAndEarn: event.target.checked
            }
            props.updateRegion(payload);
        }
    };

    const handleEditRegionModal = (data) => {
        props.openEditRegionModal(data);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = props.orderBy === property && props.order === 'asc';
        props.setOrder(isAsc ? -1 : 1);
        props.setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const redirectToRegionDetails = (region) => {
        props.history.push(`/regions/${region._id}`);
    }

  
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar totalcount={props.rowData.totalcount} />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={props.order}
                            orderBy={props.orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={props.rowData.totalcount}
                        />
                        <TableBody>
                            {props.rowData.data
                                .map((row, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            key={index}
                                        >
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.regionCode}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.name}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.pincode}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.regionalHeadsData && row.regionalHeadsData.length > 0 ? row.regionalHeadsData.length : 0}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.controlledAreas.length}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)}>{row.businessCount}</TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={row.isActive}
                                                            onChange={(e) => handleSwitchChange(e, row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: row.isActive ? 'black' : 'red' }}
                                                    label={row.isActive ? 'Active' : 'Inactive'}
                                                />
                                            </TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={row.referAndEarn}
                                                            onChange={(e) => handleReferAndEarnSwitchChange(e, row)}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: row.referAndEarn ? 'black' : 'red' }}
                                                    label={row.referAndEarn ? 'Active' : 'Inactive'}
                                                />
                                            </TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)} style={!row.referAndEarn ? { color: "gray" } : null}>{row.referralAmount}</TableCell>
                                            <TableCell align="left" className={classes.tableCellSmall} onClick={() => redirectToRegionDetails(row)} style={!row.referAndEarn ? { color: "gray" } : null}>{row.referralMinOrder}</TableCell>
                                            <TableCell align="left" className={classes.unclickableTableCellSmall}>
                                                <IconButton aria-label="edit" onClick={() => handleEditRegionModal(row)} style={{ height: '30px' }}>
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                          
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.rowData.totalcount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}
