import React, { Component } from "react";
import { connect } from "react-redux";
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField'
import SPConverstionLayout from './SPConversationLayout';
import Joi from "joi-browser";
import { lightBlue } from '@material-ui/core/colors';
import { fetchEnvs } from '../../actions/authActions';
import Divider from '@material-ui/core/Divider';
import {ReactComponent as SupportSVG} from '../../images/supportNoteSendIcon.svg';
import {addServiceProviderConversation, getAllServiceProviders } from '../../actions/dashboardActions';

const styles = theme => ({
    header: {
        height: '10%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: "22px",
        backgroundColor: theme.palette.primary.main,
        color:"#FFFFFF"
    },
    content: {
        height: '70%',
        margin: '10px 0px',
        overflow: 'auto',
    },
    footer: {
        width: '100%',
        height: '20%',
        marginTop: '10px',
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
        
    },
    headGridTwo: {
        float: 'right',
        marginRight: '2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
    },
    modalContainer: {
        width: '45%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '0px 0px',
    },
    tableCellSmall: {
        fontSize: "12px",
        padding: "6px 0px 6px 16px"
    },
    head: {
        fontWeight: 700,
        fontSize: "12px",
    },
    lightBlue: {
        color:theme.palette.getContrastText(lightBlue[50]),
        backgroundColor:lightBlue[50],
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    roundedIcon: {
        marginRight:'10px',
        marginLeft:'10px'
    },
});

class ConversationView extends Component {
    constructor() {
        super();
        this.state = {
            data: { text: ''
        },
            errors: {},
        };
    }

    schema = {
        text: Joi.string().required().min(1).max(255).label("Type your message"),
    };

  

    validateInput = (input) => {
        const { name, value } = input;
        const obj = {
            [name]: value.trim(),
        };
        const schema = {
            [name]: this.schema[name],
        };
        const { error } = Joi.validate(obj, schema);
        if (!error) return "";
        const errorMessage = error.details[0].message;
        return errorMessage;
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        const errorMessage = this.validateInput(e.target);
        errors[name] = errorMessage;
        data[name] = value;
        this.setState({ data, errors });
    };


    doSubmit = async () => {
        //server call
        let payload = {
         text : this.state.data.text,
         serviceProviderId : this.props.serviceProviderId,
         userType : this.props?.data?.auth?.user?.userType,
         userName : `${this.props?.data?.auth?.user?.firstName} ${this.props?.data?.auth?.user?.lastName}`
        }
        await this.props.addServiceProviderConversation(payload);
        console.log("supportconversations submit payload----->", payload)
         this.props.supportconversations.push(payload);
        console.log("supportconversations submit----->", this.props)

        // this.props.getAllServiceProviders()
        this.setState({
            data: { text: '', 
            // attachments: null
         },
            errors: {},
        });

    };

    componentDidMount() {
        this.props.fetchEnvs();
    }

    render() {

        const { classes, supportconversations} = this.props;
        console.log("supportconversations render----->", supportconversations)
        const { data, errors } = this.state;
        return (
            <Modal
                className={classes.modal}
                open={this.props.open}
                // onClose={                            
                //     this.props.onClose()}
                closeAfterTransition>
                <div style={{height:"80%",width:"80%"}}>
                <Container className={classes.modalContainer}>
                    <Grid container className={classes.header} spacing={1}>
                        <Grid item xs={8}>
                            <Typography variant="h6" style={{padding: "5px"}} gutterBottom>
                                Sales Notes ({this.props?.serviceProviderDetails?.firstName} {this.props?.serviceProviderDetails?.lastName})
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <CloseIcon className={classes.headGridTwo} onClick={() => {                                
                              this.props.onClose();
                              this.setState({
                                data: { text: ''},
                                errors: {},
                              });
                            
                              }} />
                        </Grid>
                    </Grid>

                    <Container className={classes.content}>
                        {   
                            supportconversations && supportconversations.length > 0 ?
                                <SPConverstionLayout 
                                data={supportconversations}                            
                                
                                />
                                :
                                <Typography variant="subtitle2" gutterBottom>
                                    No messages available to display.
                            </Typography>
                        }
                    </Container>
                    <Divider variant="middle" style={{ width: '100%', margin: '0px', height: '1px' }} />
                    <Container className={classes.footer}>
                        <div className='row'>
                            <Grid item xs={10}>
                                <TextField
                                    style={{ borderBottom: '2px solid #FAFAFA' , margin:"5px"}}
                                    //variant="outlined"
                                    required
                                    fullWidth
                                    id="text"
                                    label="Type your message"
                                    name="text"
                                    multiline={true}
                                    rows={1}
                                    error={ errors.text }
                                    value={data.text}
                                    onChange={this.handleChange} 
                                />
                            </Grid>
                            <Grid item xs={1} className={classes.roundedIcon}><div>
                                <label disabled={(data.text.trim()  !== '') ? null : true} onClick={(data.text.trim()  !== '') ? this.doSubmit : null} onKeyDown={(data.text.trim()  !== '') ? this.doSubmit : null}>
                                     <SupportSVG style={{height:"60px",width:"30px"}} />
                                </label></div>
                            </Grid>   
                        </div>
                    </Container>
                </Container>
                </div>
            </Modal>
        );
    }
}


const mapStateToProps = (state) => ({
    data: state, env: state.auth.env

});
const mapDispatchToProps = (dispatch) => ({
    addServiceProviderConversation: (data, serviceId, userRole, history) => dispatch(addServiceProviderConversation(data, serviceId, userRole, history)),
    getAllServiceProviders: () => dispatch(getAllServiceProviders()),
    fetchEnvs
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConversationView));