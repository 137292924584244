import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';


const headCells = [
    { id: 'businessId', disablePadding: false, label: 'Business ID' },
    { id: 'businessName:', disablePadding: false, label: 'Business Name' },
    { id: 'consumerId', disablePadding: false, label: 'Consumer ID' },
    { id: 'consumerFirstName', disablePadding: false, label: 'Consumer First Name' },
    { id: 'consumerLastName', disablePadding: false, label: 'Consumer Last Name' },
    { id: 'requestId', disablePadding: false, label: 'Request ID' },
    { id: 'deliveryPartner', disablePadding: false, label: 'Delivery Partner' },
    { id: 'partnerTaskId', disablePadding: false, label: 'Delivery Task ID' },
    { id: 'Delivery Status', disablePadding: false, label: 'Delivery Status' },
    { id: 'taskCreatedOn', disablePadding: false, label: 'Task Created On' },
    { id: 'deliveryType', disablePadding: false, label: 'Delivery Type' },
    { id: 'scheduledTime', disablePadding: false, label: 'Scheduled On' },
    { id: 'pickedUpOn', disablePadding: false, label: 'Picked Up On' },
    { id: 'deliveredOn', disablePadding: false, label: 'Delivered On' },
    { id: 'area', disablePadding: false, label: 'Area' },
    { id: 'distance', disablePadding: false, label: 'Distance (Km)' },
    { id: 'price', disablePadding: false, label: 'Price (Rs)' },
    { id: 'runnerName', disablePadding: false, label: 'Runner Name' },
    { id: 'runnerContact', disablePadding: false, label: 'Runner Contact' },
];

function EnhancedTableHead(props) {
    const { classes } = props;

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Deliveries ({props.total}) &nbsp; &nbsp; &nbsp; Total Charges : {props.totalCharges} &nbsp;
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"deliveries.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                    : <Button
                        variant="contained"
                        margin="normal"
                        style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                        onClick={props.fetchNotificationWithExcelFile}
                    >
                        Export
        </Button>
                }
            </Typography>
        </Toolbar>
    );
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    formControl: {
        minWidth: 102,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px"
    },
    selectEmpty: {
        // marginTop: theme.spacing.unit * 2,
        marginTop: theme.spacing(2),
        fontSize: "13px",
    },
    tableRow: {
        cursor: 'pointer'
    },
    deleteBtn: {
        cursor: 'pointer',
        color: 'grey',
        marginTop: "7px"
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'businessData.businessId', label: 'Business ID' },
        { key: 'businessData.businessName', label: 'Business Name' },
        { key: 'consumerData.consumerId', label: 'Consumer ID' },
        { key: 'consumerData.firstName', label: 'Consumer First Name' },
        { key: 'consumerData.lastName', label: 'Consumer Last Name' },
        { key: 'serviceRequestData.serviceRequestId', label: 'Request ID' },
        { key: 'deliveryPartner', label: 'Delivery Partner' },
        { key: 'partnerTaskId', label: 'Delivery Task Id' },
        { key: 'status', label: 'Delivery Status' },
        { key: 'partnerTaskCreatedOn', label: 'Task Created On' },
        { key: 'deliveryType', label: 'Delivery Type' },
        { key: 'updatedscheduledTime', label: 'Scheduled On' },
        { key: 'partnerTaskPickedUpOn', label: 'Picked Up On' },
        { key: 'partnerTaskDeliveredOn', label: 'Delivered On' },
        { key: 'area',label: 'Area'},
        { key: 'quote.distance', label: 'Distance (Km)' },
        { key: 'quote.estimated_price', label: 'Price (Rs)' },
        { key: 'runnerName', label: 'Runner Name' },
        { key: 'runnerPhone', label: 'Runner Contact' },
        { key: 'statusDetails.task_id',label: 'partner Task ID'},
        { key: 'TaskId/Ids',label: 'All Task Ids'}
    ];

    const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.data.totalRecords - props.page * props.rowsPerPage);
    let data =props.exportToExcel;

    data && data.forEach(element => {


        let deliveryStatus;
        if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'runner_accepted') deliveryStatus = 'Runner Accepted';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'reached_for_pickup') deliveryStatus = 'Reached for pickup';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'pickup_complete') deliveryStatus = 'Pickup Complete';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'started_for_delivery') deliveryStatus = 'Out for delivery';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'reached_for_delivery') deliveryStatus = 'Reached for Delivery';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'order_start_trip') deliveryStatus = 'Order start trip';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'order_cancel') deliveryStatus = 'Order cancel';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'return_active') deliveryStatus = 'Return active';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'return_finished') deliveryStatus = 'Return finished';
        else if(element.status !== null && element.status !== undefined && element.status.toLowerCase() === 'order_end_job') deliveryStatus = 'Delivered';
        else deliveryStatus = element.status;
        element.status = deliveryStatus;
        element.partnerTaskId = element.partnerTaskId || "NA";
        element.partnerTaskCreatedOn = element.status !== "quoted" && element.partnerTaskCreatedOn && element.partnerTaskCreatedOn !== "Invalid date" ? moment(element.partnerTaskCreatedOn).format('DD MMM YYYY hh:mm A'): "NA";
        element.partnerTaskPickedUpOn = element.partnerTaskPickedUpOn && element.partnerTaskPickedUpOn !== "Invalid date" && element.partnerTaskPickedUpOn !== "NA" ? moment(element.partnerTaskPickedUpOn).format('DD MMM YYYY hh:mm A'): "NA";
        element.partnerTaskDeliveredOn = element.partnerTaskDeliveredOn && element.partnerTaskDeliveredOn  !== "Invalid date" && element.partnerTaskDeliveredOn  !== "NA"? moment(element.partnerTaskDeliveredOn).format('DD MMM YYYY hh:mm A'): "NA";     
        element.updatedscheduledTime = element.scheduledTime && element.scheduledTime !== "Invalid date" && element.scheduledTime !== "NA" ? moment(element.scheduledTime).format('DD MMM YYYY hh:mm A'):"--"
        element.area = element?.serviceRequestData?.consumerDetails?.address?.area ||  "NA"
        element.runnerName = element?.runner?.name ||  "NA"
        element.runnerPhone = element?.runner?.phone_number ||  "NA"
       
    });
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                headers={headers} 
                total={props.data.totalRecords}
                totalCharges={props.data.totalCharges}
                data={data}
                showDownloadIcon={props.showDownloadIcon}
                fetchNotificationWithExcelFile={props.fetchNotificationWithExcelFile}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.data.totalRecords}
                        />
                        <TableBody>
                            {
                                props.data.data.map((row, index) => {
                                    const uniqueTasksMap = new Map();
                                    row.events.forEach(event => {
                                        if (!uniqueTasksMap.has(event.task_id)) {
                                            uniqueTasksMap.set(event.task_id, event);
                                        }
                                    });
                                    
                                    // Convert the map back to an array
                                    const uniqueEvents = Array.from(uniqueTasksMap.values());                                  
                                 
                                    
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                  
                                    let deliveryStatus;
                                    if(row.status !== undefined && row.status !== null) {                                      

                                        if(row.status.toLowerCase() === 'runner_accepted') deliveryStatus = 'Runner Accepted';
                                        else if(row.status.toLowerCase() === 'reached_for_pickup') deliveryStatus = 'Reached for pickup';
                                        else if(row.status.toLowerCase() === 'pickup_complete') deliveryStatus = 'Pickup Complete';
                                        else if(row.status.toLowerCase() === 'started_for_delivery') deliveryStatus = 'Out for delivery';
                                        else if(row.status.toLowerCase() === 'reached_for_delivery') deliveryStatus = 'Reached for Delivery';
                                        else if(row.status.toLowerCase() === 'order_start_trip') deliveryStatus = 'Order start trip';
                                        else if(row.status.toLowerCase() === 'order_cancel') deliveryStatus = 'Order cancel';
                                        else if(row.status.toLowerCase() === 'return_active') deliveryStatus = 'Return active';
                                        else if(row.status.toLowerCase() === 'return_finished') deliveryStatus = 'Return finished';
                                        else if(row.status.toLowerCase() === 'order_end_job') deliveryStatus = 'Delivered';
                                        else deliveryStatus = row.status;
                                    } else {
                                        deliveryStatus = 'NA';
                                    }

                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={row._id}
                                        >
                                            <TableCell align="left" >{row.businessId !== null && row.businessId !== undefined && row.businessId.businessId !== null && row.businessId.businessId !== undefined ? row.businessId.businessId : "NA"}</TableCell>
                                            <TableCell component="th" id={labelId} scope="row" >
                                                {row.businessId !== null && row.businessId !== undefined && row.businessId.businessName !== null && row.businessId.businessName !== undefined ? row.businessId.businessName : "NA"}
                                            </TableCell>
                                            <TableCell align="left" >{row.consumerId !== null && row.consumerId !== undefined && row.consumerId.consumerId !== null && row.consumerId.consumerId !== undefined ? row.consumerId.consumerId : "NA"}</TableCell>
                                            <TableCell align="left" >{row.consumerId !== null && row.consumerId !== undefined && row.consumerId.firstName !== null && row.consumerId.firstName !== undefined ? row.consumerId.firstName : "NA"}</TableCell>
                                            <TableCell align="left" >{row.consumerId !== null && row.consumerId !== undefined && row.consumerId.lastName !== null && row.consumerId.lastName !== undefined ? row.consumerId.lastName : "NA"}</TableCell>
                                            <TableCell align="left" >{row.seviceRequestId !== null && row.seviceRequestId !== undefined && row.seviceRequestId.serviceRequestId !== null && row.seviceRequestId.serviceRequestId !== undefined ? row.seviceRequestId.serviceRequestId : "NA"}</TableCell>
                                            <TableCell align="left">{row.deliveryPartner !== null && row.deliveryPartner !== undefined ? row.deliveryPartner : "NA"}</TableCell>
                                            {uniqueEvents && uniqueEvents.length > 1 ? uniqueEvents.map((event, index) => (
                                                <TableRow key={index}>
                                                    <TableCell style={{fontSize : "12px"}} align="left">
                                                        {event.task_id !== null && event.task_id !== undefined ? event.task_id : "NA"}
                                                        {/* <br />
                                                        {`(${new Date(event.event_timestamp).toLocaleString()})`} */}
                                                    </TableCell>
                                                </TableRow>
                                            )) :
                                                <TableCell align="left">{row.partnerTaskId !== null && row.partnerTaskId !== undefined ? row.partnerTaskId : "NA"}</TableCell>

                                            }
                                            {/* <TableCell align="left" >{row.status != null && row.status != undefined ?  row.status:"NA"}</TableCell> */}
                                            <TableCell align="left" >{deliveryStatus}</TableCell>
                                            <TableCell align="left" >{row.status !== "quoted" && row.partnerTaskCreatedOn ? moment(row.partnerTaskCreatedOn).format('DD MMM YYYY hh:mm A') : "--"}</TableCell>
                                            <TableCell align="left">{row.deliveryType ? "SCHEDULED" : "--"}</TableCell>


                                            <TableCell align="left">
                                                {row.scheduledTime && row.scheduledTime !== "Invalid date" && row.scheduledTime !== "NA" ? moment(row.scheduledTime).format('DD MMM YYYY hh:mm A') : "--"}
                                                {/* { row.seviceRequestId && row.seviceRequestId.rescheduledServiceDate && row.seviceRequestId.rescheduledServiceDate !== "Invalid date" && row.seviceRequestId.rescheduledServiceDate !== "NA" && row.seviceRequestId.rescheduledServiceDate !=null ? moment(row.seviceRequestId.rescheduledServiceDate).subtract(10, 'minutes').format('DD MMM YYYY hh:mm A'):"--" } */}
                                            </TableCell>
                                            <TableCell align="left">{row.partnerTaskPickedUpOn ? moment(row.partnerTaskPickedUpOn).format('DD MMM YYYY hh:mm A') : "--"}</TableCell>
                                            <TableCell align="left">{row.partnerTaskDeliveredOn ? moment(row.partnerTaskDeliveredOn).format('DD MMM YYYY hh:mm A') : "--"}</TableCell>
                                            <TableCell align="left">{row.seviceRequestId?.consumerDetails?.address?.area || "--"}</TableCell>
                                            <TableCell align="left">{row.quote.distance}</TableCell>
                                            <TableCell align="left">{row.status === "quoted" ? row.quote.estimated_price : row.createdEstimatedPrice}</TableCell>
                                            <TableCell align="left">{row.runner ? row.runner.name : "--"}</TableCell>
                                            <TableCell align="left">{row.runner ? row.runner.phone_number : "--"}</TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={props.data.totalRecords}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}