import {
    SET_FAQ_LIST,
    SET_FAQ_SUCCESS_MESSAGE,
    SET_FAQ_ERROR_MESSAGE,
    REQUEST_FAQS
} from "../actions/types";

const INITIAL_STATE = {
    faqList: [],
    sucessMsg: null,
    errorMsg: null,
    ops: "",
    isFetching: false
};


export default function faqReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case REQUEST_FAQS:
            return { ...state, isFetching: action.payload };
        case SET_FAQ_LIST:
            return {
                ...state,
                faqList: action.payload,
                isFetching : false
            };
        case SET_FAQ_SUCCESS_MESSAGE:
            return {
                ...state,
                sucessMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        case SET_FAQ_ERROR_MESSAGE:
            return {
                ...state,
                errorMsg: action.payload.msg,
                ops: action.payload.ops,
                isFetching: false
            };
        default:
            return state;
    }
}