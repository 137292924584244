import axios from "axios";
import {
    CHANGE_HEADER_NAME,
    SHOW_PENDING_BUSINESS_DETAILS,
    SHOW_APPROVED_BUSINESS_DETAILS,
    SET_XIRIFY_STATS,
    SET_CONSUMER_LIST,
    SET_CONSUMER_LIST_WEBPUSHNOTIFICATION,
    REQUEST_STATS,
    SET_RELEASES,
    SET_ADMIN_SUCCESS_MESSAGE,
    SET_ADMIN_ERROR_MESSAGE,
    SET_QR_CODE_STATUS,
    SET_SP_LIST,
    SET_SP_DETAILS,
    SET_BUSINESS_MASTER_OTP,
    GET_MASTER_OTP,
    SET_CONSUMER_MASTER_OTP,
    GET_CONSUMER_MASTER_OTP,
    SET_CONSUMER_LIST_EMPTY,
    SET_CONFIG,
    GET_PG_RECORDS,
    SET_CONFIG_ORDERBY,
    SET_SELECTED_CONSUMER,
    REQUEST_CALL_ICON_HIT_RECORDS,
    GET_ALL_CALL_ICON_HIT_RECORDS, 
    GET_ALL_OPEN_CLOSE_STATUSES,
    GET_ALL_OPEN_CLOSE_EXPORT_STATUSES,
    REQUEST_GET_ALL_OPEN_CLOSE_STATUSES,
    REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES,
    CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL,
    CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED,
    REQUEST_CRON_PERFORMANCE_RECORDS,
    GET_ALL_CRON_PERFORMANCE_RECORDS,
    CRON_JOB_PERF_DOWNLOAD_EXCEL,
    CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED,
    REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES,
    REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES,
    GET_ALL_ITEMS_AVAILABILITY_STATUSES,
    GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES,
    SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE,
    SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE,
    GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION,
    REQUEST_PAYMENT_GATEWAY_RECORDS,
    REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS,  
    CHANGE_CONSUMER_STATUS,
    SET_CONSUMER_STATUS_ERROR_MESSAGE,
    GET_BUSINESS_DASHBOARD_CRON_DATA,
    GET_COUPON_EXPIRY_CRON,
    GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE, 
    GET_DELETE_OPEN_CLOSE_RECORDS,
    GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE,   
    BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE,
    BUSINESS_DASHBOARD_CRON,
    BUSINESS_DASHBOARD_CRON_RESET,
    GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS,
    GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR,
    GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS,
    GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE,
    GET_DELETE_ITEMS_AVAILABILITY_RECORDS,
    GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE,
    GET_BUSINESSES_FOR_ADDRESS,
    ADD_DELETE_BUSINESS,
    ADD_DELETE_BUSINESS_ERROR,
    GET_CONSUMER_CART_DATA,
    ADD_CONSUMER_TAGS,
    SET_MINORDERS_FOR_ELIGIBLITY,
    ADD_SERVICEPROVIDER_CONVERSATION,
    GET_CONSUMER_VERSIONS,
    SAVE_BRAND_NAME
    
} from "./types";


// set the name in the Layout header component.
export const changeHeaderName = res => {
    return {
        type: CHANGE_HEADER_NAME,
        payload: res
    };
};

export const requestCronPerfRecords = () => {
	return {
		type: REQUEST_CRON_PERFORMANCE_RECORDS,
		payload: true
	};
};

export const requestCallIconHitRecords = () => {
	return {
		type: REQUEST_CALL_ICON_HIT_RECORDS,
		payload: true
	};
};
export const onlinePaymentRecords = () => {
	return {
		type: REQUEST_PAYMENT_GATEWAY_RECORDS,
		payload: true
	};
};
export const onlinePaymentStopLoadingRecords = () => {
	return {
		type: REQUEST_PAYMENT_GATEWAY_STOP_LOADING_RECORDS,
		payload: false
	};
};

export const requestOpenCloseBusinessList = () => {
	return {
		type: REQUEST_GET_ALL_OPEN_CLOSE_STATUSES,
		payload: true
	};
};
export const requestOpenCloseBusinessExportList = () => {
	return {
		type: REQUEST_GET_ALL_OPEN_CLOSE_EXPORT_STATUSES,
		payload: true
	};
};

export const requestItemsAvailabilityList = () => {
	return {
		type: REQUEST_GET_ALL_ITEMS_AVAILABILITY_STATUSES,
		payload: true
	};
};


export const requestItemsAvailabilityExportList = () => {
	return {
		type: REQUEST_GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES,
		payload: true
	};
};
export const requestBusinessStats = () => {
    return {
        type: REQUEST_STATS,
        payload: true
    };
};

export const callBusinessDashboardCron = () => {
    return {
        type: BUSINESS_DASHBOARD_CRON,
        payload: true
    };
};

export const callBusinessDashboardCronReset = () => {
    return {
        type: BUSINESS_DASHBOARD_CRON_RESET,
        payload: false
    };
};

export const showPendingBusinessDetails = res => {
    return {
        type: SHOW_PENDING_BUSINESS_DETAILS,
        payload: res
    };
};

export const showApprovedBusinessDetails = res => {
    return {
        type: SHOW_APPROVED_BUSINESS_DETAILS,
        payload: res
    };
};

export const clearMessage = () => async (dispatch) => {
    let data = {};
    data.msg = null;
    // data.ops = null;

    dispatch({ type: SET_CONFIG_ORDERBY, payload: data.msg });
    dispatch({ type: SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE, payload: data.msg });
    dispatch({ type: SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE, payload: data.msg });
    dispatch({ type: GET_BUSINESS_DASHBOARD_CRON_DATA, payload: data.msg });
    dispatch({type:GET_COUPON_EXPIRY_CRON, payload: data.msg})
    dispatch({type:GET_DELETE_OPEN_CLOSE_RECORDS, payload: data.msg})
    dispatch({type:GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE, payload: data.msg})
    dispatch({type:GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE, payload: data.msg})
    dispatch({type: BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE, payload: data.msg });
    dispatch({ type: GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS, payload: data });
    dispatch({ type: GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR, payload: data });
    dispatch({type:GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS, payload: data});
    dispatch({type:GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE, payload: data});
    dispatch({type:GET_DELETE_ITEMS_AVAILABILITY_RECORDS, payload: data});
    dispatch({type:GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE, payload: data})
    dispatch({type:SET_MINORDERS_FOR_ELIGIBLITY, payload: data.msg})
};

//Get Xirify stats
export const getXirifyStats = () => async dispatch => {
    const response = await axios.get("/api/xirify/stats")
    dispatch({ type: SET_XIRIFY_STATS, payload: response.data });
};

export const getAllConsumers = (payload) => async dispatch => {
    const response = await axios.get("/api/consumers", { params: payload });
    dispatch({ type: SET_CONSUMER_LIST, payload: response.data });
};
export const getAllConsumersVersions =() => async dispatch =>  {   
        const response = await axios.get("/api/consumers/getAllVersions");
        console.log("response.data in versions",response.data)
        dispatch({ type: GET_CONSUMER_VERSIONS, payload: response.data });
};
export const getAllConsumersForPushNotification = (payload) => async dispatch => {
    const response = await axios.get("/api/consumers/getAllConsumersForPushNotification", { params: payload });
    dispatch({ type: SET_CONSUMER_LIST_WEBPUSHNOTIFICATION, payload: response.data });
};
export const getOneConsumer = (payload) => async dispatch => {
    const response = await axios.get(`/api/consumers/${payload.id}`);
    dispatch({ type: SET_SELECTED_CONSUMER, payload: response.data });
};

export const updateConsumerAddress = (payload) => async dispatch => {
    // console.log("payload updateConsumerAddress --->", payload);
    const response = await axios.patch(`/api/consumers/${payload.id}/address`, { address: payload.addressArray , index: payload.index});
    if (response.status === 200) {
        return "success";
      } else {
        return "error";
      }
};

export const changeConsumerStatus = (payload,consumerId,oldStatus) => async dispatch => {
    try {
         console.log("=====inside changeConsumerStatus=====", payload);
        await axios.patch(`/api/consumers/${consumerId}`,payload);
        dispatch({ type: CHANGE_CONSUMER_STATUS, payload: { ops: "UPDATE", msg: "Consumer status updated successfully." } });
    } catch (error) {
        dispatch({ type: SET_CONSUMER_STATUS_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update consumer status." } });
    }
};
export const changeConsumerPaymentPreferace = (payload,consumerId,oldStatus) => async dispatch => {
    try {
         console.log("=====inside changeConsumerStatus=====", payload);
       await axios.patch(`/api/consumers/${consumerId}`,payload);
        dispatch({ type: CHANGE_CONSUMER_STATUS, payload: { ops: "UPDATE", msg: "Consumer Payment Preferance Updated Successfully." } });
    } catch (error) {
        dispatch({ type: SET_CONSUMER_STATUS_ERROR_MESSAGE, payload: { ops: "UPDATE", msg: "Failed to update consumer status." } });
    }
};

export const getAllReleases = () => async dispatch => {
    try {
        const response = await axios.get("/api/mobileversion/releases");
        dispatch({ type: SET_RELEASES, payload: response.data });
    } catch (e) {
        console.log("error while fetching releases : ", e);
        dispatch({ type: SET_RELEASES, payload: [] });
    }
};

export const addRelease = (payload) => async dispatch => {
    try {
        await axios.post("/api/mobileversion", payload);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: payload.platform + " version " + payload.versioncode + " for " + payload.appname + " has been released successfully." });
    } catch (e) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while releasing mobile version " + payload.versioncode + " for " + payload.platform + "." });
    }
};

export const updateRelease = (payload) => async dispatch => {
    try {
        await axios.patch(`/api/mobileversion/${payload._id}`, payload);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: payload.platform + " version " + payload.versioncode + " for " + payload.appname + " has been updated successfully." });
    } catch (e) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while updating mobile version " + payload.versioncode + " for " + payload.platform + "." });
    }
};

export const uploadQRCodeImage = (payload) => async dispatch => {
    try {
        const formData = new FormData();
        formData.append('file', payload.file);
        await axios.put(`/api/xirify/document/${payload.type}`,
            formData, {
            params: {
                filename: payload.file.name
            },
            headers: {
                'content-type': 'multipart/form-data'
            }
        });

        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "QR code image uploaded successfully." });
    } catch (err) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while uploading QR code image." });
    }
}

export const deleteQRCodeImage = (fileType) => async dispatch => {
    try {
        await axios.delete(`/api/xirify/document/${fileType}`);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "QR code image deleted successfully." });
    } catch (err) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while deleting QR code image." });
    }
}

export const getQRCodeUploadStatus = () => async dispatch => {
    try {
        let response = await axios.get(`/api/xirify/document/qrcode/status`);
        let qrCodeStatus = {
            qrcodebusiness: {
                uploadStatus: false,
                file: null
            },
            qrcodeconsumer: {
                uploadStatus: false,
                file: null
            }
        }
        if (response.data && response.data.qrcodebusiness & !response.data.qrcodebusiness === undefined) {
            let businessQr = await axios.get(`/api/xirify/document/qrcodebusiness`,
                {
                    responseType: 'blob'
                });
            qrCodeStatus.qrcodebusiness.uploadStatus = true;
            qrCodeStatus.qrcodebusiness.file = businessQr.data;
        }
        if (response.data && response.data.qrcodeconsumer && !response.data.qrcodeconsumer === undefined ) {
            let consumerQr = await axios.get(`/api/xirify/document/qrcodeconsumer`,
                {
                    responseType: 'blob'
                });
            qrCodeStatus.qrcodeconsumer.uploadStatus = true;
            qrCodeStatus.qrcodeconsumer.file = consumerQr.data;
        }
        dispatch({ type: SET_QR_CODE_STATUS, payload: qrCodeStatus });
    } catch (e) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while fetching QR code status for Consumer and Business App." });
    }
}

export const addTermsAndConditions = (payload) => async dispatch => {
    try {
        await axios.post("/api/termsandconditions", payload);
        dispatch({ type: SET_ADMIN_SUCCESS_MESSAGE, payload: "Terms & conditions for " + payload.type + " has been uploaded successfully." });
    } catch (e) {
        dispatch({ type: SET_ADMIN_ERROR_MESSAGE, payload: "Error while uploading Terms & Conditions for " + payload.appname + "." });
    }
};

export const getAllServiceProviders = (payload) => async dispatch => {
    const response = await axios.get("/api/serviceproviders", { params: payload });
    dispatch({ type: SET_SP_LIST, payload: response.data });
};

export const getServiceProviderDetailsById = (serviceProviderId) => async dispatch => {
    try {
        const response = await axios.get(`/api/serviceproviders/${serviceProviderId}/details`);
        dispatch({ type: SET_SP_DETAILS, payload: response.data });
    } catch (e) {
        console.log("error while fetching service provider details : ", e);
        dispatch({ type: SET_SP_DETAILS, payload: {} });
    }
};


export const getAllMasterOtp = (payload) => async dispatch => {
    try {
        const otp = await axios.get("/app/appConfigurations/getAllMasterOtp", payload);

        if(otp.data.data[0].hasOwnProperty('exotelConfig') && otp.data.data[0].exotelConfig.length > 0) {
            dispatch({ type: GET_MASTER_OTP, payload: otp.data });
        }else {
            otp.data.data[0].exotelConfig = [{
                minute: 3,
                whichReminder: '1st'
            },
            {
                minute: 6,
                whichReminder: '2nd'
            },
            {
                minute: 9,
                whichReminder: '3rd'
            }]
            dispatch({ type: GET_MASTER_OTP, payload: otp.data });
        }

    } catch (e) {
        console.log("error While grtting OTP :", e)
        dispatch({ type: GET_MASTER_OTP, payload: [] });
    }
}

export const updateSplashScreenUrl = (payload) => async dispatch => {
    try {
        await axios.patch("/app/appConfigurations/updateSplashScreenUrl", payload);
      
    } catch (e) {
        console.log("error While updating splash screen Url :", e)
       
    }
}
export const updateSplashScreenUrlBusiness = (payload) => async dispatch => {
    try {
        await axios.patch("/app/appConfigurations/updateSplashScreenUrlBusiness", payload);
    } catch (e) {
        console.log("error While updating splash screen Url :", e)
        
    }
}
export const updateWebLogo = (payload) => async dispatch => {
    try {
       await axios.patch("/app/appConfigurations/updateLogos", payload);       
    } catch (e) {
        console.log("error While updating splash screen Url :", e)
       
    }
}

export const setBusinessAppMasterOTP = (payload) => async (dispatch) => {
    try {
        await axios.patch("/app/appConfigurations/setBusinessOtp", payload);
        dispatch({ type: SET_BUSINESS_MASTER_OTP, payload: "Master businessOTP saved successfully" })
    } catch (err) {
        console.log("Error while updating Master businessOTP : ", err);
        dispatch({ type: SET_BUSINESS_MASTER_OTP, payload: "error while Master businessOTP saved" })
    }
};

export const deleteBusinessMasterOTP = (payload) => async dispatch => {
    try {
        await axios.delete("/app/appConfigurations/deleteBusinessOtp", payload);
        dispatch({ type: SET_BUSINESS_MASTER_OTP, payload: "Business Master OTP deleted successfully." });
    } catch (err) {
        dispatch({ type: SET_BUSINESS_MASTER_OTP, payload: "Error while deleting Business Master OTP" });
    }
}

export const getConsumerMasterOTP = (payload) => async dispatch => {
    try {
        const otp = await axios.get("/app/appConfigurations/getAllMasterOtp", { params: payload });
        dispatch({ type: GET_CONSUMER_MASTER_OTP, payload: otp.data });

    } catch (e) {
        console.log("error While grtting OTP :", e)
        dispatch({ type: GET_CONSUMER_MASTER_OTP, payload: [] });
    }
}



export const setConsumerAppMasterOTP = (payload) => async (dispatch) => {
    try {
        await axios.patch("/app/appConfigurations/setConsumerOtp", payload);

        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Master consumerOTP saved successfully" })
    } catch (err) {
        console.log("Error while updating Master businessOTP : ", err);
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "error while Master consumerOTP saved" })
    }
};

export const setConsumerAppMasterRegistrationOTP= (payload) => async (dispatch) => {
    try {
        await axios.patch("/app/appConfigurations/setConsumerMasterRegistrationOtp", payload);

        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Master registration consumer OTP saved successfully" })
    } catch (err) {
        console.log("Error while updating Master registration consumer OTP : ", err);
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "error while Master registration consumer OTP saved " })
    }
};


export const deleteConsumerAppRegestrationMasterOTP = (payload) => async dispatch => {
    try {
        await axios.delete("/app/appConfigurations/deleteConsumerMasterRegistrationOtp", payload);
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Consumer Master OTP deleted successfully." });
    } catch (err) {
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Error while deleting Consumer Master OTP" });
    }
};

export const deleteConsumerMasterOTP = (payload) => async dispatch => {
    try {
        await axios.delete("/app/appConfigurations/deleteConsumerOtp", payload);
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Consumer Master OTP deleted successfully." });
    } catch (err) {
        dispatch({ type: SET_CONSUMER_MASTER_OTP, payload: "Error while deleting Consumer Master OTP" });
    }
};

export const emptyConsumersList = () => async dispatch => {
    dispatch({ type: SET_CONSUMER_LIST_EMPTY, payload: null });
};

export const setCofiguration = (payload) => async dispatch => {
    try {
        let response = await axios.patch("app/appConfigurations/updateConfigurations", payload);
        console.log("response ****************---->", response)
        if(response.data.message === "Delivery Order By Time Added"){ dispatch({ type: SET_CONFIG_ORDERBY, payload: response.data.message });
        }
        if(response.data.message === "Consumer status updated successfully"){ dispatch({ type: SET_MINORDERS_FOR_ELIGIBLITY, payload: response.data.message });
        }
        
        else{dispatch({ type: SET_CONFIG, payload: "Successfully updated configuration" });}
        

    } catch (e) {
        console.log("Error while getting OTP :", e);
        dispatch({ type: SET_CONFIG, payload: "Failure updating configurations" });
    }
}
export const setExotel = (payload) => async dispatch => {
    try {
        let response = await axios.patch("app/appConfigurations/updateExotelService", payload);
        if(response.data.message === "Delivery Order By Time Added"){ dispatch({ type: SET_CONFIG_ORDERBY, payload: response.data.message });
        }else{dispatch({ type: SET_CONFIG, payload: "Successfully updated configuration" });}
        

    } catch (e) {
        console.log("Error while getting OTP :", e);
        dispatch({ type: SET_CONFIG, payload: "Failure updating configurations" });
    }
}

export const setSmsProvider = (payload) => async dispatch => {
    try {
       const response =  await axios.patch('/app/appConfigurations/updateSmsServiceProvider', payload)

       if(response.message === "SMS service provider update successfully!"){ 
           dispatch({ type: SET_CONFIG_ORDERBY, payload: response.message });
        }else{
            dispatch({ type: SET_CONFIG, payload: "SMS service provider update successfully!" });
        }
    } catch (e) {
        dispatch({type: SET_CONFIG, payload: 'Failure updating SMS service provider'})
    }
}

export const setExotelGlobalConfig = (payload) => async dispatch => {
    // console.log('payload: ', payload);
    const response = await axios.patch('/app/appConfigurations/updateExotelConfig', payload)

    if(response.message === "SMS service provider update successfully!"){
        dispatch({type: SET_CONFIG_ORDERBY, payload: response.message})
    }else {
        dispatch({ type: SET_CONFIG, payload: "SMS service provider update successfully!" });
    }
}

export const getPGTransactionRecords = (payload) => async dispatch => {
    const response = await axios.get("/api/pgIntegration/getallpgtransaction", {
        params: payload
    });
    dispatch({ type: GET_PG_RECORDS, payload: response.data });
};

export const getCallIconHitRecords = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/callIconHit", { params: payload });
        // console.log("Response of get call---->",response);
        dispatch({ type: GET_ALL_CALL_ICON_HIT_RECORDS, payload: response.data });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_ALL_CALL_ICON_HIT_RECORDS, payload: error})
    }
};
export const getOpenCloseBusinessList = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/analysisReport/getBusinessOpenCloseList", {params: payload});
        dispatch({ type: GET_ALL_OPEN_CLOSE_STATUSES, payload: response.data });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_ALL_OPEN_CLOSE_STATUSES, payload: error})
    }
};
export const getOpenCloseBusinessExportList = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/analysisReport/getOpenCloseBusinessExportList", {params: payload}); 

        dispatch({ type: GET_ALL_OPEN_CLOSE_EXPORT_STATUSES, payload: response.data.exportToExcel});
        return
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_ALL_OPEN_CLOSE_EXPORT_STATUSES, payload: error})
    }
};

export const getItemsAvailabilityList = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/analysisReport/getItemsAvailabilityList", {params: payload});
        dispatch({ type: GET_ALL_ITEMS_AVAILABILITY_STATUSES, payload: response.data });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_ALL_ITEMS_AVAILABILITY_STATUSES, payload: error})
    }
};
export const getItemsAvailabilityExportList = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/analysisReport/getItemsAvailabilityExportList", {params: payload}); 

        dispatch({ type:GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES, payload: response.data.exportToExcel});
        return
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type:GET_ALL_ITEMS_AVAILABILITY_EXPORT_STATUSES, payload: error})
    }
};

export const deleteSelectedOpenCloseBusinessByIds = (selectedBusinessOpenCloseIds) => async dispatch => {
    try {
      await axios.delete("/api/analysisReport/deleteSelectedOpenCloseBusinessByIds", {
            params: {
                selectedBusinessOpenCloseIds: selectedBusinessOpenCloseIds
            }
        });
        dispatch({ type: GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS,  
                   payload:{ops: "DELETE_OPEN_CLOSE_BUSINESS",
                   msg: "Selected records are deleted successfully",
                           }
                }) 
       
    } catch (error) {
        
        dispatch({ type:GET_DELETE_OPEN_CLOSE_BUSINESS_RECORDS_ERROR_MESSAGE, 
                   payload: {ops: "DELETE_OPEN_CLOSE_BUSINESS",
                   msg: "Unable to delete Selected Records",
                            } 
                });
    }
}
export const deleteOpenCloseRecords = (payload) => async dispatch => {
    // console.log("---action payload---",payload)
    try {
       const response= await axios.delete("/cron/clearOpenCloseCollection", {params:payload});
        dispatch({ type: GET_DELETE_OPEN_CLOSE_RECORDS,  payload:response.data.message}) 
    // console.log("---action response---",response)
        
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type:GET_DELETE_OPEN_CLOSE_RECORDS_ERROR_MESSAGE, payload: error.response.data.message});
    }
    }

export const deleteSelectedItemsAvailabilityByIds = (SelectedItemsIds) => async dispatch => {
    try {
        await axios.delete("/api/analysisReport/deleteSelectedItemsAvailabilityByIds", {
            params: {
                SelectedItemsIds: SelectedItemsIds
            }
        });
        dispatch({ type: GET_DELETE_ITEMS_AVAILABILITY_RECORDS,  payload: {
            ops: "DELETE_ITEM_AVAILABILITY",
            msg: "Selected records are deleted successfully",
        }}) 
        
    } catch (error) {
        console.log(error);
        dispatch({ type:GET_DELETE_ITEMS_AVAILABILITY_RECORDS_ERROR_MESSAGE, payload: {
            ops: "DELETE_ITEM_AVAILABILITY",
            msg: "Unable to delete Selected Records",
        }
      });
    }
}


export const getCallIconHitRecordsExportToExcel = (payload) => async dispatch => {
        dispatch({ type: CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL});
        const response = await axios.get(`/api/callIconHit/exportcallrecords`, { params: payload });
        // console.log("response of export----->",response);
        dispatch({ type: CALL_ICON_HIT_RECORDS_DOWNLOAD_EXCEL_COMPLETED, payload: response.data.callRecordsList });
};

export const getCronJobPerfExportToExcel = (payload) => async dispatch => {
    dispatch({ type: CRON_JOB_PERF_DOWNLOAD_EXCEL});
    const response = await axios.get(`/api/cronJobPerf/exportcronperfrecords`, { params: payload });
    // console.log("response of export----->",response);
    dispatch({ type: CRON_JOB_PERF_DOWNLOAD_EXCEL_COMPLETED, payload: response.data });
};
export const getCronJobPerfRecords = (payload) => async dispatch => {
    try {
        const response = await axios.get("/api/cronJobPerf/report", { params: payload });
        // console.log("Response of get call---->",response);
        dispatch({ type: GET_ALL_CRON_PERFORMANCE_RECORDS, payload: response.data });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_ALL_CRON_PERFORMANCE_RECORDS, payload: error})
    }
};

export const saveOrderCancellationReason = (payload) => async dispatch => {
    let response;
    try {
        response = await axios.patch("app/appConfigurations/updateCancellationReasons", payload);        
        dispatch({ type: SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE, payload: response.data.message });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({type: SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE, payload: response.data.message});
    }
};
export const saveBrandName = (payload) => async dispatch => {
    let response;
    try {       
        response = await axios.patch("app/appConfigurations/updateBrandName", payload);
         console.log("response from save action---->",response);
        dispatch({ type: SAVE_BRAND_NAME, payload: response.data.message });
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({type: SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE, payload: response.message});
    }
};

export const deleteOrderCancellationReason = (reasonObjId) => async dispatch => {
    let response;
    try {        
        response = await axios.delete(`app/appConfigurations/deleteCancellationReason/${reasonObjId}`);
        dispatch({type: SET_ORDER_CANCELLATION_REASON_SUCCESS_MESSAGE, payload: response.data.message});
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({type: SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE, payload: response.data.message});
    }
};

export const getCancellationReasonForAdminBusiness = () => async dispatch => {
    let response;
    try {
        let adminFlag = {
            isAvailableForAdmin:1
        }
        response = await axios.get(`/app/appConfigurations/getCancellationReason`, {params:adminFlag});        
        dispatch({type: GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION, payload: response.data});
    } catch (error) {
        console.log("Error ======= ",error);
     
    }
};

export const getCancellationReasonByRole = (payload) => async dispatch => {
    let response;
    try {
        // console.log("inside getCancellationReasonByRole--->",payload);
        let roleFlag;

        if(payload===0){
            roleFlag  = {
                isAvailableForAll:1
            }
        }
        if(payload===1){
            roleFlag  = {
                isAvailableForAdmin:1
            }
        }
        if(payload===2){
            roleFlag  = {
                isAvailableForBusiness:1
            }
        }
        if(payload===3){
            roleFlag  = {
                isAvailableForShoppers:1
            }
        }
         
        response = await axios.get(`/app/appConfigurations/getAllCancellationReasonsByRole`, {params: roleFlag});
        // console.log("===response: " , response);
        dispatch({type: GET_ADMIN_REASONS_FOR_ORDER_CANCELLATION, payload: response.data});
    } catch (error) {
        console.log("Error ======= ",error);
        // dispatch({type: SET_ORDER_CANCELLATION_REASON_ERROR_MESSAGE, payload: response.data.message});
    }
};

export const businessDashboardCronDaily = (payload) => async dispatch => {
    try {
        let response = await axios.request(`/cron/businessDashboardDetailsDaily`, {params:payload});
        dispatch({ type: GET_BUSINESS_DASHBOARD_CRON_DATA, payload: response.data.message});
    } catch (error) {
        console.log("Error ======= ",error.response);
        dispatch({ type: BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE, payload: error.response.data.message});
    }
};

export const businessDashboardCronMonthly = (payload) => async dispatch => {
    try {
        let response = await axios.request(`/cron/businessDashboardDetailsMonthly`, {params:payload});
        dispatch({ type: GET_BUSINESS_DASHBOARD_CRON_DATA, payload: response.data.message})
    } catch (error) {
        console.log("Error ======= ",error.response);
        dispatch({ type: BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE, payload: error.response.data.message});
    }
};
export const couponExpiryCron = (payload) => async dispatch => {
    try {
        let response = await axios.request(`/cron/couponExpiryCron`);      
        dispatch({ type: GET_COUPON_EXPIRY_CRON,  payload:response.data.message})
        
    } catch (error) {
        console.log("Error ======= ",error);
        dispatch({ type: GET_COUPON_EXPIRY_CRON_ERROR_MESSAGE, payload: error.response.data.message});
    }
};

export const getCashfreeVendorSplitSettlementData = (payload) => async dispatch => {
    try {

        await axios.request(`/api/script/getCashfreeVendorSplitSettlementData`);
        dispatch({ type: GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_SUCCESS, 
            payload: {
                ops: "GET",
                msg: "Cashfree Vendor Split Settlement - Execution completed successfully.",
              },
        })

    } catch (error) {
        console.log("Unable to get getCashfreeVendorSplitSettlementData ======= ", error);
        dispatch({ type: GET_CASHFREE_VENDOR_SPLIT_SETTLEMENT_ERROR, 
            payload: {
                ops: "ERROR",
                msg: "Unable to get Cashfree Vendor Split Settlement.",
              },
            });
    }
};

export const businessDashboardCronWeekly = (payload) => async dispatch => {
    try {
        let response = await axios.request(`/cron/businessDashboardDetailsWeekly`, {params:payload});
        dispatch({ type: GET_BUSINESS_DASHBOARD_CRON_DATA, payload: response.data.message});
    } catch (error) {
        console.log("Error ======= ",error.response.message);
        dispatch({ type: BUSINESS_DASHBOARD_CRON_ERROR_MESSAGE, payload: error.response.data.message});
    }
};

export const getBusinessesOfAddress = (payload) => async dispatch => {
    const response = await axios.get(`/api/consumers/${payload.id}`);
    dispatch({ type: GET_BUSINESSES_FOR_ADDRESS, payload: response.data });
};

export const addDeleteBusiness = (payload) => async dispatch => {
    try {
        // console.log("=====inside changeConsumerStatus=====");
        // console.log(payload, " ",consumerId, " ",oldStatus);
        let response = await axios.patch(`/api/consumers/visibleBusiness/${payload.id}`,payload);
        // console.log("response ------->",response)
        if (response.status === 200) {
        dispatch({ type: ADD_DELETE_BUSINESS, payload: { ops: "UPDATE", msg: response.data.message, response } });

            return {success : response.data.message };
          } else {
        dispatch({ type: ADD_DELETE_BUSINESS_ERROR, payload: { ops: "UPDATE", msg: "Failed to update businees." } });

            return "error";
          }
    } catch (error) {
        console.log("error addDeleteBusiness action---->", error);
        dispatch({ type: ADD_DELETE_BUSINESS_ERROR, payload: { ops: "UPDATE", msg: "Failed to update businees." } });
    }
};
export const getOneConsumerCartData = (payload) => async (dispatch) => {
    try{
        console.log(`In the response of consumer`,payload.id)
    const response = await axios.get(`/api/consumers/getConsumerCartData/${payload.id}`);
    console.log(`In the response of consumer action===>`, response.data)
    
    dispatch({ type: GET_CONSUMER_CART_DATA, payload: response.data});
    }
    catch(error){
        console.log("error while fetching consumer cart data",error);
    }
};


export const addconsumerTag = (id,payload)=>async (dispatch) => {
    try {
        const response = await axios.patch(`/api/consumers/consumerTags/${id}`, payload);
        dispatch({ type: ADD_CONSUMER_TAGS, payload: response.data});        
    } catch (err) {
        console.log(err);
    }
}

export const addServiceProviderConversation = (payload) => async dispatch => {
	let url;
	try {
        // console.log("supportconversations,serviceProviderId, userRole", supportconversations,serviceProviderId, userRole)
		   url = `/api/serviceproviders/addConversation`;
		//   console.log('addServiceRequestConversation success', conversation + " " + url);
			await axios.put(url, payload);
			dispatch({ type: ADD_SERVICEPROVIDER_CONVERSATION, payload: "Support message send success" });
	} catch (err) {
	  	console.log('addServiceProviderConversation failed',  " " + url);
			dispatch({ type: ADD_SERVICEPROVIDER_CONVERSATION, payload: "Support message send Fail"});
	}
};
