import axios from "axios";

import {
  SET_COUPON_LIST,
  REQUEST_COUPON_STATS,
  GET_ALL_COUPONS,
  SET_COUPON_SUCCESS_MESSAGE,
  SET_COUPON_ERROR_MESSAGE,
  CHANGE_COUPON_STATUS,
  SET_COUPON_STATUS_ERROR_MESSAGE
} from "./types";

// change the status of coupon
export const changeCouponStatus = (payload,couponId) => async dispatch => {
  try {
      //  console.log("=====inside changeCouponStatus=====");
      //  console.log(payload, " ",couponId, " ",oldStatus);
      let response = await axios.patch(`/api/coupon/changeGCCouponStatus/${couponId}`,payload);
      //console.log("...response....",response)
      dispatch({ type: CHANGE_COUPON_STATUS, payload: { ops: "UPDATE", msg: response.data.message} });
  } catch (error) {
      dispatch({ type: SET_COUPON_STATUS_ERROR_MESSAGE, payload: { ops: "UPDATE", msg:error.response.data.error ? error.response.data.error : "Failed to update coupon status." } });
  }
};

// Get the list of coupon

export const getCoupons = (payload) => async dispatch => {
  // old api call
  // const response = await axios.get("/api/coupon", {
  //   params: payload
  // })

  // new api call
  const response = await axios.get("/api/coupon/allCouponList", {
    params: payload
  })
  dispatch({ type: SET_COUPON_LIST, payload: response.data });
};

//couponsExportData

export const exportCoupons = (payload) => async dispatch => {
  //  old api call
  // const response = await axios.get("/api/coupon/export/excel/all", {
  //   params: payload
  // })

  // new api call
  const response = await axios.get("/api/coupon/export/excel/allCouponList", {
    params: payload
  })
  dispatch({ type: GET_ALL_COUPONS, payload: response.data.exportToExcel });
};

export const updateCoupon = (payload) => async (dispatch) => {
  let data = {};
  try {
    let couponId = payload.couponId;
    let query = payload.data;
    await axios.patch("/api/coupon/" + couponId, query);

    data.msg = "Coupon status edited successfully";
    data.ops = "EDIT";
    dispatch({ type: SET_COUPON_SUCCESS_MESSAGE, payload: data })
  } catch (err) {
    console.log(err);
    data.msg = "Failed to edit coupon";
    data.ops = "EDIT";
    dispatch({ type: SET_COUPON_ERROR_MESSAGE, payload: "Failed to edit COUPON" });
  }
};

export const updateSelectedCoupons = (payload) => async (dispatch) => {
  let data = {};
  try {
    await axios.patch("/api/coupon//multi/selected", payload);
    data.msg = "Coupon status edited successfully";
    data.ops = "EDIT";
    dispatch({ type: SET_COUPON_SUCCESS_MESSAGE, payload: data })
  } catch (err) {
    console.log(err);
    data.msg = "Failed to edit coupon";
    data.ops = "EDIT";
    dispatch({ type: SET_COUPON_ERROR_MESSAGE, payload: "Failed to edit COUPON" });
  }
};



export const requestCouponState = (payload) => {
  return {
    type: REQUEST_COUPON_STATS,
    payload: payload
  };
};

export const deleteCoupons = (selectedCouponIds) => async dispatch => {
  let data = {};
  try {
    await axios.delete(`/api/coupon`, {
      params: {
        selectedCouponIds: selectedCouponIds
      }
    });

    data.msg = "Coupon deleted successfully";
    data.ops = "DELETE";
    dispatch({ type: SET_COUPON_SUCCESS_MESSAGE, payload: data })
  }
  catch (err) {
    console.log(err);
    data.msg = "Failed to delete coupon";
    data.ops = "DELETE";
    dispatch({ type: SET_COUPON_ERROR_MESSAGE, payload: "Failed to delete COUPON" });
  }
}

export const clearMsg = () => async dispatch => {
  let data = {};
  data.msg = null;
  data.ops = null;
  dispatch({ type: SET_COUPON_SUCCESS_MESSAGE, payload: data });
  dispatch({ type: SET_COUPON_ERROR_MESSAGE, payload: data });
};

