import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { lightBlue } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import VendorSettlementTable from './VendorSettlementTable';
import SettlementAddForm from './SettlementAddForm';
import { requestVendorSettlement, getVendorSettlementData, requestVendorSettlementReset, deleteVendorSettlement, clearMsg, addVendorSettlements, updateVendorSettlements, removeDuplicateVendorSplitSettlementData, emailReportNotificationCall } from '../../../actions/businessActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
	container: {
		width: '100%',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column',
	},
	backdrop: {
		zIndex: 9999,
		color: '#fff',
	},
	heading: {
		fontSize: "14px",
		fontWeight: 500
	},
    containerin: {
        width: '90%',
        height: '100%',
        // paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
        paddingRight: theme.spacing(0),
      },
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0px",
    },
      lightBlue: {
        color: theme.palette.getContrastText(lightBlue[50]),
        backgroundColor: lightBlue[50],
        width: theme.spacing(4),
        height: theme.spacing(4),
        cursor: 'pointer'
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        width: '100%',
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
      },
      tableCellSmall11: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 150,
        minWidth:120,
        width: 150,
      },
      tableCellSmall12: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'center',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 200,
        minWidth:140,
        width: 200,
      },
      tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px", //TRBL
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 700,
        minWidth:300,
        width: 700,
      },
    tableRow: {
        cursor: 'pointer'
    },
    formControl: {
        minWidth: 225,
        maxWidth: 300,
    },
    // appBarSpacer: theme.mixins.toolbar,
    dialogPaper: {
        minWidth: '65vh',
        maxWidth: '65vh',
    },
});


class VendorSettlementSection extends Component {
    constructor() {
		super();
		this.state = {
            settlementModalOpen: false,
            editSettlementForm: false,
            editFlag: false,
            settledOnDateRange: "",
            transactionDateRange: "",
            status: "",
            page: 0,
			rowsPerPage: 10,
            order: -1,
            source: "",
            utr: "",
            settledOnDateRangeFilterStartDate: "",
            settledOnDateRangeFilterEndDate: "",
            transactionDateRangeFilterStartDate: "",
            transactionDateRangeFilterEndDate: "",
            deleteFlag: false,
            selectedRow: null,
            deleteSettlementDataSucessMsg: null,
            deleteSettlementDataErrorMsg: null,
            addSettlementDataSucessMsg: null,
            selectedRowEdit: null,
            showDownloadIcon: false,
            removeDuplicateSettlementDataSucessMsg: null,
            removeDuplicateSettlementDataErrorMsg: null,
            showEmailReportDownloadIcon: false,
            fromDate: "",
            emailReportExcelFlag: false,
            reportType: "",
            disableButton: false,
            emailNotificationSentSucessMsg: null,
            emailNotificationSentErrorMsg: null
		};
        this.addVendorSettlements = this.addVendorSettlements.bind(this);
        this.updateVendorSettlements = this.updateVendorSettlements.bind(this);
	}

    componentDidMount() {
        this.fetchFilter();
    };

    componentDidUpdate(prevProps, prevState) {
	    if (this.props.business.deleteSettlementDataSucessMsg) {
		    this.setState({ deleteSettlementDataSucessMsg: this.props.business.deleteSettlementDataSucessMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
            this.fetchFilter();
		}
	    if (this.props.business.deleteSettlementDataErrorMsg) {
		    this.setState({ deleteSettlementDataErrorMsg: this.props.business.deleteSettlementDataErrorMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}
	    if (this.props.business.addSettlementDataSucessMsg) {
		    this.setState({ addSettlementDataSucessMsg: this.props.business.addSettlementDataSucessMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}      
	    if (this.props.business.removeDuplicateSettlementDataSucessMsg) {
		    this.setState({ removeDuplicateSettlementDataSucessMsg: this.props.business.removeDuplicateSettlementDataSucessMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}      
	    if (this.props.business.removeDuplicateSettlementDataErrorMsg) {
		    this.setState({ removeDuplicateSettlementDataErrorMsg: this.props.business.removeDuplicateSettlementDataErrorMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}      
	    if (this.props.business.emailNotificationSentSucessMsg) {
		    this.setState({ emailNotificationSentSucessMsg: this.props.business.emailNotificationSentSucessMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}      
	    if (this.props.business.emailNotificationSentErrorMsg) {
		    this.setState({ emailNotificationSentErrorMsg: this.props.business.emailNotificationSentErrorMsg })
		    this.setState({ snackOpen: true });
		    this.props.clearMsg();
		}      
	};

    fetchFilter = async () => {
        this.props.requestVendorSettlement();
        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            status: this.state.status,
            source: this.state.source,
            // businessId: this.props.businessDetails._id, // was before
            businessObjId: this.props.businessDetails._id,
            utrNo: this.state.utr.trim(),
            settledOnDateRangeFilterStartDate: this.state.settledOnDateRangeFilterStartDate,
            settledOnDateRangeFilterEndDate: this.state.settledOnDateRangeFilterEndDate,
            transactionDateRangeFilterStartDate: this.state.transactionDateRangeFilterStartDate,
            transactionDateRangeFilterEndDate: this.state.transactionDateRangeFilterEndDate,
            exportToExcel: 0
        }
        if(this.state.showDownloadIcon) {
            this.setState({ showDownloadIcon: false });
        }
        // if(this.state.showEmailReportDownloadIcon) {
        //     this.setState({ showEmailReportDownloadIcon: false });
        // }
        await this.props.getVendorSettlementData(payload);
        this.props.requestVendorSettlementReset();
    };

    fetchVendorSplitSettlementExcel = async () => {
        this.props.requestVendorSettlement();
        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            status: this.state.status,
            source: this.state.source,
            // businessId: this.props.businessDetails._id, // was before
            businessObjId: this.props.businessDetails._id,
            utrNo: this.state.utr.trim(),
            settledOnDateRangeFilterStartDate: this.state.settledOnDateRangeFilterStartDate,
            settledOnDateRangeFilterEndDate: this.state.settledOnDateRangeFilterEndDate,
            transactionDateRangeFilterStartDate: this.state.transactionDateRangeFilterStartDate,
            transactionDateRangeFilterEndDate: this.state.transactionDateRangeFilterEndDate,
            exportToExcel: 1
        }
        // console.log("====== paylod to be sent for export=======",payload);
        await this.props.getVendorSettlementData(payload);
        this.setState({ showDownloadIcon: true });
        this.props.requestVendorSettlementReset();
    };

    emailReportExcelFlag = async () => {
        this.setState({ emailReportExcelFlag : true });
    };

    closeEmailReportExcelFlagModal = async () => {
        this.setState({ emailReportExcelFlag : false, reportType: "", disableButton: false });
    };

    handleReportTypeChangeRadio = async (event) => {
        this.setState({
            reportType: event.target.value            
        });
    };

    emailReportExcel = async (event) => {
        // console.log("=== inside emailReportExcel ===");
        // console.log("this.state ===>", this.state);
        // console.log(event);
        await this.setState({ fromDate: event, disableButton: true });
        // console.log("From date = ", this.state.fromDate);

        const ranges = {};
        let tempDate = moment(this.state.fromDate).subtract(1,'months').startOf("month");
        // console.log("tempDate------>", tempDate);
        let rangeStartTemp = new Date(moment(tempDate).set("date", 1).startOf("day"));
        // console.log("rangeStartTemp------>", rangeStartTemp);
        let rangeEndTemp = new Date(moment(tempDate)
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss"));
        ranges.rangeStart = new Date(rangeStartTemp);
        ranges.rangeEnd = new Date(rangeEndTemp);

        // console.log("rangeEndTemp------>", rangeEndTemp);
        
        this.props.requestVendorSettlement();
        let payload = {
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            settledOnDateRangeFilterStartDate: ranges.rangeStart,
            settledOnDateRangeFilterEndDate: ranges.rangeEnd,
            status: '',
            source: '',
            utrNo: '',
            transactionDateRangeFilterStartDate: '',
            transactionDateRangeFilterEndDate: '',
            exportToExcel: 1,
            businessObjId: this.props.businessDetails._id,
            consumerName: '',
            fromDate: '',
            fromTime: '',
            toDate: '',
            toTime: '',
            orderBy: 'dateOfInvite',
            order: '-1',
            requestDate: '',
            serviceRequestId: '',
            consumerId: '',
            cashfreeVendorId: '',
            businessId: '',
            businessName: '',
            location: '',
            couponCode: '',
            gstRate: '',
            orderCancelation: '',
            paymentMethod: '',
            paidMarkedBy: '',
            paymentDate: '',
            paymentMode: '',
            comments: '',
            settlementDate: '',
            utrId: '',
            requestStartDate: '',
            requestEndDate: '',
            paymentStartDate: '',
            paymentEndDate: '',
            settlementStartDate: '',
            settlementEndDate: '',
            settlementFromDate: '',
            settlementFromTime: '',
            settlementToDate: '',
            settlementToTime: '',
            exportToexcel: '1',
            requestType:'emailnotification'
        }

        if(this.state.reportType === 'orderdate') {
            payload.requestStartDate = ranges.rangeStart;
            payload.requestEndDate = ranges.rangeEnd;
        } else if(this.state.reportType === 'paymentdate') {
            payload.paymentStartDate = ranges.rangeStart;
            payload.paymentEndDate = ranges.rangeEnd;
        }
        // console.log("====== paylod to be sent for export=======",payload);

        await this.props.emailReportNotificationCall(payload);

        // this.setState({ showEmailReportDownloadIcon: true });
        this.props.requestVendorSettlementReset();
        this.setState({ emailReportExcelFlag: false, reportType: "", disableButton: false })
    };

    resetFilter = async () => {
        this.setState({
            source: "",
            status: "",
            settledOnDateRange: "",
            transactionDateRange: "",
            utr: "",
            settledOnDateRangeFilterStartDate: "",
            settledOnDateRangeFilterEndDate: "",
            transactionDateRangeFilterStartDate: "",
            transactionDateRangeFilterEndDate: ""
        }, () => {
            this.fetchFilter();
        });
    };

    handleSettlementModalOpen = () => { this.setState({ settlementModalOpen: true, editFlag: false }); };

    handleSettlementModalClose = () => { this.setState({ settlementModalOpen: false, editSettlementForm: false, editFlag: false }); };

    handleSettledOnDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            // lastActivetimeFilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
            // lastActivetimeFilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
            settledOnDateRangeFilterStartDate: picker.startDate.format(),
            settledOnDateRangeFilterEndDate: picker.endDate.format()
        });       
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ settledOnDateRange: chosenLabel });
    };

    handleDateRangeChange = (event, picker) => {
        let reportTimeRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        this.setState({
            // lastActivetimeFilterStartDate: picker.startDate.format('YYYY-MM-DD[T00:00:00.000Z]'),
            // lastActivetimeFilterEndDate: picker.endDate.format('YYYY-MM-DD[T23:59:59.999Z]')
            transactionDateRangeFilterStartDate: picker.startDate.format(),
            transactionDateRangeFilterEndDate: picker.endDate.format()
        });       
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : reportTimeRange;
        this.setState({ transactionDateRange: chosenLabel });
    };

    handleFilterChange = (event) => { this.setState({ [event.target.name]: event.target.value }); };

    setPage = (page) => { this.setState({ page }, () => this.fetchFilter()); };

    setRowsPerPage = (rowsPerPage) => { this.setState({ rowsPerPage }, () => this.fetchFilter()); };

    handleSnackClose = () => {
		this.setState({ 
            snackOpen: false, 
            deleteSettlementDataSucessMsg: null,
            deleteSettlementDataErrorMsg: null, 
            addSettlementDataSucessMsg: null,
            removeDuplicateSettlementDataSucessMsg: null,
            removeDuplicateSettlementDataErrorMsg: null,
            emailNotificationSentSucessMsg: null,
            emailNotificationSentErrorMsg: null 
        });
	};

    handleDeleteOpen = (e,row) => { this.setState({ deleteFlag: true, selectedRow: row }); };

    handleEditOpen = (e,editRow) => { this.setState({ editFlag: true, selectedRowEdit: editRow, settlementModalOpen: true }); };

    handleDeleteClose = () => { this.setState({ deleteFlag: false, selectedRow: null, settlementModalOpen: false, editFlag: false, selectedRowEdit: null }); };

    handleDeleteVendorSettlement = () => {
        // console.log("===== in handleDeleteVendorSettlement ======");
        // console.log("data to be deleted---->",this.state.selectedRow);

        let payload = {
            businessId: this.props.businessDetails._id,
            settlementId: this.state.selectedRow._id
        }

        // console.log("== payload for delete before calliing action ======", payload);

        this.props.deleteVendorSettlement(payload);
        this.fetchFilter();
        this.setState({ deleteFlag: false, selectedRow: null});
    };

    addVendorSettlements = async (addData) => {
        // console.log(" ===== in addVendorSettlements in vendor section ======");
        // console.log("addData====", addData);
        await this.props.addVendorSettlements(addData);
        this.fetchFilter();
        this.setState({ settlementModalOpen: false, editFlag: false, selectedRowEdit: null, deleteFlag: false, selectedRow: null });
    };

    updateVendorSettlements = async (editData) => {
        // console.log(" ===== in updateVendorSettlements in vendor section =====");
        // console.log("editData====",editData);
        await this.props.updateVendorSettlements(editData);
        this.fetchFilter();
        this.setState({ settlementModalOpen: false, editFlag: false, selectedRowEdit: null, deleteFlag: false, selectedRow: null });
    };

    removeDuplicateVendorSplitSettlementData = async () => {
        this.props.requestVendorSettlement();
        let payload = {
            businessId: this.props.businessDetails._id,
        };

       await this.props.removeDuplicateVendorSplitSettlementData(payload);
       this.fetchFilter();
    }

    render() {
        const { classes } = this.props;
        // console.log("this.props in vendor settlement section---->",this.props);
        // console.log("this.state in vendor settlement section---->",this.state);
        
        let muiAlert = null;

        if (this.state.deleteSettlementDataSucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.deleteSettlementDataSucessMsg}
			</MuiAlert>
		} else if (this.state.deleteSettlementDataErrorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.deleteSettlementDataErrorMsg}
			</MuiAlert>
		} else if (this.state.addSettlementDataSucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.addSettlementDataSucessMsg}
			</MuiAlert>
		} else if (this.state.removeDuplicateSettlementDataSucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.removeDuplicateSettlementDataSucessMsg}
			</MuiAlert>
		} else if (this.state.removeDuplicateSettlementDataErrorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.removeDuplicateSettlementDataErrorMsg}
			</MuiAlert>
		} else if (this.state.emailNotificationSentSucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="success">
				{this.state.emailNotificationSentSucessMsg}
			</MuiAlert>
		} else if (this.state.emailNotificationSentErrorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackClose} severity="error">
				{this.state.emailNotificationSentErrorMsg}
			</MuiAlert>
		} 
        
        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                    {
                        this.props.user.role === "admin" ? 
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleSettlementModalOpen}
                                style={{ "marginRight": "28px", "float": "right" }}
                            >
                                Add
                            </Button>
                        : null
                    }

                    {
                        this.props.user.role === "admin" ? 
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { this.removeDuplicateVendorSplitSettlementData() }}
                                style={{ "marginRight": "28px", "float": "right" }}
                            >
                                Remove Duplicate
                            </Button>
                        : null
                    }

                    <Grid item xs={12}></Grid>
                    <Container className={classes.container}></Container>                 
                    <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Search Filter</Typography>
                            </ExpansionPanelSummary>                      
                            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                <Grid container spacing={3}>
                                
                                    <Grid item xs={3}>
                                        <DateRangePicker
                                            ranges={{
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                            }}
                                            onApply={this.handleDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                        >
                                            <TextField  style={{ width: 225}}  fullWidth margin="dense" name="transactionsFromToDate"  label="Transactions From To Date" value={this.state.transactionDateRange}  variant="outlined" />
                                        </DateRangePicker>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <DateRangePicker
                                            ranges={{
                                                'Today': [moment(), moment()],
                                                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                                                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                            }}
                                            onApply={this.handleSettledOnDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                        >
                                            <TextField fullWidth margin="dense" name="settledOn" label="Settled On" value={this.state.settledOnDateRange}  variant="outlined" />
                                        </DateRangePicker>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField fullWidth margin="dense" name="utr" label="UTR No" value={this.state.utr} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                
                                    <Grid item xs={3}>
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={this.state.status}
                                                    name="status"
                                                    onChange={this.handleFilterChange}
                                                    label="Status"
                                                    // style={{ width: "225px" }}
                                                >
                                                    <MenuItem value="SUCCESS" style={{ minHeight: "28px", height: "28px" }}>SUCCESS</MenuItem>
                                                    <MenuItem value="FAILED" style={{ minHeight: "28px", height: "28px" }}>FAILED</MenuItem>
                                                </Select>
                                            </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth margin="dense" variant="outlined">
                                                <InputLabel id="demo-simple-select-standard-label">Source</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={this.state.source}
                                                    name="source"
                                                    onChange={this.handleFilterChange}
                                                    label="source"
                                                    // style={{ width: "225px" }}
                                                >
                                                    <MenuItem value="cashfree" style={{ minHeight: "28px", height: "28px" }}>Cashfree</MenuItem>
                                                    <MenuItem value="manual" style={{ minHeight: "28px", height: "28px" }}>Manual</MenuItem>
                                                </Select>
                                            </FormControl>
                                    </Grid>
                                    <Grid item xs={9}>
                                    </Grid>
                                    <Grid item xs={8}>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            onClick={this.resetFilter}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            color="primary"
                                            onClick={
                                                ()=>this.setPage(0)
                                            }
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>

                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Container>

                    <Container className={classes.container}>
                        {
                                <VendorSettlementTable
                                    data={this.props.business.vendorSettlementList.data}
                                    totalCount={this.props.business.vendorSettlementList.total}
                                    user={this.props.user}
                                    businessId={this.props.businessDetails.businessId}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    setPage={this.setPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    fetchVendorSplitSettlementExcel={this.fetchVendorSplitSettlementExcel}
                                    // emailReportExcel={(e) => this.emailReportExcel(e)}
                                    emailReportExcelFlag={this.emailReportExcelFlag}
                                    showDownloadIcon={this.state.showDownloadIcon}
                                    // showEmailReportDownloadIcon={this.state.showEmailReportDownloadIcon}
                                    exportData={this.props.business.vendorSettlementList.exportData}
                                    handleDeleteVendorSettlement={this.handleDeleteVendorSettlement}
                                    handleDeleteOpen={this.handleDeleteOpen}
                                    handleEditOpen={this.handleEditOpen}
                                    handleDeleteClose={this.handleDeleteClose}
                                    deleteFlag={this.state.deleteFlag}
                                />
                        }
                    </Container>

                    <Modal 
                        open={this.state.settlementModalOpen}
                        className={classes.modal}
                        onClose={this.handleSettlementModalClose}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    > 
                        <SettlementAddForm 
                            onClose={this.handleSettlementModalClose}
                            open={this.state.settlementModalOpen}
                            edit={this.state.editFlag}
                            businessData={this.props.businessDetails}
                            addVendorSettlements={(e,addData)=>this.addVendorSettlements(e,addData)}
                            updateVendorSettlements={(e,editData)=>this.updateVendorSettlements(e,editData)}
                            selectedRowEdit={this.state.selectedRowEdit}
                        />
                    </Modal>

                    <Dialog open={this.state.emailReportExcelFlag} onClose={this.closeEmailReportExcelFlagModal} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialogPaper }}>
                        <DialogTitle className="dialogTitle" style={{ backgroundColor: "#14B7DD", color:"#FFFFFF" }}>
                            <Grid container spacing={1} justify="center" alignItems="center" >
                                <Grid item xs={11}>Report Type and Email</Grid>
                                <Grid item xs={1}><CloseIcon style={{marginTop:"8px"}} onClick={this.closeEmailReportExcelFlagModal}/></Grid>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText style={{color:"black"}}>
                                Select Report Type
                            </DialogContentText>
                            <RadioGroup 
                                aria-label="position" name="reason"
                                onChange={this.handleReportTypeChangeRadio}
                                value={this.state.reportType}
                                style={{ paddingLeft: "10px" }}
                            >
                                <FormControlLabel value={"orderdate"} control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Transaction report by Order Date</Typography>} size="small" />
                                <FormControlLabel value={"paymentdate"} control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Transaction report by Payment Date</Typography>} size="small" />
                            </RadioGroup>
                        </DialogContent>
                        
                        <DialogActions>
                            {/* <Grid container spacing={1} justify="center" alignItems="center" >
                                <Grid item xs={2}></Grid>
                                <Grid item xs={1}> */}
                                    <Button onClick={this.closeEmailReportExcelFlagModal} variant="contained" color="secondary">Cancel</Button>
                                    {/* </Grid>
                                <Grid item xs={8}> */}
                                    <Button disabled={this.state.disableButton || !this.state.reportType} onClick={(e)=>this.emailReportExcel(e)} variant="contained" color="primary" 
                                    // fullWidth={false} style={{width:'50%'}}
                                    >
                                        Generate Report & Email
                                    </Button>
                                {/* </Grid>
                            </Grid> */}
                            
                            
                        </DialogActions>
                    </Dialog>

                    <Snackbar
                        anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                        open={this.state.snackOpen}
                        autoHideDuration={2000}
                        onClose={this.handleSnackClose}
                        ContentProps={{'aria-describedby': 'message-id'}}>
                            {muiAlert}
                    </Snackbar>
                    <Backdrop className={classes.backdrop} open={this.props.business.isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
            </div>
        )
    }
}

const mapStateToProps = state => {
	return {
        user: state.auth.user,
        business: state.business,
	}
};
export default connect(mapStateToProps, { requestVendorSettlement, getVendorSettlementData, requestVendorSettlementReset, deleteVendorSettlement, clearMsg, addVendorSettlements, updateVendorSettlements, removeDuplicateVendorSplitSettlementData, emailReportNotificationCall })(withStyles(styles)(VendorSettlementSection));