import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { changeHeaderName, getAllConsumers, requestBusinessStats, emptyConsumersList,getAllConsumersForPushNotification} from '../../actions/dashboardActions';
import { clearMsg,pushNotification, getpushNotifications, clearPushNotificationMsg, getPushNotificationConsumerData, updatePushNotification, deletePushNotification } from '../../actions/businessActions';
import { fetchEnvs } from '../../actions/authActions';
import UsersListTable from './UsersListTable';
import TabPanel from "../utils/TabPanel";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import moment from 'moment';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import ListAllTable from "./ListAllTable";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';
import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import EditPushNotification from "./EditPushNotification";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { DayPicker } from 'react-dropdown-date';
import { getConsumerTagList} from "../../actions/consumerTagAction";


const styles = theme => ({
    container: {
        width: '100%',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    content: {
        flexGrow: 1,
        height: '100%',
        overflow: 'auto',
    },
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 150,
        maxWidth: 200,
    },
    textArea: {
        width: '100%',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    }
});
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}
class PushNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            pageForListAll: 0,
            rowsPerPage: 10,
            rowsPerPageForListAll: 10,
            businessFilter: "",
            servicesId: "",
            distance: "",
            title: "",
            error: {},
            notificationText: "",
            photos: {},
            notificationName: "",
            value: 0,
            business: "",
            serviceId: "",
            notificationTitle: "",
            filternotificationName: "",
            dateRange: "",
            notificationStartDate: "",
            notificationEndDate: "",
            scheduleType: "Once-Now",
            filterStartWeek: [],
            filterEndWeek: [],
            fromDate: "",
            toDate: "",
            startDate: "",
            endDate: "",
            scheduledDate: "",
            scheduledTime: "",
            recurringStartDate: "",
            recurringEndDate: "",
            timeLine: "Daily",
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            },
            snackOpen: false,
            preparexportToExcel: false,
            daysOfWeek: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            daysOfMonth: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
            weekDays: [],
            monthDays: [],
            excelFilePushNotificationId: "",
            pushNotificationModalOpen: false,
            pushNotificationData: {},
            selectedDeletionId: "",
            scheduleTypeFilter: "",
            filterStartDate: "",
            filterEndDate: "",
            sucessMsg: null,
            errorMsg: null,
            consumerTags:[],
            filteredConsumerTags:[],
        };
        this.inputReference = React.createRef();
    }

    openpushNotificationModal = async (data) => {

        await this.setState({ pushNotificationData: data, pushNotificationModalOpen: true });
    }
    closepushNotificationModal = () => {
        this.setState({ pushNotificationModalOpen: false });
    }
    saveNotification = async (data) => {

        await this.props.updatePushNotification(data);
        this.fetchPushNotificationList();

    }
    fileUploadAction = () => this.inputReference.current.click();
    onFileChange = async event => {
        let files = event.target.files;
        this.updatePhotos(files);
        //this.updateBusinessPhotos(files);
    };
    updatePhotos = async (files) => {
        let data = new FormData();
        let folderPath = `Marketplace_Business/pushNotifications/`;
        let temp = files[0].name.split(".");
        let fileName = temp[0] + Math.ceil(Math.random() * 999999) + "." + temp[1];
        data.append("file", files[0]);
        data.append("folder", folderPath);
        data.append(
            "public_id",
            `/${fileName}`
        );
        data.append(
            "upload_preset",
            this.props.env.cloudinaryBusinessImagesUploadPreset
        );
        let res = await fetch(this.props.env.cloudinaryUploadUrl, {
            method: "POST",
            body: data,
        });

        let file = await res.json();

        let photos = {
            title: "",
            path: file.secure_url,
            public_id: file.public_id,
        };

        this.setState({
            photos
        });
        this.setState({ isUploading: false });
    };

    componentDidMount() {
        this.props.changeHeaderName("Push Notifications - Business");
        this.props.emptyConsumersList();
        this.fetchPushNotificationList();
        this.props.fetchEnvs();
        this.fetchConsumerTags();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.business.pushNotificationMsg) {
            this.setState({ sucessMsg: this.props.business.pushNotificationMsg });
            this.setState({ snackOpen: true });
                this.props.clearMsg();  
          }
    }

    fetchConsumers = async (val) => {
        let flag = await this.validateConsumer();
        if (Number(flag)) {
            let filteredConsumerTagsVar = this.state.filteredConsumerTags.join(', ');
            let payload = {
                orderBy: this.state.orderBy,
                order: Number(this.state.order),
                skip: this.state.page * this.state.rowsPerPage,
                limit: this.state.rowsPerPage,
                businessFilter: this.state.businessFilter,
                distance: Number(this.state.distance) * 1000,
                exportToexcel: 1,
                filteredConsumerTags:filteredConsumerTagsVar,
            };
            if(val !== 1){
                // await this.props.getAllConsumers(payload);
                await this.props.getAllConsumersForPushNotification(payload);
            }
            else{
                await this.fetchConsumers();
            }
           
        }
    }
    fetchConsumerTags=async()=>{
        const response=await getConsumerTagList();
        if(response){
        this.setState({consumerTags:response.data})
    }else{
        this.setState({consumerTags:[]})
      }
    }
    sendPushNotification = async () => {
        await this.fetchConsumers(1);
        let consumerFlag = await this.validateConsumer();
        let flag = await this.validateNotification();
        if (Number(flag) && Number(consumerFlag)) {
            let ids = this.props.consumerList && this.props.consumerList.exportToExcel ? this.props.consumerList.exportToExcel.map(items => items._id.toString()) : null;
            let payload = {
                businessId: this.props.consumerList && this.props.consumerList.business_id ? this.props.consumerList.business_id : null,
                consumerIds: ids,
                serviceIds: this.state.servicesId,
                distance: this.state.distance,
                notificationName: this.state.notificationName,
                notificationTitle: this.state.title,
                notificationText: this.state.notificationText,
                photo: this.state.photos,
                scheduleType: this.state.scheduleType,
                scheduledDate: this.state.scheduledDate,
                scheduledTime: this.state.scheduledTime,
                startDate: this.state.recurringStartDate,
                endDate: this.state.recurringEndDate,
                interval: this.state.timeLine,
                status: "Expired",
                daysOfWeek: this.state.weekDays,
                daysOfMonth: this.state.monthDays
            };
            await this.props.pushNotification(payload);
            await this.fetchPushNotificationList();
            // await this.setState({
            //     businessFilter: "",
            //     distance: "",
            //     servicesId: "",
            //     notificationText: "",
            //     title: "",
            //     notificationName: "",
            //     photos: {},
            //     timeLine: "",
            //     scheduleType: "Once-Now",
            //     scheduledDate: "",
            //     scheduledTime: "",
            //     recurringStartDate: "",
            //     recurringEndDate: "",
            //     weekDays: [],
            //     monthDays: []
            // });
            await this.resetFilter();
        }
    }
    searchPushNotification = async () => {
        await this.setState({ pageForListAll: 0 });
        this.fetchPushNotificationList();
    }
    fetchPushNotificationList = async () => {
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.pageForListAll * this.state.rowsPerPageForListAll,
            limit: this.state.rowsPerPageForListAll,
            business: this.state.business,
            serviceId: this.state.serviceId,
            notificationTitle: this.state.notificationTitle,
            notificationName: this.state.filternotificationName,
            dateRange: this.state.dateRange,
            notificationStartDate: this.state.notificationStartDate,
            notificationEndDate: this.state.notificationEndDate,
            scheduleType: this.state.scheduleTypeFilter,
            startDate: this.state.filterStartDate,
            endDate: this.state.filterEndDate,
            exportToexcel: 0
        }
        await this.props.getpushNotifications(payload);
        if (this.state.preparexportToExcel) {
            await this.setState({ preparexportToExcel: false });
        }
    }
    fetchNotificationWithExcelFile = async () => {
        let payload = {
            orderBy: this.state.orderBy,
            order: Number(this.state.order),
            skip: this.state.pageForListAll * this.state.rowsPerPageForListAll,
            limit: this.state.rowsPerPageForListAll,
            business: this.state.business,
            serviceId: this.state.serviceId,
            notificationTitle: this.state.notificationTitle,
            notificationName: this.state.filternotificationName,
            dateRange: this.state.dateRange,
            notificationStartDate: this.state.notificationStartDate,
            notificationEndDate: this.state.notificationEndDate,
            exportToexcel: 1
        }
        await this.props.getpushNotifications(payload);
        await this.setState({ preparexportToExcel: true });
    }
    setOrder = (order) => {
        this.setState({ order }, () => this.fetchConsumers());
    }

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.fetchConsumers());
    }

    setPage = (page) => {
        this.setState({ page }, () => this.fetchConsumers());
    }


    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    }

    setRowsPerPageForListAll = (rowsPerPageForListAll) => {
        this.setState({ rowsPerPageForListAll }, () => this.fetchPushNotificationList());
    }

    setPageForListAll = (pageForListAll) => {
        this.setState({ pageForListAll }, () => this.fetchPushNotificationList());
    }

    handleChange = async (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            error: {},
        });
        if (event.target.name === "businessFilter" || event.target.name === "distance" || event.target.name === "notificationText" || event.target.name === "title") {
            this.setState({ error: {} });
        }
    }


    handleblur=async (event) => {
        this.setState({
            [event.target.name]: event.target.value.trim(),
            error: {},
        });
        if (event.target.name === "businessFilter" || event.target.name === "distance" || event.target.name === "notificationText" || event.target.name === "title") {
            this.setState({ error: {} });
        }
    }


    handleStartWeekChange = date => {
        this.setState({
            filterStartWeek: getWeekDays(getWeekRange(date).from),
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleStartWeekClick = (weekNumber, days, e) => {
        this.setState({
            filterStartWeek: days,
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleEndWeekChange = date => {
        this.setState({
            filterEndWeek: getWeekDays(getWeekRange(date).from),
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };

    handleEndWeekClick = (weekNumber, days, e) => {
        this.setState({
            filterEndWeek: days,
        });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    };


    resetFilter = async () => {
        await this.resetState();
        await this.props.emptyConsumersList();
        await this.fetchPushNotificationList();
    }
    resetState = () => {
        this.setState({
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            pageForListAll: 0,
            rowsPerPageForListAll: 10,
            businessFilter: "",
            servicesId: "",
            distance: "",
            title: "",
            error: {},
            notificationText: "",
            photos: {},
            notificationName: "",
            business: "",
            serviceId: "",
            notificationTitle: "",
            filternotificationName: "",
            dateRange: "",
            notificationStartDate: "",
            notificationEndDate: "",
            scheduleType: "Once-Now",
            scheduledDate: "",
            scheduledTime: "",
            recurringStartDate: "",
            recurringEndDate: "",
            timeLine: "",
            modalDeleteOpen: false,
            weekDays: [],
            monthDays: [],
            filterEndDate: "",
            filterStartDate: "",
            scheduleTypeFilter: "",
            filteredConsumerTags:[],
        });
    }
    validateConsumer = async () => {
        let consumerFlag = 1;
        let decimalValueRegex = /^[1-9]\d*(\.\d+)?$/;
        let error = this.state.error;
        if (!this.state.businessFilter.trim()) {
            error.businessFilter = "Please Enter Business Name or Id";
            consumerFlag = 0;
        }
        if (!this.state.distance.trim()) {
            error.distance = "Please Enter Distance";
            consumerFlag = 0;
        }
        let regExcheckAmount = await decimalValueRegex.test(this.state.distance);
        if (!regExcheckAmount) {
            error.distance = "Please Enter valid Distance";
            consumerFlag = 0;
        }

        if (this.state.timeLine === "") {
            error.timeLine = "Required"
        }
        if (this.state.timeLine === "Weekly") {
            if (!this.state.filterStartWeek.length) {
                error.fromDate = "Required"
            }
            if (!this.state.filterEndWeek.length) {
                error.toDate = "Required"
            }

        } else {
            if (!this.state.fromDate) {
                error.fromDate = "Required"
            }
            if (!this.state.toDate) {
                error.toDate = "Required"

            }
        }
        if (!(this.state.startDate)) {
            error.startDate = "Required"
        }
        if (!(this.state.endDate)) {
            error.endDate = "Required"
        }
        await this.setState({ error });
        return consumerFlag;
    }

    handleFilterFromDateChange = (event) => {
        this.setState({ fromDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterToDateChange = (event) => {
        this.setState({ toDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }


    handleFilterFromMonthChange = (event) => {
        this.setState({ fromDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }
    handleFilterToMonthChange = (event) => {
        this.setState({ toDate: event });
        if (!_.isEmpty(this.state.error)) {
            this.setState({ error: {} })
        }
    }

    validateNotification = async () => {
        let error = this.state.error;
        let flag = 1;
        if (!this.state.title.trim()) {
            error.title = "Please Enter Notification Title";
            flag = 0;
        }
        if (!this.state.notificationText.trim()) {
            error.notificationText = "Please Enter Notification Text";
            flag = 0;
        }
        if (this.props.consumerList && this.props.consumerList.exportToExcel.length === 0) {
            error.consumerCount = "No consumers Found Please check Business Name/Id and Distance !";
            flag = 0;
        }
        if (this.state.scheduleType === "Once-Schedule") {
            if (!this.state.scheduledDate) {
                error.scheduledDate = "Please Enter Schedule Date";
                flag = 0;
            }
            if (!this.state.scheduledTime) {
                error.scheduledTime = "Please Enter Schedule Time";
                flag = 0;
            }
            if (moment().isAfter(moment(this.state.scheduledDate + " " + this.state.scheduledTime))) {
                error.scheduledTime = "Please Enter Valid Schedule Time";
                error.scheduledDate = "Please Valid Schedule Date";
                flag = 0;
            }
        }
        if (this.state.scheduleType === "Recurring") {
            if (!this.state.scheduledTime) {
                error.scheduledTime = "Please Enter Schedule Time";
                flag = 0;
            }
            if (!this.state.recurringStartDate) {
                error.recurringStartDate = "Please Enter Start Date";
                flag = 0;
            }
            if (!this.state.recurringEndDate) {
                error.recurringEndDate = "Please Enter End Date";
                flag = 0;
            }
            if (moment().isAfter(moment(this.state.recurringStartDate + " 23:59"))) {
                error.recurringStartDate = "Please Enter Valid start Date";
                flag = 0;
            }
            if (moment().isAfter(this.state.recurringEndDate) || moment(this.state.recurringStartDate + " 23:59").isAfter(moment(this.state.recurringStartDate + " 23:59")) ) {
                error.recurringEndDate = "Please Valid Valid End Date";
                flag = 0;
            }
        }
       this.setState({ error });
        return flag;
    }

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
        this.resetFilter();

    };

    handleDateRangeChange = (event, picker) => {
        let notificationDateRange = picker.startDate.format('DD MMM YYYY') + " - " + picker.endDate.format('DD MMM YYYY');
        picker.endDate.add(1, "days");
        this.setState({
            notificationStartDate: picker.startDate.format('DD MMM YYYY'),
            notificationEndDate: picker.endDate.format('DD MMM YYYY')
        });
        let chosenLabel = picker.chosenLabel !== "Custom Range" ? picker.chosenLabel : notificationDateRange;
        this.setState({ dateRange: chosenLabel });
    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    enterPressed = (event) => {
        let code = event.keyCode;
        if (code === 13) {
            event.preventDefault();
            this.fetchPushNotificationList();
        }
    }

    handleSnackClose = async () => {
        this.setState({ snackOpen: false });
        this.setState({ pushNotificationModalOpen: false});
        
    }
    fetchConsumerDetails = async (excelFilePushNotificationId) => {
        await this.props.getPushNotificationConsumerData(excelFilePushNotificationId);
        await this.setState({ excelFilePushNotificationId })
    }
    deletePushNotificationModalOpen = (_id) => {
        this.setState({ modalDeleteOpen: true, selectedDeletionId: _id });

    }
    handleDeleteClose = () => {
        this.setState({ modalDeleteOpen: false })
    }
    deletePushNotification = async (_id) => {
        await this.props.deletePushNotification({ id: this.state.selectedDeletionId });
        this.fetchPushNotificationList();
        this.handleDeleteClose();
    }
    render() {
        const { classes } = this.props;

        // const { filterStartWeek, filterEndWeek } = this.state;

        // const startWeekDaysAreSelected = filterStartWeek.length > 0;
        // const endWeekDaysAreSelected = filterEndWeek.length > 0;

        // const startmodifiers = {
        //     selectedRange: startWeekDaysAreSelected && {
        //         from: filterStartWeek[0],
        //         to: filterStartWeek[6],
        //     },
        //     selectedRangeStart: startWeekDaysAreSelected && filterStartWeek[0],
        //     selectedRangeEnd: startWeekDaysAreSelected && filterStartWeek[6],
        // };

        // const endmodifiers = {
        //     selectedRange: endWeekDaysAreSelected && {
        //         from: filterEndWeek[0],
        //         to: filterEndWeek[6],
        //     },
        //     selectedRangeStart: endWeekDaysAreSelected && filterEndWeek[0],
        //     selectedRangeEnd: endWeekDaysAreSelected && filterEndWeek[6],
        // };

        let muiAlert = null;
        if (this.state.sucessMsg) {
            muiAlert = (
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={this.handleSnackClose}
                severity="success"
              >
                {this.state.sucessMsg}
              </MuiAlert>
            );
        }
        return (
            <Container className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Tabs
                    value={this.state.value}
                    onChange={this.handleChangeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    left="true"
                >
                    <Tab label="Send New" />
                    <Tab label="List All" />
                </Tabs>
                <TabPanel value={this.state.value} index={0}>
                    <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                        <Grid container spacing={3} alignItems="center">

                            {this.state.error && this.state.error.consumerCount ?
                                <Grid item xs={12} style={{ color: "red" }} >
                                    <Typography  >{this.state.error.consumerCount}</Typography>
                                </Grid>
                                : null
                            }
                            <Grid item xs={4}>
                                <Typography>Business Name/Id *</Typography>
                                <TextField fullWidth margin="dense" name="businessFilter" variant="outlined" value={this.state.businessFilter} error={this.state.error.businessFilter} helperText={this.state.error.businessFilter} onChange={this.handleChange} onBlur={this.handleblur}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography> Service ID(s)(comma separated)</Typography>
                                <TextField fullWidth margin="dense" name="servicesId" variant="outlined" value={this.state.servicesId} error={this.state.error.servicesId} helperText={this.state.error.servicesId} onChange={this.handleChange} onBlur={this.handleblur}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Distance (In KM)*</Typography>
                                <TextField fullWidth margin="dense" name="distance" variant="outlined" value={this.state.distance} error={this.state.error.distance} helperText={this.state.error.distance} onChange={this.handleChange} onBlur={this.handleblur}/>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Title *</Typography>
                                <TextField fullWidth margin="dense" name="title" variant="outlined" value={this.state.title} error={this.state.error.title} helperText={this.state.error.title} onChange={this.handleChange} onBlur={this.handleblur}/>

                                <Typography>Notification Image</Typography>
                                <TextField margin="dense" name="photos" variant="outlined" value={this.state.photos && this.state.photos.path ? this.state.photos.path : "No Image attached"} error={this.state.error.photos} helperText={this.state.error.photos} disabled={true} />
                                <Button style={{ marginTop: 10 }} variant="contained" htmlFor="photos" onClick={this.fileUploadAction} color="secondary" component="span" >
                                    Upload
                                </Button>
                                <input
                                    ref={this.inputReference}
                                    id="photos"
                                    name="photos"
                                    style={{ display: 'none' }}
                                    type={"file"}
                                    onChange={this.onFileChange}
                                    accept="image/x-png,image/jpeg"
                                />

                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Text *</Typography>
                                <TextareaAutosize
                                    name="notificationText"
                                    className={classes.textArea}
                                    rowsMin={5}
                                    label="notificationText"
                                    value={this.state.notificationText}
                                    onChange={this.handleChange}
                                    onBlur={this.handleblur}
                                />
                                {this.state.error.notificationText ?
                                    <helperText style={{ color: "red" }}>Please Enter Notification Text </helperText> : null}
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>Notification Name</Typography>
                                <TextField fullWidth margin="dense" name="notificationName" label="Notification Name" variant="outlined" value={this.state.notificationName} error={this.state.error.notificationName} helperText={this.state.error.notificationName} onChange={this.handleChange} onBlur={this.handleblur} />
                            </Grid>
                            <Grid item xs={4} >
                                <Typography variant="subtitle2" gutterBottom> Consumer Tags</Typography>
                                    <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                        <InputLabel id="demo-mutiple-checkbox-label">Select Consumer Tags</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-checkbox-label"
                                            id="demo-mutiple-checkbox"
                                            multiple
                                            value={this.state.filteredConsumerTags}
                                            name="filteredConsumerTags"
                                            onChange={this.handleFilterChange}
                                            input={<Input />}
                                            renderValue={(selected) => selected.join(', ')}
                                            MenuProps={MenuProps}
                                            >
                                            {this.state.consumerTags?.length && this.state.consumerTags?.map((eachConsumerTagObj) => (
                                                <MenuItem key={eachConsumerTagObj._id} value={eachConsumerTagObj.name} style={{ minHeight: "28px", height: "28px" }}>
                                                    <Checkbox checked={this.state.filteredConsumerTags.indexOf(eachConsumerTagObj.name) > -1} />
                                                    <ListItemText primary={eachConsumerTagObj.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid> 
                            <Grid item xs={12} className='row'  >
                                <Grid item xs={5}>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Schedule Type
                                    </Typography>
                                    <RadioGroup row aria-label="position" name="scheduleType" onChange={this.handleFilterChange} value={this.state.scheduleType}>
                                        <FormControlLabel value="Once-Now" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Once-Now</Typography>} size="small" />
                                        <FormControlLabel value="Once-Schedule" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Once-Schedule</Typography>} size="small" />
                                        <FormControlLabel value="Recurring" control={<Radio color="primary" />} label={<Typography style={{ fontSize: 14 }}>Recurring</Typography>} size="small" />
                                    </RadioGroup>
                                </Grid>
                                {/* 
                                {this.state.scheduleType === "Once-Now" ?
                                    <Grid xs={7}></Grid>
                                    : null
                                } */}
                                <Grid item xs={7}></Grid>

                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>Scheduled On</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="scheduledDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            error={this.state.error.scheduledDate}
                                            helperText={this.state.error.scheduledDate}
                                            // error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            // helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                            value={this.state.scheduledDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={1}></Grid>
                                    : null}
                                {this.state.scheduleType === "Once-Schedule" ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom> Time </Typography>
                                        <TextField
                                            name="scheduledTime"
                                            type="time"
                                            fullWidth
                                            margin="dense"
                                            variant="filled"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: 300 // 5 min
                                            }}
                                            error={this.state.error.scheduledTime}
                                            helperText={this.state.error.scheduledTime}
                                        />
                                    </Grid>
                                    : null
                                }

                                {this.state.scheduleType === 'Recurring' ?
                                    <Grid item xs={3}>
                                        <Typography variant="subtitle2" gutterBottom>
                                            Recurring
                                        </Typography>

                                        <FormControl variant="outlined" margin="dense" className={classes.formControl} >
                                            <Select
                                                id="timeLine"
                                                value={this.state.timeLine}
                                                name="timeLine"
                                                onChange={this.handleChange}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem value="Daily" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Daily" />
                                                </MenuItem>
                                                <MenuItem value="Weekly" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Weekly" />
                                                </MenuItem>
                                                <MenuItem value="Monthly" style={{ minHeight: "28px", height: "28px" }}>
                                                    <ListItemText className={classes.listItemText} primary="Monthly" />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {(this.state.scheduleType === "Recurring" && this.state.timeLine === "Weekly") ?
                                    <Grid item xs={3}>
                                        <Typography>
                                            Select Days
                                        </Typography>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <Select
                                                labelId="weeklyDays"
                                                multiple
                                                value={this.state.weekDays}
                                                name="weekDays"
                                                onChange={this.handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                            >
                                                {this.state.daysOfWeek.map((day) => (
                                                    <MenuItem key={day} value={day} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.weekDays.indexOf(day) > -1} />
                                                        <ListItemText primary={day} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {(this.state.scheduleType === "Recurring" && this.state.timeLine === "Monthly") ?
                                    <Grid item xs={3}>
                                        <Typography>
                                            Select Days
                                        </Typography>
                                        <FormControl variant="outlined" fullWidth className={classes.formControl} >
                                            <Select
                                                labelId="monthlyDays"
                                                multiple
                                                value={this.state.monthDays}
                                                name="monthDays"
                                                onChange={this.handleChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(',')}
                                                MenuProps={MenuProps}
                                            >
                                                {this.state.daysOfMonth.map((day) => (
                                                    <MenuItem key={day} value={day} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.monthDays.indexOf(day) > -1} />
                                                        <ListItemText primary={day} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    : null}
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3} style={{ padding: "0px 20px" }} >
                                        <Typography variant="subtitle2" gutterBottom> Time </Typography>
                                        <TextField
                                            name="scheduledTime"
                                            type="time"
                                            fullWidth
                                            margin="dense"
                                            variant="filled"
                                            onChange={this.handleChange}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            inputProps={{
                                                step: 300 // 5 min
                                            }}
                                            error={this.state.error.scheduledTime}
                                            helperText={this.state.error.scheduledTime}
                                        />
                                    </Grid>
                                    : null
                                }
                                {(this.state.scheduleType === "Recurring") && (this.state.timeLine === "Monthly" || this.state.timeLine === "Weekly") ?
                                    <Grid item xs={3}>
                                    </Grid>
                                    : null
                                }
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3} style={{ padding: "0px 20px" }}>
                                        <Typography variant="subtitle2" gutterBottom>Start Date</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="recurringStartDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            error={this.state.error.recurringStartDate}
                                            helperText={this.state.error.recurringStartDate}
                                            value={this.state.recurringStartDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                                {this.state.scheduleType === "Recurring" ?
                                    <Grid item xs={3} style={{ padding: "0px 20px" }}>
                                        <Typography variant="subtitle2" gutterBottom>End Date</Typography>
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            name="recurringEndDate"
                                            type="date"
                                            className={classes.textField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            error={this.state.error.recurringEndDate}
                                            helperText={this.state.error.recurringEndDate}
                                            value={this.state.recurringEndDate}
                                            onChange={this.handleChange}
                                        />
                                    </Grid> : null
                                }
                                
                            </Grid>
                            


                            <Grid item xs={8}></Grid>
                            <Grid item xs={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.resetFilter}
                                >
                                    Reset
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    onClick={this.fetchConsumers}
                                >
                                    Preview
                                </Button>
                            </Grid>
                            <Grid item xs={1}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    margin="normal"
                                    color="primary"
                                    onClick={this.sendPushNotification}
                                >
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container className={classes.container}>
                        {
                            this.props.consumerList && this.props.consumerList.data ?
                                <UsersListTable
                                    data={this.props.consumerList}
                                    // total={this.props.consumerList.total}
                                    businessFilter={this.state.businessFilterError}
                                    distance={this.state.distance}
                                    order={this.state.order === 1 ? 'asc' : 'desc'}
                                    orderBy={this.state.orderBy}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    currentStatus={this.state.currentStatus}
                                    setOrder={this.setOrder}
                                    setOrderBy={this.setOrderBy}
                                    setPage={this.setPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    history={this.props.history}
                                />
                                : null
                        }
                    </Container>
                    <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <Container className={classes.container} style={{ paddingBottom: '0px' }}>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Search </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails onKeyDown={this.enterPressed}>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <TextField fullWidth margin="dense" name="business" label="Business Name /ID" value={this.state.business} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField fullWidth margin="dense" name="serviceId" label="Service ID(s)(comma separated)" value={this.state.serviceId} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField fullWidth margin="dense" name="notificationTitle" label="Notification Title" value={this.state.notificationTitle} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField fullWidth margin="dense" name="filternotificationName" label="Notification Name" value={this.state.filternotificationName} variant="outlined" onChange={this.handleFilterChange} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <DateRangePicker
                                            ranges={this.state.ranges}
                                            onApply={this.handleDateRangeChange}
                                            maxSpan={{ "months": 12 }}
                                            maxDate={moment()}
                                            showDropdowns
                                        >
                                            <TextField fullWidth margin="dense" name="createdAt" label="Notification Push On" value={this.state.dateRange} variant="outlined" />
                                        </DateRangePicker>
                                    </Grid>
                                    <Grid item xs={12} className='row' >
                                        <Grid item xs={6} style={{ marginTop: 30 }}>
                                            <RadioGroup row aria-label="position" name="scheduleTypeFilter" onChange={this.handleFilterChange} value={this.state.scheduleTypeFilter}>
                                                <FormControlLabel value="" control={<Radio color="primary" />} label="All" size="small" />
                                                <FormControlLabel value="Once-Now" control={<Radio color="primary" />} label="Once-Now" size="small" />
                                                <FormControlLabel value="Once-Schedule" control={<Radio color="primary" />} label="Once-Schedule" size="small" />
                                                <FormControlLabel value="Recurring" control={<Radio color="primary" />} label="Recurring" size="small" />
                                            </RadioGroup>
                                        </Grid>

                                        <Grid className='col-2' item xs={3} >

                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                name="filterStartDate"
                                                label="Start Date"
                                                type="date"
                                                variant="outlined"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}

                                                // error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()) )) ? "Pease Select Valid scheduled Date" : null}
                                                // helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                                value={this.state.filterStartDate}
                                                onChange={this.handleChange}
                                            />
                                        </Grid>

                                        {this.state.error.fromDate ?
                                            <helperText style={{ color: "red" }}>Required </helperText> : null}
                                        <Grid className='col-2' item xs={3}>
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                name="filterEndDate"
                                                label="End Date"
                                                type="date"
                                                className={classes.textField}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"

                                                // error={(this.state.scheduledDate && moment().isAfter(this.state.scheduledDate && moment(this.state.scheduledDate).isAfter(moment()) )) ? "Pease Select Valid scheduled Date" : null}
                                                // helperText={(this.state.scheduledDate && (moment().isAfter(this.state.scheduledDate) && moment(this.state.scheduledDate).isAfter(moment()))) ? "Pease Select Valid scheduled Date" : null}
                                                value={this.state.filterEndDate}
                                                onChange={this.handleChange}
                                            />
                                            {this.state.error.toDate ?
                                                <helperText style={{ color: "red" }}>Required </helperText> : null}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={8} ></Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            onClick={this.resetFilter}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            margin="normal"
                                            color="primary"
                                            onClick={this.searchPushNotification}
                                        >
                                            Search
                                        </Button>
                                    </Grid>
                                </Grid>

                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Container>
                    <Container className={classes.container}>
                        {this.props.business && this.props.business.pushNotifications && this.props.business.pushNotifications.data && this.props.business.pushNotifications.data.length > 0 ?
                            <ListAllTable
                                data={this.props.business.pushNotifications.data}
                                total={this.props.business.pushNotifications.total}
                                consumerData={this.props.business.pushNotificationsConsumerData}
                                excelFilePushNotificationId={this.state.excelFilePushNotificationId}
                                exportToexcel={this.props.business.pushNotifications.exportToexcel}
                                order={this.state.order === 1 ? 'asc' : 'desc'}
                                orderBy={this.state.orderBy}
                                page={this.state.pageForListAll}
                                rowsPerPage={this.state.rowsPerPageForListAll}
                                setOrder={this.setOrder}
                                setOrderBy={this.setOrderBy}
                                setPage={this.setPageForListAll}
                                setRowsPerPage={this.setRowsPerPageForListAll}
                                fetchNotificationWithExcelFile={this.fetchNotificationWithExcelFile}
                                showDownloadIcon={this.state.preparexportToExcel}
                                fetchConsumerDetails={this.fetchConsumerDetails}
                                editNotification={this.openpushNotificationModal}
                                deleteNotification={this.deletePushNotificationModalOpen}
                            /> : null
                        }
                    </Container>
                    <Dialog open={this.state.modalDeleteOpen} onClose={this.handleDeleteClose}>
                        <DialogTitle>Delete Notification</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Do you want to delete Push Notification?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                            <Button onClick={() => this.deletePushNotification()} color="primary" autoFocus> Yes </Button>
                        </DialogActions>
                    </Dialog>
                    <EditPushNotification
                        open={this.state.pushNotificationModalOpen}
                        onClose={this.closepushNotificationModal}
                        data={this.state.pushNotificationData}
                        saveNotification={this.saveNotification}
                    />
                    <Backdrop className={classes.backdrop} open={this.props.isFetching}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </TabPanel>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}>
                    {muiAlert}
                </Snackbar>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        isFetching: state.dashboard.isFetching,
        consumerList: state.dashboard.consumerList,
        business: state.business,
        env: state.auth.env
    }
};

export default connect(mapStateToProps, { fetchEnvs, changeHeaderName, getAllConsumers, requestBusinessStats, pushNotification, emptyConsumersList, getpushNotifications, clearPushNotificationMsg, getPushNotificationConsumerData, updatePushNotification,getAllConsumersForPushNotification, deletePushNotification,clearMsg })(withStyles(styles)(PushNotifications));