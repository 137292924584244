import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from "@material-ui/core/Checkbox";
import Toolbar from "@material-ui/core/Toolbar";
import { connect } from "react-redux";
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import { getAddonsToLinkItem, updateAddonLinkedToItem, requestAddonRecords, requestAddonRecordsFalse } from '../../../../actions/addonsAction';
import { updateGroupLinkedItem } from '../../../../actions/addonGroupAction';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { ReactComponent as UnlinkAllIcon } from '../../../../images/UnlinkAll_Icon.svg';
import { ReactComponent as LinkAllIcon } from '../../../../images/LinkAll_Icon.svg';
import { ReactComponent as UnlinkIcon } from '../../../../images/Unlink_Icon.svg';
import { ReactComponent as LinkIcon } from '../../../../images/Link_Icon.svg';
import { ReactComponent as CollapsedIcon } from '../../../../images/collapsed.svg';
import { ReactComponent as ExpandedIcon } from '../../../../images/expanded.svg';
import { Chip , Box} from '@material-ui/core';




const styles = (theme) => ({
    form: {
        position: "absolute",
        top: 5,
        right: 140,
        left: 140,
        bottom: 5,
        background: "white",
        overflow: "auto",
        '&::-webkit-scrollbar': {
            width: '1px',
            backgroundColor: 'white',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'white',
            borderRadius: '1px',
        },
    },

    header: {
        height: '6%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    CloseIcon: {
        float: "right",
        color: "white",
        padding: "2px",
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    containerText: {
        color: "black",
        fontSize: "20px",
        fontFamily: "serif",
        paddingLeft: "25px",
    },
    bodyText: {
        color: "black",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "5px 0px 0px 0px",
    },
    searchHeader: {
        color: "black",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "0px 0px 0px 0px",
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        paddingLeft: "1px",
    },
    table: {
        border: "1px"
    },
    bodyContainerTop: {
        padding: "0px 0px 10px 0px",
        backgroundColor: '#EEEEEE'
    },
    paper: {
        border: "1px solid #dcdcdc"
    },

    footer: {
        height: '10%',
        float: "right",
        paddingTop: "5px",
        paddingBottom: "5px",
        paddinRight: "55px"

    },

    defaultTab: {
        width: '475px',
        marginRight: '5px',
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
        fontFamily: 'initial',
        fontSize: '17px',
        color : 'black'
    },
    tab1: {
        width: '475px',
        marginRight: '5px',
        backgroundColor: '#FFF1F1',
        border: '2px solid #ffcfcf',
        borderRadius: '5px',
        fontFamily: 'initial',
        fontSize: '17px',
        color: 'black'
    },
    tab2: {
        width: '475px',
        marginLeft: '5px',
        backgroundColor: '#D3F5F8',
        border: '2px solid #abeaef',
        borderRadius: '5px',
        fontFamily: 'initial',
        fontSize: '17px',
        color : 'black'
    },
    tabContainer: {
        padding: '20px 20px',
        fontSize: '17px'
    },

    flexBox: {
        display: 'flex'
    },
    divB :{
        marginTop : '5px',
        marginRight : '10px'
    },
    divC:{
       minWidth : '350px',
       marginRight : '10px'
    },
    divD:{
       minWidth : '130px',
       marginRight : '10px'
    },
    divE:{
       minWidth : '130px',
       marginRight : '10px'
    },
    linkedGroupTable:{
        backgroundColor :'#FFF1F1',
        margin :'15px 0px'
    },
    dynamicBox: {
        height: 'fit-content',
        width: '100%',
        display: "flex", flexWrap: "wrap"
    },
    chipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#FFB300'
    },
    dynamicChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#15B5D8'
    },
    hiddenItemChipBody: {
        margin: '4px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        backgroundColor: '#CCCCCC'
    },
    whiteScale :{
        backgroundColor : '#fff'
    }


});
const useStyles = makeStyles((theme) => ({
    form: {
        position: "absolute",
        top: 100,
        right: 150,
        left: 150,
        bottom: 100,
        background: "white",
        overflow: "auto"
    },

    header: {
        height: '6%',
        margin: '0px 0px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
    },
    CloseIcon: {
        float: "right",
        color: "white",
        padding: "2px",
    },
    headerText: {
        color: "white",
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    topcontainer: {
        position: "relative",
        top: 0,
        right: 0,
        left: 0,
        bottom: "50%",
    },

    bottomcontainer: {
        position: "relative",
        top: "50%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    bottomcontainerDynamic: {
        position: "absolute",
        top: "40%",
        right: 0,
        left: 0,
        bottom: 0,
        backgroundColor: "F2F2F2"
    },
    addonHeader: {
        margin: '0px 0px',
        width: '100%',
        fontSize: "20px",
        fontFamily: "serif",
        padding: "1px",
    },
    table: {
        border: "1px"
    },
    cellPadding: {
        padding: "0px 0px 0px 16px"
    },
    paper: {
        border: "1px solid #dcdcdc"
    },

    tableBottomBorder: {
        borderBottom: "none",
        minWidth: 3,
        padding: "0px 5px 0px 5px"
    },
    itemTable: {
        border: '1px solid linkedAddons #D3F5F8',
    },
 


}));


export function SearchedAddonToolbar(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 790px" }}>
                    Total Addons Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function SelectedAddonToolbar(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 765px" }}>
                    Selected Addons Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function EnhancedTableHeadItem(props) {

    return (
        <TableHead style={{ backgroundColor: "#EEEEEE" }}>
            <TableRow>

                <TableCell align="left"></TableCell>
                <TableCell align="left">Item Id</TableCell>
                <TableCell align="left">Item Name</TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left"></TableCell>
                <TableCell align="left">Cost</TableCell>
                <TableCell align="left">Quantity</TableCell>
                <TableCell align="left">Unit</TableCell>
                <TableCell align="left">Item Tag</TableCell>
                <TableCell align="left">Head & SubHeads</TableCell>


            </TableRow>
        </TableHead>
    );
}
export function EnhancedTableHeadAddon(props) {


    return (
        <TableHead style={{ backgroundColor: "#D3F5F8" }}>
            <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='left'></TableCell>
                <TableCell align='left'>Addon Id</TableCell>
                <TableCell align='left'>Addon Name</TableCell>
                <TableCell align='left'>Food Type</TableCell>
                <TableCell align='center'>Cost</TableCell>
                <TableCell align='left'>Unit</TableCell>
                <TableCell align='center'>Quantity</TableCell>
                <TableCell align="left">Linked Items</TableCell>
            </TableRow>
        </TableHead>
    );
}
export function TableHeadSearchedAddon(props) {


    return (
        <TableHead style={{ backgroundColor: "#D3F5F8" }}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell align='left'>Addon Id</TableCell>
                <TableCell align='left'>Addon Name</TableCell>
                <TableCell align='left'>Food Type</TableCell>
                <TableCell align='center'>Cost</TableCell>
                <TableCell align='left'>Unit</TableCell>
                <TableCell align='center'>Quantity</TableCell>
                <TableCell align='left'><Button onClick={() => props.handleLinkAll()}><LinkAllIcon></LinkAllIcon></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function TableHeadSelectedAddon(props) {


    return (
        <TableHead style={{ backgroundColor: "#D3F5F8" }}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell align='left'>Addon Id</TableCell>
                <TableCell align='left'>Addon Name</TableCell>
                <TableCell align='left'>Food Type</TableCell>
                <TableCell align='center'>Cost</TableCell>
                <TableCell align='left'>Unit</TableCell>
                <TableCell align='center'>Quantity</TableCell>
                <TableCell align='left'><Button onClick={() => props.handleUnlinkAll()}><UnlinkAllIcon /></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function ItemTable(props) {
    const classes = useStyles();

    return (
        <div>
            <Container>
                <div className={classes.paper}>
                    <TableContainer>
                        <Table
                            className={classes.itemTable}
                            size="small"
                            aria-label="a dense table"
                        >
                            <EnhancedTableHeadItem
                            />
                            <TableBody>
                                {
                                    props.itemRecord && props.itemRecord.map((row, index) => {
                                        let itemTags = row.mustTry === true ? "Must Try" : (row.newArrival === true ? "New" : (row.bestSeller ? "Best Seller" : "NA"))

                                        return (
                                            <>
                                                <TableRow>
                                                    <TableCell align="left" className={classes.cellPadding}>
                                                        {row.photos && row.photos[0] && row.photos[0].path ?
                                                            <Avatar src={row.photos[0].path} variant="square" style={{ padding: "0px" }}></Avatar>
                                                            : <Avatar variant="square" src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                    </TableCell>
                                                    <TableCell align="left" className={classes.cellPadding}>{row.serviceId ? row.serviceId : "NA"}</TableCell>
                                                    <TableCell align="left" colSpan={4} className={classes.cellPadding}>{row.service ? row.service : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.cellPadding}>{row.cost?.fixedCost ? row.cost?.fixedCost : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.cellPadding}>{row.cost?.quantity ? row.cost?.quantity : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.cellPadding}>{row.cost?.unit ? row.cost?.unit : "NA"}</TableCell>
                                                    <TableCell align="left" className={classes.cellPadding} >{itemTags}</TableCell>
                                                    {
                                                        row.heads && row.heads.length > 0 ?
                                                            <TableCell align="left" className={classes.tableCellSmall}>
                                                                <Table
                                                                    aria-labelledby="tableTitle"
                                                                    size="small"
                                                                    aria-label="enhanced table">
                                                                    <TableHead>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {row.heads.map((head, i) => {
                                                                            // console.log("head ====> ", head);
                                                                            let subH = row.subHeads && row.subHeads.length > 0 && row.subHeads.find(sub => sub !== null && sub !== undefined && sub.parentId === head._id);
                                                                            // console.log("subH ====> ", subH);
                                                                            if (!subH) {
                                                                                subH = {
                                                                                    name: 'NA'
                                                                                }
                                                                            }
                                                                            return (<TableRow key={i}>
                                                                                <TableCell align="left" className={classes.tableBottomBorder}>{head.name}</TableCell>
                                                                                <TableCell align="left" className={classes.tableBottomBorder}>{subH.name}</TableCell>
                                                                            </TableRow>)
                                                                        }
                                                                        )}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell> : <TableCell align="left">
                                                                <Table>
                                                                    <TableHead>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                                            <TableCell align="left" className={classes.tableBottomBorder} >NA</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>}
                                                </TableRow>
                                            </>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>
        </div>
    )
}
export function SearchedAddonTable(props) {
    const classes = useStyles();



    return (
        <Container style={{ padding: '0px' }}>
            {props.fetchedAddonRecords && props.fetchedAddonRecords.length ?
                <Container>
                    <SearchedAddonToolbar totalCount={props.fetchedAddonRecords.length} />
                    <Paper className={classes.paper}>

                        <TableContainer>

                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >

                                <TableHeadSearchedAddon handleLinkAll={props.handleLinkAll} />
                                <TableBody>
                                    {props.fetchedAddonRecords.length ? props.fetchedAddonRecords.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left" style={{ padding: "0px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square" style={{ height: '30px', width: '30px' }}></Avatar>
                                                        : <Avatar variant="square" style={{ height: '30px', width: '30px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding} style={{ minWidth: '250px' }}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.foodType ? row.foodType : "--"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost?.fixedCost ? row.cost?.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 20px' }}>
                                                    {row.cost?.quantity ? row.cost?.quantity : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 18px' }}><Button onClick={() => props.handleLinkItem(row)}><LinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </Container>
    )


}
export function SelectedAddonTable(props) {
    const classes = useStyles();



    return (
        <Container style={{ padding: '0px' }}>
            {props.selectedAddonToLinkItem && props.selectedAddonToLinkItem.length ?
                <Container>
                    <SelectedAddonToolbar totalCount={props.selectedAddonToLinkItem.length} />
                    <Paper className={classes.paper}>

                        <TableContainer>

                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >

                                <TableHeadSelectedAddon handleUnlinkAll={props.handleUnlinkAll} />
                                <TableBody>
                                    {props.selectedAddonToLinkItem.length ? props.selectedAddonToLinkItem.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left" style={{ padding: "0px" }}>
                                                    {row.photos && row.photos[0] && row.photos[0].path ?
                                                        <Avatar src={row.photos[0].path} variant="square" style={{ height: '30px', width: '30px' }}></Avatar>
                                                        : <Avatar variant="square" style={{ height: '30px', width: '30px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.serviceId ? row.serviceId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding} style={{ minWidth: '250px' }}>
                                                    {row.service ? row.service : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.foodType ? row.foodType : "--"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost?.fixedCost ? row.cost?.fixedCost : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.cost?.unit ? row.cost?.unit : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 20px' }}>
                                                    {row.cost?.quantity ? row.cost?.quantity : "NA"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 18px' }}><Button onClick={() => props.handleUnLinkItem(row._id)}><UnlinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : null}
        </Container>
    )


}


export function SelectedGroupToolbar(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 765px" }}>
                    Selected Groups Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}

export function SearchedGroupToolbar(props) {

    const { totalCount } = props;


    return (
        <Toolbar style={{ padding: "0px" }}>
            <Grid>
                <Typography style={{ padding: "0px 0px 0px 790px" }}>
                    Total Groups Found({totalCount})
                </Typography>
            </Grid>
        </Toolbar>
    );
}
export function TableHeadSearchedGroup(props) {


    return (
        <TableHead style={{ backgroundColor: '#FFF1F1' }}>
            <TableRow>
                <TableCell align='left'>Addon Group Id</TableCell>
                <TableCell align='left'>Addon Group Name</TableCell>
                <TableCell align='left'>Min Selection</TableCell>
                <TableCell align='left'>Max Selection</TableCell>
                <TableCell align='left'>Selection Type</TableCell>
                <TableCell align='left'>Is Locked</TableCell>
                <TableCell align='left'><Button onClick={() => props.handleLinkAllGroup()}><LinkAllIcon></LinkAllIcon></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}
export function SearchedGroupTable(props) {
    const classes = useStyles();



    return (
        <Container style={{ padding: '0px' }}>
            {props.fetchedGroupRecords && props.fetchedGroupRecords.length ?
                <Container>
                    <SearchedGroupToolbar totalCount={props.fetchedGroupRecords.length} />
                    <Paper className={classes.paper}>

                        <TableContainer>

                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >

                                <TableHeadSearchedGroup handleLinkAllGroup={props.handleLinkAllGroup} />
                                <TableBody>
                                    {props.fetchedGroupRecords.length ? props.fetchedGroupRecords.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.addOnGroupId ? row.addOnGroupId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding} style={{ minWidth: '300px' }}>
                                                    {row.addOnGroupName ? row.addOnGroupName : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.selectionType ? row.selectionType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.minSelection && row.minSelection > 0 ? row.minSelection  : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                {row.maxSelection && row.maxSelection > 0 ? row.maxSelection  : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 20px' }}>
                                                    {row.isLocked && row.isLocked === true ? 'Yes' : "No"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 18px' }}><Button onClick={() => props.handleLinkGroup(row)}><LinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container> : props.noGroupRecordsFound === true ? <Typography style={{ margin: "30px 320px" }}>No Results Found </Typography> : null}
        </Container>
    )


}


export function TableHeadSelectedGroup(props) {


    return (
        <TableHead style={{ backgroundColor: '#FFF1F1' }}>
            <TableRow>
                <TableCell align='left'>Addon Group Id</TableCell>
                <TableCell align='left'>Addon Group Name</TableCell>
                <TableCell align='left'>Min Selection</TableCell>
                <TableCell align='left'>Max Selection</TableCell>
                <TableCell align='left'>Selection Type</TableCell>
                <TableCell align='left'>Is Locked</TableCell>
                <TableCell align='left'><Button onClick={() => props.handleUnlinkAllGroup()}><UnlinkAllIcon /></Button></TableCell>
            </TableRow>
        </TableHead>
    );
}

export function SelectedGroupTable(props) {
    const classes = useStyles();



    return (
        <Container style={{ padding: '0px' }}>
            {props.selectedGroupsToLinkItem && props.selectedGroupsToLinkItem.length ?
                <Container>
                    <SelectedGroupToolbar totalCount={props.selectedGroupsToLinkItem.length} />
                    <Paper className={classes.paper}>

                        <TableContainer>

                            <Table
                                className={classes.table}
                                size="small"
                                aria-label="a dense table"
                            >

                                <TableHeadSelectedGroup handleUnlinkAllGroup={props.handleUnlinkAllGroup} />
                                <TableBody>
                                    {props.selectedGroupsToLinkItem.length ? props.selectedGroupsToLinkItem.map((row, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                className={classes.tableRow}
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.addOnGroupId ? row.addOnGroupId : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding} style={{ minWidth: '300px' }}>
                                                    {row.addOnGroupName ? row.addOnGroupName : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.selectionType ? row.selectionType : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                    {row.minSelection && row.minSelection > 0 ? row.minSelection  : "NA"}
                                                </TableCell>
                                                <TableCell align='left' className={classes.cellPadding}>
                                                {row.maxSelection && row.maxSelection > 0 ? row.maxSelection  : "NA"}
                                                </TableCell>
                                                <TableCell align='left' style={{ padding: '0px 0px 0px 20px' }}>
                                                    {row.isLocked && row.isLocked === true ? 'Yes' : "No"}
                                                </TableCell>
                                                <TableCell style={{ padding: '0px 0px 0px 18px' }}><Button onClick={() => props.handleUnLinkGroup(row._id)}><UnlinkIcon /></Button></TableCell>
                                            </TableRow>
                                        )
                                    }) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Container>  : null}
        </Container>
    )


}

class UpdateLinkedAddon extends React.Component {
    constructor() {
        super();
        this.state = {

            stateCheck: 0,
            addonDetail: null,
            searchedAddonId: "",
            fetchedAddonRecords: [],
            selectedAddonToLinkItem: [],
            tabValue: 0,
            mainAddonRecords: [],
            tableIndex: -1,
            addonGroupId : '',
            fetchedGroupRecords : [],
            selectedGroupsToLinkItem : [],
            linkedAddOnGroups : [],
            noGroupRecordsFound : false

        };

    }

    componentDidMount() {
        this.setState({ addonDetail: this.props.existingAddons , linkedAddOnGroups :  this.props.itemArray[0].linkedAddOnGroups })
    };

    resetSearchedAddon = async () => {
        this.setState(
            {
                searchedAddonId: "",
                fetchedAddonRecords: [],
            });
        await this.props.getAddonsToLinkItem()
    };


    handleAddonSelectChange = (event, _id) => {
        let existingAddons = this.props.existingAddons;
        if (event.target.checked) {
            existingAddons.find(value => value._id === _id).flag = event.target.checked;
            this.setState({ stateCheck: Math.random(), addonDetail: existingAddons })

        } else {

            existingAddons.find(value => value._id === _id).flag = false;
            this.setState({ stateCheck: Math.random(), addonDetail: existingAddons })
        }

    }

    handleFilterChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onAddonSearch = async () => {
        let existingAddons = this.props.existingAddons;
        let IdArray = existingAddons.map((element) => element._id)

        if (this.state.searchedAddonId) {
            let payload = {
                businessId: this.props.businessId,
                addonId: this.state.searchedAddonId.trim(),
                existingAddonId: IdArray,
                isSearched: true,
            }
            await this.props.getAddonsToLinkItem(payload)
            this.setState({ stateCheck: Math.random(), fetchedAddonRecords: this.props.addons.addonRecordstoLink.data  , mainAddonRecords : this.props.addons.addonRecordstoLink.data})
        }

    };


    onLinkAddonToItemSubmit = async () => {
        let addonDetail = this.state.addonDetail
        let addonToLink = this.state.selectedAddonToLinkItem
        let addonToLinkItem = addonToLink.map(element => element._id)
        let itemId = this.props.itemArray[0]._id
        let addonToUnlink = []
        if (addonDetail && addonDetail.length) {
            for (let index = 0; index < addonDetail.length; index++) {
                if (addonDetail[index].flag === false) {
                    addonToUnlink.push(addonDetail[index]._id)
                }
            }
        }
        if (addonToUnlink.length || addonToLinkItem.length) {
            await this.props.requestAddonRecords()
            let payload = {
                id: itemId,
                addonIdToUnlink: addonToUnlink,
                addonIdToLink: addonToLinkItem
            }
            await this.props.updateAddonLinkedToItem(payload)
        }
        await this.props.handleEditAddonClose()
        await this.props.fetchItemsLinkedWithAddons()
        await this.props.requestAddonRecordsFalse()
    }



    handleTabChange = (event, newValue) => {
        this.setState({ tabValue: newValue })
    };


    handleLinkItem = async (item) => {
        let tempArray = [];
        if (item) {
            tempArray.push(...this.state.selectedAddonToLinkItem, item)
        };
        let selectedArray = new Set(tempArray.map(obj => obj._id))
        let unSelectedArray = this.state.fetchedAddonRecords.filter(obj => !selectedArray.has(obj._id));
        let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
        this.setState({ fetchedAddonRecords: unSelectedArray, selectedAddonToLinkItem: uniqueSelectedItems })
    }

    handleUnLinkItem = async (_id) => {
        let unlinkLinkedItem = _id
        let fetchedAddonRecords = this.state.mainAddonRecords
        let selectedAddonToLinkItem = this.state.selectedAddonToLinkItem.filter(obj => obj._id !== unlinkLinkedItem.toString());
        let itemIds = new Set(selectedAddonToLinkItem.map(obj => obj._id))
        let unSelectedArray = fetchedAddonRecords.filter(obj => !itemIds.has(obj._id));
        this.setState({ fetchedAddonRecords: unSelectedArray, selectedAddonToLinkItem: selectedAddonToLinkItem })
    }

    handleUnlinkAll = async () => {

        let fetchedAddonRecords = this.state.mainAddonRecords
        this.setState({ fetchedAddonRecords: fetchedAddonRecords, selectedAddonToLinkItem: [] });

    }

    handleLinkAll = async () => {
        if (this.state.fetchedAddonRecords.length) {
            let tempArray = [];
            tempArray.push(...this.state.selectedAddonToLinkItem, ...this.state.fetchedAddonRecords)
            let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
            this.setState({ selectedAddonToLinkItem: uniqueSelectedItems, fetchedAddonRecords: [] })
        }
    }
    handleCollapsible = (value) => {
        this.setState({ tableIndex: value })
    }

    onGroupSearch= async()=>{
        let linkedAddOnGroups = this.props.itemArray[0].linkedAddOnGroups
        let linkedIds =  linkedAddOnGroups && linkedAddOnGroups.length ? linkedAddOnGroups.map((element) => element._id) : []
    
        if (this.state.addonGroupId) {
            let payload = {
                businessId: this.props.businessId,
                addonGroupId: this.state.addonGroupId.trim(),
                existingGroupIds: linkedIds
            }
            await this.props.updateGroupLinkedItem(payload)
            this.setState({ stateCheck: Math.random(), fetchedGroupRecords: this.props.addonGroup.groupsUpdateItem.data, mainGroupRecords: this.props.addonGroup.groupsUpdateItem.data, noGroupRecordsFound : this.props.addonGroup.groupsUpdateItem.noGroupRecordsFound })
        }

    }

    resetSearchedGroup = async () => {
        this.setState(
            {
                 addonGroupId: "",
                 fetchedGroupRecords: [],
                 noGroupRecordsFound : false
            });
    };

    handleLinkGroup = async (item) => {
        let tempArray = [];
        if (item) {
            tempArray.push(...this.state.selectedGroupsToLinkItem, item)
        };
        let selectedArray = new Set(tempArray.map(obj => obj._id))
        let unSelectedArray = this.state.fetchedGroupRecords.filter(obj => !selectedArray.has(obj._id));
        let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
        this.setState({ fetchedGroupRecords: unSelectedArray, selectedGroupsToLinkItem: uniqueSelectedItems })
    }

    handleUnLinkGroup = async (_id) => {
        let unlinkLinkedItem = _id
        let fetchedGroupRecords = this.state.mainGroupRecords
        let selectedGroupsToLinkItem = this.state.selectedGroupsToLinkItem.filter(obj => obj._id !== unlinkLinkedItem.toString());
        let itemIds = new Set(selectedGroupsToLinkItem.map(obj => obj._id))
        let unSelectedArray = fetchedGroupRecords.filter(obj => !itemIds.has(obj._id));
        this.setState({ fetchedGroupRecords: unSelectedArray, selectedGroupsToLinkItem: selectedGroupsToLinkItem })
    }

    handleUnlinkAllGroup = async () => {

        let fetchedGroupRecords = this.state.mainGroupRecords
        this.setState({ fetchedGroupRecords: fetchedGroupRecords, selectedGroupsToLinkItem: [] });

    }

    handleLinkAllGroup = async () => {
        if (this.state.fetchedGroupRecords.length) {
            let tempArray = [];
            tempArray.push(...this.state.selectedGroupsToLinkItem, ...this.state.fetchedGroupRecords)
            let uniqueSelectedItems = Array.from(new Map(tempArray.map(element => [element._id, element])).values());
            this.setState({ selectedGroupsToLinkItem: uniqueSelectedItems, fetchedGroupRecords: [] })
        }
    }

    handleLinkedChange =(event ,_id)=>{
        const linkedAddOnGroups = this.state.linkedAddOnGroups;
        if (event.target.checked) {
            linkedAddOnGroups.find(value => value._id === _id).isLinked = event.target.checked;
            this.setState({ stateCheck: Math.random(), linkedAddOnGroups: linkedAddOnGroups })

        } else {

            linkedAddOnGroups.find(value => value._id === _id).isLinked = false;
            this.setState({ stateCheck: Math.random(), linkedAddOnGroups: linkedAddOnGroups })
        }  
    }

    onLinkGroupToItemSubmit = async( )=>{
        const linkedAddOnGroups    =  this.state.linkedAddOnGroups
        const selectedGroupsToLinkItem  =  this.state.selectedGroupsToLinkItem
        let selectedGroups = selectedGroupsToLinkItem.map(element=> element._id);
        let unLinkGroupIds = (linkedAddOnGroups && linkedAddOnGroups.length && linkedAddOnGroups.filter(element=> element.isLinked === false ).map(value=> value._id))
        let itemId = this.props.itemArray[0]._id
        if ((selectedGroupsToLinkItem && selectedGroupsToLinkItem.length) || (unLinkGroupIds && unLinkGroupIds.length)) {
            await this.props.requestAddonRecords()
            let payload = {
                id: itemId,
                linkGroupIds   : selectedGroups,
                unLinkGroupIds : unLinkGroupIds
            }
            await this.props.updateAddonLinkedToItem(payload)
        }
        await this.props.handleEditAddonClose()
        await this.props.fetchItemsLinkedWithAddons()
        await this.props.requestAddonRecordsFalse()
    }



    render() {
        const { classes } = this.props;
        const { addonDetail , linkedAddOnGroups  } =  this.state
     

        return (
            <div className={classes.form}>
                <Grid container className={classes.header} spacing={1}>
                    <Grid item xs={8}>
                        <Typography variant="h5" gutterBottom className={classes.headerText}>
                            Edit Linked Addon Group/s & Addons
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <CloseIcon className={classes.CloseIcon} onClick={() => { this.props.handleEditAddonClose() }} />
                    </Grid>
                </Grid>
                <Container className={classes.bodyContainerTop}>
                    <Container style={{ padding: "0px" }}>
                        <Typography className={classes.containerText}>
                            Item Details
                        </Typography>
                        <ItemTable
                            itemRecord={this.props.itemArray}
                        />
                    </Container>
                </Container>


                <div className={classes.tabContainer}>
                    <Paper square>
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                            variant="fullWidth"
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab
                                className={this.state.tabValue === 0 ? classes.tab1 : classes.defaultTab}
                                label={`Linked Addon Group/s ${linkedAddOnGroups && linkedAddOnGroups.length ? "" : "(0)" }${linkedAddOnGroups && linkedAddOnGroups.length ? `(${linkedAddOnGroups.length})` : ""}`}
                            />

                            <Tab
                                className={this.state.tabValue === 1 ? classes.tab2 : classes.defaultTab}
                                label={`Linked Addon/s ${addonDetail && addonDetail.length ? "" : "(0)" }${addonDetail && addonDetail.length ? `(${addonDetail.length})` : ""}`}
                            />
                        </Tabs>
                    </Paper>
                    {this.state.tabValue === 0 && <div>

                        <Paper>
                        {linkedAddOnGroups && linkedAddOnGroups.length ? 
                            <Table size="small" aria-label="a dense table" className={classes.linkedGroupTable}>
                                <TableBody>
                                    {linkedAddOnGroups && linkedAddOnGroups.length ? linkedAddOnGroups.map((row, index) => (
                                        <React.Fragment key={row._id}>
                                            <TableRow className={classes.addonGroupTable}>
                                                <TableCell className={classes.flexBox}>
                                                    <div className={classes.divA}>
                                                        <Checkbox checked={row.isLinked} onChange={(event)=>this.handleLinkedChange(event , row._id)} />
                                                    </div>
                                                    <div className={classes.divB}>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            onClick={() => this.handleCollapsible(this.state.tableIndex === index ? -1 : index)}
                                                        >
                                                            {this.state.tableIndex === index ? <ExpandedIcon /> : <CollapsedIcon />}
                                                        </IconButton>
                                                    </div>
                                                    <div className={classes.divC}>
                                                        <Grid container direction="column">
                                                        <Grid item>Group Name:<b> {row.addOnGroupName}</b></Grid>
                                                        <Grid item> Group ID:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{row.addOnGroupId}</b></Grid>
                                                        </Grid>
                                                    </div>

                                                    <div className={classes.divD}>
                                                        <Grid container direction="column">
                                                            <Grid item>Min Selection: <b>{row.minSelection === 0 ? 'NA' : row.minSelection}</b> </Grid>
                                                            <Grid item>Max Selection: <b>{row.maxSelection === 0 ? 'NA' : row.maxSelection}</b></Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className={classes.divD}>
                                                        <Grid container direction="column">
                                                            <Grid item>Total Addons: <b>{row.linkedAddOns && row.linkedAddOns.length ? row.linkedAddOns.length : null}</b></Grid>
                                                            <Grid item>Available:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>{row.available}</b></Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className={classes.divE}>
                                                        <Grid container direction="column">
                                                            <Grid item>Selection Type: <b>{row.selectionType}</b></Grid>
                                                        </Grid>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow  className={classes.whiteScale}>
                                                {this.state.tableIndex === index ?

                                                    <Container>
                                                        {row.linkedAddOns ?
                                                            <Box className={classes.dynamicBox}>
                                                                {row.linkedAddOns.map((item, index) => {
                                                                    const text = `${item.service} ${` ${`₹`} ${item.cost.fixedCost}`} (${item.cost.quantity ? item.cost.quantity : 'per'} ${item.cost.unit})`;
                                                                    return (
                                                                        <div style={{ margin: '4px' }}>
                                                                            <Chip
                                                                                label={text}
                                                                                className={(item.isDefault === true && item.isDisplay === true && item.isEnabled === true) ? classes.chipBody : (item.isDefault === true && (item.isDisplay === false || item.isEnabled === false)) ? classes.defaultAndHiddenItem : (item.isDisplay === false || item.isEnabled === false) ? classes.hiddenItemChipBody : classes.dynamicChipBody}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </Box> : null}
                                                    </Container>
                                                    : null }
                                            </TableRow>
                                        </React.Fragment>
                                    )) : <Typography style={{ margin: "0px 460px" }}>No Records Available</Typography>}
                                </TableBody>
                            </Table>   : null }
                        </Paper>
                        
                        <Container style={{ padding: "10px 0px" }}>
                            <SelectedGroupTable
                                selectedGroupsToLinkItem={this.state.selectedGroupsToLinkItem}
                                handleUnLinkGroup={this.handleUnLinkGroup}
                                handleUnlinkAllGroup={this.handleUnlinkAllGroup}
                            />
                        </Container>

                        <div style={{ backgroundColor: '#F5F5F5' }}>
                            <hr></hr>


                            <Container style={{ padding: '0px' }}>
                                <Typography className={classes.searchHeader}>
                                    Link More Addon Group/s
                                </Typography>
                                <Container style={{ display: "flex!important", padding: '0px' }}>
                                    <TextField label="Addon Group Name/ ID" margin="dense" variant="outlined" name="addonGroupId" value={this.state.addonGroupId} onChange={this.handleFilterChange} placeholder="Addon Group Name/ ID" style={{ width: "75%" }} />
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                        onClick={this.onGroupSearch}
                                        color='primary'>
                                        Search
                                    </Button>
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                        onClick={this.resetSearchedGroup}
                                        color='default'>
                                        Reset
                                    </Button>
                                </Container>
                                <Container style={{ padding: "5px 0px" }}>
                                    <SearchedGroupTable
                                        fetchedGroupRecords={this.state.fetchedGroupRecords}
                                        noGroupRecordsFound={this.state.noGroupRecordsFound}
                                        handleLinkGroup={this.handleLinkGroup}
                                        handleLinkAllGroup={this.handleLinkAllGroup}
                                    />
                                </Container>
                            </Container>
                            <hr></hr>

                            <Container className={classes.footer}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    onClick={() => { this.onLinkGroupToItemSubmit() }}
                                    style={{ maxWidth: "70px", float: "right", marginRight: "10px", marginTop: "10px", padding: '5px 50px' }}
                                    color="primary">
                                    SAVE
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="default"
                                    onClick={() => { this.props.handleEditAddonClose() }}
                                    style={{ maxWidth: "70px", float: "right", marginRight: "10px", marginTop: "10px", padding: '5px 50px' }}
                                >
                                    CANCEL
                                </Button>
                            </Container>

                        </div>



                    </div>}
                    {this.state.tabValue === 1 && <div>


                        <Container style={{ padding: "15px 0px 0px 0px" }}>
                            {addonDetail && addonDetail.length ?
                                <Paper className={classes.paper}>
                                    <TableContainer>
                                        <Table size="small" aria-label="a dense table" className={classes.linkedAddons}>
                                            <EnhancedTableHeadAddon />
                                            <TableBody>
                                                {addonDetail && addonDetail.length && addonDetail.map((data, index) => {

                                                    return (
                                                        <TableRow key={data._id} >

                                                            <TableCell style={{ padding: "2px" }} >
                                                                <Checkbox
                                                                    onChange={(event) => { this.handleAddonSelectChange(event, data._id) }}
                                                                    checked={data.flag}
                                                                    aria-checked={data.flag}
                                                                    selected={data.flag}
                                                                />

                                                            </TableCell>
                                                            <TableCell align="left" style={{ padding: "0px" }}>
                                                                {data.photos && data.photos[0] && data.photos[0].path ?
                                                                    <Avatar src={data.photos[0].path} variant="square" style={{ padding: "0px", height: '25px', width: '25px' }}></Avatar>
                                                                    : <Avatar variant="square" style={{ padding: "0px", height: '25px', width: '25px' }} src="https://res.cloudinary.com/xirifytp/image/upload/v1614761858/Marketplace_Business/5f81671c008fdd00115f3524/service-default791449.jpg.jpg"></Avatar>}
                                                            </TableCell>
                                                            <TableCell align="left" className={classes.cellPadding}>{data.serviceId ? data.serviceId : "NA"}</TableCell>
                                                            <TableCell align="left" className={classes.cellPadding} style={{ minWidth: '300px' }}>{data.service ? data.service : "NA"}</TableCell>
                                                            <TableCell align="left" className={classes.cellPadding}>  {data.foodType ? data.foodType : "--"}</TableCell>
                                                            <TableCell align="left" className={classes.cellPadding}>{data.cost?.fixedCost ? data.cost?.fixedCost : "NA"}</TableCell>
                                                            <TableCell align="left"  >{data.cost?.unit ? data.cost?.unit : "NA"}</TableCell>
                                                            <TableCell align="left" className={classes.cellPadding}>{data.cost?.quantity ? data.cost?.quantity : "NA"}</TableCell>
                                                            <TableCell align="left" className={classes.cellPadding} style={{ pointerEvents: 'none' }}>{data.addOnServiceItemParents && data.addOnServiceItemParents.length ?
                                                                <Container style={{ display: 'flex', paddingLeft: '0px' }}>
                                                                    <Typography style={{ padding: "0px 2px", fontSize: "0.875rem", marginRight: '15px' }}>Yes</Typography>
                                                                    <Button style={{ padding: "0px 2px", borderRadius: "15px" }} variant="contained" margin="normal" color="primary">{data.addOnServiceItemParents.length}</Button>
                                                                </Container> :
                                                                <Container style={{ display: 'flex', paddingLeft: '0px' }}><Typography style={{ padding: "0px 2px", fontSize: "0.875rem", marginRight: '15px' }}>No</Typography><Button style={{ padding: "0px 2px", borderRadius: "15px" }} variant="contained" margin="normal" color="default">00</Button></Container>}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper> : null}
                        </Container>

                        <Container style={{ padding: "10px 0px" }}>
                            <SelectedAddonTable
                                selectedAddonToLinkItem={this.state.selectedAddonToLinkItem}
                                handleUnLinkItem={this.handleUnLinkItem}
                                handleUnlinkAll={this.handleUnlinkAll}
                            />
                        </Container>

                        <div style={{ backgroundColor: '#F5F5F5' }}>
                            <hr></hr>


                            <Container style={{ padding: '0px' }}>
                                <Typography className={classes.searchHeader}>
                                    Link More Addon/s
                                </Typography>
                                <Container style={{ display: "flex!important", padding: '0px' }}>
                                    <TextField label="Addon Id | Addon Name" margin="dense" variant="outlined" name="searchedAddonId" value={this.state.searchedAddonId} onChange={this.handleFilterChange} placeholder="Addon Id | Addon Name" style={{ width: "75%" }} />
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                        onClick={this.onAddonSearch}
                                        color='primary'>
                                        Search
                                    </Button>
                                    <Button
                                        variant="contained"
                                        margin="normal"
                                        style={{ maxWidth: "50px", margin: "8px 0px 0px 10px", padding: '5px 50px' }}
                                        onClick={this.resetSearchedAddon}
                                        color='default'>
                                        Reset
                                    </Button>
                                </Container>
                                <Container style={{ padding: "5px 0px" }}>
                                    <SearchedAddonTable
                                        fetchedAddonRecords={this.state.fetchedAddonRecords}
                                        noRecordsFound={this.props.addons.addonRecordstoLink?.noRecordsFound}
                                        handleLinkItem={this.handleLinkItem}
                                        handleLinkAll={this.handleLinkAll}
                                    />
                                </Container>
                            </Container>
                            <hr></hr>

                            <Container className={classes.footer}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    onClick={() => { this.onLinkAddonToItemSubmit() }}
                                    style={{ maxWidth: "70px", float: "right", marginRight: "10px", marginTop: "10px", padding: '5px 50px' }}
                                    color="primary">
                                    SAVE
                                </Button>

                                <Button
                                    type="submit"
                                    variant="contained"
                                    margin="normal"
                                    color="default"
                                    onClick={() => { this.props.handleEditAddonClose() }}
                                    style={{ maxWidth: "70px", float: "right", marginRight: "10px", marginTop: "10px", padding: '5px 50px' }}
                                >
                                    CANCEL
                                </Button>
                            </Container>

                        </div>
                    </div>}
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        addons: state.addons,
        addonGroup: state.addonGroup,
    };
};
export default connect(mapStateToProps, {
    getAddonsToLinkItem, updateAddonLinkedToItem, requestAddonRecords, requestAddonRecordsFalse , updateGroupLinkedItem
})(withStyles(styles)(UpdateLinkedAddon));