import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Login from "./auth/Login";
import Forgot from "./auth/Forgot";
import Dashboard from "./dashboard/Dashboard";
import CategoryTabs from "./CategoriesSpeciality/CategoriesSpeciality";
import ConsumerTagTab from "./ConsumerTag/ConsumerTag.js";
import BusinessTagTab from "./BusinessTag/BusinessTag.js";
import PrivateRoute from "./private-route/PrivateRoute";
import Admin from "./Admin/Admin";
import PendingBusiness from "./Business/PendingBusiness/PendingBusiness";
import ReportedBusiness from "./Business/ReportedBusiness/ReportedBusiness";
import BasicBusinessDetails from "./Business/PendingBusiness/BasicBusinessDetails";
import FullBusinessDetails from "./Business/ApproveRejectBusiness/FullBusinessDetails";
import ApprovedBusiness from "./Business/ApproveRejectBusiness/ApprovedBusiness";
import RejectedBusiness from "./Business/ApproveRejectBusiness/RejectedBusiness";
import DisabledBusiness from "./Business/ApproveRejectBusiness/DisabledBusiness";
import ReportedBusinessDetails from "./Business/ReportedBusiness/ReportedBusinessDetails";
import MobileOTPReport from "./OtpReport/mobileOtpReport.js";
import FAQ from "./FAQ/Faq";
import ServiceRequests from "./AllOrders/AllOrders";
import PrepaymentOrders from "./AllOrders/PrepaymentOrders.js";
import ServiceRequestDetails from "./AllOrders/ServiceRequestDetails";
import Consumers from "./AllConsumers/AllConsumers";
import ConsumerAddressDetails from "./AllConsumers/ConsumerAddressDetails";
import ServiceProviders from "./ServiceProviders/ServiceProviders";
import ServiceProviderDetails from "./ServiceProviders/ServiceProviderDetails";
import Regions from "./Regions/Region";
import RegionDetails from "./Regions/RegionDetails";
import RegionalHeads from "./RegionHead/RegionHead";
import Areas from "./Areas/Area";
import AreaManagers from "./AreaManager/AreaManager";
import Release from "./AppManagement/Releases";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import TopPerformingBusinesses from "./TopPerformingBusinesses/TopPerformingBusinesses";
import Invites from "./Invites/Invites";
import BusinessReports from "./BusinessReports/BusinessReports";
import ReviewReport from "./ReviewReport/reviewReport"
import Coupon from "./Coupon/Coupon"
import '../style/style.css';
import "bootstrap/dist/css/bootstrap.css"
import BusinessPerformanceReport from "./BusinessPerformanceReport/BusinessPerformanceReport";
import MasterOTP from "./AdminMasterOTP/MasterOTP";
import DeliveriesReport from "./DeliveriesReport/DeliveriesReport";
import ActiveBusinessUsersReport from "./ActiveBusinessUserReport/activeBusinessReports";
import PushNotifications from "./PushNotifications/PushNotifications";
import configurations from "./Configurations/configuration";
import onlinePaymentReports from "./PaymentGatewayReport/paymentGateway";
import PaymentSummaryReport from "./PaymentSummaryReport";
import CallIconHitReports from "./PaymentGatewayReport/CallIconHit";
import BusinessOpenCloseReport from "./BusinessOpenCloseReport/BusinessOpenCloseReport";
import ItemsAvailabilityReport from "./itemsAvailabilityReport/itemsAvailabilityReport";
import CronJobReports from "./cronJobReport/cronJobReport";
import masterPin from "./AdminMasterOTP/masterPin";
import appMaintenance from "./AppManagement/AppMaintenance";
import Campaigns from "./Campaigns/Campaigns";
import CampaignReport from "./Campaigns/campaignReport";
import CampaignBusinessReport from './Campaigns/campaignBusinessReport';
import promoCodeReport from "./PromoCodeReport/promoCodeReport";
// import offerCodeReport from "./OfferCodeReport/offerCodeReport";
import CampaignsCouponSettlement from "./Settlement/CampaignsCouponSettlement";
import CampaignsCouponSettlementBusiness from "./Settlement/CampaignsCouponSettlementBusiness";
import BusinessItemTagReport from "./BusinessItemTagReport/businessItemTagReport";
import InAppNotice from "./Notice/InAppNotice";
import Expense from './Expense/Expense'
import VisibleBusinesses from './VisibleBusinesses/VisibleBusinesses'
var xirifyTheme = {
  palette: {
    primary: {
      main: '#14B7DD',
      contrastText: "#fff" //button text white instead of black
    },
    secondary: {
      main: '#76747F'
    },
    success: {
      main: '#8bc34a'
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    button: {
      fontWeight: 200,
      textAlign: 'capitalize',
    },
  },
};

class App extends Component {


  render() {
    var applyTheme = createMuiTheme(xirifyTheme);

    return (
      <ThemeProvider theme={applyTheme}>
        <Router>
          <div className="App">
            <Route exact path="/" component={Login} />
            <Route exact path="/forgot" component={Forgot} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/admin" component={Admin} />
              <PrivateRoute exact path="/pendingBusiness" component={PendingBusiness} />
              <PrivateRoute exact path="/rejectedBusiness" component={RejectedBusiness} />
              <PrivateRoute exact path="/approvedBusiness" component={ApprovedBusiness} />
              <PrivateRoute exact path="/disabledBusiness" component={DisabledBusiness} />
              <PrivateRoute exact path="/PendingBusinessDetails" component={BasicBusinessDetails} />
              <PrivateRoute exact path="/RejectedBusinessDetails" component={BasicBusinessDetails} />
              <PrivateRoute exact path="/ApprovedBusinessDetails" component={FullBusinessDetails} />
              <PrivateRoute exact path="/DisabledBusinessDetails" component={FullBusinessDetails} />
              <PrivateRoute exact path="/reportedbusiness" component={ReportedBusiness} />
              <PrivateRoute exact path="/reportedbusinessdetails" component={ReportedBusinessDetails} />
              <PrivateRoute exact path="/mobileOtpReport" component={MobileOTPReport} />
              <PrivateRoute exact path="/regions" component={Regions} />
              <PrivateRoute exact path="/regions/:regionId" component={RegionDetails} />
              <PrivateRoute exact path="/regionalheads" component={RegionalHeads} />
              <PrivateRoute exact path="/areas" component={Areas} />
              <PrivateRoute exact path="/areamanagers" component={AreaManagers} />
              <PrivateRoute exact path="/categories" component={CategoryTabs} />
              <PrivateRoute exact path="/consumerTag" component={ConsumerTagTab} />
              <PrivateRoute exact path="/businessTag" component={BusinessTagTab} />
              <PrivateRoute exact path="/coupon" component={Coupon} />
              <PrivateRoute exact path="/faq" component={FAQ} />
              <PrivateRoute exact path="/servicerequests" component={ServiceRequests} />
              <PrivateRoute exact path="/servicerequests/getAllPrepaymentOrders" component={PrepaymentOrders} />
              <PrivateRoute exact path="/ReviewReport" component={ReviewReport} />
              <PrivateRoute exact path="/servicerequests/:serviceRequestId" component={ServiceRequestDetails} />
              <PrivateRoute exact path="/consumers" component={Consumers} />
              <PrivateRoute exact path="/consumers/:consumerId" component={ConsumerAddressDetails} />
              <PrivateRoute exact path="/serviceproviders" component={ServiceProviders} />
              <PrivateRoute exact path="/serviceproviders/:serviceProviderId" component={ServiceProviderDetails} />
              <PrivateRoute exact path="/topperformingbusinesses" component={TopPerformingBusinesses} />
              <PrivateRoute exact path="/referrals" component={Invites} />
              <PrivateRoute exact path="/deliveriesreport" component={DeliveriesReport} />
              <PrivateRoute exact path="/businessreports" component={BusinessReports} />
              <PrivateRoute exact path="/onlinePaymentReports" component={onlinePaymentReports} />
              <PrivateRoute exact path="/callIconHitReports" component={CallIconHitReports} />
              <PrivateRoute exact path="/paymentSummaryReport" component={PaymentSummaryReport} />
              <PrivateRoute exact path="/businessOpenClose" component={BusinessOpenCloseReport} />
              <PrivateRoute exact path="/itemsAvailability" component={ItemsAvailabilityReport} />
              <PrivateRoute exact path="/cronJobPerfReports" component={CronJobReports} />
              <PrivateRoute exact path="/releases" component={Release} />
              <PrivateRoute exact path="/termsandconditions" component={TermsAndConditions} />
              <PrivateRoute exact path="/businessperformance" component={BusinessPerformanceReport} />
              <PrivateRoute exact path="/masterotp" component={MasterOTP} />
              <PrivateRoute exact path="/BusinessUsersReport" component={ActiveBusinessUsersReport} />
              <PrivateRoute exact path="/pushNotifications" component={PushNotifications} />
              <PrivateRoute exact path="/configurations" component={configurations} />
              <PrivateRoute exact path="/MasterMPin" component={masterPin} />
              <PrivateRoute exact path="/AppMaintenance" component={appMaintenance} />
              <PrivateRoute exact path="/campaigns" component={Campaigns} />
              <PrivateRoute exact path="/campaigns/:campaignId" component={CampaignReport} />
              <PrivateRoute exact path="/campaigns/:campaignId/:businessId" component={CampaignBusinessReport} />
              <PrivateRoute exact path="/promocodereport" component={promoCodeReport} />
              {/* <PrivateRoute exact path="/offercodereport" component={offerCodeReport} /> */}
              <PrivateRoute exact path="/campaignsCouponSettlement" component={CampaignsCouponSettlement} />
              {/* <PrivateRoute exact path="/campaignsCouponSettlement/:businessId" component={CampaignsCouponSettlementBusiness} /> */}
              <PrivateRoute exact path="/campaignsCouponSettlementBusiness" component={CampaignsCouponSettlementBusiness} />
              <PrivateRoute exact path="/businessItemTag" component={BusinessItemTagReport} />
              <PrivateRoute exact path="/inAppNotice" component={InAppNotice} />
              <PrivateRoute exact path="/expense" component={Expense} />
              <PrivateRoute exact path="/visibleBusinesses" component={VisibleBusinesses} />
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    );
  }
}
export default App;