import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Modal from "@material-ui/core/Modal";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import BulkUpload from '../../../common/FileUploader';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ManageAddonTable from "./ManageAddonTable"
import ManageCreateUpdateAddon from "./ManageCreateUpdateAddon";
import { createAddon , clearMsg , getAllAddonList , updateAddon , requestAddonRecords , requestAddonRecordsFalse  , deleteAddons ,uploadAddons ,downloadAddons} from '../../../../actions/addonsAction';
import LinkedItemsModal from "./LinkedItemsModal";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import { CSVLink } from "react-csv";
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
const styles = (theme) => ({

  container: {
    width: "100%",
    float: "left",
    padding: "5px 0px 0px 0px",
  },
  table: {
    marginTop: "10px",
    minWidth: 750,
  },
  content: {
    flexGrow: 1,
    height: "100%",
    width: "100%",
    overflow: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    fontSize: "14px",
    fontWeight: 800,
    marginleft: "4px",
  },
 
  

});

const foodType = ['Veg','NonVeg','Egg','NA','Blank']
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class ManageAddonList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      snackOpen: false,
      sucessMsg: null,
      errorMsg: null,
      addonId: "",
      foodType: [],
      isEnabled: "",
      page: 0,
      rowsPerPage: 10,
      rows: [],
      availability: "",
      isDisplay: "",
      createUpdateModalOpen : false,
      edit : false,
      addonEditDetails : null,
      handleLinkedItemsOpen : false,
      addonObjectId    : null,
      selectedAddonToDelete : [],
      handleDeleteAddon : false,
      showDownloadIcon: false,

      headers: [
        { key: 'addOnId', label: 'addonId' },
        { key: 'petPoojaItemId', label: 'petPoojaItemId' },
        { key: 'petPoojaVariationId', label: 'petPoojaVariationId' },
        { key: 'service', label: 'itemName' },
        { key: 'description', label: 'itemDescription' },
        { key: 'cost.costType', label: 'itemCostType' },
        { key: 'cost.from', label: 'itemCostFrom' },
        { key: 'cost.to', label: 'itemCostTo' },
        { key: 'cost.fixedCost', label: 'itemFixedCost' },
        { key: 'cost.quantity', label: 'itemQuantity' },
        { key: 'cost.unit', label: 'itemUnit' },
        { key: 'servicesPackagingCharges', label: 'itemPackagingCharges' },
        { key: 'cost.gstConf.gst', label: 'itemGst (5%, 12%, 18%, 28%)' },
        { key: 'cost.gstConf.cgst', label: 'itemCgst' },
        { key: 'cost.gstConf.sgst', label: 'itemSgst' },
        { key: 'cost.gstConf.igst', label: 'itemIgst' },
        { key: 'isEnabled', label: 'itemIsEnabled' },
        { key: 'allowLooseQuantity', label: 'itemAllowLooseQuantity' },
        { key: 'foodType', label: 'itemFoodType' },
        { key: 'isDisplay', label: 'itemIsDisplay' },
        { key: 'mustTry', label: 'itemMustTry' },
        { key: 'newArrival', label: 'itemNew' },
        { key: 'excludeItemTags', label: 'excludeItemTagsFlag' },
        { key: 'workingHours', label: 'itemWorkingHrs' },
        { key: 'availability.holidays', label: 'itemHolidays' },
        { key: 'photos[0].path', label: 'itemPhotos' },
        { key: 'serviceOffers.discount.offers[0].discountType', label: 'itemDiscountTypeOne(FLAT,PERCENTAGE)' },
        { key: 'serviceOffers.discount.offers[0].off', label: 'itemDiscountOffOne' },
        { key: 'serviceOffers.discount.offers[0].maxDiscountCap', label: 'itemMaxDiscountCapOne' },
        { key: 'serviceOffers.discount.offers[0].isActive', label: 'itemDiscountIsActiveOne' },

        { key: 'serviceOffers.discount.offers[1].discountType', label: 'itemDiscountTypeTwo(FLAT,PERCENTAGE)' },
        { key: 'serviceOffers.discount.offers[1].off', label: 'itemDiscountOffTwo' },
        { key: 'serviceOffers.discount.offers[1].maxDiscountCap', label: 'itemMaxDiscountCapTwo' },
        { key: 'serviceOffers.discount.offers[1].isActive', label: 'itemDiscountIsActiveTwo' },

        { key: 'serviceOffers.discount.offers[2].discountType', label: 'itemDiscountTypeThree(FLAT,PERCENTAGE)' },
        { key: 'serviceOffers.discount.offers[2].off', label: 'itemDiscountOffThree' },
        { key: 'serviceOffers.discount.offers[2].maxDiscountCap', label: 'itemMaxDiscountCapThree' },
        { key: 'serviceOffers.discount.offers[2].isActive', label: 'itemDiscountIsActiveThree' },
        { key: 'addOnServiceItemParents', label: 'linkItemId' },
        { key: 'addOnGroupIds', label: 'linkedAddonGroupId' }
    ],
      
    };
  }

   componentDidMount = async()=>{
    await this.fetchAddonListFilter()
   };

  fetchAddonListFilter = async () => {
    await this.props.requestAddonRecords()
    let payload = {
      id   : this.props.businessDetails._id,
      skip: this.state.page * this.state.rowsPerPage,
      limit: this.state.rowsPerPage,
      addonId: this.state.addonId.trim(),
      foodType: this.state.foodType,
      isEnabled: this.state.isEnabled,
      isDisplay: this.state.isDisplay
      // availability: this.state.availability
    };
    await this.props.getAllAddonList(payload)
    this.setState({ showDownloadIcon: false });
    await this.props.requestAddonRecordsFalse()

  };

  fetchDownloadToExcel = async () => {
   
    let payload = {
      businessId   : this.props.businessDetails._id,
      addonId: this.state.addonId.trim(),
      foodType: this.state.foodType,
      isEnabled: this.state.isEnabled,
      isDisplay: this.state.isDisplay
    };
    await this.props.downloadAddons(payload);
    this.setState({ showDownloadIcon: true });
  };

  resetFilter = async () => {
    this.setState(
      {
        addonId: "",
        foodType: [],
        isEnabled: "",
        isDisplay: "",
        page: 0,
        // availability: "",
        showDownloadIcon: false,
        
      },
      () => {
        this.fetchAddonListFilter();
      }
    );
  };

  enterPressed = (event) => {
    let code = event.keyCode;
    if (code === 13) {
      event.preventDefault();
    }
  };

  setPage = (page) => {
    this.setState({ page }, () => this.fetchAddonListFilter());
  };

  setRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage }, () => this.fetchAddonListFilter());
  };


  handleFilterChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleCreateUpdateAddonOpen =()=>{
    this.setState({ createUpdateModalOpen : true })
  }
  handleCreateUpdateAddonClose =()=>{
    this.setState({ createUpdateModalOpen : false });
    this.setState({ edit : false });
  };
  handleEditAddonOpen= (data)=>{
    // console.log(`In the data`, data);
    this.setState({ createUpdateModalOpen : true });
    this.setState({ edit : true });
    this.setState({ addonEditDetails : data });
  };

  componentDidUpdate(prevProps, prevState) {
   
    if (this.props.addons.sucessMsg) {
      this.setState({ sucessMsg: this.props.addons.sucessMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    } else if (this.props.addons.errorMsg) {
      this.setState({ errorMsg: this.props.addons.errorMsg });
      this.setState({ snackOpen: true });
      this.props.clearMsg();
    }
  }

  handleSnackClose = () => {
    this.setState({ snackOpen: false });
    this.setState({ modalOpen: false });
    this.setState({ sucessMsg: null });
    this.setState({ errorMsg: null });
    this.setState({ editMode: false });

  };

  handleSwitchChange = async (event, service) => {
    let updatePayload = {};
    if (event.target.name === 'availability') {
        let availabilityData = service.availability;
        availabilityData.isActive = event.target.checked;
        updatePayload.availability = availabilityData;
    } else {
        updatePayload[event.target.name] = event.target.checked;
    }
    let payload = {
        serviceId: service._id,
        data: updatePayload
    }
    await this.props.updateAddon(payload);
    this.fetchAddonListFilter();
};

handleLinkedItemsOpen=  async(_id)=>{
  this.setState(
    { 
      handleLinkedItemsOpen : true,
      addonObjectId    : _id
     }
    );

}

handleLinkedItemsClose =  async()=>{
  await this.fetchAddonListFilter();
  this.setState(
    { handleLinkedItemsOpen : false  }
    );

}

setSelectedAddonDelete = (IdArr) => {
  this.setState({ selectedAddonToDelete: IdArr })
};

handleDeleteAddonOpen =()=>{
  this.setState({ handleDeleteAddon : true })
};

handleDeleteAddonClose = () =>{
  this.setState({ handleDeleteAddon : false  , selectedAddonToDelete : []})
}

deleteAddonRecords= async()=>{
  await this.props.requestAddonRecords()
  let payload ={
    addonIdArray : this.state.selectedAddonToDelete
  };
  await this.props.deleteAddons(payload);
  await this.fetchAddonListFilter();
  this.handleDeleteAddonClose()
  await this.props.requestAddonRecordsFalse()
}

bulkUpload = async(file) => {
  let payload = {
      businessId: this.props.businessDetails._id,
      file: file
  }
 await this.props.uploadAddons(payload);
 await this.fetchAddonListFilter()
}


  ITEM_HEIGHT = 48;
  ITEM_PADDING_TOP = 8;
  MenuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 4.5 + this.ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  render() {
    const { classes } = this.props;
    let muiAlert = null;
   
    if (this.state.sucessMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="success"
        >
          {this.state.sucessMsg}
        </MuiAlert>
      );
    } else if (this.state.errorMsg) {
      muiAlert = (
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={this.handleSnackClose}
          severity="error"
        >
          {this.state.errorMsg}
        </MuiAlert>
      );
    }

    return (

        <Container className={classes.container}>
       
          <Grid item xs={12} sm={12}>
            <Container>

            {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 115px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.handleDeleteAddonOpen}
                disabled={this.state.selectedAddonToDelete.length < 1}
              >
                Delete
              </Button> : null}
            </Container>
            {this.props.role === "areaManager" ?   
            <Container style={{ padding : "0px 130px 0px 0px"}}>
            <BulkUpload handleFile={this.bulkUpload} buttonName="Addon Bulk Upload" />  
            </Container> :  
            null  }

          
           <Container>
           {this.props.role === "areaManager" ?
              <Button
                style={{ float: "right", margin: "5px 5px 0px 5px" }}
                variant="contained"
                color="primary"
                onClick={this.handleCreateUpdateAddonOpen}
              >
                CREATE ADDON
              </Button> : null}
             <ManageCreateUpdateAddon 
             businessDetails={this.props.businessDetails}
             open={this.state.createUpdateModalOpen}
             onClose={this.handleCreateUpdateAddonClose}
             fetchAddonListFilter={this.fetchAddonListFilter}
             edit={this.state.edit}
             data={this.state.addonEditDetails}
             />
          </Container>


          <Container>
            {this.state.showDownloadIcon ?
              <CSVLink
                style={{ float: "right", margin: "5px 5px 0px 0px" }}
                data={this.props.addons.addonsDownloadToExcel}
                headers={this.state.headers}
                filename={this.props.businessDetails.businessName + ".csv"}
                className="btn btn"
                target="_blank"
              >
                <Tooltip title="Export to Excel">
                  <img alt="Export To Excel" src="../../../images/exportToExcel.png" width="25" height="25" />
                </Tooltip>
              </CSVLink>
              :
              this.props.role === "areaManager" ? 
              <Button
                style={{float: "right", margin: "5px 5px 0px 0px"}}
                variant="contained"
                color="primary"
                onClick={this.fetchDownloadToExcel}
              >
                DOWNLOAD ADDONS
              </Button>
                :
                <Button
                style={{marginLeft:"800px"}}
                variant="contained"
                color="primary"
                onClick={this.fetchDownloadToExcel}
              >
                DOWNLOAD ADDONS
              </Button>
            }
          </Container>

        </Grid>

        <Container
          className={classes.container}
          style={{ paddingBottom: "0px" }} 
        >
          <ExpansionPanel>
         <ExpansionPanelSummary
           expandIcon={<ExpandMoreIcon />}
           aria-controls="panel1a-content"
           id="panel1a-header"
         >
           <Typography className={classes.heading}>Search/Filter Report</Typography>
         </ExpansionPanelSummary>
         <ExpansionPanelDetails onKeyDown={this.enterPressed}>
           <Grid container spacing={1}>

             <Grid item xs={3}>
               <TextField
                 fullWidth
                 margin="dense"
                 name="addonId"
                 label="AddonId / Addon Name"
                 value={this.state.addonId}
                 variant="outlined"
                 onChange={this.handleFilterChange}
               />
             </Grid>
             <Grid item xs={3}>
                                <FormControl variant="outlined" fullWidth margin="dense" className={classes.formControl} >
                                    <InputLabel id="demo-simple-select-standard">Food Type</InputLabel>
                                    <Select
                                                labelId="demo-mutiple-checkbox-label"
                                                id="demo-mutiple-checkbox"
                                                multiple
                                                value={this.state.foodType}
                                                name="foodType"
                                                onChange={this.handleFilterChange}
                                                input={<Input />}
                                                renderValue={(selected) => selected.join(', ')}
                                                MenuProps={MenuProps}
                                        >
                                        {foodType.map((foodType) => (
                                                    <MenuItem key={foodType} value={foodType} style={{ minHeight: "28px", height: "28px" }}>
                                                        <Checkbox checked={this.state.foodType.indexOf(foodType) > -1} />
                                                        <ListItemText primary={foodType} />
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
              </Grid>
             <Grid item xs={3}>
               <FormControl fullWidth margin="dense" variant="outlined">
                 <InputLabel id="demo-simple-select-standard-label">
                   Is Enabled
                 </InputLabel>
                 <Select
                   labelId="demo-simple-select-standard-label"
                   id="demo-simple-select-standard"
                   name="isEnabled"
                   label="Is Enabled"
                   value={this.state.isEnabled}
                   onChange={this.handleFilterChange}
                 >
                   <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Enabled </MenuItem>
                   <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Disabled</MenuItem>
                 </Select>
               </FormControl>
             </Grid>
             <Grid item xs={3}>
               <FormControl fullWidth margin="dense" variant="outlined">
                 <InputLabel id="demo-simple-select-standard-label">Hide/Display</InputLabel>
                 <Select
                   labelId="demo-simple-select-standard-label"
                   id="demo-simple-select-standard-label"
                   name="isDisplay"
                   label="isDisplay"
                   value={this.state.isDisplay}
                   onChange={this.handleFilterChange}
                 >
                   <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Hide</MenuItem>
                   <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Display</MenuItem>
                  
                 </Select>
               </FormControl>
             </Grid>
             {/* <Grid item xs={3}>
               <FormControl fullWidth margin="dense" variant="outlined">
                 <InputLabel id="demo-simple-select-standard-label">Availability</InputLabel>
                 <Select
                   labelId="demo-simple-select-standard-label"
                   id="demo-simple-select-standard-label"
                   name="availability"
                   label="Availability"
                   value={this.state.availability}
                   onChange={this.handleFilterChange}
                 >
                   <MenuItem value={true} style={{ minHeight: "28px", height: "28px" }}>Available</MenuItem>
                   <MenuItem value={false} style={{ minHeight: "28px", height: "28px" }}>Unavailable</MenuItem>
                 </Select>
               </FormControl>
             </Grid> */}
             <Grid item xs={12}></Grid>
             <Grid item xs={8}></Grid>
             <Grid item xs={2}>
               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 margin="normal"
                 onClick={this.resetFilter}
               >
                 Reset
               </Button>
             </Grid>
             <Grid item xs={2}>
               <Button
                 type="submit"
                 fullWidth
                 variant="contained"
                 margin="normal"
                 color="primary"
                 onClick={
                   () => this.setPage(0)

                 }
               >
                 Search
               </Button>
             </Grid>
           </Grid>
         </ExpansionPanelDetails>
       </ExpansionPanel>

        </Container>
        <Container className={classes.container}>
         <ManageAddonTable  
          addonList={this.props.addons.addonList.data}
          totalCount={this.props.addons.addonList.totalCount}
          page={this.state.page}
          rowsPerPage={this.state.rowsPerPage}
          setPage={this.setPage}
          setRowsPerPage={this.setRowsPerPage}
          role={this.props.role}
          handleEditAddonOpen={this.handleEditAddonOpen}
          handleSwitchChange={this.handleSwitchChange}
          handleLinkedItemsOpen={this.handleLinkedItemsOpen}
          selectedAddonToDelete={this.state.selectedAddonToDelete}
          setSelectedAddonDelete={this.setSelectedAddonDelete}
        
         />
        </Container>
           <Modal
             open={this.state.handleLinkedItemsOpen}
             className={classes.linkedItemsModal}
             onClose={this.handleLinkedItemsClose}
             closeAfterTransition
           >
            <LinkedItemsModal 
             handleLinkedItemsClose={this.handleLinkedItemsClose}
             addonObjectId={this.state.addonObjectId}
             role={this.props.role}
             />
            </Modal>  
            <Dialog
          open={this.state.handleDeleteAddon}
          onClose={this.handleDeleteAddonClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete Addon</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to Delete Addons ?<br></br>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteAddonClose} color="primary">
              No
            </Button>
            <Button onClick={() => this.deleteAddonRecords()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>  
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={this.state.snackOpen}
          autoHideDuration={2000}
          onClose={this.handleSnackClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
        >
          {muiAlert}
        </Snackbar>

        <Backdrop
          className={classes.backdrop}
          open={this.props.addons.isFetching}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

    );
  }
}

const mapStateToProps = (state) => {
  return {
    addons : state.addons
  };
};

export default connect(mapStateToProps, {
 
  createAddon , clearMsg , getAllAddonList , updateAddon , requestAddonRecords , requestAddonRecordsFalse  , deleteAddons , uploadAddons , downloadAddons
  

})(withStyles(styles)(ManageAddonList));
