import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import React from 'react';
import { CSVLink } from "react-csv";



let headCells = [
    { id: 'cronJob', disablePadding: false, label: 'Cron Job Name' },
    { id: 'executionStartAt', disablePadding: false, label: 'Started on (UTC)' },
    { id: 'executionEndAt', disablePadding: false, label: 'Ended on (UTC)' },
    { id: 'executionDuration', disablePadding: false, label: 'Duration (ms)' },
]
function EnhancedTableHead(props) {
    const {classes} = props;
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        className={classes.tableCellSmall}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
}));


const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    return (
        <Toolbar style={{ paddingLeft: "16px" }}>
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
                Cron Job Performance ({props.totalCount})
            </Typography>

            <Typography component="div">
                {props.showDownloadIcon ?
                    <CSVLink
                        data={props.data}
                        headers={props.headers}
                        filename={"cronJobPerformance.csv"}
                        target="_blank"
                    >
                        <Tooltip title="Export to Excel">
                            <img alt="Export To Excel" src="../../images/exportToExcel.png" width="25" height="25" />
                        </Tooltip>
                    </CSVLink>
                : <Button
                    variant="contained"
                    margin="normal"
                    style={{ backgroundColor: "green", color: "white", fontFamily: "bold" }}
                    onClick={props.fetchCronJobPerformanceExcel}
                    >
                    Export
                </Button>
                } 
            </Typography> 
        </Toolbar>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableCellSmall: {
        fontSize: "13px",
        padding: "6px 0px 6px 16px",
        textAlign: 'left',
    },
    tableRow: {
        // cursor: 'pointer'
    },
    tableCellSmall13: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px",
        maxWidth: 300,
        minWidth:200,
        width: 700,
    },
    tableCellSmall14: {
        fontSize: "13px",
        padding: "6px 0px 6px 10px",
        width: '100%',
        textAlign: 'left',
        marginRight:"25px",
        marginLeft:"25px"
    },
}));
export default function EnhancedTable(props) {
    const classes = useStyles();
    // console.log("in table code");
    // console.log("props--->",props)

    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    let headers = [
        { key: 'cronJob', label: 'Cron Job Name' },
        { key: 'executionStartAt', label: 'Started on (UTC)' },
        { key: 'executionEndAt', label: 'Ended on (UTC)' },
        { key: 'executionDuration', label: 'Duration (ms)' },
       
    ];

    let data = []
    props.exportToExcel && props.exportToExcel.forEach(element => {
        let item = element
        item.cronJob = item.cronJob ? item.cronJob : "";
        item.executionStartAt = moment.utc(item.executionStartAt).format('DD MMM YYYY hh:mm:ss A') ;
        item.executionEndAt = moment.utc(item.executionEndAt).format('DD MMM YYYY hh:mm:ss A');
        item.executionDuration = parseInt(item.executionDuration).toFixed(2);        
        data.push(item);
    });

    let totalCount = props.totalCount;
    // const emptyRows = props.rowsPerPage - Math.min(props.rowsPerPage, props.totalCount - props.page * props.rowsPerPage);
    
    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar 
                    headers={headers}
                    totalCount={props.totalCount}
                    data={data}
                    showDownloadIcon={props.showDownloadIcon}
                    fetchCronJobPerformanceExcel={props.fetchCronJobPerformanceExcel}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size="small"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            rowCount={props.totalCount}
                        />
                        <TableBody>
                            {
                                props.data && props.data.map((row, index) => {
                                    // const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            className={classes.tableRow}
                                            tabIndex={-1}
                                            key={index}
                                        >   <TableCell align="left">{row.cronJob}</TableCell>
                                            <TableCell align="left">{moment.utc(row.executionStartAt).format('DD MMM YYYY hh:mm:ss A')}</TableCell>
                                            <TableCell align="left">{moment.utc(row.executionEndAt).format('DD MMM YYYY hh:mm:ss A')}</TableCell>
                                            <TableCell align="left">{parseInt(row.executionDuration).toFixed(2)}</TableCell>
                                            
                                        </TableRow>
                                    );
                                })
                            }
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 33 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}