import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../utils/TabPanel";
import Button from "@material-ui/core/Button";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { showApprovedBusinessDetails } from "../../../actions/dashboardActions";
import { fetchEnvs } from '../../../actions/authActions';
import {requestBusinessDetailsRecords, requestBusinessDetailsRecordsFalse } from "../../../actions/businessActions";
import {
    getBusinessById,
    disableBusiness,
    approveBusiness,
    clearBusiErrorMsg,
    clearBusiSucessMsg,
    getPanServPro,
    getAadharServPro,
    getRevenueByBusiness,
    getLastOrderReceivedByBusiness,
    getShopActServPro,
    getQRCodeImage,
    deleteBusinessPhotos,
    getMembersList,
    updateDeliveryPartnerAvailability,
    updateDeliveryPartnerPriority,
    updateAadharCard, updatePanCard,
    getServicesByBusinessId,
    // updateBusinessDetails,
    updateBusinessPersonalDetails,
    updateServiceProviderForUserRoleFromWeb,
    deleteAllServices,
    getHeadByBusinessId,
    getHeadSubheadHierarchy,
    getHeadWithServicesByBusinessId
} from "../../../actions/businessActions";
import {
    updateContract,
    clearContractMsgs,
    addContract,
} from "../../../actions/contractActions";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import moment from "moment";
import _ from 'lodash';
import Divider from '@material-ui/core/Divider';
import axios from "axios";
import PaymentForm from "./PaymentForm";
import ContractForm from "../PendingBusiness/ContractForm";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import MembersTable from "./MembersTable";
import BusinessDetailsSection from "./BusinessDetailSection";
import ServiceSection from "./ServiceSection";
import DeliverySettlementTable from "./DeliverySettlementTable";
import { getSettlementsRecords, getSettlementsNotes, clearNotesRecord , getServiceDeliveryPartner} from "../../../actions/servicePartnerDeliveriesAction";
import { 
    getAllVariationGroups,
  } from "../../../actions/variationsAction";
 import { getAllAddonList ,getAllItemsLinkedWithAddons} from "../../../actions/addonsAction";
import SettleAmountModal from "./SettleAmountModal";
import AddNotesModal from "./AddNotesModal";
import DiscountSection from "./DiscountSection";
import TransactionCharges from "./TransactionCharges";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Input } from '@material-ui/core';
import VendorSettlementSection from './VendorSettlementSection';
import BusinessAgreementSection from "./BusinessAgreementSection";
import { getAllAddOnGroups,} from '../../../actions/addonGroupAction';
import InputAdornment from '@material-ui/core/InputAdornment';


const styles = (theme) => ({
    content: {
        flexGrow: 1,
        height: '100%',
        // overflow: 'hidden',
        overflow: 'auto',
    },
    heading: {
        fontSize: "14px",
        fontWeight: 500
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
        maxWidth: "1300px",
        position: "absolute",
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    // container: {
    //     maxWidth: "100%",
    //     position: "absolute",
    //     paddingTop: theme.spacing(1),
    //     paddingBottom: theme.spacing(1),
    // },
    dpCont: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    gridList: {
        width: 500,
        height: 450,
    },
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    serviceList: {
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifycontent: "center",
        padding: "0px",
    },
    gridItem: {
        display: "flex",
        alignItems: "center",
        justifycontent: "center",
        // marginLeft: "50px",
        marginLeft: "200px",
        marginTop: "25px",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    modalContainer: {
        width: "60%",
        height: "70%",
        backgroundColor: theme.palette.background.paper,
        padding: "0px 0px",
    },
    contractModalContainer: {
        width: "40%",
        height: "95%",
        backgroundColor: theme.palette.background.paper,
        padding: "0px 0px",
    },
    card: {
        minWidth: 175,
        width: 175,
        height: 110,
    },
    ul: {
        backgroundColor: "inherit",
        padding: 0,
    },
    searchPaper: {
        padding: "0px 0px",
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
    },
    clearButton: {
        marginLeft: "25px",
    },
    formControl: {
        margin: "8px",
    },
    tabPadding : {
        minWidth:"135px"
    },
    headerTwo: {
        height: '10%',
        margin: '0px',
        paddingRight:'8px',
        borderRadius:"0px 0px 0px 0px",
        fontSize:"14px",
        backgroundColor: '#F2F2F2'
    },
    borderOutline: {
        height: '5%',
        width: '20%',
        borderRadius:"6px 6px 6px 6px",
        wordWrap:"normal",
        border: "1px solid #CCCCCC",
        margin: "5px 0px 5px 15%",
        opacity: 1,
        padding: "10px 10px 10px 10px"
    },
    selectBox :{
     height : '30px',
     width : '61px',
     marginTop :'10px'
    },
    textStyle :{
        width : '100px',
        height : '20px'
    }
});

// 0 = 'Add Charges to Consumer' - We will add into consumerConvFees
// 1 = 'Add Charges to Business' - We will add into businessConvFees
const options = ['Add Charges to Consumer', 'Add Charges to Business'];
let dialogState = {
    txnChargestype: null,
    convFeesIndex: null,
    slabIndex: null,
    cb: null,
    label: null,
};

let subsequentRowDeleteDialogState = {
    txnChargestype: null,
    convFeesIndex: null,
    slabIndex: null,
    cb: null
};
class FullBusinessDetails extends Component {
    constructor() {
        super();
        this.anchorRef = React.createRef();
        this.bcfRefs = [];
        this.ccfRefs = [];
        this.state = {
            value: 0,
            expanded: [],
            modalOpen: [],
            modalBusinessPhoto: false,
            snackOpen: false,
            backDropOpen: false,
            buttonDisabled: false,
            enableDialogOpen: false,
            disableDialogOpen: false,
            paymentModalOpen: false,
            selectedContract: {},
            contractModalOpen: false,
            servicePhotos: [],
            order: -1,
            orderBy: "name",
            page: 0,
            imgfileForAadhar: {},
            imgfileForPan: {},
            rowsPerPage: 10,
            searchValue: "",
            clearSearch: false,
            isDeliveryByPartnerAllowd: false,
            isDeliveryByPartner: false,
            serviceDeliveryPriority: [],
            sdpError: "",
            sdpSuccess: "",
            sucessMsg: null,
            isFetching: false,
            rowsPerPageDeliverySettlementTable: 10,
            deliverySettlementTablePage: 0,
            settleModal: false,
            openNotesModal: false,
            selectedSettlementRecords: [{}],
            showDownloadIcon: false,
            contractTab: 0,
            selectedIndex: 0,
            openTxnChrButton: false,
            businessConvFees: [],
            consumerConvFees: [],
            convFeesSuccess: "",
            convFeesError: "",
            isDelTxnDialogOpen: false,
            isDelTxnChrgsDialogOpen: false,
            saveConvFeesButtonDisabled: true,
            editDetails: false,
            requiredError: false,
            firstName: "",
            lastName: "",
            aadhar: "",
            pan: "",
            primaryMobileNumber: "",
            mobileNumbers: "",
            address: "",
            aadharCardNumber: "",
            panCardNumber: "",
            email: "",
            fileNameForAadhar: "",
            fileNameForPan: "",
            businessDashboardCards: {
                orders: true,
                cancel: true,
                shoppers: true,
                settlement: true,
                onlinePayment: true,
                directPayment: true,
                campaign: true,
                userSentiment: true,
            },
            phoneValidationError: false,
            aadharValidationError: false,
            panValidationError: false,
            lastNameValidationError: false,
            firstNameValidationError: false,
            isSubsequentDeleteTxnDialogOpen: false,
            businessMemberStatus: {},
            specialityListArr: [],
            cuisineListArr: [],
            businessUserRole:{},
            confirmDialogFlag: false,
            status: "",
            statusData: null,
            resetButtonDisabled : false,
            servicePartnerDeliveryRadiusInKm: null,
            deliveryParterRaduisFlag: false,
            radiusFieldRequired: false,
            invalidRadiusField: false,
            deliveryPartnerPriorityAsKm: [],
            mobileValidationError : false,
            mobileValidationSpaceError: false,
            shadowFaxDiscount : null,
            xirifyDeliveryDiscount : null,
            porterDiscount: null,
            borzoDiscount: null,
            dunzoDiscount: null,
            shadowfaxRadiusDiscount : null,
            xirifyDeliveryRadiusDiscount: null,
            porterRadiusDiscount: null,
            borzoRadiusDiscount: null,
            dunzoRadiusDiscount: null,
            
        };
    }

    async componentDidMount() {
        console.log(" this.props in FBD --->", this.props)
        this.props.fetchEnvs();
        let id = { businessId: this.props.dashboard.setSelectedBusiness._id };
        this.props.requestBusinessDetailsRecords();
        this.props.getBusinessById(id);
        this.props.requestBusinessDetailsRecordsFalse();

        this.setConvenienceFees(this.props.dashboard.setSelectedBusiness.convenienceFees
            || { businessConvFees: [], consumerConvFees: [] });
        this.bcfRefs = Array(this.props.dashboard.setSelectedBusiness.convenienceFees ? this.props.dashboard.setSelectedBusiness.convenienceFees.businessConvFees.length : 0).fill(React.createRef());
        this.ccfRefs = Array(this.props.dashboard.setSelectedBusiness.convenienceFees ? this.props.dashboard.setSelectedBusiness.convenienceFees.consumerConvFees.length : 0).fill(React.createRef());
        await this.getServiceDeliveryPartner();
        this.getSettlementsRecords();
        this.setData();
        if(this.props.user.role === "areaManager" && this.props.business.allServices && this.props.business.allServices.data) {
            this.setState({
                resetButtonDisabled: false
            });
        }
        if(this.props.user.role === "admin" || this.props.user.role === "regionalHead") {
            this.setState({
                resetButtonDisabled: true
            });
        }

        const SpecialityList = await axios('/api/specialities');
        this.setState({ specialityListArr: SpecialityList.data });

        const cuisineList = await axios('/api/cuisines');
        this.setState({ cuisineListArr: cuisineList.data });    
    };

    getSpecialityList = () => {
        axios.get('/api/specialities')
            .then((response) => response.json())
            .then(speciality => {
                this.setState({ specialityList: speciality });
            });
    }

    setConvenienceFees = (convenienceFees) => {
        this.setState({ businessConvFees: convenienceFees.businessConvFees });
        this.setState({ consumerConvFees: convenienceFees.consumerConvFees });
    };

    isSaveConvDisabled = () => {
       // console.log("this.formRef");
    };
    
    checkConvenienceFeesValues(convFees, txnChargestype, index) {
        let convenienceFees = _.cloneDeep(convFees);
        // console.log("checkConvenienceFeesValues", convenienceFees);
        let hasError = false;
        if (convenienceFees.feeType === "slab") {
            let slabs = convenienceFees.slabs;
            for (let j = 0; j < slabs.length; j++) {
                let slab = slabs[j];
                if (!slab.hasOwnProperty("from") || slab.from === '') {
                    hasError = true;
                    slab.error = 'From Rupees missing';
                } else if (!slab.hasOwnProperty("to") || slab.to === '') {
                    hasError = true;
                    slab.error = 'To Rupees missing';
                } else if (!slab.hasOwnProperty("chargeType") || slab.chargeType === '' || slab.chargeType === null) {
                    slab.error = 'Charges missing';
                    hasError = true;
                } else if (!slab.hasOwnProperty("value") || slab.value === '') {
                    slab.error = 'Value field missing';
                    hasError = true;
                } else if (typeof slab.from != 'number' || slab.from < 0) {
                    slab.error = 'Invalid From Rupees field';
                    hasError = true;
                } else if (typeof slab.to != 'number' || slab.to < 0) {
                    slab.error = 'Invalid To Rupees field';
                    hasError = true;
                } else if (typeof slab.value != 'number' || slab.value < 0) {
                    slab.error = 'Invalid value field';
                    hasError = true;
                } else if (slab.to <= slab.from) {
                    slab.error = 'To Rupees value should be greater than From Rupees value';
                    hasError = true;
                } else if (slab.chargeType === 'fixed' && slab.value > slab.to) {
                    slab.error = 'Fixed Value should be lower than To Rupees field';
                    hasError = true;
                } else if (j > 0 && slab.from <= slabs[j - 1].to) {
                    hasError = true;
                    slab.error = 'Slab values are not incremental than previous slab';
                } else {
                    slab.error = null;
                }
            }
        } else if (convenienceFees.feeType === "flat") {
            if (!convenienceFees.hasOwnProperty("value") || convenienceFees.value === null || convenienceFees.value === '') {
                hasError = true;
                convenienceFees.error = 'Value field missing';
            } else if (!convenienceFees.hasOwnProperty("chargeType") || convenienceFees.chargeType === "") {
                hasError = true;
                convenienceFees.error = 'Charges field missing';
            } else if (typeof convenienceFees.value != 'number' || convenienceFees.value < 0) {
                convenienceFees.error = 'Invalid Value field';
                hasError = true;
            } else {
                convenienceFees.error = null;
            }
        }
        return { hasError, convenienceFees };
    };

    convFeesFormChanged = () => {
        if (this.state.saveConvFeesButtonDisabled === true) {
            this.setState({ saveConvFeesButtonDisabled: false })
        }
    };

    saveConvenienceFees = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        let hasError = false;
        let newBusinessConvFees = [...this.state.businessConvFees];
        for (let i = 0; i < newBusinessConvFees.length; i++) {
            let bcf = newBusinessConvFees[i];
            let result = this.checkConvenienceFeesValues(bcf, "Business", i);
            newBusinessConvFees[i] = result.convenienceFees;
            if (!hasError && result.hasError === true) {
                hasError = true;
            }
        }
        this.setState({ businessConvFees: newBusinessConvFees });

        let newConsumerConvFees = [...this.state.consumerConvFees];
        for (let j = 0; j < newConsumerConvFees.length; j++) {
            let ccf = newConsumerConvFees[j];
            let result = this.checkConvenienceFeesValues(ccf, "Consumer", j);
            newConsumerConvFees[j] = result.convenienceFees;
            if (!hasError && result.hasError === true) {
                hasError = true;
            }
        }
        this.setState({ consumerConvFees: newConsumerConvFees });
        if (hasError === true) {
            return;
        }
        let convFeesObj = {};
        let bcfObj = newBusinessConvFees.map((newBcf) => {
            if (newBcf.feeType === 'flat') {
                delete newBcf.error;
                delete newBcf.isNew;
            } else if (newBcf.feeType === 'slab') {
                newBcf.slabs.forEach((slab) => {
                    delete slab.error;
                    delete slab.isNew;
                })
            }
            return newBcf;
        });
        convFeesObj.businessConvFees = bcfObj;

        let ccfObj = newConsumerConvFees.map((newccf) => {
            if (newccf.feeType === 'flat') {
                delete newccf.error;
                delete newccf.isNew;
            } else if (newccf.feeType === 'slab') {
                newccf.slabs.forEach((slab) => {
                    delete slab.error;
                    delete slab.isNew;
                })
            }
            return newccf;
        });
        convFeesObj.consumerConvFees = ccfObj;

        let objToSave = {
            convenienceFees: convFeesObj
        }

        // console.log("Convinence fees--- >", objToSave)


        // if (objToSave.convenienceFees.businessConvFees.length == 0 && objToSave.convenienceFees.businessConvFees.length) {
        //     this.setState({ convFeesError: "" });
        //     this.setState({ snackOpen: true });
        // }

        // console.log(objToSave);
        try {
            await axios.patch("/api/business/" + this.props.dashboard.setSelectedBusiness._id,
                objToSave);
            // console.log("saveConvenienceFees success case", resConvienceFees);
            this.setState({ convFeesSuccess: "Successfully updated Transaction Charges" });
            this.setState({ snackOpen: true });
            this.setState({ saveConvFeesButtonDisabled: true })
            if (this.bcfRefs && this.bcfRefs.length > 0) {
                this.bcfRefs.forEach((bcfRef) => {
                    if (bcfRef && bcfRef.saved) {
                        bcfRef.saved();
                    }
                });
            }
            if (this.ccfRefs && this.ccfRefs.length > 0) {
                this.ccfRefs.forEach((ccfRef) => {
                    if (ccfRef && ccfRef.saved) {
                        ccfRef.saved();
                    }
                });
            }
        } catch (err) {
            // console.log("saveConvenienceFees error case", err);
            this.setState({ convFeesError: "Failed to update Transaction Charges" });
            this.setState({ snackOpen: true });
        }
    };

    resetFilter = () => {
        this.setState({
            order: -1,
            orderBy: 'createdAt',
            page: 0,
            rowsPerPage: 10,
            consumerId: "",
            dateRange: "",
            filterStartDate: "",
            filterEndDate: "",
            societyName: "",
            firstName: "",
            lastName: "",
            email: "",
            pinCode: "",
            primaryMobileNumber: "",
            businessFilter: "",
            distance: "",
            lastActivetimeDateRange: "",
            lastActivetimeFilterEndDate: "",
            lastActivetimeFilterStartDate: "",
            registrationDateRangeFilterStartDate: "",
            registrationDateRangeFilterEndDate: "",
            businessFilterError: "",
            status: "",
        }, () => {
            // this.fetchConsumersByQuery();
        })
    };

    getSettlementsRecords = async () => {
        let payload = {
            businessid: this.props.dashboard.setSelectedBusiness._id,
            data: {
                skip: this.state.deliverySettlementTablePage * this.state.rowsPerPageDeliverySettlementTable,
                limit: this.state.rowsPerPageDeliverySettlementTable,
            }
        }
        await this.props.getSettlementsRecords(payload);
        this.setState({ showDownloadIcon: false });
    };

    fetchWithExcelFile = async () => {
        let payload = {
            businessid: this.props.dashboard.setSelectedBusiness._id,
            data: {
                skip: this.state.deliverySettlementTablePage * this.state.rowsPerPageDeliverySettlementTable,
                limit: this.state.rowsPerPageDeliverySettlementTable,
                exportToexcel: 1
            }
        }
        await this.props.getSettlementsRecords(payload);
        await this.setState({ showDownloadIcon: true });
    };

    componentWillMount() {
        this.props.fetchEnvs();

        this.props.clearBusiErrorMsg();
        this.props.clearBusiSucessMsg();
        let expandedList = new Array(
            this.props.dashboard.setSelectedBusiness.services.length
        ).fill(false);
        this.setState({ expanded: expandedList });

        let modalOpenList = new Array(
            this.props.dashboard.setSelectedBusiness.services.length
        ).fill(false);
        this.setState({ modalOpen: modalOpenList, editStatusFlag:false });
        let id = this.props.dashboard.setSelectedBusiness.ownerId._id
        this.props.getPanServPro(id);
        this.props.getAadharServPro(id);

        this.props.getShopActServPro({
            serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id,
            businessId: this.props.dashboard.setSelectedBusiness._id,
        });

        this.props.getQRCodeImage({
            type: "qrcodeconsumer",
        });



        // Get revenue by business for today, yesterday, weekly, monthly and till now
        this.getBusinessRevenue();
        this.memberDetails();
    };

    getBusinessRevenue = () => {
        this.props.getLastOrderReceivedByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
        });
        let todaysDate = new Date();
        this.props.getRevenueByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
            adminId: this.props.user.id,
            revenueType: "tillnow",
            startDate: null,
            endDate: todaysDate.toUTCString(),
        });

        let todaysStart = new Date(
            Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 1,
                23,
                59,
                59
            )
        );
        this.props.getRevenueByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
            adminId: this.props.user.id,
            revenueType: "today",
            startDate: todaysStart.toUTCString(),
            endDate: todaysDate.toUTCString(),
        });
        let yesterdaysDate = new Date();
        yesterdaysDate.setDate(new Date().getDate() - 1);
        let yesterdaysStart = new Date(
            Date.UTC(
                yesterdaysDate.getFullYear(),
                yesterdaysDate.getMonth(),
                yesterdaysDate.getDate() - 1,
                23,
                59,
                59
            )
        );
        let yesterdaysEnd = new Date(
            Date.UTC(
                yesterdaysDate.getFullYear(),
                yesterdaysDate.getMonth(),
                yesterdaysDate.getDate(),
                23,
                59,
                59
            )
        );
        this.props.getRevenueByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
            adminId: this.props.user.id,
            revenueType: "yesterday",
            startDate: yesterdaysStart.toUTCString(),
            endDate: yesterdaysEnd.toUTCString(),
        });

        let date = new Date();
        let weekStartDate =
            date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        date.setDate(weekStartDate);
        let weekStart = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate() - 1,
                23,
                59,
                59
            )
        );
        this.props.getRevenueByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
            adminId: this.props.user.id,
            revenueType: "weekly",
            startDate: weekStart.toUTCString(),
            endDate: todaysDate.toUTCString(),
        });

        let monthsFirst = new Date(
            todaysDate.getFullYear(),
            todaysDate.getMonth(),
            1
        );
        let monthStart = new Date(
            Date.UTC(
                monthsFirst.getFullYear(),
                monthsFirst.getMonth(),
                monthsFirst.getDate() - 1,
                23,
                59,
                59
            )
        );
        this.props.getRevenueByBusiness({
            businessId: this.props.dashboard.setSelectedBusiness._id,
            adminId: this.props.user.id,
            revenueType: "monthly",
            startDate: monthStart.toUTCString(),
            endDate: todaysDate.toUTCString(),
        });
    };

    handleChange = (event) => {
        if (event.target.name === "firstName") { this.setState({ [event.target.name]: event.target.value, error: {}, firstNameValidationError: false }); }
        if (event.target.name === "lastName") { this.setState({ [event.target.name]: event.target.value, error: {}, lastNameValidationError: false }); }
        if (event.target.name === "primaryMobileNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, phoneValidationError: false }); }
        if (event.target.name === "aadharCardNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, aadharValidationError: false }); }
        if (event.target.name === "panCardNumber") { this.setState({ [event.target.name]: event.target.value, error: {}, panValidationError: false }); }
        if (event.target.name === "servicePartnerDeliveryRadiusInKm") { this.setState({ [event.target.name]: event.target.value, radiusFieldRequired: false, invalidRadiusField: false }); }
        if (event.target.name === "mobileNumbers") { this.setState({ [event.target.name]: event.target.value, error: {}, mobileValidationError: false,mobileValidationSpaceError : false }); }
        

        this.setState({
            [event.target.name]: event.target.value,
            // error: {},

            businessVideoError: false,
            businessVideoErrorExists: false,
            businessVideoErrorEditExists: false,

        });
    };

    handleBusinessCardEnableChange = async (e, cardName) => {
        // console.log("e.target.checked", e.target.checked);
        let newBusinessDashboardCards = { ...this.state.businessDashboardCards };
        newBusinessDashboardCards[cardName] = e.target.checked;

        if (cardName === "onlinePayment" || cardName === "directPayment") {
            if (e.target.checked === true) {
                if (this.state.businessDashboardCards.settlement === false) {
                    newBusinessDashboardCards["settlement"] = true;
                }
            } else if (e.target.checked === false) {
                if (cardName === "onlinePayment") {
                    if (this.state.businessDashboardCards.directPayment === false) {
                        newBusinessDashboardCards["settlement"] = false;
                    }
                } else if (cardName === "directPayment") {
                    if (this.state.businessDashboardCards.onlinePayment === false) {
                        newBusinessDashboardCards["settlement"] = false;
                    }
                }
            }
        }

        if (cardName === "settlement") {
            newBusinessDashboardCards["directPayment"] = newBusinessDashboardCards["onlinePayment"] = false;
        }

        await axios.patch("/api/business/" + this.props.dashboard.setSelectedBusiness._id, { businessDashboardCards: newBusinessDashboardCards });
        this.setState({ businessDashboardCards: newBusinessDashboardCards });

    };

    setData = () => {
        let dunzDiscount;
        let borzoDiscount;
        let porterDiscount;
        let shadowfaxDiscount;
        let xirifyDeliveryD;
        let deliveryPartnerPriorityAsKm  = this.props.dashboard.setSelectedBusiness.deliveryPartnerPriorityAsKm && this.props.dashboard.setSelectedBusiness.deliveryPartnerPriorityAsKm !== null && this.props.dashboard.setSelectedBusiness.deliveryPartnerPriorityAsKm !== undefined && this.props.dashboard.setSelectedBusiness.deliveryPartnerPriorityAsKm.length > 0 ? this.props.dashboard.setSelectedBusiness.deliveryPartnerPriorityAsKm : []
        if (deliveryPartnerPriorityAsKm && deliveryPartnerPriorityAsKm.length) {
            dunzDiscount  = deliveryPartnerPriorityAsKm.find(e=> e.delivery_partner === 'dunzo')
            borzoDiscount  = deliveryPartnerPriorityAsKm.find(e=> e.delivery_partner === 'borzo')
            porterDiscount  = deliveryPartnerPriorityAsKm.find(e=> e.delivery_partner === 'porter')
            shadowfaxDiscount  = deliveryPartnerPriorityAsKm.find(e=> e.delivery_partner === 'shadowfax')
            xirifyDeliveryD  = deliveryPartnerPriorityAsKm.find(e=> e.delivery_partner === 'xirify_delivery')
         }
        this.setState({
            firstName: this.props.dashboard.setSelectedBusiness.ownerId.firstName,
            lastName: this.props.dashboard.setSelectedBusiness.ownerId.lastName,
            email: this.props.dashboard.setSelectedBusiness.ownerId.email,
            primaryMobileNumber: this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber,
            mobileNumbers: this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers,
            address: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address,
            aadharCardNumber: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number,
            panCardNumber: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number,
            imgfileForAadhar: this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar,
            businessDashboardCards: this.props.dashboard.setSelectedBusiness.businessDashboardCards,
            businessMemberStatus: this.props.business.businessMembersDetails,      
            servicePartnerDeliveryRadiusInKm: this.props.dashboard.setSelectedBusiness?.kmRadiusServiceDeliveryPriority
            || null,
            deliveryParterRaduisFlag: this.props.dashboard.setSelectedBusiness?.priorityRadiusFlag || false,
            deliveryPartnerPriorityAsKm: deliveryPartnerPriorityAsKm.length ?  deliveryPartnerPriorityAsKm : [],
            dunzoRadiusDiscount: dunzDiscount ? dunzDiscount.discountOnDelivery : null ,
            borzoRadiusDiscount: borzoDiscount ?  borzoDiscount.discountOnDelivery : null  , 
            porterRadiusDiscount: porterDiscount ?  porterDiscount.discountOnDelivery  : null , 
            shadowfaxRadiusDiscount : shadowfaxDiscount ? shadowfaxDiscount.discountOnDelivery : null,
            xirifyDeliveryRadiusDiscount: xirifyDeliveryD ? xirifyDeliveryD.discountOnDelivery : null,
       });        
    };

    handleFileUploadForAadhar = (e) => {
        this.setState({ imgfileForAadhar: e.target.files[0] });
        let splitArray = e.target.files[0].name.split(".");
        let fileExtention = splitArray[splitArray.length - 1];
        let timeStamp = Date.now();
        let fileName = `${this.props.dashboard.setSelectedBusiness.ownerId._id}-${timeStamp}.${fileExtention}`;
        this.setState({ fileNameForAadhar: fileName })
    };

    handleFileUploadForPan = (e) => {
        this.setState({ imgfileForPan: e.target.files[0] });
        let splitArray = e.target.files[0].name.split(".");
        let fileExtention = splitArray[splitArray.length - 1];
        let timeStamp = Date.now();
        let fileName = `${this.props.dashboard.setSelectedBusiness.ownerId._id}-${timeStamp}.${fileExtention}`;
        this.setState({ fileNameForPan: fileName })

    };

    closeEditDetails = () => {
        this.editDetailsFalse();
        this.setData();
        this.setState({ requiredError: false, phoneValidationError: false, aadharValidationError: false, panValidationError: false, lastNameValidationError: false, firstNameValidationError: false,mobileValidationError : false,  mobileValidationSpaceError : false	});
    };

    editDetailsFalse = () => {
        this.setState({ editDetails: false });
    };

    editDetailsTrue = () => {
        this.setState({ editDetails: true });
    };

    saveDetails = async () => {
        let hasError = false;


        if (!this.state.primaryMobileNumber || (this.state.primaryMobileNumber.trim().length !== 10 && this.state.primaryMobileNumber.trim().length !== 0)) {
            this.setState({ phoneValidationError: true, });
            hasError = true;
        }

        if (this.state.mobileNumbers !== "") {
          //  console.log("mobileValidationSpaceError",this.state.mobileValidationSpaceError)
            if (! /^[0-9]*$/.test(this.state.mobileNumbers)) { hasError = true; this.setState({ mobileValidationSpaceError: true }) }
            if (! /^[0-9]{10}$/.test(this.state.mobileNumbers)) { 
              //  console.log("mobileValidationError",this.state.mobileValidationError )
                hasError = true;
                this.setState({ mobileValidationError: true }) }
          }
        if (!this.state.firstName || !this.state.lastName) { hasError = true }

        if (this.state.firstName || this.state.lastName) {
            if (! /([a-zA-Z])$/.test(this.state.firstName)) {
                this.setState({ firstNameValidationError: true, })
                hasError = true;
            }
            if (! /([a-zA-Z])$/.test(this.state.lastName)) {
                this.setState({ lastNameValidationError: true, })
                hasError = true;
            }

        }

        // if(!this.state.aadharCardNumber || !this.state.panCardNumber) {  
        //     if(!this.state.aadharCardNumber) {this.setState({ aadharValidationError: true})        
        //     }  
        //     if(!this.state.panCardNumber) {this.setState({ panValidationError: true})        
        //     }       
        //     hasError = true
        // }

        if (!this.state.aadharCardNumber && !this.state.panCardNumber) {
            this.setState({ aadharValidationError: true, panValidationError: true })

            hasError = true
        }
        if (!this.state.panCardNumber && (this.state.fileNameForPan || this.props.business.panCardImage)) {
            this.setState({ panValidationError: true });
            hasError = true;
        }

        if (!this.state.aadharCardNumber && (this.state.fileNameForAadhar || this.props.business.aadharCardImage)) {
            this.setState({ aadharValidationError: true });
            hasError = true;
        }



        if (!this.state.aadharCardNumber && this.state.panCardNumber) {
            if ((!this.state.fileNameForPan && !this.props.business.panCardImage) || ! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber)) {
                this.setState({ panValidationError: true });
                hasError = true;
            }
        }

        if (!this.state.panCardNumber && this.state.aadharCardNumber) {
            if ((!this.state.fileNameForAadhar && !this.props.business.aadharCardImage) || ! /([0-9]){12}$/.test(this.state.aadharCardNumber)) {
                this.setState({ aadharValidationError: true });
                hasError = true;
            }
        }

        if (this.state.panCardNumber) {
            if ((!this.state.fileNameForPan && !this.props.business.panCardImage) || ! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber)) {
                this.setState({ panValidationError: true, });
                hasError = true;
            }
        }

        if (this.state.aadharCardNumber) {
            if ((!this.state.fileNameForAadhar && !this.props.business.aadharCardImage) || ! /([0-9]){12}$/.test(this.state.aadharCardNumber)) {
                this.setState({ aadharValidationError: true, });
                hasError = true;
            }
        }

        if (!hasError) {
            if (this.state.imgfileForAadhar) {
                let payload = {
                    file: this.state.imgfileForAadhar,
                    fileName: this.state.fileNameForAadhar,
                    type: "aadhar",
                    serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id
                };
                this.props.updateAadharCard(payload);
                // console.log("------AADHAR PAYLOAD-------",payload)
            }
            if (this.state.imgfileForPan) {
                let payload = {
                    file: this.state.imgfileForPan,
                    fileName: this.state.fileNameForPan,
                    type: "pan",
                    serviceProviderId: this.props.dashboard.setSelectedBusiness.ownerId._id
                };
                this.props.updatePanCard(payload);
                // console.log("------PAN PAYLOAD-------",payload)
            }
            this.setState({
                phoneValidationError: false,
                aadharValidationError: false,
                panValidationError: false,
                firstNameValidationError: false,
                lastNameValidationError: false,
                mobileValidationSpaceError:false,
                mobileValidationError:false

            });
            let data = {
                _id: this.props.dashboard.setSelectedBusiness.ownerId._id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                primaryMobileNumber: this.state.primaryMobileNumber,
                mobileNumbers: this.state.mobileNumbers,
                businessName: this.props.dashboard.setSelectedBusiness.businessName,
                "personalDetails.aadhar.number": this.state.aadharCardNumber,
                "personalDetails.panCard.number": this.state.panCardNumber,
                email: this.state.email
            };
            // console.log("----DATA ON ACTION-----",data);
            if (this.state.fileNameForAadhar) {
                data["personalDetails.aadhar.fileName"] = this.state.fileNameForAadhar;
            }
            if (this.state.fileNameForPan) {
                data["personalDetails.panCard.fileName"] = this.state.fileNameForPan;
            }
            await this.props.updateBusinessPersonalDetails(data);
            this.editDetailsFalse();
            let id = { businessId: this.props.dashboard.setSelectedBusiness._id };
            await this.props.getBusinessById(id);
            // console.log("------data for personal details------",data);
            this.props.getAadharServPro(data._id);

            this.props.getPanServPro(data._id)

        }
    };

    handleChangeTab = (event, newValue) => {
        this.setState({ value: newValue });
    };

    handleContractTab = (event, newValue) => {
        this.setState({ contractTab: newValue });
    };

    setfalse() {
        this.setState({ editAmenities: false });
    };

    handleModalBusinessPhotosOpen = (servicePhotos) => {
        this.setState({ servicePhotos });
        this.setState({ modalBusinessPhoto: true });
    };

    handleModalBusinessPhotosClose = () => {
        this.setState({ modalBusinessPhoto: false });
    };

    handleExpandClick = (index) => {
        let expArray = this.state.expanded.slice();
        expArray[index] = !expArray[index];
        this.setState({ expanded: expArray });
    };

    handleModalOpen = (index) => {
        let modalArray = this.state.modalOpen.slice();
        modalArray[index] = true;
        this.setState({ modalOpen: modalArray });
    };

    handleModalClose = (index) => {
        let modalArray = this.state.modalOpen.slice();
        modalArray[index] = false;
        this.setState({ modalOpen: modalArray });
    };

    handleServiceListItemClick = (service) => { };
    setOrder = (order) => {
        this.setState({ order }, () => this.memberDetails());
    };

    setOrderBy = (orderBy) => {
        this.setState({ orderBy }, () => this.memberDetails());
    };

    setPage = (page) => {
        this.setState({ page }, () => this.memberDetails());
    };

    setPageDeliverySettlementTable = (deliverySettlementTablePage) => {
        this.setState({ deliverySettlementTablePage }, () => this.getSettlementsRecords());
    };

    setRowsPerPage = (rowsPerPage) => {
        this.setState({ rowsPerPage });
    };

    setRowsPerPageDeliverySettlementTable = (rowsPerPageDeliverySettlementTable) => {
        this.setState({ rowsPerPageDeliverySettlementTable });
    };

    handleSearchChange = (event) => {
        this.setState({ searchValue: event.target.value });
    };

    handleSearchSubmit = (event) => {
        event.preventDefault();
        if (this.state.searchValue.trim()) {
            this.setState(
                {
                    page: 0,
                    clearSearch: true,
                },
                () => this.memberDetails()
            );
        } else {
            this.setState({ clearSearch: false, searchValue: "" }, () =>
                this.memberDetails()
            );
        }
    };

    getServiceDeliveryPartner = async ()=>{
        this.setState({ isFetching: true });
        let payload = {id :  this.props.dashboard.setSelectedBusiness._id}
        await this.props.getServiceDeliveryPartner(payload);
        this.setIsDeliveryByPartner()
        this.setState({ isFetching: false });
    }

    setIsDeliveryByPartner = async () => {

        this.setState({
            isDeliveryByPartnerAllowd: this.props.dashboard.setSelectedBusiness.isDeliveryPartnerAllowed,
            isDeliveryByPartner: this.props.dashboard.setSelectedBusiness.isDeliveryByPartner
        });

        if (this.props.dashboard.setSelectedBusiness.isDeliveryPartnerAllowed &&
            this.props.dashboard.setSelectedBusiness.isDeliveryByPartner) {
               let serviceDeliveryPriority = this.props.deliveries?.servicePartnerDelivery
               if (serviceDeliveryPriority && serviceDeliveryPriority.length) {
                let dunzDiscount    = serviceDeliveryPriority.find(e=> e.delivery_partner === 'dunzo')
                let borzoDiscount   = serviceDeliveryPriority.find(e=> e.delivery_partner === 'borzo')
                let porterDiscount  = serviceDeliveryPriority.find(e=> e.delivery_partner === 'porter')
                let shadowfaxDiscount  = serviceDeliveryPriority.find(e=> e.delivery_partner === 'shadowfax')
                let xirifyDeliveryD    = serviceDeliveryPriority.find(e=> e.delivery_partner === 'xirify_delivery')
                this.setState({ serviceDeliveryPriority: serviceDeliveryPriority , dunzoDiscount : dunzDiscount?.discountOnDelivery  , borzoDiscount  : borzoDiscount?.discountOnDelivery ,porterDiscount : porterDiscount?.discountOnDelivery ,
                    shadowFaxDiscount: shadowfaxDiscount?.discountOnDelivery  ,xirifyDeliveryDiscount :   xirifyDeliveryD?.discountOnDelivery   });
               }
         
           
        }
    };

    handleDeliveryPartnerAvailabilitySwitchChange = async (event, data) => {
        this.setState({
            isDeliveryByPartnerAllowd: event.target.checked
        });
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            data: {
                isDeliveryPartnerAllowed: event.target.checked,
                businessName: this.props.dashboard.setSelectedBusiness.businessName,
                isDeliveryByPartner: event.target.checked

            }
        }
        if (!event.target.checked) {
            payload.data.isDeliveryByPartner = false
        }

        if (event.target.checked){
            if (typeof this.state.serviceDeliveryPriority === 'undefined' || this.state.serviceDeliveryPriority === null || (this.state.serviceDeliveryPriority && this.state.serviceDeliveryPriority.length === 0)) {
            this.state.serviceDeliveryPriority = [
                {
                    delivery_partner: "dunzo",
                    priority: 1
                },
                {
                    delivery_partner: "borzo",
                    priority: 2
                },
                {
                    delivery_partner: "porter",
                    priority: 3
                },
                {
                    delivery_partner: "shadowfax",
                    priority: 4
                },
                {
                    delivery_partner: "xirify_delivery",
                    priority: 5
                }                
            ];
            this.setState({
                serviceDeliveryPriority: [
                    {
                        delivery_partner: "dunzo",
                        priority: 1
                    },
                    {
                        delivery_partner: "borzo",
                        priority: 2
                    },
                    {
                        delivery_partner: "porter",
                        priority: 3
                    },
                    {
                        delivery_partner: "shadowfax",
                        priority: 4
                    },
                    {
                        delivery_partner: "xirify_delivery",
                        priority: 5
                    }        
                ]
            })
            let payloadPriority = {
                businessId: this.props.dashboard.setSelectedBusiness._id,
                data: {
                    serviceDeliveryPriority: this.state.serviceDeliveryPriority
                }
            }

           await this.props.updateDeliveryPartnerPriority(payloadPriority);
        }
            if (typeof this.state.deliveryPartnerPriorityAsKm === 'undefined' || this.state.deliveryPartnerPriorityAsKm === null || (this.state.deliveryPartnerPriorityAsKm && this.state.deliveryPartnerPriorityAsKm.length === 0)) {
                this.state.deliveryPartnerPriorityAsKm = [
                    {
                        delivery_partner: "dunzo",
                        priority: 1
                    },
                    {
                        delivery_partner: "borzo",
                        priority: 2
                    },
                    {
                        delivery_partner: "porter",
                        priority: 3
                    },
                    {
                        delivery_partner: "shadowfax",
                        priority: 4
                    },
                    {
                        delivery_partner: "xirify_delivery",
                        priority: 5
                    }        
                ];
                this.setState({
                    deliveryPartnerPriorityAsKm: [
                        {
                            delivery_partner: "dunzo",
                            priority: 1
                        },
                        {
                            delivery_partner: "borzo",
                            priority: 2
                        },
                        {
                            delivery_partner: "porter",
                            priority: 3
                        },
                        {
                            delivery_partner: "shadowfax",
                            priority: 4
                        },
                        {
                            delivery_partner: "xirify_delivery",
                            priority: 5
                        }                           
                    ]
                })
                let payloadPriority = {
                    businessId: this.props.dashboard.setSelectedBusiness._id,
                    data: {
                        deliveryPartnerPriorityAsKm: this.state.deliveryPartnerPriorityAsKm
                    }
                }

                await this.props.updateDeliveryPartnerPriority(payloadPriority);
            }
       }



        var result = await this.props.updateDeliveryPartnerAvailability(payload);
        if (result === null) {
            this.setState({
                isDeliveryByPartner: payload.data.isDeliveryByPartner,
            });
        }

    };

    handleClearButton = () => {
        this.setState(
            {
                page: 0,
                searchValue: "",
                clearSearch: false,
            },
            () => this.memberDetails()
        );
    };

    memberDetails = () => {
        let data = {
            searchValue: this.state.searchValue,
            businessId: this.props.dashboard.setSelectedBusiness._id,
            skip: this.state.page * this.state.rowsPerPage,
            limit: this.state.rowsPerPage,
            orderBy: this.state.orderBy,
            order: Number(this.state.order),           
        };
        this.props.getMembersList(data);
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.business.busStateChgSuccess !== null &&
            this.state.snackOpen === false
        ) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true });
        } else if (
            this.props.business.busStateChgErr !== null &&
            this.state.snackOpen === false
        ) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true });
        } else if (
            this.props.contract.contractSucessMsg !== null &&
            this.state.snackOpen === false
        ) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true });
        } else if (
            this.props.contract.contractErrorMsg !== null &&
            this.state.snackOpen === false
        ) {
            this.setState({ backDropOpen: false });
            this.setState({ snackOpen: true });
        }  
        if(this.props.isFetching===false){
		    if (this.props.sucessMsg) {
		    	this.setState({ sucessMsg: this.props.sucessMsg,snackOpen: true })
		    	this.props.clearMessage();
		    	this.fetchConsumersByQuery();
		    } else if (this.props.errorMsg) {
		    	this.setState({ errorMsg: this.props.errorMsg,snackOpen: true })
		    	this.props.clearMessage();
		    }
        }
    };
    
    handleSnackBusinessUserClose = () => {this.setState({ snackOpen: false,sucessMsg: null,errorMsg: null, editMode: false })};

    handleSnackClose = () => {
        let businessStatus = this.props.dashboard.setSelectedBusiness
            .businessStatus;
        let sdpError = this.state.sdpError;
        let sdpSuccess = this.state.sdpSuccess;
        let convFeesSuccess = this.state.convFeesSuccess;
        let convFeesError = this.state.convFeesError;

        this.setState({ snackOpen: false });
        this.props.clearBusiErrorMsg();
        this.props.clearBusiSucessMsg();
        this.props.clearContractMsgs();
        this.setState({ buttonDisabled: false });
        this.setState({ sdpError: "", sdpSuccess: "" });
        this.setState({ convFeesSuccess: "", convFeesError: "" });

        /* 
         * In case where sdpError || sdpSuccess || convFeesSuccess || convFeesError 
         * is set return from the function to stay on the same page 
         */
        if (sdpError || sdpSuccess || convFeesSuccess || convFeesError) {
            return;
        }

        /*
         * The business Status is equal to the status which was worked on when
         * FullBesinessDetails component was opened. Which leads to appropriate
         * going on the appropriate Business List
         */

        if (businessStatus === "DISABLED") {
            this.props.history.push("/disabledBusiness");
        } else if (businessStatus === "APPROVED") {
            this.props.history.push("/approvedBusiness");
        }
    };

    /* Calls the action to Approve the Business */
    handleApprove = () => {
        this.setState({ enableDialogOpen: false });
        this.props.approveBusiness(
            this.props.dashboard.setSelectedBusiness._id,
            this.props.dashboard.setSelectedBusiness.businessName
        );
        this.setState({ backDropOpen: true });
        this.setState({ buttonDisabled: true });
    };

    /* Calls the action to Disable the Business */
    handleDisable = () => {
        this.setState({ disableDialogOpen: false });
        this.props.disableBusiness(
            this.props.dashboard.setSelectedBusiness._id,
            this.props.dashboard.setSelectedBusiness.businessName
        );
        this.setState({ backDropOpen: true });
        this.setState({ buttonDisabled: true });
    };

    /* Handlers to open Enable Business Dialog box */
    handleEnableDialogOpen = () => {
        this.setState({ enableDialogOpen: true });
    };

    /* Handlers to close Enable Business Dialog box */
    handleEnableDialogClose = () => {
        this.setState({ enableDialogOpen: false });
    };

    /* Handlers to open Disable Business Dialog box */
    handleDisableDialogOpen = () => {
        this.setState({ disableDialogOpen: true });
    };

    /* Handlers to close Disable Business Dialog box */
    handleDisableDialogClose = () => {
        this.setState({ disableDialogOpen: false });
    };

    /* Handlers to open Add payment Modal */
    handlePaymentModalOpen = (contract) => {
        this.setState({ selectedContract: contract, paymentModalOpen: true });
    };

    /* Handlers to close Add payment Modal */
    handlePaymentModalClose = () => {
        this.setState({ paymentModalOpen: false });
    };

    handleDisableDialogOpen1 = () => {
        this.setState({ disableDialogOpen1: true });
    };

    /* Handlers to close Disable Business Dialog box */
    handleDisableDialogClose1 = () => {
        this.setState({ disableDialogOpen1: false });
    };

    handleDisableDialogOpen2 = () => {
        this.setState({ disableDialogOpen1: false });
        this.setState({ disableDialogOpen2: true });
    };

     /* Handlers to close Disable Business Dialog box */
     handleDisableDialogClose2 = () => {
        this.setState({ disableDialogOpen2: false });
    };
   
    handlereset = async() =>{
        // this.props.deleteAllServices(this.props.businessDetails._id)
        let payload = {
            busId:this.props.dashboard.setSelectedBusiness._id,
         
        }
        let payload2 = {
            id:this.props.dashboard.setSelectedBusiness._id,
         
        }

        await this.props.deleteAllServices(payload);
        this.setState({ disableDialogOpen2: false });
        this.setState({ disableDialogOpen1: false });
        await this.props.getServicesByBusinessId(payload2);
        await this.props.getAllVariationGroups(payload2);
        await this.props.getHeadByBusinessId(payload2);
        await this.props.getAllAddonList(payload2);
        await this.props.getHeadSubheadHierarchy(payload2);
        await this.props.getAllItemsLinkedWithAddons(payload2);
        await this.props.getAllAddOnGroups(payload2)
        this.props.getHeadWithServicesByBusinessId(payload2.id);
        // this.fetchServices();
        

    }

    /* Will add payment against contact/ update contract */
    addPayment = (props) => {
        let payload = {
            contractId: this.state.selectedContract._id,
            data: {
                $push: { payments: props },
            },
        };
        this.props.updateContract(payload);
        this.handlePaymentModalClose();
        this.setState({ backDropOpen: true });
    };

    /* Handlers to open Business contract modal */
    handleContractModalOpen = () => {
        this.setState({ contractModalOpen: true });
    };

    /* Handlers to open Business contract modal */
    handleContractModalClose = () => {
        this.setState({ contractModalOpen: false });
    };

    /* Handlers to open Business contract modal */
    addContract = (props) => {
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            businessName: this.props.dashboard.setSelectedBusiness.businessName,
            businessStatus: this.props.dashboard.setSelectedBusiness.businessStatus,
            data: {
                businessId: this.props.dashboard.setSelectedBusiness._id,
                startDate: props.contractStartDate,
                endDate: props.contractEndDate,
                agreedAmount: Number(props.agreedContrctAmount),
                isActive: true,
                payments: [
                    {
                        amount: Number(props.amount),
                        date: new Date(),
                        mode: props.mode,
                        note: props.note,
                    },
                ],
                description: props.description,
            },
        };
        this.props.addContract(payload);
        this.handleContractModalClose();
        this.setState({ backDropOpen: true });
    };

    openSettleModal = async (rows) => {
        await this.setState({ selectedSettlementRecords: rows });
        this.setState({ settleModal: true });
    };

    openNotesModal = async (rows) => {
        if (rows && rows.length > 0) {
            await this.setState({ selectedSettlementRecords: rows });
            this.setState({ openNotesModal: true });
            await this.props.getSettlementsNotes({ settlementId: rows[0].settlementId });
        }
    };

    onClose = async () => {
        this.setState({
            settleModal: false,
            openNotesModal: false
        });
        await this.props.clearNotesRecord();
    };

    saveAndClose = async () => {
        this.onClose();
        this.getSettlementsRecords();
        // await this.props.getSettlementsRecords({ businessid: this.props.dashboard.setSelectedBusiness._id });
    };

    handleDeliveryPartnerOnOFF = (e, deliverPartnerName) => {
        if (typeof this.state.serviceDeliveryPriority === 'undefined' || this.state.serviceDeliveryPriority === null) {
            this.state.serviceDeliveryPriority = [];
        }

        let sdp = [...this.state.serviceDeliveryPriority];
        if (e.target.checked) {
            sdp.push(
                {
                    "delivery_partner": deliverPartnerName
                }
            );
        } else {
            sdp = this.state.serviceDeliveryPriority.filter(sdpObj => sdpObj.delivery_partner !== deliverPartnerName);
            sdp = sdp.map((sdpObj) => { 
                if (sdpObj.priority > 1) {
                    sdpObj.priority--;
                }
                else if (sdpObj.priority > 2) {
                    sdpObj.priority--;
                }
                return sdpObj;
            })
        }
        this.setState({ serviceDeliveryPriority: sdp });
    };

    handleDeliveryPartnerOnOFFBasedOnRadius = (e, deliverPartnerName) => {
        if (typeof this.state.deliveryPartnerPriorityAsKm === 'undefined' || this.state.deliveryPartnerPriorityAsKm === null) {
            this.state.deliveryPartnerPriorityAsKm = [];
        }

        let sdp = [...this.state.deliveryPartnerPriorityAsKm];
        if (e.target.checked) {
            sdp.push(
                {
                    "delivery_partner": deliverPartnerName
                }
            );
        } else {
            sdp = this.state.deliveryPartnerPriorityAsKm.filter(sdpObj => sdpObj.delivery_partner !== deliverPartnerName);
            sdp = sdp.map((sdpObj) => {
              //  console.log("sdpObj --->", sdpObj);
                if (sdpObj.priority > 1) {
                    sdpObj.priority--;
                }
                return sdpObj;
            })
        }
        this.setState({ deliveryPartnerPriorityAsKm: sdp });
    };


    handleDeliveryPriority = (e, deliverPartnerName) => {
        let sdp = [...this.state.serviceDeliveryPriority];
        // console.log("sdp",sdp)
        let index = sdp.findIndex((sdpObj) => sdpObj.delivery_partner === deliverPartnerName);
        sdp[index].priority = e.target.value;
        this.setState({ serviceDeliveryPriority: sdp });
    };

    handleDeliveryPriorityBasedOnRadius =  (e, deliverPartnerName) => {
        let sdp = [...this.state.deliveryPartnerPriorityAsKm];
      //  console.log("sdp",sdp)
        let index = sdp.findIndex((sdpObj) => sdpObj.delivery_partner === deliverPartnerName);
        sdp[index].priority = e.target.value;
        this.setState({ deliveryPartnerPriorityAsKm: sdp });
    };

    saveDeliveryPriority = async () => {
        if (this.state.serviceDeliveryPriority.find(sdpObj => !sdpObj.priority || sdpObj.priority === '') != null) {
            this.setState({ sdpError: "Service Delivery Partner Priority is required" });
            this.setState({ snackOpen: true });
            return;
        } else if (this.state.serviceDeliveryPriority.length === 0) {
            this.setState({ sdpError: "At least one Delivery Partner is required" });
            this.setState({ snackOpen: true });
            return;
        } else if (this.state.serviceDeliveryPriority.length === 1 &&
            this.state.serviceDeliveryPriority[0].priority > 1) {
               
            this.setState({ sdpError: "Set Priority of " + this.state.serviceDeliveryPriority[0].delivery_partner + " to 1." });
            this.setState({ snackOpen: true });
            return;
        } 
        else if (this.state.serviceDeliveryPriority.length === 2 &&
            this.state.serviceDeliveryPriority[0].priority > 2 ) {
              
            this.setState({ sdpError: "Set Priority of " + this.state.serviceDeliveryPriority[0].delivery_partner +" and " + this.state.serviceDeliveryPriority[1].delivery_partner + " to 1 and 2." });
           
            this.setState({ snackOpen: true });
            return;
        }  else if (this.state.serviceDeliveryPriority.length === 2 &&
            this.state.serviceDeliveryPriority[1].priority > 2 ) {
             
            this.setState({ sdpError: "Set Priority of " + this.state.serviceDeliveryPriority[0].delivery_partner +" and " + this.state.serviceDeliveryPriority[1].delivery_partner + " to 1 and 2." });
           
            this.setState({ snackOpen: true });
            return;
        }
        
        else {
            let dupLength = _.uniq(this.state.serviceDeliveryPriority.map(item => item.priority)).length;
            if (dupLength !== this.state.serviceDeliveryPriority.length) {
                this.setState({ sdpError: "Service Delivery Partner Priority has to be unique" });
                this.setState({ snackOpen: true });
                return;
            }
        }

        if (this.state.deliveryParterRaduisFlag && !this.state.servicePartnerDeliveryRadiusInKm) {
            this.setState({ radiusFieldRequired: true });
            return;
        }
        if (this.state.deliveryParterRaduisFlag && this.state.servicePartnerDeliveryRadiusInKm < 0) {
            this.setState({ invalidRadiusField: true });
            return;
        }
        
        const {dunzoDiscount, borzoDiscount,porterDiscount ,shadowFaxDiscount ,xirifyDeliveryDiscount} = this.state
        let  deilveryDetailsArr = this.state.serviceDeliveryPriority;
        for (let delivery of deilveryDetailsArr) {
            if (delivery.delivery_partner === 'dunzo') {
                delivery.discountOnDelivery = Number(dunzoDiscount) > 0 ? Number(dunzoDiscount) : null;
            } else if (delivery.delivery_partner === 'borzo') {
                delivery.discountOnDelivery = Number(borzoDiscount) > 0 ? Number(borzoDiscount) : null;
            } else if (delivery.delivery_partner === 'porter') {
                delivery.discountOnDelivery = Number(porterDiscount) > 0 ? Number(porterDiscount) : null;
            } else if (delivery.delivery_partner === 'shadowfax') {
                delivery.discountOnDelivery = Number(shadowFaxDiscount) > 0 ? Number(shadowFaxDiscount) : null;
            } else {
                delivery.discountOnDelivery = Number(xirifyDeliveryDiscount) > 0 ? Number(xirifyDeliveryDiscount) : null;
            }
        }
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            data: {
                serviceDeliveryPriority: deilveryDetailsArr,
                priorityRadiusFlag: this.state.deliveryParterRaduisFlag,
                kmRadiusServiceDeliveryPriority: this.state.servicePartnerDeliveryRadiusInKm
            }
        }

        // console.log("PAYLOAD before saving ----->", payload);

        let result = await this.props.updateDeliveryPartnerPriority(payload);
        if (result === true) {
            
            this.setState({ sdpSuccess: "Successfully updated Service Delivery Partner Priority" });
            this.setState({ snackOpen: true });
            let id = { businessId: this.props.dashboard.setSelectedBusiness._id };        
            this.props.getBusinessById(id);
            this.getServiceDeliveryPartner()
        } else {
            this.setState({ sdpError: "Failed to update Service Delivery Partner Priority" });
            this.setState({ snackOpen: true });
        }
    };

    savePriorityBasedOnKmRadius = async () => {
        if (this.state.deliveryPartnerPriorityAsKm.find(sdpObj => !sdpObj.priority || sdpObj.priority === '') != null) {
            this.setState({ sdpError: "Service Delivery Partner Priority is required" });
            this.setState({ snackOpen: true });
            return;
        } else if (this.state.deliveryPartnerPriorityAsKm.length === 0) {
            this.setState({ sdpError: "At least one Delivery Partner is required" });
            this.setState({ snackOpen: true });
            return;
        } else if (this.state.deliveryPartnerPriorityAsKm.length === 1 &&
            this.state.deliveryPartnerPriorityAsKm[0].priority > 1) {
             //   console.log("@@@@@@@")
            this.setState({ sdpError: "Set Priority of " + this.state.deliveryPartnerPriorityAsKm[0].delivery_partner + " to 1." });
            this.setState({ snackOpen: true });
            return;
        } 
        else if (this.state.deliveryPartnerPriorityAsKm.length === 2 &&
            this.state.deliveryPartnerPriorityAsKm[0].priority > 2 ) {
             //   console.log("%%%%%%%%%%%")
            this.setState({ sdpError: "Set Priority of " + this.state.deliveryPartnerPriorityAsKm[0].delivery_partner +" and " + this.state.deliveryPartnerPriorityAsKm[1].delivery_partner + " to 1 and 2." });
           
            this.setState({ snackOpen: true });
            return;
        }  else if (this.state.deliveryPartnerPriorityAsKm.length === 2 &&
            this.state.deliveryPartnerPriorityAsKm[1].priority > 2 ) {
            //    console.log("*************")
            this.setState({ sdpError: "Set Priority of " + this.state.deliveryPartnerPriorityAsKm[0].delivery_partner +" and " + this.state.deliveryPartnerPriorityAsKm[1].delivery_partner + " to 1 and 2." });
           
            this.setState({ snackOpen: true });
            return;
        }
        
        else {
            let dupLength = _.uniq(this.state.deliveryPartnerPriorityAsKm.map(item => item.priority)).length;
            if (dupLength !== this.state.deliveryPartnerPriorityAsKm.length) {
                this.setState({ sdpError: "Service Delivery Partner Priority has to be unique" });
                this.setState({ snackOpen: true });
                return;
            }
        }
        const {dunzoRadiusDiscount,borzoRadiusDiscount,porterRadiusDiscount,shadowfaxRadiusDiscount,xirifyDeliveryRadiusDiscount} = this.state
        let radiusDeliveryArr = this.state.deliveryPartnerPriorityAsKm;
        for (let delivery of radiusDeliveryArr) {
            if (delivery.delivery_partner === 'dunzo') {
                delivery.discountOnDelivery = Number(dunzoRadiusDiscount) > 0 ? Number(dunzoRadiusDiscount) : null;
            } else if (delivery.delivery_partner === 'borzo') {
                delivery.discountOnDelivery = Number(borzoRadiusDiscount) > 0 ? Number(borzoRadiusDiscount) : null;
            } else if (delivery.delivery_partner === 'porter') {
                delivery.discountOnDelivery = Number(porterRadiusDiscount) > 0 ? Number(porterRadiusDiscount) : null;
            } else if (delivery.delivery_partner === 'shadowfax') {
                delivery.discountOnDelivery = Number(shadowfaxRadiusDiscount) > 0 ? Number(shadowfaxRadiusDiscount) : null;
            } else {
                delivery.discountOnDelivery = Number(xirifyDeliveryRadiusDiscount) > 0 ? Number(xirifyDeliveryRadiusDiscount) : null;
            }
        }
        
        let payload = {
            businessId: this.props.dashboard.setSelectedBusiness._id,
            data: {
                deliveryPartnerPriorityAsKm: radiusDeliveryArr
            }
        }
        // console.log("PAYLOAD before saving for other section ----->", payload);
        let result = await this.props.updateDeliveryPartnerPriority(payload);
     
        if (result === true) {
            this.setState({ sdpSuccess: "Successfully updated Service Delivery Partner Priority" });
            this.setState({ snackOpen: true });
             let id = { businessId: this.props.dashboard.setSelectedBusiness._id };   
             this.props.getBusinessById(id);

        } else {
            this.setState({ sdpError: "Failed to update Service Delivery Partner Priority" });
            this.setState({ snackOpen: true });
        }
    };

    handleAddTransactionsCharges = () => {
        this.setState({ contractModalOpen: true });
    };

    handleAddTxnButMenuClick = (event, index) => {
        this.setState({ selectedIndex: index });
        this.setState({ openTxnChrButton: false });
    };

    handleAddTxnButClick = () => {
        this.convFeesFormChanged();
        // console.info(`You clicked ${options[this.state.selectedIndex]}`, this.state.selectedIndex);
        if (this.state.selectedIndex === 0) {
            let ccf = this.state.consumerConvFees;
            ccf.push({ isActive: false, feeType: 'flat', isNew: true, error: null });
            this.ccfRefs.push(React.createRef());
            this.setState({ consumerConvFees: ccf });
        } else if (this.state.selectedIndex === 1) {
            let bcf = this.state.businessConvFees;
            bcf.push({ isActive: false, feeType: 'flat', isNew: true, error: null });
            this.bcfRefs.push(React.createRef());
            this.setState({ businessConvFees: bcf });
        }
    };

    handleToggleAddTxn = () => {
        this.setState({ openTxnChrButton: !this.state.openTxnChrButton });
    };

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ openTxnChrButton: false });
    };

    deleteTxnCharges = (index, txnChargestype) => {
        this.convFeesFormChanged();
        if (txnChargestype === "businessConvFees") {
            let bcf = this.state.businessConvFees;
            bcf.splice(index, 1);
            this.setState({ businessConvFees: bcf });
            this.bcfRefs.splice(index, 1);
        } else if (txnChargestype === "consumerConvFees") {
            let ccf = this.state.consumerConvFees;
            ccf.splice(index, 1);
            this.setState({ consumerConvFees: ccf });
            this.ccfRefs.splice(index, 1);
        }
    };

    openDelTxnChargesDialog = (convFeesIndex, txnChargestype) => {
        dialogState = { txnChargestype, convFeesIndex };
        if (txnChargestype === "businessConvFees") {
            dialogState.label = "Business Convenience Charges Section"
        } else if (txnChargestype === "consumerConvFees") {
            dialogState.label = "Consumer Convenience Charges Section"
        }
        this.setState({ isDelTxnChrgsDialogOpen: true });
    };

    handleChgFeeType = (index, feeType, txnChargestype) => {
        // console.log("txnChargestype", txnChargestype);
        // console.log("index", index);

        // console.log("businessConvFees array", this.state.businessConvFees);
        // console.log("businessConvFees array", this.state.businessConvFees);
        // console.log("bcf element at index: ", index, bcf[index]);
        // console.log("businessConvFees:", this.state.businessConvFees);

        if (txnChargestype === "businessConvFees") {
            let bcf = this.state.businessConvFees;
            bcf[index] = { isActive: bcf[index].isActive };
            bcf[index].feeType = feeType;
            if (feeType === "slab") {
                bcf[index].slabs = [
                    { from: '', to: '', chargeType: '', value: '', isNew: true }
                ]
            } else {
                bcf[index].isNew = true;
                bcf[index].value = '';
                bcf[index].chargeType = '';
            }
            this.setState({ businessConvFees: bcf });
        } else if (txnChargestype === "consumerConvFees") {
            let ccf = this.state.consumerConvFees;
            ccf[index] = { isActive: ccf[index].isActive };
            ccf[index].feeType = feeType;
            if (feeType === "slab") {
                ccf[index].slabs = [
                    { from: '', to: '', chargeType: '', value: '', isNew: true }
                ]
            } else {
                ccf[index].isNew = true;
                ccf[index].value = '';
                ccf[index].chargeType = '';
            }
            this.setState({ consumerConvFees: ccf });
        }
    };

    addTxnChargesRow = (txnChargestype, convFeesIndex, slabIndex) => {
        // console.log("addTxnChargesRow", txnChargestype, convFeesIndex, slabIndex);

        if (txnChargestype === "businessConvFees") {
            // console.log("businessConvFees", this.state.businessConvFees);
            let bcf = this.state.businessConvFees;
            bcf[convFeesIndex].slabs.splice(slabIndex + 1, 0, { from: '', to: '', chargeType: '', value: '', error: null });
            this.setState({ businessConvFees: bcf });
        } else if (txnChargestype === "consumerConvFees") {
            // console.log("businessConvFees", this.state.consumerConvFees);
            let ccf = this.state.consumerConvFees;
            ccf[convFeesIndex].slabs.splice(slabIndex + 1, 0, { from: '', to: '', chargeType: '', value: '', error: null });
            this.setState({ consumerConvFees: ccf });
        }
    };

    delTxnChargesRow = (txnChargestype, convFeesIndex, slabIndex, rowsToDeleteCount = 1) => {
        // console.log("delTxnChargesRow", txnChargestype, convFeesIndex, slabIndex);
        if (txnChargestype === "businessConvFees") {
            // console.log("businessConvFees", this.state.businessConvFees);
            let bcf = this.state.businessConvFees;
            bcf[convFeesIndex].slabs.splice(slabIndex, rowsToDeleteCount);
            this.setState({ businessConvFees: bcf });
        } else if (txnChargestype === "consumerConvFees") {
            // console.log("consumerConvFees", this.state.consumerConvFees);
            let ccf = this.state.consumerConvFees;
            ccf[convFeesIndex].slabs.splice(slabIndex, rowsToDeleteCount);
            this.setState({ consumerConvFees: ccf });
        }
        this.convFeesFormChanged();
    };

    openDelConfirmDialog = (txnChargestype, convFeesIndex, slabIndex, cb) => {
        dialogState = { txnChargestype, convFeesIndex, slabIndex, cb };
        this.setState({ isDelTxnDialogOpen: true });
    };

    openSubsequentTxnRowDelConfirmDialog = (txnChargestype, convFeesIndex, slabIndex, cb) => {
        subsequentRowDeleteDialogState = { txnChargestype, convFeesIndex, slabIndex, cb };
        if (txnChargestype === "businessConvFees") {
            if (this.state.businessConvFees[convFeesIndex].slabs.length === (slabIndex + 1)) {
                return cb(this.delTxnChargesRow);
            }
        } else if (txnChargestype === "consumerConvFees") {
            if (this.state.consumerConvFees[convFeesIndex].slabs.length === (slabIndex + 1)) {
                return cb(this.delTxnChargesRow);
            }
        }
        this.setState({ isSubsequentDeleteTxnDialogOpen: true });
    };

    editTxnChargesRow = (txnChargestype, convFeesIndex, slabIndex) => {
        // console.log("editTxnChargesRowValue", txnChargestype, convFeesIndex, slabIndex);
      //  console.log("editTxnChargesRowValue");
    };

    changeTxnChargesRow = (value, txnChargestype, convFeesIndex, feeType, slabIndex, keyName) => {
        // console.log("changeTxnChargesRow", value, txnChargestype, convFeesIndex, feeType, slabIndex, keyName);
        if (txnChargestype === "businessConvFees") {
            let bcf = this.state.businessConvFees;
            if (feeType === 'slab') {
                bcf[convFeesIndex].slabs[slabIndex][keyName] = value;
            } else {
                bcf[convFeesIndex][keyName] = value;
                delete bcf[convFeesIndex].isNew;
            }
            this.setState({ businessConvFees: bcf });
            // console.log("businessConvFees", this.state.businessConvFees);

        } else if (txnChargestype === "consumerConvFees") {
            // console.log("consumerConvFees", this.state.consumerConvFees);
            let ccf = this.state.consumerConvFees;
            if (feeType === 'slab') {
                ccf[convFeesIndex].slabs[slabIndex][keyName] = value;
            } else {
                ccf[convFeesIndex][keyName] = value;
                delete ccf[convFeesIndex].isNew;
            }
            this.setState({ consumerConvFees: ccf });
            // console.log("consumerConvFees", this.state.consumerConvFees);
        }
        this.convFeesFormChanged();
    };

    handleConvFeesActiveState = (value, index, txnChargestype) => {
        if (txnChargestype === "businessConvFees") {
            let bcf = this.state.businessConvFees;
            // console.log("handleConvFeesActiveState", bcf[index]);
            if (!bcf[index].hasOwnProperty("feeType")) {
                this.setState({ convFeesError: "Charges to Business cannot be activated without selecting the Fee Type." });
                this.setState({ snackOpen: true });
                return;
            }
            bcf[index].isActive = value;
            if (value === true) {
                bcf.forEach((bcfObj, bcfObjIndex) => {
                    if (index !== bcfObjIndex) {
                        bcfObj.isActive = false;
                    }
                });
            }
            this.setState({ businessConvFees: bcf });
        } else if (txnChargestype === "consumerConvFees") {
            let ccf = this.state.consumerConvFees;
            if (!ccf[index].hasOwnProperty("feeType")) {
                this.setState({ convFeesError: "Charges to Consumer cannot be activated without selecting the Fee Type." });
                this.setState({ snackOpen: true });
                return;
            }
            ccf[index].isActive = value;
            if (value === true) {
                ccf.forEach((ccfObj, ccfObjIndex) => {
                    if (index !== ccfObjIndex) {
                        ccfObj.isActive = false;
                    }
                });
            }
            this.setState({ consumerConvFees: ccf });
        }
    };

    handleBusinessMemberStatus = async (businessMemberStatus, updatedObj) => {      
        await this.props.updateBusinessPersonalDetails(updatedObj);
        this.setState({ businessMemberStatus });

    }
 
    handleBusinessUserRole = async (businessUserRole, updatedObj) => {      
        // await this.props.updateBusinessPersonalDetails(updatedObj);
        this.setState({ businessUserRole});

    }
    updateRoleStatus = (toBeUpdatedObj,newRoleStatus) => { this.setState({ statusData: toBeUpdatedObj, confirmDialogFlag: true, newRoleStatus: newRoleStatus });}

    changeNewRoleFlag = (newRole,row,toBeUpdatedObj) => {  
    this.setState({ selectedStatus: newRole , editIndex: toBeUpdatedObj})}

    handleDeleteClose = () => { 
        this.setState({ confirmDialogFlag: false, editStatusFlag:false, selectedStatus: ""}) 
        this.memberDetails()};
    changeEditStatusFlag = (f,i) => {this.setState({ editStatusFlag: f , editIndex: i})}
    handleChangeRole = async () => {  
        this.setState({confirmDialogFlag:false})        
        let payload = { updatedObj : this.state.editIndex,
                       businessId :this.props.dashboard.setSelectedBusiness._id} 

        await this.props.updateServiceProviderForUserRoleFromWeb(payload);
        this.setState({editStatusFlag:false,selectedStatus:""})
        this.setState({ sucessMsg: "Business User Role Updated Successfully" })
        this.setState({ snackOpen: true });       
        // this.fetchConsumersByQuery();

    };

    handleDeliveryPartnerRadiusOnOFF = async (event) => {
        this.setState({ deliveryParterRaduisFlag: event.target.checked });
    };
    
    handleDeliveryDiscount =(event)=>{
       this.setState({ [event.target.name] : event.target.value })
    };


    render() {
        //  console.log("this.state in fullbusinessdetails...........>",this.state);
        // console.log("this.state in fullbusinessdetails...........>",this.state);
        // console.log("this.state.specialityListArr", this.state.specialityListArr);
        // console.log("this.state.cuisineListArr", this.state.cuisineListArr);
        // console.log(this.state.selectedStatus)
        const { classes } = this.props;
        let mobileNumbers = null;
        let muiAlert = null;
        let businessStatus = this.props.dashboard.setSelectedBusiness
            .businessStatus;
        let footerButton = null;
        let paymentMethodOptions = [];
        let dunzop = this.state.serviceDeliveryPriority &&
            this.state.serviceDeliveryPriority.find(sdpObj => sdpObj.delivery_partner === 'dunzo');
        let borzop = this.state.serviceDeliveryPriority &&
            this.state.serviceDeliveryPriority.find(sdpObj => sdpObj.delivery_partner === 'borzo');
        // console.log("borzop",borzop)
        let xirify_delivery = this.state.serviceDeliveryPriority &&
            this.state.serviceDeliveryPriority.find(sdpObj => sdpObj.delivery_partner === 'xirify_delivery');
        // console.log("xirifydelivery",xirify_delivery);

        let porterp = this.state.serviceDeliveryPriority &&
            this.state.serviceDeliveryPriority.find(sdpObj => sdpObj.delivery_partner === 'porter');
        // console.log("porter --> ", porterp);
        
        let shadowfaxp = this.state.serviceDeliveryPriority &&
            this.state.serviceDeliveryPriority.find(sdpObj => sdpObj.delivery_partner === 'shadowfax');
        // console.log("shadowfaxp--> ", shadowfaxp);

        // To set priorities as per KMS set
        let dunzoRadius = this.state.deliveryPartnerPriorityAsKm &&
            this.state.deliveryPartnerPriorityAsKm.find(sdpObj => sdpObj.delivery_partner === 'dunzo');

        let borzoRadius = this.state.deliveryPartnerPriorityAsKm &&
            this.state.deliveryPartnerPriorityAsKm.find(sdpObj => sdpObj.delivery_partner === 'borzo');

        let xirifyDeliveryRadius = this.state.deliveryPartnerPriorityAsKm &&
            this.state.deliveryPartnerPriorityAsKm.find(sdpObj => sdpObj.delivery_partner === 'xirify_delivery');

        let porterRadius = this.state.deliveryPartnerPriorityAsKm &&
            this.state.deliveryPartnerPriorityAsKm.find(sdpObj => sdpObj.delivery_partner === 'porter');

        let shadowfaxRadius = this.state.deliveryPartnerPriorityAsKm &&
            this.state.deliveryPartnerPriorityAsKm.find(sdpObj => sdpObj.delivery_partner === 'shadowfax');


        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.cod) {
            paymentMethodOptions.push("COD");
        }
        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.upi) {
            paymentMethodOptions.push("UPI");
        }
        if (
            this.props.dashboard.setSelectedBusiness.paymentMethodOptions.banktransfer
        ) {
            paymentMethodOptions.push("Bank Transfer");
        }
        if (this.props.dashboard.setSelectedBusiness.paymentMethodOptions.cheque) {
            paymentMethodOptions.push("Cheque");
        }

       
        if (this.props.dashboard.setSelectedBusiness.amenities) {
            this.props.dashboard.setSelectedBusiness.amenities.split(",");
        }

        let contractsArray =
            this.props.dashboard &&
            this.props.dashboard.setSelectedBusiness &&
            this.props.dashboard.setSelectedBusiness.contracts;
        contractsArray &&
            contractsArray.length > 0 &&
            contractsArray.forEach(function (item, i) {
                if (item.isActive) {
                    contractsArray.splice(i, 1);
                    contractsArray.unshift(item);
                }
            });

            if (this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers !==null && this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers.length > 0 && this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers !==""
            )  {
            mobileNumbers = this.props.dashboard.setSelectedBusiness.ownerId.mobileNumbers.join();
        } else {
            mobileNumbers = "None";
        }



        if (businessStatus === "APPROVED" ) {
            footerButton = (
                <Grid container spacing={3}>
                    <Grid item className={classes.gridItem} xs={12} sm={3}>
                    {
                    this.state.value === 3 ?
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.resetButtonDisabled}
                            onClick={this.handleDisableDialogOpen1}
                            //style={{  height: "40px", backgroundColor: "#f50505", color: "white" }}
                            style={{ height: "40px",color: this.props.user.role==="areaManager"?"white":"", backgroundColor: this.props.user.role === "areaManager" ? '#f50505' : '' }}
                        >
                            RESET
                        </Button>
                        : null
                    }
                    
                    </Grid>  
                    
                  
                    <Button
                        variant="contained"
                        color="secondary"
                        disabled={this.state.buttonDisabled}
                        onClick={this.handleDisableDialogOpen}
                        style={{ height: "40px" ,marginTop : "37px"}}
                    >
                        DISABLE
                    </Button>  

                </Grid>
            );
        } else if (businessStatus === "DISABLED") {
            footerButton = (
                <Grid container spacing={3}>
                    <Grid item className={classes.gridItem} xs={12} sm={6}>
                    {
                    this.state.value === 3 ?
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.resetButtonDisabled}
                            onClick={this.handleDisableDialogOpen1}
                            //style={{  height: "40px", backgroundColor: "#f50505", color: "white" }}
                            style={{ height: "40px",color: this.props.user.role==="areaManager"?"white":"", backgroundColor: this.props.user.role === "areaManager" ? '#f50505' : '' }}
                        >
                            RESET
                        </Button>
                        : null
                    }
                    </Grid>  
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.buttonDisabled}
                            onClick={this.handleEnableDialogOpen}
                            style={{ height: "40px" ,marginTop : "37px"}}
                        >
                            ENABLE
                        </Button>
                   
                </Grid>
            );
        }

        if (this.props.business.busStateChgSuccess) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="success"
                >
                    {this.props.business.busStateChgSuccess}
                </MuiAlert>
            );
        } else if (this.props.business.busStateChgErr) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="error"
                >
                    {this.props.business.busStateChgErr}
                </MuiAlert>
            );
        } else if (this.props.contract.contractSucessMsg) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="success"
                >
                    {this.props.contract.contractSucessMsg}
                </MuiAlert>
            );
        } else if (this.props.contract.contractErrorMsg) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="error"
                >
                    {this.props.contract.contractErrorMsg}
                </MuiAlert>
            );
        } else if (this.state.sdpError) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    onClose={this.handleSnackClose}
                >
                    {this.state.sdpError}
                </MuiAlert>
            );
        } else if (this.state.sdpSuccess) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="success"
                >
                    {this.state.sdpSuccess}
                </MuiAlert>
            );
        } else if (this.state.convFeesSuccess) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={this.handleSnackClose}
                    severity="success"
                >
                    {this.state.convFeesSuccess}
                </MuiAlert>
            );
        } else if (this.state.convFeesError) {
            muiAlert = (
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    severity="error"
                    onClose={this.handleSnackClose}
                >
                    {this.state.convFeesError}
                </MuiAlert>
            );
        }         

        let clearSearchButton = null;

        if (this.state.clearSearch) {
            clearSearchButton = (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleClearButton()}
                    className={classes.clearButton}
                >
                    Clear
                </Button>
            );
        }
        if (this.state.sucessMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackBusinessUserClose} severity="success">
				{this.state.sucessMsg}
			</MuiAlert>
		} else if (this.state.errorMsg) {
			muiAlert = <MuiAlert elevation={6} variant="filled" onClose={this.handleSnackBusinessUserClose} severity="error">
				{this.state.errorMsg}
			</MuiAlert>
		}
        console.log("this.props ===========>",this.props);
        let brandName = this.props.dashboard?.getMasterOTP && this.props.dashboard?.getMasterOTP !== undefined && this.props.dashboard?.getMasterOTP !== null && this.props.dashboard?.getMasterOTP.data && this.props.dashboard?.getMasterOTP.data.length && this.props?.dashboard?.getMasterOTP?.data[0]?.brandName ? this.props?.dashboard?.getMasterOTP?.data[0]?.brandName : this.props.env.BRAND_NAME;

        return (
            <div className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container className={classes.container}>
                    <Grid alignItems="center">
                        <Typography variant="h6" gutterBottom>
                            {this.props.dashboard.setSelectedBusiness.businessName}
                        </Typography>
                    </Grid>
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChangeTab}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="STATISTICS" className={classes.tabPadding} />
                        <Tab label="PERSONAL" className={classes.tabPadding} />
                        <Tab label="BUSINESS" className={classes.tabPadding} />
                        <Tab label="CATALOG" className={classes.tabPadding} />
                        <Tab label="CONTACTS" className={classes.tabPadding} />
                        <Tab label="CONTRACTS" className={classes.tabPadding} />
                        {this.props.user.role === "admin" ? <Tab label="DELIVERY PARTNER" className={classes.tabPadding} /> : null}
                        {this.props.user.role === "areaManager" ? <Tab label="DISCOUNTS" className={classes.tabPadding} /> : null}
                        <Tab label="SETTLEMENT" className={classes.tabPadding} />
                    </Tabs>
                    <TabPanel value={this.state.value} index={0}>
                        {
                            <div>
                                <Grid container spacing={2}>
                                    {this.props.business.lastReceivedOrder &&
                                        this.props.business.lastReceivedOrder.creationDate ? (
                                        <Grid item xs={4}>
                                            <Card>
                                                <CardContent>
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="textSecondary"
                                                        gutterBottom
                                                    >
                                                        Last Request
                                                    </Typography>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Request Date
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {moment(
                                                                    this.props.business.lastReceivedOrder
                                                                        .creationDate
                                                                ).format("DD MMM YYYY hh:mm A")}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Cost Type
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                {this.props.business.lastReceivedOrder &&
                                                                    this.props.business.lastReceivedOrder
                                                                        .services &&
                                                                    this.props.business.lastReceivedOrder.services
                                                                        .length > 0 &&
                                                                    this.props.business.lastReceivedOrder
                                                                        .services[0].cost.costType ? this.props.business.lastReceivedOrder &&
                                                                        this.props.business.lastReceivedOrder
                                                                            .services &&
                                                                        this.props.business.lastReceivedOrder.services
                                                                            .length > 0 &&
                                                                this.props.business.lastReceivedOrder
                                                                    .services[0].cost.costType : '-'}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Amount
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {this.props.business.lastReceivedOrder.amount ? this.props.business.lastReceivedOrder.amount.toFixed(2) : 0}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Request Status
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {
                                                                    this.props.business.lastReceivedOrder
                                                                        .currentStatus
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom
                                                                style={{ marginBottom: 0 }}
                                                            >
                                                                Payment Status
                                                            </Typography>
                                                            <Typography
                                                                variant="subtitle2"
                                                                display="block"
                                                                gutterBottom
                                                            >
                                                                {
                                                                    this.props.business.lastReceivedOrder
                                                                        .paymentStatus
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ) : null}
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Till Now
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueTillNow.totalRevenue ? this.props.business.revenueTillNow.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueTillNow.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Today
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueToday.totalRevenue ? this.props.business.revenueToday.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueToday.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Yesterday
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueYesterday.totalRevenue ? this.props.business.revenueYesterday.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueYesterday.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Weekly
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueWeekly.totalRevenue ? this.props.business.revenueWeekly.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueWeekly.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Monthly
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Amount:{" "}
                                                    {this.props.business.revenueMonthly.totalRevenue ? this.props.business.revenueMonthly.totalRevenue.toFixed(2) : 0}
                                                </Typography>
                                                <Typography variant="subtitle2" component="h2">
                                                    Requests: {this.props.business.revenueMonthly.count}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Orders
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.orders}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'orders')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.orders ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.orders ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.orders ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Cancel
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.cancel}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'cancel')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.cancel ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.cancel ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.cancel ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Shoppers
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.shoppers}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'shoppers')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.shoppers ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.shoppers ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.shoppers ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Settlement
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.settlement}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'settlement')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.settlement ?
                                                            'Enabled' : 'Disabled'}
                                                        style={{
                                                            fontSize: "10px", color: this.state.businessDashboardCards.settlement ? 'black' : 'red'
                                                        }}

                                                    /> : this.state.businessDashboardCards.settlement ?
                                                        'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Online Payment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            this.state.businessDashboardCards.settlement ?
                                                                <Switch
                                                                    size="small"
                                                                    checked={this.state.businessDashboardCards.onlinePayment}
                                                                    onChange={(e) => this.handleBusinessCardEnableChange(e, 'onlinePayment')}
                                                                    color="primary"
                                                                />
                                                                : <Switch
                                                                    size="small"
                                                                    checked={this.state.businessDashboardCards.onlinePayment}
                                                                    color="secondary"
                                                                    disabled
                                                                />
                                                        }
                                                        label={this.state.businessDashboardCards.settlement === true
                                                            && this.state.businessDashboardCards.onlinePayment ? 'Enabled' : 'Disabled'}
                                                        style={{
                                                            fontSize: "10px", color: (this.state.businessDashboardCards.settlement === true
                                                                && this.state.businessDashboardCards.onlinePayment) ? 'black' : 'red'
                                                        }}

                                                    /> : (this.state.businessDashboardCards.settlement === true
                                                        && this.state.businessDashboardCards.onlinePayment) ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Direct Payment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            this.state.businessDashboardCards.settlement ?
                                                                <Switch
                                                                    size="small"
                                                                    checked={this.state.businessDashboardCards.directPayment}
                                                                    onChange={(e) => this.handleBusinessCardEnableChange(e, 'directPayment')}
                                                                    color="primary"
                                                                />
                                                                : <Switch
                                                                    size="small"
                                                                    checked={this.state.businessDashboardCards.directPayment}
                                                                    color="secondary"
                                                                    disabled
                                                                />
                                                        }
                                                        label={(this.state.businessDashboardCards.settlement === true
                                                            && this.state.businessDashboardCards.directPayment) ? 'Enabled' : 'Disabled'}
                                                        style={{
                                                            fontSize: "10px", color: (this.state.businessDashboardCards.settlement === true
                                                                && this.state.businessDashboardCards.directPayment) ? 'black' : 'red'
                                                        }}

                                                    /> : (this.state.businessDashboardCards.settlement === true
                                                        && this.state.businessDashboardCards.directPayment) ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    Campaign
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.campaign}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'campaign')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.campaign ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.campaign ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.campaign ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item>
                                        <Card className={classes.card}>
                                            <CardContent>
                                                <Typography
                                                    variant="subtitle2"
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    User Sentiment
                                                </Typography>
                                                {this.props.user.role === "admin" ?
                                                    < FormControlLabel
                                                        control={
                                                            <Switch
                                                                size="small"
                                                                checked={this.state.businessDashboardCards.userSentiment}
                                                                onChange={(e) => this.handleBusinessCardEnableChange(e, 'userSentiment')}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.businessDashboardCards.userSentiment ? 'Enabled' : 'Disabled'}
                                                        style={{ fontSize: "10px", color: this.state.businessDashboardCards.userSentiment ? 'black' : 'red' }}

                                                    /> : this.state.businessDashboardCards.userSentiment ? 'Enabled' : 'Disabled'
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        {
                            <div>
                                <Grid item xs={3} sm={3} style={{ float: "right",marginRight:"15rem" }}>
                                    {
                                        (this.props.user.role === "areaManager" || this.props.user.role === "admin") && !this.state.editDetails ?
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                component="span"
                                                onClick={this.editDetailsTrue}
                                            >
                                                EDIT PERSONAL DETAILS
                                            </Button> : null
                                    }
                                </Grid>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={3}>
                                        <b>First Name</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.firstName} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <Grid item xs={12} sm={9}>
                                                <TextField
                                                    name="firstName"
                                                    label="First Name"
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    value={this.state.firstName}
                                                    // value={this.state.firstName ? this.state.firstName.trim().replace(/\D/g, "") : ""}
                                                    error={!this.state.firstName ? true : (this.state.firstNameValidationError ? true : false)}
                                                />
                                                {!this.state.firstName ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Required
                                                    </FormHelperText>
                                                ) : this.state.firstNameValidationError ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Invalid First Name
                                                    </FormHelperText>) :
                                                    null}
                                            </Grid>
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.firstName}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Last Name</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.lastName} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <Grid item xs={12} sm={9}>
                                                <TextField
                                                    name="lastName"
                                                    label="Last Name"
                                                    onChange={this.handleChange}
                                                    variant="outlined"
                                                    value={this.state.lastName}
                                                    error={!this.state.lastName ? true : (this.state.lastNameValidationError ? true : false)}
                                                />
                                                {!this.state.lastName ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Required
                                                    </FormHelperText>
                                                ) : this.state.lastNameValidationError ? (
                                                    <FormHelperText style={{ color: "red" }}>
                                                        Invalid Last Name
                                                    </FormHelperText>)
                                                    : null}
                                            </Grid>
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.lastName}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Email</b>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.email} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <TextField
                                                name="email"
                                                label="Email"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                value={this.state.email}
                                            />
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.email}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Primary Mobile Number</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={9}>
                                            <TextField
                                                name="primaryMobileNumber"
                                                label="Primary Mobile Number"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                inputProps={{ maxLength: 10 }}
                                                value={this.state.primaryMobileNumber ? this.state.primaryMobileNumber.trim().replace(/\D/g, "") : ""}
                                                error={!this.state.primaryMobileNumber ? true : (this.state.phoneValidationError ? true : false)}
                                            />
                                            {!this.state.primaryMobileNumber ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                    Required
                                                </FormHelperText>
                                            ) : this.state.phoneValidationError ? (
                                                <FormHelperText style={{ color: "red" }}>
                                                    Invalid Mobile Number
                                                </FormHelperText>
                                            ) : null}
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={9}>
                                        <Typography>
                                            {this.props.dashboard.setSelectedBusiness.ownerId.primaryMobileNumber}
                                        </Typography>
                                    </Grid>
                                    )}


                                    <Grid item xs={12} sm={3}>
                                        <b>Mobile Number</b>
                                    </Grid>
                                    {/* {mobileNumbers} */}
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={9}>
                                            <TextField
                                                name="mobileNumbers"
                                                label="Mobile Number"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                type="number"
                                                value={this.state.mobileNumbers}
                                                error={this.state.mobileValidationError ? true :(this.state.mobileValidationSpaceError ? true:false)}
                                            />
                                    {this.state.mobileValidationSpaceError ? (
                                            <FormHelperText style={{ color: "red" }}>
                                              Alphabet/spaces are not allowed
                                            </FormHelperText>
                                            ) : (
                                    this.state.mobileValidationError ? (
                                            <FormHelperText style={{ color: "red" }}>
                                            Mobile number should be 10 digits
                                            </FormHelperText>
                                        ) : null)
                                    }
                                        </Grid>) : (
                                        <Grid item xs={12} sm={9}>
                                            <Typography>
                                                {mobileNumbers}
                                            </Typography>
                                        </Grid>
                                        )
                                    }

                                    <Grid item xs={12} sm={3}>
                                        <b>Address</b>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        {/* {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address} */}
                                        {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                            <TextField
                                                name="address"
                                                label="Address"
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                value={this.state.address}
                                            />
                                        ) : (
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.address}
                                            </Typography>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Aadhar Card</b>
                                    </Grid>

                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name="aadharCardNumber"
                                                label="aadhar Number"
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 12 }}
                                                variant="outlined"
                                                value={this.state.aadharCardNumber}
                                            // error={!this.state.aadharCardNumber ? true : (this.state.aadharValidationError ? true : false)}

                                            />
                                            {this.state.aadharValidationError ?
                                                <FormHelperText style={{ color: "red" }}>
                                                    {!this.state.aadharCardNumber ? `Aadhar Number is required` : (! /([0-9]){12}$/.test(this.state.aadharCardNumber) ? `Invalid Aadhar Number` :
                                                        (!this.state.fileNameForAadhar && !this.props.business.aadharCardImage ? `Aadhar Photo is required` : ''))}
                                                </FormHelperText>
                                                : null
                                            }
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={3}>
                                        {!this.state.editDetails && this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number ?
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.aadhar.number}
                                            </Typography> : "Not Available"}
                                    </Grid>
                                    )}

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Aadhar Card Image</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ?

                                        <Grid item xs={12} sm={3} style={{ paddingLeft: "6px" }}>
                                            <Input type="file" name="aadhar" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUploadForAadhar} placeholder="White Icon" disableUnderline />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={9}>
                                            {!this.state.editDetails && this.props.business.aadharCardImage ?
                                                <img
                                                    src={URL.createObjectURL(
                                                        new Blob([this.props.business.aadharCardImage], {
                                                            type: "image/jpeg",
                                                        })
                                                    )}
                                                    alt="aadhar card"
                                                    width="400"
                                                    height="250"
                                                /> : "Not Available"}
                                        </Grid>}

                                    <Grid item xs={12}></Grid>

                                    <Grid item xs={12} sm={3}>
                                        <b>Pan Card</b>
                                    </Grid>

                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ? (
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                name="panCardNumber"
                                                label="Pancard Number"
                                                onChange={this.handleChange}
                                                inputProps={{ maxLength: 10 }}
                                                variant="outlined"
                                                value={this.state.panCardNumber}
                                            // error={!this.state.panCardNumber ? true : (this.state.panValidationError ? true : false)}
                                            />
                                            {
                                                this.state.panValidationError ?
                                                    <FormHelperText style={{ color: "red" }}>
                                                        {!this.state.panCardNumber ? `Pan Number is required` : (! /([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(this.state.panCardNumber) ? `Invalid Pan Number` :
                                                            (!this.state.fileNameForPan && !this.props.business.panCardImage ? `Pan Photo is required` : ''))}
                                                    </FormHelperText>
                                                    : null
                                            }
                                        </Grid>
                                    ) : (<Grid item xs={12} sm={3}>
                                        {!this.state.editDetails && this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number ?
                                            <Typography>
                                                {this.props.dashboard.setSelectedBusiness.ownerId.personalDetails.panCard.number}
                                            </Typography> :
                                            "Not Available"}
                                    </Grid>
                                    )}

                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12} sm={3}>
                                        <b>Pan Card Image</b>
                                    </Grid>
                                    {(this.state.editDetails && (this.props.user.role === "areaManager" || this.props.user.role === "admin")) ?

                                        <Grid item xs={12} sm={6} style={{ paddingLeft: "6px" }}>
                                            <Input type="file" name="pan" inputProps={{ accept: "image/x-png,image/jpeg" }} onChange={this.handleFileUploadForPan} placeholder="White Icon" disableUnderline />
                                        </Grid>
                                        :
                                        <Grid item xs={12} sm={9}>
                                            {!this.state.editDetails && this.props.business.panCardImage ?
                                                <img
                                                    src={URL.createObjectURL(
                                                        new Blob([this.props.business.panCardImage], {
                                                            type: "image/jpeg",
                                                        })
                                                    )}
                                                    alt="pan card"
                                                    width="400"
                                                    height="250"
                                                /> : "Not Available"}
                                        </Grid>}


                                    {this.state.editDetails ? (
                                        <Grid
                                            container
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ width: "15%", margin: "10px" }}
                                                variant="contained"
                                                margin="normal"
                                                component="span"
                                                onClick={this.closeEditDetails}
                                            >
                                                CANCEL
                                            </Button>
                                            <Button
                                                style={{ width: "15%", margin: "10px" }}
                                                variant="contained"
                                                color="primary"
                                                margin="normal"
                                                component="span"
                                                onClick={this.saveDetails}
                                            >
                                                SAVE
                                            </Button>
                                        </Grid>

                                    ) : null}
                                </Grid>
                            </div>
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={2}>
                        {
                            <BusinessDetailsSection
                                businessDetails={this.props.dashboard.setSelectedBusiness}
                                specialityDetails={this.state.specialityListArr}
                                cuisineDetails={this.state.cuisineListArr}
                                dashboardDetails={this.props.dashboard.getMasterOTP}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={3} >
                        {
                            <ServiceSection
                                businessDetails={this.props.dashboard.setSelectedBusiness}
                            />
                        }
                    </TabPanel>
                    <TabPanel value={this.state.value} index={4}>
                        {this.props.business &&
                            this.props.business.businessMembersDetails ? (
                            <Container>
                                <Container className={classes.searchPaper}>
                                    <Paper>
                                        <form onSubmit={this.handleSearchSubmit}>
                                            <InputBase
                                                placeholder="Search"
                                                value={this.state.searchValue}
                                                style={{ width: "240px", margin: "0px 10px" }}
                                                onChange={this.handleSearchChange}
                                            />
                                            <IconButton type="submit">
                                                <SearchIcon style={{ float: "right" }} />
                                            </IconButton>
                                        </form>
                                    </Paper>
                                    {clearSearchButton}
                                </Container>
                                <MembersTable
                                    rowData={this.props.business.businessMembersDetails}
                                    order={this.state.order === 1 ? "asc" : "desc"}
                                    orderBy={this.state.orderBy}
                                    page={this.state.page}
                                    rowsPerPage={this.state.rowsPerPage}
                                    setOrder={this.setOrder}
                                    setOrderBy={this.setOrderBy}
                                    setPage={this.setPage}
                                    setRowsPerPage={this.setRowsPerPage}
                                    history={this.props.history}
                                    handleBusinessMemberStatus={this.handleBusinessMemberStatus}  
                                    handleBusinessUserRole={this.handleBusinessUserRole}  
                                    updateRoleStatus={(Role,newRoleStatus)=>{this.updateRoleStatus(Role,newRoleStatus)}}
                                    changeEditStatusFlag={(flag,i)=>{this.changeEditStatusFlag(flag,i)}}
                                    editStatusFlag={this.state.editStatusFlag}                               
                                    editIndex={this.state.editIndex}
                                    changeNewRole={(newRole,row,i)=>{this.changeNewRoleFlag(newRole,row,i)}}
                                    selectedStatus={this.state.selectedStatus}
                                />
                            </Container>
                        ) : null}
                        <Dialog open={this.state.confirmDialogFlag} onClose={this.handleDeleteClose}>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to change the role to {this.state.selectedStatus}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose} color="primary"> No </Button>
                        <Button onClick={() => this.handleChangeRole()} color="primary" autoFocus> Yes </Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{vertical: 'top',horizontal: 'center'}}
                    open={this.state.snackOpen}
                    autoHideDuration={2000}
                    onClose={this.handleSnackBusinessUserClose}
                    ContentProps={{'aria-describedby': 'message-id'}}>
                        {muiAlert}
                </Snackbar>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={5}>
                        <Tabs
                            value={this.state.contractTab}
                            onChange={this.handleContractTab}
                        >
                            <Tab label="MANAGE SUBSCRIPTION" />
                            {this.props.user.role === "admin" ? <Tab label="MANAGE TRANSACTION CHARGES" /> : null}
                            {/* {this.props.user.role === "admin" ? <Tab label="MANAGE CONTRACT" /> : null} */}
                            <Tab label="MANAGE CONTRACT" />
                        </Tabs>
                        <TabPanel value={this.state.contractTab} index={0}>
                       
                            <Grid container spacing={3}>
                          
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    startIcon={<AddIcon />}
                                    onClick={this.handleContractModalOpen}
                                    style={{ cursor: "pointer", marginLeft: "12px" }}
                                >
                                    Add Contract
                                </Button>
                                <Grid item >
              </Grid>
                                {contractsArray &&
                                    contractsArray.length > 0 &&
                                    contractsArray.map((contract, index) => {
                                        let totalPaidAmount = 0;
                                        let pendingAmount = 0;
                                        contract &&
                                            contract.payments &&
                                            contract.payments.length > 0 &&
                                            contract.payments.forEach((payment) => {
                                                totalPaidAmount += Number(payment.amount);
                                            });

                                        pendingAmount =
                                            Number(contract.agreedAmount) - Number(totalPaidAmount);

                                        return (
                                            <Grid item key={contract._id} xs={12}>
                                                <Card>
                                                    <CardContent>
                                                        <Grid container justify="space-between">
                                                            {contract.isActive ? (
                                                                <Grid item>
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        gutterBottom
                                                                        color="primary"
                                                                        style={{
                                                                            background: "forestgreen",
                                                                            color: "aliceblue",
                                                                            padding: "0px 10px",
                                                                            borderRadius: "10px",
                                                                        }}
                                                                    >
                                                                        Active
                                                                    </Typography>
                                                                </Grid>
                                                            ) : null}
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Contract Start Date
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {moment(contract.startDate).format(
                                                                                "DD MMM YYYY"
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Contract End Date
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {moment(contract.endDate).format(
                                                                                "DD MMM YYYY"
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Contract Amount
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {contract.agreedAmount}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Description
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {contract.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Total Amount Paid
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {totalPaidAmount}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        <Typography
                                                                            variant="caption"
                                                                            display="block"
                                                                            gutterBottom
                                                                        >
                                                                            Pending Amount
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography variant="subtitle2" gutterBottom>
                                                                            {pendingAmount}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Grid item>
                                                                        {pendingAmount > 0 ? (
                                                                            <Button
                                                                                variant="contained"
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    this.handlePaymentModalOpen(contract)
                                                                                }
                                                                            >
                                                                                Payment Received
                                                                            </Button>
                                                                        ) : null}
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <CardActions style={{ padding: "0px" }}>
                                                            Payment History
                                                            <IconButton
                                                                onClick={(e) => this.handleExpandClick(index)}
                                                                aria-expanded={this.state.expanded[index]}
                                                                aria-label="show more"
                                                            >
                                                                <ExpandMoreIcon />
                                                            </IconButton>
                                                        </CardActions>
                                                    </CardContent>
                                                    <Collapse
                                                        in={this.state.expanded[index]}
                                                        timeout="auto"
                                                        unmountOnExit
                                                    >
                                                        <CardContent>
                                                            <TableContainer component={Paper}>
                                                                <Table
                                                                    className={classes.table}
                                                                    aria-label="simple table"
                                                                >
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Date</TableCell>
                                                                            <TableCell align="left">Amount</TableCell>
                                                                            <TableCell align="left">
                                                                                Mode of Payment
                                                                            </TableCell>
                                                                            <TableCell align="left">Note</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {contract &&
                                                                            contract.payments &&
                                                                            contract.payments.length > 0 &&
                                                                            contract.payments.map((payment, index) => {
                                                                                return (
                                                                                    <TableRow key={index}>
                                                                                        <TableCell component="th" scope="row">
                                                                                            {moment(payment.date).format(
                                                                                                "DD MMM YYYY"
                                                                                            )}
                                                                                        </TableCell>
                                                                                        <TableCell align="left">
                                                                                            {payment.amount}
                                                                                        </TableCell>
                                                                                        <TableCell align="left">
                                                                                            {payment.mode}
                                                                                        </TableCell>
                                                                                        <TableCell align="left">
                                                                                            {payment.note}
                                                                                        </TableCell>
                                                                                    </TableRow>
                                                                                );
                                                                            })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </CardContent>
                                                    </Collapse>
                                                </Card>
                                                <Modal
                                                    className={classes.modal}
                                                    open={this.state.paymentModalOpen}
                                                    onClose={this.handlePaymentModalClose}
                                                    closeAfterTransition
                                                >
                                                    <Container className={classes.modalContainer}>
                                                        <PaymentForm
                                                            onSubmit={this.addPayment}
                                                            selectedContract={this.state.selectedContract}
                                                            onClose={this.handlePaymentModalClose}
                                                        />
                                                    </Container>
                                                </Modal>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                  
                        </TabPanel>
                        {this.props.user.role === "admin" ? <TabPanel value={this.state.contractTab} index={1}>
                            <form onChange={this.convFeesFormChanged} onSubmit={this.saveConvenienceFees} ref={(ref) => this.formRef = ref}>
                                <div style={{ marginBottom: "88px" }}>
                                    <Grid container direction="row" alignItems="center" justifycontent="center">
                                        <Grid item xs={12}>
                                            <ButtonGroup variant="contained" color="primary" ref={this.anchorRef}
                                                aria-label="split button" style={{ float: "left", marginTop: "16px" }}>
                                                <Button onClick={this.handleAddTxnButClick}>{options[this.state.selectedIndex]}</Button>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    aria-controls={this.state.openTxnChrButton ? 'split-button-menu' : undefined}
                                                    aria-expanded={this.state.openTxnChrButton ? 'true' : undefined}
                                                    aria-label="select-add-txn"
                                                    aria-haspopup="menu"
                                                    onClick={this.handleToggleAddTxn}
                                                >
                                                    <ArrowDropDownIcon />
                                                </Button>
                                            </ButtonGroup>
                                            <Popper open={this.state.openTxnChrButton} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={this.handleClose}>
                                                                <MenuList id="add-txn-menu">
                                                                    {options.map((option, index) => (
                                                                        <MenuItem
                                                                            key={option}
                                                                            selected={index === this.state.selectedIndex}
                                                                            onClick={(event) => this.handleAddTxnButMenuClick(event, index)}
                                                                        >
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))}
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ marginTop: "16px" }}>
                                    {
                                        this.state.businessConvFees.length > 0 &&
                                        this.state.businessConvFees.map((bcf, convFeesIndex) => {
                                            return (<TransactionCharges
                                                convFees={bcf}
                                                ref={(ref) => { this.bcfRefs[convFeesIndex] = ref }}
                                                convFeesIndex={convFeesIndex}
                                                openDelTxnChargesDialog={this.openDelTxnChargesDialog}
                                                handleChgFeeType={this.handleChgFeeType}
                                                txnChargestype="businessConvFees"
                                                editTxnChargesRow={this.editTxnChargesRow}
                                                addTxnChargesRow={this.addTxnChargesRow}
                                                openDelConfirmDialog={this.openDelConfirmDialog}
                                                changeTxnChargesRow={this.changeTxnChargesRow}
                                                handleConvFeesActiveState={this.handleConvFeesActiveState}
                                                openSubsequentTxnRowDelConfirmDialog={this.openSubsequentTxnRowDelConfirmDialog}
                                            />);
                                        })
                                    }
                                </div>
                                <div style={{ marginTop: "16px" }}>
                                    {
                                        this.state.consumerConvFees.length > 0 &&
                                        this.state.consumerConvFees.map((ccf, convFeesIndex) => {
                                            return (<TransactionCharges
                                                convFees={ccf}
                                                ref={(ref) => { this.ccfRefs[convFeesIndex] = ref }}
                                                convFeesIndex={convFeesIndex}
                                                openDelTxnChargesDialog={this.openDelTxnChargesDialog}
                                                handleChgFeeType={this.handleChgFeeType}
                                                txnChargestype="consumerConvFees"
                                                editTxnChargesRow={this.editTxnChargesRow}
                                                addTxnChargesRow={this.addTxnChargesRow}
                                                openDelConfirmDialog={this.openDelConfirmDialog}
                                                changeTxnChargesRow={this.changeTxnChargesRow}
                                                handleConvFeesActiveState={this.handleConvFeesActiveState}
                                                openSubsequentTxnRowDelConfirmDialog={this.openSubsequentTxnRowDelConfirmDialog}
                                            />);
                                        })
                                    }
                                </div>
                                <Button type="submit" disabled={this.state.saveConvFeesButtonDisabled}
                                    variant="contained" color="primary"
                                    style={{ float: "right", marginTop: "16px" }}>SAVE</Button>
                            </form>
                        </TabPanel> : null}

                        {
                            this.props.user.role === 'admin' ?  
                                <TabPanel value={this.state.contractTab} index={2}>
                                     <Grid container spacing={3}>

                                        {/* Business Agreement Section */}
                                        <BusinessAgreementSection
                                            businessId = {this.props.dashboard.setSelectedBusiness._id}
                                            selectedBusinessData = {this.props.dashboard.setSelectedBusiness}
                                            // tempHistory = {tempHistory}
                                        />
                                        
                                     </Grid>
                                </TabPanel>  
                            : <TabPanel value={this.state.contractTab} index={1}>
                                <Grid container spacing={3}>
                                    {/* Business Agreement Section */}
                                    <BusinessAgreementSection
                                        businessId = {this.props.dashboard.setSelectedBusiness._id}
                                        selectedBusinessData = {this.props.dashboard.setSelectedBusiness}
                                        // tempHistory = {tempHistory}
                                    />
                                </Grid>
                            </TabPanel>
                        }
                    </TabPanel>
                    {this.props.user.role === "admin" ?
                        <TabPanel value={this.state.value} index={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justify="space-between">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Delivery Partner Availability
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        size="small"
                                                                        checked={this.state.isDeliveryByPartnerAllowd}
                                                                        onChange={this.handleDeliveryPartnerAvailabilitySwitchChange}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                style={{ fontSize: "10px", color: this.state.isDeliveryByPartnerAllowd ? 'black' : 'red' }}
                                                                label={this.state.isDeliveryByPartnerAllowd ? 'On' : 'Off'}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom>
                                                                (Turning this OFF will disable the delivery partner services for this business)
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justify="space-between">
                                                <Grid container spacing={1}>
                                                    <Grid item xs={3}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Delivery Partner Selected By Business
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <Typography
                                                                color="primary">
                                                                {this.state.isDeliveryByPartner === true ? "Yes" : "No"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="subtitle2" gutterBottom>
                                                                Initially Accepted On
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Grid item>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                                gutterBottom>
                                                                {this.props && this.props.dashboard && this.props.dashboard.setSelectedBusiness && this.props.dashboard.setSelectedBusiness.deliveryByPartnerAccepted ? moment(this.props.dashboard.setSelectedBusiness.deliveryByPartnerAccepted).format("DD MMM YYYY hh:mm A") : ""}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                            {this.state.isDeliveryByPartnerAllowd && this.state.isDeliveryByPartner ? <Grid className={classes.dpCont} item={12}>
                                <Card>
                                    <Grid container spacing={1} className={classes.headerTwo}>
                                        <span style={{marginTop: "31px", marginLeft: "1.5%"}}><b>Delivery Partner Priority</b></span>
                                        <Grid item xs={6} className={classes.borderOutline}>
                                        <span style={{position: "relative", left: 50, top: 11, }}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        size="small"
                                                        checked={this.state.deliveryParterRaduisFlag}
                                                        onChange={this.handleDeliveryPartnerRadiusOnOFF}
                                                        color="primary"
                                                        key={'deliveryPartnerRadius'}
                                                    />
                                                }
                                                style={{ fontSize: "10px", color: this.state.deliveryParterRaduisFlag ? 'black' : 'red' }}
                                                label={this.state.deliveryParterRaduisFlag ? 'On' : 'Off'}
                                            />
                                        </span>
                                        <span style={{ position: "relative", left: 320, top: 11, opacity: this.state.deliveryParterRaduisFlag ? "1" : '0.5' }}><b>Upto</b></span>
                                        <span style={{ marginLeft: "57%", marginTop: "16px", width: "7%", opacity: this.state.deliveryParterRaduisFlag ? "1" : '0.5' }}>
                                            <TextField 
                                                fullWidth 
                                                margin="dense" 
                                                name="servicePartnerDeliveryRadiusInKm" 
                                                label="" 
                                                variant="outlined" 
                                                value={this.state.servicePartnerDeliveryRadiusInKm}
                                                // value={this.props.editContractFlag && this.props.editContractFlag === true ? this.props.initialValues.outletEntitySignUpFee : this.state.outletEntitySignUpFee}
                                                // value={this.props.editContractFlag && this.props.editContractFlag === true && this.state.editFieldsOutletFeeFlag && this.state.editFieldsOutletFeeFlag === true ? this.props.initialValues.outletEntitySignUpFee : this.state.outletEntitySignUpFee}
                                                onChange={this.handleChange}
                                                error={this.state.radiusFieldRequired ? true : (this.state.invalidRadiusField ? true : false)}
                                                type='number'
                                                disabled={this.state.deliveryParterRaduisFlag ? false : true}
                                                helperText={this.state.radiusFieldRequired ? 'Required' : (this.state.invalidRadiusField ? 'Invalid' : null)}
                                                style={{width:"70px"}}
                                            />
                                        </span>
                                        <span style={{ position: "relative", left: 15, top: 11, opacity: this.state.deliveryParterRaduisFlag ? "1" : '0.5' }}><b>KMs</b></span>
                                        </Grid>                                        
                                    </Grid>
                                    
                                    <CardContent style={{ paddingBottom: "8px" }}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={3}>
                                                <b>Service Provider</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Status (ON/OFF)</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Priority</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Delivery Discount</b>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Dunzo
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={dunzop != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFF(e, "dunzo")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: dunzop != null ? 'black' : 'red' }}
                                                    label={dunzop != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                     labelId="demo-simple-select-standard-label"
                                                     id="demo-simple-select-standard"
                                                     variant="outlined"
                                                     value={dunzop != null ? dunzop.priority : ''}
                                                     onChange={(e) => this.handleDeliveryPriority(e, "dunzo")}
                                                     label="Delivery Partner"
                                                     disabled={dunzop == null}
                                                     required
                                                     className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid>
                                             { dunzop != null  ? <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="dunzoDiscount"
                                                value={this.state.dunzoDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                type="Number"
                                                placeholder=""
                                                margin="dense"
                                                disabled={dunzop == null}
                                                 
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                         </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Borzo
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={borzop != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFF(e, "borzo")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: borzop != null ? 'black' : 'red' }}
                                                    label={borzop != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={borzop != null ? borzop.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriority(e, "borzo")}
                                                    label="Delivery Partner"
                                                    disabled={borzop == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                             { borzop != null ? <TextField
                                                id="b"
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="borzoDiscount"
                                                type="Number"
                                                value={this.state.borzoDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={borzop == null}
                                               
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Porter
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={porterp != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFF(e, "porter")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: porterp != null ? 'black' : 'red' }}
                                                    label={porterp != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={porterp != null ? porterp.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriority(e, "porter")}
                                                    label="Delivery Partner"
                                                    disabled={porterp == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                           { porterp != null ? <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="porterDiscount"
                                                type="Number"
                                                value={this.state.porterDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={porterp == null}
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                            
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Shadowfax
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={shadowfaxp != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFF(e, "shadowfax")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: shadowfaxp != null ? 'black' : 'red' }}
                                                    label={shadowfaxp != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={shadowfaxp != null ? shadowfaxp.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriority(e, "shadowfax")}
                                                    label="Delivery Partner"
                                                    disabled={shadowfaxp == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            {shadowfaxp != null ?  <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="shadowFaxDiscount"
                                                type="Number"
                                                value={this.state.shadowFaxDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={shadowfaxp == null}
                                               
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                            
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                            {brandName} Delivery
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={xirify_delivery != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFF(e, "xirify_delivery")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: xirify_delivery != null ? 'black' : 'red' }}
                                                    label={xirify_delivery != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={xirify_delivery != null ? xirify_delivery.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriority(e, "xirify_delivery")}
                                                    label="Delivery Partner"
                                                    disabled={xirify_delivery == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            {xirify_delivery != null ?    <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="xirifyDeliveryDiscount"
                                                type="Number"
                                                value={this.state.xirifyDeliveryDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={xirify_delivery == null}
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                             
                                        </Grid>
                                        <Divider />
                                        <Grid container direction="row-reverse"
                                            justifycontent="space-around"
                                            alignItems="center" spacing={1}>
                                            <Grid item xs={1}>
                                                <Button onClick={this.saveDeliveryPriority} variant="contained"
                                                    margin="normal" color="primary" style={{ marginTop: "4px" }}>
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid> : null}
                            {this.state.isDeliveryByPartnerAllowd && this.state.isDeliveryByPartner && this.state.deliveryParterRaduisFlag && this.state.deliveryParterRaduisFlag !== null && this.state.deliveryParterRaduisFlag !== undefined && this.state.deliveryPartnerPriorityAsKm && this.state.deliveryPartnerPriorityAsKm !== null && this.state.deliveryPartnerPriorityAsKm !== undefined && this.state.servicePartnerDeliveryRadiusInKm !== null && this.state.servicePartnerDeliveryRadiusInKm !== undefined && this.state.servicePartnerDeliveryRadiusInKm >= 0 && this.state.servicePartnerDeliveryRadiusInKm !== "" ? <Grid className={classes.dpCont} item={12}>
                                <Card>
                                    <Grid container spacing={1} className={classes.headerTwo}>
                                        <span style={{marginTop: "20px", marginLeft: "1.5%", marginBottom: "20px"}}><b>Delivery Partner Priority Above {this.state?.servicePartnerDeliveryRadiusInKm || "NA"} KMs</b></span>
                                    </Grid>
                                    <CardContent style={{ paddingBottom: "8px" }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                <b>Service Provider</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Status (ON/OFF)</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Priority</b>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <b>Delivery Discount</b>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid container spacing={1}>
                                            <Grid item xs={3} >
                                                Dunzo
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={dunzoRadius != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFFBasedOnRadius(e, "dunzo")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: dunzoRadius != null ? 'black' : 'red' }}
                                                    label={dunzoRadius != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={dunzoRadius != null ? dunzoRadius.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriorityBasedOnRadius(e, "dunzo")}
                                                    label="Delivery Partner"
                                                    disabled={dunzoRadius == null}
                                                    required
                                                    className={classes.selectBox}
                                                    
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid  >
                                            {dunzoRadius != null ?  <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' , }}
                                                name="dunzoRadiusDiscount"
                                                type="Number"
                                                value={this.state.dunzoRadiusDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                placeholder=""
                                                margin="dense"
                                                disabled={dunzoRadius == null}
                                               
                                            /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                         </Grid>
                                         </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Borzo
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={borzoRadius != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFFBasedOnRadius(e, "borzo")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: borzoRadius != null ? 'black' : 'red' }}
                                                    label={borzoRadius != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={borzoRadius != null ? borzoRadius.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriorityBasedOnRadius(e, "borzo")}
                                                    label="Delivery Partner"
                                                    disabled={borzoRadius == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid style={{ paddingBottom : '5px'}}>
                                            {borzoRadius != null ?  <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="borzoRadiusDiscount"
                                                type="Number"
                                                value={this.state.borzoRadiusDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={borzoRadius == null}
                                               
                                               
                                                /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                            
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Porter
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={porterRadius != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFFBasedOnRadius(e, "porter")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: porterRadius != null ? 'black' : 'red' }}
                                                    label={porterRadius != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={porterRadius != null ? porterRadius.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriorityBasedOnRadius(e, "porter")}
                                                    label="Delivery Partner"
                                                    disabled={porterRadius == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            <Grid style={{ paddingBottom : '5px'}}>
                                            {porterRadius != null ?   <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="porterRadiusDiscount"
                                                type="Number"
                                                value={this.state.porterRadiusDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={porterRadius == null}
                                               
                                               
                                                /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                            
                                            </Grid>
                                    
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                                Shadowfax
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={shadowfaxRadius != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFFBasedOnRadius(e, "shadowfax")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: shadowfaxRadius != null ? 'black' : 'red' }}
                                                    label={shadowfaxRadius != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                   labelId="demo-simple-select-standard-label"
                                                   id="demo-simple-select-standard"
                                                   variant="outlined"
                                                    value={shadowfaxRadius != null ? shadowfaxRadius.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriorityBasedOnRadius(e, "shadowfax")}
                                                    label="Delivery Partner"
                                                    disabled={shadowfaxRadius == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>
                                            </Grid>
                                            {shadowfaxRadius != null ?    <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="shadowfaxRadiusDiscount"
                                                type="Number"
                                                value={this.state.shadowfaxRadiusDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={shadowfaxRadius == null}
                                               
                                               
                                                /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                           
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={3}>
                                            Xirify Delivery
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            size="small"
                                                            checked={xirifyDeliveryRadius != null}
                                                            onChange={(e) => this.handleDeliveryPartnerOnOFFBasedOnRadius(e, "xirify_delivery")}
                                                            color="primary"
                                                        />
                                                    }
                                                    style={{ fontSize: "10px", color: xirifyDeliveryRadius != null ? 'black' : 'red' }}
                                                    label={xirifyDeliveryRadius != null ? 'On' : 'Off'}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Select
                                                  
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    variant="outlined"
                                                    value={xirifyDeliveryRadius != null ? xirifyDeliveryRadius.priority : ''}
                                                    onChange={(e) => this.handleDeliveryPriorityBasedOnRadius(e, "xirify_delivery")}
                                                    label="Delivery Partner"
                                                    disabled={xirifyDeliveryRadius == null}
                                                    required
                                                    className={classes.selectBox}
                                                >
                                                    <MenuItem value={1}>1</MenuItem>
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={3}>3</MenuItem>
                                                    <MenuItem value={4}>4</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                </Select>

                                            </Grid>
                                            {xirifyDeliveryRadius != null ?    <TextField
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                              ₹
                                                        </InputAdornment>
                                                    ),
                                                  
                                                }}
                                                style={{ width : '100px' ,}}
                                                name="xirifyDeliveryRadiusDiscount"
                                                type="Number"
                                                value={this.state.xirifyDeliveryRadiusDiscount}
                                                onChange={this.handleDeliveryDiscount}
                                                margin="dense"
                                                disabled={xirifyDeliveryRadius == null}
                                                
                                               
                                                /> :  <input placeholder="  ₹" disabled={true} style={{ width : '100px', margin : '10px 0px 0px 0px' , height : '36px' , borderRadius : '5px' }}></input>}
                                           
                                        </Grid>
                                        <Divider />
                                        <Grid container direction="row-reverse"
                                            justifycontent="space-around"
                                            alignItems="center" spacing={1}>
                                            <Grid item xs={1}>
                                                <Button onClick={this.savePriorityBasedOnKmRadius} variant="contained"
                                                    margin="normal" color="primary" style={{ marginTop: "4px" }}>
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid> : null}
                            {
                                (this.props && this.props.deliveries && this.props.deliveries.settlementRecords && this.props.deliveries.settlementRecords.slotData) ?
                                    <DeliverySettlementTable
                                        data={this.props.deliveries.settlementRecords.slotData}
                                        exportToexcel={this.props.deliveries.settlementRecords}
                                        rowsPerPage={this.state.rowsPerPageDeliverySettlementTable}
                                        total={this.props.deliveries.settlementRecords.total}
                                        getSettlementsRecords={this.getSettlementsRecords}
                                        page={this.state.deliverySettlementTablePage}
                                        setPage={this.setPageDeliverySettlementTable}
                                        setRowsPerPage={this.setRowsPerPageDeliverySettlementTable}
                                        openSettleModal={this.openSettleModal}
                                        openNotesModal={this.openNotesModal}
                                        history={this.props.history}
                                        showDownloadIcon={this.state.showDownloadIcon}
                                        fetchWithExcelFile={this.fetchWithExcelFile}
                                        businessName={this.props.dashboard.setSelectedBusiness.businessName}
                                    />
                                    : null
                            }
                            <Backdrop className={classes.backdrop} open={this.state.isFetching}>
                                <CircularProgress color="inherit" />
                            </Backdrop>
                        </TabPanel> : null
                    }
                    {this.props.user.role === "areaManager" ?
                        <TabPanel value={this.state.value} index={6}>
                            {
                                <DiscountSection
                                    businessDetails={this.props.dashboard.setSelectedBusiness}
                                />
                            }
                        </TabPanel> : null
                    }

                    {
                        this.props.user.role === "admin" || this.props.user.role === "areaManager" ? 
                            <TabPanel value={this.state.value} index={7}>
                                {
                                    <VendorSettlementSection
                                        businessDetails={this.props.dashboard.setSelectedBusiness}
                                    />
                                }
                            </TabPanel>
                        :   
                            <TabPanel value={this.state.value} index={6}>
                                {
                                    <VendorSettlementSection
                                        businessDetails={this.props.dashboard.setSelectedBusiness}
                                    />
                                }
                            </TabPanel>
                    }
                    
                    {footerButton}
                </Container>
                <SettleAmountModal
                    open={this.state.settleModal}
                    onClose={this.onClose}
                    saveAndClose={this.saveAndClose}
                    data={this.state.selectedSettlementRecords}
                />
                <AddNotesModal
                    open={this.state.openNotesModal}
                    onClose={this.saveAndClose}
                    data={this.state.selectedSettlementRecords}

                />
                <Snackbar
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.state.snackOpen}
                    autoHideDuration={3000}
                    onClose={this.handleSnackClose}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                >
                    {muiAlert}
                </Snackbar>
                <Dialog
                    open={this.state.disableDialogOpen}
                    onClose={this.handleDisableDialogClose}
                >
                    <DialogTitle>{"Do you want to Disable the Business?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            Disabled Business is not available/visible to consumers on Xirify.
                            Disabled Business can be Enabled.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisableDialogClose} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={this.handleDisable} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.enableDialogOpen}
                    onClose={this.handleEnableDialogClose}
                >
                    <DialogTitle>{"Do you want to Enable the Business?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            Enabled Business will be available to consumers on Xirify. Enabled
                            Business can be Disabled.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleEnableDialogClose} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={this.handleApprove} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDelTxnDialogOpen}
                    onClose={() => { this.setState({ isDelTxnDialogOpen: false }) }}
                >
                    <DialogTitle>{"Delete Transaction Row"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            Do you want to Delete the Transaction Charges Row
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ isDelTxnDialogOpen: false }) }} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={() => {
                            this.setState({ isDelTxnDialogOpen: false });
                            // console.log("Dialog State", dialogState);
                            this.delTxnChargesRow(dialogState.txnChargestype, dialogState.convFeesIndex, dialogState.slabIndex);
                            dialogState.cb();
                        }} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isDelTxnChrgsDialogOpen}
                    onClose={() => { this.setState({ isDelTxnChrgsDialogOpen: false }) }}
                >
                    <DialogTitle>{"Delete Convenience Charges"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            Do you want to Delete the {dialogState.label}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ isDelTxnChrgsDialogOpen: false }) }} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={() => {
                            this.setState({ isDelTxnChrgsDialogOpen: false });
                            // console.log("Dialog State", dialogState);
                            this.deleteTxnCharges(dialogState.convFeesIndex, dialogState.txnChargestype);
                        }} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.isSubsequentDeleteTxnDialogOpen}
                    onClose={() => { this.setState({ isSubsequentDeleteTxnDialogOpen: false }) }}
                >
                    <DialogTitle>{"Delete Subsequent Transaction Row(s)"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            Setting infinty will delete the subsequent slabs. Do you want to continue?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { this.setState({ isSubsequentDeleteTxnDialogOpen: false }) }} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={() => {
                            this.setState({ isSubsequentDeleteTxnDialogOpen: false });
                            // console.log("Dialog State", subsequentRowDeleteDialogState);
                            subsequentRowDeleteDialogState.cb(this.delTxnChargesRow);
                        }} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.disableDialogOpen1}
                    onClose={this.handleDisableDialogClose1}
                >
                    <DialogTitle>{"You are Deleting the listed services. Are you sure?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                        You are deleting All services, Heads, Subheads, Addons, and Variations linked with the services. Are you sure to delete all?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisableDialogClose1} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={  this.handleDisableDialogOpen2} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.disableDialogOpen2}
                    onClose={this.handleDisableDialogClose2}
                >
                    <DialogTitle>{"Are you sure you want to delete the listed services?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText color="black">
                            
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDisableDialogClose2} color="mediumblue">
                            No
                        </Button>
                        <Button onClick={this.handlereset} color="mediumblue" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                
                <Modal
                    className={classes.modal}
                    open={this.state.contractModalOpen}
                    onClose={this.handleContractModalClose}
                    closeAfterTransition
                >
                    <Container className={classes.contractModalContainer}>
                        <ContractForm
                            onSubmit={this.addContract}
                            selectedBusiness={this.props.dashboard.setSelectedBusiness}
                            onClose={this.handleContractModalClose}
                        />
                    </Container>
                </Modal>

                <Backdrop className={classes.backdrop} open={this.state.backDropOpen}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dashboard: state.dashboard,
        business: state.business,
        contract: state.contract,
        user: state.auth.user,
        env: state.auth.env,
        deliveries: state.deliveries,
        addonGroup: state.addonGroup,
    };
};

export default connect(mapStateToProps, {
    showApprovedBusinessDetails,
    disableBusiness,
    approveBusiness,
    getShopActServPro,
    getQRCodeImage,
    deleteBusinessPhotos,
    clearBusiSucessMsg,
    clearBusiErrorMsg,
    getMembersList,
    getPanServPro,
    getAadharServPro,
    updateContract,
    clearContractMsgs,
    addContract,
    getRevenueByBusiness,
    getLastOrderReceivedByBusiness,
    updateDeliveryPartnerAvailability,
    updateDeliveryPartnerPriority,
    getSettlementsRecords,
    getSettlementsNotes,
    clearNotesRecord,
    fetchEnvs,
    updateAadharCard, updatePanCard,
    updateBusinessPersonalDetails,
    requestBusinessDetailsRecords,
     requestBusinessDetailsRecordsFalse,
    // updateBusinessDetails,
    getBusinessById,
    updateServiceProviderForUserRoleFromWeb,
    getServicesByBusinessId,
    deleteAllServices,
    getAllVariationGroups,
    getHeadByBusinessId,
    getAllAddonList,
    getHeadSubheadHierarchy,
    getAllItemsLinkedWithAddons,
    getHeadWithServicesByBusinessId,
    getAllAddOnGroups,
    getServiceDeliveryPartner
})(withStyles(styles)(FullBusinessDetails));
